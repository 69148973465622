import React from 'react';
import "/opt/build/repo/src/page-policy/page-list.tsx?resplendence=true";
import { Container, ContainerHeader } from 'common/layout';
import { FormattedMessage, useIntl } from 'react-intl';
import { PolicyType, PolicyDetailsFragment } from 'graphql.g';
import Loading from 'common/loading';
import { Link } from 'router';
import { usePolicies } from './use-policies';
import { flatMap } from 'utils/helpers';
import uniqBy from 'lodash/uniqBy';
import { OperatorTag, AreaTag, GenericTag } from 'common/tag';
import Currency from 'common/currency';
import { POLICY_TYPE_STRINGS } from './strings';
import { useScrollIntoView } from 'utils/use-scroll-into-view';
import PolicyCommonPage from './common-page';
import { getPolicyTags, sortPolicies } from './util';
import { useLocation } from 'react-router';

/*
    @import 'style';
*/;

const TARGET = "rx-page-policy-page-list-1"/*
    background-color: $blue-00;
*/;

const TABLE = "rx-page-policy-page-list-2"/*
    @include table;
    width: 100%;

    tbody tr > :nth-child(2) {
        padding: 7.5rem 8rem;
    }

    tbody tr th {
        padding-top: 36rem;
        padding-bottom: 12rem;
    }

    .title {
        @include text-body-bold;
        font-size: 20rem;
        color: $gray-90;
        padding-top: 48rem;
        padding-bottom: 24rem;
    }

    .first {
        padding-top: 0rem;
    }
*/;

const MULTIPLE_CONTAINER = "rx-page-policy-page-list-3"/*
    line-height: 29rem;
    > :not(:first-child) {
        margin-top: 6rem;
    }
*/;

interface PolicyListItemProps {
    policy: PolicyDetailsFragment;
}

const showFees = (policyType: PolicyType): boolean =>
    policyType === PolicyType.right_of_way ||
    policyType === PolicyType.trip_start ||
    policyType === PolicyType.trip_start_or_stop ||
    policyType === PolicyType.trip_start_xor_stop ||
    policyType === PolicyType.trip_start_and_stop ||
    policyType === PolicyType.trip_end ||
    policyType === PolicyType.mean_active;

const showTarget = (policyType: PolicyType): boolean =>
    policyType === PolicyType.vehicle_cap ||
    policyType === PolicyType.vehicle_cap_percentage ||
    policyType === PolicyType.minimum_deployment ||
    policyType === PolicyType.minimum_deployment_percentage ||
    policyType === PolicyType.speed_limit ||
    policyType === PolicyType.max_idle_time ||
    policyType === PolicyType.provider_deployment_cap ||
    policyType === PolicyType.provider_deployment_minimum;

const getTargetValue = (rule: PolicyDetailsFragment['rules'][number]) =>
    'maximum' in rule
        ? rule.maximum
        : rule.__typename === 'PolicyMinDeploymentRule'
        ? rule.minimum
        : null;

const getTargetValueStyle = (policyType: PolicyType) => (
    targetValue: number | null
) =>
    targetValue == null
        ? null
        : policyType === PolicyType.vehicle_cap_percentage ||
          policyType === PolicyType.minimum_deployment_percentage
        ? `${targetValue}%`
        : targetValue;

function PolicyListItem({ policy }: PolicyListItemProps) {
    const [ref, matched] = useScrollIntoView<HTMLTableRowElement>(policy.id);
    const areas = flatMap(policy.rules, rule => rule.areas);
    const uniqAreas = uniqBy(areas, 'id');
    const tags = getPolicyTags({ policy });

    return (
        <tr
            key={policy.id}
            id={policy.id}
            ref={ref}
            className={matched ? TARGET : undefined}
        >
            <td>
                <div
                    style={{
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        gridGap: '10rem'
                    }}
                >
                    <span
                        style={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            maxWidth: '50%',
                            display: 'inline-block'
                        }}
                    >
                        <Link
                            state={{ endDate: policy.endDate }}
                            to={`../${policy.id}`}
                        >
                            {policy.name}
                        </Link>
                    </span>
                    {tags.map(text => (
                        <GenericTag text={text} size="smaller" />
                    ))}
                </div>
            </td>
            {showTarget(policy.policyType) ? (
                <td>
                    {policy.rules
                        .map(getTargetValue)
                        .map(getTargetValueStyle(policy.policyType))
                        .filter(Boolean)
                        .join(', ')}
                </td>
            ) : null}
            {showFees(policy.policyType) ? (
                <td>
                    <div className={MULTIPLE_CONTAINER}>
                        {policy.rules.map(rule => (
                            <div key={rule.id}>
                                <Currency value={rule['rateAmount']} />
                            </div>
                        ))}
                    </div>
                </td>
            ) : null}
            <td>
                <div className={MULTIPLE_CONTAINER}>
                    {policy.operators.length > 0
                        ? policy.operators.map(op => (
                              <div key={op.id}>
                                  <OperatorTag {...op} />
                              </div>
                          ))
                        : 'All'}
                </div>
            </td>
            <td>
                <div className={MULTIPLE_CONTAINER}>
                    {uniqAreas.map(area => (
                        <div key={area.id}>
                            <AreaTag {...area} />
                        </div>
                    ))}
                </div>
            </td>
        </tr>
    );
}

function PolicyListPage() {
    const state = useLocation().state;
    const policiesFromApi = usePolicies();
    const policiesByLocationState =
        state === 'active'
            ? policiesFromApi?.policies
            : policiesFromApi?.endedPolicies;
    const intl = useIntl();

    const isLoading = policiesFromApi == null;

    // sorts the order of the policies by type then alphabet
    const policies =
        policiesByLocationState != null
            ? sortPolicies(policiesByLocationState)
            : [];

    const policyTypes: PolicyType[] = [];
    for (const policy of policies) {
        if (!policyTypes.includes(policy.policyType)) {
            policyTypes.push(policy.policyType);
        }
    }
    const sortedPolicyTypes = [...policyTypes].sort((a, b) =>
        a.localeCompare(b)
    );

    const policiesByType = sortedPolicyTypes.map(policyType => ({
        type: policyType,
        // use the translated policy name whenever possible
        name: intl.formatMessage(POLICY_TYPE_STRINGS[policyType] ?? policyType),
        // get all policies of this type
        policies: policies.filter(p => p.policyType === policyType)
    }));

    return (
        <PolicyCommonPage>
            <Loading loading={isLoading} />
            {policies.length === 0 ? (
                <Container>
                    {state === 'active' ? (
                        <FormattedMessage
                            key="no-active-policies"
                            defaultMessage="There are no active policies."
                        />
                    ) : (
                        <FormattedMessage
                            key="no-ended-policies"
                            defaultMessage="There are no ended policies."
                        />
                    )}
                </Container>
            ) : (
                policiesByType.map(policyType => (
                    <Container key={policyType.type}>
                        <ContainerHeader>{policyType.name}</ContainerHeader>
                        <table className={TABLE}>
                            <thead>
                                <tr>
                                    <th>
                                        <FormattedMessage
                                            key="table-header-name"
                                            defaultMessage="Name"
                                        />
                                    </th>
                                    {showTarget(policyType.type) ? (
                                        <th>
                                            <FormattedMessage
                                                key="table-header-target"
                                                defaultMessage="Target"
                                            />
                                        </th>
                                    ) : null}
                                    {showFees(policyType.type) ? (
                                        <th>
                                            <FormattedMessage
                                                key="table-header-fee"
                                                defaultMessage="Fee"
                                            />
                                        </th>
                                    ) : null}
                                    <th>
                                        <FormattedMessage
                                            key="table-header-provider"
                                            defaultMessage="Provider"
                                        />
                                    </th>
                                    <th>
                                        <FormattedMessage
                                            key="table-header-area"
                                            defaultMessage="Area"
                                        />
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {policyType.policies.map(policy => (
                                    <PolicyListItem
                                        key={policy.id}
                                        policy={policy}
                                    />
                                ))}
                            </tbody>
                        </table>
                    </Container>
                ))
            )}
        </PolicyCommonPage>
    );
}

export default PolicyListPage;
