import React from 'react';
import "/opt/build/repo/src/page-custom-data/preview-csv.tsx?resplendence=true";

/*
@import 'style';
*/;

const TABLE = "rx-page-custom-data-preview-csv-1"/*
    @include table;

    tbody tr > :nth-child(2) {
        padding: 7.5rem 8rem;
    }
*/;

function PreviewCSV({
    headers,
    rows
}: {
    headers?: string[];
    rows: (string | number | boolean)[][];
}) {
    return (
        <table className={TABLE}>
            {headers && (
                <thead>
                    <tr>
                        {headers?.map((header, i) => (
                            <th key={`${i} ${header}`}>{header}</th>
                        ))}
                    </tr>
                </thead>
            )}
            <tbody>
                {rows.map((row, i) => (
                    <tr key={i}>
                        {row.map((value, j) => {
                            return value == null ? (
                                <td key={`${i}-${j}`}></td>
                            ) : (
                                <td key={`${i}-${j}-${value}`}>
                                    {value.toString()}
                                </td>
                            );
                        })}
                    </tr>
                ))}
            </tbody>
        </table>
    );
}

export default PreviewCSV;
