import React, { useEffect, useState } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import "/opt/build/repo/src/page-policy/page-new.tsx?resplendence=true";
import { Link, Switch } from 'router';
import PolicySelector from './policy-selector';
import NewPolicyForm, { PolicyFormProps } from './new-policy-form';
import { AoiInfoFragment, PolicyType, VehicleClass, Day } from 'graphql.g';
import { LocalDate } from 'utils/date-tools';
import { MapViewOperator } from 'types';
import { useMapView } from 'common/use-map-view';
import { useAreas } from 'common/use-areas';
import { PageBody, PageContent, Container, Button } from 'common/layout';
import Loading from 'common/loading';
import { Title } from 'common/title';
import useDocumentTitle from 'common/use-document-title';
import { useHistory, useLocation, useRouteMatch } from 'react-router';
import Modal from 'common/modal';
import { sendEventAnalytics } from 'utils/use-analytics';
import DeletePolicy from './page-policy-delete-policy';
import { EditPolicyInput, useEditPolicyForm } from './policy-edit';
import Callout from 'common/callout';

/*
    @import 'style';
*/;

const LINK = "rx-page-policy-page-new-1"/*
    @include text-body-link-underlined;
    color: $blue-50;
*/;

const POLICY_CONTAINER = "rx-page-policy-page-new-2"/*
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(400rem, 1fr));
    grid-auto-rows: auto;
    grid-gap: 16rem;
*/;

/** creates an initial policy, with the start date set to Today */
function createInitialPolicy(
    ianaTimezone: string,
    area: AoiInfoFragment | null
): NewPolicy {
    return {
        policyType: PolicyType.no_parking_zone,
        name: '',
        description: '',
        area,
        providers: [],
        startDate: LocalDate.todayInTz(ianaTimezone),
        endDate: null,
        vehicleClass: [],
        days: Object.values(Day),
        isPublic: false
    };
}

/**
 * Determines the initial area for policies.
 *
 * Defaults to the municipality boundary. We should always have a boundary set,
 * but if we don't, then we'll fall back to the first area that is shared with
 * operators.
 *
 * A null return signifies that no areas are shared with operators.
 */
function getInitialArea(areas: AoiInfoFragment[] | null) {
    const sharedAreas = areas?.filter(a => a.sharedWithOperators);

    if (sharedAreas == null) return null;

    const municipalityBoundary = sharedAreas.find(
        a => a.isMunicipalityBoundary
    );

    return municipalityBoundary != null ? municipalityBoundary : sharedAreas[0];
}

export interface NewPolicy {
    policyType: PolicyType;
    name: string;
    description: string;
    providers: MapViewOperator[];
    startDate: LocalDate;
    endDate: LocalDate | null;
    area: AoiInfoFragment | null;
    vehicleClass: [] | [VehicleClass];
    days: Day[];
    rateAmount?: number;
    maximum?: number;
    minimum?: number;
    /**
     * an [hour, minute] representation
     * 8:15 AM would be [8, 15], 3:00 PM would be [15, 0]
     * */
    startTime?: [number, number];
    endTime?: [number, number];
    isPublic: boolean;
}

type ModalState = { type: 'delete' };

export type PolicyFormMode = 'edit' | 'new';

const m = defineMessages({
    'document-title': 'Create a Policy · Ride Report'
});

export type LocationProps = {
    policyToEdit: EditPolicyInput;
    policyId: string;
};

function PolicyEditorPage() {
    const { ianaTimezone } = useMapView();
    const areas = useAreas();
    const hasMunicipalityBoundary = areas?.some(
        area => area.isMunicipalityBoundary
    );
    const initialArea = getInitialArea(areas);
    const [policy, setPolicy] = useState<NewPolicy>(
        createInitialPolicy(ianaTimezone, initialArea)
    );
    const [hasAreas, setHasAreas] = useState(true);
    const [modalState, setModalState] = useState<null | ModalState>(null);
    const history = useHistory();

    const mode = useRouteMatch<{
        mode: PolicyFormMode;
    }>().params.mode;

    const editState = useLocation().state as LocationProps;

    useEditPolicyForm(mode, editState, setPolicy);

    const intl = useIntl();
    useDocumentTitle(intl.formatMessage(m[`document-title`]));

    // if there is no area set, take the first one. this happens when loading
    // the policy editor before the areas query has returned data
    useEffect(() => {
        if (policy.area == null) {
            const initialArea = getInitialArea(areas);

            // only update the policy if we can get an initial area. If it's
            // null, we'll create an infinite loop here. We need to show the
            // user a message so they can create an area.
            if (initialArea == null) {
                setHasAreas(false);
            } else {
                setHasAreas(true);
                setPolicy({
                    ...policy,
                    area: initialArea
                });
            }
        }
    }, [areas, policy, setPolicy]);

    return (
        <PageContent>
            <PageBody>
                {mode === 'new' ? (
                    <Title
                        title={
                            <FormattedMessage
                                key="create-a-policy"
                                defaultMessage="Create a Policy"
                            />
                        }
                        subtitle={
                            <p>
                                <Link
                                    to={`../../dashboard`}
                                    preserveQuery
                                    className={LINK}
                                >
                                    <FormattedMessage
                                        key="back-to-policies"
                                        defaultMessage="← Back to Policies"
                                    />
                                </Link>
                            </p>
                        }
                    />
                ) : (
                    <Title
                        title={
                            <FormattedMessage
                                key="edit-policy"
                                defaultMessage="Edit Policy"
                            />
                        }
                        subtitle={
                            <p>
                                <Link
                                    to={`../../${editState.policyId}`}
                                    preserveQuery
                                    className={LINK}
                                >
                                    <FormattedMessage
                                        key="back-to-policy"
                                        defaultMessage="← Back to Policy"
                                    />
                                </Link>
                            </p>
                        }
                        children={
                            !!hasAreas && (
                                <Button
                                    color="red"
                                    onClick={() =>
                                        setModalState({
                                            type: 'delete'
                                        })
                                    }
                                >
                                    <FormattedMessage
                                        key="delete-policy"
                                        defaultMessage="Delete Policy"
                                    />
                                </Button>
                            )
                        }
                    />
                )}
                {mode === 'new' && !hasMunicipalityBoundary && (
                    <Callout color="yellow">
                        <FormattedMessage
                            key="municipality-boundary-required-warning"
                            defaultMessage="There is no municipality boundary set for this mapview.  A municipal boundary must be set to create a new policy. Please contact <mail>support@ridereport.com</mail> for assistance setting a municipality boundary."
                            values={{
                                mail: msg => (
                                    <a
                                        className={LINK}
                                        href="mailto:support@ridereport.com"
                                    >
                                        {msg}
                                    </a>
                                )
                            }}
                        />
                    </Callout>
                )}

                {mode === 'edit' ? (
                    <Modal isModalShowing={modalState !== null}>
                        {modalState?.type === 'delete' ? (
                            <DeletePolicy
                                onCancel={() => setModalState(null)}
                                onSuccess={() => {
                                    setModalState(null);
                                    history.replace('../../../dashboard', {
                                        action: 'delete',
                                        policyName: policy.name
                                    });
                                    sendEventAnalytics({
                                        event_name: 'policy event',
                                        action: 'deleted',
                                        name: policy.name,
                                        policy_type: policy.policyType,
                                        rate_amount: undefined
                                    });
                                }}
                                policyId={editState.policyId}
                            />
                        ) : null}
                    </Modal>
                ) : null}

                <Loading loading={areas === null} />

                {hasAreas ? (
                    <div className={POLICY_CONTAINER}>
                        <Switch
                            pages={[
                                {
                                    path: '/select',
                                    exact: true,
                                    component: PolicySelector
                                },
                                {
                                    path: '/:policyType',
                                    exact: true,
                                    component: NewPolicyForm,
                                    properties: {
                                        policy,
                                        setPolicy
                                    } as PolicyFormProps
                                },
                                {
                                    path: '/:policyType/:policyId',
                                    exact: true,
                                    component: NewPolicyForm,
                                    properties: {
                                        policy,
                                        setPolicy
                                    } as PolicyFormProps
                                },
                                {
                                    redirectTo: '/select'
                                }
                            ]}
                        />
                    </div>
                ) : (
                    <Container>
                        <p>
                            <FormattedMessage
                                key="no-areas-error"
                                defaultMessage="There are no active areas shared with operators. Before creating a new policy, <a>create an area</a> that is shared with operators."
                                values={{
                                    a: msg => (
                                        <Link
                                            to="../../areas/new"
                                            className={LINK}
                                        >
                                            {msg}
                                        </Link>
                                    )
                                }}
                            />
                        </p>
                    </Container>
                )}
            </PageBody>
        </PageContent>
    );
}

export default PolicyEditorPage;
