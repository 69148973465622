import { LocationProps, NewPolicy } from './page-new';
import { useAreas } from 'common/use-areas';
import { useMapView } from './../common/use-map-view';
import { LocalDate } from 'utils/date-tools';
import {
    Day,
    EditPolicyMutationArgs,
    EditPolicyResult,
    PolicyWithMetricsData,
    VehicleClass
} from 'graphql.g';
import { FormEvent, useEffect } from 'react';
import { useMemo } from 'react';
import { gql, useMutation } from '@apollo/client';
import { useHistory } from 'react-router';
import { sendEventAnalytics } from 'utils/use-analytics';

export type EditPolicyInput = PolicyWithMetricsData['mapViewById']['policy'];

export type PolicyFormMode = 'edit' | 'new';

// This function takes the policy queried from the detail page
// and turns it into a form that's accepted by the policy form fields for editing.
export function useEditPolicyForm(
    mode: PolicyFormMode,
    state: LocationProps | undefined,
    setPolicy: (policy: NewPolicy) => void
) {
    const operators = useMapView().operators;

    const areas = useAreas();

    const policyToEdit: NewPolicy | null = useMemo(() => {
        if (state == null || state.policyToEdit == null || areas == null) {
            return null;
        }

        const startTime = state.policyToEdit?.rules[0].startTime
            ? state.policyToEdit.rules[0].startTime.split(':')
            : undefined;

        const endTime = state.policyToEdit?.rules[0].endTime
            ? state.policyToEdit.rules[0].endTime.split(':')
            : undefined;

        const providers =
            state.policyToEdit !== null &&
            state.policyToEdit?.operators.length > 0
                ? operators.filter(op =>
                      state.policyToEdit?.operators.some(
                          policyOp => op.operatorId === policyOp.id
                      )
                  )
                : [];

        return {
            policyType: state.policyToEdit.policyType,
            name: state.policyToEdit.name,
            description: state.policyToEdit.description,
            providers: providers,
            startDate: LocalDate.fromDateString(state.policyToEdit.startDate),
            endDate:
                state.policyToEdit.endDate != null
                    ? LocalDate.fromDateString(state.policyToEdit.endDate)
                    : null,
            area: areas?.filter(
                area => state.policyToEdit?.rules[0].areas[0].id === area.id
            )[0],
            rateAmount: undefined,
            vehicleClass: state.policyToEdit.rules[0].vehicleClasses as [
                VehicleClass
            ],
            days:
                state.policyToEdit.rules[0].days.length === 0
                    ? Object.values(Day)
                    : state.policyToEdit.rules[0].days,
            maximum: state.policyToEdit.metric?.ruleTarget,
            minimum: undefined,
            startTime: startTime ? [startTime[0], startTime[1]] : undefined,
            endTime: endTime ? [endTime[0], endTime[1]] : undefined,
            isPublic: state.policyToEdit.isPublic
        };
    }, [state, areas, operators]);

    // We tell react to fill in our original policy details into the policy form fields here
    useEffect(() => {
        if (mode === 'edit' && policyToEdit != null) {
            setPolicy({
                policyType: policyToEdit.policyType,
                name: policyToEdit.name,
                description: policyToEdit.description,
                providers: policyToEdit.providers,
                startDate: policyToEdit.startDate,
                endDate: policyToEdit.endDate,
                area: policyToEdit.area,
                days: policyToEdit.days,
                vehicleClass: policyToEdit.vehicleClass,
                rateAmount: policyToEdit.rateAmount,
                maximum: policyToEdit.maximum,
                minimum: policyToEdit.minimum,
                startTime: policyToEdit.startTime,
                endTime: policyToEdit.endTime,
                isPublic: policyToEdit.isPublic
            });
        }
    }, [mode, policyToEdit, setPolicy]);
    return;
}

const EDIT_POLICY_MUTATION = gql`
    mutation EditPolicy($policy: EditPolicyInput!) {
        editPolicy(policy: $policy) {
            policy {
                id
                name
                description
                operators {
                    name
                }
                startDate
                endDate
                isPublic
            }
        }
    }
`;

function useEditPolicy(
    policy: NewPolicy,
    policyId: string,
    formHasErrors: boolean
) {
    const mapView = useMapView();
    const history = useHistory();

    const [editPolicy] = useMutation<EditPolicyResult, EditPolicyMutationArgs>(
        EDIT_POLICY_MUTATION,
        {
            update(cache, { data }) {
                // get the updated policy returned from the mutation
                const updatedPolicy = data?.editPolicy?.policy;
                if (updatedPolicy == null) return;
                cache.modify({
                    // get the id of the mapview in the cache that we want to update
                    id: cache.identify(mapView),
                    fields: {
                        // take in the existting policy references
                        policies(existingPolicyRefs = [], { toReference }) {
                            // get the reference ot the updatedPolicy in our cache
                            const updatedPolicyRef = toReference(updatedPolicy);
                            // build a new array of policy references to store
                            // on the mapview policies
                            return [...existingPolicyRefs, updatedPolicyRef];
                        }
                    }
                });
            }
        }
    );

    const onEditSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        // this shouldn't be possible due to a disabled submit button, but
        // we want to be sure before trying to make the API request.
        if (formHasErrors) {
            return;
        }

        // Create our policy and update the Apollo cache with the result that
        // was returned. This is necessary to show the new policy in the list
        // without reloading the page.
        const res = await editPolicy({
            variables: {
                policy: {
                    mapviewId: mapView.id,
                    policyId: policyId,
                    name: policy.name,
                    description: policy.description,
                    operatorIds: policy.providers.map(op => op.operatorId),
                    startDate: policy.startDate.toString(),
                    endDate: policy.endDate?.toString(),
                    isPublic: policy.isPublic ?? false
                }
            }
        });

        const updatedPolicyId = res.data?.editPolicy?.policy?.id;
        if (updatedPolicyId) {
            history.push(`/${mapView.slug}/policy/${updatedPolicyId}`, {
                action: 'edited',
                policyName: policy.name,
                endDate: policy.endDate
            });
            sendEventAnalytics({
                event_name: 'policy event',
                action: 'edited',
                name: policy.name,
                policy_type: policy.policyType,
                rate_amount: policy.rateAmount
            });
        } else {
            throw new Error('EditPolicy query returned no error and no data.');
        }
    };
    return onEditSubmit;
}

export default useEditPolicy;
