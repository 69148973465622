import React, { useMemo } from 'react';
import { Table, Row, HeaderRow, TimestampValue, HeaderProps } from './tables';
import statDescriptions from '../constants/stat-descriptions';
import { MapView } from 'types';
import gql from 'graphql-tag';
import Loading from 'common/loading';
import { useReloadingData } from 'utils/use-data';
import { ReportMaxVehiclesFragment } from './fragments';
import { LocalDate } from 'utils/date-tools';
import {
    DailyReportOverviewMaxVehiclesData,
    DailyReportOverviewMaxVehiclesArgs
} from 'graphql.g';
import { useVehicleClass } from 'common/vehicle-classes';
import { doesReportHaveOperatorName } from './helpers';
import { ContainerMessage } from 'common/layout';
import { FormattedMessage } from 'react-intl';
import { useSorting } from 'common/table-sorting';
import orderBy from 'lodash/orderBy';
import zipWith from 'lodash/zipWith';

const headerInfo = [
    statDescriptions.maxAvailable,
    statDescriptions.maxUnavailable,
    statDescriptions.maxParked
];
const sortKeys = [
    'maxVehicleMetric.maxAvailable',
    'maxVehicleMetric.maxUnavailable',
    'maxVehicleMetric.maxParked'
];
const headers: HeaderProps[] = zipWith(headerInfo, sortKeys, (a, b) => {
    return [a, b];
});

type Props = {
    reportDate: LocalDate;
    mapView: MapView;
};

const QUERY = gql`
    query DailyReportOverviewMaxVehicles(
        $slug: String
        $date: Date!
        $vehicleClass: VehicleClass
    ) {
        mapView(slug: $slug) {
            id
            overviewReport(date: $date, vehicleClass: $vehicleClass) {
                id
                reportDate
                reports {
                    id
                    operator {
                        name
                    }
                    ...ReportMaxVehicles
                }
            }
        }
    }
    ${ReportMaxVehiclesFragment}
`;

function PageDailySummaryVehicles({ mapView, reportDate }: Props) {
    const vehicleClass = useVehicleClass();
    const [data, loading] = useReloadingData<
        DailyReportOverviewMaxVehiclesData,
        DailyReportOverviewMaxVehiclesArgs
    >(QUERY, {
        slug: mapView.slug,
        date: reportDate.toString(),
        vehicleClass
    });
    const [sorting, onSortHeaderClick] = useSorting<
        | 'operator.name'
        | 'maxVehicleMetric.maxAvailable'
        | 'tripsPerVehicleMetric.maxUnavailable'
        | 'maxVehicleMetric.maxParked'
    >('operator.name');

    const mapViewData = data && data.mapView;

    const allReports = useMemo(() => {
        const list =
            mapViewData && mapViewData.overviewReport
                ? mapViewData.overviewReport.reports
                : [];
        return orderBy(list, [...sorting.keys()], [...sorting.values()]);
    }, [sorting, mapViewData]);

    return (
        <>
            <Loading loading={loading} kind="over-table" />
            {allReports && allReports.length !== 0 ? (
                <Table>
                    <HeaderRow
                        headers={headers}
                        sorting={sorting}
                        onSortHeaderClick={onSortHeaderClick}
                    />
                    <tbody>
                        {allReports
                            .filter(doesReportHaveOperatorName)
                            .map(this_report => {
                                if (this_report == null) {
                                    return null;
                                }
                                const {
                                    id,
                                    maxVehicleMetric,
                                    operator
                                } = this_report;
                                const name = operator && operator.name;
                                const values = [
                                    maxVehicleMetric && (
                                        <TimestampValue
                                            value={
                                                maxVehicleMetric.maxAvailable
                                            }
                                            time={
                                                maxVehicleMetric.maxAvailableTime
                                            }
                                        />
                                    ),
                                    maxVehicleMetric && (
                                        <TimestampValue
                                            value={
                                                maxVehicleMetric.maxUnavailable
                                            }
                                            time={
                                                maxVehicleMetric.maxUnavailableTime
                                            }
                                        />
                                    ),
                                    maxVehicleMetric && (
                                        <TimestampValue
                                            value={maxVehicleMetric.maxParked}
                                            time={
                                                maxVehicleMetric.maxParkedTime
                                            }
                                        />
                                    )
                                ];
                                return (
                                    <Row
                                        timestamps
                                        key={id}
                                        name={name}
                                        values={values}
                                    />
                                );
                            })}
                    </tbody>
                </Table>
            ) : (
                <ContainerMessage>
                    <FormattedMessage
                        key="no-vehicle-metrics"
                        defaultMessage="This report doesn't have any vehicle metrics."
                    />
                </ContainerMessage>
            )}
        </>
    );
}

export default PageDailySummaryVehicles;
