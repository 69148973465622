import { useState } from 'react';

type SortDirection = 'asc' | 'desc';
export type TableSorting = Map<string, SortDirection>;

export const useSorting = <Column extends string>(
    initialColumn: Column | null = null
): [TableSorting, (string) => void] => {
    const initialSorting: TableSorting = new Map();
    // This defaults to ascending because the standard initial sort is by operator
    // name, which we want to default to ascending. Other sorts should default to
    // descending.
    if (initialColumn) {
        initialSorting.set(initialColumn, 'asc');
    }
    const [sorting, setSorting] = useState<TableSorting>(initialSorting);

    const onSortHeaderClick = (column: string) => {
        const currentColumn =
            sorting.get(column) && column === [...sorting.keys()][0];
        if (currentColumn) {
            if (sorting.get(column) === 'asc') {
                setSorting(new Map());
                return;
            } else {
                const direction = 'asc';
                setSorting(
                    new Map([
                        [column, direction],
                        ...[...sorting].filter(([c]) => c !== column)
                    ])
                );
            }
        } else {
            const direction = 'desc';
            setSorting(
                new Map([
                    [column, direction],
                    ...[...sorting].filter(([c]) => c !== column)
                ])
            );
        }
    };

    return [sorting, onSortHeaderClick];
};
