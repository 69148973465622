import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import {
    DEFAULT_END_TIME,
    DEFAULT_START_TIME,
    NumberInput,
    TimeSelectInput
} from './common';
import { PolicyFormProps } from './types';
import { SingleOperatorSelect } from './operator-selectors';
import VehicleClassInput from './vehicle-class-selector';
import DayOfWeekInput from './day-of-week-selector';

type VehicleCapFormProps = PolicyFormProps & { isPercentBased: boolean };

function VehicleCapForm({
    policy,
    setPolicy,
    formErrors,
    setFormErrors,
    isPercentBased
}: VehicleCapFormProps) {
    useEffect(() => {
        const hasMaximumError = policy.maximum == null;
        // set a maximum error if it doesn't already exist
        if (formErrors.maximum !== hasMaximumError) {
            setFormErrors({
                ...formErrors,
                maximum: hasMaximumError
            });
        }
    }, [policy.maximum, formErrors, setFormErrors]);

    return (
        <>
            <TimeSelectInput
                label={
                    <FormattedMessage
                        key="time-label-start"
                        defaultMessage="Start time:"
                    />
                }
                id="start-time-input"
                selectedTime={policy.startTime ?? DEFAULT_START_TIME}
                onChange={startTime => {
                    setPolicy({
                        ...policy,
                        startTime
                    });
                }}
            />

            <TimeSelectInput
                label={
                    <FormattedMessage
                        key="time-label-end"
                        defaultMessage="End time:"
                    />
                }
                id="end-time-input"
                selectedTime={policy.endTime ?? DEFAULT_END_TIME}
                onChange={endTime =>
                    setPolicy({
                        ...policy,
                        endTime
                    })
                }
            />

            <DayOfWeekInput
                days={policy.days}
                onChange={days =>
                    setPolicy({
                        ...policy,
                        days
                    })
                }
            />

            <VehicleClassInput policy={policy} setPolicy={setPolicy} />

            <NumberInput
                width={96}
                numberLabel={isPercentBased ? '%' : undefined}
                numberLabelPosition={isPercentBased ? 'right' : undefined}
                label={
                    <FormattedMessage
                        key="vehicle-cap-label"
                        defaultMessage="Vehicle Cap:"
                    />
                }
                id="vehicle-cap-input"
                value={policy.maximum ?? ''}
                min={0}
                max={isPercentBased ? 100 : undefined}
                onChange={newValue => {
                    const amount = parseInt(newValue);
                    setPolicy({
                        ...policy,
                        maximum: Number.isNaN(amount) ? undefined : amount
                    });
                }}
            />

            <SingleOperatorSelect
                policy={policy}
                setPolicy={setPolicy}
                formErrors={formErrors}
                setFormErrors={setFormErrors}
            />
        </>
    );
}

export default VehicleCapForm;
