import React, { useEffect, useState } from 'react';
import { Expression, Layer } from 'mapbox-gl';
import { defineMessages, useIntl } from 'react-intl';
import { Checkbox } from 'common/layout';
import { DispatchMapOptions } from 'page-explore';

const m = defineMessages({
    'bike-lanes': 'Bike Lanes',
    'multi-use-paths': 'Multi-Use Paths'
});

const DEFAULT_LINE_WIDTH: Expression = [
    'interpolate',
    ['linear'],
    ['zoom'],
    12,
    1,
    22,
    8
];

// Copy of routes-data.tsx line 534
const DEFAULT_WIDTH_STEPS: Expression = [
    'interpolate',
    ['linear'],
    ['zoom'],
    12, // at zoom level 12
    1.5, // make the line 1.5 units wide
    22, // at zoom level 22 (max)
    12 // make the line 12 units wide
];

const BIKE_LANES: Layer = Object.freeze({
    id: 'bike-lanes',
    type: 'line',
    source: 'composite',
    'source-layer': 'road',
    layout: {
        'line-join': 'round',
        'line-cap': 'round'
    },
    paint: {
        'line-opacity': 0.6,
        'line-offset': DEFAULT_WIDTH_STEPS, // Offset the bikeLanes to not overlap with Routes
        'line-width': DEFAULT_LINE_WIDTH,
        'line-color': [
            'case',
            // if this feature has a bike_lane or is type:cycleway
            [
                'match',
                ['get', 'bike_lane'],
                ['yes', 'both', 'right', 'left'],
                true,
                false
            ],
            // make it green
            'hsl(117, 88%, 38%)',
            // hide all other roads
            'transparent'
        ]
    }
});

const MULTI_USE_PATH: Layer = Object.freeze({
    id: 'multi-use-paths',
    type: 'line',
    source: 'composite',
    'source-layer': 'road',
    layout: {
        'line-join': 'round',
        'line-cap': 'round'
    },
    paint: {
        'line-width': DEFAULT_LINE_WIDTH,
        'line-dasharray': [1, 2],
        'line-color': [
            'case',
            // if this feature is a type:path or type:cycleway
            ['match', ['get', 'type'], ['path', 'cycleway'], true, false],
            // make it green
            'hsl(120, 61%, 50%)',
            // hide all other roads
            'transparent'
        ]
    }
});

export const commonPathLayers = {
    BIKE_LANES,
    MULTI_USE_PATH
};

interface CommonMapboxLayersProps {
    dispatchMapOptions: DispatchMapOptions;
}

const CommonPathLayersToggles = ({
    dispatchMapOptions
}: CommonMapboxLayersProps) => {
    const intl = useIntl();

    const [showBikeLanes, setShowBikeLanes] = useState(false);
    useEffect(() => {
        dispatchMapOptions({
            type: 'set layers',
            payload: {
                dataType: 'bikeLanes',
                layers: showBikeLanes ? [commonPathLayers.BIKE_LANES] : []
            }
        });
        return () =>
            dispatchMapOptions({
                type: 'set layers',
                payload: { dataType: 'bikeLanes', layers: [] }
            });
    }, [dispatchMapOptions, showBikeLanes]);

    const [showMultiUsePaths, setShowMultiUsePaths] = useState(false);
    useEffect(() => {
        dispatchMapOptions({
            type: 'set layers',
            payload: {
                dataType: 'multiUsePaths',
                layers: showMultiUsePaths
                    ? [commonPathLayers.MULTI_USE_PATH]
                    : []
            }
        });
        return () =>
            dispatchMapOptions({
                type: 'set layers',
                payload: { dataType: 'multiUsePaths', layers: [] }
            });
    }, [dispatchMapOptions, showMultiUsePaths]);

    return (
        <>
            <Checkbox
                key="toggle-bike-layer"
                checked={showBikeLanes}
                onChange={() => setShowBikeLanes(!showBikeLanes)}
                label={intl.formatMessage(m['bike-lanes'])}
            />
            <Checkbox
                key="toggle-multi-use-layer"
                checked={showMultiUsePaths}
                onChange={() => setShowMultiUsePaths(!showMultiUsePaths)}
                label={intl.formatMessage(m['multi-use-paths'])}
            />
        </>
    );
};

export default CommonPathLayersToggles;
