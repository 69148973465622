function findAreaMetricMatchingSlug<T extends { area: { slug: string } }>(
    slug: string,
    metrics: T[]
) {
    // We switched our underscores to dashes to make area slugs consistent with
    // mapview slugs. This fixes those old urls.
    const withUnderscoresReplaced = slug.replace(/[-_]+/g, '-');

    const [name, createdDate, operator] = withUnderscoresReplaced.split(':');

    // first, look for an exact match
    const exactMatch = metrics.find(
        m => m.area.slug === withUnderscoresReplaced
    );
    if (exactMatch) return exactMatch;

    // otherwise, if the slug doesn't have a date, look for matches ignoring
    // the date part
    const inferredDate = metrics.find(m => {
        const parts = m.area.slug.split(':');
        const metricName = parts[0];
        const metricOperator = parts[2];
        return (
            !createdDate && name === metricName && operator === metricOperator
        );
    });
    if (inferredDate) return inferredDate;

    // otherwise, if the slug is just a bare string, ignore the date and the
    // operator part (probably this is an old bookmark from before we added
    // a tag to mark operator-only areas.
    const inferredOperator = metrics.find(m => {
        const metricName = m.area.slug.split(':')[0];
        return !createdDate && !operator && name === metricName;
    });
    return inferredOperator ?? null;
}

export default findAreaMetricMatchingSlug;
