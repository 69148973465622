import React, { useEffect, useState } from 'react';
import "/opt/build/repo/src/common/dropdown.tsx?resplendence=true";
import cx from 'classnames';

import { CSSTransition } from 'react-transition-group';
import { ButtonProps, Button } from './layout';

/*
@import "style";
*/;

const WRAPPER = "rx-common-dropdown-1"/*
    position: relative;
    display: grid;

    pointer-events: auto;
*/;

const DROPDOWN = "rx-common-dropdown-2"/*
    border-radius: 6rem;
    padding: 8rem;
    position: absolute;
    z-index: 11;
    background-color: $white;
    @include shadow-8;
    transition-property: opacity;
    transition-duration: 100ms;
    &.enter-active, &.exit {
        opacity: 1;
    }
    &.enter, &.exit-active {
        opacity: 0;
    }
    &.exit {
        transition-duration: 200ms;
        pointer-events: none;
    }
    &.bottom-left {
        top: calc(100% + 8rem);
        left: 0;
    }
    &.bottom-right {
        top: calc(100% + 8rem);
        right: 0;
    }
*/;

type Props = {
    /** Props for the button that opens the dropdown */
    buttonProps: Omit<ButtonProps, 'onClick'>;

    dropdownClassName?: string;

    /** What goes inside the dropdown */
    children: React.ReactNode;

    disabled?: boolean;

    position?: 'bottom-left' | 'bottom-right';
} & (
    | {
          active: boolean;
          setActive: React.Dispatch<React.SetStateAction<boolean>>;
      }
    | { active?: undefined; setActive?: undefined }
);

/**
 * Generic component for creating a dropdown. Override the styles to reposition
 * or reanimate it.
 */
export function Dropdown({
    children,
    buttonProps,
    dropdownClassName,
    active: propsActive,
    setActive: setPropsActive,
    disabled,
    position = 'bottom-left'
}: Props) {
    const [hovering, setHovering] = useState(false);
    const [innerActive, setInnerActive] = useState(false);
    const active = propsActive ?? innerActive;
    const setActive = setPropsActive ?? setInnerActive;
    useEffect(() => {
        if (!hovering && active) {
            const onClickOutside = () => {
                setActive(false);
            };
            document.addEventListener('click', onClickOutside);
            return () => document.removeEventListener('click', onClickOutside);
        }
    }, [active, hovering, setActive]);

    return (
        <div
            className={WRAPPER}
            onMouseOver={() => setHovering(true)}
            onMouseLeave={() => setHovering(false)}
        >
            <Button
                disabled={disabled}
                onClick={() => setActive(value => !value)}
                {...buttonProps}
            />
            <CSSTransition
                in={active}
                timeout={{ enter: 100, exit: 200 }}
                mountOnEnter
                unmountOnExit
            >
                <div className={dropdownClassName || cx(DROPDOWN, position)}>
                    {children}
                </div>
            </CSSTransition>
        </div>
    );
}
