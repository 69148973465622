import React from 'react';
import "/opt/build/repo/src/common/dropdown-select.tsx?resplendence=true";
import { Dropdown } from './dropdown';

import { IconName } from 'common/icon';

/*
@import "style";
*/;

const INPUT = "rx-common-dropdown-select-1"/*
    height: 36rem;
    min-width: 144rem;
    border-radius: 6rem;
    box-sizing: border-box;
    padding: 6rem;
    padding-left: 36rem;
    position: relative;
    @include link-border;
    transition: 150ms all;
    white-space: nowrap;
    svg {
        position: absolute;
        font-size: 24rem;
        top: calc(50% - 24rem / 2);
        left: 7rem;
        pointer-events: none;
        color: $blue-50;
        transition: 150ms all;
    }
    &:disabled {
        color: $gray-30;
        svg {
            color: $gray-30;
        }
    }
*/;

type Props = {
    /** The text to shown in the select button */
    value: any;

    /** The icon to render on the left */
    icon: IconName;

    /** What goes inside the dropdown */
    children: React.ReactNode;

    /** Whether the dropdown is currently revealed */
    active: boolean;

    /** A state setter for the active state */
    setActive: React.Dispatch<React.SetStateAction<boolean>>;

    disabled?: boolean;

    position?: 'bottom-left' | 'bottom-right';
};

/**
 * A fake "select" field which creates a dropdown container on click, for when
 * we want more complicated dropdowns, such as date pickers.
 */
export function DropdownSelect({
    value,
    icon,
    children,
    active,
    setActive,
    disabled,
    position
}: Props) {
    return (
        <Dropdown
            disabled={disabled}
            active={active}
            setActive={setActive}
            buttonProps={{ className: INPUT, icon, children: value }}
            position={position}
        >
            {children}
        </Dropdown>
    );
}
