import React, { useState, useEffect } from 'react';
import "/opt/build/repo/src/common/loading.tsx?resplendence=true";
import cx from 'classnames';

/*
@use "sass:math";
@import 'style';
$inactive: #aaa;
$active: tomato;
$speed: 3s;
$size: 50rem;
$unit: math.div($size, 16);

@keyframes spinner {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(2880deg);
    transform: rotate(2160deg);
  }
}

$spinner-color: currentColor;

@keyframes border {
  0% {
    border-width: 0;
    border-top-color: rgba(0, 0, 0, 0);
    border-bottom-color: rgba(0, 0, 0, 0);
  }
  25% {
    border-top-color: currentColor;
    border-bottom-color: currentColor;
  }
  50% {
    border-width: .2em;
  }
  75% {
    border-top-color: currentColor;
    border-bottom-color: currentColor;
  }
  0% {
    border-width: 0;
    border-top-color: rgba(0, 0, 0, 0);
    border-bottom-color: rgba(0, 0, 0, 0);
  }
}
*/;

const SPINNER = "rx-common-loading-1"/*
  display: block;
  float: left;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  &.loading-spinner-active {
    animation: spinner $speed ease-in-out infinite;
    &:after {
      animation: border $speed ease-in-out infinite;
    }
  }
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    border: 0 solid transparent;
  }
*/;

const LOADING = "rx-common-loading-2"/*
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $gray-10;
  opacity: 0;
  transition-property: opacity;
  transition-duration: 500ms;
  pointer-events: none;
  border-radius: inherit;
  &.show {
    opacity: 1;
    pointer-events: auto;
  }
  background-color: transparent;
  &.background {
    background-color: $gray-10;;
  }
  &.blue{
    background-color: $blue-50;
  }
  &.over-table {
    background-color: fade-out($white, .3);
    color: $gray-90;
  }
  z-index: 10;
  @media print {
    display: none;
  }
*/;

type Props = {
    /** If the spinner should currently be shown. */
    loading?: boolean;

    /** The relative size of the spinner. Defaults to 12. */
    size?: number;

    /**
     * The visual appearance of the spinner.
     * - *clear* – Renders only the spinner and nothing else.
     * - *background* – Renders a solid gray background behind the spinner.
     * - *over-table* – Renders a semi-transparent background behind the spinner.
     */
    kind?: 'clear' | 'background' | 'blue' | 'over-table';
};

/**
 * Shows a loading spinner.
 */
function Loading({ loading = true, size = 12, kind = 'background' }: Props) {
    const [showSpinner, setShowSpinner] = useState(false);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setShowSpinner(true);
        }, 100);
        return () => clearTimeout(timeout);
    });

    return (
        <div
            role={loading ? 'alert' : undefined}
            aria-label={loading ? 'loading' : undefined}
            className={cx(LOADING, kind, { show: loading })}
            style={{ fontSize: Math.round(size * 12) + 'rem' }}
        >
            <div
                className={cx(SPINNER, {
                    'loading-spinner-active': showSpinner && loading
                })}
            />
        </div>
    );
}

export default Loading;
