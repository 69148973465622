import React from 'react';
import { FormattedNumber } from 'react-intl';
import { useMapView } from './use-map-view';

type Props = {
    value: number;
};

const SMALL_DENOMINATION_AMOUNT = {
    USD: 100
};

function Currency({ value }: Props) {
    const mapView = useMapView();

    return (
        <FormattedNumber
            value={value / (SMALL_DENOMINATION_AMOUNT[mapView.currency] ?? 1)}
            style="currency"
            currency={mapView.currency}
        />
    );
}

export default Currency;
