import {
    Container,
    ContainerHeader,
    ContainerMessage,
    LINK
} from 'common/layout';
import React from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { OperatorTag } from 'common/tag';
import "/opt/build/repo/src/page-health/health-summary-page.tsx?resplendence=true";
import cx from 'classnames';
import Icon from 'common/icon';
import { useMapView } from 'common/use-map-view';
import { FeedStatusesData } from 'graphql.g';
import IncidentLog from './health-incident-log';
import useDocumentTitle from 'common/use-document-title';

/*
@import 'style.scss';
*/;

const MDS_VERSION = "rx-page-health-health-summary-page-1"/*
    @include text-body;
    color: $gray-60;
*/;

const FEED_HEADER = "rx-page-health-health-summary-page-2"/*
    display: flex;
    justify-content: space-between;
    padding-bottom: 8rem;
    color: $white;
*/;

const FEED_CONTAINER = "rx-page-health-health-summary-page-3"/*
    margin-bottom: 24rem;
    &:last-child {
        margin-bottom: 0rem;
    }
*/;

const FEED_DETAIL = "rx-page-health-health-summary-page-4"/*
    @include container;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 0rem 0rem;
    & > div {
        @include text-link;
        color: $gray-90;
        padding: 16rem 24rem;
    }
    & > div > div {
        margin-top: 6rem;
    }
    & > div:not(:last-child) {
        border-right: 1rem solid $gray-30;
    }
*/;

const DOT = "rx-page-health-health-summary-page-5"/*
    height: 12rem;
    width: 12rem;
    fill: $green-50;
    border-radius: 50%;
    position: relative;
    margin-right: 6rem;
    &.issues {
        fill: $red-50;
        @include blinking-animation;
    }
    &.no-feed {
        fill: $gray-60;
    }
*/;

const m = defineMessages({
    'status-changes': 'Status Changes',
    trips: 'Trips',
    'document-title': 'Feed Health · Ride Report'
});

interface FeedStatusProps {
    label: string;
    status: boolean | null;
}

function FeedStatus({ label, status }: FeedStatusProps) {
    return (
        <div>
            {label}
            {status ? (
                <div>
                    <Icon icon="Circle" className={DOT} />
                    <FormattedMessage
                        key="Operational"
                        defaultMessage={'Operational'}
                    />
                </div>
            ) : status === false ? (
                <div>
                    <Icon icon="Circle" className={cx(DOT, 'issues')} />
                    <FormattedMessage key="issues" defaultMessage={'Issues'} />
                </div>
            ) : (
                <div>
                    <Icon
                        icon="Circle"
                        className={cx(DOT, {
                            'no-feed': true
                        })}
                    />
                    <FormattedMessage
                        key="no-feed"
                        defaultMessage={'No Feed'}
                    />
                </div>
            )}
        </div>
    );
}

function HealthSummaryPage({ feedStatuses }: FeedStatusesData) {
    const intl = useIntl();
    useDocumentTitle(intl.formatMessage(m[`document-title`]));
    const { name } = useMapView();
    const subject = encodeURIComponent(`Data Health Issue for ${name} `);
    return (
        <>
            <Container>
                <ContainerHeader el="h2">
                    <FormattedMessage
                        key="feed-status"
                        defaultMessage="Feed Status"
                    />
                </ContainerHeader>
                {feedStatuses && feedStatuses.length > 0 ? (
                    feedStatuses.map(feedStatus =>
                        feedStatus ? (
                            <div
                                className={FEED_CONTAINER}
                                key={feedStatus.sourceId}
                            >
                                <hgroup className={FEED_HEADER}>
                                    <h3>
                                        <OperatorTag
                                            key={feedStatus.operator.id}
                                            name={feedStatus.operator.name}
                                        />
                                    </h3>
                                    <div className={MDS_VERSION}>
                                        <FormattedMessage
                                            key="mds-version"
                                            defaultMessage="MDS {mdsVersion}x"
                                            values={{
                                                mdsVersion:
                                                    feedStatus.mdsVersion
                                            }}
                                        />
                                    </div>
                                </hgroup>
                                <div className={FEED_DETAIL}>
                                    <FeedStatus
                                        label={intl.formatMessage(
                                            m['status-changes']
                                        )}
                                        status={feedStatus.statusChangeStatus}
                                    />
                                    <FeedStatus
                                        label={intl.formatMessage(m['trips'])}
                                        status={feedStatus.tripsStatus}
                                    />
                                </div>
                            </div>
                        ) : null
                    )
                ) : (
                    <ContainerMessage>
                        <FormattedMessage
                            key="no-active-feeds"
                            defaultMessage="There are no active feeds for this service area. If you think this is an error, please <link>contact us</link>."
                            values={{
                                link: msg => (
                                    <a
                                        className={LINK}
                                        href={`mailto:support@ridereport.com?subject=${subject}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {msg}
                                    </a>
                                )
                            }}
                        />
                    </ContainerMessage>
                )}
            </Container>
            {<IncidentLog />}
        </>
    );
}

export default HealthSummaryPage;
