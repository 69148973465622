import React from 'react';
import "/opt/build/repo/src/page-policy/dashboard/table.tsx?resplendence=true";
import { FormattedMessage, useIntl, defineMessages } from 'react-intl';
import { PolicyMetricFragment, PolicyDetailsFragment } from 'graphql.g';
import useTippy from 'utils/use-tippy';
import PolicyDashboardRow from './row';
import { OperatorName } from 'constants/operators';

const m = defineMessages({
    'compliance-tooltip':
        'A policy is considered to be in compliance if the target is met on 90% or more of the days.'
});

/*
@import 'style.scss';
*/;

const TOOLTIP = "rx-page-policy-dashboard-table-1"/*
    @include tooltip;
*/;

// NOTE: this styling is a bit hectic, since tables don't support borders
// on <tr> elements. The classes here are set on rows, and they control how
// to properly give each <td> a border so we get the appearance of one
// container around a section of our table.
const TABLE = "rx-page-policy-dashboard-table-2"/*
    @include table;
    width: 100%;

    border-collapse: separate;
    tbody {
        tr {
            height: 72rem;
        }
        tr:not(:last-child) td {
            border-bottom: 1rem solid $gray-30;
        }
        tr:not(:last-child).showingDetails td {
            border-bottom: none;
        }

        td {
            border: 1rem solid transparent;
        }
        tr.showingDetails.mainRowWithBorders td {
            border-top: 1rem solid $gray-30;
        }

        tr.showingDetails.mainRowWithBorders td:first-child {
            border-left: 1rem solid $gray-30;
            border-top-left-radius: 8rem;
        }
        tr.showingDetails.mainRowWithBorders td:last-child {
            border-right: 1rem solid $gray-30;
            border-top-right-radius: 8rem;
        }

        tr.subRowWithBorders td:first-child {
            border-left: 1rem solid $gray-30;
        }
        tr.subRowWithBorders td:last-child {
            border-right: 1rem solid $gray-30;
        }

        tr.lastOfDetails td {
            border-bottom: 1rem solid $gray-30;
        }
        tr.lastOfDetails td:first-child {
            border-bottom-left-radius: 8rem;
        }
        tr.lastOfDetails td:last-child {
            border-bottom-right-radius: 8rem;
        }
    }
*/;

type PolicyDetailsWithMetrics = {
    metric: PolicyMetricFragment | null;
} & PolicyDetailsFragment;
interface PolicyDashboardTableProps {
    policies: PolicyDetailsWithMetrics[];
    operators: Set<OperatorName>;
}

function PolicyDashboardTable({
    policies,
    operators
}: PolicyDashboardTableProps) {
    const intl = useIntl();

    const [complianceTooltipRef] = useTippy(
        intl.formatMessage(m['compliance-tooltip']),
        { placement: 'bottom' }
    );

    return (
        <table className={TABLE}>
            <thead>
                <tr>
                    <th>
                        <FormattedMessage
                            key="table-header-operator"
                            defaultMessage="Operator"
                        />
                    </th>
                    <th>
                        <FormattedMessage
                            key="table-header-area-time"
                            defaultMessage="Area / Time"
                        />
                    </th>
                    <th>
                        <FormattedMessage
                            key="table-header-performance"
                            defaultMessage="Performance"
                        />
                    </th>
                    <th>
                        <span ref={complianceTooltipRef} className={TOOLTIP}>
                            <FormattedMessage
                                key="table-header-compliance"
                                defaultMessage="Compliance"
                            />
                        </span>
                    </th>
                </tr>
            </thead>
            <tbody>
                {policies.map(policy => (
                    <PolicyDashboardRow
                        operators={operators}
                        key={policy.id}
                        policy={policy}
                    />
                ))}
            </tbody>
        </table>
    );
}

export default PolicyDashboardTable;
