import { useState, useCallback } from 'react';

const getCachedState = <T extends any>(key: string, initial: T) => {
    const stored = localStorage.getItem(key);
    if (!stored) return initial;
    try {
        return JSON.parse(stored) as T;
    } catch {
        localStorage.removeItem(key);
        return initial;
    }
};

/**
 * A replacement for React's useState() that caches the result in the user's
 * localstorage.
 *
 * @param key - The localStorage key to use. Should be a value from the LOCAL_STORAGE_KEYS constant
 * @param initial –
 */
const useCachedState = <T>(
    key: string,
    initial: T
): [T, React.Dispatch<React.SetStateAction<T>>] => {
    const [state, setState] = useState<T>(() => getCachedState(key, initial));
    const setCachedState = useCallback(
        (value: T) => {
            localStorage.setItem(key, JSON.stringify(value));
            setState(value);
        },
        [key]
    );
    return [state, setCachedState];
};

export default useCachedState;
