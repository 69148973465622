import React from 'react';
import { useMapMode } from '..';
import { useEffect } from 'react';
import "/opt/build/repo/src/page-explore/page-heatmaps/hex-bins/layers.tsx?resplendence=true";

import { H3HexagonLayer } from '@deck.gl/geo-layers';
import { MapboxLayer } from '@deck.gl/mapbox';
import { Map } from 'mapbox-gl';
import { useConstRef } from 'utils/helpers';
import { HexDatum, Action, State, Color } from '.';
import { TripsData } from './data';

/*
@import 'style.scss';
*/;

const useHexLayers = (
    map: Map | null,
    { hoveredHexId, selectedHexId }: State,
    dispatch: React.Dispatch<Action>,
    max: number,
    min: number,
    tripsData: TripsData,
    colors: Color[],
    privacyThreshold: number | null
) => {
    const mapboxLayer = useConstRef(getInitialHexagonLayer);
    const selectedHexLayer = useConstRef(getInitialSelectedHexLayer);
    const selectedHex = tripsData.find(d => d.hex === selectedHexId) ?? null;
    const [mode] = useMapMode();

    useEffect(() => {
        if (map) {
            map.addLayer(mapboxLayer, 'road-label-simple');
            map.addLayer(selectedHexLayer, 'road-label-simple');

            // sync deckgl cursor with mapbox cursor
            (map as any).__deck.props.getCursor = () =>
                map.getCanvas().style.cursor;

            return () => {
                try {
                    map.removeLayer('hexagon-heatmap');
                    map.removeLayer('hexagon-heatmap-selected');
                } catch {
                    // this means the map is already cleaned up and we don't
                    // need to do anything
                }
            };
        }
    }, [map, mapboxLayer, selectedHexLayer]);

    useEffect(() => {
        if (map && selectedHexLayer) {
            selectedHexLayer.setProps({
                data: selectedHex ? [selectedHex] : null,
                visible: mode === 'trip_starts' || mode === 'trip_ends',
                getFillColor: d =>
                    getHexColor(d, max, min, hoveredHexId, colors)
            });
        }
    });

    useEffect(() => {
        if (map && mapboxLayer) {
            const key = `${max}-${hoveredHexId}-${selectedHexId}`;
            mapboxLayer.setProps({
                data: tripsData,
                visible: mode === 'trip_starts' || mode === 'trip_ends',

                getFillColor: d => {
                    if (d.hex === selectedHexId) return [255, 255, 255, 255];
                    return getHexColor(d, max, min, hoveredHexId, colors);
                },
                onClick: info => {
                    if (selectedHexId)
                        dispatch({ type: 'selectedHexId', value: null });
                    else {
                        let hex = info.object?.hex ?? null;
                        if (
                            privacyThreshold &&
                            info.object?.count <= privacyThreshold
                        )
                            hex = null;
                        dispatch({ type: 'selectedHexId', value: hex });
                    }
                },
                onHover: info => {
                    dispatch({
                        type: 'hoveredHexId',
                        value: info.object?.hex ?? null
                    });
                    if (
                        privacyThreshold &&
                        info.object?.count <= privacyThreshold
                    ) {
                        map.getCanvas().style.cursor = 'default';
                        return true;
                    }
                    map.getCanvas().style.cursor = '';
                },
                updateTriggers: {
                    getFillColor: key,
                    onClick: selectedHexId,
                    onHover: selectedHexId
                }
            });
        }
    });
};

const blend = (a: Color, b: Color, amount: number) => {
    const x = amount;
    const y = 1 - amount;
    const result: Color = [
        y * a[0] + x * b[0],
        y * a[1] + x * b[1],
        y * a[2] + x * b[2],
        y * (a[3] ?? 255) + x * (b[3] ?? 255)
    ];
    return result;
};

const BLANK_HEX_COLOR: Color = [0, 0, 0, 0.1 * 255];

const toPercent = (value: number, max: number, min = 0) => {
    return (value - min) / (max - min);
};
export const toSteps = <T extends any>(
    value: number,
    max: number,
    min: number,
    steps: T[]
) => {
    const step = Math.floor(toPercent(value, max + 0.0001, min) * steps.length);
    return steps[step];
};

const getHexColor = (
    datum: HexDatum,
    max: number,
    min: number,
    hoveredHex: string | null,
    steps: Color[]
) => {
    if (datum.count === 0) return BLANK_HEX_COLOR;
    let step = [
        ...toSteps(
            Math.log1p(datum.count),
            Math.log1p(max),
            Math.log1p(min),
            steps
        )
    ] as Color;
    if (hoveredHex === datum.hex) {
        step[3] = 255;
        step = blend(step, [255, 255, 255], 0.5);
    }
    return step;
};

const getInitialHexagonLayer = () =>
    new MapboxLayer({
        id: 'hexagon-heatmap',
        type: H3HexagonLayer,
        data: [],
        wireframe: false,
        visible: false,
        filled: true,
        stroked: false,
        extruded: false,
        getHexagon: d => d.hex,
        coverage: 0.97,
        pickable: true,
        getFillColor: BLANK_HEX_COLOR,
        depthTest: false,
        transitions: {
            getFillColor: 150
        }
    });

const getInitialSelectedHexLayer = () =>
    new MapboxLayer({
        id: 'hexagon-heatmap-selected',
        type: H3HexagonLayer,
        data: [],
        wireframe: false,
        visible: false,
        filled: true,
        stroked: false,
        extruded: false,
        getHexagon: d => d.hex,
        coverage: 0.3,
        getFillColor: BLANK_HEX_COLOR
    });

export default useHexLayers;
