import { gql } from '@apollo/client';
import {
    DateButtons,
    DateRangeSelect,
    DATE_PICKER_CONTAINER
} from 'common/date-picker';
import { PageBody, PageContent } from 'common/layout';
import Loading from 'common/loading';
import { Title } from 'common/title';
import { useAreas } from 'common/use-areas';
import useDocumentTitle from 'common/use-document-title';
import useLastReportDate from 'common/use-last-report-date';
import { useMapView } from 'common/use-map-view';
import { useVehicleClasses } from 'common/vehicle-classes';
import {
    EarliestReportDateArgs,
    EarliestReportDateData,
    LatestFinalizedReportDateArgs,
    LatestFinalizedReportDateData
} from 'graphql.g';
import { ExistingReports } from 'page-report/report-picker-common';
import { useFinalizedReportDays } from 'page-report/report-picker-daily';
import React from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import "/opt/build/repo/src/page-analyze/index.tsx?resplendence=true";
import { useQueryState } from 'router';
import { LocalDate } from 'utils/date-tools';
import useData from 'utils/use-data';
import useDateRangeQuery from 'utils/use-date-range-query';
import Analyze from './analyze';
import AnalyzeFilter from './analyze-filter';
import MetricSelector from './metric-selector';
import { ChartMetric, CHART_TYPES } from './types';

/*
@import 'style';
*/;

const CONTAINER = "rx-page-analyze-index-1"/*
    @include container;
    padding: 24rem;
    display: flex;
    flex-flow: row wrap;
    gap: 37rem 8rem;
*/;

const m = defineMessages({
    'document-title': 'Analyze · Ride Report'
});
const LATEST_FINALIZED_REPORT_DATE_QUERY = gql`
    query LatestFinalizedReportDate($slug: String!) {
        mapView(slug: $slug) {
            id
            latestFinalizedReportDate
        }
    }
`;

export const EARLIEST_REPORT_DATE_QUERY = gql`
    query EarliestReportDate($slug: String!) {
        mapView(slug: $slug) {
            id
            earliestReportDate
        }
    }
`;

export function useLatestFinalizedReportDate(): LocalDate | null {
    const { slug } = useMapView();
    const data = useData<
        LatestFinalizedReportDateData,
        LatestFinalizedReportDateArgs
    >(LATEST_FINALIZED_REPORT_DATE_QUERY, { slug });
    const date = data?.mapView.latestFinalizedReportDate ?? null;
    return date ? LocalDate.fromDateString(date) : null;
}

export function useEarliestReportDate(): LocalDate | null {
    const { slug } = useMapView();
    const data = useData<EarliestReportDateData, EarliestReportDateArgs>(
        EARLIEST_REPORT_DATE_QUERY,
        { slug }
    );
    const date = data?.mapView.earliestReportDate;
    return date ? LocalDate.fromDateString(date) : null;
}

export function getPreliminaryDates(reports: ExistingReports): Set<string> {
    const reportsAsArray = Object.entries(reports);
    const reportsUnfinalized = reportsAsArray.filter(
        ([, value]) => value === false
    );
    const preliminaryDates = new Set<string>();
    reportsUnfinalized.forEach(([key]) => {
        preliminaryDates.add(key);
    });
    return preliminaryDates;
}

function AnalyzePage() {
    const mapView = useMapView();
    const intl = useIntl();

    useDocumentTitle(intl.formatMessage(m[`document-title`]));
    const lastReportDate = useLastReportDate();
    const earliestReportDate = useEarliestReportDate();
    const latestFinalizedReportDate =
        useLatestFinalizedReportDate() ?? lastReportDate;
    const [dateRange, setDateRange] = useDateRangeQuery([
        lastReportDate.minusDays(14),
        lastReportDate
    ]);
    const [finalizedReportsByDate] = useFinalizedReportDays(
        mapView,
        latestFinalizedReportDate.minusDays(30),
        lastReportDate
    );

    const [metric, setMetric] = useQueryState<ChartMetric>('metric', {
        constrain: {
            options: new Set(CHART_TYPES),
            fallbackValue: 'trips_by_date'
        }
    });

    const preliminaryDates = getPreliminaryDates(finalizedReportsByDate);

    const hasPreliminaryData =
        dateRange[1].isAfter(latestFinalizedReportDate) ||
        preliminaryDates.size !== 0;

    const areas = useAreas();
    const availableVehicleClasses = useVehicleClasses();
    return (
        <PageContent>
            <Title
                title={
                    <FormattedMessage
                        key="page-title"
                        defaultMessage="Analyze Data"
                    />
                }
            ></Title>
            <PageBody>
                <div className={CONTAINER}>
                    <MetricSelector metric={metric} setMetric={setMetric} />
                    <div className={DATE_PICKER_CONTAINER}>
                        <DateRangeSelect
                            selectedDateRange={dateRange}
                            onChange={setDateRange}
                            latestAllowed={lastReportDate}
                            earliestAllowed={earliestReportDate ?? undefined}
                            preliminaryDates={preliminaryDates}
                        />
                        <DateButtons
                            earliestDate={earliestReportDate}
                            selectedDateRange={dateRange}
                            setSelectedDateRange={setDateRange}
                        />
                    </div>
                    <AnalyzeFilter
                        areas={areas}
                        availableVehicleClasses={availableVehicleClasses}
                        chartType={metric}
                    />
                    <Loading loading={!availableVehicleClasses || !areas} />
                </div>
                <Analyze
                    areas={areas}
                    metric={metric}
                    dateRange={dateRange}
                    preliminaryDates={preliminaryDates}
                    hasPreliminaryData={hasPreliminaryData}
                />
            </PageBody>
        </PageContent>
    );
}

export default AnalyzePage;
