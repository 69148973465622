import React, { useState, useEffect, useMemo } from 'react';
import {
    useMapbox,
    getEmptyAreaSource,
    getAreaSourceData,
    AreaWithGeometry,
    getAreaLayers
} from 'common/map';
import { useMapView } from 'common/use-map-view';
import { useGetAreaColor } from 'common/use-areas';
import bbox from '@turf/bbox';
import "/opt/build/repo/src/common/area-map.tsx?resplendence=true";
import Loading from 'common/loading';
import 'mapbox-gl/dist/mapbox-gl.css';
import { Sources } from 'mapbox-gl';
import { gql } from '@apollo/client';
import { AREA_DETAILS_FRAGMENT } from '../page-areas/fragments';
import { useReloadingData } from 'utils/use-data';
import {
    AreaGeometryData,
    AreaGeometryArgs,
    AreaDetailData,
    AreaDetailArgs
} from 'graphql.g';
import { MultiPolygon } from 'geojson';
import cx from 'classnames';
import { AoiInfoFragment } from 'page-report/fragments';

type Props = {
    area: AreaWithGeometry | null;
    /** An optional override for the existing CSS class */
    className?: string;
    loading?: boolean;
    tiny?: boolean;
};

/*
@import 'style.scss';
*/;

export const MAP = "rx-common-area-map-1"/*
    overflow: hidden;
*/;

export const MAP_CONTAINER = "rx-common-area-map-2"/*
    position: relative;
    height: 694rem;
    display: grid;
    overflow: hidden;
    ~* undo the container's padding. I found this easier that making new versions
     * of Container and ContainerHeader *~
    margin: -24rem;
    @include mobile {
        height: calc(100vw - 112rem);
        max-height: 500rem;
    }
    &.tiny {
        height: calc(48rem + 100%);
    }

*/;

function AreaMap({ area, className, loading = false, tiny = false }: Props) {
    const { operators, initialViewBounds } = useMapView();
    const getAreaColor = useGetAreaColor();

    const sources = useMemo(
        () =>
            ({
                'aoi-geographies': area
                    ? {
                          type: 'geojson',
                          data: getAreaSourceData(
                              [area],
                              operators,
                              getAreaColor
                          )
                      }
                    : getEmptyAreaSource()
            } as Sources),
        [area, getAreaColor, operators]
    );
    const layers = useMemo(getAreaLayers, []);

    const [map, container] = useMapbox({
        showControls: !tiny,
        showLabels: !tiny,
        sources,
        layers
    });

    const [loaded, setLoaded] = useState(false);
    useEffect(() => {
        if (map) {
            if (area?.geometry?.coordinates?.length) {
                const box = bbox(area.geometry);
                const [x1, y1, x2, y2] = box;
                const p = 0.01;
                map.fitBounds([x1 - p, y1 - p, x2 + p, y2 + p], {
                    animate: loaded
                });
                let cancelled = false;
                // insert a tiny pause before showing the the map to give mapbox
                // time to render the new layer and zoom to the right boundaries.
                setTimeout(() => {
                    if (!cancelled) {
                        setLoaded(true);
                    }
                }, 100);
                return () => {
                    cancelled = true;
                };
            } else {
                map.fitBounds(initialViewBounds);
                setTimeout(() => {
                    setLoaded(true);
                }, 100);
            }
        }
    }, [area, getAreaColor, initialViewBounds, loaded, map, operators]);

    return (
        <div className={cx(className || MAP_CONTAINER, { tiny })}>
            <Loading
                loading={loading || !map || (!loaded && !!area)}
                size={tiny ? 4 : 12}
            />
            <div className={MAP} ref={container}></div>
        </div>
    );
}

const AREAS_DETAILS_QUERY = gql`
    query AreaDetail($mapview: String!, $area: String!) {
        mapView(slug: $mapview) {
            id
            areaOfInterest(slug: $area) {
                ...AreaDetails
            }
        }
    }
    ${AREA_DETAILS_FRAGMENT}
`;

/**
 * Fetch a single area of interest along with all its geometry, details, and dataRanges.
 */
export function useAreaDetails(areaSlug?: string) {
    const { slug } = useMapView();
    const [data, loading] = useReloadingData<AreaDetailData, AreaDetailArgs>(
        AREAS_DETAILS_QUERY,
        areaSlug ? { mapview: slug, area: areaSlug } : 'skip'
    );

    return useMemo(() => {
        if (loading || areaSlug == null || data == null) return 'loading';
        const area = data.mapView.areaOfInterest;
        if (area == null) return 'not found';
        return {
            ...area,
            geometry: JSON.parse(area.geometryJson) as MultiPolygon
        };
    }, [areaSlug, loading, data]);
}

const AREAS_GEOMETRY_QUERY = gql`
    query AreaGeometry($mapview: String!, $area: String!) {
        mapView(slug: $mapview) {
            id
            areaOfInterest(slug: $area) {
                geometryJson
                ...AoiInfo
            }
        }
    }
    ${AoiInfoFragment}
`;

/**
 * Fetch a single area of interest's info, with the geometry data.
 */
export function useAreaGeometry(areaSlug?: string) {
    const { slug } = useMapView();
    const [data, loading] = useReloadingData<
        AreaGeometryData,
        AreaGeometryArgs
    >(
        AREAS_GEOMETRY_QUERY,
        areaSlug ? { mapview: slug, area: areaSlug } : 'skip'
    );

    return useMemo(() => {
        if (loading || areaSlug == null || data == null) return 'loading';
        const area = data.mapView.areaOfInterest;
        if (area == null) return 'not found';
        return {
            ...area,
            geometry: JSON.parse(area.geometryJson) as MultiPolygon
        };
    }, [areaSlug, loading, data]);
}

export default AreaMap;
