import React, { useEffect } from 'react';
import { gql } from '@apollo/client';
import { ORGANIZATION_INFO_FRAGMENT, UserContext } from 'common/user-context';
import useData from 'utils/use-data';
import { CurrentUserArgs, CurrentUserData } from 'graphql.g';
import UserMasqueradeSelector from 'common/user-masquerade-selector';
import { User } from 'types';
import useCachedState from 'utils/use-cached-state';
import LOCAL_STORAGE_KEYS from 'constants/local-storage';
import { identifyAnalyticsUser, AnalyticsUserInfo } from 'utils/use-analytics';
import LoadingSite from './loading-site';

type Props = { children: React.ReactNode };

export const USER_QUERY = gql`
    query CurrentUser {
        user {
            id
            isStaff
            enabledFeatures
            hasAcceptedTerms
            email
            orgAuth {
                id
                role
                organization {
                    __typename
                    customerStage
                    operatorStage
                    ...OrganizationInfo
                }
            }
        }
    }
    ${ORGANIZATION_INFO_FRAGMENT}
`;

function PageAppUserProvider({ children }: Props) {
    const user = useData<CurrentUserData, CurrentUserArgs>(USER_QUERY)?.user;

    useEffect(() => {
        if (user) {
            const userInfo: AnalyticsUserInfo = user.isStaff
                ? {
                      email: user.email,
                      isStaff: true,
                      organization: null,
                      type: null,
                      operator: null
                  }
                : {
                      email: user.email,
                      isStaff: false,
                      organizationName: user.orgAuth?.organization.name,
                      type:
                          user.orgAuth?.organization.__typename ===
                          'AgencyOrganization'
                              ? 'Agency'
                              : 'Operator',
                      operator:
                          user.orgAuth?.organization.__typename ===
                          'OperatorOrganization'
                              ? user.orgAuth.organization.operatorInfo.name
                              : null,
                      customerStage: user.orgAuth?.organization.customerStage,
                      operatorStage: user.orgAuth?.organization.operatorStage
                  };
            identifyAnalyticsUser(
                user.id.toString(),
                user.hasAcceptedTerms,
                userInfo
            );
            window.rollbar.configure({
                payload: {
                    person: {
                        id: user.id,
                        email: user.email
                    }
                }
            });
        }
    }, [user]);

    const [useDemoFeatures, setUseDemoFeatures] = useCachedState(
        LOCAL_STORAGE_KEYS.demoFeatures,
        false
    );
    if (!user) return <LoadingSite />;
    if (!user.orgAuth) {
        if (user.isStaff) {
            return <UserMasqueradeSelector currentUser={user} />;
        } else {
            window.rollbar.error(
                `User ${user.email} is not a member of any organizations.`
            );
            localStorage.removeItem(LOCAL_STORAGE_KEYS.authToken);
            return (
                <span>
                    You are not a member of any organizations. Please contact{' '}
                    <a href="mailto:support@ridereport.com">
                        support@ridereport.com
                    </a>
                    .
                </span>
            );
        }
    }
    const userContext: User = {
        ...user,
        orgAuth: user.orgAuth,
        useDemoFeatures,
        setUseDemoFeatures
    };
    return (
        <UserContext.Provider value={userContext}>
            {children}
        </UserContext.Provider>
    );
}

export default PageAppUserProvider;
