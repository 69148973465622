/** Parse CSV columns with custom logic, usually for addresses */
export const customColumnParsers = {
    // Take out the arbitrary street direction from the address, e.g. 1701 N Wynkoop St -> 1701 Wynkoop St
    'Remove street directions: 1701 N Wynkoop -> 1701 Wynkoop (Denver 311)': (
        address: string
    ) => {
        const directions = ['N', 'S', 'E', 'W', 'NE', 'NW', 'SE', 'SW'];
        const parts = address.split(' ');
        if (directions.includes(parts[1])) parts.splice(1, 1);
        return parts.join(' ');
    }
};
