import React from 'react';
import "/opt/build/repo/src/root/navigation-menu.tsx?resplendence=true";
import cx from 'classnames';
import Icon, { IconName } from '../common/icon';
import { useMapView } from '../common/use-map-view';
import { FormattedMessage } from 'react-intl';
import { AbsoluteLink, useQueryState } from 'router';
import { useUser } from 'common/user';
import { usePermissionToAccessCustomData } from 'page-custom-data/permissions';

/*
@import 'style';
*/;

const NAVIGATION_HEADER_WRAPPER = "rx-root-navigation-menu-1"/*
    position: relative;
    width: 64px;
    @include mobile-tiny {
        width: 0px;
    }
    z-index: 12;
*/;

const NAVIGATION_HEADER_CONTAINER = "rx-root-navigation-menu-2"/*
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    z-index: 2;
    background-color: $white;
    @include border-bottom-1px;
    transition: all 150ms;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include not-mobile-tiny {
        &:hover {
            width: 160px;
        }
        width: 64px;
    }
    @include mobile-tiny {
        position: fixed;
        width: 100vw;
        left: -100vw;
        top: 64px;
        height: calc(100vh - 64px);
        &.show {
            left: 0;
        }
    }
*/;

const NAVIGATION_HEADER = "rx-root-navigation-menu-3"/*
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-flow: row;
    grid-gap: 20px;
    align-content: start;
    justify-items: stretch;
    padding: 14px;
*/;

const LINK = "rx-root-navigation-menu-4"/*
    @include navigation-link;
    font-size: 16px;
    transition: all 150ms;
    overflow: hidden;
    border-radius: 4px;
    height: 36px;
    padding: 0 6px;
    display: grid;
    grid: 1fr / auto auto;
    grid-gap: 8px;
    align-items: center;
    justify-content: start;
    svg {
        color: $gray-60;
    }
    &.active svg {
        color: $white;
    }
*/;
const ICON = "rx-root-navigation-menu-5"/*
    font-size: 24px;
*/;

type NavigationHeaderLinkProps = {
    icon: IconName;
    path: string;
    params?: Record<string, string>;
    onClick: () => void;
    children: React.ReactNode;
};

function NavigationHeaderLink({
    path,
    icon,
    onClick,
    params,
    children
}: NavigationHeaderLinkProps) {
    const mapView = useMapView();
    return (
        <AbsoluteLink
            className={LINK}
            onClick={onClick}
            nav
            to={{
                pathname: `/${mapView.slug}/${path}`,
                search: params && new URLSearchParams(params).toString()
            }}
        >
            <Icon icon={icon} className={ICON} />
            {children}
        </AbsoluteLink>
    );
}

type Props = {
    /** Whether the sidebar should expanded on mobile devices. */
    expandOnMobile: boolean;
    setExpandOnMobile: (value: boolean) => void;
};

/**
 * The page header that allows the user to choose which of the top-level
 * features they want to use.
 */
function NavigationHeader({ expandOnMobile, setExpandOnMobile }: Props) {
    const onClick = () => setExpandOnMobile(false);
    const { hasFees } = useMapView();
    const { useDemoFeatures, isStaff } = useUser();
    const [dates] = useQueryState('dates', {});
    const dateParams = dates ? { dates: dates } : undefined;

    const canAccessCustomData = usePermissionToAccessCustomData();

    return (
        <div className={NAVIGATION_HEADER_WRAPPER}>
            <div
                className={cx(NAVIGATION_HEADER_CONTAINER, {
                    show: expandOnMobile
                })}
            >
                <nav className={NAVIGATION_HEADER}>
                    <NavigationHeaderLink
                        icon="Report"
                        onClick={onClick}
                        path="report"
                    >
                        <FormattedMessage
                            key="report-link"
                            defaultMessage="Report"
                        />
                    </NavigationHeaderLink>
                    <NavigationHeaderLink
                        icon="Map"
                        onClick={onClick}
                        path="realtime"
                    >
                        <FormattedMessage
                            key="realtime-link"
                            defaultMessage="Realtime"
                        />
                    </NavigationHeaderLink>
                    <NavigationHeaderLink
                        icon="Heatmap"
                        onClick={onClick}
                        path="heatmaps"
                        params={dateParams}
                    >
                        <FormattedMessage
                            key="heatmaps-link"
                            defaultMessage="Heatmaps"
                        />
                    </NavigationHeaderLink>
                    <NavigationHeaderLink
                        icon="Navigation-Chart"
                        onClick={onClick}
                        path="analyze"
                        params={dateParams}
                    >
                        <FormattedMessage
                            key="analyze-link"
                            defaultMessage="Analyze"
                        />
                    </NavigationHeaderLink>
                    {hasFees && (
                        <NavigationHeaderLink
                            icon="Fees"
                            onClick={onClick}
                            path="fees"
                        >
                            <FormattedMessage
                                key="fees-link"
                                defaultMessage="Fees"
                            />
                        </NavigationHeaderLink>
                    )}
                    <NavigationHeaderLink
                        icon="Policy"
                        onClick={onClick}
                        path="policy/dashboard"
                        params={dateParams}
                    >
                        <FormattedMessage
                            key="policy-link"
                            defaultMessage="Policy"
                        />
                    </NavigationHeaderLink>
                    <NavigationHeaderLink
                        icon="Area"
                        onClick={onClick}
                        path="areas"
                    >
                        <FormattedMessage
                            key="areas-link"
                            defaultMessage="Areas"
                        />
                    </NavigationHeaderLink>
                    <NavigationHeaderLink
                        icon="Audit"
                        onClick={onClick}
                        path="health/status"
                    >
                        <FormattedMessage
                            key="health-link"
                            defaultMessage="Health"
                        />
                    </NavigationHeaderLink>
                    {isStaff && useDemoFeatures && (
                        <NavigationHeaderLink
                            icon="No-Car"
                            onClick={onClick}
                            path="mode-shift"
                        >
                            <FormattedMessage
                                key="mode-shift-link"
                                defaultMessage="ModeShift"
                            />
                        </NavigationHeaderLink>
                    )}
                    {canAccessCustomData && (
                        <NavigationHeaderLink
                            icon="Spreadsheet"
                            onClick={onClick}
                            path="custom-data"
                        >
                            <FormattedMessage
                                key="custom-data-link"
                                defaultMessage="Datasets"
                            />
                        </NavigationHeaderLink>
                    )}
                </nav>
            </div>
        </div>
    );
}

export default NavigationHeader;
