import {
    MeasurementUnitSystemType,
    PreviewTripFilterArgs,
    PreviewTripFilterData,
    VehicleClass
} from 'graphql.g';

import React from 'react';
import { useIntl } from 'utils/use-intl';
import * as strings from 'strings';
import Icon from 'common/icon';
import { ICON_FOR_VEHICLE_CLASS } from 'common/vehicle-classes';
import SelectNumberField from 'common/select-number-field';
import { defineMessages, FormattedMessage, FormattedNumber } from 'react-intl';
import "/opt/build/repo/src/page-health/page-trip-filter-new-row.tsx?resplendence=true";
import IncludedPercent from './page-trip-filter-included-pct';
import { gql } from '@apollo/client';
import { useGraphql } from 'utils/use-data';
import { messages as unitMessages, UNIT_IN_COMMON_VALUE } from 'common/units';
import { useMapView } from 'common/use-map-view';
import {
    Filter,
    filterToInput,
    FilterValue,
    filterValueToCommonValue
} from './page-trip-filter-common';

/*
@import 'style';
*/;

const FILTER_COLUMN = "rx-page-health-page-trip-filter-new-row-1"/*
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    margin-top: -25rem;
*/;

const TO = "rx-page-health-page-trip-filter-new-row-2"/*
    margin: 32rem 8rem 8rem 8rem;
    flex: 0 0 auto;
*/;

type UpdateFilter = (
    filter: keyof Filter,
    vehicleClass: VehicleClass,
    value: FilterValue | null
) => void;

type Props = {
    vehicleClass: VehicleClass;
    filter: Filter;
    updateFilter: UpdateFilter;
};

const messages = defineMessages({
    'no-min': 'No Min',
    'no-max': 'No Max'
});

const PREVIEW_QUERY = gql`
    query PreviewTripFilter($slug: String!, $filter: CreateTripFilterInput!) {
        mapView(slug: $slug) {
            id
            tripFilterPreviewForVehicleClass(filter: $filter)
        }
    }
`;

function usePreviewExcludedPercent(
    vehicleClass: VehicleClass,
    filter: Filter
): Error | number | 'loading' | null {
    const { slug } = useMapView();
    const [data, loading, error] = useGraphql<
        PreviewTripFilterData,
        PreviewTripFilterArgs
    >(PREVIEW_QUERY, { slug, filter: filterToInput(vehicleClass, filter) });
    if (error) return error;
    if (!data || loading) return 'loading';
    return data.mapView.tripFilterPreviewForVehicleClass;
}

function NewTripFilterRow({ vehicleClass, filter, updateFilter }: Props) {
    const intl = useIntl();
    const excludedPercent = usePreviewExcludedPercent(vehicleClass, filter);
    return (
        <tr>
            <td>
                <Icon icon={ICON_FOR_VEHICLE_CLASS[vehicleClass]} />
                {intl.formatMessage(strings.vehicleClasses[vehicleClass])}
            </td>
            <td>
                <div className={FILTER_COLUMN}>
                    <FilterField
                        updateFilter={updateFilter}
                        filter="minDistance"
                        filters={filter}
                        vehicleClass={vehicleClass}
                    />
                    <span className={TO}>–</span>
                    <FilterField
                        updateFilter={updateFilter}
                        filter="maxDistance"
                        filters={filter}
                        vehicleClass={vehicleClass}
                    />
                </div>
            </td>
            <td>
                <div className={FILTER_COLUMN}>
                    <FilterField
                        updateFilter={updateFilter}
                        filter="minDuration"
                        filters={filter}
                        vehicleClass={vehicleClass}
                    />
                    <span className={TO}>–</span>
                    <FilterField
                        updateFilter={updateFilter}
                        filter="maxDuration"
                        filters={filter}
                        vehicleClass={vehicleClass}
                    />
                </div>
            </td>
            <td>
                <IncludedPercent value={excludedPercent} />
            </td>
        </tr>
    );
}

const FILTER_FIELD = "rx-page-health-page-trip-filter-new-row-3"/*
    &:not(:focus-within):not(:hover) > .imperial {
        display: none;
    }
    > .imperial {
        @include text-label;
        color: $gray-70;
        margin-left: 8rem;
    }
    height: 79.2rem;
    margin-bottom: -19.2rem;
    flex: 0 1 104rem;
*/;

type FilterFieldProps = {
    updateFilter: UpdateFilter;
    filter: 'maxDistance' | 'minDistance' | 'maxDuration' | 'minDuration';
    filters: Filter;
    vehicleClass: VehicleClass;
};

function FilterField({
    updateFilter,
    filter,
    filters,
    vehicleClass
}: FilterFieldProps) {
    const intl = useIntl();
    const mapView = useMapView();
    const isDistance = filter === 'maxDistance' || filter === 'minDistance';
    const units = isDistance
        ? (['meters', 'kilometers'] as const)
        : (['seconds', 'minutes', 'hours', 'days'] as const);
    const isMin = filter === 'minDistance' || filter === 'minDuration';
    const filterValue = filters[filter];

    let min: number | undefined;
    if (filterValue && !isMin) {
        // We want to ensure that maximum set is always greater than the
        // minimum. However, the min and the max might be in different units. We
        // can conver the minimum value to the smallest unit...
        const minValue = filterValueToCommonValue(
            filters[filter.replace('max', 'min')]
        );
        // .. and then convert that into the max filter's unit to determine the
        // smallest allowed value for the filter.
        if (minValue !== null) {
            min = Math.ceil(minValue / UNIT_IN_COMMON_VALUE[filterValue.unit]);
        }
    }

    return (
        <div className={FILTER_FIELD}>
            <SelectNumberField
                value={
                    filterValue
                        ? {
                              number: filterValue.value,
                              selection: filterValue.unit
                          }
                        : null
                }
                min={min}
                id={`${vehicleClass}-${filter}-field`}
                nullName={intl.formatMessage(
                    isMin ? messages['no-min'] : messages['no-max']
                )}
                onChange={value =>
                    updateFilter(
                        filter,
                        vehicleClass,
                        value
                            ? { value: value.number, unit: value.selection }
                            : null
                    )
                }
                options={units.map(unit => ({
                    key: unit,
                    formattedName: intl.formatMessage(unitMessages[unit]),
                    shortFormattedName: intl.formatMessage(
                        unitMessages[unit + '-short']
                    )
                }))}
                placeholder="Min"
                label={
                    isMin ? (
                        <FormattedMessage
                            key="min-label"
                            defaultMessage="Min"
                        />
                    ) : (
                        <FormattedMessage
                            key="max-label"
                            defaultMessage="Max"
                        />
                    )
                }
            />
            {mapView.measurementSystem === MeasurementUnitSystemType.IMPERIAL &&
                isDistance &&
                filterValue && (
                    <div className="imperial">
                        <FormattedMessage
                            key="imperial-approximation"
                            defaultMessage="~ {value} {unit}"
                            values={toFriendlyImperialUnit(
                                UNIT_IN_COMMON_VALUE[filterValue.unit] *
                                    filterValue.value *
                                    3.28084
                            )}
                        />
                    </div>
                )}
        </div>
    );
}

const FEET_PER_MILE = 5280;

function toFriendlyImperialUnit(feet: number) {
    if (feet > FEET_PER_MILE * 0.1) {
        return {
            value: (
                <FormattedNumber
                    value={feet / FEET_PER_MILE}
                    maximumSignificantDigits={2}
                />
            ),
            unit: 'mi'
        };
    } else {
        return {
            value: (
                <FormattedNumber value={feet} maximumSignificantDigits={2} />
            ),
            unit: 'ft'
        };
    }
}

export default NewTripFilterRow;
