import React, { useState, useMemo } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import AppHeader from './app-header';
import {
    PageContent,
    PageBody,
    Container,
    ContainerHeader,
    Button
} from 'common/layout';
import "/opt/build/repo/src/root/page-settings.tsx?resplendence=true";
import { gql } from '@apollo/client';
import useData from 'utils/use-data';
import { UsersListArgs, UsersListData, UserListInfoFragment } from 'graphql.g';
import Loading from 'common/loading';
import orderBy from 'lodash/orderBy';
import Icon from 'common/icon';
import { useUser } from 'common/user';
import { IntlProvider } from 'utils/use-intl';
import Modal from 'common/modal';
import AddUserForm from './page-settings-add-user-form';
import { getDomainFromEmailAddress } from 'utils/helpers';
import PageSettingsUser, { USER_LIST_INFO } from './page-settings-user';
import { ErrorCallout } from 'common/error-message';
import Callout from 'common/callout';
import DeleteVerification from './page-settings-delete-verification';
import { Title } from 'common/title';
import useDocumentTitle from 'common/use-document-title';
import { useSorting } from 'common/table-sorting';
import { sendEventAnalytics } from 'utils/use-analytics';

/*
@import 'style.scss';
*/;

const TABLE = "rx-root-page-settings-1"/*
    @include table;
    width: 100%;
    table-layout: fixed;

    thead td {
        width: 44rem;
    }
    thead th button {
        cursor: initial;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        svg {
            font-size: 24rem;
        }
    }
    tbody td:nth-child(2) div {
        justify-items: start;
    }
*/;

const USERS_QUERY = gql`
    query UsersList($orgId: ID!) {
        organization(orgId: $orgId) {
            id
            users {
                ...UserListInfo
            }
        }
    }
    ${USER_LIST_INFO}
`;

type Status =
    | {
          type: 'success';
          action: 'delete' | 'add';
          email: string;
      }
    | {
          type: 'error';
          error: Error;
      };

type ModalState =
    | { type: 'add' }
    | { type: 'delete'; user: UserListInfoFragment };

const m = defineMessages({
    'document-title': 'Settings · Ride Report'
});

function SettingsPage() {
    const intl = useIntl();
    useDocumentTitle(intl.formatMessage(m[`document-title`]));
    const [sorting, onSortHeaderClick] = useSorting<'email' | 'role'>();
    const [modalState, setModalState] = useState<null | ModalState>(null);
    const [status, setStatus] = useState<Status | null>(null);
    const user = useUser();
    const usersData = useData<UsersListData, UsersListArgs>(USERS_QUERY, {
        orgId: user.orgAuth.organization.id
    });

    const users = useMemo(() => {
        const list = usersData?.organization.users ?? [];
        return orderBy(list, [...sorting.keys()], [...sorting.values()]);
    }, [sorting, usersData]);

    // Get a list of all the current email domains in use so we can warn users when
    // they try to add users with a different domain.
    const presentDomains = new Set(
        users.map(u => getDomainFromEmailAddress(u.email)).filter(u => u)
    ) as Set<string>;

    return (
        <IntlProvider locale={user.orgAuth.organization.locale}>
            <AppHeader />
            <PageContent>
                <PageBody>
                    <Title
                        title={
                            <FormattedMessage
                                key="title"
                                defaultMessage="Settings"
                            />
                        }
                    >
                        {user.orgAuth.role === 'Administrator' && (
                            <Button
                                color="blue"
                                onClick={() => setModalState({ type: 'add' })}
                            >
                                <FormattedMessage
                                    key="add-user-button"
                                    defaultMessage="Add User"
                                />
                            </Button>
                        )}
                    </Title>
                    <Modal isModalShowing={modalState !== null}>
                        {modalState?.type === 'add' ? (
                            <AddUserForm
                                onCancel={() => setModalState(null)}
                                onSuccess={email => {
                                    setModalState(null);
                                    setStatus({
                                        type: 'success',
                                        action: 'add',
                                        email
                                    });
                                    sendEventAnalytics({
                                        event_name: 'admin event',
                                        action: 'user added',
                                        name: email
                                    });
                                }}
                                domains={presentDomains}
                            />
                        ) : modalState?.type === 'delete' ? (
                            <DeleteVerification
                                onCancel={() => setModalState(null)}
                                onSuccess={email => {
                                    setModalState(null);
                                    setStatus({
                                        type: 'success',
                                        action: 'delete',
                                        email
                                    });
                                    sendEventAnalytics({
                                        event_name: 'admin event',
                                        action: 'user deleted',
                                        name: email
                                    });
                                }}
                                user={modalState.user}
                            />
                        ) : null}
                    </Modal>
                    {user.orgAuth.role === 'User' && (
                        <Callout color="blue">
                            {users?.every(user => user.role === 'User') ? (
                                <FormattedMessage
                                    key="not-permitted-callout-no-admins"
                                    defaultMessage="If you need to add, remove, or edit user permissions, please contact <MailLink>{supportEmail}</MailLink>."
                                    values={{
                                        MailLink: msg => (
                                            <a href={`mailto:${msg}`}>{msg}</a>
                                        ),
                                        supportEmail: 'support@ridereport.com'
                                    }}
                                />
                            ) : (
                                <FormattedMessage
                                    key="not-permitted-callout"
                                    defaultMessage="If you need to add, remove, or edit user permissions, please contact your administrator."
                                />
                            )}
                        </Callout>
                    )}
                    {status &&
                        (status.type === 'success' ? (
                            <Callout
                                color="blue"
                                dismiss={() => setStatus(null)}
                            >
                                {status.action === 'add' ? (
                                    <FormattedMessage
                                        key="add-user-success"
                                        defaultMessage="{email} was added to your organization. An email has been sent to the user to finish setting up their account."
                                        values={{
                                            email: <b>{status.email}</b>,
                                            // by using window.location.hostname, we don't
                                            // have to worry about updating this string if
                                            // we change the dashboard's URL again :)
                                            // It does mean that this will say "localhost"
                                            // during development though!
                                            dashboardUrl:
                                                window.location.hostname
                                        }}
                                    />
                                ) : (
                                    <FormattedMessage
                                        key="delete-user-sucess"
                                        defaultMessage="{email} was removed from your organization."
                                        values={{ email: status.email }}
                                    />
                                )}
                            </Callout>
                        ) : (
                            <ErrorCallout
                                error={status.error}
                                onDismiss={() => setStatus(null)}
                            />
                        ))}
                    <Container>
                        <Loading kind="over-table" loading={!usersData} />
                        <ContainerHeader>
                            <FormattedMessage
                                key="user-permissions-title"
                                defaultMessage="User Permissions"
                            />
                        </ContainerHeader>
                        <table className={TABLE}>
                            <thead>
                                <tr>
                                    <th>
                                        <button
                                            onClick={() =>
                                                onSortHeaderClick('email')
                                            }
                                        >
                                            <FormattedMessage
                                                key="email-column"
                                                defaultMessage="Email"
                                            />
                                            {sorting.get('email') &&
                                                'email' ===
                                                    [...sorting.keys()][0] && (
                                                    <Icon
                                                        icon={
                                                            sorting.get(
                                                                'email'
                                                            ) === 'desc'
                                                                ? 'Down'
                                                                : 'Up'
                                                        }
                                                    />
                                                )}
                                        </button>
                                    </th>
                                    <th>
                                        <button
                                            onClick={() =>
                                                onSortHeaderClick('role')
                                            }
                                        >
                                            <FormattedMessage
                                                key="role-column"
                                                defaultMessage="Role"
                                            />
                                            {sorting.get('role') &&
                                                'role' ===
                                                    [...sorting.keys()][0] && (
                                                    <Icon
                                                        icon={
                                                            sorting.get(
                                                                'role'
                                                            ) === 'desc'
                                                                ? 'Down'
                                                                : 'Up'
                                                        }
                                                    />
                                                )}
                                        </button>
                                    </th>
                                    <td />
                                </tr>
                            </thead>
                            <tbody>
                                {users?.map(user => (
                                    <PageSettingsUser
                                        user={user}
                                        onError={error =>
                                            setStatus({
                                                type: 'error',
                                                error
                                            })
                                        }
                                        onDelete={() =>
                                            setModalState({
                                                type: 'delete',
                                                user
                                            })
                                        }
                                        key={user.id}
                                    />
                                ))}
                            </tbody>
                        </table>
                    </Container>
                </PageBody>
            </PageContent>
        </IntlProvider>
    );
}

export default SettingsPage;
