import React from 'react';
import { defineMessages } from 'react-intl';
import { useIntl } from 'utils/use-intl';
import {
    convertToSlug,
    sortAreas,
    sortOperators,
    getAreaSections
} from 'utils/helpers';
import Navs, { Section } from 'common/side-nav';
import { AoiInfoFragment } from 'graphql.g';
import { IconName } from 'common/icon';

const messages = defineMessages({
    'no-report-tooltip':
        'There is no available report for this operator on this date.',
    'summary-link': 'Summary',
    pages: 'Pages',
    'areas-pages': 'Areas',
    'operators-pages': 'Operators',
    'no-area-tooltip':
        'There is no available report for this area on this date.',
    'archived-area':
        'This area has been archived and is only available in historical reports.',
    'municipality-boundary':
        'This zone represents the municipality boundary of the service area.'
});

export type AoiLink = AoiInfoFragment & { disabled: boolean };

type Props = {
    operators: { name: string; disabled?: boolean }[] | null;
    areas: AoiLink[] | null;
};

function ReportNavs({ operators, areas }: Props) {
    const intl = useIntl();
    const sections: Section[] = [];
    if (operators) {
        sections.push({
            name: intl.formatMessage(messages['operators-pages']),
            links: sortOperators(operators).map(op => {
                const tooltip = op.disabled
                    ? intl.formatMessage(messages['no-report-tooltip'])
                    : null;
                return {
                    name: op.name,
                    disabled: op.disabled,
                    tooltip,
                    pathname: '/for/' + convertToSlug(op.name),
                    preserveQuery: true
                };
            })
        });
    }
    if (areas && areas.length) {
        sections.push({
            name: intl.formatMessage(messages['areas-pages']),
            links: getAreaSections(sortAreas(areas)).map(section =>
                section.map(area => ({
                    name: area.name,
                    pathname: '/in/' + area.slug,
                    preserveQuery: true,
                    disabled: area.disabled,
                    tooltip: area.disabled
                        ? intl.formatMessage(messages['no-area-tooltip'])
                        : area.archivedAt
                        ? intl.formatMessage(messages['archived-area'])
                        : area.isMunicipalityBoundary
                        ? intl.formatMessage(messages['municipality-boundary'])
                        : null,
                    icon: area.archivedAt
                        ? ('Archive' as IconName)
                        : area.isMunicipalityBoundary
                        ? ('MunBoundary' as IconName)
                        : undefined
                }))
            ),
            searchable: true
        });
    }
    return (
        <Navs
            title={intl.formatMessage(messages.pages)}
            links={[
                {
                    name: intl.formatMessage(messages['summary-link']),
                    pathname: '/',
                    exact: true,
                    preserveQuery: true
                }
            ]}
            sections={sections}
        />
    );
}

export default ReportNavs;
