import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import cx from 'classnames';
import "/opt/build/repo/src/common/common-chart.tsx?resplendence=true";
import { orderBy, truncate } from 'lodash';
import Icon from 'common/icon';
import { Payload as ToolTipPayload } from 'recharts/types/component/DefaultTooltipContent';
import { LocalDate } from 'utils/date-tools';

/*
@import '../style.scss';
*/;

export const ANIMATION_DURATION = 1000;

export const CHART_MESSAGES = defineMessages({
    'tick-label-with-percentage': '({percentageOfTotal, number}% of total)',
    'vehicles-tick-label':
        '{count, number} {count, plural, one {vehicle} other {vehicles}} on {day, date, dayOfWeekReportDay}',
    'trips-tick-label':
        '{count, number} {count, plural, one {trip} other {trips}} on {day, date, dayOfWeekReportDay}',
    'hours-tick-label':
        '{count, number} {count, plural, one {hour} other {hours}} on {day, date, dayOfWeekReportDay}',
    'trips_per_vehicle-tick-label':
        '{count, number} {count, plural, one {trip per vehicle} other {trips per vehicle}} on {day, date, dayOfWeekReportDay}',
    'seconds-tick-label':
        '{count, number} {count, plural, one {second} other {seconds}} on {day, date, dayOfWeekReportDay}',
    'no-data-tick-label': 'No data for {day, date, dayOfWeekReportDay}',
    trips: 'trips',
    vehicles: 'vehicles',
    'vehicles-tooltip':
        '{count, number} {count, plural, one {vehicle} other {vehicles}}',
    'vehicles-percent-tooltip': '{count, number}% of vehicles',
    trips_per_vehicle: 'trips per vehicle',
    seconds: 'seconds',
    hours: 'hours',
    'hours-abbreviation': 'hours',
    'minutes-abbreviation': 'min',
    'kilometers-abbreviation': 'km',
    'miles-abbreviation': 'mi',
    'hour-abbreviation': 'hr',
    miles: 'miles',
    kilometers: 'km',
    co2_tons: 'tons',
    minutes: 'min'
});

export const AXIS_LABELS = {
    fontSize: '16rem'
};

const TOOLTIP_HEADER = "rx-common-common-chart-1"/*
    padding: 4rem 8rem;
*/;

const tooltipMessages = defineMessages({
    'tick-label-date': '{day, date, dayOfWeekReportDay}',
    'no-data-tick-label': 'No data for {day, date, dayOfWeekReportDay}',
    'total-count': 'Total {count, number}'
});

export const TooltipDateHeader = ({ label }: { label: string }) => {
    const intl = useIntl();
    return (
        <p className={TOOLTIP_HEADER}>
            {intl.formatMessage(tooltipMessages[`tick-label-date`], {
                day: LocalDate.fromDateString(label).toDate()
            })}
        </p>
    );
};

export const DOT = "rx-common-common-chart-2"/*
    height: 12rem;
    width: 12rem;
    border-radius: 50%;
    margin: 0rem 8rem;
*/;

const TOOLTIP_WRAPPER = "rx-common-common-chart-3"/*
    background-color: rgba(35, 42, 53, 0.9); //grey-90
    color: $gray-30;
    border-radius:4rem;
    z-index: 99;
    font-size: 16rem;
    width: fit-content;
    &.label > div {
        border-top: 1rem solid $gray-70;
    }
*/;

const TOOLTIP_DETAIL = "rx-common-common-chart-4"/*
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 4rem 0rem;
    white-space: nowrap;
*/;

const TOOLTIP_VALUE = "rx-common-common-chart-5"/*
    padding: 0rem 8rem 0rem 16rem;
*/;

type TooltipProps = {
    active: boolean;
    payload: ToolTipPayload<number, string>[];
    showLabel?: boolean;
};

export const CommonTooltip = ({
    active,
    payload,
    showLabel = false
}: TooltipProps) => {
    const intl = useIntl();

    const label = showLabel && payload.length >= 1 && payload[0].payload.xValue;

    if (active && payload.length) {
        return (
            <div className={cx(TOOLTIP_WRAPPER)}>
                {label && <TooltipDateHeader label={label} />}
                {orderBy(payload, pl => pl.value, 'desc').map((payload, i) => {
                    const unit = payload.unit?.toLocaleString() ?? '';
                    const value =
                        payload.value &&
                        `${intl.formatMessage(
                            CHART_MESSAGES[`${unit}-tooltip`],
                            {
                                count: payload.value
                            }
                        )}`;

                    const color = payload.color;

                    return (
                        <div key={i} className={TOOLTIP_DETAIL}>
                            <div>
                                <Icon
                                    icon="Circle"
                                    className={DOT}
                                    style={{ color: color }}
                                />
                                {truncate(payload.name, {
                                    length: 14
                                })}
                            </div>
                            <div className={TOOLTIP_VALUE}>{value}</div>
                        </div>
                    );
                })}
            </div>
        );
    }
    return null;
};
