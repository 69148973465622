import { OperatorName } from 'constants/operators';

export type Report = {
    id: string;
    operator: {
        name: OperatorName;
    } | null;
} | null;

// determine whether a report has an operator name
export function doesReportHaveOperatorName(report: Report) {
    return report?.operator?.name;
}

// sort reports alphabetically by operator name
export function sortReportsByOperatorName(reportA: Report, reportB: Report) {
    const nameA = reportA?.operator?.name;
    const nameB = reportB?.operator?.name;
    if (nameA && nameB) {
        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }
        return 0;
    }
    return 0;
}
