import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import { useMapView } from 'common/use-map-view';
import { PolicyFormProps } from './types';
import { getAvailableOperatorIds } from './utils';
import { AllOrSingleOperatorSelect } from './operator-selectors';
import { NumberInput } from './common';

function TripStartForm({
    policy,
    setPolicy,
    formErrors,
    setFormErrors
}: PolicyFormProps) {
    const { currency, operators } = useMapView();
    const availableOperatorIds = getAvailableOperatorIds(
        operators,
        policy.area
    );

    useEffect(() => {
        const hasRateAmountError =
            policy.rateAmount == null || policy.rateAmount <= 0;

        if (formErrors.rateAmount !== hasRateAmountError) {
            setFormErrors({
                ...formErrors,
                rateAmount: hasRateAmountError
            });
        }
    }, [policy.rateAmount, formErrors, setFormErrors, availableOperatorIds]);

    return (
        <>
            <NumberInput
                width={96}
                label={
                    <FormattedMessage
                        key="trip-start-fee-label"
                        defaultMessage="Trip Start Fee:"
                    />
                }
                numberLabel={currency === 'USD' ? '$' : undefined}
                id="trip-start-input"
                value={policy.rateAmount ?? ''}
                min="0"
                step="0.01"
                onChange={newValue => {
                    const amount = parseFloat(newValue);
                    setPolicy({
                        ...policy,
                        rateAmount: Number.isNaN(amount) ? undefined : amount
                    });
                }}
            />

            <AllOrSingleOperatorSelect
                policy={policy}
                setPolicy={setPolicy}
                formErrors={formErrors}
                setFormErrors={setFormErrors}
            />
        </>
    );
}

export default TripStartForm;
