import "/opt/build/repo/src/page-report/report-picker-common.tsx?resplendence=true";
import { LocalMonth, LocalDate } from 'utils/date-tools';
import { gql } from '@apollo/client';
import { useReloadingData, useQueryFn } from 'utils/use-data';
import {
    AggregatedReportsListData,
    AggregatedReportsListArgs,
    AggregatedReportPeriod
} from 'graphql.g';
import { MapView } from 'types';
import { flatMap } from 'utils/helpers';

/*
@import "style";
@import "~common/date-picker-styles";
*/;

export type ExistingReportsData<Operator> = {
    mapView: {
        operators: Operator[];
    };
};

export type ExistingReport = { reportDate: string; finalized: boolean };
export type ExistingReports = Record<string, boolean>;

/**
 * @returns a dictionary where the keys are report dates, and the value is true
 * if the report for that day is finalized
 */
export function getFinalizedReportDays(
    reports: ExistingReport[]
): ExistingReports {
    const result: ExistingReports = {};
    for (const report of reports) {
        if (report.reportDate in result) {
            result[report.reportDate] =
                result[report.reportDate] && report.finalized;
        } else {
            result[report.reportDate] = report.finalized;
        }
    }
    return result;
}

export const getMonthDateRange = (
    month: LocalMonth
): [LocalDate, LocalDate] => [
    month.first.toLocalWeek().monday,
    month.last.toLocalWeek().sunday
];

export function getMonthStartEndDate(month: LocalMonth) {
    const [startDate, endDate] = getMonthDateRange(month).map(day =>
        day.toString()
    );
    return {
        startDate,
        endDate
    };
}

export const EXISTING_AGGREGATED_REPORTS = gql`
    query AggregatedReportsList(
        $slug: String
        $startDate: Date!
        $endDate: Date!
        $period: AggregatedReportPeriod!
    ) {
        mapView(slug: $slug) {
            id
            operators {
                id
                name
                aggregatedReports(
                    startDate: $startDate
                    endDate: $endDate
                    period: $period
                ) {
                    id
                    reportDate
                    finalized
                }
            }
        }
    }
`;

export function useFinalizedAggregatedReportsQuery() {
    return useQueryFn<AggregatedReportsListData, AggregatedReportsListArgs>(
        EXISTING_AGGREGATED_REPORTS
    );
}

export function useFinalizedAggregatedReports(
    mapView: MapView,
    period: AggregatedReportPeriod,
    startDate: LocalDate,
    endDate: LocalDate
) {
    const [data, loading] = useReloadingData<
        AggregatedReportsListData,
        AggregatedReportsListArgs
    >(EXISTING_AGGREGATED_REPORTS, {
        slug: mapView.slug,
        startDate: startDate.toString(),
        endDate: endDate.toString(),
        period
    });

    const reportDays = getFinalizedReportDays(
        flatMap(
            data?.mapView.operators ?? [],
            operator => operator.aggregatedReports
        )
    );
    return [reportDays, loading] as const;
}
