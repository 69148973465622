import React, { CSSProperties } from 'react';
import cx from 'classnames';
import "/opt/build/repo/src/page-policy/forms/common.tsx?resplendence=true";import rx from 'resplendence';

import { DateSelect } from 'common/date-picker';
import { NumberField, TextareaField, TextField } from 'common/layout';
import { LocalDate } from 'utils/date-tools';
import TimeSelect from './time-select';

/*
    @import 'style';
*/;

const FORM_INPUT = "rx-page-policy-forms-common-1"/*
    display: flex;
    flex-direction: column;
    margin-bottom: 16rem;
*/;

const LABEL = "rx-page-policy-forms-common-2"/*
    @include text-label;
    color: $gray-70;
    margin-bottom: 3rem;
*/;

export const TIME_SELECTOR_ROW = rx('div', "rx-page-policy-forms-common-3")/*
    display: flex;
    gap: 16rem;
*/;

// These values are only used for display purposes. If no value is set,
// a policy with empty start_time and end_time is created.
export const DEFAULT_START_TIME: [number, number] = [0, 0];
export const DEFAULT_END_TIME: [number, number] = [23, 45];

// returns true if the time represented by a is < the time represented by b
export function timeLessThan(
    a: [number, number],
    b: [number, number]
): boolean {
    return a[0] < b[0] || (a[0] === b[0] && a[1] < b[1]);
}

interface FormInputProps {
    children: React.ReactNode;
    label: React.ReactNode;
    labelId: string;
}

/**
 * A common form input container that provides a label and accepts children.
 * The `labelId` should match an id on an input element within the form
 */
function FormInput({ label, labelId, children }: FormInputProps) {
    return (
        <div className={FORM_INPUT}>
            <label htmlFor={labelId} className={LABEL}>
                {label}
            </label>
            {children}
        </div>
    );
}

const FORM_NOTICE = "rx-page-policy-forms-common-4"/*
    @include text-body-tiny;
    ~* prevent the container from resizing as we add or remove help text *~
    height: 19.2rem;
    color: $gray-70;

    &.error {
        color: $red-70;
    }
*/;

interface FormNoticeProps {
    children: React.ReactNode;
    noticeType?: 'info' | 'error';
    showNotice: boolean;
}

/**
 * Shows a notice to the user on a form. It will render space for the notice,
 * regardless of whether or not it is showing to avoid the form height
 * shifting when a message is shown.
 */
function FormNotice({
    children,
    noticeType = 'info',
    showNotice
}: FormNoticeProps) {
    return (
        <span className={cx(FORM_NOTICE, noticeType)}>
            {showNotice ? children : null}
        </span>
    );
}

interface TextInputProps {
    id: string;
    label: React.ReactNode;
    onChange: (value: string) => void;
    value: string;
    placeholder?: string;
}

/** A common text input for a form */
function TextInput({
    id,
    label,
    onChange,
    value,
    placeholder
}: TextInputProps) {
    return (
        <FormInput label={label} labelId={id}>
            <TextField
                id={id}
                onChange={onChange}
                placeholder={placeholder}
                value={value}
            />
        </FormInput>
    );
}

interface TextareaInputProps {
    id: string;
    label: React.ReactNode;
    onChange: (value: string) => void;
    value: string;
    placeholder?: string;
}

/** A common textarea input for a form */
function TextareaInput({
    id,
    label,
    onChange,
    value,
    placeholder
}: TextareaInputProps) {
    return (
        <FormInput label={label} labelId={id}>
            <TextareaField
                resize="vertical"
                id={id}
                onChange={onChange}
                placeholder={placeholder}
                value={value}
            />
        </FormInput>
    );
}

interface NumberInputProps {
    id: string;
    label: React.ReactNode;
    onChange: (value: string) => void;
    value: React.InputHTMLAttributes<HTMLInputElement>['value'];
    numberLabel?: string;
    numberLabelPosition?: 'left' | 'right' | 'none';
    min?: React.InputHTMLAttributes<HTMLInputElement>['min'];
    max?: React.InputHTMLAttributes<HTMLInputElement>['max'];
    step?: React.InputHTMLAttributes<HTMLInputElement>['step'];
    width?: CSSProperties['width'];
}

/** A number input for a form. Can be configured with units (such as currency)
 * via adding a label, min, and/or step prop.
 */
function NumberInput({
    id,
    label,
    onChange,
    value,
    numberLabel,
    min,
    max,
    step,
    width,
    numberLabelPosition = 'none'
}: NumberInputProps) {
    return (
        <FormInput label={label} labelId={id}>
            <NumberField
                label={numberLabel}
                labelPosition={numberLabelPosition}
                id={id}
                onChange={onChange}
                value={value}
                min={min}
                max={max}
                step={step}
                width={width}
            />
        </FormInput>
    );
}

interface TimeSelectInputProps {
    id: string;
    label: React.ReactNode;
    /** Time stored as [hour, minute] in integers */
    selectedTime: [number, number];
    onChange: (time: [number, number]) => void;
}

/**
 * A time select input for forms, in the format of hh : mm AM/PM
 */
function TimeSelectInput({
    id,
    label,
    selectedTime,
    onChange
}: TimeSelectInputProps) {
    return (
        <FormInput label={label} labelId={id}>
            <TimeSelect time={selectedTime} onChange={onChange} />
        </FormInput>
    );
}
interface DateSelectInputProps {
    id: string;
    label: React.ReactNode;
    selectedDate: LocalDate | null;
    onChange: (date: LocalDate) => void;
    disabled?: boolean;
    earliestAllowed?: LocalDate;
}

/** A common single date select input for a form */
function DateSelectInput({
    id,
    label,
    selectedDate,
    onChange,
    disabled = false,
    earliestAllowed
}: DateSelectInputProps) {
    return (
        <FormInput label={label} labelId={id}>
            <DateSelect
                earliestAllowed={earliestAllowed}
                disabled={disabled}
                selectedDate={selectedDate}
                onChange={onChange}
            />
        </FormInput>
    );
}

export {
    DateSelectInput,
    FormInput,
    FormNotice,
    NumberInput,
    TextareaInput,
    TextInput,
    TimeSelectInput
};
