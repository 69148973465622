import React from 'react';
import useCachedState from 'utils/use-cached-state';
import { FormattedMessage } from 'react-intl';
import MapCallout from 'page-explore/common/map-callout';
import "/opt/build/repo/src/page-explore/page-heatmaps/hex-bins/privacy-callout.tsx?resplendence=true";
import LOCAL_STORAGE_KEYS from 'constants/local-storage';

/*
@import 'style.scss';
*/;

const PRIVACY_CALLOUT = "rx-page-explore-page-heatmaps-hex-bins-privacy-callout-1"/*
    justify-self: center;
    max-width: 752rem;
*/;

const PRIVACY_CALLOUT_MESSAGE =
    'Seeing low numbers? Ride Report filters out hex bins with very few trips. Try expanding the date and hour range to see more.';

interface Props {
    showNoDataMsg: boolean;
}

const TripsPrivacyCallout = ({ showNoDataMsg: hasNoBins }: Props) => {
    // We have two use cases where we want to show a privacy callout.
    //   1. The user just visited Trip Starts/Ends and hasn't seen the callout
    //   2. The user selected a bin that has too few trips to show any data.
    //
    // We vary the message slightly for each case, and if there is no data
    // to show, the callout is not dismissible

    const [dismissed, setDismissed] = useCachedState(
        LOCAL_STORAGE_KEYS.explorePrivacyCalloutDismissed,
        false
    );

    return (
        <div className={PRIVACY_CALLOUT}>
            {hasNoBins ? (
                <MapCallout color="blue" shadow>
                    <FormattedMessage
                        key="no-bins-message"
                        defaultMessage={PRIVACY_CALLOUT_MESSAGE}
                    />
                </MapCallout>
            ) : (
                <MapCallout
                    color="blue"
                    shadow
                    isDismissed={dismissed}
                    dismiss={() => setDismissed(true)}
                >
                    <FormattedMessage
                        key="privacy-message"
                        defaultMessage={PRIVACY_CALLOUT_MESSAGE}
                    />
                </MapCallout>
            )}
        </div>
    );
};

export default TripsPrivacyCallout;
