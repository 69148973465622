import React from 'react';
import "/opt/build/repo/src/common/tag.tsx?resplendence=true";
import { OperatorName } from 'constants/operators';
import { useMapView } from './use-map-view';
import { AbsoluteLink } from 'router';
import { convertToSlug, getOperatorColorStyle } from 'utils/helpers';
import { useGetAreaColor } from './use-areas';
import cx from 'classnames';
import { FormattedMessage } from 'react-intl';
import useLastReportDate from './use-last-report-date';

/*
@import 'style.scss';
*/;

export const TAG = "rx-common-tag-1"/*
    display: inline-block;
    background-color: black;
    @include text-body-link;

    text-decoration: none!important;
    color: white;
    padding: 2.5rem 12rem;
    border-radius: 20rem;
    @media print {
        background-color: transparent!important;
        border: 1rem solid $gray-60;
        color: $gray-90!important;
        padding: 1.5rem 11rem;
    }
    &a:hover {
        // the easiest way to darken the background programatically is to add
        // another semi-transparent background layer
        // here we use a linear-gradient transitioning too and from the same value
        background-image: linear-gradient(to right, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
    }
    &.disableColor {
        background-color: white;
        color: black;
    }
*/;

type genericTagProps = {
    text: string;
    disableColor?: boolean;
    size?: 'smaller' | 'larger';
};

export function GenericTag({
    text,
    disableColor = false,
    size
}: genericTagProps) {
    let fontSize = '1em';
    if (size === 'smaller') fontSize = '0.7em';
    else if (size === 'larger') fontSize = '1.3em';
    let style: React.CSSProperties = {
        textTransform: 'capitalize',
        fontSize
    };
    if (!disableColor)
        style = {
            ...style,
            ...{ backgroundColor: '#d7ddfc', color: '#000000' }
        };
    return (
        <div className={cx(TAG, { disableColor: disableColor })} style={style}>
            {text}
        </div>
    );
}

type OperatorTagProps = {
    name: OperatorName;
    disableColor?: boolean;
};
export function OperatorTag({ name, disableColor = false }: OperatorTagProps) {
    const { slug } = useMapView();
    const lastReportDate = useLastReportDate();
    return (
        <AbsoluteLink
            className={cx(TAG, { disableColor: disableColor })}
            style={disableColor ? null : getOperatorColorStyle(name)}
            to={`/${slug}/report/daily/${lastReportDate.toString()}/for/${convertToSlug(
                name
            )}`}
        >
            {name}
        </AbsoluteLink>
    );
}

export function AllOperatorsTag() {
    const { slug } = useMapView();
    const lastReportDate = useLastReportDate();
    return (
        <AbsoluteLink
            className={cx(TAG, { disableColor: true })}
            style={null}
            to={`/${slug}/report/daily/${lastReportDate.toString()}`}
        >
            <FormattedMessage
                key="all-operators-tag"
                defaultMessage="All Operators"
            />
        </AbsoluteLink>
    );
}

type AreaTagProps = {
    name: string;
    id: string;
    slug: string;
};
export function AreaTag({ name, id, slug }: AreaTagProps) {
    const { slug: mapviewSlug } = useMapView();
    const getAreaColor = useGetAreaColor();
    const link = `/${mapviewSlug}/areas/${slug}`;

    return (
        <AbsoluteLink className={TAG} style={getAreaColor(id)} to={link}>
            {name}
        </AbsoluteLink>
    );
}
