import React from 'react';
import "/opt/build/repo/src/common/tabs.tsx?resplendence=true";

/*
@import 'style';
*/;

type Props = { children: React.ReactNode };

const TABS = "rx-common-tabs-1"/*
    display: flex;
    flex-flow: row nowrap;
    border-bottom: 1rem solid $gray-50;
    justify-self: stretch;
*/;

/**
 * Tabbed navigation to be placed at the top of a page.
 *
 * Use like:
 * ```jsx
 * <Tabs>
 *     <Link className={TAB_LINK} nav to="somewhere">
 *         Somewhere
 *     </Link>
 *     <Link className={TAB_LINK} nav to="somewhere_else">
 *         Somewhere Else
 *     </Link>
 * </Tabs>
 * ```
 *
 * You can use whatever variety of link you want, so long as you:
 * - style it with {@link TAB_LINK `TAB_LINK`}
 * - set it as a "nav" link so that the `active` classname is applied when that
 *   page is selected.
 */
export function Tabs({ children }: Props) {
    return <nav className={TABS}>{children}</nav>;
}

/**
 * For styling a tab within a {@link Tabs `<Tabs>`} component.
 */
export const TAB_LINK = "rx-common-tabs-2"/*
    padding: 4rem 32rem 2rem 32rem;
    min-width: 158rem;
    position: relative;
    @include text-body-link;
    color: $gray-70;
    text-align: center;
    border-radius: 6rem 6rem 0 0;
    &.active {
        color: $blue-50;
        &:after {
            content: "";
            position: absolute;
            bottom: -2rem;
            left: 0;
            width: 100%;
            height: 3rem;
            border-radius: 3rem;
            background-color: $blue-50;
        }
    }
    &:hover {
        background-color: rgba($white, 0.5);
    }

*/;
