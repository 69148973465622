import React from 'react';
import { FormattedMessage } from 'react-intl';
import "/opt/build/repo/src/root/page-app-root-router.tsx?resplendence=true";

import { Switch } from 'router';
import Region from './page-region';
import HomePage from './page-home';
import { useUser } from 'common/user';
import ReviewTermsPage from './page-review-terms';
import { IntlProvider } from 'utils/use-intl';
import UserMasqueradeSelector from 'common/user-masquerade-selector';
import AppHeader from './app-header';
import SettingsPage from './page-settings';
import { FeatureFlaggedFeature } from 'graphql.g';
import { RegionalDashboardHomepage } from 'page-regional-dashboard';
import { User } from 'types';
import TOP_LEVEL_PATHS from './top-level-paths';

/*
@import 'style.scss';
*/;

const NO_DATA = "rx-root-page-app-root-router-1"/*
    @include headline-3;
    flex: 1 0 0;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    padding: 24rem;
    & > button {
        margin-top: 24rem
    }
*/;

function OrganizationPage() {
    const user = useUser();
    return (
        <IntlProvider locale="en">
            <AppHeader />
            <UserMasqueradeSelector currentUser={user} />
        </IntlProvider>
    );
}

/**
 * Establish what region (or "mapview") we are going to be looking at.
 */
function RootRouter() {
    const user = useUser();
    const mapViews = user.orgAuth.organization.mapViews;

    if (!user.hasAcceptedTerms) {
        return (
            <IntlProvider locale={user.orgAuth.organization.locale}>
                <ReviewTermsPage />
            </IntlProvider>
        );
    }

    return mapViews.length > 0 ? (
        <Switch
            pages={[
                ...(user.isStaff
                    ? [
                          {
                              path: TOP_LEVEL_PATHS.ADMIN,
                              component: OrganizationPage
                          }
                      ]
                    : []),
                {
                    path: TOP_LEVEL_PATHS.SETTINGS,
                    component: SettingsPage
                },

                ...mapViews.map(mapView => ({
                    path: `/${mapView.slug}`,
                    component: Region,
                    properties: { slug: mapView.slug }
                })),
                ...homePage(
                    user.orgAuth.organization.__typename,
                    mapViews[0].slug,
                    user.enabledFeatures.includes(
                        FeatureFlaggedFeature.regional_dashboard
                    ),
                    user.isStaff,
                    user
                ),
                {
                    redirectTo: '/'
                }
            ]}
        />
    ) : user.isStaff ? (
        <UserMasqueradeSelector currentUser={user} />
    ) : (
        <div className={NO_DATA}>
            <IntlProvider locale={user.orgAuth.organization.locale}>
                <FormattedMessage
                    key="no-data"
                    defaultMessage="You currently don't have any data sources set up."
                />
            </IntlProvider>
        </div>
    );
}

function homePage(
    orgAuthType: 'AgencyOrganization' | 'OperatorOrganization',
    defaultMapView: string,
    showRegionalDashboard: boolean,
    isStaff: boolean,
    user: User
) {
    if (!isStaff && showRegionalDashboard) {
        return [
            {
                path: '/',
                exact: true,
                component: RegionalDashboardHomepage,
                // Pass these two necessary pieces of data through props to avoid the nonnull assertion error
                properties: { user: user }
            }
        ];
    }

    return orgAuthType === 'AgencyOrganization'
        ? [
              {
                  path: '/',
                  exact: true,
                  redirectTo: `/${defaultMapView}`
              }
          ]
        : [
              {
                  path: '/',
                  exact: true,
                  component: HomePage
              }
          ];
}

export default RootRouter;
