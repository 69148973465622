import textColorForBackgroundColor from 'common/text-color-for-bg-color';
import React, { useState } from 'react';
import "/opt/build/repo/src/page-explore/page-realtime/context-panel.tsx?resplendence=true";import rx from 'resplendence';
import Icon from 'common/icon';
import cx from 'classnames';

/*
@import 'style';
*/;

const CONTEXT_PANEL_CONTAINER = "rx-page-explore-page-realtime-context-panel-1"/*
    @include container;
    background: $gray-10;
    box-shadow: 0rem 6rem 16rem rgba(0, 0, 0, 0.12), 0rem 1rem 4rem rgba(0, 0, 0, 0.1); // TODO decrease opacity
    border: none;
    width: 340rem;
    max-height: calc(100% - 106rem); // account for mapbox default controls
    z-index: 1;
    pointer-events: auto;
    transition: 200ms all;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 8rem;
    right: 8rem;
*/;

const CONTEXT_PANEL_CHILDREN_CONTAINER = "rx-page-explore-page-realtime-context-panel-2"/*
    overflow-x: hidden;
    overflow-y: auto;
    margin: 4rem;
*/;

const CONTEXT_PANEL_TITLE = "rx-page-explore-page-realtime-context-panel-3"/*
    @include text-body-bold;
    border-radius: 4rem;
    &.hasChildren {
        border-radius: 4rem 4rem 0 0;
    }
    height: 56rem;
    padding: 16rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    & h1 {
        font-weight: bold;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    & button {
        display: flex;
        align-items: center;
        font-size: 24rem;
    }
*/;

type ContextPanelProps = {
    title: React.ReactNode;
    color: string;
    children?: React.ReactNode;
    onClose: () => void;
};

/**
 * The outer most container for the explore page side panel.
 * It controls enter & exit animations and the components general layout and placement on screen
 */
export function ContextPanel({
    title,
    color,
    children,
    onClose
}: ContextPanelProps) {
    return (
        <div className={CONTEXT_PANEL_CONTAINER}>
            <div
                className={cx(CONTEXT_PANEL_TITLE, {
                    hasChildren: children
                })}
                style={{
                    backgroundColor: color,
                    color: textColorForBackgroundColor(color)
                }}
            >
                {title}
                <button onClick={onClose}>
                    <Icon icon={'Close'} />
                </button>
            </div>
            {children && (
                <div className={CONTEXT_PANEL_CHILDREN_CONTAINER}>
                    {children}
                </div>
            )}
        </div>
    );
}

const CONTEXT_PANEL_INFO = "rx-page-explore-page-realtime-context-panel-4"/*
    padding: 12rem;
    background-color: white;
*/;

interface ContextPanelInfoProps {
    children: React.ReactNode;
}

export function ContextPanelInfo({ children }: ContextPanelInfoProps) {
    return <div className={CONTEXT_PANEL_INFO}>{children}</div>;
}

const CONTEXT_DETAILS_CONTAINER = "rx-page-explore-page-realtime-context-panel-5"/*
    background: $white;
    border-radius: 2rem;
    padding: 0 16rem;
    position: relative;
    margin: 8rem 0;
    & > :first-child, :last-child {
        margin: 0;
    }
*/;

const CONTEXT_DETAILS_HEADER = "rx-page-explore-page-realtime-context-panel-6"/*
    display: flex;
    align-content: center;
    justify-content: space-between;
    color: $gray-90;
    white-space: nowrap;
    margin-bottom: 16rem;
    margin-right: 8rem;
    padding: 16rem 0;
    > h2 {
        @include text-body-bold;
        overflow: hidden;
        text-overflow: ellipsis;
        &:before { // show our own custom arrow image
            content: "";
            display: inline-block;
            width: 12rem;
            height: 12rem;
            background-repeat: no-repeat;
            background-size: contain;
            background-color: $gray-90;
            margin-right: 8rem;
            transform: rotate(0.5turn);
            transition: transform 100ms;
            -webkit-mask-image: url('../../assets/svgs/details_arrow.svg');
            mask-image: url('../../assets/svgs/details_arrow.svg');
        }
        &.open:before {
            transform: rotate(0turn);
        }
    }
    > h3 {
        font-weight: 600;
        font-size: 12rem;
        color: $gray-60;
        padding-left: 8rem;
    }
*/;

export const Separator = rx('hr', "rx-page-explore-page-realtime-context-panel-7")/*
    box-sizing: content-box;
    margin-bottom: 8rem;
    width: 100%;
    border-width: .5rem;
    border-style: solid;
    border-image-slice: 1;
    border-image-source: linear-gradient(90deg, rgba(223, 227, 231, 0) 0%, rgba(223, 227, 231, 0.5) 25%, #DFE3E7 50%, rgba(223, 227, 231, 0) 100%);
*/;

const CONTEXT_CONTENT_CONTAINER = "rx-page-explore-page-realtime-context-panel-8"/*
    margin: 8rem 0;
*/;

const CONTEXT_CONTENT_DETAILS = "rx-page-explore-page-realtime-context-panel-9"/*
    transform: scaleY(0);
    transform-origin: top;
    transition: transform 0.25s ease;
    &.open {
        transform: scaleY(1);
    }
*/;

type ContextPanelDetailsProps = {
    open?: boolean;
    title: string;
    subtitle?: string;
    summaryComponent?: React.ReactNode;
    detailComponent: React.ReactNode;
};

/**
 * The inner dropdown component for the context panel.
 * Hides and shows the content for the selected Mapbox feature.
 * Accepts a title and detail component, as well as optional subtitle and summary component
 */
export function ContextPanelDetails({
    open = false,
    title,
    subtitle,
    detailComponent,
    summaryComponent
}: ContextPanelDetailsProps) {
    const [isOpen, setIsOpen] = useState(open);

    const handleToggle = () => {
        setIsOpen(prev => !prev);
    };

    return (
        <div className={cx(CONTEXT_DETAILS_CONTAINER)}>
            <div onClick={handleToggle} className={cx(CONTEXT_DETAILS_HEADER)}>
                <h2 className={cx({ open: isOpen })}>{title}</h2>
                <h3>{subtitle}</h3>
            </div>
            {!isOpen && summaryComponent != null && (
                <>
                    <Separator />
                    <div className={cx(CONTEXT_CONTENT_CONTAINER)}>
                        {summaryComponent}
                    </div>
                </>
            )}
            <div
                className={cx(
                    CONTEXT_CONTENT_CONTAINER,
                    CONTEXT_CONTENT_DETAILS,
                    { open: isOpen }
                )}
            >
                {isOpen && (
                    <>
                        <Separator />
                        {detailComponent}
                    </>
                )}
            </div>
        </div>
    );
}
