import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { hot } from 'react-hot-loader/root';

import { Switch } from 'router';
import App from './page-app';
import ErrorBoundary from '../common/error-boundary';
import TermsPage from './page-terms';
import { ViewportProvider } from 'utils/use-viewport';
import { IntlProvider } from 'utils/use-intl';
import TOP_LEVEL_PATHS from './top-level-paths';
import { usePageViews } from 'utils/use-analytics';

/**
 * The top level of the application. Establishes routing and error handling,
 * then immediately switches between /terms (which doesn't require
 * authentication) and everything else, which does.
 */
function InnerRoot() {
    usePageViews();

    return (
        <IntlProvider locale="en">
            <ErrorBoundary size="large">
                <ViewportProvider>
                    <Switch
                        pages={[
                            {
                                path: TOP_LEVEL_PATHS.TERMS,
                                component: TermsPage
                            },
                            { component: App }
                        ]}
                    />
                </ViewportProvider>
            </ErrorBoundary>
        </IntlProvider>
    );
}

function Root() {
    return (
        <BrowserRouter>
            <InnerRoot />
        </BrowserRouter>
    );
}

export default hot(Root);
