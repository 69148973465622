import { gql } from '@apollo/client';
import { useGraphql } from 'utils/use-data';
import { POLICY_DETAILS_FRAGMENT, POLICY_METRIC_FRAGMENT } from './fragments';
import { useMapView } from 'common/use-map-view';
import { PoliciesWithMetricsData, PoliciesWithMetricsArgs } from 'graphql.g';
import { LocalDate } from 'utils/date-tools';

const POLICIES_WITH_METRICS_QUERY = gql`
    query PoliciesWithMetrics($id: ID!, $startDate: Date!, $endDate: Date!) {
        mapViewById(id: $id) {
            id
            policies {
                ...PolicyDetails
                metric(startDate: $startDate, endDate: $endDate) {
                    ...PolicyMetric
                }
            }
        }
    }
    ${POLICY_DETAILS_FRAGMENT}
    ${POLICY_METRIC_FRAGMENT}
`;

export function usePoliciesWithMetrics(
    startDate: LocalDate,
    endDate: LocalDate
) {
    const { id } = useMapView();
    const [data, isLoading, error] = useGraphql<
        PoliciesWithMetricsData,
        PoliciesWithMetricsArgs
    >(POLICIES_WITH_METRICS_QUERY, {
        id,
        startDate: startDate.toString(),
        endDate: endDate.toString()
    });

    const policiesWithMetrics =
        data != null && error == null ? data.mapViewById.policies : null;

    return [policiesWithMetrics, isLoading, error] as const;
}
