import LOCAL_STORAGE_KEYS from 'constants/local-storage';

const FIVE_MINUTES_IN_MS = 1000 * 60 * 5;
const getLastReload = () => {
    const lastReload = localStorage.getItem(LOCAL_STORAGE_KEYS.lastReload);
    return (lastReload && parseInt(lastReload)) || 0;
};

// this comes from the type definition for React.lazy, which isn't exported
type LazyComponentFactory = () => Promise<{ default: any }>;

/**
 * Wrapper for React.lazy with error handling.
 *
 * First it will try to reload the failed component, up to the number of
 * times specified by `attemptsLeft` (defaults to 1). If this still fails, it
 * will fallback to reloading the entire page.
 */
function lazyErrorHandler(
    lazyComponent: LazyComponentFactory,
    attemptsLeft: number = 1
): Promise<{ default: any }> {
    return new Promise((resolve, reject) => {
        lazyComponent()
            // if we successfully load a component, we can just resolve
            .then(resolve)
            .catch((error: Error) => {
                console.log(error);
                // if we haven't exhausted our retry attempts, try to load
                // this component again in 1 second
                if (attemptsLeft > 0) {
                    setTimeout(() => {
                        lazyErrorHandler(lazyComponent, attemptsLeft - 1).then(
                            resolve,
                            reject
                        );
                    }, 1000);
                    return;
                }

                // If it's not a network error, the resource may no longer
                // exist. This is an issue with lazy loading code: if the
                // app is updated while the user is on the page, lazy loads
                // will fail as the old version of the code can no longer
                // be fetched. The fix is just to reload the page to make
                // sure we're getting the latest version of everything.

                // get the last timestamp that we tried reloading the page
                const lastReload = getLastReload();

                // if the last reload was within the last 5 minutes,
                // just reject with the error. We don't want to get stuck
                // in a refresh loop if the chunk never exists.
                if (
                    Date.now() - lastReload < FIVE_MINUTES_IN_MS ||
                    error.name !== 'ChunkLoadError'
                ) {
                    reject(error);
                    return;
                }

                localStorage.setItem(
                    LOCAL_STORAGE_KEYS.lastReload,
                    Date.now().toString()
                );
                window.location.reload();
            });
    });
}

export default lazyErrorHandler;
