import React, { useMemo, useState } from 'react';
import "/opt/build/repo/src/page-segment-editor/index.tsx?resplendence=true";
import { useMapbox } from 'common/map';
import { useMapView } from 'common/use-map-view';
import Loading from 'common/loading';
import {
    StreetSegments,
    useStreetSegments
} from 'page-explore/layers/routes/api';
import FilterPanel from 'page-segment-editor/segment-editor-filter-panel';
import { AnySourceData, EventData, Layer, MapLayerMouseEvent } from 'mapbox-gl';
import { DEFAULT_WIDTH_STEPS } from 'page-explore/layers/routes/layers';
import { useEffect } from 'react';
import { GEOCODER } from 'page-explore/page-realtime/geocoder';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import mapboxgl from 'mapbox-gl';
import { OVERLAY } from 'page-explore/common/overlay';
import getFeatureForPoint from 'page-explore/common/get-feature-for-point';
import SegmentEditorPanel from './segment-editor-panel';

/*
    @import 'style';
*/;

const MAP = "rx-page-segment-editor-index-1"/*
    overflow: hidden;
*/;

const MAP_CONTAINER = "rx-page-segment-editor-index-2"/*
    display: grid;
    overflow: hidden;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    grid-template-rows: 1fr auto;
*/;

function useStreetSegmentSources(streetSegments: StreetSegments | null) {
    return useMemo(
        () => ({
            'street-segments-source': {
                type: 'geojson',
                data: {
                    type: 'FeatureCollection',
                    features:
                        streetSegments != null
                            ? Object.entries(streetSegments).map(
                                  ([geometryId, segment]) => ({
                                      type: 'Feature',
                                      properties: {
                                          id: geometryId,
                                          name: segment.name,
                                          roadClass: segment.road_class
                                      },
                                      geometry: segment.geometry
                                  })
                              )
                            : []
                }
            } as AnySourceData
        }),
        [streetSegments]
    );
}

function useStreetSegmentsLayers(
    selectedSegmentId?: string,
    roadClassFilterValue?: string[]
) {
    return useMemo(
        () =>
            [
                {
                    id: 'street-segments-layer',
                    type: 'line',
                    source: 'street-segments-source',
                    layout: {
                        'line-join': 'round',
                        'line-cap': 'round'
                    },
                    paint: {
                        'line-opacity': 0.85,
                        'line-color': selectedSegmentId
                            ? [
                                  // if we have a selected segment, use this color
                                  'match',
                                  ['get', 'id'],
                                  selectedSegmentId ?? '',
                                  '#3b55e6', // $blue-50
                                  // otherwise use a gray
                                  '#AAAAAA'
                              ]
                            : // If we have road class filters and no selected segment
                            roadClassFilterValue &&
                              roadClassFilterValue.length > 0
                            ? [
                                  'match',
                                  ['get', 'roadClass'],
                                  roadClassFilterValue,
                                  '#3b55e6',
                                  '#AAAAAA'
                              ]
                            : '#AAAAAA',
                        'line-width': DEFAULT_WIDTH_STEPS
                    }
                }
            ] as Layer[],
        [selectedSegmentId, roadClassFilterValue]
    );
}

function SegmentEditorPage() {
    const [selectedSegment, setSelectedSegment] = useState<{
        geometryId: string;
        name: string;
        roadClass: string | null;
    } | null>(null);
    const [roadClassFilterValue, setRoadClassFilterValue] = useState<string[]>(
        []
    );
    const { initialViewBounds, maxBounds } = useMapView();
    const streetSegments = useStreetSegments();
    const isLoading = streetSegments == null;

    const sources = useStreetSegmentSources(streetSegments);
    const layers = useStreetSegmentsLayers(
        selectedSegment?.geometryId,
        roadClassFilterValue
    );

    const [map, container] = useMapbox({
        sources,
        layers
    });

    useEffect(() => {
        if (map) {
            map.fitBounds(initialViewBounds);

            const geocoder: MapboxGeocoder = new MapboxGeocoder({
                accessToken: mapboxgl.accessToken,
                mapboxgl: map,
                marker: true,
                collapsed: true,
                bbox: maxBounds
            });

            const geocoderNode = document.getElementById('geocoder');
            if (geocoderNode != null) {
                geocoderNode.appendChild(geocoder.onAdd(map));
            }

            return () => {
                geocoder.onRemove();
            };
        }
    }, [map, initialViewBounds, maxBounds]);

    useEffect(() => {
        if (map && !isLoading) {
            const listener = (event: MapLayerMouseEvent & EventData) => {
                const feature = getFeatureForPoint(
                    map,
                    ['street-segments-layer'],
                    event.point
                );

                if (!feature || !feature.properties || !feature.geometry) {
                    setSelectedSegment(null);
                    return;
                }

                setSelectedSegment({
                    geometryId: feature.properties.id,
                    name: feature.properties.name,
                    roadClass: feature.properties.roadClass
                });
            };

            map.resize();
            map.on('click', listener);
            return () => {
                map.off('click', listener);
            };
        }
    }, [isLoading, map]);

    const availableRoadClasses = useMemo(() => {
        let roadClasses: Set<string> = new Set();
        if (streetSegments) {
            Object.values(streetSegments).forEach(streetSegment => {
                if (streetSegment.road_class) {
                    roadClasses.add(streetSegment.road_class);
                }
            });
        }
        return [...roadClasses].map(rc => {
            return { label: rc, value: rc };
        });
    }, [streetSegments]);

    return (
        <div className={MAP_CONTAINER}>
            <Loading loading={isLoading} />
            <div className={OVERLAY}>
                <FilterPanel
                    roadClassOptions={availableRoadClasses}
                    setRoadClassFilterValue={setRoadClassFilterValue}
                ></FilterPanel>
                <div className={GEOCODER} id="geocoder" />
                {selectedSegment != null && (
                    <SegmentEditorPanel
                        selectedSegment={selectedSegment}
                        onClose={() => setSelectedSegment(null)}
                        onSave={() => {
                            setSelectedSegment(null);
                            // for the time being, we don't actually have the
                            // update here on the frontend, and because this
                            // change can take time to propagate on the backend,
                            // reloading the page doesn't show the segment either.
                            // So for now we just alert the user it was successful.
                            alert('Successfully saved segment.');
                        }}
                    />
                )}
            </div>
            <div className={MAP} ref={container} />
        </div>
    );
}

export default SegmentEditorPage;
