import React, { forwardRef } from 'react';
import AbsoluteLink from './absolute-link';
import { getUrlWithChangedParams } from '../utils/helpers';
import { useRouteMatch } from 'react-router-dom';

type Props = {
    className?: string;
    params: { [param: string]: any };
    replace?: boolean;
    children?: any;
    nav?: boolean;
    exact?: boolean;
    disabled?: boolean;
    [prop: string]: any;
};

/**
 * A react-router link that navigates to the current URL with different
 * url params* 
```
// suppose the component tree currently is at /:mapview/:operator/gbfs
// and the current url is /austin/bird/gbfs

// goes to /christchurch/bird/gbfs
<ParamLink params={{mapview: "christchurch"}} />

// goes to /austin/lime/gbfs
<ParamLink params={{operator: "lime"}} /> 
```
 */
const ParamLink = forwardRef<any, Props>(({ params, ...rest }, ref) => {
    const match = useRouteMatch();
    const path = getUrlWithChangedParams(match.path, params);
    return <AbsoluteLink to={path} {...rest} ref={ref} />;
});
export default ParamLink;
