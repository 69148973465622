import React from 'react';

import { PolicyFormProps } from './types';
import { AllOrSingleOperatorSelect } from './operator-selectors';
import VehicleClassInput from './vehicle-class-selector';
import DayOfWeekInput from './day-of-week-selector';
import {
    DEFAULT_END_TIME,
    DEFAULT_START_TIME,
    FormNotice,
    TimeSelectInput
} from './common';
import { FormattedMessage } from 'react-intl';

function NoParkingZoneForm({
    policy,
    setPolicy,
    formErrors,
    setFormErrors
}: PolicyFormProps) {
    return (
        <>
            <TimeSelectInput
                label={
                    <FormattedMessage
                        key="time-label-start"
                        defaultMessage="Start time:"
                    />
                }
                id="start-time-input"
                selectedTime={policy.startTime ?? DEFAULT_START_TIME}
                onChange={startTime => {
                    setPolicy({
                        ...policy,
                        startTime
                    });
                }}
            />

            <TimeSelectInput
                label={
                    <FormattedMessage
                        key="time-label-end"
                        defaultMessage="End time:"
                    />
                }
                id="end-time-input"
                selectedTime={policy.endTime ?? DEFAULT_END_TIME}
                onChange={endTime =>
                    setPolicy({
                        ...policy,
                        endTime
                    })
                }
            />

            <FormNotice noticeType="error" showNotice={formErrors.endTime}>
                <FormattedMessage
                    key="end-time"
                    defaultMessage="End time must be greater than or equal to start time."
                />
            </FormNotice>

            <DayOfWeekInput
                days={policy.days}
                onChange={days =>
                    setPolicy({
                        ...policy,
                        days
                    })
                }
            />

            <VehicleClassInput policy={policy} setPolicy={setPolicy} />

            <AllOrSingleOperatorSelect
                policy={policy}
                setPolicy={setPolicy}
                formErrors={formErrors}
                setFormErrors={setFormErrors}
            />
        </>
    );
}

export default NoParkingZoneForm;
