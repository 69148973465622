import React, { useEffect, useRef, useMemo } from 'react';
import {
    ApolloClient,
    ApolloLink,
    HttpLink,
    ApolloProvider,
    ServerError,
    InMemoryCache
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';

import config from 'config';
import introspection from './introspection.g.json';
import { useAuthInfo } from 'common/authentication';

type Props = {
    children: React.ReactNode;
};

const possibleTypes: Record<string, string[]> = {};
for (const supertype of introspection.__schema.types) {
    if (supertype.possibleTypes) {
        possibleTypes[supertype.name] = supertype.possibleTypes.map(
            subtype => subtype.name
        );
    }
}

const httpLink = new HttpLink({
    uri: `${config.apiHost}/api/v1/graphql`
});

const cache = new InMemoryCache({
    possibleTypes,
    typePolicies: {
        MapView: {
            fields: {
                operator(existingData, { args, toReference }) {
                    return (
                        existingData ||
                        toReference({ __typename: 'Operator', id: args?.id })
                    );
                }
            }
        }
    }
});

const getLink = (
    authRef: React.MutableRefObject<ReturnType<typeof useAuthInfo>>
) => {
    const authLink = new ApolloLink((operation, forward) => {
        operation.setContext({
            headers: {
                authorization: `Bearer ${authRef.current.token}`
            }
        });

        return forward(operation);
    });
    const logoutLink = onError(({ networkError }) => {
        // Per https://github.com/RideReport/tickets/issues/2425, we've been getting
        // unexpected 403 errors from the backend which can only be fixed by logging
        // out and logging back in again. We don't know why this is happening, as
        // 403 errors _should_ indicate that re-authenticating won't fix the problem.
        // However, because our practices are to have graphQL do all 403-styl
        // authentication via graphQL error reponses instead of via HTTP status codes,
        // it should be safe to log the user out whenever Apollo encounters a 403.
        if (
            networkError &&
            ((networkError as ServerError).statusCode === 401 ||
                (networkError as ServerError).statusCode === 403)
        ) {
            authRef.current.logout();
        }
    });

    return logoutLink.concat(authLink).concat(httpLink);
};

/**
 * Establishes the necessary context for data fetching via graphql.
 */
function Apollo({ children }: Props) {
    const authInfo = useAuthInfo();

    // Store the auth token in a ref so that we're always using the latest one
    // without needing to tear down and rebuild Apollo
    const authInfoRef = useRef(authInfo);
    authInfoRef.current = authInfo;

    const client = useMemo(() => {
        // Continue setting up Apollo as usual.
        return new ApolloClient({
            link: getLink(authInfoRef),
            cache
        });
    }, []);

    // Clean up the client on dismount.
    useEffect(() => {
        return () => {
            client.clearStore();
            client.stop();
        };
    }, [client]);
    return <ApolloProvider client={client}>{children}</ApolloProvider>;
}

export default Apollo;

/**
 * Apollo gives cool hooks to modify the cache after receiving responses from
 * mutations. However, fields are cached multiple times, once for unique
 * combination of arguments passed. If you need to only modify field caches with
 * specific arguments, you'll need to use this little hack of a function to
 * parse out which cache you are working with.
 *
 * See https://github.com/apollographql/apollo-feature-requests/issues/238#issuecomment-694961960
 */
export const getArgsFromCache = (
    storeFieldName: string
): Record<string, any> => {
    try {
        const match = storeFieldName.match(/^.+\((.+)\)$/);
        if (!match) {
            return {};
        }
        return JSON.parse(match[1]);
    } catch {
        return {};
    }
};
