import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Checkbox } from 'common/layout';
import SidebarSection from './sidebar-section';
import { useCustomDatasets } from 'page-custom-data/custom-data-context';
import MapOptions from '../../../common/map-options';
import "/opt/build/repo/src/page-explore/common/sidebar/custom-datasets.tsx?resplendence=true";

/*
@import 'style.scss';
*/;

const CUSTOM_DATA_MENU = "rx-page-explore-common-sidebar-custom-datasets-1"/*
    @include container;
    position: absolute;
    padding: 12rem;
    top: 40rem;
    @include shadow-8;
    display: flex;
    flex-flow: column nowrap;
    z-index: 1;
    transition: 100ms all;
    &.enter {
        transition-timing-function: ease-out;
    }
    &.exit {
        transition-duration: 200ms;
        transition-timing-function: ease-in;
    }
    &.enter, &.exit.exit-active {
        opacity: 0;
        transform: translateY(-40rem);
    }
    &.enter.enter-active, &.exit {
        opacity: 1;
        transform: translateY(0rem);
    }
*/;

const CustomDataMapOptions = () => {
    const {
        state: customDatasetState,
        dispatch: dispatchCustomDatasets
    } = useCustomDatasets();

    const updateShowHeatmap = () =>
        dispatchCustomDatasets({
            type: 'CHANGE_LAYER_DISPLAY',
            payload: {
                displayType:
                    customDatasetState.displayType === 'heatmap'
                        ? 'default'
                        : 'heatmap'
            }
        });

    return (
        <MapOptions
            className={CUSTOM_DATA_MENU}
            toggles={[
                {
                    header: (
                        <FormattedMessage
                            key="data-view"
                            defaultMessage="Data View"
                        />
                    ),
                    options: [
                        {
                            id: 'hide-heatmap',
                            label: (
                                <FormattedMessage
                                    key="points-button"
                                    defaultMessage="Points"
                                />
                            ),
                            icon: 'Points'
                        },
                        {
                            id: 'show-heatmap',
                            label: (
                                <FormattedMessage
                                    key="heatmap-button"
                                    defaultMessage="Heatmap"
                                />
                            ),
                            icon: 'Heatmap'
                        }
                    ],
                    onChange: updateShowHeatmap,
                    checkedId:
                        customDatasetState.displayType === 'heatmap'
                            ? 'show-heatmap'
                            : 'hide-heatmap'
                }
            ]}
        />
    );
};

function SidebarCustomDatasets() {
    const customDatasets = useCustomDatasets();

    // don't show anything in the sidebar if there's no datasets loaded.
    if (Object.keys(customDatasets.state.datasets).length < 1) {
        return null;
    }

    return (
        <SidebarSection
            moreOptions={<CustomDataMapOptions />}
            beta
            title={
                <FormattedMessage
                    key="label"
                    defaultMessage="Custom Datasets"
                />
            }
        >
            {Object.values(customDatasets.state.datasets).map(dataset => (
                <Checkbox
                    key={`toggle-custom-datasets-${dataset.id}`}
                    color={dataset.color}
                    checked={dataset.activeOnMap}
                    onChange={() =>
                        customDatasets.dispatch({
                            type: 'TOGGLE_DATASET_ACTIVE_ON_MAP',
                            payload: { id: dataset.id }
                        })
                    }
                    label={dataset.title}
                />
            ))}
        </SidebarSection>
    );
}

export default SidebarCustomDatasets;
