import { gql, ApolloCache } from '@apollo/client';
import { AoiInfoFragment } from 'page-report/fragments';
import { MapView } from 'types';

export const POLICY_RULE_FRAGMENT = gql`
    fragment RuleRelatedToArea on PolicyRule {
        id
        name
        policy {
            id
            name
        }
    }
`;

export const AREA_DETAILS_FRAGMENT = gql`
    fragment AreaDetails on AreaOfInterest {
        ...AoiInfo
        geometryJson
        dataRanges
        policyRules {
            ...RuleRelatedToArea
        }
    }
    ${POLICY_RULE_FRAGMENT}
    ${AoiInfoFragment}
`;

export function updateMapviewAreaCache<T extends {}>(
    cache: ApolloCache<T>,
    mapview: MapView,
    area: {
        id: string;
        slug: string;
    }
) {
    cache.writeFragment({
        fragment: gql`
            fragment UpdateAreaBySlugCache on MapView {
                id
                areaOfInterest(slug: $area) {
                    id
                    slug
                }
            }
        `,
        id: cache.identify(mapview),
        data: {
            id: mapview.id,
            areaOfInterest: {
                __typename: 'AreaOfInterest',
                id: area.id,
                slug: area.slug
            }
        },
        variables: {
            area: area.slug
        },
        fragmentName: 'UpdateAreaBySlugCache'
    });
}
