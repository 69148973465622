import React from 'react';
import {
    defineMessages,
    FormattedMessage,
    FormattedNumber,
    useIntl
} from 'react-intl';
import { DateRange } from 'common/date-picker';
import {
    AggregationTripDataType,
    RegionalAnalyzeChartArgs,
    RegionalAnalyzeChartData,
    VehicleClass
} from 'graphql.g';
import { useReloadingData } from 'utils/use-data';
import { TABLE } from 'page-fees/page-list';
import { Link } from 'router';
import "/opt/build/repo/src/page-regional-dashboard/region-jurisdiction-table.tsx?resplendence=true";
import { REGIONAL_ANALYZE_QUERY } from 'page-regional-dashboard';

/*
@import 'style.scss';
*/;

const LOADING_TRIP_DISTANCE = "rx-page-regional-dashboard-region-jurisdiction-table-1"/*
    background-color: $gray-30;
    width: 80rem;
    height: 19rem;
    border-radius: 3rem;
    position: relative;
    overflow: hidden;

    &::before {
        content: '';
        display: block;
        position: absolute;
        left: -82px;
        height: 100%;
        width: 82px;
        background: linear-gradient(to right, transparent 0%, #C5CDD4 50%, transparent 100%);
        animation: load 1s cubic-bezier(0.4, 0.0, 0.2, 1) infinite;
    }

    @keyframes load {
        from {
            left: -82px;
        }
        to   {
            left: 100%;
        }
    }
*/;

type JurisdictionTableProps = {
    tripCountData: RegionalAnalyzeChartData;
    dateRange: DateRange;
    vehicleClass: VehicleClass | null;
};

const m = defineMessages({
    miles: 'mi',
    kilometers: 'km'
});

function JurisdictionTable({
    tripCountData: data,
    dateRange,
    vehicleClass
}: JurisdictionTableProps) {
    const intl = useIntl();
    const [tripDistanceData, loading] = useReloadingData<
        RegionalAnalyzeChartData,
        RegionalAnalyzeChartArgs
    >(REGIONAL_ANALYZE_QUERY, {
        operatorId: null,
        areaId: null,
        start: dateRange[0].toString(),
        end: dateRange[1].toString(),
        metric: 'trip_distance',
        vehicleClass: vehicleClass,
        tripDataType: AggregationTripDataType.trip_starts
    });

    return (
        <table className={TABLE}>
            <thead>
                <tr>
                    <th>
                        <FormattedMessage
                            defaultMessage={'Jurisdiction'}
                            key={'jurisdiction'}
                        />
                    </th>
                    <th>
                        <FormattedMessage
                            defaultMessage={'Trips'}
                            key={'trips'}
                        />
                    </th>
                    <th>
                        <FormattedMessage
                            defaultMessage={'Total Distance'}
                            key={'total-distance'}
                        />
                    </th>
                </tr>
            </thead>
            <tbody>
                {data.mapViews.map((tripCountDataWrapper, index) => {
                    const tripDistanceDataWrapper =
                        tripDistanceData?.mapViews[index];
                    return (
                        <tr key={tripCountDataWrapper.slug}>
                            <td>
                                <Link to={tripCountDataWrapper.slug}>
                                    {tripCountDataWrapper.name}
                                </Link>
                            </td>
                            <td>
                                {tripCountDataWrapper.analyzeData?.dataLine
                                    .totalCount && (
                                    <FormattedNumber
                                        value={
                                            tripCountDataWrapper.analyzeData
                                                ?.dataLine.totalCount
                                        }
                                    />
                                )}
                            </td>
                            <td>
                                {tripDistanceDataWrapper?.analyzeData?.dataLine
                                    .sumTotal && !loading ? (
                                    <span>
                                        <FormattedNumber
                                            value={
                                                tripDistanceDataWrapper
                                                    ?.analyzeData?.dataLine
                                                    .sumTotal
                                            }
                                            maximumFractionDigits={0}
                                        />
                                        {intl.formatMessage(
                                            m[
                                                tripDistanceDataWrapper
                                                    ?.analyzeData.aggregateUnit
                                            ]
                                        )}
                                    </span>
                                ) : !!tripDistanceDataWrapper?.analyzeData //if we query for data we expect to exist but doesn't, show null instead of the loading indicator
                                      ?.dataLine.sumTotal ? (
                                    <div className={LOADING_TRIP_DISTANCE} />
                                ) : null}
                            </td>
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
}

export default JurisdictionTable;
