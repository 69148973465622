import React from 'react';
import { FormattedMessage } from 'react-intl';
import { MODAL_FORM, MODAL_TITLE, MODAL_BUTTONS } from 'common/modal';
import { Button } from 'common/layout';
import { CancelButtonText, DeleteButtonText } from 'strings';
import { DeletePolicyResult, DeletePolicyMutationArgs } from 'graphql.g';
import { gql, useMutation } from '@apollo/client';
import { ErrorCallout } from 'common/error-message';
import { useMapView } from 'common/use-map-view';

type Props = {
    onCancel: () => void;
    onSuccess: () => void;
    policyId: string;
};

const DELETE_POLICY_MUTATION = gql`
    mutation DeletePolicy($policy: DeletePolicyInput!) {
        deletePolicy(policy: $policy) {
            policyId
        }
    }
`;

function DeletePolicy({ onCancel, onSuccess, policyId }: Props) {
    const mapview = useMapView();
    const [deletePolicy, { loading, error }] = useMutation<
        DeletePolicyResult,
        DeletePolicyMutationArgs
    >(DELETE_POLICY_MUTATION, {
        update(cache, { data }) {
            if (data?.deletePolicy?.policyId) {
                const id = data.deletePolicy.policyId;
                cache.modify({
                    // get the mapview part of the cache, which is where the
                    // custom policies live
                    id: cache.identify(mapview),
                    fields: {
                        policies(policies, { readField }) {
                            // remove this specific policy from the collection
                            // of polcies on this mapview.
                            return policies.filter(
                                policy => readField('policyId', policy) !== id
                            );
                        }
                    }
                });
            }
        }
    });

    const onDelete = async () => {
        try {
            await deletePolicy({
                variables: {
                    policy: { mapviewId: mapview.id, policyId: policyId }
                }
            });
            onSuccess();
        } catch (err) {
            // We'll handle this with getting {error} out of the mutation hook
            console.error(err);
            window.rollbar.error(err);
        }
    };
    return (
        <div className={MODAL_FORM}>
            <h3 className={MODAL_TITLE}>
                <FormattedMessage
                    key="delete-verification-title"
                    defaultMessage="Are you sure?"
                />
            </h3>
            <p>
                <FormattedMessage
                    key="delete-verification-body"
                    defaultMessage="You are about to delete a policy. This action cannot be undone."
                />
            </p>
            {error && <ErrorCallout error={error} />}
            <div className={MODAL_BUTTONS}>
                <Button color="blue" onClick={onCancel}>
                    <CancelButtonText />
                </Button>
                <Button color="red" disabled={loading} onClick={onDelete}>
                    <DeleteButtonText />
                </Button>
            </div>
        </div>
    );
}

export default DeletePolicy;
