import { useEnabledFeatures } from 'common/use-feature-flags';
import { useUser } from 'common/user';
import { FeatureFlaggedFeature } from 'graphql.g';

/**
 * Returns true if this user can access the custom data feature
 */
export function usePermissionToAccessCustomData() {
    const { useDemoFeatures } = useUser();
    const enabledFeatures = useEnabledFeatures();

    return (
        useDemoFeatures ||
        enabledFeatures.includes(FeatureFlaggedFeature.custom_datasets)
    );
}
