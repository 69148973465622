import React from 'react';
import {
    CartesianGrid,
    XAxis,
    YAxis,
    Tooltip,
    ResponsiveContainer,
    AreaChart,
    Area
} from 'recharts';
import { IntlShape, useIntl } from 'react-intl';
import { Container, ContainerHeader } from 'common/layout';
import { OperatorName, OPERATOR_NAMES } from 'constants/operators';
import {
    convertToSlug,
    getDataColor,
    getOperatorColorStyle
} from 'utils/helpers';
import { LocalDate } from 'utils/date-tools';
import { AXIS_LABELS, CommonTooltip } from 'common/common-chart';
import Loading from 'common/loading';

type Props = {
    operators: any;
    title: React.ReactNode;
    date: LocalDate;
    loading: boolean;
};

const DailyAreaChart = ({ operators, title, date, loading }: Props) => {
    const intl = useIntl();
    const data = transformData(operators);

    return (
        <Container>
            <Loading loading={loading} kind="over-table" />
            <ContainerHeader>{title}</ContainerHeader>
            <ResponsiveContainer width="100%" height={250}>
                <AreaChart
                    data={data}
                    margin={{
                        // A top margin here keeps the cartesian grid equal, and yAxis values round/even numbers.
                        top: 10,
                        right: -20,
                        left: 20,
                        bottom: 0
                    }}
                >
                    <CartesianGrid stroke="#ebeef0" vertical={false} />

                    {data.map((op, index) => {
                        const name = operators[index]?.name as OperatorName;

                        const operatorColor =
                            name && OPERATOR_NAMES.includes(name)
                                ? getOperatorColorStyle(name).backgroundColor
                                : getDataColor(index).backgroundColor;

                        const gradient =
                            name && OPERATOR_NAMES.includes(name)
                                ? `url(#gradient-${convertToSlug(name)})`
                                : `url(#gradient-${index})`;

                        return (
                            <Area
                                type="monotone"
                                key={index}
                                dataKey={index}
                                name={name}
                                stroke={operatorColor}
                                strokeWidth={3}
                                fillOpacity={1}
                                unit="vehicles"
                                activeDot={{
                                    stroke: operatorColor,
                                    strokeWidth: 4
                                }}
                                fill={gradient}
                            />
                        );
                    })}
                    <XAxis
                        dataKey="hour"
                        axisLine={false}
                        tickLine={false}
                        padding={{ right: 10 }}
                        interval={'preserveStartEnd'}
                        tickFormatter={tick =>
                            formatHourOnXAxis(tick, date, intl)
                        }
                        style={AXIS_LABELS}
                    />
                    <YAxis
                        orientation="right"
                        axisLine={false}
                        tickLine={false}
                        padding={{ bottom: 20 }}
                        domain={[0, 'auto']}
                        tickFormatter={tick =>
                            intl.formatNumber(tick, {
                                notation: 'compact'
                            })
                        }
                        style={AXIS_LABELS}
                    />
                    <Tooltip<number, string>
                        isAnimationActive={false}
                        content={({ active, payload }) =>
                            active &&
                            payload && (
                                <CommonTooltip
                                    active={active}
                                    payload={payload}
                                />
                            )
                        }
                    />
                </AreaChart>
            </ResponsiveContainer>
        </Container>
    );
};

export default DailyAreaChart;

/* transformReChartsData() takes in data the shape of:
    const oldData = {
        operator1: [
            [0, 123],
            [1, 456]
        ],
        operator2: [
            [0, 321],
            [1, 654]
        ]
    };

    .. and returns it in the required ReCharts format:

     const newData = [
        { operator1: 123, operator2: 321, hour: 0 },
        { operator1: 456, operator2: 654, hour: 1 }
    ];
*/
function transformData(operators) {
    if (!operators) return [];
    const dataByHour = {};

    const hourlyDeploymentsPerOperator = operators.map(
        op => op.hourlyDeploymentCounts
    );
    hourlyDeploymentsPerOperator.forEach((countsPerHour, operatorIndex) => {
        // This is a bit of a hack. Right now the backend is
        // sending us time data points that look like [0...23, 0],
        // including midnight of the next day as an extra zero.
        // So, if we encounter a 0 that isn't the first data point,
        // we assume it should actually be a 24.
        let updatedCountsPerHour = countsPerHour.map((entry, i) => [
            i,
            entry[1]
        ]);

        updatedCountsPerHour.forEach(([hour, count]) => {
            if (dataByHour[hour] == null) {
                dataByHour[hour] = { [operatorIndex]: count };
            } else {
                dataByHour[hour][operatorIndex] = count;
            }
        });
    });

    return Object.entries(dataByHour).map(([hour, countsPerOperator]) => ({
        ...(countsPerOperator as any),
        hour
    }));
}

const HOURLY_START = 0;
const HOURLY_END = 24;
const HOURLY_MID = 12;

function formatHourOnXAxis(
    value: string,
    date: LocalDate,
    intl: IntlShape
): string {
    const tick = Number(value);

    if (tick === HOURLY_START || tick === HOURLY_END || tick === HOURLY_MID) {
        return intl.formatTime(date.toDate().setHours(tick), {
            hour: 'numeric'
        });
    }
    // Hide odd hours
    if (tick % 2) {
        return '';
    }
    // convert evening hours to standard time
    if (tick > 12) {
        return (tick - 12).toString();
    }
    return tick.toString();
}
