import gql from 'graphql-tag';
import useData from 'utils/use-data';
import { useMemo } from 'react';
import { sortAreas, getDataColor } from 'utils/helpers';

import { AreaNamesData, AreaNamesArgs } from 'graphql.g';
import { useMapView } from './use-map-view';
import { AoiInfoFragment } from 'page-report/fragments';
import DATA_COLORS from 'constants/data-colors.json';

const AREAS_QUERY = gql`
    query AreaNames($slug: String, $includeArchived: Boolean) {
        mapView(slug: $slug) {
            id
            areasOfInterest(includeArchived: $includeArchived) {
                ...AoiInfo
            }
        }
    }
    ${AoiInfoFragment}
`;

/** Fetch a list of every area of interest in the current map view. */
export function useAreas(includeArchived = false) {
    const mapView = useMapView();
    const data = useData<AreaNamesData, AreaNamesArgs>(AREAS_QUERY, {
        slug: mapView.slug,
        includeArchived
    });

    return useMemo(
        () => (data ? sortAreas(data.mapView.areasOfInterest) : null),
        [data]
    );
}

/**
 * @returns a function that takes an area id and returns a color style for that
 * area.
 *
 * Colors use our standard data color order. Each operator has their own counter
 * that runs separately, to make the colors consistent between users even if an
 * operator user can't see another operator's areas.
 *
 * If the area isn't currently active (ie, isn't being returned by
 * MapView.areasOfInterest), then it is assigned a default neutral color.
 *
 */
export function useGetAreaColor() {
    const areas = useAreas(true);
    return useMemo(() => {
        const result: {
            [areaId: string]: { color: string; backgroundColor: string };
        } = {};
        if (areas) {
            let baseCount = 0;
            const counts: {
                [operatorIds: string]: number;
            } = {};
            // We filter archived areas out in the code instead of as part of
            // the query, so that when the cache updates due to mutations, the
            // correct filtering will be applied without having to refetch.
            for (const area of areas.filter(a => a.archivedAt === null)) {
                if (!area.operatorIds.length) {
                    result[area.id] = getDataColor(baseCount++);
                } else {
                    // we need to make a copy of the operatorIds array
                    // before trying to sort it, since it's frozen so it can't
                    // be mutated https://stackoverflow.com/a/53420326/3711733
                    const key = [...area.operatorIds].sort().join('-');
                    // start operator colors halfway through the data colors
                    // so that operator area colors are different than shared areas
                    if (counts[key] === undefined) {
                        counts[key] = Math.ceil(DATA_COLORS.length / 2);
                    }
                    result[area.id] = getDataColor(counts[key]++);
                }
            }
        }
        return (id: string) =>
            result[id] ?? { color: 'white', backgroundColor: '#222a35' };
    }, [areas]);
}
