import React from 'react';
import "/opt/build/repo/src/common/key-value.tsx?resplendence=true";

/*
@import 'style.scss';
*/;

export interface KeyValueProps {
    keyName: React.ReactNode;
    children: React.ReactNode;
}

export const KEY_VALUE = "rx-common-key-value-1"/*
    @include text-body;
*/;

/**
 * Groups a collection of <KeyValue>s
 */
export const KEY_VALUES = "rx-common-key-value-2"/*
    & > *:not(:last-child) {
        margin-bottom: 16rem;
    }
*/;

export const KEY = "rx-common-key-value-3"/*
    @include text-body-bold;
    &:after {
        content: ": ";
    }
*/;

/**
 * Used to display a property-name -> property-value relationship to users.
 *
 * Rendered with the key as bold text and the value as light text, separated by
 * a semicolon.
 */
function KeyValue({ keyName, children }: KeyValueProps) {
    return (
        <p className={KEY_VALUE}>
            <span className={KEY}>{keyName}</span>
            <span>{children}</span>
        </p>
    );
}

export default KeyValue;
