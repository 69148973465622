import React from 'react';
import BarChart from './bar-chart';
import { Chart, PLACEHOLDER } from './metric-chart';
import groupBy from 'lodash/groupBy';
import toPairs from 'lodash/toPairs';
import sumBy from 'lodash/sumBy';
import { AnalyzeDataXUnit } from 'graphql.g';
import "/opt/build/repo/src/page-analyze/three-dimensional-chart.tsx?resplendence=true";
import { useIntl } from 'utils/use-intl';
import { getOperatorColorStyle, range } from 'utils/helpers';
import DATA_COLORS from 'constants/data-colors.json';
import hexRgb from 'hex-rgb';
import { FormattedMessage } from 'react-intl';
import { formatChartLabel } from './analyze-filter';

/*
@import 'style';
*/;

const GRID = "rx-page-analyze-three-dimensional-chart-1"/*
    display: grid;
    grid-template:  repeat(7, 1fr) 1fr / repeat(24, 1fr) 50rem;
    margin: 0 15rem 0 10rem;
*/;

const GRID_ITEM = "rx-page-analyze-three-dimensional-chart-2"/*
    @include text-label;
    font-size: 9rem;
    width: 100%;
    height: 0;
    padding-bottom: calc(100% + 2rem);
    position: relative;
    color: transparent;
    outline: 2rem transparent;
    transition-property: border-color, transform, z-index;
    transition-duration: 150ms;
    border-radius: 3rem;
    flex: 0 0;
    overflow: hidden;
    box-sizing: border-box;
    background-color: white;
    text-align: center;
    &:before {
        content: '';
        display: block;
        top: -2rem;
        left: -2rem;
        position: absolute;
        width: calc(100% + 2rem);
        height: calc(100% + 2rem);
        background-color: var(--color);
        transition-property: opacity;
        transition-duration: 150ms;
        border-radius: 3rem;
    }
    transform: scale(90%);
    &:hover {
        transform: scale(130%);
        outline: 2rem solid var(--border);
        &:before {
            opacity: 0;
        }
        color: $gray-90;
        z-index: 1;
    }
*/;

const LABEL = "rx-page-analyze-three-dimensional-chart-3"/*
    margin: 2rem;
*/;

const ROUNDED = "rx-page-analyze-three-dimensional-chart-4"/*
    @include text-label;
*/;

const X_LABEL = "rx-page-analyze-three-dimensional-chart-5"/*
    @include text-label;
    color: $gray-70;
    display: flex;
    text-align: center;
    align-items: flex-start;
    justify-content: center;
*/;

const Y_LABEL = "rx-page-analyze-three-dimensional-chart-6"/*
    @include text-label;
    color: $gray-70;
    display: flex;
    align-items: center;
    justify-content: flex-end;
*/;

const HEADER = "rx-page-analyze-three-dimensional-chart-7"/*
    margin-top: 40rem;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    @include subhead;
    min-height: 55rem;
    box-sizing: content-box;
    &.small {
        @include text-body;
        min-height: 44rem;
    }
    color: $gray-70;
*/;

type Props = {
    baseChart: Chart;
    threeDimensionalChart: Chart;
};

function ThreeDimensionalChart({ baseChart, threeDimensionalChart }: Props) {
    const intl = useIntl();
    const largestPoint = Math.max(
        ...(threeDimensionalChart.data?.dataLine.dataPoints.map(
            point => point.yValue ?? 0
        ) ?? [1])
    );
    const hexColor = baseChart.filter.operator
        ? getOperatorColorStyle(baseChart.filter.operator.name).backgroundColor
        : DATA_COLORS[0].background;
    const color = hexRgb(hexColor);

    const rounded = !threeDimensionalChart.dateRange[1].equals(
        baseChart.dateRange[1]
    ) && (
        <div className={ROUNDED}>
            <FormattedMessage
                key="rounded"
                defaultMessage="Rounded to: <1>{startDate, date, reportWeek} – {endDate, date, reportWeek}</1>"
                values={{
                    1: text => <strong>{text}</strong>,
                    startDate: threeDimensionalChart.dateRange[0].toDate(),
                    endDate: threeDimensionalChart.dateRange[1].toDate()
                }}
            />
        </div>
    );

    return (
        <>
            {baseChart.data ? (
                <BarChart analyzeData={baseChart.data} />
            ) : (
                <div className={PLACEHOLDER} />
            )}
            <h3 className={HEADER}>
                {formatChartLabel(intl, 'trips_by_weekday', baseChart.filter)}{' '}
                {rounded}
            </h3>
            {threeDimensionalChart.data ? (
                <BarChart
                    analyzeData={{
                        ...threeDimensionalChart.data,
                        xUnit: AnalyzeDataXUnit.weekday,
                        dataLine: {
                            ...threeDimensionalChart.data.dataLine,
                            dataPoints: toPairs(
                                groupBy(
                                    threeDimensionalChart.data.dataLine
                                        .dataPoints,
                                    point => point.xValue.split(' ')[0]
                                )
                            ).map(([group, points]) => ({
                                xValue: group,
                                yValue: sumBy(
                                    points,
                                    point => point.yValue ?? 0
                                ),
                                percentageOfTotal: sumBy(
                                    points,
                                    point => point.percentageOfTotal ?? 0
                                )
                            }))
                        }
                    }}
                />
            ) : (
                <div className={PLACEHOLDER} />
            )}
            <h3 className={HEADER}>
                {formatChartLabel(
                    intl,
                    'trips_per_day_by_hour',
                    baseChart.filter
                )}
                {rounded}
            </h3>
            <div className={GRID}>
                {threeDimensionalChart.data?.dataLine.dataPoints.map(
                    point =>
                        point.yValue !== null && (
                            <div
                                className={GRID_ITEM}
                                style={
                                    {
                                        gridColumn:
                                            parseInt(
                                                point.xValue.split(' ')[1]
                                            ) + 1,
                                        gridRow: parseInt(
                                            point.xValue.split(' ')[0]
                                        ),
                                        '--color': `rgba(${color.red}, ${
                                            color.green
                                        }, ${color.blue}, ${point.yValue /
                                            largestPoint})`,
                                        '--border': hexColor
                                    } as any
                                }
                            >
                                <div className={LABEL}>
                                    {intl.formatNumber(point.yValue)}
                                </div>
                                {point.percentageOfTotal !== null && (
                                    <div className={LABEL}>
                                        {intl.formatNumber(
                                            point.percentageOfTotal,
                                            { maximumSignificantDigits: 2 }
                                        )}
                                        %
                                    </div>
                                )}
                            </div>
                        )
                )}
                {range(1, 8).map(day => {
                    let date = new Date(2022, 3, 10 + day);
                    return (
                        <div
                            className={Y_LABEL}
                            style={{ gridColumn: 25, gridRow: day }}
                        >
                            {intl.formatDate(date, { weekday: 'short' })}
                        </div>
                    );
                })}
                {range(0, 24).map(hour => {
                    let date = new Date(2022, 3, 11, hour);
                    return (
                        <div
                            className={X_LABEL}
                            style={{ gridColumn: hour + 1, gridRow: 8 }}
                        >
                            {hour % 12 === 0
                                ? intl.formatTime(date, { hour: 'numeric' })
                                : hour % 12}
                        </div>
                    );
                })}
            </div>
        </>
    );
}

export default ThreeDimensionalChart;
