import React, { useState } from 'react';
import { FormattedMessage, useIntl, defineMessages } from 'react-intl';
import { Button, TextField, BUTTON } from 'common/layout';
import { Select } from 'common/select';
import "/opt/build/repo/src/root/page-settings-add-user-form.tsx?resplendence=true";
import { gql, useMutation, ApolloError } from '@apollo/client';
import { AddUserMutationArgs, AddUserResult, UserRoleType } from 'graphql.g';
import { useUser } from 'common/user';
import cx from 'classnames';
import { ErrorCallout } from 'common/error-message';
import { getDomainFromEmailAddress } from 'utils/helpers';
import { MODAL_FORM, MODAL_TITLE, MODAL_BUTTONS } from 'common/modal';
import { userRoleString } from './page-settings-user';

/*
@import 'style.scss';
*/;

const ADD_USER_MUTATION = gql`
    mutation AddUser($email: String!, $role: UserRoleType!) {
        addUser(userEmail: $email, role: $role) {
            userOrgAuth {
                id
                email
                role
            }
        }
    }
`;

const messages = defineMessages({
    'submit-button': 'Add User',
    'example-email': 'iona@sunnycity.gov'
});

type AddUserFormProps = {
    onCancel: () => void;
    onSuccess: (email: string) => void;
    domains: Set<string>;
};

type EmailError = 'duplicate' | 'invalid' | 'domain-warning';

function AddUserForm({ onCancel, domains, onSuccess }: AddUserFormProps) {
    const user = useUser();
    const [addUser, { error }] = useMutation<
        AddUserResult,
        AddUserMutationArgs
    >(ADD_USER_MUTATION, {
        update(cache, { data }) {
            if (data?.addUser?.userOrgAuth) {
                const newUser = data.addUser.userOrgAuth;
                cache.modify({
                    id: cache.identify(user.orgAuth.organization),
                    fields: {
                        users(users, { toReference }) {
                            const reference = toReference(newUser);
                            return [reference, ...users];
                        }
                    }
                });
            }
        }
    });
    const [email, setEmail] = useState('');
    const [role, setRole] = useState(UserRoleType.User);
    const [emailError, setEmailError] = useState<null | EmailError>(null);
    const [loading, setLoading] = useState(false);

    // This ^ becomes true once the user deselects the email field for the first time,
    // so we don't show validation errors while they're still entering data.

    const validate = (value: string): null | EmailError => {
        const domain = getDomainFromEmailAddress(value);
        if (!domain) return 'invalid';
        if (!domains.has(domain)) return 'domain-warning';
        return null;
    };
    const intl = useIntl();
    const formatRole = userRoleString(intl);

    return (
        <form
            className={MODAL_FORM}
            onSubmit={e => {
                e.preventDefault();
                setLoading(true);
                addUser({ variables: { email, role } })
                    .then(() => onSuccess(email))
                    .catch(error => {
                        setLoading(false);
                        if (error instanceof ApolloError) {
                            if (error.message.startsWith('DUPLICATE')) {
                                setEmailError('duplicate');
                                return;
                            }
                        }
                        console.error(error);
                    });
            }}
        >
            <h3 className={MODAL_TITLE}>
                <FormattedMessage key="title" defaultMessage="Add User" />
            </h3>
            {error && <ErrorCallout error={error} />}
            <TextField
                value={email}
                id="email-input"
                type="email"
                disabled={loading}
                label={
                    <FormattedMessage
                        key="email-label"
                        defaultMessage="Email"
                    />
                }
                placeholder={intl.formatMessage(messages['example-email'])}
                onChange={value => setEmail(value)}
                onBlur={e => setEmailError(validate(e.target.value))}
                error={
                    emailError === 'duplicate' ? (
                        <FormattedMessage
                            key="duplicate-email-error"
                            defaultMessage="A user with this email address already exists."
                        />
                    ) : emailError === 'invalid' ? (
                        <FormattedMessage
                            key="invalid-email-error"
                            defaultMessage="Please enter a valid email."
                        />
                    ) : null
                }
                warning={
                    emailError === 'domain-warning' && (
                        <FormattedMessage
                            key="unknown-domain-warning"
                            defaultMessage="The domain for this user's email address does not match any of the existing email domains for your organization."
                        />
                    )
                }
            />
            <div>
                <label>
                    <FormattedMessage key="role-label" defaultMessage="Role" />
                </label>
                <Select
                    options={Object.values(UserRoleType).map(role => ({
                        value: role,
                        label: formatRole(role)
                    }))}
                    onChange={selectedOption => {
                        const value = selectedOption?.value;
                        if (!value) return;
                        setRole(value);
                    }}
                    value={{
                        value: role,
                        label: formatRole(role)
                    }}
                    isLoading={loading}
                />
            </div>
            <div className={MODAL_BUTTONS}>
                <Button color="blue" onClick={onCancel}>
                    <FormattedMessage key="cancel" defaultMessage="Cancel" />
                </Button>
                <input
                    className={cx(BUTTON, 'blue')}
                    type="submit"
                    disabled={loading || !getDomainFromEmailAddress(email)}
                    value={intl.formatMessage(messages['submit-button'])}
                />
            </div>
        </form>
    );
}

export default AddUserForm;
