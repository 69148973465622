import { useMemo } from 'react';

const noEmpty = (x: string) => x !== '';

export function makeRelativePath(
    origin: string,
    destination: string,
    preserveQuery = false
) {
    const [originPath, originQuery] = origin.split('?');
    const [destinationPath, destinationQuery] = destination.split('?');
    const toPath = destinationPath.split('/').filter(noEmpty);
    let back = 0;
    while (back < toPath.length) {
        if (toPath[back] !== '..') break;
        back++;
    }

    const result =
        [
            '',
            ...originPath
                .split('/')
                .filter(noEmpty)
                .slice(0, back ? -back : undefined),
            ...toPath.slice(back)
        ].join('/') || '/';
    const queryString = preserveQuery ? originQuery : destinationQuery;
    return queryString ? `${result}?${queryString}` : result;
}

export const useRelativePath = (
    base: string,
    path: string,
    preserveQuery?: boolean
) =>
    useMemo(() => makeRelativePath(base, path, preserveQuery), [
        base,
        path,
        preserveQuery
    ]);
