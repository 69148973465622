import * as React from 'react';
import "/opt/build/repo/src/common/error-boundary.tsx?resplendence=true";
import cx from 'classnames';
import { FetchError } from 'utils/use-data';
import ErrorMessage from './error-message';

/*
@import 'style';
*/;

const ERROR_BOUNDARY = "rx-common-error-boundary-1"/*
	display: flex;
    flex-flow: column nowrap;
	align-items: center;
	justify-content: center;
	width: 100%;
    height: 100%;
    &.large {
        font-size: 32rem;
    }
*/;

type State = {
    error: Error | null;
    attempts: number;
};

type Failure = {
    error: Error;
    retry: () => void;
};

/**
 * Any exception that is thrown during rendering will "bubble" upwards to the
 * nearest error boundary. By placing error boundaries at strategic locations,
 * we can confine network failures and bugs to only the specific area that is
 * breaking, allowing the user to keep using the rest of the app.
 *
 * This is a higher-order component, meaning it wraps an existing component to
 * modify it. In this case, it wraps that component in an error boundary, and
 * then passes an additional "failure" prop if it catches any errors.
 *
 * You can use this to catch errors near the root of the component tree, and
 * then pass that info down into a child component that can display the error.
 */
export function withErrorHandling<T extends {}>(
    Component: React.ComponentType<T & { failure: null | Failure }>
) {
    class ManualErrorBoundary extends React.Component<T, State> {
        state = {
            error: null,
            attempts: 0
        };

        componentDidCatch = (error: Error) => {
            this.setState(state => ({
                attempts: state.attempts + 1
            }));
            const data: Record<string, any> = {};
            if (error instanceof FetchError) {
                data.variables = error.variables;
                data.gqlErrors = error.gqlErrors;
            }

            this.setState({ error });

            // don't try to report an error if rollbar doesn't exist
            if (window.rollbar) {
                window.rollbar.error(error.message, error, data);
            }

            // only throw errors in the test environment
            if (process.env.NODE_ENV === 'test') {
                throw error;
            }
        };

        retry = () => {
            this.setState({ error: null, attempts: 0 });
        };

        render = () => {
            const props = this.props;
            const { error, attempts } = this.state;
            if (error && attempts > 1) {
                // If attempts > 1 that means that the manual error-handling is
                // breaking too, so we should fall back to the default boundary
                // to keep from getting stuck in a loop.
                return (
                    <div className={ERROR_BOUNDARY}>
                        <ErrorMessage retry={this.retry} />
                    </div>
                );
            }
            return (
                <Component
                    {...props}
                    failure={error && { error, retry: this.retry }}
                />
            );
        };
    }
    return ManualErrorBoundary;
}

type ErrorBoundaryProps = {
    children: any;
    size?: 'large' | 'small';
};

/**
 * Any exception that is thrown during rendering will "bubble" upwards to the
 * nearest error boundary. By placing error boundaries at strategic locations,
 * we can confine network failures and bugs to only the specific area that is
 * breaking, allowing the user to keep using the rest of the app.
 *
 * This is our default error boundary, for when we can just hide all the
 * children with the error message, and re-render on retry.
 */
const ErrorBoundary = withErrorHandling<ErrorBoundaryProps>(
    ({ failure, children, size = 'small' }) => {
        return failure ? (
            <div className={cx(ERROR_BOUNDARY, size)}>
                <ErrorMessage retry={failure.retry} error={failure.error} />
            </div>
        ) : (
            children
        );
    }
);

export default ErrorBoundary;
