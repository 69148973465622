import { sendEventAnalytics } from './use-analytics';

/**
 * Downloads the given data to the specified filename.
 *
 * Downloading a file is achieved by adding a new DOM element, clicking it,
 * then removing the element from the page. All of this happens fast enough
 * that it won't be displayed on the screen.
 */
const downloadData = (filename: string, data: string) => {
    const blob = new Blob([data]);
    // create new element to add data to
    const link = document.createElement('a');
    // add data
    link.href = URL.createObjectURL(blob);
    // add filename
    link.download = filename;
    // add element to the body
    document.body.appendChild(link);
    // programmatically 'click' the link, causing the data to download
    link.click();
    // remove the element
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    link.parentNode!.removeChild(link);

    const fileExt = filename.split('.').pop();

    sendEventAnalytics({
        event_name: 'data export event',
        name: filename,
        action: 'exported',
        type: fileExt
    });
};

export default downloadData;
