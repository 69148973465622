import React, { ReactNode } from 'react';
import "/opt/build/repo/src/page-explore/common/sidebar/sidebar-section.tsx?resplendence=true";
import Loading from 'common/loading';
import { FormattedMessage } from 'react-intl';

/*
@import 'style';
*/;

const SECTION_TITLE = "rx-page-explore-common-sidebar-sidebar-section-1"/*
    color: $gray-60;
    @include text-label-bold;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
*/;

const SECTION = "rx-page-explore-common-sidebar-sidebar-section-2"/*
    display: grid;
    grid: auto-flow / 1fr;
    grid-gap: 14rem;
    min-width: 0;
    & > * {
        min-width: 0;
    }
*/;

const LOADING_BOX = "rx-page-explore-common-sidebar-sidebar-section-3"/*
    position: relative;
    width: 40rem;
    @include text-body;
*/;

const BETA = "rx-page-explore-common-sidebar-sidebar-section-4"/*
    padding: 0 4rem;
    border-radius: 2rem;
    background-color: $green-50;
    color: $white;
    margin-left: auto;
*/;

const SECTION_MORE = "rx-page-explore-common-sidebar-sidebar-section-5"/*
    margin-right: 8rem;
*/;

type Props = {
    title: any;
    moreOptions?: ReactNode;
    loading?: boolean;
    children: React.ReactNode;
    beta?: boolean;
};

const SidebarSection = ({
    title,
    moreOptions,
    children,
    loading = false,
    /** mark this section with a 'beta' tag */
    beta = false
}: Props) => {
    return (
        <div className={SECTION}>
            <div className={SECTION_TITLE}>
                {moreOptions && (
                    <div className={SECTION_MORE}>{moreOptions}</div>
                )}
                {title}
                {loading && (
                    <div className={LOADING_BOX}>
                        <Loading loading={loading} size={1.5} kind="clear" />
                    </div>
                )}
                {beta && (
                    <div className={BETA}>
                        <FormattedMessage key="beta" defaultMessage="Beta" />
                    </div>
                )}
            </div>
            {children}
        </div>
    );
};

export default SidebarSection;
