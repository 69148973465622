import React, { useEffect, useMemo } from 'react';
import { TabToggle } from 'common/layout';
import { Select } from 'common/select';
import { useMapView } from 'common/use-map-view';
import { useIntl } from 'utils/use-intl';
import "/opt/build/repo/src/page-analyze/analyze-filter.tsx?resplendence=true";
import { useQueryState } from 'router';
import {
    AggregationTripDataType,
    AnalyzeChartArgs,
    AnalyzeChartData,
    AoiInfoFragment,
    MapViewOperatorFragment,
    VehicleClass
} from 'graphql.g';
import { useReloadingData } from 'utils/use-data';
import { ChartMetric, CHART_LABELS, CHART_MESSAGES } from './types';
import gql from 'graphql-tag';
import { useAreas } from 'common/use-areas';
import { defineMessages, IntlShape } from 'react-intl';
import {
    useVehicleClass,
    VehicleClassQueryStatePicker
} from 'common/vehicle-classes';
import * as strings from 'strings';
import { TRIP_HISTOGRAMS } from './types';
import { DateRange } from 'common/date-picker';

/*
@import 'style';
*/;

const OPERATOR_SELECT = "rx-page-analyze-analyze-filter-1"/*
    width: 200rem;
*/;

const AREA_SELECT = "rx-page-analyze-analyze-filter-2"/*
    width: 320rem;
*/;

const VEHICLE_SELECT = "rx-page-analyze-analyze-filter-3"/*
    width: 160rem;
*/;

const TRIPS_TOGGLE = "rx-page-analyze-analyze-filter-4"/*
    margin-top: -19.2rem;
*/;

const TOGGLE_LABEL = "rx-page-analyze-analyze-filter-5"/*
    @include text-body-tiny;
    color: $gray-70;
*/;

export const ANALYZE_QUERY = gql`
    query AnalyzeChart(
        $slug: String!
        $operatorId: ID
        $areaId: ID
        $start: Date!
        $end: Date!
        $metric: String!
        $vehicleClass: VehicleClass
        $tripDataType: AggregationTripDataType
    ) {
        mapView(slug: $slug) {
            id
            analyzeData(
                operatorId: $operatorId
                areaId: $areaId
                startDate: $start
                endDate: $end
                metric: $metric
                vehicleClass: $vehicleClass
                tripDataType: $tripDataType
            ) {
                groupBy
                tripDataType
                startDate
                endDate
                xUnit
                yUnit
                aggregateUnit
                totalCountUnit
                dataLine {
                    group
                    dataPoints {
                        xValue
                        yValue
                        percentageOfTotal
                    }
                    median
                    average
                    sumTotal
                    totalCount
                }
                metricDefinition {
                    displayName
                    description
                    purposeDescription
                }
            }
        }
    }
`;

type Props = {
    index?: number;
    availableVehicleClasses: VehicleClass[] | null;
    removeComparisonMetricButton?: React.ReactNode;
    chartType: ChartMetric;
    areas: AoiInfoFragment[] | null;
};

const NEEDS_OPERATOR_SELECTED = [
    'max_available',
    'mean_available',
    'trips_per_mean_available_vehicle',
    'mean_active',
    'trips_per_mean_active_vehicle',
    'average_vehicle_idle_time'
] as ChartMetric[];

const CAN_USE_TRIP_ENDS = [
    ...TRIP_HISTOGRAMS,
    'trips_by_date',
    'total_trip_distance_by_day'
] as ChartMetric[];

const m = defineMessages({
    'select-operator': 'Select an operator to see metrics.',
    trip_starts: 'Starts',
    trip_ends: 'Ends',
    'trip-toggle-label': '*Compute trip metrics by',
    'start-trips': 'Start Trips',
    'end-trips': 'End Trips'
});

export function formatChartLabel(
    intl: IntlShape,
    chartType: ChartMetric,
    filter: Filter
) {
    const labelSections = [
        intl.formatMessage(CHART_MESSAGES[chartType])
    ] as string[];
    if (CAN_USE_TRIP_ENDS.includes(chartType)) {
        labelSections.push(intl.formatMessage(m[filter.tripType]));
    }
    if (filter.operator) {
        labelSections.push(filter.operator.name);
    }
    if (filter.area) {
        labelSections.push(filter.area.name);
    }
    if (filter.vehicleClass) {
        labelSections.push(
            intl.formatMessage(strings.vehicleClasses[filter.vehicleClass])
        );
    }
    return labelSections.join(' | ');
}

export interface Filter {
    vehicleClass: VehicleClass | null;
    operator: MapViewOperatorFragment | null;
    area: AoiInfoFragment | null;
    tripType: AggregationTripDataType;
    error: 'no-operator-selected' | null;
}

export function useFilter(
    metric: ChartMetric,
    areas: AoiInfoFragment[] | null,
    index: string = ''
): Filter {
    const mapView = useMapView();

    const vehicleClass = useVehicleClass('vehicleClass' + index);
    const [operatorSlug] = useQueryState('operator' + index);
    const [areaSlug] = useQueryState('area' + index);
    const [tripType] = useQueryState('trips' + index, {
        constrain: {
            fallbackValue: 'starts',
            options: new Set(['starts', 'ends'] as const)
        }
    });
    return useMemo(() => {
        const operators = mapView.operators.filter(op => op.hasMdsData);
        const singleOperator = operators.length === 1;
        const operator =
            (singleOperator
                ? operators[0]
                : operatorSlug &&
                  mapView.operators.find(
                      op => op.info.slug === operatorSlug
                  )) || null;
        const area =
            (areaSlug && areas && areas.find(area => area.slug === areaSlug)) ||
            null;
        return {
            vehicleClass,
            operator,
            area,
            tripType:
                tripType === 'starts'
                    ? AggregationTripDataType.trip_starts
                    : AggregationTripDataType.trip_ends,
            error:
                !operator && NEEDS_OPERATOR_SELECTED.includes(metric)
                    ? 'no-operator-selected'
                    : null
        };
    }, [
        areaSlug,
        areas,
        mapView.operators,
        metric,
        operatorSlug,
        tripType,
        vehicleClass
    ]);
}

export function useAnalyzeData(
    dateRange: DateRange,
    metric: ChartMetric | null,
    filter: Filter | null
) {
    const { slug } = useMapView();
    const [data, loading] = useReloadingData<
        AnalyzeChartData,
        AnalyzeChartArgs
    >(
        ANALYZE_QUERY,
        filter && !filter.error && metric
            ? {
                  slug,
                  operatorId: filter.operator?.operatorId ?? null,
                  areaId: filter.area?.id ?? null,
                  start: dateRange[0].toString(),
                  end: dateRange[1].toString(),
                  metric,
                  vehicleClass: filter.vehicleClass ?? null,
                  tripDataType: filter.tripType
              }
            : 'skip'
    );
    return [data?.mapView.analyzeData ?? null, loading] as const;
}

function AnalyzeFilter({
    index = 0,
    availableVehicleClasses,
    removeComparisonMetricButton,
    chartType
}: Props) {
    const intl = useIntl();
    const mapView = useMapView();
    const areas = useAreas();

    const paramIndex = index === 0 ? '' : index.toString();

    const operators = mapView.operators.filter(op => op.hasMdsData);
    const singleOperator = operators.length === 1;
    const [operatorSlug, setOperatorSlug] = useQueryState<string | null>(
        'operator' + paramIndex,
        {
            deleteOnUnmount: true,
            constrain: {
                fallbackValue:
                    operators.length === 1 ? operators[0].info.slug : null,
                options: new Set([...operators.map(op => op.info.slug), null])
            }
        }
    );
    const selectedOperator =
        (operatorSlug && operators.find(op => op.info.slug === operatorSlug)) ||
        null;

    const [areaSlug, setAreaSlug] = useQueryState<string | null>(
        'area' + paramIndex,
        areas
            ? {
                  deleteOnUnmount: true,
                  constrain: areas && {
                      fallbackValue: null,
                      options: new Set(areas.map(a => a.slug))
                  }
              }
            : undefined
    );
    const selectedArea = areas?.find(a => a.slug === areaSlug) ?? null;

    const [tripDataType, setTripDataType] = useQueryState(
        'trips' + paramIndex,
        {
            constrain: {
                fallbackValue: 'starts',
                options: new Set(['starts', 'ends'] as const)
            }
        }
    );

    const switchTripDataType = () =>
        setTripDataType(tripDataType === 'starts' ? 'ends' : 'starts');

    //If a user switches to a chart that doesn't allow computing by trip ends, default tripDataType back to trip starts.
    useEffect(() => {
        if (
            CAN_USE_TRIP_ENDS.includes(chartType) === false &&
            tripDataType !== 'starts'
        ) {
            setTripDataType('starts');
        }
    }, [chartType, setTripDataType, tripDataType]);

    const areaOptions =
        areas
            ?.filter(
                area =>
                    area.operatorIds.length === 0 ||
                    (selectedOperator &&
                        area.operatorIds.includes(selectedOperator.operatorId))
            )
            .map(area => ({
                value: area.slug,
                label: area.archivedAt
                    ? intl.formatMessage(CHART_LABELS['archived-area'], {
                          areaName: area.name
                      })
                    : area.name
            })) ?? [];

    const allOperatorOption = {
        value: null,
        label: intl.formatMessage(CHART_LABELS['operator-select-all'])
    };

    const allAreasOption = {
        value: null,
        label: intl.formatMessage(CHART_LABELS['area-select-service-area'])
    };

    return (
        <>
            {/* we have 3 cases:
                    1. only one operator => no select shown
                    2. multiple operators, can select all => show an option for all
                    3. multiple operators, must select only one => show a placeholder asking to select one
                */}
            {!singleOperator && (
                <div className={OPERATOR_SELECT}>
                    {!NEEDS_OPERATOR_SELECTED.includes(chartType) ? (
                        <Select
                            value={
                                selectedOperator == null
                                    ? allOperatorOption
                                    : {
                                          value:
                                              selectedOperator?.info.slug ??
                                              null,
                                          label:
                                              (selectedOperator?.name as string) ??
                                              null
                                      }
                            }
                            onChange={option =>
                                setOperatorSlug(option?.value ?? null)
                            }
                            options={[
                                allOperatorOption,
                                ...operators.map(op => ({
                                    value: op.info.slug,
                                    label: op.name
                                }))
                            ]}
                        />
                    ) : (
                        <Select
                            styles={{
                                menu: style => ({
                                    ...style,
                                    minWidth: '200rem'
                                })
                            }}
                            value={
                                selectedOperator
                                    ? {
                                          value:
                                              selectedOperator.info.slug ??
                                              null,
                                          label:
                                              (selectedOperator.name as string) ??
                                              null
                                      }
                                    : null
                            }
                            onChange={option =>
                                setOperatorSlug(option?.value ?? null)
                            }
                            options={[
                                ...operators.map(op => ({
                                    value: op.info.slug,
                                    label: op.name
                                }))
                            ]}
                            placeholder={intl.formatMessage(
                                CHART_LABELS['operator-select-placeholder']
                            )}
                        />
                    )}
                </div>
            )}

            {areas?.length ? (
                <div className={AREA_SELECT}>
                    <Select
                        value={
                            selectedArea
                                ? {
                                      value: selectedArea.slug,
                                      label: selectedArea.archivedAt
                                          ? intl.formatMessage(
                                                CHART_LABELS['archived-area'],
                                                {
                                                    areaName: selectedArea.name
                                                }
                                            )
                                          : selectedArea.name
                                  }
                                : allAreasOption
                        }
                        onChange={option => setAreaSlug(option?.value ?? null)}
                        options={[allAreasOption, ...areaOptions]}
                    />
                </div>
            ) : null}
            {!!availableVehicleClasses?.length && (
                <div className={VEHICLE_SELECT}>
                    <VehicleClassQueryStatePicker
                        vehicleClasses={availableVehicleClasses}
                        searchParam={'vehicleClass' + paramIndex}
                    />
                </div>
            )}
            {CAN_USE_TRIP_ENDS.includes(chartType) && (
                <div className={TRIPS_TOGGLE}>
                    <TabToggle
                        checked={tripDataType === 'ends'}
                        onChange={() => switchTripDataType()}
                        label={intl.formatMessage(m['trip-toggle-label'])}
                        tabLabels={[
                            intl.formatMessage(m['start-trips']),
                            intl.formatMessage(m['end-trips'])
                        ]}
                        className={TOGGLE_LABEL}
                        tiny={true}
                    />
                </div>
            )}

            {!!removeComparisonMetricButton && removeComparisonMetricButton}
        </>
    );
}

export default AnalyzeFilter;
