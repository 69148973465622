import { useCallback } from 'react';
import { DateRange } from 'common/date-picker';
import { useMapView } from 'common/use-map-view';
import { MapMode, useMapMode } from '..';
import { useMemo, useEffect } from 'react';
import useData from 'utils/use-data';

import gql from 'graphql-tag';
import { useReloadingData, useQueryFn } from 'utils/use-data';
import {
    AggregatedTripsArgs,
    AggregatedTripsData,
    MapViewHexIdsArgs,
    MapViewHexIdsData,
    OriginDestinationPrivacyThresholdArgs,
    OriginDestinationPrivacyThresholdData
} from 'graphql.g';
import { State, HexDatum } from '.';

const QUERY = gql`
    query AggregatedTrips(
        $slug: String
        $metric: String
        $startDate: Date
        $endDate: Date
        $startHour: Int
        $endHour: Int
        $inHex: ID
    ) {
        mapView(slug: $slug) {
            id
            aggregatedTrips(
                metric: $metric
                startDate: $startDate
                endDate: $endDate
                startHour: $startHour
                endHour: $endHour
                inHex: $inHex
            ) {
                data
            }
        }
    }
`;

const HEX_IDS_QUERY = gql`
    query MapViewHexIds($slug: String) {
        mapView(slug: $slug) {
            id
            hexIds
        }
    }
`;

const PRIVACY_THRESHOLD_QUERY = gql`
    query OriginDestinationPrivacyThreshold($slug: String) {
        mapView(slug: $slug) {
            id
            originDestinationPrivacyThreshold
        }
    }
`;

const combineHexes = (data: HexDatum[], emptyHexes: string[]) => {
    const countsByHex: Record<string, number> = {};
    for (const { hex, count } of data) {
        countsByHex[hex] = count;
    }
    for (const hex of emptyHexes) {
        if (!(hex in countsByHex)) {
            countsByHex[hex] = 0;
        }
    }
    return Object.keys(countsByHex).map(hex => ({
        hex,
        count: countsByHex[hex]
    }));
};

const aggregatedTripsVariables = (
    slug: string,
    dataPeriod: DateRange,
    mode: MapMode,
    hourRange: [number, number],
    inHex: string | null
): AggregatedTripsArgs => {
    return {
        slug: slug,
        startDate: dataPeriod[0].toString(),
        endDate: dataPeriod[1].toString(),
        metric: mode === 'trip_starts' ? 'starts' : 'ends',
        startHour: hourRange[0],
        endHour: hourRange[1],
        inHex
    };
};

export const useTripCountsByHex = (
    { hourRange, selectedHexId }: State,
    dataPeriod: DateRange
) => {
    const [mode] = useMapMode();
    const { slug } = useMapView();
    const [hexIdsData, hexIdsLoading] = useReloadingData<
        MapViewHexIdsData,
        MapViewHexIdsArgs
    >(HEX_IDS_QUERY, { slug });

    const [aggregateData, aggregatedDataLoading] = useReloadingData<
        AggregatedTripsData,
        AggregatedTripsArgs
    >(
        QUERY,
        aggregatedTripsVariables(
            slug,
            dataPeriod,
            mode,
            hourRange,
            selectedHexId
        )
    );
    return useMemo(() => {
        const loading = hexIdsLoading || aggregatedDataLoading;
        return {
            loading,
            data: loading
                ? []
                : combineHexes(
                      aggregateData?.mapView?.aggregatedTrips.data ?? [],
                      hexIdsData?.mapView?.hexIds ?? []
                  )
        };
    }, [aggregateData, aggregatedDataLoading, hexIdsData, hexIdsLoading]);
};

export type TripsData = ReturnType<typeof useTripCountsByHex>['data'];

export const usePrefetchedHexData = (
    { hoveredHexId, selectedHexId, hourRange }: State,
    dataPeriod: DateRange
) => {
    const [mode] = useMapMode();
    const { slug } = useMapView();
    const query = useQueryFn<AggregatedTripsData, AggregatedTripsArgs>(QUERY);
    const prefetch = useCallback(
        (id: string) =>
            query(
                aggregatedTripsVariables(slug, dataPeriod, mode, hourRange, id)
            ),
        [dataPeriod, hourRange, mode, query, slug]
    );

    // Prefetch the result if they hover over the hex for a moment
    // The timeout is trying to strike a balance between over-fetching and
    // making things feel snappy.
    useEffect(() => {
        if (hoveredHexId && !selectedHexId) {
            const timeout = setTimeout(() => prefetch(hoveredHexId), 150);
            return () => clearTimeout(timeout);
        }
    }, [hoveredHexId, prefetch, selectedHexId]);
};

export const usePrivacyThreshold = () => {
    const { slug } = useMapView();
    const privacyThresholdData = useData<
        OriginDestinationPrivacyThresholdData,
        OriginDestinationPrivacyThresholdArgs
    >(PRIVACY_THRESHOLD_QUERY, {
        slug: slug
    });
    if (privacyThresholdData) {
        return privacyThresholdData.mapView.originDestinationPrivacyThreshold;
    }
    return null;
};
