import React, { Suspense } from 'react';
import { useGetAreaColor } from 'common/use-areas';
import { useMapView } from 'common/use-map-view';
import { Banner, Container } from 'common/layout';
import { AbsoluteLink } from 'router';
import KeyValue from 'common/key-value';
import { FormattedMessage, FormattedDate } from 'react-intl';
import { LocalDate } from 'utils/date-tools';
import Loading from 'common/loading';

import { AoiInfoFragment } from 'graphql.g';
import { useAreaDetails } from 'common/area-map';
import "/opt/build/repo/src/page-report/report-area-banner.tsx?resplendence=true";

const AreaMap = React.lazy(() => import('common/area-map'));

/*
@import 'style.scss';
*/;

const AREA_BANNER_CONTAINER = "rx-page-report-report-area-banner-1"/*
    display: grid;
    grid-template-columns: 1fr;
     grid-auto-flow: column;
    grid-auto-columns: auto;
    grid-gap: 24rem;
    h2 {
        @include headline-4;
    }
*/;
const AREA_BANNER_INFO_LINK = "rx-page-report-report-area-banner-2"/*
    @include text-label-bold;
    display: grid;
    grid-template-rows: 1fr auto;
    > :first-child {
        border: none;
    }
    > :last-child {
        margin: 4rem;
    }
    border: 1rem solid $gray-30;
    border-radius: 3rem;
    position: relative;
    &:hover:after {
        content: "";
        z-index: 2;
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 3rem;
        background-color: $gray-90;
        opacity: 0.1;
    }
    a.mapboxgl-ctrl-logo {
        height: 23rem !important;
        background-size: cover;
        margin: 0 0 -4rem -4rem !important;
        overflow: visible !important;
        &.mapboxgl-compact {
            width: 23rem !important;
        }
    }
    .mapboxgl-ctrl-bottom-left .mapboxgl-ctrl {
        margin: 0 0 8rem 8rem !important;
    }
*/;

type AreaBannerProps = {
    area: AoiInfoFragment;
};

export function AreaBanner({ area }: AreaBannerProps) {
    const getAreaColor = useGetAreaColor();
    const mapview = useMapView();

    const areaDetails = useAreaDetails(area.slug);

    const areaLink = `/${mapview.slug}/areas/${area.slug}`;

    return (
        <>
            <div className={AREA_BANNER_CONTAINER}>
                <Banner el="header" style={getAreaColor(area.id)}>
                    <h2>{area.name}</h2>
                </Banner>
                <AbsoluteLink to={areaLink} className={AREA_BANNER_INFO_LINK}>
                    <Container el="div">
                        <Suspense fallback={<Loading size={4} />}>
                            <AreaMap
                                tiny
                                area={
                                    areaDetails !== 'not found' &&
                                    areaDetails !== 'loading'
                                        ? areaDetails
                                        : null
                                }
                            />
                        </Suspense>
                    </Container>
                    <div>About this area</div>
                </AbsoluteLink>
            </div>
            <Container>
                <Loading
                    size={3}
                    loading={areaDetails === 'loading'}
                    kind="over-table"
                />
                {areaDetails !== 'not found' && (
                    <KeyValue
                        keyName={
                            <FormattedMessage
                                key="first-report-date-label"
                                defaultMessage="First report date for this area"
                            />
                        }
                    >
                        {areaDetails === 'loading' ? null : areaDetails
                              .dataRanges.length === 0 ? (
                            <FormattedMessage
                                key="first-report-date-none"
                                defaultMessage="Not yet generated"
                            />
                        ) : (
                            <FormattedDate
                                format="localdayandyear"
                                value={LocalDate.fromDateString(
                                    areaDetails.dataRanges[0][0]
                                ).toDate()}
                            />
                        )}
                    </KeyValue>
                )}
            </Container>
        </>
    );
}
