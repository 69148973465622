import {
    defineMessages,
    FormattedMessage,
    FormattedNumber,
    IntlShape,
    useIntl
} from 'react-intl';
import React from 'react';
import "/opt/build/repo/src/page-mode-shift/chart.tsx?resplendence=true";import rx from 'resplendence';
import { ChartMetric } from 'page-analyze/types';
import { PageColumns, ContainerHeader, Container } from 'common/layout';
import { Filter, useFilter } from 'page-analyze/analyze-filter';
import { ModeShiftMetricDataArgs, ModeShiftMetricDataData } from 'graphql.g';
import { DateRange } from 'common/date-picker';
import ErrorBoundary from 'common/error-boundary';
import Loading from 'common/loading';
import MetricChart from 'page-analyze/metric-chart';
import { ModeShiftBlockProps, MODE_SHIFT_METRIC_QUERY } from './props';
import { useMapView } from 'common/use-map-view';
import { useReloadingData } from 'utils/use-data';
import { TotalByVehicleClass } from './types';
import { vehicleClasses } from 'strings';

/*
@import 'style';
*/;

const DescriptionContainer = rx('div', "rx-page-mode-shift-chart-1")/*
    @include text-body;
    color: $gray-70;
    padding: 10rem 10rem;
    text-align: left;
*/;

const SubTotalContainer = rx('div', "rx-page-mode-shift-chart-2")/*
    @include text-body;
    color: $gray-70;
    padding-left: 30rem;
    padding-bottom: 40rem;
    text-align: left;
*/;

const CONTEXT_PANEL_TITLE = "rx-page-mode-shift-chart-3"/*
    @include headline-6;
    border-radius: 4rem;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: $gray-70;
*/;

const NoDataContainer = rx('div', "rx-page-mode-shift-chart-4")/*
    @include text-body;
    font-size: 20px;
    font-weight: bold;
    color: $gray-70;
    min-height: 212rem;
    margin: auto;
    padding-top: 85rem;
*/;

const messages = defineMessages({
    'co2_tons_saved_by_day-description':
        "Change the time period above to view the total carbon emissions your shared micromobility program saved in your region. This estimate is based on the percentage of shared bike and scooter trips that replace car trips. We're working to include operator lifecycle emissions, like rebalancing, battery charging, or vehicle replacement.",
    'trip_duration_mode_shift-description':
        'Check out the increase in physical activity in your region! This metric estimates the total hours of physical activity generated by replacing vehicle trips with shared bike and scooter trips.',
    'car_trips_shifted-description':
        "This metric makes it easy to report on how many car trips were replaced by your shared micromobility program. This estimate is tailored to your region, and the percentage of shared mobility trips that would've been car trips.",
    'co2_tons_saved_by_day-title': 'Carbon Emissions Avoided',
    'trip_duration_mode_shift-title': 'Additional Physical Activity',
    'car_trips_shifted-title': 'Car Trips Replaced',
    'co2_tons_saved_by_day-total': 'Tons CO2e',
    'trip_duration_mode_shift-total': 'Hours',
    'car_trips_shifted-total': 'Trips'
});

function useModeShiftData(
    dateRange: DateRange,
    metric: ChartMetric | null,
    filter: Filter | null
) {
    const { slug } = useMapView();

    const [data, loading] = useReloadingData<
        ModeShiftMetricDataData,
        ModeShiftMetricDataArgs
    >(
        MODE_SHIFT_METRIC_QUERY,
        filter && !filter.error && metric
            ? {
                  slug,
                  start_date: dateRange[0].toString(),
                  end_date: dateRange[1].toString(),
                  metric
              }
            : 'skip'
    );
    return [
        data?.mapViewBySlug?.modeShift?.modeShiftData ?? null,
        loading
    ] as const;
}

function TotalsByVehicle(
    totalByVehicleClass: TotalByVehicleClass[],
    metric: ChartMetric,
    intl: IntlShape
) {
    // Display totals for vehicle classes in alphabetical order
    const result = [...totalByVehicleClass]
        .sort((a, b) =>
            a.vehicleType.valueOf().localeCompare(b.vehicleType.valueOf())
        )
        .map(item => {
            return (
                <li key={item.vehicleType}>
                    {intl.formatMessage(vehicleClasses[item.vehicleType])}:{' '}
                    <FormattedNumber
                        value={item.total ? item.total : 0}
                        maximumFractionDigits={2}
                    />{' '}
                    {intl.formatMessage(messages[`${metric}-total`])}
                </li>
            );
        });
    return <SubTotalContainer>{result}</SubTotalContainer>;
}

function ChartDescription(
    metric: ChartMetric,
    totalByVehicleClass: TotalByVehicleClass[] | null
) {
    const intl = useIntl();
    const overallTotal = totalByVehicleClass?.reduce(
        (value, tByVC) => value + (tByVC?.total ? tByVC.total : 0),
        0
    );
    return (
        <DescriptionContainer>
            <ul className={CONTEXT_PANEL_TITLE}>
                Total:{' '}
                <FormattedNumber
                    value={overallTotal ?? 0}
                    maximumFractionDigits={2}
                />{' '}
                {intl.formatMessage(messages[`${metric}-total`])}
            </ul>
            {totalByVehicleClass
                ? TotalsByVehicle(totalByVehicleClass, metric, intl)
                : null}
            <DescriptionContainer>
                {intl.formatMessage(messages[`${metric}-description`])}
            </DescriptionContainer>
        </DescriptionContainer>
    );
}

export function ModeShiftBlock({
    metric,
    areas,
    dateRange,
    preliminaryDates,
    hasPreliminaryData
}: ModeShiftBlockProps) {
    const filter = useFilter(metric, areas);
    const intl = useIntl();
    const [modeShiftData, mainLoading] = useModeShiftData(
        dateRange,
        metric,
        filter
    );

    const hasChartData =
        modeShiftData?.analyzeData?.dataLine?.dataPoints &&
        modeShiftData?.analyzeData?.dataLine?.dataPoints.some(
            dp => dp != null && dp.yValue != null
        ) &&
        modeShiftData?.analyzeData?.dataLine?.dataPoints?.length > 0;
    return (
        <ErrorBoundary>
            <Container>
                <Loading loading={mainLoading} kind="over-table" />
                <ContainerHeader>
                    {intl.formatMessage(messages[`${metric}-title`])}
                </ContainerHeader>
                <PageColumns balanced>
                    {ChartDescription(
                        metric,
                        modeShiftData?.totalByVehicleClass ?? null
                    )}
                    <Container>
                        {hasChartData ? (
                            <MetricChart
                                chart={{
                                    dateRange,
                                    metric,
                                    filter,
                                    data: modeShiftData?.analyzeData ?? null
                                }}
                                extraChart={null}
                                hasPreliminaryData={hasPreliminaryData}
                                preliminaryDates={preliminaryDates}
                            />
                        ) : (
                            <NoDataContainer>
                                <FormattedMessage
                                    key="no-chart-data-for-mode-shift-metric"
                                    defaultMessage="No data"
                                />
                            </NoDataContainer>
                        )}
                    </Container>
                </PageColumns>
            </Container>
        </ErrorBoundary>
    );
}
