import React, { useState } from 'react';
import "/opt/build/repo/src/common/modal.tsx?resplendence=true";
import ReactDOM from 'react-dom';
import { CSSTransition } from 'react-transition-group';

/*
@import "style";
*/;

/** A common animated modal with a custom hook, see usage below:
 *
 * import Modal, { useModal } from 'common/modal';
 *
 * const DemoComponent = () => {
 *  const { isModalShowing, toggle } = useModal();
 *
 *  return (
 *     <Modal isShowing={isShowing} hide={toggle}>
 *        <p>Modal Content</p>
 *     </Modal>
 *
 *     <Button onClick={toggle}>"Close><Button/>
 *  )
 * }
 */

const OVERLAY = "rx-common-modal-1"/*
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 24rem;
    background-color: rgba(0,0,0,0.5);
    transition-property: opacity;
    transition-duration: 300ms;

    & > div {
        transition: 300ms all;
    }
    &.exit {
        transition-duration: 300ms;
        pointer-events: none;
        & > div {
            transform: translateY(0);
            transition: opacity 300ms, transform 300ms;
        }
    }
    &.enter {
        & > div {
            transform: scale(0.9);
        }
    }
    &.enter-active, &.exit {
        background-color: rgba(0, 0, 0, 0.5);
        opacity: 1;
        & > div {
            transform: translateY(0rem);
        }
    }
    &.enter, &.exit-active {
        opacity: 0;
        background-color: rgba(0, 0, 0, 0);
        & > div {
            transform: translateY(-58rem);
        }
    }
*/;

const MODAL = "rx-common-modal-2"/*
    @include container;
    z-index: 1050;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0;
    padding: 24rem;
*/;

export function useModal() {
    const [isModalShowing, setIsModalShowing] = useState(false);

    function toggleModal() {
        setIsModalShowing(!isModalShowing);
    }

    return {
        isModalShowing,
        toggleModal
    };
}

interface ModalProps {
    isModalShowing: boolean;
    hide?: () => void;
    children: React.ReactNode;
}

function Modal({ isModalShowing, hide, children }: ModalProps) {
    return ReactDOM.createPortal(
        <CSSTransition
            in={isModalShowing}
            timeout={300}
            mountOnEnter
            unmountOnExit
        >
            <div onClick={hide} className={OVERLAY}>
                <div
                    className={MODAL}
                    onClick={e => e.stopPropagation()}
                    aria-modal
                    aria-hidden
                    tabIndex={-1}
                    role="dialog"
                >
                    {children}
                </div>
            </div>
        </CSSTransition>,
        document.body
    );
}

export default Modal;

export const MODAL_TITLE = "rx-common-modal-3"/*
    @include headline-5;
*/;
export const MODAL_FORM = "rx-common-modal-4"/*
    display: grid;
    grid-auto-flow: row;
    grid-gap: 16rem;
    label {
        @include text-label;
        color: $gray-70;
    }
    width: 400rem;
*/;
export const MODAL_BUTTONS = "rx-common-modal-5"/*
    margin-top: 16rem;
    justify-self: end;
    display: grid;
    grid-auto-flow: column;
    grid-gap: 8rem;
*/;
