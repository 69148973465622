import { useContext } from 'react';
import { ContextError } from 'utils/errors';
import { UserContext, ORGANIZATION_INFO_FRAGMENT } from './user-context';
import { gql, useMutation } from '@apollo/client';
import {
    JoinOrganizationResult,
    JoinOrganizationMutationArgs
} from 'graphql.g';

/**
 * Regularly-needed info about the current user.
 * Supplied by a <UseProvider> somewhere higher up in the component tree.
 */
export function useUser() {
    const user = useContext(UserContext);
    if (!user) throw new ContextError('User');
    return user;
}

const JOIN_ORG_MUTATION = gql`
    mutation JoinOrganization($org: ID!, $role: UserRoleType!) {
        joinOrganization(orgId: $org, role: $role) {
            user {
                id
                orgAuth {
                    id
                    role
                    organization {
                        ...OrganizationInfo
                    }
                }
            }
        }
    }
    ${ORGANIZATION_INFO_FRAGMENT}
`;

export const useJoinOrgMutation = () =>
    useMutation<JoinOrganizationResult, JoinOrganizationMutationArgs>(
        JOIN_ORG_MUTATION
    );
