import React from 'react';
import { Map } from 'mapbox-gl';
import { MapOptionsAction, MapOptions } from 'page-explore';
import { useQueryState } from 'router';
import MapRoutes from './routes';
import MapHexBins from './hex-bins';
import useDocumentTitle from 'common/use-document-title';
import { defineMessages, useIntl } from 'react-intl';
import CurbEvents from './curb-events';
import { useMapView } from 'common/use-map-view';
import { FeatureFlaggedFeature } from 'graphql.g';
import { useUser } from 'common/user';

type Props = {
    map: Map | null;
    mapOptions: MapOptions;
    dispatchMapOptions: React.Dispatch<MapOptionsAction>;
    clientHeight: number | null;
};

export function useMapModes(): MapMode[] {
    const user = useUser();
    const { enabledFeatures: mapviewEnabledFeatures } = useMapView();

    const baseModes: MapMode[] = ['trip_starts', 'trip_ends', 'routes'];
    if (
        mapviewEnabledFeatures.includes(FeatureFlaggedFeature.trip_events) ||
        user.isStaff
    ) {
        return baseModes.concat('curb_events');
    }
    return baseModes;
}

export type MapMode = 'trip_starts' | 'trip_ends' | 'routes' | 'curb_events';

export function useMapMode(active: boolean = true) {
    const mapModes = useMapModes();
    const [mode, setMode] = useQueryState<MapMode>('mode', {
        constrain: {
            options: new Set(mapModes),
            fallbackValue: 'routes'
        },
        active,
        addToHistory: true
    });
    return [mode, setMode] as const;
}

const messages = defineMessages({
    'routes-document-title': 'Routes · Ride Report',
    'trip_starts-document-title': 'Trip Starts · Ride Report',
    'trip_ends-document-title': 'Trip Ends · Ride Report',
    'curb_events-document-title': 'Curb Events · Ride Report'
});

function HeatmapsPage(props: Props) {
    const [mode] = useMapMode();
    const intl = useIntl();
    useDocumentTitle(intl.formatMessage(messages[`${mode}-document-title`]));

    if (mode === 'routes') return <MapRoutes {...props} />;
    if (mode === 'trip_ends') return <MapHexBins {...props} metric="ends" />;
    if (mode === 'trip_starts')
        return <MapHexBins {...props} metric="starts" />;
    return <CurbEvents {...props} />;
}

export default HeatmapsPage;
