import React, { useEffect } from 'react';

import { PolicyFormProps } from './types';
import { AllOrSingleOperatorSelect } from './operator-selectors';
import VehicleClassInput from './vehicle-class-selector';
import {
    NumberInput,
    TimeSelectInput,
    DEFAULT_END_TIME,
    DEFAULT_START_TIME
} from './common';
import { FormattedMessage } from 'react-intl';
import { useMapView } from 'common/use-map-view';
import { MeasurementUnitSystemType } from 'graphql.g';
import DayOfWeekInput from './day-of-week-selector';

function SpeedLimitForm({
    policy,
    setPolicy,
    formErrors,
    setFormErrors
}: PolicyFormProps) {
    const { measurementSystem } = useMapView();

    useEffect(() => {
        const hasMaximumError = policy.maximum == null;
        // set a maximum error if it doesn't already exist
        if (formErrors.maximum !== hasMaximumError) {
            setFormErrors({
                ...formErrors,
                maximum: hasMaximumError
            });
        }
    }, [policy.maximum, formErrors, setFormErrors]);

    return (
        <>
            <NumberInput
                width={128}
                label={
                    <FormattedMessage
                        key="speed-limit-label"
                        defaultMessage="Speed Limit:"
                    />
                }
                numberLabelPosition="right"
                numberLabel={
                    measurementSystem === MeasurementUnitSystemType.IMPERIAL
                        ? 'MPH'
                        : 'KPH'
                }
                id="speed-limit-input"
                value={policy.maximum ?? ''}
                min="1"
                step="1"
                onChange={newValue => {
                    const amount = parseInt(newValue);
                    setPolicy({
                        ...policy,
                        maximum: Number.isNaN(amount) ? undefined : amount
                    });
                }}
            />

            <TimeSelectInput
                label={
                    <FormattedMessage
                        key="time-label-start"
                        defaultMessage="Start time:"
                    />
                }
                id="start-time-input"
                selectedTime={policy.startTime ?? DEFAULT_START_TIME}
                onChange={startTime => {
                    setPolicy({
                        ...policy,
                        startTime
                    });
                }}
            />

            <TimeSelectInput
                label={
                    <FormattedMessage
                        key="time-label-end"
                        defaultMessage="End time:"
                    />
                }
                id="end-time-input"
                selectedTime={policy.endTime ?? DEFAULT_END_TIME}
                onChange={endTime =>
                    setPolicy({
                        ...policy,
                        endTime
                    })
                }
            />

            <DayOfWeekInput
                days={policy.days}
                onChange={days =>
                    setPolicy({
                        ...policy,
                        days
                    })
                }
            />

            <VehicleClassInput policy={policy} setPolicy={setPolicy} />

            <AllOrSingleOperatorSelect
                policy={policy}
                setPolicy={setPolicy}
                formErrors={formErrors}
                setFormErrors={setFormErrors}
            />
        </>
    );
}

export default SpeedLimitForm;
