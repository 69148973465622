import React, { useState } from 'react';
import "/opt/build/repo/src/root/app-header.tsx?resplendence=true";
import { Link } from 'react-router-dom';
import rideLogo from 'assets/logo.svg';
import logoIcon from 'assets/logo_icon.svg';
import printLogo from 'assets/logo_mono.svg';
import Icon from 'common/icon';
import cx from 'classnames';
import config from 'config';
import { FormattedMessage, defineMessages } from 'react-intl';
import orderBy from 'lodash/orderBy';
import { AbsoluteLink, ParamLink } from 'router';
import { useIntl } from 'utils/use-intl';
import { useAuthInfo } from 'common/authentication';
import { useUser, useJoinOrgMutation } from 'common/user';
import { useRouteMatch } from 'react-router-dom';
import { useOptionalMapView } from 'common/use-map-view';
import { FeatureFlaggedFeature, UserRoleType } from 'graphql.g';
import SearchInput from 'common/search-input';
import useSearchFilter from 'utils/search';
import { MapView } from 'types';

/*
@import 'style.scss';
*/;

const DROPDOWN_WRAPPER = "rx-root-app-header-1"/*
    position: relative;
*/;

const DROPDOWN_BUTTON = "rx-root-app-header-2"/*
    color: $white;
    display: flex;
    align-items: center;
    @include text-body;
    font-size: 16px;
    &:hover {
        svg {
            color: $white;
        }
    }
    @media print {
        color: black;
    }
*/;

const DROPDOWN_ICON = "rx-root-app-header-3"/*
    font-size: 20px;
    color: $gray-70;
    margin-left: 1px;
    transition-property: color;
    transition-duration: 150ms;
    @media print {
        display: none;
    }
*/;

const DROPDOWN = "rx-root-app-header-4"/*
    background-color: $white;
    border-radius: 6px;
    @include shadow-8;
    position: absolute;
    z-index: 100;
    top: calc(100% + 8px);
    right: 0;
    padding: 12px 0px;
    width: 240px;
    opacity: 0;
    transition-property: opacity;
    transition-duration: 150ms;
    box-sizing: border-box;
    pointer-events: none;
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    &.show {
        pointer-events: auto;
        opacity:1;
    }
    @include mobile-tiny {
        left: 20px;
    }
*/;

const DROPDOWN_OUTER = "rx-root-app-header-5"/*
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    z-index: 99;
*/;

type DropdownProps = {
    label: string;
    children: any;
};

function Dropdown({ label, children }: DropdownProps) {
    const [isDropped, setIsDropped] = useState(false);

    const toggleMenu = () => {
        setIsDropped(!isDropped);
    };

    return (
        <div className={DROPDOWN_WRAPPER}>
            {isDropped ? (
                <div
                    className={DROPDOWN_OUTER}
                    onClick={() => {
                        setIsDropped(false);
                    }}
                />
            ) : null}
            <button
                type="button"
                className={DROPDOWN_BUTTON}
                onClick={toggleMenu}
            >
                {label}
                <Icon
                    icon={isDropped ? 'Up' : 'Down'}
                    className={DROPDOWN_ICON}
                />
            </button>
            <div className={cx(DROPDOWN, { show: isDropped })}>{children}</div>
        </div>
    );
}

const LOGO_LINK = "rx-root-app-header-6"/*
    height: 100%;
    width: auto;
    flex: 0 0 auto;
*/;

const MOBILE_MENU_BUTTON = "rx-root-app-header-7"/*
    @include button;

    height: 36px;
    font-size: 16px;
    @include not-mobile-tiny {
        display: none;
    }
    @media print {
        display: none;
    }
    background-color: transparent;
    border-color: transparent;
    color: $white;
    font-size: 32px;
    padding: 0 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 8px;
    &:hover {
        background-color: $gray-60;
    }
*/;

const USER_BUTTON = "rx-root-app-header-8"/*
    margin: 0 12px;
    padding: 4px 12px;
    flex: 0 0 auto;
    color: $gray-90;
    @include text-body-link;
    font-size: 16px;
    &:hover {
        background: $gray-30;
    }
    border-radius: 6px;
*/;

const HEADER_SECTION = "rx-root-app-header-9"/*
    display: grid;
    grid-template-columns: auto auto;
    grid-column-gap: 63px;
    @include mobile-tiny {
        width: 100%;
        display: flex;
        flex-flow: column;
        align-items: flex-start;
        justify-content: space-between;
    }
*/;

const SEARCH_LINK = "rx-root-app-header-10"/*
    padding: 4px 12px;
    margin: 0 10px 0 12px;
    color: $gray-90;
    @include text-body-link;
    font-size: 16px;
    border-radius: 3px;
    z-index: 1;
    &.active {
        background-color: $blue-50;
        color: white;
        cursor: default;
    }
    &:not(.active):not(.disabled):hover {
        background: $gray-10;
    }
    &.disabled {
        color: $gray-50;
        cursor: default;
        background-color: white;
    }
*/;
const MAPVIEW_LINKS_WRAPPER = "rx-root-app-header-11"/*
    margin-right: 2px;
    &:after {
        content: "";
        position: absolute;
        top: 55px;
        left: 0;
        width: calc(100% - 10px);
        height: 18px;
        background: linear-gradient(180deg, white 0%, rgba(255, 255, 255, 0) 100%);
    }
    &:before {
        content: "";
        position: absolute;
        bottom: 12px;
        left: 0;
        width: calc(100% - 10px);
        height: 18px;
        background: linear-gradient(0deg, white 0%, rgba(255, 255, 255, 0) 100%);
    }
*/;

const MAP_VIEW_LINKS = "rx-root-app-header-12"/*
    max-height: 512px;
    overflow-y: auto;
    display: flex;
    flex-flow: column nowrap;
*/;

const FEATURE_PREVIEW = "rx-root-app-header-13"/*
    position: absolute;
    top: 100%;
    width: 100%;
    border-top: 4rem solid $green-50;
    left: 0;
    z-index: 13;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    span {
        background-color: $green-50;
        color: $white;
        @include text-label-bold;
        font-size: 12.8rem;
        padding: 0 8rem 2rem 8rem;
        border-radius: 0 0 3rem 3rem;
        margin-top: -3rem;
        position: relative;
    }
*/;

const m = defineMessages({
    'all-service-areas-link': 'All Service Areas',
    'regional-dashboard-link': 'Regional Dashboard'
});

type Props = {
    toggleMobileNavMenu?: () => void;
    onRegionalDashboard?: boolean;
};

export function Logos() {
    return (
        <>
            <img alt="Ride Report" src={logoIcon} className="logo icon" />
            <img alt="Ride Report" src={rideLogo} className="logo full" />
            <img alt="Ride Report" src={printLogo} className="logo print" />
        </>
    );
}

/**
 * The top-level header component for the app.
 */
function AppHeader({
    toggleMobileNavMenu,
    onRegionalDashboard = false
}: Props) {
    const mapView = useOptionalMapView();
    const user = useUser();
    const isOperator =
        user.orgAuth.organization.__typename === 'OperatorOrganization';
    const mapViews = user.orgAuth.organization.mapViews;
    const authInfo = useAuthInfo();
    const intl = useIntl();
    const [searchText, setSearchText] = useState('');
    const match = useRouteMatch<{ mapview?: string }>();

    const filteredMapViews = useSearchFilter(
        mapViews,
        mv => mv.name + ' ' + mv.slug,
        searchText
    );

    const [joinOrg] = useJoinOrgMutation();

    const dropdownLabel = useMapViewDropDownLabel(onRegionalDashboard, mapView);

    const signout = () => {
        fetch(`${config.authHost}/logout`, {
            method: 'POST',
            headers: { Authorization: 'Bearer ' + authInfo.token }
        }).then(result => {
            if (result.status >= 300) {
                throw Error(`Logout returned non-200 code ${result.status}`);
            }
            authInfo.logout();
        });
    };

    return (
        <header>
            <Link to="/" className={LOGO_LINK}>
                <Logos />
            </Link>

            <div className={HEADER_SECTION}>
                {mapViews.length > 1 || !mapView ? (
                    <Dropdown label={dropdownLabel}>
                        <SearchInput
                            value={searchText}
                            onChange={setSearchText}
                        />
                        <div className={MAPVIEW_LINKS_WRAPPER}>
                            <div className={MAP_VIEW_LINKS}>
                                {isOperator ? (
                                    <AbsoluteLink
                                        className={SEARCH_LINK}
                                        to={'/'}
                                        exact
                                        nav
                                    >
                                        {intl.formatMessage(
                                            m['all-service-areas-link']
                                        )}
                                    </AbsoluteLink>
                                ) : null}
                                {!user.isStaff &&
                                    user.enabledFeatures.includes(
                                        FeatureFlaggedFeature.regional_dashboard
                                    ) && (
                                        <AbsoluteLink
                                            className={SEARCH_LINK}
                                            to={'/'}
                                            key={'regional-dashboard'}
                                            exact
                                            nav
                                        >
                                            {intl.formatMessage(
                                                m['regional-dashboard-link']
                                            )}
                                        </AbsoluteLink>
                                    )}
                                {(searchText
                                    ? filteredMapViews
                                    : orderBy(mapViews, 'name')
                                ).map(mv =>
                                    match.params.mapview ? (
                                        <ParamLink
                                            className={SEARCH_LINK}
                                            key={mv.slug}
                                            params={{
                                                mapview: mv.slug
                                            }}
                                            disabled={!mv.hasAtLeastOneMdsFeed}
                                            nav
                                        >
                                            {mv.name}
                                        </ParamLink>
                                    ) : (
                                        <AbsoluteLink
                                            className={SEARCH_LINK}
                                            to={'/' + mv.slug}
                                            key={mv.slug}
                                            disabled={!mv.hasAtLeastOneMdsFeed}
                                            nav
                                        >
                                            {mv.name}
                                        </AbsoluteLink>
                                    )
                                )}
                            </div>
                        </div>
                    </Dropdown>
                ) : (
                    <AbsoluteLink className={DROPDOWN_BUTTON} to={'/'} exact>
                        {mapView.name}
                    </AbsoluteLink>
                )}
                <Dropdown label={user.email}>
                    <AbsoluteLink to="/settings" className={USER_BUTTON}>
                        <FormattedMessage
                            key="settings-link"
                            defaultMessage="Settings"
                        />
                    </AbsoluteLink>
                    <a href="/terms" target="_blank" className={USER_BUTTON}>
                        <FormattedMessage
                            key="terms-link"
                            defaultMessage="Terms"
                        />
                    </a>
                    {user.isStaff && (
                        <>
                            <AbsoluteLink to="/~admin" className={USER_BUTTON}>
                                Change Organization
                            </AbsoluteLink>
                            <button
                                type="button"
                                className={USER_BUTTON}
                                onClick={() =>
                                    user.setUseDemoFeatures(
                                        !user.useDemoFeatures
                                    )
                                }
                            >
                                {user.useDemoFeatures ? (
                                    <FormattedMessage
                                        key="hide-demo-features-button"
                                        defaultMessage="Hide demo features"
                                    />
                                ) : (
                                    <FormattedMessage
                                        key="show-demo-features-button"
                                        defaultMessage="Show demo features"
                                    />
                                )}
                            </button>
                            <button
                                type="button"
                                className={USER_BUTTON}
                                onClick={() => {
                                    joinOrg({
                                        variables: {
                                            org: user.orgAuth.organization.id,
                                            role:
                                                user.orgAuth.role ===
                                                UserRoleType.Administrator
                                                    ? UserRoleType.User
                                                    : UserRoleType.Administrator
                                        }
                                    });
                                }}
                            >
                                View as
                                {user.orgAuth.role ===
                                UserRoleType.Administrator
                                    ? ' non-admin'
                                    : ' admin'}
                            </button>
                            {mapView != null && (
                                <AbsoluteLink
                                    to={`/${mapView.slug}/segment-editor`}
                                    className={USER_BUTTON}
                                >
                                    Segment Editor
                                </AbsoluteLink>
                            )}
                        </>
                    )}
                    <button
                        type="button"
                        className={USER_BUTTON}
                        onClick={signout}
                    >
                        <FormattedMessage
                            key="signout-button"
                            defaultMessage="Sign Out"
                        />
                    </button>
                </Dropdown>
            </div>
            {toggleMobileNavMenu ? (
                <button
                    type="button"
                    className={MOBILE_MENU_BUTTON}
                    onClick={toggleMobileNavMenu}
                >
                    <Icon icon="Hamburger" />
                </button>
            ) : null}
            {/* if the user has demo features, show a banner. */}
            {user.useDemoFeatures ? (
                <div className={FEATURE_PREVIEW}>
                    <FormattedMessage
                        tagName="span"
                        key="feature-preview"
                        defaultMessage="Feature Preview"
                    />
                </div>
            ) : null}
        </header>
    );
}

// This is a temporary workaround. See: https://github.com/RideReport/tickets/issues/4263
function useMapViewDropDownLabel(
    onRegionalDashboard: boolean,
    mapView: MapView | null
) {
    const intl = useIntl();
    if (onRegionalDashboard) {
        return intl.formatMessage(m['regional-dashboard-link']);
    }
    return mapView
        ? mapView.name
        : intl.formatMessage(m['all-service-areas-link']);
}

export default AppHeader;
