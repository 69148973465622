import * as React from 'react';
import { Link } from 'react-router-dom';
import "/opt/build/repo/src/root/page-home.tsx?resplendence=true";
import AppHeader from './app-header';
import { IntlProvider, useIntl } from 'utils/use-intl';
import { FormattedMessage, defineMessages } from 'react-intl';
import orderBy from 'lodash/orderBy';
import cx from 'classnames';
import { PageContent, Banner, OperatorBanner } from 'common/layout';
import { useUser } from 'common/user';
import Icon from 'common/icon';
import { Title } from 'common/title';

/*
@import 'style';
*/;

const LINK = "rx-root-page-home-1"/*
    @extend %link;
    display: block;
    font-size: 16rem;
    color: $blue-50;
*/;

const MAP_VIEWS = "rx-root-page-home-2"/*
    display: grid;
    grid: auto-flow / 1fr;
    row-gap: 16rem;
*/;

const MAP_VIEW = "rx-root-page-home-3"/*
    display: flex;
    flex: 0 0 auto;
    flex-flow: row;
    align-items: center;
    justify-content: space-between;
    padding: 16rem 24rem;
    min-height: 64rem;
    background: $white;
    border: $gray-30 1rem solid;
    box-sizing: border-box;
    border-radius: 6rem;
    &:last-child {
        margin-bottom: 64rem;
    }
    &.alert {
        background: $red-00;
        border: 1rem solid $red-50;
    }
    @include mobile{
        padding: 8rem 12rem;
    }
*/;

const MAP_VIEW_DETAIL = "rx-root-page-home-4"/*
    display: grid;
    grid-template-columns: auto auto;
    grid-column-gap: 12rem;
    align-items: center;
    position:relative;
    &.alert {
        color: $red-50;
    }
    @include mobile {
        grid-column-gap: 6rem;
    }
*/;

const MAP_VIEW_NAME = "rx-root-page-home-5"/*
    font-size: 20rem;
    @include mobile{
        font-size: 18rem;
        margin-right: 4rem;
    }
*/;

const DOT = "rx-root-page-home-6"/*
    height: 12rem;
    width: 12rem;
    fill: $green-50;
    border-radius: 50%;
    position: relative;
    &.alert {
        fill: $red-50;
        @include blinking-animation;
    }
*/;

const NO_MDS_TOKEN = "rx-root-page-home-7"/*
    @include button;
*/;

type EmailProps = {
    service_area_name: String;
};

function RequestMdsTokenEmail({ service_area_name }: EmailProps) {
    const intl = useIntl();
    const toAddress = 'michal@ridereport.com';
    const ccAddress = 'mds@ridereport.com';
    const subject = `New MDS Endpoints for ${service_area_name}`;
    const body = `
    Hi Ride Report,%0A
    %0A
    We have just granted you MDS access to ${service_area_name} and have uploaded authentication information for our feeds to Ride Report's secure direct drop.%0A
    %0A
    ## Additional Information (Optional)%0A
    %0A
    URLS: (Insert MDS URLs)%0A
    %0A
    Comments: (Insert any additional comments, notes, etc.)%0A
    %0A
    Regards,%0A
    `;

    return (
        <a
            href={`mailto:${toAddress}?cc=${ccAddress}&subject=${subject}&body=${body}`}
            className={cx(NO_MDS_TOKEN, 'red')}
        >
            {intl.formatMessage(m['provide-mds-token'])}
        </a>
    );
}

const m = defineMessages({
    'view-service-area': 'View Service Area',
    'provide-mds-token': 'Provide MDS Token',
    'hello-operators': 'Hello, {name}! Here’s a look at your service areas.'
});

/**
 * A region ("mapview") picker.
 */
function HomePage() {
    const user = useUser();
    const mapViews = user.orgAuth.organization.mapViews;

    return (
        <IntlProvider locale={user.orgAuth.organization.locale}>
            <AppHeader />
            <PageContent>
                <Title
                    title={
                        <FormattedMessage
                            id="operator-title"
                            defaultMessage="Service Areas"
                        />
                    }
                />
                {user.orgAuth.organization.__typename ===
                'OperatorOrganization' ? (
                    <OperatorBanner
                        name={user.orgAuth.organization.operatorInfo.name}
                    />
                ) : (
                    <Banner>
                        <FormattedMessage
                            key="admin-banner"
                            defaultMessage="Admin"
                        />
                    </Banner>
                )}
                <div className={MAP_VIEWS}>
                    {orderBy(mapViews, mv => mv.name).map(mv => {
                        const alertNoMds = !mv.hasAtLeastOneMdsFeed;
                        return (
                            <div
                                className={cx(MAP_VIEW, {
                                    alert: alertNoMds
                                })}
                                key={mv.slug}
                            >
                                <div
                                    className={cx(MAP_VIEW_DETAIL, {
                                        alert: alertNoMds
                                    })}
                                >
                                    <Icon
                                        icon="Circle"
                                        className={cx(DOT, {
                                            alert: alertNoMds
                                        })}
                                    />
                                    <div className={MAP_VIEW_NAME}>
                                        {mv.name}
                                    </div>
                                </div>
                                <div>
                                    {!alertNoMds ||
                                    user.orgAuth.organization.__typename ===
                                        'OperatorOrganization' ? (
                                        <Link
                                            className={LINK}
                                            key={mv.slug}
                                            to={mv.slug}
                                        >
                                            <FormattedMessage
                                                id="view-service-area"
                                                defaultMessage="View Service Area"
                                            />
                                        </Link>
                                    ) : (
                                        <RequestMdsTokenEmail
                                            service_area_name={mv.name}
                                        />
                                    )}
                                </div>
                            </div>
                        );
                    })}
                </div>
            </PageContent>
        </IntlProvider>
    );
}

export default HomePage;
