import React from 'react';
import { useMapMode } from '..';
import { useRef, useEffect } from 'react';
import "/opt/build/repo/src/page-explore/page-heatmaps/hex-bins/popup.tsx?resplendence=true";

import { h3ToGeo } from 'h3-js';
import { Map, Popup } from 'mapbox-gl';
import { defineMessages, useIntl } from 'react-intl';
import Icon from 'common/icon';

const m = defineMessages({
    'tooltip-starts-selected-same':
        '<b>{count, number}</b> trips started {startsIcon} and ended {endsIcon} in the selected hex.',
    'tooltip-starts-selected':
        '<b>{count, number}</b> trips started in the selected hex and ended {endsIcon} here.',
    'tooltip-starts-below-privacy-threshold':
        '<b>{count, number}</b> starts {startsIcon}',
    'tooltip-starts-all':
        '<b>{count, number}</b> starts {startsIcon}. Click to see where these trips ended {endsIcon}.',
    'tooltip-ends-selected-same':
        '<b>{count, number}</b> trips started {startsIcon} and ended {endsIcon} in the selected hex.',
    'tooltip-ends-selected':
        '<b>{count, number}</b> trips started {startsIcon} here and ended in the selected hex.',
    'tooltip-ends-below-privacy-threshold':
        '<b>{count, number}</b> ends {endsIcon}',
    'tooltip-ends-all':
        '<b>{count, number}</b> ends {endsIcon}. Click to see where these trips started {startsIcon}.'
});

/*
@import 'style.scss';
*/;
const POPUP = "rx-page-explore-page-heatmaps-hex-bins-popup-1"/*
    @include text-label;
    padding: 8rem;
    pointer-events: none;
    color: $gray-70;
    svg {
        fill: $blue-50;
        font-size: 28rem;
        width: 1em;
        height: 1em;
        margin: -9rem -3rem;
    }
*/;
const POPUP_NUMBER = "rx-page-explore-page-heatmaps-hex-bins-popup-2"/*
    @include text-label-bold;
    color: $gray-90;
*/;

type Hex = {
    hex: string;
    count: number;
};

type TripsPopupProps = {
    map: Map;
    hoveredHex: Hex | null;
    selectedHexId: string | null;
    privacyThreshold: number | null;
};

const TripsPopup = ({
    map,
    hoveredHex,
    selectedHexId,
    privacyThreshold
}: TripsPopupProps) => {
    const intl = useIntl();

    const popupRef = useRef<HTMLDivElement>(null);
    // Show popup when vehicle is selected
    useEffect(() => {
        if (popupRef.current && hoveredHex) {
            const copy = popupRef.current.cloneNode(true);
            const latLng = h3ToGeo(hoveredHex.hex) as [number, number];
            const popup = new Popup({ maxWidth: '480rem' })
                .setLngLat([latLng[1], latLng[0]])
                .setDOMContent(copy)
                .addTo(map);
            return () => {
                popup.remove();
            };
        }
    }, [map, hoveredHex]);

    const [mode] = useMapMode();
    const popupMode = mode === 'trip_starts' ? 'starts' : 'ends';
    const belowPrivacyThreshold =
        hoveredHex && privacyThreshold && hoveredHex?.count <= privacyThreshold;
    const popupHex = selectedHexId
        ? selectedHexId === hoveredHex?.hex
            ? 'selected-same'
            : 'selected'
        : belowPrivacyThreshold
        ? 'below-privacy-threshold'
        : 'all';

    return (
        <div style={{ display: 'none' }}>
            <div
                className={POPUP}
                ref={popupRef}
                key={`tooltip-${popupMode}-${popupHex}`}
            >
                {intl.formatMessage(m[`tooltip-${popupMode}-${popupHex}`], {
                    count: hoveredHex?.count ?? 0,
                    b: msg => [
                        <span
                            key={`number-${popupMode}-${popupHex}`}
                            className={POPUP_NUMBER}
                        >
                            {msg}
                        </span>
                    ],
                    startsIcon: (
                        <Icon
                            key={`start-icon-${popupMode}-${popupHex}`}
                            icon="Trip-Start"
                        />
                    ),
                    endsIcon: (
                        <Icon
                            key={`end-icon-${popupMode}-${popupHex}`}
                            icon="Trip-End"
                        />
                    )
                })}
            </div>
        </div>
    );
};

export default TripsPopup;
