import gql from 'graphql-tag';
import { User } from 'types';
import { createContext } from 'react';

/** Context for info about the current user */
export const UserContext = createContext<User | null>(null);

/** Fetch common info about a given organization */
export const ORGANIZATION_INFO_FRAGMENT = gql`
    fragment OrganizationInfo on Organization {
        __typename
        id
        name
        locale
        mapViews {
            id
            name
            slug
            hasAtLeastOneMdsFeed
        }
        ... on OperatorOrganization {
            operatorInfo {
                id
                name
            }
        }
    }
`;
