import React from 'react';
import { Switch } from 'router';
import ListPage from './page-list';
import UploadPage from './page-upload';

function CustomDataPage() {
    return (
        <Switch
            pages={[
                {
                    path: '/upload',
                    component: UploadPage
                },
                {
                    component: ListPage
                }
            ]}
        />
    );
}

export default CustomDataPage;
