import React, { useCallback, useState } from 'react';
import {
    PageContent,
    Container,
    ContainerHeader,
    PageBody,
    ContainerMessage
} from 'common/layout';
import { Title } from 'common/title';
import { FormattedMessage } from 'react-intl';

import { LocalDate } from 'utils/date-tools';
import { useMapView } from 'root/page-region';
import { MapViewProvider } from 'common/use-map-view';
import { User } from 'types';
import NavigationMenu from 'root/navigation-menu';
import AppHeader from 'root/app-header';
import {
    DateButtons,
    DATE_PICKER_CONTAINER,
    DateRangeTextOnly
} from 'common/date-picker';
import useDateRangeQuery from 'utils/use-date-range-query';
import {
    AggregationTripDataType,
    AnalyzeChartData,
    OrganizationInfo_AgencyOrganization_Fragment,
    RegionalAnalyzeChartArgs,
    RegionalAnalyzeChartData,
    RegionalEarliestReportDatesArgs,
    RegionalEarliestReportDatesData,
    VehicleClass
} from 'graphql.g';
import gql from 'graphql-tag';
import useData, { useReloadingData } from 'utils/use-data';
import LineChart from 'page-analyze/multi-line-graph';
import { useQueryState } from 'router';
import Loading from 'common/loading';
import "/opt/build/repo/src/page-regional-dashboard/index.tsx?resplendence=true";
import { useEffect } from 'react';
import RegionSidebar, { useAllAvailableVehicleClasses } from './region-sidebar';
import JurisdictionTable from './region-jurisdiction-table';

export type MapViews = OrganizationInfo_AgencyOrganization_Fragment['mapViews'];

type AnalyzeData = NonNullable<AnalyzeChartData['mapView']['analyzeData']>;

/*
    @import 'style';
*/;

const PAGE_LAYOUT = "rx-page-regional-dashboard-index-1"/*
    display: grid;
    grid-template-columns: 3fr minmax(100rem, 1fr);
    @include mobile {
        grid-template-columns: 1fr;
    }
    grid-gap: 24rem;
    align-items: start;
*/;

const REGIONAL_DASH_CONTAINER = "rx-page-regional-dashboard-index-2"/*
    display: flex;
    flex-direction: column;
    row-gap: 32rem;
    column-gap: 32rem;
*/;
interface RegionalDashboardProps {
    mapViews: MapViews;
    user: User;
}

export const REGIONAL_EARLIEST_REPORT_DATE_QUERY = gql`
    query RegionalEarliestReportDates {
        mapViews {
            earliestReportDate
        }
    }
`;

export const REGIONAL_ANALYZE_QUERY = gql`
    query RegionalAnalyzeChart(
        $operatorId: ID
        $areaId: ID
        $start: Date!
        $end: Date!
        $metric: String!
        $vehicleClass: VehicleClass
        $tripDataType: AggregationTripDataType
    ) {
        mapViews {
            slug
            name
            id
            analyzeData(
                operatorId: $operatorId
                areaId: $areaId
                startDate: $start
                endDate: $end
                metric: $metric
                vehicleClass: $vehicleClass
                tripDataType: $tripDataType
            ) {
                xUnit
                yUnit
                aggregateUnit
                totalCountUnit
                dataLine {
                    group
                    dataPoints {
                        xValue
                        yValue
                        percentageOfTotal
                    }
                    sumTotal
                    totalCount
                }
            }
        }
    }
`;

export function RegionalDashboardHomepage({ user }: RegionalDashboardProps) {
    // For now we need to set a selected mapView to be able to make queries and use the navigation menu without errors from MapViewProvider
    const firstMapViewSlug = user?.orgAuth?.organization.mapViews[0].slug;
    const mapView = useMapView(firstMapViewSlug, user.isStaff);

    const [expandOnMobile, setExpandOnMobile] = useState(false);
    const toggleMobileNavMenu = useCallback(
        () => setExpandOnMobile(show => !show),
        []
    );

    const yesterdaysDate = LocalDate.fromDate(new Date()).minusDays(1);
    const [
        earliestReportDateOfAllMapViews,
        setEarliestReportDateOfAllMapViews
    ] = useState<LocalDate>(yesterdaysDate);

    const [dateRange, setDateRange] = useDateRangeQuery([
        yesterdaysDate.minusDays(28),
        yesterdaysDate
    ]);

    const earliestDate = useEarliestDate(earliestReportDateOfAllMapViews);

    if (earliestDate.isBefore(earliestReportDateOfAllMapViews)) {
        setEarliestReportDateOfAllMapViews(earliestDate);
    }

    const [vehicleClasses, ,] = useAllAvailableVehicleClasses();

    const [vehicleClass, setVehicleClass] = useQueryState('vehicleClass', {
        constrain: {
            options: new Set([...vehicleClasses, null]),
            fallbackValue: null
        }
    });

    useEffect(() => {
        if (vehicleClass === 'all_vehicles') {
            setVehicleClass(null);
        }
    }, [vehicleClass, setVehicleClass]);

    const [analyzeData, loading] = useReloadingData<
        RegionalAnalyzeChartData,
        RegionalAnalyzeChartArgs
    >(REGIONAL_ANALYZE_QUERY, {
        operatorId: null,
        areaId: null,
        start: dateRange[0].toString(),
        end: dateRange[1].toString(),
        metric: 'trips_by_date',
        vehicleClass: vehicleClass as VehicleClass | null,
        tripDataType: AggregationTripDataType.trip_starts
    });

    if (mapView == null) {
        return null;
    }

    return (
        <MapViewProvider value={mapView}>
            <AppHeader
                toggleMobileNavMenu={toggleMobileNavMenu}
                onRegionalDashboard={true}
            />
            <NavigationMenu
                setExpandOnMobile={setExpandOnMobile}
                expandOnMobile={expandOnMobile}
            />
            <PageContent>
                <Title
                    title={
                        <FormattedMessage
                            key="page-title"
                            defaultMessage="Regional Dashboard"
                        />
                    }
                ></Title>
                <PageBody>
                    <div className={PAGE_LAYOUT}>
                        <div className={REGIONAL_DASH_CONTAINER}>
                            <RegionalDatePicker
                                earliestReportDate={
                                    earliestReportDateOfAllMapViews
                                }
                                dateRange={dateRange}
                                setDateRange={setDateRange}
                            />
                            <Container style={{ zIndex: 1 }}>
                                <ContainerHeader>
                                    <FormattedMessage
                                        key="city-trips-by-day"
                                        defaultMessage="City Trips By Day"
                                    />
                                </ContainerHeader>
                                <Loading
                                    loading={loading}
                                    kind={'over-table'}
                                />
                                {analyzeData ? (
                                    <LineChart
                                        data={
                                            analyzeData.mapViews
                                                .map(
                                                    analyzeDataWrapper =>
                                                        analyzeDataWrapper.analyzeData
                                                )
                                                .filter(
                                                    maybeAnalyzeData =>
                                                        maybeAnalyzeData !==
                                                        null
                                                ) as AnalyzeData[]
                                        }
                                        seriesNames={analyzeData.mapViews.map(
                                            analyzeDataWrapper =>
                                                analyzeDataWrapper.name
                                        )}
                                        showLegend={true}
                                    />
                                ) : (
                                    <ContainerMessage>
                                        <FormattedMessage
                                            key="no-available-metrics"
                                            defaultMessage="There are no available regional metrics"
                                        />
                                    </ContainerMessage>
                                )}
                            </Container>

                            {/* We add a negative z-index here so the chart's tooltip can render over the table*/}
                            <Container>
                                <ContainerHeader>
                                    <FormattedMessage
                                        key="totals-by-city"
                                        defaultMessage="Totals By City"
                                    />
                                </ContainerHeader>
                                <Loading
                                    loading={loading}
                                    kind={'over-table'}
                                />
                                {analyzeData ? (
                                    <JurisdictionTable
                                        tripCountData={analyzeData}
                                        dateRange={dateRange}
                                        vehicleClass={
                                            vehicleClass as VehicleClass | null
                                        }
                                    />
                                ) : (
                                    <ContainerMessage>
                                        <FormattedMessage
                                            key="no-available-metrics"
                                            defaultMessage="There are no available regional metrics"
                                        />
                                    </ContainerMessage>
                                )}
                            </Container>
                        </div>
                        <RegionSidebar
                            vehicleClass={vehicleClass}
                            setVehicleClass={setVehicleClass}
                        />
                    </div>
                </PageBody>
            </PageContent>
        </MapViewProvider>
    );
}

function RegionalDatePicker({ earliestReportDate, dateRange, setDateRange }) {
    return (
        <Container>
            <div className={DATE_PICKER_CONTAINER}>
                <DateRangeTextOnly selectedDateRange={dateRange} />
                <DateButtons
                    earliestDate={earliestReportDate}
                    selectedDateRange={dateRange}
                    setSelectedDateRange={range => {
                        setDateRange(range);
                    }}
                />
            </div>
        </Container>
    );
}

function useEarliestDate(currentEarliestDate: LocalDate) {
    const earliestDates = useData<
        RegionalEarliestReportDatesData,
        RegionalEarliestReportDatesArgs
    >(REGIONAL_EARLIEST_REPORT_DATE_QUERY, {});

    if (earliestDates === null) {
        return currentEarliestDate;
    }
    return earliestDates.mapViews
        .map(dateWrapper => dateWrapper.earliestReportDate)
        .filter(maybeDateString => maybeDateString !== null)
        .map((dateString: string) => LocalDate.fromDateString(dateString))
        .reduce((a, b) => {
            if (a.isBefore(b)) {
                return a;
            }
            return b;
        }, currentEarliestDate);
}
