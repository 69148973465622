import React from 'react';
import { Select } from 'common/select';
import { DateTime } from 'luxon';
import "/opt/build/repo/src/page-policy/forms/time-select.tsx?resplendence=true";
import { range } from 'utils/helpers';

/*
@import 'style.scss';
*/;

const TIME_SELECT = "rx-page-policy-forms-time-select-1"/*
    width: 130rem;
*/;

interface TimeSelectProps {
    time: [number, number];
    onChange: (time: [number, number]) => void;
}

/**
 * A time selector that shows hours : minutes (AM, PM)
 *
 * Time is stored as [hours, minutes] and the AM, PM is derived from the hours
 * state.
 */
function TimeSelect({ time, onChange }: TimeSelectProps) {
    const timestamp = DateTime.local().set({
        hour: time[0],
        minute: time[1]
    });

    const onTimeInputChange = (time: string) => {
        const [hours, minutes] = time.split(':');
        // round to the nearest 15 minutes
        const roundedMinutes = Math.round(parseInt(minutes) / 15) * 15;
        const newDateTime = timestamp.set({
            hour: parseInt(hours),
            minute: roundedMinutes
        });
        onChange([newDateTime.hour, newDateTime.minute]);
    };

    // creates an option list for every 15 minute interval in a 24 hour day
    const timeOptions = range(0, 24 * 4).map(minutes => {
        const hour = Math.floor(minutes / 4);
        const minute = (minutes % 4) * 15;

        const formattedTime = timestamp.set({ hour, minute }).toLocaleString({
            hour: 'numeric',
            minute: '2-digit'
        });
        return {
            value: `${hour}:${minute.toString().padStart(2, '0')}`,
            label: formattedTime
        };
    });

    return (
        <Select
            className={TIME_SELECT}
            options={timeOptions}
            value={timeOptions.find(opt => {
                const hour = timestamp.hour;
                const minute = timestamp.minute;
                const roundedMinute = Math.round(minute / 15) * 15;
                return (
                    opt.value ===
                    `${hour}:${roundedMinute.toString().padStart(2, '0')}`
                );
            })}
            onChange={opt => {
                if (opt == null) {
                    return;
                }
                onTimeInputChange(opt.value);
            }}
        />
    );
}

export default TimeSelect;
