import { gql, useApolloClient } from '@apollo/client';
import { DateRange } from 'common/date-picker';
import {
    CurbEventsArgs,
    CurbEventsData,
    EventLocationsDataType,
    VehicleClass
} from 'graphql.g';
import downloadData from 'utils/download-data';

export const CURB_EVENTS_QUERY = gql`
    query CurbEvents(
        $slug: String!
        $dataType: EventLocationsDataType!
        $dates: DateRange!
        $vehicleClass: VehicleClass
        $operatorId: ID
    ) {
        mapView(slug: $slug) {
            eventLocationsJson(
                dataType: $dataType
                dates: $dates
                vehicleClass: $vehicleClass
                operatorId: $operatorId
            )
        }
    }
`;

interface DownloadCurbEventsProps {
    apolloClient: ReturnType<typeof useApolloClient>;
    slug: string;
    dataPeriod: DateRange;
    vehicleClass: VehicleClass | null;
    dataType: EventLocationsDataType;
    operator: string | null;
}

const formatter = new Intl.NumberFormat(undefined, {
    maximumFractionDigits: 6
});

function generateCurbEventsCsv(curbEvents: GeoJSON.Feature<GeoJSON.Point>[]) {
    const headerRow = ['latitude', 'longitude'];

    const dataRows = curbEvents.map(
        feature =>
            `${formatter.format(
                feature.geometry.coordinates[1]
            )},${formatter.format(feature.geometry.coordinates[0])}`
    );
    return headerRow.join(',') + '\n' + dataRows.join('\n');
}

export async function downloadCurbEvents({
    apolloClient,
    slug,
    dataPeriod,
    vehicleClass,
    dataType,
    operator
}: DownloadCurbEventsProps) {
    const { data } = await apolloClient.query<CurbEventsData, CurbEventsArgs>({
        query: CURB_EVENTS_QUERY,
        variables: {
            slug,
            dates: [dataPeriod[0].toString(), dataPeriod[1].toString()],
            vehicleClass: vehicleClass,
            dataType,
            operatorId: operator
        },
        fetchPolicy: 'no-cache'
    });

    const curbEvents = JSON.parse(data.mapView.eventLocationsJson);

    if (curbEvents == null) {
        const params = {
            dataPeriod,
            vehicleClass,
            dataType,
            operator
        };
        const stringifiedParams = JSON.stringify(params);
        throw new Error(
            `No curb events returned for slug ${slug} with params ${stringifiedParams}`
        );
    }

    const filename = `${slug}-curb-events-${dataPeriod[0]}-${dataPeriod[1]}-${dataType}.csv`;

    const curbEventsCsv = generateCurbEventsCsv(curbEvents);

    downloadData(filename, curbEventsCsv);
}
