import { Expression } from 'mapbox-gl';
import { flatMap } from 'utils/helpers';

export const interpolate = (on: string[], colors: string[]): Expression => {
    const l = colors.length;
    return [
        'step',
        on,
        'transparent',
        0.01,
        colors[0],
        0.05,
        colors[1],
        ...flatMap(colors.slice(2), (c, i) => [(i + 2) / l, c])
    ];
};
