import React, { forwardRef } from 'react';
import { Link, NavLink, NavLinkProps } from 'react-router-dom';
import cx from 'classnames';

type Props = {
    className?: string;
    to:
        | string
        | { pathname?: string; search?: string; hash?: string; state?: any };
    replace?: boolean;
    children?: any;
    nav?: boolean;
    exact?: boolean;
    disabled?: boolean;
    disabledClassName?: string;
    isActive?: NavLinkProps['isActive'];
    [prop: string]: any;
};

/**
 * A react-router link with a few common properties applied.
 */
const AbsoluteLink = forwardRef<any, Props>(
    (
        {
            to,
            nav = false,
            exact = false,
            disabled = false,
            disabledClassName = 'disabled',
            className,
            isActive,
            ...rest
        },
        ref
    ) => {
        return disabled ? (
            <div
                ref={ref}
                {...rest}
                className={cx(className, { [disabledClassName]: disabled })}
            />
        ) : nav ? (
            <NavLink
                ref={ref}
                className={className}
                to={to}
                exact={exact}
                activeClassName="active"
                isActive={isActive}
                {...rest}
            />
        ) : (
            <Link ref={ref} className={className} to={to} {...rest} />
        );
    }
);
export default AbsoluteLink;
