import React, { useMemo } from 'react';
import { AreaReport } from './report';
import {
    Table,
    TableSection,
    HeaderRow,
    Row,
    AverageValue,
    messages,
    HeaderProps
} from './tables';
import { defineMessages } from 'react-intl';
import { useParams } from 'react-router';
import { useIntl } from 'utils/use-intl';
import { rotateAreaMetricsData } from 'utils/helpers';
import {
    aggregatedDescriptions,
    HeaderInfo
} from '../constants/stat-descriptions';
import Loading from 'common/loading';
import { AggregatedReportAoiMetricsFragment } from './fragments';
import gql from 'graphql-tag';
import { useReloadingData } from 'utils/use-data';
import { LocalMonth, LocalWeek } from 'utils/date-tools';
import { useMapView } from 'common/use-map-view';
import {
    AggregatedReportAreasArgs,
    AggregatedReportAreasData,
    AggregatedReportPeriod
} from 'graphql.g';
import { useVehicleClass } from 'common/vehicle-classes';
import { useAreaMetricsMatchingSlug } from './page-daily-area';
import { ReportNotFound } from 'page-report';
import { useAreaDetails } from 'common/area-map';
import useDocumentTitle from 'common/use-document-title';
import { useAggregatedUnfinalizedOperators } from './page-aggregated-summary';
import { useSorting } from 'common/table-sorting';
import orderBy from 'lodash/orderBy';
import zipWith from 'lodash/zipWith';

type Props = {
    reportDate: LocalMonth | LocalWeek;
};

const m = defineMessages({
    'metrics-by-operator': 'Metrics by Operator',
    'weekly-document-title':
        '{area} · Week of {monday, date, reportweek} · Ride Report',
    'monthly-document-title':
        '{area} · {month, date, reportmonth} · Ride Report'
});

const headerInfo: HeaderInfo[] = [
    aggregatedDescriptions.startTrips,
    aggregatedDescriptions.endTrips,
    aggregatedDescriptions.morningDeployed,
    aggregatedDescriptions.tripsPerVehicle
];
const sortKeys = [
    'tripCounts.startCount',
    'tripCounts.endCount',
    'morningDeploymentCounts.averageAvailableCount',
    'tripsPerVehicle'
];

const headers: HeaderProps[] = zipWith(headerInfo, sortKeys, (a, b) => {
    return [a, b];
});

function PageAggregatedArea({ reportDate }: Props) {
    const mapView = useMapView();
    const vehicleClass = useVehicleClass();
    const intl = useIntl();
    const [data, loading] = useReloadingData<
        AggregatedReportAreasData,
        AggregatedReportAreasArgs
    >(QUERY, {
        slug: mapView.slug,
        date:
            reportDate instanceof LocalMonth
                ? reportDate.first.toString()
                : reportDate.monday.toString(),
        period:
            reportDate instanceof LocalMonth
                ? AggregatedReportPeriod.monthly
                : AggregatedReportPeriod.weekly,
        vehicleClass
    });
    const areaFromUrl = useParams<{ area: string }>().area;

    const areas = useMemo(
        () =>
            rotateAreaMetricsData(
                data,
                op => op.aggregatedReport && op.aggregatedReport.aoiMetrics
            ),
        [data]
    );

    const areaMetric = useAreaMetricsMatchingSlug(areas);

    const title =
        reportDate instanceof LocalWeek
            ? intl.formatMessage(m[`weekly-document-title`], {
                  monday: reportDate.monday.toDate(),
                  area: areaMetric?.area.name
              })
            : intl.formatMessage(m[`monthly-document-title`], {
                  month: reportDate?.first.toDate(),
                  area: areaMetric?.area.name
              });

    useDocumentTitle(title);
    const [sorting, onSortHeaderClick] = useSorting<
        | 'name'
        | 'tripCounts.startCount'
        | 'tripCounts.endCount'
        | 'morningDeploymentCounts.averageAvailableCount'
        | 'tripsPerVehicle'
    >('name');
    const operators = useMemo(() => {
        const list =
            areaMetric?.operators ??
            mapView.operators.map(op => ({
                name: op.name,
                operatorId: op.operatorId,
                morningDeploymentCounts: null,
                tripCounts: null,
                tripsPerVehicle: null,
                totalMaxAvailable: null
            }));
        return orderBy(list, [...sorting.keys()], [...sorting.values()]);
    }, [sorting, mapView, areaMetric?.operators]);

    const unfinalizedOperators = useAggregatedUnfinalizedOperators(reportDate);

    const areaDetails = useAreaDetails(areaMetric?.area.slug);
    if ((areas && !areaMetric) || areaDetails === 'not found')
        return (
            <ReportNotFound reportDate={reportDate} areaFromUrl={areaFromUrl} />
        );
    if (!areaMetric || areaDetails === 'loading') return <Loading />;

    const totalTripCountForAllOperators = operators.reduce(
        (value, op) => value + (op?.tripCounts?.startCount ?? 0),
        0
    );

    const totalMaxAvailableForAllOperators = operators.reduce(
        (value, op) => value + (op?.totalMaxAvailable ?? 0),
        0
    );

    const totalTripsPerVehicle = intl.formatNumber(
        totalTripCountForAllOperators / totalMaxAvailableForAllOperators,
        {
            maximumFractionDigits: 2
        }
    );

    return (
        <AreaReport
            reportDate={reportDate}
            area={areaDetails}
            unfinalizedOperators={unfinalizedOperators}
        >
            <TableSection title={intl.formatMessage(m['metrics-by-operator'])}>
                <Loading loading={loading} kind="over-table" />
                <Table>
                    <HeaderRow
                        headers={headers}
                        sorting={sorting}
                        onSortHeaderClick={onSortHeaderClick}
                    />
                    <tbody>
                        {operators.map(op => (
                            <Row
                                key={op.operatorId}
                                name={op.name}
                                values={[
                                    op.tripCounts && (
                                        <AverageValue
                                            value={op.tripCounts.startCount}
                                            average={
                                                op.tripCounts.averageStartCount
                                            }
                                        />
                                    ),
                                    op.tripCounts && (
                                        <AverageValue
                                            value={op.tripCounts.endCount}
                                            average={
                                                op.tripCounts.averageEndCount
                                            }
                                        />
                                    ),
                                    op.morningDeploymentCounts
                                        ?.averageAvailableCount ?? null,
                                    op.tripsPerVehicle
                                ]}
                            />
                        ))}
                        <Row
                            name={intl.formatMessage(messages['total-row'])}
                            values={[
                                intl.formatNumber(
                                    operators.reduce(
                                        (value, op) =>
                                            value +
                                            (op?.tripCounts?.startCount ?? 0),
                                        0
                                    )
                                ),
                                intl.formatNumber(
                                    operators.reduce(
                                        (value, op) =>
                                            value +
                                            (op?.tripCounts?.endCount ?? 0),
                                        0
                                    )
                                ),
                                intl.formatNumber(
                                    operators.reduce(
                                        (value, op) =>
                                            value +
                                            (op?.morningDeploymentCounts
                                                ?.averageAvailableCount ?? 0),
                                        0
                                    )
                                ),
                                totalTripsPerVehicle
                            ]}
                        />
                    </tbody>
                </Table>
            </TableSection>
        </AreaReport>
    );
}

const QUERY = gql`
    query AggregatedReportAreas(
        $slug: String
        $date: Date!
        $period: AggregatedReportPeriod!
        $vehicleClass: VehicleClass
    ) {
        mapView(slug: $slug) {
            id
            operators {
                id
                operatorId
                name
                aggregatedReport(
                    date: $date
                    period: $period
                    vehicleClass: $vehicleClass
                ) {
                    ...AggregatedReportAoiMetrics
                }
            }
        }
    }
    ${AggregatedReportAoiMetricsFragment}
`;

export default PageAggregatedArea;
