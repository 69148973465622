import { DateRange } from 'common/date-picker';
import { DateTime } from 'luxon';
import React, { forwardRef, Ref } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import "/opt/build/repo/src/page-explore/layers/routes/popup.tsx?resplendence=true";

/*
@import 'style';

.mapboxgl-popup-close-button {
    display: none;
}

.mapboxgl-popup-content {
    padding: 0!important;
    border-radius: 3rem!important;
    overflow: hidden;
    background: $white!important;
    pointer-events: none!important;
    @include shadow-8;
}

.mapboxgl-popup {
    pointer-events: none;
}

.mapboxgl-popup-anchor-bottom, .mapboxgl-popup-anchor-bottom-right, .mapboxgl-popup-anchor-bottom-left {
    top: -10rem;
    .mapboxgl-popup-tip {
        border-top-color: $white!important;
        border-top-color: var(--popup-color)!important;
    }
}
.mapboxgl-popup-anchor-top, .mapboxgl-popup-anchor-top-right, .mapboxgl-popup-anchor-top-left  {
    top: 10rem;
    .mapboxgl-popup-tip {
        border-bottom-color: $white!important;
    }
}
.mapboxgl-popup-anchor-left, .mapboxgl-popup-anchor-left-top  {
    left: 10rem;
    .mapboxgl-popup-tip {
        border-right-color: $white!important;
    }
}
.mapboxgl-popup-anchor-left-bottom {
    left: 10rem;
    .mapboxgl-popup-tip {
        border-right-color: $white!important;
        border-right-color: var(--popup-color)!important;
    }
}
.mapboxgl-popup-anchor-right, .mapboxgl-popup-anchor-right-top {
    left: -10rem;
    .mapboxgl-popup-tip {
        border-left-color: $white!important;
    }
}
.mapboxgl-popup-anchor-right-bottom {
    left: -10rem;
    .mapboxgl-popup-tip {
        border-right-color: $white!important;
        border-right-color: var(--popup-color)!important;
    }
}

.mapboxgl-popup-tip {
    border: 5rem solid transparent;
}
*/;

const POPUP = "rx-page-explore-layers-routes-popup-1"/*
	display: flex;
    flex-direction: column;
    justify-content: space-around;
	pointer-events: auto;
    min-width: 186rem;
    min-height: 88rem;
	color: rgb(0, 0, 0);
	margin: 0;
	text-decoration: none;
	z-index: 4;
    line-height: normal;
    border: none;
    padding: 8rem 12rem;
*/;

const STREET_NAME = "rx-page-explore-layers-routes-popup-2"/*
    @include text-label;
    font-size: 16rem;
    line-height: 20rem;
    color: $gray-90;

*/;

const DETAILS = "rx-page-explore-layers-routes-popup-3"/*
    @include text-label;
    color: $gray-70;
*/;

type Props = {
    streetSegment: any | null;
    dataPeriod: DateRange;
};

export const findAverageDailyTrips = (dataPeriod: DateRange, count: number) => {
    const startDate = DateTime.fromISO(dataPeriod[0].toString());
    const endDate = DateTime.fromISO(dataPeriod[1].toString());
    const diffInSelectedDays = endDate.diff(startDate, 'days').days + 1;
    const average = Math.round(count / diffInSelectedDays);

    return average;
};

export const RoutesPopup = forwardRef(
    ({ streetSegment, dataPeriod }: Props, ref: Ref<HTMLDivElement>) => {
        const intl = useIntl();
        if (!streetSegment) {
            return null;
        }

        const { name, count, percentage } = streetSegment.properties;
        return (
            <div className={POPUP} ref={ref}>
                <div className={STREET_NAME}>{name ? name : 'N/A'}</div>
                <div className={DETAILS}>
                    <FormattedMessage
                        key="total-trips-popup"
                        defaultMessage="{total} Total Trips"
                        values={{
                            total: intl.formatNumber(count)
                        }}
                    />
                </div>
                <div className={DETAILS}>
                    <FormattedMessage
                        key="avg-daily-trip"
                        defaultMessage="{average} Avg. Daily Trips"
                        values={{
                            average: findAverageDailyTrips(dataPeriod, count)
                        }}
                    />
                </div>
                <div className={DETAILS}>
                    <FormattedMessage
                        key="service-area-total"
                        defaultMessage="{percentage}% of Service Area Total"
                        values={{
                            percentage: percentage.toFixed(1)
                        }}
                    />
                </div>
            </div>
        );
    }
);
