import React from 'react';
import "/opt/build/repo/src/page-policy/dashboard/sidebar.tsx?resplendence=true";
import { ContainerHeader, Toggle } from 'common/layout';
import { FormattedMessage } from 'react-intl';
import { DateRangeSelect, DateRange, DateButtons } from 'common/date-picker';
import { getOperatorColorStyle } from 'utils/helpers';
import { OperatorName } from 'constants/operators';

/*
    @import 'style';
*/;

const SIDEBAR = "rx-page-policy-dashboard-sidebar-1"/*
    @include container;
    padding: 24rem;
    position: sticky;
    top: 0;
*/;

const OPERATORS_LABEL = "rx-page-policy-dashboard-sidebar-2"/*
    @include text-label;
    color: $gray-60;
*/;

const OPERATOR_FILTERS = "rx-page-policy-dashboard-sidebar-3"/*
    margin-top: 16rem;
    display: flex;
    flex-direction: column;
    row-gap: 16rem;
*/;

interface PolicyDashboardSidebarProps {
    operators: Set<OperatorName>;
    operatorFilters: OperatorName[];
    setOperatorFilters: (filters: OperatorName[]) => void;
    dateRange: DateRange;
    selectedDateRange: DateRange;
    setSelectedDateRange: (range: DateRange) => void;
}

function PolicyDashboardSidebar({
    operators,
    operatorFilters,
    setOperatorFilters,
    dateRange,
    selectedDateRange,
    setSelectedDateRange
}: PolicyDashboardSidebarProps) {
    const hasMultipleOperators = operators.size > 1;
    const handleToggle = (operator: OperatorName) => () => {
        if (operatorFilters.includes(operator)) {
            setOperatorFilters(operatorFilters.filter(op => op !== operator));
        } else {
            setOperatorFilters([...operatorFilters, operator]);
        }
    };

    return (
        <div className={SIDEBAR}>
            <ContainerHeader>
                <FormattedMessage key="filter-header" defaultMessage="Filter" />
            </ContainerHeader>
            <DateRangeSelect
                position="bottom-right"
                selectedDateRange={selectedDateRange}
                onChange={setSelectedDateRange}
                earliestAllowed={dateRange[0]}
                latestAllowed={dateRange[1]}
            />
            <DateButtons
                earliestDate={dateRange[0]}
                selectedDateRange={selectedDateRange}
                setSelectedDateRange={setSelectedDateRange}
            />
            {hasMultipleOperators && (
                <>
                    <span className={OPERATORS_LABEL}>
                        <FormattedMessage
                            key="label"
                            defaultMessage="Operators"
                        />
                    </span>
                    <div className={OPERATOR_FILTERS}>
                        {[...operators].map(operator => (
                            <Toggle
                                key={operator}
                                checked={!operatorFilters.includes(operator)}
                                onChange={handleToggle(operator)}
                                color={
                                    getOperatorColorStyle(operator)
                                        .backgroundColor
                                }
                                label={operator}
                            />
                        ))}
                    </div>
                </>
            )}
        </div>
    );
}

export default PolicyDashboardSidebar;
