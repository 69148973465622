import { AnalyzeChartData } from 'graphql.g';
import { defineMessages } from 'react-intl';

export const CHART_LABELS = defineMessages({
    'area-select-service-area': 'Service Area',
    'operator-select-all': 'All operators',
    'operator-select-placeholder': 'Select an Operator',
    'operator-select-no-mds': 'No operators with MDS Data',
    'select-data-type': 'Select Chart',
    'max-available-chart': 'Max Available Vehicles',
    'mean-available-chart': 'Mean Available Vehicles',
    'trips-per-mean-available-vehicle-chart':
        'Trips Per Mean Available Vehicle',
    'mean-active-chart': 'Mean Active Vehicles',
    'trips-per-mean-active-vehicle-chart': 'Trips Per Mean Active Vehicle',
    'trip-duration-chart': 'Trip Duration',
    'trip-distance-chart': 'Trip Distance',
    'total-trip-distance-by-date-chart': 'Total Trip Distance',
    'trips-by-hour-chart': 'Trips By Hour',
    'trips-by-date-chart': 'Trips By Date',
    'trips_by_date-chart-trip_starts': 'Trip Starts By Date',
    'trips_by_date-chart-trip_ends': 'Trip Ends By Date',
    'average-vehicle-idle-time-chart': 'Mean Vehicle Idle Time',
    'archived-area': '{areaName} (Archived)',
    'metric-comparison-by-date': 'Metrics Comparison By Date',
    'trips-per-day-by-hour-chart': 'Trips By Hour & Weekday',
    'trips-by-weekday-chart': 'Trips By Weekday',
    'co2-tons-saved-by-day-chart': 'Carbon Emissions Avoided',
    'trip-duration-mode-shift-chart': 'Additional Physical Activity',
    'car-trips-shifted-chart': 'Car Trips Replaced'
});

export const CHART_MESSAGES = {
    trips_by_date: CHART_LABELS['trips-by-date-chart'],
    trip_duration: CHART_LABELS['trip-duration-chart'],
    trip_distance: CHART_LABELS['trip-distance-chart'],
    total_trip_distance_by_day:
        CHART_LABELS['total-trip-distance-by-date-chart'],
    trips_by_hour: CHART_LABELS['trips-by-hour-chart'],
    max_available: CHART_LABELS['max-available-chart'],
    mean_available: CHART_LABELS['mean-available-chart'],
    mean_active: CHART_LABELS['mean-active-chart'],
    trips_per_mean_available_vehicle:
        CHART_LABELS['trips-per-mean-available-vehicle-chart'],
    trips_per_mean_active_vehicle:
        CHART_LABELS['trips-per-mean-active-vehicle-chart'],
    average_vehicle_idle_time: CHART_LABELS['average-vehicle-idle-time-chart'],
    trips_per_day_by_hour: CHART_LABELS['trips-per-day-by-hour-chart'],
    trips_by_weekday: CHART_LABELS['trips-by-weekday-chart'],
    co2_tons_saved_by_day: CHART_LABELS['co2-tons-saved-by-day-chart'],
    trip_duration_mode_shift: CHART_LABELS['trip-duration-mode-shift-chart'],
    car_trips_shifted: CHART_LABELS['car-trips-shifted-chart']
};

export type ChartMetric = keyof typeof CHART_MESSAGES | 'trips_by_weekday';
export const CHART_TYPES = (Object.keys(
    CHART_MESSAGES
) as ChartMetric[]).filter(
    metric =>
        ![
            'trips_per_day_by_hour',
            'trips_by_weekday',
            'co2_tons_saved_by_day',
            'trip_duration_mode_shift',
            'car_trips_shifted'
        ].includes(metric)
);

export const defaultChartType = 'trips_by_date';

export const ALLOWABLE_EXTRA_METRICS = [
    'trips_by_date',
    'max_available',
    'mean_available',
    'mean_active',
    'total_trip_distance_by_day'
] as ChartMetric[];

export type AnalyzeData = NonNullable<
    AnalyzeChartData['mapView']['analyzeData']
>;

export const TRIP_HISTOGRAMS = [
    'trip_duration',
    'trip_distance',
    'trips_by_hour',
    'trips_by_weekday',
    'trips_per_day_by_hour'
] as ChartMetric[];
