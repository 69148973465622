import { DateRange } from 'common/date-picker';
import gql from 'graphql-tag';
import { AoiInfoFragment } from 'graphql.g';
import { ChartMetric } from 'page-analyze/types';

export const MODE_SHIFT_METRIC_QUERY = gql`
    query ModeShiftMetricQuery(
        $slug: String!
        $start_date: Date!
        $end_date: Date!
        $metric: String!
    ) {
        mapViewBySlug(slug: $slug) {
            id
            modeShift(startDate: $start_date, endDate: $end_date) {
                id
                modeShiftData(metric: $metric) {
                    totalByVehicleClass {
                        vehicleType
                        total
                    }
                    analyzeData {
                        groupBy
                        tripDataType
                        startDate
                        endDate
                        xUnit
                        yUnit
                        aggregateUnit
                        totalCountUnit
                        dataLine {
                            group
                            dataPoints {
                                xValue
                                yValue
                                percentageOfTotal
                            }
                            median
                            average
                            sumTotal
                            totalCount
                        }
                        metricDefinition {
                            displayName
                            description
                            purposeDescription
                        }
                    }
                }
            }
        }
    }
`;

export type ModeShiftBlockProps = {
    metric: ChartMetric;
    dateRange: DateRange;
    preliminaryDates: Set<string>;
    hasPreliminaryData: boolean;
    areas: AoiInfoFragment[] | null;
};
