import { DateTime } from 'luxon';
import { LocalDate } from 'utils/date-tools';
import { useMapView } from './use-map-view';

export function getLastReportDate(ianaTimezone: string): LocalDate {
    const nowInTz = DateTime.fromJSDate(new Date()).setZone(ianaTimezone);
    return new LocalDate(nowInTz.year, nowInTz.month, nowInTz.day).minusDays(
        nowInTz.hour < 6 ? 2 : 1
    );
}

function useLastReportDate() {
    const { ianaTimezone } = useMapView();
    return getLastReportDate(ianaTimezone);
}

export default useLastReportDate;
