/**
 * A collection of small "helper functions" to operate on our data in common
 * ways. If you're adding something here, consider whether there's a more
 * specific place you could put it instead
 */

import { useRef } from 'react';
import orderBy from 'lodash/orderBy';
import groupBy from 'lodash/groupBy';
import isEqual from 'lodash/isEqual';

import { OperatorsData } from '../types';
import { OperatorName } from 'constants/operators';
import { OPERATOR_COLORS } from 'constants/operators';
import { AoiInfoFragment } from 'graphql.g';
import DATA_COLORS from 'constants/data-colors.json';

/**
 * Given a React-Router style path of the form /path/:param/subpath, locates the
 * named param, and creates a new url out of window.location with that param modified.
 */
export const getUrlWithChangedParams = (
    path: string,
    params: { [param: string]: any }
) => {
    const urlParts = window.location.pathname.split('/');
    const pathParts = path.split('/');
    for (const param in params) {
        const value = params[param] + '';
        let paramIndex: number;
        do {
            paramIndex = pathParts.indexOf(':' + param);

            if (paramIndex >= 0) {
                urlParts[paramIndex] = value;
                pathParts[paramIndex] = value;
            }
        } while (paramIndex >= 0);
    }
    return urlParts.join('/') + (window.location.search ?? '');
};

/**
 * For each key of an object, map its value into a new value with the same key.
 */
export const mapObject = <
    T extends { [key: string]: any },
    F extends (value: T[keyof T], key?: string) => any
>(
    obj: T,
    fn: F
): { [Key in keyof T]: ReturnType<F> } => {
    const result: any = {};
    Object.keys(obj).forEach(key => {
        result[key] = fn(obj[key], key);
    });
    return result;
};

/**
 * Streets that we shouldn't render on the map. Please push back if you're
 * asked to add more streets to this list, as we don't want to maintain or
 * normalize this sort of exception-based feature in the code.
 */
export const forbiddenStreetsAOIMadrid = [
    'Calles no Operativas (Nuevo)',
    'Non Operating Streets'
];

/**
 * Lodash's flatten function was giving me trouble, so I just wrote my own.
 *
 * Concats an array of arrays in a flat array. Is not recursive.
 */
export const flatten = <T>(array: T[][]) => {
    const result: T[] = [];
    for (const nested of array) {
        for (const item of nested) {
            result.push(item);
        }
    }
    return result;
};

/**
 * Map each value of a list into a separate array, and then concat those arrays
 * into a flat single array of values.
 */
export const flatMap = <T, X>(array: T[], fn: (item: T, i: number) => X[]) => {
    return flatten(array.map(fn));
};

const DEFAULT_OPERATOR_COLOR = { background: '#222A35', blackText: false };
/**
 * Gets the Operator's brand color, or a default. If an operator name is provided
 * that we don't have a color for, we'll fire an alert so we can fix this.
 */
const getOperatorColor = (name?: OperatorName) => {
    if (!name) return DEFAULT_OPERATOR_COLOR;

    const color = OPERATOR_COLORS[name];

    if (color == null) {
        const noOperatorError = new Error(
            `Operator ${name} does not have brand colors setup. It does not exist in OPERATOR_COLORS.`
        );
        window.rollbar.error(noOperatorError);
    }

    return color || DEFAULT_OPERATOR_COLOR;
};

/**
 * Provides the operator's brand background color and text color.
 */
export const getOperatorColorStyle = (name?: OperatorName) => {
    const color = getOperatorColor(name);
    return {
        backgroundColor: color.background,
        color: color.blackText ? '#222A35' : 'white'
    };
};

export const getDataColor = (index: number) => {
    const color = DATA_COLORS[index % DATA_COLORS.length];
    return {
        backgroundColor: color.background,
        color: color.blackText ? '#222a35' : 'white'
    };
};

/**
 * Convert a string into a friendly-looking url-safe slug. We use this to create
 * routing without needing ugly uuids in our urls.
 *
 * Note that this is a destructive operation, so you can't "undo" the
 * conversion. Instead you will need to run it on both strings before doing a
 * comparison.
 */
export const convertToSlug = (name: string) =>
    name
        .toLowerCase()
        .replace(/[ /]/g, '_')
        .replace(/[^a-zA-Z0-9-_]/g, '');

type Area = {
    name: string;
    priority: number;
    archivedAt: string | null;
};

/**
 * Sort some items by an area of interest related to it.
 */
export const sortByAreas = <T, A extends Area>(
    /** The items to be sorted */
    items: T[],
    /**  Given an item, fetch the area of interest that we'll be using for comparison. */
    map: (item: T) => A
) =>
    orderBy(items, [
        // Archived areas are sorted to the bottom, regardless of priority
        i => (map(i).archivedAt ? Infinity : map(i).priority),
        i => map(i).name
    ]);

/**
 * Sort a list of AOIs.
 */
export const sortAreas = <T extends Area>(areas: T[]) =>
    sortByAreas(areas, a => a);

type AreaForTable = Area & {
    operatorIds: string[];
    tableViewOnly?: boolean | null;
    archivedAt: null | string;
};

/**
 * Group a list of areas into sections by table-only status.
 */
export const getAreaSections = <T extends AreaForTable>(areas: T[]): T[][] => {
    const groupedTables = groupBy(areas || [], a =>
        a.archivedAt ? 2 : a.tableViewOnly ? 1 : 0
    );

    return Object.keys(groupedTables)
        .sort()
        .map(key =>
            sortAreas(groupedTables[key]).filter(
                area => !forbiddenStreetsAOIMadrid.includes(area.name)
            )
        );
};

type Operator = {
    name: string;
};

/** Sort a list of operators */
export const sortOperators = <T extends Operator>(operators: T[]) =>
    orderBy(operators, o => o.name);

/**
 * Get a list of incremental numbers with a given start and end.
 *
 * @param from - the starting number, inclusive.
 * @param to - the ending number, exclusive.
 */
export function range(from: number, to: number): number[];

/**
 * Get a list of incremental numbers, starting at zero.
 *
 * @param to – the number of items in the list.
 */
export function range(to: number): number[];

export function range(...args: [number] | [number, number]) {
    let from = 0,
        to = 0;
    if (args.length === 1) {
        to = args[0];
    } else {
        from = args[0];
        to = args[1];
    }
    const result: number[] = [];
    for (let i = from; i < to; i++) {
        result.push(i);
    }
    return result;
}

type RotatedOperator = {
    name: OperatorName;
    operatorId: string;
};
type AreaWithInfo = {
    area: AoiInfoFragment;
};

const rotateOperatorsThruAreas = <
    Operator extends RotatedOperator,
    Metrics extends {}
>(
    operators: Operator[] | null,
    map: (operator: Operator) => (AreaWithInfo & Metrics)[] | null
) => {
    type ResultOperator = RotatedOperator & Omit<Metrics, 'area'>;
    type ResultArea = {
        area: AoiInfoFragment;
        operators: ResultOperator[];
    };
    const result: { [id: string]: ResultArea } = {};
    if (!operators) return null;
    for (const operator of operators) {
        const metrics = operator && map(operator);
        if (metrics) {
            for (const metric of metrics) {
                const { area, ...areaMetrics } = metric;
                if (!result[area.id]) {
                    result[area.id] = {
                        area,
                        operators: []
                    };
                }
                result[area.id].operators.push({
                    operatorId: operator.operatorId,
                    name: operator.name,
                    ...areaMetrics
                } as any);
            }
        }
    }
    return Object.keys(result)
        .map(key => result[key])
        .map(({ operators, area }) => ({
            operators: orderBy(operators, op => op.name),
            area
        }));
};

/**
 * Takes our standard graphql data model of
 * ```
 * {
 *   mapView {
 *     operators [{
 *       id
 *       name
 *       areaMetrics [{
 *         area: { ... }
 *         ...metrics
 *       }]
 *     }]
 *   }
 * }
 * ```
 * and transforms it to an array of areas:
 * [{
 *     area: { ... }
 *     operators [{
 *       id
 *       name
 *       ...metrics
 *     }]
 * }]
 * ```
 *
 * The second argument tells the function how to find the area metrics, given an operator.
 * This way we can find the area metrics from a daily, weekly, or monthly report with the same logic.
 */
export const rotateAreaMetricsData = <
    Operator extends RotatedOperator,
    Metrics extends {}
>(
    data: OperatorsData<Operator> | null,
    map: (operator: Operator) => (AreaWithInfo & Metrics)[] | null
) => {
    return rotateOperatorsThruAreas(data && data.mapView.operators, map);
};

/**
 * For the given metric, get the full list of AOIs that any operator has data
 * for.
 */
export const getAreasFromMetricsData = <Operator>(
    /** The graphql data response with a list of operators */
    data: OperatorsData<Operator> | null,
    /** A selector to get the given metric out of an operator */
    map: (operator: Operator) => AreaWithInfo[] | null
) => {
    if (!data) return null;
    const result: { [id: string]: AoiInfoFragment } = {};
    for (const operator of data.mapView.operators) {
        const areaMetrics = map(operator);
        if (areaMetrics) {
            for (const metric of areaMetrics) {
                if (!result[metric.area.id]) {
                    result[metric.area.id] = metric.area;
                }
            }
        }
    }
    return Object.keys(result).map(key => result[key]);
};

/**
 * Memoize an object with deep equality comparison, so that its reference will only change if one
 * of the properties changes, without worrying about if the input has the same reference or not.
 */
export const useMemoizedObject = <T>(obj: T): T => {
    const ref = useRef(obj);
    if (!isEqual(ref.current, obj)) {
        ref.current = obj;
    }
    return ref.current;
};

export const intoDict = <K extends string, V>(pairs: [K, V][]) => {
    const result: Partial<Record<K, V>> = {};
    for (const [key, value] of pairs) {
        result[key] = value;
    }
    return result as Record<K, V>;
};

/**
 * If the value is higher than max, return max. If it's lower than min, return min.
 */
export const constrain = (min: number, max: number, value: number) => {
    return Math.max(min, Math.min(max, value));
};

/**
 * Returns a lazy getter for a constant value that only gets initialized the
 * first time it is needed.
 *
 * See https://reactjs.org/docs/hooks-faq.html#how-to-create-expensive-objects-lazily
 */
export const useLazyConstRef = <T>(init: () => T) => {
    const ref = useRef<T | null>(null);

    const getRef = () => {
        if (ref.current === null) {
            ref.current = init();
        }
        return ref.current;
    };
    return getRef;
};

/**
 * Returns a constant value that is initialized once when the component first
 * renders, and never changes.
 */
export const useConstRef = <T extends any>(init: () => T): T => {
    const getRef = useLazyConstRef(init);
    return getRef();
};

const stepped = (num: number, steps: number[]) => {
    for (let i = 0; i < steps.length - 1; i++) {
        const value = steps[i];
        const nextValue = steps[i + 1];
        if (num > (value + nextValue) / 2) return value;
    }
    return steps[steps.length - 1];
};

/**
 * Takes a number like "236", "16", or "9855" and returns it rounded to a pretty
 * value, like "250", "15", or "10000".
 */
export const getRoundNum = (num: number) => {
    const pow10 = Math.pow(10, `${Math.floor(num)}`.length - 1);
    let d = num / pow10;

    const steps =
        pow10 === 1
            ? [10, 9, 8, 7, 6, 5, 4, 3, 2, 1]
            : [10, 7.5, 5, 4, 3, 2.5, 2, 1.5];

    return pow10 * stepped(d, steps);
};

/**
 * From a given array, create a new array with the "separator" inserted
 * between each element
 */
export const separatedBy = <T, J>(values: T[], separator: J): (T | J)[] => {
    var result: (T | J)[] = [];
    for (var i = 0; i < values.length; i++) {
        if (i > 0) result.push(separator);
        result.push(values[i]);
    }
    return result;
};

/**
 * Given an email address, get the domain name part. Also acts as simple validator
 * and returns null if the email doesn't have a domain.
 */
export const getDomainFromEmailAddress = (email: string): string | null => {
    // Note that validating much more than "it has an @ sign" is probably overkill,
    // because all sorts of extremely wild emails are technically legal according to
    // the specification, like:
    //
    //   hello+world@8.8.8.8
    //   "This email has spaces!"@example.com
    return email.match(/^.+@(.+?\..+?)$/)?.[1] ?? null;
};
