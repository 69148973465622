import React from 'react';
import { LocalDate } from 'utils/date-tools';
import { useFinalizedReportDays } from './report-picker-daily';
import HeaderNav from './report-nav';
import { useIntl } from 'utils/use-intl';
import { defineMessages } from 'react-intl';
import { useMapView } from 'common/use-map-view';
import useLastReportDate from 'common/use-last-report-date';

const m = defineMessages({
    'yesterday-link': 'Yesterday'
});

type Props = {
    date: LocalDate;
};

function DailyNav({ date }: Props) {
    const mapView = useMapView();
    const intl = useIntl();
    const lastReportDate = useLastReportDate();

    const [finalizedReports] = useFinalizedReportDays(
        mapView,
        date.minusDays(3),
        date.plusDays(3)
    );
    const reportDays = Object.keys(finalizedReports).map(
        LocalDate.fromDateString
    );
    let nextReport: LocalDate | null = null;

    for (const report of reportDays) {
        if (report.isAfter(date)) {
            nextReport = report;
            break;
        }
    }
    let prevReport: LocalDate | null = null;
    for (const report of reportDays) {
        if (!report.isBefore(date)) {
            break;
        } else {
            prevReport = report;
        }
    }
    return (
        <HeaderNav
            mapView={mapView}
            mostRecent={lastReportDate}
            mostRecentLabel={intl.formatMessage(m['yesterday-link'])}
            nextReport={nextReport}
            previousReport={prevReport}
            selectedReport={date}
        />
    );
}

export default DailyNav;
