import React from 'react';
import { PolicyMetricDataLineFragment } from 'graphql.g';
import {
    ResponsiveContainer,
    Line,
    LineChart,
    ReferenceLine,
    YAxis
} from 'recharts';

interface PolicyPerformanceChartProps {
    line: PolicyMetricDataLineFragment | undefined;
    target: number;
    isHovered: boolean;
}

function PolicyPerformanceChart({
    line,
    target,
    isHovered
}: PolicyPerformanceChartProps) {
    // if there's no line data, don't try to show a chart
    if (line == null) {
        return null;
    }

    const onlyOneDataPoint = line.points.length === 1;

    const dotStyle = {
        fill: isHovered ? '#667380' : '#dfe3e7',
        pointerEvents: 'none',
        stroke: '#667380',
        r: 4
    };

    return (
        <ResponsiveContainer>
            <LineChart data={line.points}>
                <Line
                    type="monotone"
                    dataKey={'yValue'}
                    stroke={
                        onlyOneDataPoint
                            ? 'none'
                            : isHovered
                            ? '#8797A6'
                            : '#C5CDD4'
                    }
                    strokeWidth={2}
                    isAnimationActive={false}
                    dot={onlyOneDataPoint ? dotStyle : false}
                />
                <ReferenceLine
                    ifOverflow={'extendDomain'}
                    y={target}
                    stroke="#3B55E6"
                    strokeWidth={2}
                    strokeDasharray="2 2"
                />
                <YAxis hide domain={['auto', 'auo']} />
            </LineChart>
        </ResponsiveContainer>
    );
}

export default PolicyPerformanceChart;
