import { useMemo } from 'react';
import { ApolloClient, gql, useMutation } from '@apollo/client';
import Papa from 'papaparse';

import { useMapView } from 'common/use-map-view';
import config from 'config';
import useData from 'utils/use-data';
import {
    CustomDatasetArgs,
    CustomDatasetData,
    CustomDatasetsArgs,
    CustomDatasetsData,
    DeleteCustomDatasetMutationArgs,
    DeleteCustomDatasetResult
} from 'graphql.g';
import { CustomDatasetFromApi } from './types';
import { MAPBOX_ACCESS_TOKEN } from 'constants/map';

const UPLOAD_CUSTOM_DATA_URL = `${config.apiHost}/custom-dataset/upload`;

interface CustomDataForApi {
    title: string;
    hexColor: string;
    mapviewId: string;
    latCol: number;
    lngCol: number;
    file: File;
}

/** Saves a custom dataset to the backend via a POST request. Will return a
 * boolean indicating if the request was successful.
 */
export async function postCustomDataset(
    authToken: string,
    { title, hexColor, mapviewId, latCol, lngCol, file }: CustomDataForApi
) {
    const formData = new FormData();

    formData.append('name', title);
    formData.append('hex_color', hexColor);
    formData.append('mapview_id', mapviewId);
    formData.append('latitude_column_index', latCol.toString());
    formData.append('longitude_column_index', lngCol.toString());
    formData.append('file', file);

    const response = await fetch(UPLOAD_CUSTOM_DATA_URL, {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${authToken}`
        },
        body: formData
    });

    if (!response.ok) {
        throw new Error(
            `Couldn't upload custom dataset "${title}" for mapview "${mapviewId}". ${response.status}: ${response.statusText}`
        );
    }

    return response.ok;
}

export const CUSTOM_DATASETS_LIST_QUERY = gql`
    query CustomDatasets($mapviewId: ID!) {
        mapViewById(id: $mapviewId) {
            id
            customDatasets {
                id
                name
                hexColor
            }
        }
    }
`;

export function useCustomDatasetsList() {
    const { id } = useMapView();
    const data = useData<CustomDatasetsData, CustomDatasetsArgs>(
        CUSTOM_DATASETS_LIST_QUERY,
        { mapviewId: id }
    );

    const sortedDatasets = useMemo(() => {
        const unsortedDatasets = data?.mapViewById.customDatasets ?? [];
        return [...unsortedDatasets].sort((a, b) =>
            a.name.localeCompare(b.name)
        );
    }, [data]);

    return sortedDatasets;
}

export const CUSTOM_DATASET_QUERY = gql`
    query CustomDataset($mapviewId: ID!, $customDatasetId: ID!) {
        mapViewById(id: $mapviewId) {
            id
            customDataset(id: $customDatasetId) {
                id
                name
                hexColor
                latitudeColumnIndex
                longitudeColumnIndex
                csvFile
            }
        }
    }
`;

/** Fetches a single custom dataset for a mapview by id, without parsing the file.
 * This is intended for use when downloading the csv directly.
 */
export async function fetchCustomDatasetRaw(
    apolloClient: ApolloClient<any>,
    mapviewId: string,
    customDatasetId: string
) {
    const { data } = await apolloClient.query<
        CustomDatasetData,
        CustomDatasetArgs
    >({
        query: CUSTOM_DATASET_QUERY,
        variables: { mapviewId, customDatasetId }
    });

    const dataset = data.mapViewById.customDataset;

    if (dataset == null) {
        throw new Error(
            `No custom dataset returned for id ${customDatasetId} for mapview ${mapviewId}`
        );
    }

    return dataset;
}

/**
 * Fetches a single custom dataset for a mapview by id. This will throw an error
 * if the dataset doesn't exist, otherwise it wil parse and return the data.
 */
export async function fetchCustomDataset(
    apolloClient: ApolloClient<any>,
    mapviewId: string,
    customDatasetId: string
): Promise<CustomDatasetFromApi> {
    const dataset = await fetchCustomDatasetRaw(
        apolloClient,
        mapviewId,
        customDatasetId
    );

    const parsedCsv = await new Promise<string[][]>(resolve => {
        Papa.parse(dataset.csvFile, {
            skipEmptyLines: true,
            worker: true,
            complete: results => resolve(results.data as string[][])
        });
    });

    const headers = parsedCsv[0];
    const rows = parsedCsv.slice(1);

    const rowsWithData = rows.filter(row => {
        // if every cell in a row is an empty string, remove it
        return !row.every(cell => cell.trim() === '');
    });

    return {
        color: dataset.hexColor,
        title: dataset.name,
        rows: rowsWithData,
        headers,
        id: dataset.id,
        identifiers: {
            lat: dataset.latitudeColumnIndex,
            lng: dataset.longitudeColumnIndex
        }
    };
}

const DELETE_CUSTOM_DATASET_MUTATION = gql`
    mutation DeleteCustomDataset($customDatasetId: ID!) {
        deleteCustomDataset(deletedCustomDatasetId: $customDatasetId) {
            customDatasetId
        }
    }
`;

/**
 * Creates a function that can be used to send a GraphQL mutation to delete a
 * dataset.
 *
 * Example usage:
 * const [deleteCustomDataset, { loading, error }] = useDeleteCustomDataset();
 * deleteCustomDataset({ variables: { customDatasetId: "your-dataset-id" } });
 */
export function useDeleteCustomDataset() {
    const mapview = useMapView();
    return useMutation<
        DeleteCustomDatasetResult,
        DeleteCustomDatasetMutationArgs
    >(DELETE_CUSTOM_DATASET_MUTATION, {
        // we update the cache here to remove this custom dataset for this mapview.
        // if we don't update the cache, then we'll have outdated information
        // within the React app until a user refreshes the page or a query
        // goes through to the backend again.
        update(cache, { data }) {
            if (data?.deleteCustomDataset?.customDatasetId) {
                const id = data.deleteCustomDataset.customDatasetId;
                cache.modify({
                    // get the mapview part of the cache, which is where the
                    // custom datasets live
                    id: cache.identify(mapview),
                    fields: {
                        customDatasets(customDatasets, { readField }) {
                            // remove this specific dataset from the collection
                            // of datasets on this mapview.
                            return customDatasets.filter(
                                dataset => readField('id', dataset) !== id
                            );
                        }
                    }
                });
            }
        }
    });
}

/** Converts array of string addresses to geojson feature objects */
export const batchConvertGeocodeToFeatures = async (
    batch: string[] | undefined,
    bbox: number[]
) => {
    try {
        const bodyQuery = batch?.map(address => ({
            q: address,
            limit: 1,
            bbox
        }));
        const response = await fetch(
            `https://api.mapbox.com/search/geocode/v6/batch?&access_token=${MAPBOX_ACCESS_TOKEN}`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(bodyQuery)
            }
        );
        const result = await response.json();
        const features = result.batch.map(
            (collection: any) => collection.features[0]
        );
        return features;
    } catch (error) {
        throw new Error('Unable to convert GeoCodes to features');
    }
};
