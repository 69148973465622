import React, { useMemo, useEffect } from 'react';
import { AreaReport } from './report';
import {
    Table,
    TableSection,
    HeaderRow,
    Row,
    messages,
    HeaderProps
} from './tables';
import { defineMessages, FormattedMessage } from 'react-intl';
import { useIntl } from 'utils/use-intl';
import { rotateAreaMetricsData, getUrlWithChangedParams } from 'utils/helpers';
import statDescriptions from '../constants/stat-descriptions';
import Loading from 'common/loading';
import {
    MdsAoiInfoFragment,
    MdsAoiTripCountsFragment,
    MdsAoiInfo,
    MdsAoiTripCounts,
    MdsAoiMorningDeployments,
    MdsAoiMorningDeploymentsFragment
} from './fragments';
import gql from 'graphql-tag';
import { useReloadingData } from 'utils/use-data';
import { OperatorsData } from 'types';
import { OperatorName } from 'constants/operators';
import { LocalDate } from 'utils/date-tools';
import { useMapView } from 'common/use-map-view';
import { useVehicleClass } from 'common/vehicle-classes';
import { useRouteMatch, useHistory, useParams } from 'react-router';
import { AoiInfoFragment } from 'graphql.g';
import { ReportNotFound } from 'page-report';
import findAreaMetricMatchingSlug from './page-daily-area-find-metric';
import useDocumentTitle from 'common/use-document-title';
import { useUnfinalizedOperators } from './page-daily-summary';
import orderBy from 'lodash/orderBy';
import zipWith from 'lodash/zipWith';
import { useSorting } from 'common/table-sorting';
import DailyAreaChart from './page-daily-area-chart';

type AdditionalMetrics = {
    tripsPerVehicle: number | null;
    hourlyDeploymentCounts: [number, number][] | null;
};

type Props = {
    reportDate: LocalDate;
};

const m = defineMessages({
    'metrics-by-operator': 'Metrics by Operator',
    'hourly-deployments-tooltip':
        '{count, number} {operator} vehicles at {time, time, hour}',
    'document-title': '{area} · {day, date, reportday} Report · Ride Report'
});

const headerInfo = [
    statDescriptions.startTrips,
    statDescriptions.endTrips,
    statDescriptions.mdsMorningDeployed,
    statDescriptions.tripsPerVehicle
];
const sortKeys = [
    'tripStartCount',
    'tripEndCount',
    'morningDeploymentCounts.availableCount',
    'tripsPerVehicle'
];
const headers: HeaderProps[] = zipWith(headerInfo, sortKeys, (a, b) => {
    return [a, b];
});

/**
 * Our AOI slugs have multiple pieces of information potentially associated
 * with them that optionally can be used to disambiguate areas with the same
 * name:
 *
 *      name : createdDate : operator
 *
 * "name" is a url-friendly version of the area's name
 * "createdDate" is the date that the area was created in the mapview's local tz
 * "operator" is set for areas that belong to operator dashboards (ie, an area
 *      that has not been inherited from an agency mapview.)
 *
 * Only the name is required, and any missing elements can be 'inferred' if
 * necessary. This function tries to find a metric that matches a given slug,
 * prioritizing more explicit matches over implicit ones.
 *
 * SOME EXAMPLE SLUGS
 *
 * City limits with the created date to be inferred
 *      city-limits
 *
 * City limits with an explicit created date
 *      city-limits:2020-08-12
 *
 * A zone belonging to an operator dashboard, with the created date inferred
 *      special-zone::tayo
 *
 * A zone belonging to an operator dashboard, with an explicit created date
 *      special-zone:2020-11-01:tayo
 */
export function useAreaMetricsMatchingSlug<T extends { area: AoiInfoFragment }>(
    metrics: T[] | null
) {
    const match = useRouteMatch<{ area: string }>();
    const slug = match.params.area;
    if (slug === undefined) {
        // If you're getting this error, either you're using this function
        // incorrectly, or you need to refactor it to support your use case.
        // If you're changing this, make sure to fix the history.replace() call
        // down below, too!
        throw new Error(
            "This function was designed under the assumption that there is route param named 'area' and will not work if that param doesn't exist."
        );
    }

    const history = useHistory();
    const metric = useMemo(
        () => metrics && findAreaMetricMatchingSlug(slug, metrics),
        [metrics, slug]
    );

    useEffect(() => {
        if (metric && metric.area.slug !== slug) {
            history.replace(
                getUrlWithChangedParams(match.path, { area: metric.area.slug })
            );
        }
    }, [history, match.path, metric, slug]);
    if (!metrics) return null;
    return metric;
}

export function PageDailyArea({ reportDate }: Props) {
    const mapView = useMapView();
    const vehicleClass = useVehicleClass();
    const intl = useIntl();
    const [data, loading] = useReloadingData<Data>(QUERY, {
        slug: mapView.slug,
        date: reportDate.toString(),
        vehicleClass
    });
    const areaFromUrl = useParams<{ area: string }>().area;

    const unfinalizedOperators = useUnfinalizedOperators(reportDate);

    const areas = useMemo(
        () =>
            rotateAreaMetricsData(
                data,
                op => op.report && op.report.aoiMdsMetrics
            ),
        [data]
    );
    const areaMetrics = useAreaMetricsMatchingSlug(areas);

    const title = intl.formatMessage(m['document-title'], {
        area: areaMetrics?.area.name,
        day: reportDate?.toDate()
    });

    useDocumentTitle(title);
    const [sorting, onSortHeaderClick] = useSorting<
        | 'name'
        | 'tripStartCount'
        | 'tripEndCount'
        | 'morningDeploymentCounts.availableCount'
        | 'tripsPerVehicle'
    >('name');

    const operators = useMemo(() => {
        const list =
            areaMetrics?.operators ??
            mapView.operators.map(op => ({
                name: op.name,
                operatorId: op.operatorId,
                tripStartCount: null,
                tripEndCount: null,
                morningDeploymentCounts: null,
                tripsPerVehicle: null,
                hourlyDeploymentCounts: null
            }));
        return orderBy(list, [...sorting.keys()], [...sorting.values()]);
    }, [sorting, areaMetrics, mapView]);

    if (areas && !areaMetrics)
        return (
            <ReportNotFound reportDate={reportDate} areaFromUrl={areaFromUrl} />
        );
    if (!areaMetrics) return <Loading />;

    return (
        <AreaReport
            reportDate={reportDate}
            area={areaMetrics.area}
            unfinalizedOperators={unfinalizedOperators}
        >
            <DailyAreaChart
                loading={loading}
                title={
                    <FormattedMessage
                        key="hourly-chart-title"
                        defaultMessage="Hourly Available Vehicles in {area}"
                        values={{ area: areaMetrics?.area.name ?? '' }}
                    />
                }
                date={reportDate}
                operators={operators}
            />

            <TableSection title={intl.formatMessage(m['metrics-by-operator'])}>
                <Loading loading={loading} kind="over-table" />
                <Table>
                    <HeaderRow
                        headers={headers}
                        sorting={sorting}
                        onSortHeaderClick={onSortHeaderClick}
                    />
                    <tbody>
                        {operators.map(op => (
                            <Row
                                key={op.operatorId + op.name}
                                name={op.name}
                                values={[
                                    op.tripStartCount,
                                    op.tripEndCount,
                                    op.morningDeploymentCounts
                                        ?.availableCount ?? null,
                                    op.tripsPerVehicle
                                ]}
                            />
                        ))}
                        <Row
                            name={intl.formatMessage(messages['total-row'])}
                            values={[
                                intl.formatNumber(
                                    operators.reduce(
                                        (value, op) =>
                                            value + (op?.tripStartCount ?? 0),
                                        0
                                    )
                                ),
                                intl.formatNumber(
                                    operators.reduce(
                                        (value, op) =>
                                            value + (op?.tripEndCount ?? 0),
                                        0
                                    )
                                ),
                                intl.formatNumber(
                                    operators.reduce(
                                        (value, op) =>
                                            value +
                                            (op?.morningDeploymentCounts
                                                ?.availableCount ?? 0),
                                        0
                                    )
                                )
                            ]}
                        />
                    </tbody>
                </Table>
            </TableSection>
        </AreaReport>
    );
}
const QUERY = gql`
    query DailyReportAreas(
        $slug: String
        $date: Date!
        $vehicleClass: VehicleClass
    ) {
        mapView(slug: $slug) {
            id
            operators {
                id
                operatorId
                name
                report(date: $date, vehicleClass: $vehicleClass) {
                    id
                    aoiMdsMetrics {
                        ...MdsAoiInfo
                        ...MdsAoiTripCounts
                        ...MdsAoiMorningDeployments
                        tripsPerVehicle
                        hourlyDeploymentCounts
                    }
                }
            }
        }
    }
    ${MdsAoiInfoFragment}
    ${MdsAoiMorningDeploymentsFragment}
    ${MdsAoiTripCountsFragment}
`;

type Data = OperatorsData<{
    operatorId: string;
    id: string;
    name: OperatorName;
    report: {
        aoiMdsMetrics: (MdsAoiInfo &
            MdsAoiTripCounts &
            AdditionalMetrics &
            MdsAoiMorningDeployments)[];
    } | null;
}>;

export default PageDailyArea;
