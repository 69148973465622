import {
    BACK_LINK,
    Button,
    CONTAINER,
    ContainerHeader,
    PageContent
} from 'common/layout';
import React, { useCallback, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'router';
import "/opt/build/repo/src/page-health/page-trip-filter-new.tsx?resplendence=true";
import { useVehicleClasses } from 'common/vehicle-classes';
import Loading from 'common/loading';
import NewTripFilterRow from './page-trip-filter-new-row';
import {
    CreateFilterMutationArgs,
    CreateFilterResult,
    GetTripFiltersArgs,
    GetTripFiltersData,
    TripFilterInfoFragment,
    VehicleClass
} from 'graphql.g';
import { gql, useMutation } from '@apollo/client';
import { useMapView } from 'common/use-map-view';
import { ErrorCallout } from 'common/error-message';
import { useHistory } from 'react-router';
import { CommonTitle } from './common-page';
import useData from 'utils/use-data';
import {
    Filter,
    filterToInput,
    FilterValue,
    GET_TRIP_FILTERS,
    mdsDistanceInUnits,
    mdsDurationInUnits,
    TRIP_FILTER_WITH_EXCLUDED_FRAGMENT
} from './page-trip-filter-common';

/*
@import 'style';
*/;

function PageTripFilterNew() {
    const vehicleClasses = useVehicleClasses();

    const { slug } = useMapView();

    const existingFilters = useData<GetTripFiltersData, GetTripFiltersArgs>(
        GET_TRIP_FILTERS,
        { slug }
    );

    return (
        <PageContent>
            <CommonTitle />
            <Link to=".." className={BACK_LINK}>
                <FormattedMessage
                    key="back-link"
                    defaultMessage="Back to Feed Health"
                />
            </Link>
            <Loading
                kind="over-table"
                loading={!vehicleClasses && !existingFilters}
            />
            {vehicleClasses && existingFilters && (
                <TripFilterForm
                    vehicleClasses={vehicleClasses}
                    existingFilters={existingFilters.mapView.tripFilters}
                />
            )}
        </PageContent>
    );
}

const TABLE = "rx-page-health-page-trip-filter-new-1"/*
    @include table;
    border-collapse: separate;
    td, th {
        vertical-align: middle;
    }
    thead th {
        box-sizing: content-box;
        padding-bottom: 15rem;
    }
    tbody tr {
        > * {
            height: 88rem;
            vertical-align: center;
            border-color: transparent;
            border-style: solid;
            border-width: 0;
            &:first-child {
                @include text-body-bold;
                white-space: nowrap;
                svg {
                    font-size: 24rem;
                    margin-right: 16rem;
                }
            }
        }
    }
    td:last-child {
        box-sizing: content-box;
        width: 164rem;
    }
    tr.sub-row td:first-child {
        text-align: right;
    }
    max-width: 100%;
*/;

const CREATE_FILTER_MUTATION = gql`
    mutation CreateFilter(
        $id: ID!
        $slug: String!
        $filters: [CreateTripFilterInput!]!
    ) {
        createTripFilter(mapviewId: $id, filters: $filters) {
            mapView {
                id
                tripFilters {
                    ...TripFilterWithExcludedPercent
                }
            }
        }
    }
    ${TRIP_FILTER_WITH_EXCLUDED_FRAGMENT}
`;

// NOTE: Djscoots has a similar "default recommended filters" constant.
// If you're making changes to our application's default recommended trip filters,
// please do it in a holistic way that affects both these filters in the frontend
// and those filters in the backend.
// https://github.com/RideReport/djscoots/blob/585699c101cdfd44454def87dbcc32d68b437584/public_dashboard/data/compose.py#L21-L37
const BIKE_RECOMMENDED_FILTER: Filter = {
    minDistance: { value: 1, unit: 'meters' },
    maxDistance: { value: 70, unit: 'kilometers' },
    minDuration: { value: 1, unit: 'seconds' },
    maxDuration: { value: 8, unit: 'hours' }
};
const UNKNOWN_RECOMMENDED_FILTER: Filter = {
    minDistance: { value: 1, unit: 'meters' },
    maxDistance: null,
    minDuration: { value: 1, unit: 'seconds' },
    maxDuration: null
};
const SCOOTER_RECOMMENDED_FILTER: Filter = {
    minDistance: { value: 1, unit: 'meters' },
    maxDistance: { value: 50, unit: 'kilometers' },
    minDuration: { value: 1, unit: 'seconds' },
    maxDuration: { value: 6, unit: 'hours' }
};

const RECOMMENDED_FILTER_BY_VEHICLE_CLASS: { [key in VehicleClass]: Filter } = {
    bike: BIKE_RECOMMENDED_FILTER,
    ebike: BIKE_RECOMMENDED_FILTER,
    scooter: SCOOTER_RECOMMENDED_FILTER,
    moped: UNKNOWN_RECOMMENDED_FILTER,
    car: UNKNOWN_RECOMMENDED_FILTER,
    other: UNKNOWN_RECOMMENDED_FILTER,
    cargo_bike: BIKE_RECOMMENDED_FILTER,
    cargo_ebike: BIKE_RECOMMENDED_FILTER,
    seated_scooter: SCOOTER_RECOMMENDED_FILTER,
    delivery_robot: UNKNOWN_RECOMMENDED_FILTER,
    bus: UNKNOWN_RECOMMENDED_FILTER,
    truck: UNKNOWN_RECOMMENDED_FILTER,
    motorcycle: UNKNOWN_RECOMMENDED_FILTER,
    pedestrian: UNKNOWN_RECOMMENDED_FILTER
};

function filterDataToFilterField(filter: TripFilterInfoFragment): Filter {
    return {
        minDistance:
            filter.minDistance === null
                ? null
                : mdsDistanceInUnits(filter.minDistance),
        maxDistance:
            filter.maxDistance === null
                ? null
                : mdsDistanceInUnits(filter.maxDistance),
        minDuration:
            filter.minDuration === null
                ? null
                : mdsDurationInUnits(filter.minDuration),
        maxDuration:
            filter.maxDuration === null
                ? null
                : mdsDurationInUnits(filter.maxDuration)
    };
}

type TripFilterFormProps = {
    vehicleClasses: VehicleClass[];
    existingFilters: TripFilterInfoFragment[];
};
export function TripFilterForm({
    vehicleClasses,
    existingFilters
}: TripFilterFormProps) {
    const initialState = useMemo(() => {
        const filtersByVehicleClass: {
            [key in VehicleClass]?: Filter;
        } = {};
        for (const vehicleClass of vehicleClasses) {
            filtersByVehicleClass[vehicleClass] =
                RECOMMENDED_FILTER_BY_VEHICLE_CLASS[vehicleClass];
        }
        for (const filter of existingFilters) {
            if (filter.vehicleClass in filtersByVehicleClass) {
                filtersByVehicleClass[
                    filter.vehicleClass
                ] = filterDataToFilterField(filter);
            }
        }
        return filtersByVehicleClass;
    }, [existingFilters, vehicleClasses]);
    const [filters, setFilters] = useState(initialState);
    const updateFilter = useCallback(
        (
            filter: keyof Filter,
            vehicleClass: VehicleClass,
            value: FilterValue | null
        ) => {
            setFilters(filters => ({
                ...filters,
                [vehicleClass]: {
                    ...filters[vehicleClass],
                    [filter]: value
                }
            }));
        },
        []
    );

    const [mutation, { loading, error }] = useMutation<
        CreateFilterResult,
        CreateFilterMutationArgs
    >(CREATE_FILTER_MUTATION);
    const history = useHistory();
    const { id, slug } = useMapView();
    const createTripFilter = async () => {
        await mutation({
            variables: {
                id,
                slug,
                filters: vehicleClasses.map(vc =>
                    filterToInput(vc, filters[vc] as Filter)
                )
            }
        });
        history.push(`/${slug}/health/trip-filter`, 'created');
    };
    return (
        <>
            {error && <ErrorCallout error={error} />}
            <form
                className={CONTAINER}
                onSubmit={e => {
                    e.preventDefault();
                    createTripFilter();
                }}
            >
                <Loading size={4} loading={!vehicleClasses} kind="over-table" />
                <ContainerHeader el="h2">
                    <FormattedMessage
                        key="header"
                        defaultMessage="Create new trip filter configuration"
                    />
                </ContainerHeader>
                <table className={TABLE}>
                    <thead>
                        <tr>
                            <th>
                                <FormattedMessage
                                    key="vehicle-class-header"
                                    defaultMessage="Vehicle Class"
                                />
                            </th>
                            <th>
                                <FormattedMessage
                                    key="distance-header"
                                    defaultMessage="Distance"
                                />
                            </th>
                            <th>
                                <FormattedMessage
                                    key="duration-header"
                                    defaultMessage="Duration"
                                />
                            </th>
                            <th>
                                <FormattedMessage
                                    key="valid-header"
                                    defaultMessage="Valid Trips (Preview)"
                                />
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {vehicleClasses.map(vc => (
                            <NewTripFilterRow
                                key={vc}
                                vehicleClass={vc}
                                filter={filters[vc] as Filter}
                                updateFilter={updateFilter}
                            />
                        ))}
                    </tbody>
                </table>
                <div>
                    <Button type="submit" loading={loading} color="blue">
                        <FormattedMessage
                            key="save-button"
                            defaultMessage="Save New Configuration"
                        />
                    </Button>
                </div>
            </form>
        </>
    );
}

export default PageTripFilterNew;
