import React from 'react';

import { MapOptions, DispatchMapOptions } from 'page-explore';
import { FormattedMessage } from 'react-intl';
import { useMapView } from 'common/use-map-view';
import { DateRange } from 'common/date-picker';
import DownloadButton from 'common/download-button';
import Sidebar from '../../common/sidebar/sidebar';
import SidebarCustomDatasets from '../../common/sidebar/custom-datasets';

interface ExportTripsProps {
    metric: 'starts' | 'ends';
    hourRange: [number, number];
    dataPeriod: DateRange;
}

const ExportTrips = ({ metric, hourRange, dataPeriod }: ExportTripsProps) => {
    const slug = useMapView().slug;
    const start_date = dataPeriod[0].toString();
    const end_date = dataPeriod[1].toString();
    const start_hour = hourRange[0].toString();
    const end_hour = hourRange[1].toString();

    return (
        <DownloadButton
            icon="Download"
            path="trip-hex-metrics"
            filename={`${slug}_trip_${metric}_${start_date}_to_${end_date}_${start_hour}_${end_hour}_export(CONFIDENTIAL).zip`}
            params={{
                mapview_slug: slug,
                metric: metric,
                start_date: start_date,
                end_date: end_date,
                start_hour: start_hour,
                end_hour: end_hour
            }}
            failureReason="download-failure-time-range"
        >
            <FormattedMessage
                key="button-label"
                defaultMessage="Export Trips"
            />
        </DownloadButton>
    );
};

interface TripsSidebarProps {
    mapOptions: MapOptions;
    dispatchMapOptions: DispatchMapOptions;
    hourRange: [number, number];
    dataPeriod: DateRange;
    metric: 'starts' | 'ends';
}

const TripsSidebar = ({
    mapOptions,
    dispatchMapOptions,
    hourRange,
    dataPeriod,
    metric
}: TripsSidebarProps) => {
    return (
        <Sidebar
            mapOptions={mapOptions}
            dispatchMapOptions={dispatchMapOptions}
            headerButton={
                <ExportTrips
                    metric={metric}
                    hourRange={hourRange}
                    dataPeriod={dataPeriod}
                />
            }
        >
            <SidebarCustomDatasets />
        </Sidebar>
    );
};

export default TripsSidebar;
