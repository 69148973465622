import { getRoundNum, range } from 'utils/helpers';

/**
 * Returns an array of exponentially spaced out numbers
 *
 * @param min minimum value for this stepped array
 * @param max maximum value for this stepped array
 * @param maxNumberOfSteps the most steps that this array can have
 */
export const steppedArray = (
    min: number,
    max: number,
    maxNumberOfSteps: number
): number[] => {
    let maxSteps = maxNumberOfSteps;
    let steps: number[] = [];
    let hasRepeat = true;

    const logMin = Math.log1p(min);
    const logMax = Math.log1p(max);
    // figure out how many discreet steps we can have without repeating our
    // rounded values
    while (hasRepeat) {
        hasRepeat = false;
        const stepSize = (logMax - logMin) / maxSteps;

        steps = range(maxSteps)
            .map(i => logMin + i * stepSize + 0.5 * stepSize)
            .map(Math.expm1)
            .map(Math.ceil)
            .map(getRoundNum);

        const stepSet = new Set(steps);
        if (stepSet.size !== steps.length) {
            // there's a repeated value, try again with less steps
            hasRepeat = true;
            maxSteps--;
        }
    }

    return steps;
};
