export const MAPBOX_ACCESS_TOKEN =
    'pk.eyJ1IjoicXVpY2tseXdpbGxpYW0iLCJhIjoibmZ3UkZpayJ9.8gNggPy6H5dpzf4Sph4-sA';

export const HEATMAP_COLORS = [
    '#FFDDC0',
    '#F9BC88',
    '#E7779F',
    '#EB5190',
    '#B352F3',
    '#7E18CF',
    '#49058C'
];
