import React from 'react';
import {
    UserListInfoFragment,
    ChangeUserRoleResult,
    ChangeUserRoleMutationArgs,
    UserRoleType
} from 'graphql.g';
import { gql, useMutation } from '@apollo/client';
import { Button } from 'common/layout';
import { useIntl, defineMessages, IntlShape } from 'react-intl';
import "/opt/build/repo/src/root/page-settings-user.tsx?resplendence=true";
import { useUser } from 'common/user';
import useTippy from 'utils/use-tippy';
import { Select } from 'common/select';

/*
@import 'style.scss';
*/;

type Props = {
    user: UserListInfoFragment;
    onError: (error: Error) => void;
    onDelete: () => void;
};

export const USER_LIST_INFO = gql`
    fragment UserListInfo on UserOrgAuth {
        id
        email
        role
    }
`;

const CHANGE_ROLE_MUTATION = gql`
    mutation ChangeUserRole($email: String!, $role: UserRoleType!) {
        changeUserRole(changeUserEmail: $email, newRole: $role) {
            userOrgAuth {
                ...UserListInfo
            }
        }
    }
    ${USER_LIST_INFO}
`;

const messages = defineMessages({
    revoke_access_tooltip: 'Revoke Access',
    user_role_User: 'User',
    user_role_Administrator: 'Administrator'
});

export const userRoleString = (intl: IntlShape) => (
    role: UserRoleType
): string => {
    return intl.formatMessage(messages[`user_role_${role}`]);
};

function PageSettingsUser({ user, onError, onDelete }: Props) {
    const [changeRole, { loading }] = useMutation<
        ChangeUserRoleResult,
        ChangeUserRoleMutationArgs
    >(CHANGE_ROLE_MUTATION);
    const intl = useIntl();
    const currentUser = useUser();
    const canEdit = currentUser.orgAuth.role === 'Administrator';
    const [revokeAccessTooltip] = useTippy<HTMLButtonElement>(
        intl.formatMessage(messages.revoke_access_tooltip),
        { placement: 'top' }
    );

    const formatRole = userRoleString(intl);

    return (
        <tr>
            <td>{user.email}</td>
            <td>
                {canEdit ? (
                    <Select
                        isLoading={loading}
                        options={Object.values(UserRoleType).map(role => ({
                            value: role,
                            label: formatRole(role)
                        }))}
                        value={{
                            value: user.role,
                            label: formatRole(user.role)
                        }}
                        onChange={selectedOption => {
                            const value = selectedOption?.value;
                            if (!value) return;
                            changeRole({
                                variables: { email: user.email, role: value }
                            }).catch(err => {
                                window.rollbar.error(err);
                                console.error(err);
                                onError(err);
                            });
                        }}
                    />
                ) : (
                    formatRole(user.role)
                )}
            </td>
            {canEdit && (
                <td>
                    <Button
                        ref={revokeAccessTooltip}
                        color="outline-red"
                        onClick={onDelete}
                        icon="User-Remove"
                    />
                </td>
            )}
        </tr>
    );
}

export default PageSettingsUser;
