import React, { useMemo, useState } from 'react';
import {
    Table,
    HeaderRow,
    Row,
    NoAreasInReport,
    LoadingTable,
    messages,
    CONSTRAINED_TABLE,
    HeaderProps
} from './tables';
import { sortByAreas } from 'utils/helpers';
import statDescriptions from '../constants/stat-descriptions';
import Loading from 'common/loading';
import gql from 'graphql-tag';
import {
    MdsAoiInfoFragment,
    MdsAoiTripCountsFragment,
    MdsAoiMorningDeploymentsFragment
} from './fragments';
import { useReloadingData } from 'utils/use-data';
import { MapView } from 'types';
import { LocalDate } from 'utils/date-tools';
import {
    SingleOperatorDailyAoiDataData,
    SingleOperatorDailyAoiDataArgs
} from 'graphql.g';
import { useVehicleClass } from 'common/vehicle-classes';
import useSearchFilter from 'utils/search';
import TableSearchInput from 'common/table-search-input';
import { useIntl } from 'utils/use-intl';
import orderBy from 'lodash/orderBy';
import zipWith from 'lodash/zipWith';
import { useSorting } from 'common/table-sorting';

interface Props {
    mapView: MapView;
    operatorId: string;
    reportDate: LocalDate;
}

const QUERY = gql`
    query SingleOperatorDailyAoiData(
        $slug: String
        $operatorId: ID
        $date: Date!
        $vehicleClass: VehicleClass
    ) {
        mapView(slug: $slug) {
            id
            operator(id: $operatorId) {
                id
                report(date: $date, vehicleClass: $vehicleClass) {
                    id
                    aoiMdsMetrics {
                        ...MdsAoiInfo
                        ...MdsAoiTripCounts
                        ...MdsAoiMorningDeployments
                    }
                }
            }
        }
    }
    ${MdsAoiInfoFragment}
    ${MdsAoiTripCountsFragment}
    ${MdsAoiMorningDeploymentsFragment}
`;

const headerInfo = [
    statDescriptions.startTrips,
    statDescriptions.endTrips,
    statDescriptions.mdsMorningDeployed
];
const sortKeys = [
    'tripStartCount',
    'tripEndCount',
    'morningDeploymentCounts.availableCount'
];
const headers: HeaderProps[] = zipWith(headerInfo, sortKeys, (a, b) => {
    return [a, b];
});

function PageDailyOperatorsArea({ mapView, operatorId, reportDate }: Props) {
    const intl = useIntl();
    const vehicleClass = useVehicleClass();
    const [data, loading] = useReloadingData<
        SingleOperatorDailyAoiDataData,
        SingleOperatorDailyAoiDataArgs
    >(QUERY, {
        slug: mapView.slug,
        operatorId,
        date: reportDate.toString(),
        vehicleClass
    });
    const [sorting, onSortHeaderClick] = useSorting<
        | 'tripStartCount'
        | 'tripEndCount'
        | 'morningDeploymentCounts.availableCount'
    >();
    const report = data?.mapView.operator?.report;
    const areaMetrics = useMemo(() => {
        return orderBy(
            report?.aoiMdsMetrics
                ? sortByAreas(report.aoiMdsMetrics, x => x.area)
                : [],
            [...sorting.keys()],
            [...sorting.values()]
        );
    }, [sorting, report]);
    const [filter, setFilter] = useState('');
    const filteredAreas = useSearchFilter(
        areaMetrics,
        metric => metric.area.name,
        filter
    );
    return (
        <>
            <Loading loading={loading} kind="over-table" />
            <TableSearchInput
                placeholder={intl.formatMessage(messages['search-areas'])}
                value={filter}
                onChange={setFilter}
            />
            <div className={CONSTRAINED_TABLE}>
                {report?.aoiMdsMetrics ? (
                    areaMetrics.length > 0 ? (
                        <Table>
                            <HeaderRow
                                wideLeftLabels
                                headers={headers}
                                sorting={sorting}
                                onSortHeaderClick={onSortHeaderClick}
                            />
                            <tbody>
                                {filteredAreas.map(metric => (
                                    <Row
                                        key={metric.area.name}
                                        name={metric.area.name}
                                        values={[
                                            metric.tripStartCount,
                                            metric.tripEndCount,
                                            metric.morningDeploymentCounts
                                                ?.availableCount ?? null
                                        ]}
                                    />
                                ))}
                            </tbody>
                        </Table>
                    ) : (
                        <NoAreasInReport />
                    )
                ) : (
                    <LoadingTable headers={headers} />
                )}
            </div>
        </>
    );
}

export default PageDailyOperatorsArea;
