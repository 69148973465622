import { useUser } from 'common/user';
import { PolicyType } from 'graphql.g';
import React from 'react';
import { defineMessages, useIntl, FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router';
import "/opt/build/repo/src/page-policy/policy-selector.tsx?resplendence=true";
import cx from 'classnames';

/*
    @import 'style';
*/;

const CARD = "rx-page-policy-policy-selector-1"/*
    @include container;
    height: 176rem;
    padding: 24rem;
    transition: all 100ms ease-in-out;
    &:not(.disabled):hover {
        border-color: $blue-50;
        background-color: $blue-00;
        cursor: pointer;
    }
    &.disabled {
        background-color: $gray-10;
        color: $gray-70;
        pointer-events: none;
    }
*/;

const CARD_TITLE = "rx-page-policy-policy-selector-2"/*
    @include headline-5;
*/;

const LINK = "rx-page-policy-policy-selector-3"/*
    margin-top: 8rem;
    > a {
        @include text-body-link-underlined;
        pointer-events: auto;
        color: $blue-50;
    }
*/;

interface PolicyCardProps {
    title: string;
    description: string;
    onClick: () => void;
    disabled: boolean;
}

function PolicyCard(props: PolicyCardProps) {
    return (
        <div
            className={cx(CARD, { disabled: props.disabled })}
            onClick={props.disabled ? undefined : props.onClick}
        >
            <h2 className={CARD_TITLE}>{props.title}</h2>
            <p>{props.description}</p>

            {props.disabled && (
                <p className={LINK}>
                    <FormattedMessage
                        key="policy-set-up"
                        defaultMessage=" Contact <0>support@ridereport.com</0> to set up this policy."
                        values={{
                            0: (msg: string) => (
                                <a href="mailto:support@ridereport.com">
                                    {msg}
                                </a>
                            )
                        }}
                    />
                </p>
            )}
        </div>
    );
}

export const AVAILABLE_POLICIES_MESSAGES = defineMessages({
    // these are keyed by the enum values from AVAILABLE_POLICIES, hence the underscores
    'vehicle_cap-name': 'Vehicle Cap',
    'vehicle_cap-description':
        'A Vehicle Cap specifies the maximum number of active vehicles an operator may have within a given zone at any time.',
    'vehicle_cap_percentage-name': 'Percentage-based Vehicle Cap',
    'vehicle_cap_percentage-description':
        'A Percentage-based Vehicle Cap specifies the maximum number of active vehicles an operator may have within a given zone at any time.',
    'no_parking_zone-name': 'No Parking Zone',
    'no_parking_zone-description':
        'A No Parking Zone Policy prevents vehicles from being parked in the given area.',
    'trip_start-name': 'Trip Start Fee',
    'trip_start-description':
        'A Trip Start Fee enacts a fee for each trip that starts within the given zone, regardless of where that trip ends.',
    'minimum_deployment-name': 'Minimum Deployment',
    'minimum_deployment-description':
        'A Minimum Deployment defines the minimum number of available vehicles an operator must have in an area at a specified time.',
    'minimum_deployment_percentage-name': 'Percentage-based Minimum Deployment',
    'minimum_deployment_percentage-description':
        "A Percentage-based Minimum Deployment defines the minimum percentage of an operator's fleet they must have in an area at a specified time.",
    'speed_limit-name': 'Speed Zone',
    'speed_limit-description': 'Restrict vehicle speeds in the given area.',
    'no_ride_zone-name': 'No Ride Zone',
    'no_ride_zone-description':
        'A No Ride Zone policy prevents vehicles from traveling through the given area.',
    'max_idle_time-name': 'Max Idle Time',
    'max_idle_time-description':
        'A Max Idle Time policy defines a maximum allowable time a vehicle can remain unused in a given area.'
});

export const AVAILABLE_POLICIES = [
    PolicyType.no_parking_zone,
    PolicyType.trip_start,
    PolicyType.vehicle_cap,
    PolicyType.vehicle_cap_percentage,
    PolicyType.minimum_deployment,
    PolicyType.minimum_deployment_percentage,
    PolicyType.speed_limit,
    PolicyType.no_ride_zone,
    PolicyType.max_idle_time
];

// Fee related policies require additional support to set up. We disable these options for all nonstaff users.
export const FEATURE_GATED_POLICIES = [PolicyType.trip_start];

function PolicySelector() {
    const history = useHistory();
    const intl = useIntl();
    const { useDemoFeatures } = useUser();

    return (
        <>
            {AVAILABLE_POLICIES.map(currentPolicy => {
                const isFeatureGated =
                    !useDemoFeatures &&
                    FEATURE_GATED_POLICIES.includes(currentPolicy);

                return (
                    <PolicyCard
                        key={currentPolicy}
                        disabled={isFeatureGated}
                        title={intl.formatMessage(
                            AVAILABLE_POLICIES_MESSAGES[`${currentPolicy}-name`]
                        )}
                        description={intl.formatMessage(
                            AVAILABLE_POLICIES_MESSAGES[
                                `${currentPolicy}-description`
                            ]
                        )}
                        onClick={() => {
                            history.push(
                                history.location.pathname.replace(
                                    'select',
                                    `${currentPolicy}`
                                )
                            );
                        }}
                    />
                );
            })}
        </>
    );
}

export default PolicySelector;
