import React, { useEffect } from 'react';
import "/opt/build/repo/src/page-report/report-picker-daily.tsx?resplendence=true";

import { ParamLink } from 'router';
import { LocalDate, LocalMonth } from 'utils/date-tools';
import { MapView } from 'types';
import gql from 'graphql-tag';
import { useReloadingData } from 'utils/use-data';
import { useApolloClient } from '@apollo/client';
import {
    getMonthStartEndDate,
    getMonthDateRange,
    getFinalizedReportDays
} from './report-picker-common';
import {
    Weekdays,
    CalendarMonthNav,
    PreliminaryDataKey
} from 'common/date-picker';
import Loading from 'common/loading';
import cx from 'classnames';
import Icon from 'common/icon';
import { flatMap } from 'utils/helpers';
import { ReportsListArgs, ReportsListData } from 'graphql.g';

/*
@import "style";
@import "~common/date-picker-styles";
*/;

const CALENDAR_GRID = "rx-page-report-report-picker-daily-1"/*
    @include calendar-grid;
    @include calendar;
    @include calendar-wrapper;
*/;

const DAY = "rx-page-report-report-picker-daily-2"/*
    @include calendar-cell;
*/;

export const EXISTING_DAILY_REPORTS = gql`
    query ReportsList($slug: String, $startDate: Date!, $endDate: Date!) {
        mapView(slug: $slug) {
            id
            operators {
                id
                name
                reports(startDate: $startDate, endDate: $endDate) {
                    id
                    reportDate
                    finalized
                }
            }
        }
    }
`;

export function useFinalizedReportDays(
    mapView: MapView,
    startDate: LocalDate,
    endDate: LocalDate
) {
    const [data, loading] = useReloadingData<ReportsListData, ReportsListArgs>(
        EXISTING_DAILY_REPORTS,
        {
            slug: mapView.slug,
            startDate: startDate.toString(),
            endDate: endDate.toString()
        }
    );

    const reportDays = getFinalizedReportDays(
        flatMap(data?.mapView.operators ?? [], op => op.reports ?? [])
    );
    return [reportDays, loading] as const;
}

type Props = {
    mapView: MapView;
    selectedMonth: LocalMonth;
    selectMonth: (month: LocalMonth) => void;
};

function ReportPickerDaily({ mapView, selectedMonth, selectMonth }: Props) {
    const client = useApolloClient();

    useEffect(() => {
        client.query({
            query: EXISTING_DAILY_REPORTS,
            variables: {
                slug: mapView.slug,
                ...getMonthStartEndDate(selectedMonth.minusMonths(1))
            }
        });
        client.query({
            query: EXISTING_DAILY_REPORTS,
            variables: {
                slug: mapView.slug,
                ...getMonthStartEndDate(selectedMonth.plusMonths(1))
            }
        });
    }, [client, mapView.slug, selectedMonth]);

    const [finalizedReportsByDate, loading] = useFinalizedReportDays(
        mapView,
        ...getMonthDateRange(selectedMonth)
    );

    const today = LocalDate.todayInTz(mapView.ianaTimezone).toString();

    return (
        <>
            <CalendarMonthNav month={selectedMonth} onChange={selectMonth} />
            <div className={CALENDAR_GRID}>
                <Loading loading={loading} kind="over-table" size={4} />
                <Weekdays />
                {selectedMonth.toWeeks().map(week =>
                    week.toDays().map(day => {
                        const dayStr = day.toString();
                        const beforeToday = dayStr < today;
                        const reportExists =
                            loading || dayStr in finalizedReportsByDate;
                        const outside = !day
                            .toLocalMonth()
                            .equals(selectedMonth);
                        return !reportExists && beforeToday ? (
                            <div
                                key={dayStr}
                                className={cx(DAY, 'missing', { outside })}
                            >
                                <Icon icon="Missing-File" />
                            </div>
                        ) : (
                            <ParamLink
                                params={{
                                    mode: 'daily',
                                    date: dayStr
                                }}
                                nav
                                key={dayStr}
                                disabled={!reportExists}
                                className={cx(DAY, {
                                    outside,
                                    preliminary:
                                        finalizedReportsByDate[dayStr] === false
                                })}
                            >
                                {day.day}
                            </ParamLink>
                        );
                    })
                )}
            </div>
            {Object.values(finalizedReportsByDate).some(
                finalized => !finalized
            ) && <PreliminaryDataKey />}
        </>
    );
}

export default ReportPickerDaily;
