import { colorArrayToRGB } from 'common/color-array-to-rgb';
import { HEX_COLORS } from 'page-explore/page-heatmaps/hex-bins';
import { useMemo } from 'react';
import { steppedArray } from 'utils/stepped-array';
import { RoutesData } from './api';

export interface DataStep {
    value: number;
    color: string;
    width: number;
}

// these are largely arbitrary - they look distinctive enough at varying
// zoom levels
const ROUTES_WIDTHS = [1, 1.5, 2.25, 3, 6, 11];

export function useRoutesDataSteps(
    aggregatedTripRoutes: RoutesData | null
): DataStep[] | null {
    return useMemo(() => {
        if (aggregatedTripRoutes == null) {
            return null;
        }

        const values = Object.values(aggregatedTripRoutes.referenceCounts);

        const max = values.reduce((max, { count }) => Math.max(max, count), 0);
        const min = values.reduce(
            (min, { count }) => Math.min(min, count),
            max
        );

        const steps = steppedArray(min, max, HEX_COLORS.length);

        return steps.map((value, i) => ({
            value,
            color: colorArrayToRGB(
                HEX_COLORS[Math.round((i * HEX_COLORS.length) / steps.length)]
            ),
            width:
                ROUTES_WIDTHS[
                    Math.round((i * ROUTES_WIDTHS.length) / steps.length)
                ]
        }));
    }, [aggregatedTripRoutes]);
}
