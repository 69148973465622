import React from 'react';
import { FormattedMessage } from 'react-intl';
import "/opt/build/repo/src/page-segment-editor/segment-editor-filter-panel.tsx?resplendence=true";
import { MultiSelect } from 'common/select';

/*
@import 'style';
*/;

const CONTAINER = "rx-page-segment-editor-segment-editor-filter-panel-1"/*
    @include shadow-2;
    background-color: $gray-10;
    position: absolute;
    left: auto;
    height: 100%;
    width: 272rem;
    z-index: 99;
    pointer-events: auto;
*/;

const HEADER = "rx-page-segment-editor-segment-editor-filter-panel-2"/*
    @include text-body;
    display: flex;
    font-weight: 600;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-radius: 6rem;
    background-color: $gray-90;
    color: $gray-60;
    height: 56rem;
    display: flex;
    padding: 0 8rem;
    margin: 9rem;
*/;

const SUBHEAD = "rx-page-segment-editor-segment-editor-filter-panel-3"/*
    @include subhead;
    font-weight: 600;
    color: $gray-70;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16rem 8rem;
    height: 62rem;
    margin-right: 9rem;
    margin-left: 9rem;
*/;

const SCROLL_CONTAINER = "rx-page-segment-editor-segment-editor-filter-panel-4"/*
    height: calc(100% - 137rem); // account for other elements above
    padding-left: 9rem;
    padding-right: 10rem;
    overflow-y: auto;
    overflow-x: hidden;
    padding-bottom: 24rem;
*/;

type FilterPanelProps = {
    roadClassOptions: { label: string; value: string }[];
    setRoadClassFilterValue: any;
};

function FilterPanel({
    roadClassOptions,
    setRoadClassFilterValue
}: FilterPanelProps) {
    return (
        <div className={CONTAINER}>
            <div className={HEADER}>
                <FormattedMessage
                    key="segment-editor-filter-panel-header"
                    defaultMessage="Filters"
                />
            </div>
            <div className={SUBHEAD}>
                <FormattedMessage
                    key="road-class-filter-label"
                    defaultMessage="By Road Class"
                />
            </div>
            <div className={SCROLL_CONTAINER}>
                <MultiSelect
                    options={roadClassOptions}
                    onChange={selectedValues => {
                        setRoadClassFilterValue(
                            selectedValues.map(v => v.value)
                        );
                    }}
                />
            </div>
        </div>
    );
}

export default FilterPanel;
