import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from './layout';
import "/opt/build/repo/src/common/error-message.tsx?resplendence=true";
import Callout from './callout';
import { ServerError } from '@apollo/client';
import { NetworkError } from 'utils/use-data';

/*
@import 'style.scss';
*/;

type Props = {
    retry?: () => void;
    error?: Error;
    color?: 'red' | 'blue' | 'gray';
};

const ERROR_MESSAGE = "rx-common-error-message-1"/*
    display: grid;
    justify-items: center;
    align-items: center;
    grid-gap: 16rem;
    padding: 16rem;
    @include text-body;
*/;

type ErrorMessageTextProps = {
    error?: Error;
};

const isNetworkError = (error?: Error) =>
    // we want to catch errors that never reached the server in a cross-
    // browser way. The message and title is different on each browser, so
    // we instead check to see if any error code was set by the server. If
    // not, we can safely assume that the connection is interrupted.
    error instanceof NetworkError && !(error.error as ServerError).statusCode;

/**
 * A formatted string for a given error, default to a generic "something went wrong"
 * message.
 */
function ErrorMessageText({ error }: ErrorMessageTextProps) {
    return isNetworkError(error) ? (
        <FormattedMessage
            key="network-failed-error"
            defaultMessage="<title>The network connection was interrupted.</title> Please check your internet connection and try again."
            values={{ title: msg => <strong>{msg}</strong> }}
        />
    ) : (
        <FormattedMessage
            key="default-error"
            defaultMessage="<title>Something went wrong.</title> Please try again or contact us at support@ridereport.com."
            values={{ title: msg => <strong>{msg}</strong> }}
        />
    );
}

/**
 * A stateless error message, for rendering all our errors similarly.
 *
 * @param props
 * @param props.message - The message to the display to the user. Defaults to a generic message.
 * @param props.retry - A callback to retry whatever failed. If you don't pass anything, no retry button is rendered.
 */
function ErrorMessage({ error, retry, color = 'blue' }: Props) {
    const [retrying, setRetrying] = useState(false);
    return (
        <div role={retry ? 'alertdialog' : 'alert'} className={ERROR_MESSAGE}>
            <ErrorMessageText error={error} />
            {retry && (
                <Button
                    onClick={() => {
                        setRetrying(true);
                        retry();
                    }}
                    color={color}
                    disabled={retrying}
                >
                    <FormattedMessage
                        key="retry-button"
                        defaultMessage="Retry"
                    />
                </Button>
            )}
        </div>
    );
}

type ErrorCalloutProps = {
    children?: React.ReactNode;
    error?: Error;
    onDismiss?: () => void;
};

export function ErrorCallout({
    children,
    onDismiss,
    error
}: ErrorCalloutProps) {
    return (
        <Callout color="red" dismiss={onDismiss} role="alert">
            {children ?? <ErrorMessageText error={error} />}
        </Callout>
    );
}

const SMALL_ERROR_MESSAGE = "rx-common-error-message-2"/*
    color: $red-50;
    @include text-label;
    title {
        display: inline;
        font-size: 1em;
        font-weight: bold;
    }
*/;

type SmallErrorMessageProps = {
    children?: React.ReactNode;
    error?: Error;
};
export function SmallErrorMessage({ children, error }: SmallErrorMessageProps) {
    return (
        <div className={SMALL_ERROR_MESSAGE} role="alert">
            {children ?? <ErrorMessageText error={error} />}
        </div>
    );
}

export default ErrorMessage;
