import Icon from 'common/icon';
import { AnalyzeData, ChartMetric, TRIP_HISTOGRAMS } from './types';
import React from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import "/opt/build/repo/src/page-analyze/metric-chart.tsx?resplendence=true";
import useTippy from 'utils/use-tippy';
import BarChart from './bar-chart';
import Legend from './metric-chart-legend';
import LineChart from './multi-line-graph';
import { Filter, formatChartLabel } from './analyze-filter';
import { DateRange } from 'common/date-picker';

const m = defineMessages({
    'preliminary-data-tooltip':
        "This chart includes preliminary data that may be missing events that haven't been reported yet. Data is finalized after three days."
});

/*
@import 'style.scss';
*/;

export const PLACEHOLDER = "rx-page-analyze-metric-chart-1"/*
    min-height: 212rem;
*/;

const PRELIMINARY_DATA_KEY = "rx-page-analyze-metric-chart-2"/*
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: flex-end;
    margin-top: 8rem;
    svg {
        color: $yellow-50;
        margin-right: 8rem;
        opacity: 0.4;
    }
*/;

const PRELIMINARY_DATA_TOOLTIP = "rx-page-analyze-metric-chart-3"/*
    @include tooltip;
    padding-bottom: 2rem;
*/;

const LEGEND_CONTAINER = "rx-page-analyze-metric-chart-4"/*
    display:flex;
    justify-content: space-between;
    align-items: flex-start;
*/;

export interface Chart {
    dateRange: DateRange;
    data: AnalyzeData | null;
    filter: Filter;
    metric: ChartMetric;
}

type Props = {
    chart: Chart;
    extraChart?: Chart | null;
    hasPreliminaryData?: boolean;
    preliminaryDates?: Set<string>;
};

export type LineStyle = 'solid' | 'dashed';

export const NEEDS_OPERATOR_SELECTED = [
    'max_available',
    'mean_available',
    'trips_per_mean_available_vehicle',
    'mean_active',
    'trips_per_mean_active_vehicle',
    'average_vehicle_idle_time'
] as ChartMetric[];

export const AVERAGE_AGGREGATE_COUNTS = [
    'trips_per_mean_available_vehicle',
    'trips_per_mean_active_vehicle',
    'total_trip_distance_by_day'
] as ChartMetric[];

function MetricChart({
    chart,
    extraChart,
    hasPreliminaryData,
    preliminaryDates
}: Props) {
    const intl = useIntl();
    // line charts are aggregated by date, histograms by pre-defined buckets.
    const isLineChart = !TRIP_HISTOGRAMS.includes(chart.metric);
    const wasLineChart = chart.data?.xUnit === 'date';

    // Lines for all operators use our distinct data colors, but lines for
    // specific operators always use that operator's color. What this means is
    // that if multiple lines share the same operator, we need to change their
    // "style" instead, ie using a dashed line instead of a solid one.
    /** each data line with its associated style */
    const styledLines: { style: LineStyle; data: AnalyzeData | null }[] = [];
    /** how many times we've already seen a line for each operator */
    const seenOperators: Record<string, number> = {};

    for (const analyzeChart of [chart, extraChart]) {
        if (analyzeChart) {
            const { filter } = analyzeChart;
            if (filter.operator) {
                if (filter.operator.name in seenOperators) {
                    // if we've already added a line for this operator, use a
                    // dashed line instead.
                    styledLines.push({
                        style: 'dashed',
                        data: analyzeChart.data
                    });
                    seenOperators[filter.operator.name]++;
                } else {
                    styledLines.push({
                        style: 'solid',
                        data: analyzeChart.data
                    });
                    seenOperators[filter.operator.name] = 1;
                }
            } else {
                styledLines.push({ style: 'solid', data: analyzeChart.data });
            }
        }
    }

    const [preliminaryTooltipRef] = useTippy<HTMLDivElement>(
        intl.formatMessage(m['preliminary-data-tooltip']),
        { placement: 'top' }
    );

    return (
        <>
            {hasPreliminaryData && isLineChart && (
                <div className={PRELIMINARY_DATA_KEY}>
                    <Icon icon="Circle" />
                    <div
                        className={PRELIMINARY_DATA_TOOLTIP}
                        ref={preliminaryTooltipRef}
                    >
                        <FormattedMessage
                            key="preliminary-data-key"
                            defaultMessage="Preliminary data"
                        />
                    </div>
                </div>
            )}
            {/*  Some metrics are not available for the "All Operator filter, so we use a placeholder.*/}
            {chart.filter.error !== 'no-operator-selected' &&
            chart.data &&
            wasLineChart === isLineChart ? (
                isLineChart ? (
                    <LineChart
                        data={
                            extraChart?.data
                                ? [chart.data, extraChart.data]
                                : [chart.data]
                        }
                        seriesNames={
                            extraChart?.data
                                ? [
                                      chart.data.dataLine.group,
                                      extraChart.data.dataLine.group
                                  ]
                                : [chart.data.dataLine.group]
                        }
                        preliminaryDates={preliminaryDates}
                    />
                ) : (
                    <BarChart analyzeData={chart.data} />
                )
            ) : (
                <div className={PLACEHOLDER} />
            )}

            <div className={LEGEND_CONTAINER}>
                {styledLines.map(({ data, style }, index) => {
                    //switch on index for correct metric details
                    const analyzeChart = index === 0 ? chart : extraChart;
                    if (!analyzeChart) return null;

                    return (
                        <Legend
                            analyzeData={data}
                            metric={analyzeChart.metric}
                            operator={analyzeChart.filter.operator?.name}
                            key={index}
                            style={style}
                            index={index}
                            label={formatChartLabel(
                                intl,
                                analyzeChart.metric,
                                analyzeChart.filter
                            )}
                            error={analyzeChart.filter.error}
                            showLine={isLineChart}
                        />
                    );
                })}
            </div>
        </>
    );
}

export default MetricChart;
