import React from 'react';

import "/opt/build/repo/src/page-explore/common/total-count.tsx?resplendence=true";
import cx from 'classnames';
import { useCountUp } from 'react-countup';
import { useIntl } from 'utils/use-intl';

/*
@import 'style';
*/;

const COUNT_VALUE = "rx-page-explore-common-total-count-1"/*
    @include headline-4;
    color: $white;
    line-height: 26rem;
*/;

const TOTAL_COUNT = "rx-page-explore-common-total-count-2"/*
    border-radius: 0 0 6rem 6rem;
    margin: 0 -16rem -16rem -16rem;
    display: grid;
    grid-template-columns: 1fr auto;
    padding: 16rem;
    background-color: $gray-90;
    @include text-body;
    color: $gray-60;
    overflow: hidden;
    height: 56rem;
    &.mobile {
        border-radius: 6rem 6rem 0 0;
        background-color: $gray-90;
        margin: 0;
    }
    &.stand-alone {
        border-radius: 6rem;
    }
*/;

interface Props {
    isMobile?: boolean;
    isStandAlone?: boolean;
    count: number | undefined;
    children: React.ReactNode;
}
const TotalCount = ({ isMobile, isStandAlone, count, children }: Props) => {
    const intl = useIntl();

    const { countUp, update } = useCountUp({
        end: 0,
        duration: 0.3
    });

    update(count);

    return (
        <div
            className={cx(TOTAL_COUNT, {
                mobile: isMobile,
                'stand-alone': isStandAlone
            })}
        >
            <div className={COUNT_VALUE}>
                {countUp === '0' ? '-' : intl.formatNumber(Number(countUp))}
            </div>
            {children}
        </div>
    );
};

export default TotalCount;
