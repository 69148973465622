import React from 'react';
import {
    FormattedMessage,
    FormattedDate,
    defineMessages,
    useIntl
} from 'react-intl';
import { LocalDate } from 'utils/date-tools';
import { Link } from 'router';
import Currency from 'common/currency';
import "/opt/build/repo/src/page-fees/page-list-invoice-row.tsx?resplendence=true";
import useTippy from 'utils/use-tippy';
import { InvoiceListData } from 'graphql.g';

/*
@import 'style.scss';

@mixin invoice-alert {
    span {
        @include text-body-tooltip
    }
}
*/;

const OPERATOR = "rx-page-fees-page-list-invoice-row-1"/*
    font-weight: bold;
*/;

const DISABLED_LINK = "rx-page-fees-page-list-invoice-row-2"/*
    @include text-body-link-disabled;
*/;

const PROCESSING = "rx-page-fees-page-list-invoice-row-3"/*
    @include invoice-alert;
    color: $blue-50;
*/;

const FAILED = "rx-page-fees-page-list-invoice-row-4"/*
    @include invoice-alert;
    color: $red-50;
*/;

const messages = defineMessages({
    'invoice-status-processing-tooltip':
        'This invoice is still processing and is scheduled to be issued on {expectedDate, date, reportday}.',
    'invoice-status-processing-tooltip-unknown':
        'This invoice is still processing and has not yet been issued.',
    'invoice-status-unavailable-unknown':
        'We were unable to calculate the fees for this period. Please contact us for support.'
});

interface ProcessingProps {
    expectedIssueDate: null | string;
}

function Processing({ expectedIssueDate }: ProcessingProps) {
    const intl = useIntl();
    const [statusRef] = useTippy<HTMLSpanElement>(
        intl.formatMessage(
            expectedIssueDate
                ? messages['invoice-status-processing-tooltip']
                : messages['invoice-status-processing-tooltip-unknown'],
            {
                expectedDate:
                    expectedIssueDate &&
                    LocalDate.fromDateString(expectedIssueDate).toDate()
            }
        ),
        {}
    );
    return (
        <td className={PROCESSING}>
            <span ref={statusRef}>
                <FormattedMessage
                    key="invoice-status-processing"
                    defaultMessage="Processing"
                />
            </span>
        </td>
    );
}

function Unavailable() {
    const intl = useIntl();
    const [statusRef] = useTippy<HTMLSpanElement>(
        intl.formatMessage(messages['invoice-status-unavailable-unknown']),
        {}
    );
    return (
        <td className={FAILED}>
            <span ref={statusRef}>
                <FormattedMessage
                    key="invoice-status-unavailable"
                    defaultMessage="Unavailable"
                />
            </span>
        </td>
    );
}

type Invoice = NonNullable<InvoiceListData['mapView']>['invoices'][0];

interface InvoiceRowProps {
    invoice: Invoice;
}

/** A row representing a single invoice, in one of several possible states */
function InvoiceRow({ invoice }: InvoiceRowProps) {
    const date = (
        <FormattedDate
            value={LocalDate.fromDateString(invoice.startDate).toDate()}
            format="reportmonth"
        />
    );
    return (
        <tr>
            <td>
                <Link
                    disabled={invoice.__typename !== 'IssuedInvoice'}
                    disabledClassName={DISABLED_LINK}
                    to={`${invoice.operator.slug}/${invoice.startDate}`}
                >
                    {date}
                </Link>
            </td>
            <td className={OPERATOR}>{invoice.operator.name}</td>
            {invoice.__typename === 'IssuedInvoice' ? (
                <td>
                    <Currency value={invoice.total} />
                </td>
            ) : invoice.__typename === 'PendingInvoice' ? (
                <Processing expectedIssueDate={invoice.expectedIssueDate} />
            ) : (
                <Unavailable />
            )}
        </tr>
    );
}

export default InvoiceRow;
