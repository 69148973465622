import React, { useRef, useEffect, useState, useCallback } from 'react';
import Hammer from 'hammerjs';
import "/opt/build/repo/src/common/hour-range-slider.tsx?resplendence=true";
import cx from 'classnames';
import { useIntl } from 'utils/use-intl';
import { range, constrain } from 'utils/helpers';
import { Button } from './layout';
import { Select } from 'common/select';
import { MultiHourRangeActions } from './multi-hour-range-slider';

/*
@import 'style.scss';
*/;

const HOUR_RANGE_SLIDER = "rx-common-hour-range-slider-1"/*
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 4rem;
    max-width: 248rem;
*/;

const SLIDER = "rx-common-hour-range-slider-2"/*
    display: grid;
    grid-template-columns: repeat(25, 1fr);
    align-items: center;
    * {
        grid-row: 1;
    }
*/;

const SLIDER_BACKGROUND = "rx-common-hour-range-slider-3"/*
    grid-column: 1 / -1;
    height: 8rem;
    background-color: $gray-30;
    border-radius: 6rem;
*/;

const SLIDER_HIGHLIGHT = "rx-common-hour-range-slider-4"/*
    height: 8rem;
    background-color: $blue-10;
    margin: 0 -5rem 0 5rem;
*/;

const SLIDER_HANDLE = "rx-common-hour-range-slider-5"/*
    justify-self: start;
    width: 10rem;
    height: 16rem;
    border-radius: 3rem;
    background-color: $blue-50;
    .end {
        justify-self: end;
    }
    &:hover {
        box-shadow: 0rem 0rem 4rem $blue-50;
    }
*/;

const LABELS = "rx-common-hour-range-slider-6"/*
    @include text-caption;
    color: $gray-70;
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: auto;
    justify-items: center;
    justify-content: space-between;
*/;

const HOUR_SELECTS = "rx-common-hour-range-slider-7"/*
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    align-items: center;
    &.remove_button {
        grid-template-columns: 1fr auto 1fr auto;
    }
    max-width: 248rem;
*/;

const DELETE_BUTTON = "rx-common-hour-range-slider-8"/*
    @include button;
    margin-left: 8rem;
    width: 36rem;
    height: 36rem;
    position: relative;
    display: flex;
    align-items: center;
    svg {
        font-size: 28rem;
        &:last-child {
            margin: 2rem -6rem;
        }
    }
*/;

const HOUR_RANGE_DASH = "rx-common-hour-range-slider-9"/*
    width: 48rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $gray-70;
*/;

/**
 * Attaches a Hammer to the returned ref, set up to only listen to horizontal
 * panning.
 */
function useHandle() {
    const ref = useRef<HTMLDivElement>(null);
    const [hammer, setHammer] = useState<HammerManager | null>(null);

    useEffect(() => {
        if (ref.current) {
            setHammer(
                new Hammer(ref.current, {
                    recognizers: [
                        [Hammer.Pan, { direction: Hammer.DIRECTION_HORIZONTAL }]
                    ]
                })
            );
        }
    }, []);
    return { hammer, ref };
}

/**
 * Drag interactions always involve a little bit of manual pixel math.
 * Here, we take the container element for the hour slider, and a hammer
 * event, and use that to calculate what hour they have dragged to.
 *
 * This expects the consumer to do any constraining of possible values, so it
 * could easily return a nonsense hour like "-5" or "64".
 */
function getHourForHandlePosition(
    slider: React.RefObject<HTMLDivElement>,
    e: HammerInput
) {
    if (slider.current) {
        const { left, width } = slider.current.getBoundingClientRect();
        const dragPositionRelativeToSlider = e.center.x - left;
        return Math.floor((25 * dragPositionRelativeToSlider) / width);
    }
    return null;
}

interface HourRangeSliderProps {
    hourRange: [number, number];
    setHourRange?: (value: [number, number]) => void;
    setMultiHour?: React.Dispatch<React.SetStateAction<MultiHourRangeActions>>;
    id?: number;
    removeHourRange?: React.Dispatch<
        React.SetStateAction<MultiHourRangeActions>
    >;
}

/**
 * A component for selecting a range of hours across a day, with select fields
 * and draggable handles for two modes of visualization/interaction.
 *
 * Hour ranges are an array of two numbers like [2, 5]. The first is the start
 * hour and the second is the end hour. The end hour is exclusive, meaning a
 * range of [3, 4] is a 1-hour-long range.
 *
 * @param props
 * @param props.hourRange – the current range.
 * @param props.setHourRange callback to set the range.
 */
function HourRangeSlider({
    hourRange,
    setHourRange,
    setMultiHour,
    id,
    removeHourRange
}: HourRangeSliderProps) {
    const intl = useIntl();
    const startHandle = useHandle();
    const endHandle = useHandle();
    const [tempHourRange, setTempHourRange] = useState<[number, number] | null>(
        hourRange
    );

    const sliderRef = useRef<HTMLDivElement>(null);

    const rangeToShow = tempHourRange ?? hourRange;

    const updateHourRange = useCallback(
        (newHour: [number, number]) => {
            setTempHourRange(newHour);
            !setMultiHour && setHourRange
                ? setHourRange(newHour)
                : // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  setMultiHour!({
                      type: 'setHourRange',
                      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                      position: id!,
                      hours: newHour
                  });
        },
        [id, setHourRange, setMultiHour]
    );

    useEffect(() => {
        if (startHandle.hammer) {
            const h = startHandle.hammer;
            const handler: HammerListener = e => {
                const hour = constrain(
                    0,
                    hourRange[1] - 1,
                    getHourForHandlePosition(sliderRef, e) ?? hourRange[0]
                );

                if (e.isFinal) {
                    updateHourRange([hour, hourRange[1]]);
                    setTempHourRange(null);
                } else {
                    setTempHourRange([hour, hourRange[1]]);
                }
            };
            h.on('pan', handler);
            return () => h.off('pan', handler);
        }
    }, [hourRange, updateHourRange, startHandle.hammer]);
    useEffect(() => {
        if (endHandle.hammer) {
            const h = endHandle.hammer;
            const handler: HammerListener = e => {
                const hour = constrain(
                    hourRange[0] + 1,
                    24,
                    getHourForHandlePosition(sliderRef, e) ?? hourRange[1]
                );

                if (e.isFinal) {
                    updateHourRange([hourRange[0], hour]);
                    setTempHourRange(null);
                } else {
                    setTempHourRange([hourRange[0], hour]);
                }
            };
            h.on('pan', handler);
            return () => h.off('pan', handler);
        }
    }, [hourRange, updateHourRange, endHandle.hammer]);

    return (
        <>
            <div
                className={cx(HOUR_SELECTS, {
                    remove_button: !!removeHourRange
                })}
            >
                <Select
                    options={range(0, 24).map(i => ({
                        value: i,
                        label: intl.formatTime(
                            new Date().setHours(i, 0, 0, 0),
                            {
                                hour: 'numeric'
                            }
                        )
                    }))}
                    value={{
                        value: rangeToShow[0],
                        label: intl.formatTime(
                            new Date().setHours(rangeToShow[0], 0, 0, 0),
                            {
                                hour: 'numeric'
                            }
                        )
                    }}
                    isOptionDisabled={h => rangeToShow[1] <= h.value}
                    onChange={option =>
                        updateHourRange([option?.value ?? 0, hourRange[1]])
                    }
                />
                <div className={HOUR_RANGE_DASH}>—</div>
                <Select
                    options={range(1, 25).map(i => ({
                        value: i,
                        label: intl.formatTime(
                            new Date().setHours(i, 0, 0, 0),
                            {
                                hour: 'numeric'
                            }
                        )
                    }))}
                    value={{
                        value: rangeToShow[1],
                        label: intl.formatTime(
                            new Date().setHours(rangeToShow[1], 0, 0, 0),
                            {
                                hour: 'numeric'
                            }
                        )
                    }}
                    isOptionDisabled={h => rangeToShow[0] >= h.value}
                    onChange={option =>
                        updateHourRange([hourRange[0], option?.value ?? 24])
                    }
                />
                {removeHourRange && (
                    <Button
                        className={DELETE_BUTTON}
                        color="red"
                        center={true}
                        icon="Close"
                        onClick={() =>
                            removeHourRange({
                                type: 'remove',
                                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                position: id!
                            })
                        }
                    />
                )}
            </div>
            <div className={HOUR_RANGE_SLIDER}>
                <div className={SLIDER} ref={sliderRef}>
                    {/* The "ticks" on the slider are defined by a css grid
                    split into 25 segments (midnight to midnight, inclusive).
                    We can then position the handles dynamically
                    by setting the grid-column property.
                    Note that css grid is 1-indexed while our hours are 0-
                    indexed, hence the +1's below. */}
                    <div className={SLIDER_BACKGROUND} />
                    <div
                        className={SLIDER_HIGHLIGHT}
                        style={{
                            gridColumn: `${rangeToShow[0] +
                                1} / ${rangeToShow[1] + 1}`
                        }}
                    />
                    <div
                        className={SLIDER_HANDLE}
                        style={{ gridColumn: rangeToShow[0] + 1 }}
                        ref={startHandle.ref}
                    />
                    <div
                        className={cx(SLIDER_HANDLE, 'end')}
                        style={{ gridColumn: rangeToShow[1] + 1 }}
                        ref={endHandle.ref}
                    />
                </div>
                <div className={LABELS}>
                    {[0, 6, 12, 18, 24].map(hour => (
                        <div key={hour}>
                            {intl.formatTime(
                                new Date().setHours(hour, 0, 0, 0),
                                {
                                    hour: 'numeric'
                                }
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
}

export default HourRangeSlider;
