import React from 'react';
import { FormattedMessage } from 'react-intl';
import MapCallout from 'page-explore/common/map-callout';
import useCachedState from 'utils/use-cached-state';
import LOCAL_STORAGE_KEYS from 'constants/local-storage';

const RoutesPrivacyCallout = () => {
    const [dismissed, setDismissed] = useCachedState(
        LOCAL_STORAGE_KEYS.routesPrivacyCalloutDismissed,
        false
    );

    return (
        <MapCallout
            color="blue"
            shadow
            isDismissed={dismissed}
            dismiss={() => setDismissed(true)}
        >
            <FormattedMessage
                key="routes-privacy-message"
                defaultMessage="Seeing low numbers? Ride Report filters out segments with very few trips. Try expanding the date and hour range to see more."
            />
        </MapCallout>
    );
};

export default RoutesPrivacyCallout;
