import React from 'react';
import "/opt/build/repo/src/page-explore/common/map-callout.tsx?resplendence=true";
import { CSSTransition } from 'react-transition-group';
import Callout from 'common/callout';

/*
@import 'style.scss';
*/;

type Props = {
    dismiss?: () => void;
    children: React.ReactNode;
    color: 'gray' | 'blue' | 'red';
    shadow?: boolean;
    isDismissed?: boolean;
};

const WRAPPER = "rx-page-explore-common-map-callout-1"/*
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding-bottom: 48rem;
    transition: 300ms all;
    > * {
        transition: 300ms all;
        transform: scale(1);
        max-width: 800rem;
    }
    pointer-events: none;
    &.exit {
        opacity: 1;
    }
    &.enter, &.appear {
        transition-delay: 500ms;
        opacity: 0;
        > * {
            transform: scale(0.9);
        }
    }
    &.enter-active, &.exit, &.appear-active {
        opacity: 1;
    }
    &.enter, &.exit-active, &.appear {
        opacity: 0;
        > * {
            transform: scale(0.9);
        }
    }
*/;

function MapCallout({ dismiss, children, color, shadow, isDismissed }: Props) {
    return (
        <CSSTransition
            in={!isDismissed}
            timeout={300}
            mountOnEnter
            unmountOnExit
            appear={true}
        >
            <div className={WRAPPER}>
                <Callout dismiss={dismiss} color={color} shadow={shadow}>
                    {children}
                </Callout>
            </div>
        </CSSTransition>
    );
}

export default MapCallout;
