const INTENSITY_THRESHOLD = 186;

/** Returns a text color that has appropriate contrast with the hex color given  */
function textColorForBackgroundColor(bgColor: string) {
    // this is taken from
    // https://stackoverflow.com/a/3943023/3711733
    const color = bgColor.charAt(0) === '#' ? bgColor.substring(1, 7) : bgColor;
    const r = parseInt(color.substring(0, 2), 16); // hexToR
    const g = parseInt(color.substring(2, 4), 16); // hexToG
    const b = parseInt(color.substring(4, 6), 16); // hexToB
    return r * 0.299 + g * 0.587 + b * 0.114 > INTENSITY_THRESHOLD
        ? 'black'
        : 'white';
}

export default textColorForBackgroundColor;
