import { useState, useEffect, useCallback } from 'react';
import {
    ApolloError,
    useQuery,
    useApolloClient,
    QueryHookOptions
} from '@apollo/client';
import { DocumentNode } from 'graphql';

type Variables = { [key: string]: any };

export class NetworkError extends Error {
    error: NonNullable<ApolloError['networkError']>;

    constructor(error: NonNullable<ApolloError['networkError']>) {
        super(error?.message);
        this.error = error;
    }
    toString() {
        return this.error.message;
    }
}

export class FetchError extends Error {
    gqlErrors: ApolloError['graphQLErrors'] = [];
    apolloMessage: string;
    variables: Variables | undefined;
    constructor(error: ApolloError, name: string, variables?: Variables) {
        super(`Error loading query ${name}. ${error.message}`);
        this.gqlErrors = error.graphQLErrors;
        this.apolloMessage = error.message;
        this.variables = variables;
    }
    toString() {
        return [this.message, JSON.stringify(this.gqlErrors, null, 2)].join(
            '\n'
        );
    }
}

export class CacheError extends Error {}

type FetchResult<T> = [
    T | null,
    boolean,
    FetchError | ApolloError['networkError'] | CacheError | null
];

/**
 * Given a query, returns a function you can call imperatively to fetch the
 * data. Generally you'll want to use Data or ReloadingData for our actual data
 * fetching (see below), but this can be useful for prefetching a query when the
 * user hovers over a link or button, for example.
 */
export const useQueryFn = <T, V extends object = object>(query: any) => {
    const client = useApolloClient();
    return useCallback(
        (variables: V) => client.query<T, V>({ query, variables }),
        [client, query]
    );
};

/**
 * Lower-level hook for fetching API data. Use this to grab data from graphql.
 * It returns null while loading, so you'll need to handle the loading states yourself.
 *
 * Fetch errors are returned so they can be handled specifically by the function.
 * If you don't want to think about handling errors, useData is what you want.
 */

export const useGraphql = <T, V = object>(
    query: DocumentNode,
    variables?: V | 'skip',
    options?: QueryHookOptions
): FetchResult<T> => {
    const [savedResult, setSavedResult] = useState<T | null>(null);
    const { data, loading, error } = useQuery<T>(
        query,
        variables === 'skip'
            ? { skip: true }
            : {
                  ...options,
                  variables
              }
    );

    // So sometimes apollo does this thing where the cache gets messed up and
    // a previously successful result turns into an empty data object
    // This is a workaround that stores successes into the state and ignores those
    // empty objects if they show up
    useEffect(() => {
        if (data && Object.keys(data).length) {
            setSavedResult(data);
        }
    }, [data]);

    const result = data && Object.keys(data).length ? data : savedResult;

    if (error) {
        return [
            result,
            loading,
            error.networkError
                ? new NetworkError(error.networkError)
                : new FetchError(
                      error,
                      (query.definitions[0] as any).name.value,
                      variables === 'skip' ? undefined : variables
                  )
        ];
    }

    if (!savedResult && !loading && data && !Object.keys(data).length) {
        return [
            result,
            loading,
            new CacheError(
                `Cache error with query ${
                    (query.definitions[0] as any).name.value
                }\n`
            )
        ];
    }
    return [result, loading, null];
};

/**
 * For when you need to fetch data from our graphql API and want to know if the
 * result is currently loading.
 */
export const useReloadingData = <T, V extends Variables = object>(
    query: DocumentNode,
    variables?: V | 'skip',
    options?: QueryHookOptions
): [T | null, boolean] => {
    const [result, loading, error] = useGraphql<T, V>(
        query,
        variables,
        options
    );
    if (error) throw error;
    return [result, loading];
};

/**
 * Our standard way of fetching API data. Use this to grab data from graphql.
 * It returns null while loading, so you'll need to handle the loading states yourself.
 * Fetch errors will bubble up to closest error boundary. Maybe make another one close by if it makes sense!
 */
const useData = <T, V extends Variables = object>(
    query: DocumentNode,
    variables?: V | 'skip',
    options?: QueryHookOptions
) => {
    const [result, , error] = useGraphql<T, V>(query, variables, options);
    if (variables !== 'skip' && error) throw error;
    return result;
};

export default useData;
