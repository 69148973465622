import React from 'react';
import cx from 'classnames';
import "/opt/build/repo/src/page-policy/common-page.tsx?resplendence=true";
import { Link } from 'router';
import { PageContent, PageBody } from 'common/layout';
import { Title } from 'common/title';
import { FormattedMessage, useIntl, defineMessages } from 'react-intl';
import { FeatureFlaggedFeature } from 'graphql.g';
import { useEnabledFeatures } from 'common/use-feature-flags';
import { useUser } from 'common/user';
import useDocumentTitle from 'common/use-document-title';
import Icon from 'common/icon';
import { Tabs, TAB_LINK } from 'common/tabs';

/*
    @import 'style';
*/;

export const NEW_LINK = "rx-page-policy-common-page-1"/*
    @include button;
    display: flex;
    flex-direction: row;
    justify-self: start;
    align-items: center;
    svg:first-child {
        font-size: 24rem;
        margin-right: 8rem;
    }
*/;

const NAV_ROW = "rx-page-policy-common-page-2"/*
    display: flex;
    align-items: center;
    justify-content: space-between;

    > *:first-child {
        flex-grow: 1;
        margin-right: 16rem;
    }
*/;

const m = defineMessages({
    'document-title': 'Policy · Ride Report'
});

function PolicyCommonPage({ children }) {
    const { useDemoFeatures, orgAuth } = useUser();
    const enabledFeatures = useEnabledFeatures();
    const intl = useIntl();
    useDocumentTitle(intl.formatMessage(m[`document-title`]));

    const canCreatePolicies =
        orgAuth.organization.__typename === 'AgencyOrganization' &&
        (enabledFeatures.includes(FeatureFlaggedFeature.self_serve_policies) ||
            useDemoFeatures);

    return (
        <PageContent wide>
            <Title
                title={<FormattedMessage key="title" defaultMessage="Policy" />}
            />
            <div className={NAV_ROW}>
                <Tabs>
                    <Link className={TAB_LINK} nav to="../dashboard">
                        <FormattedMessage
                            key="tab-metrics"
                            defaultMessage="Metrics"
                        />
                    </Link>
                    <Link
                        className={TAB_LINK}
                        nav
                        to="../active"
                        state={'active'}
                    >
                        <FormattedMessage
                            key="tab-active-policies"
                            defaultMessage="Active Policies"
                        />
                    </Link>
                    <Link
                        className={TAB_LINK}
                        nav
                        to="../ended"
                        state={'ended'}
                    >
                        <FormattedMessage
                            key="tab-ended-policies"
                            defaultMessage="Ended Policies"
                        />
                    </Link>
                </Tabs>
                {canCreatePolicies ? (
                    <Link
                        to={`../form/new/select`}
                        className={cx(NEW_LINK, 'blue')}
                    >
                        <Icon icon="Plus" />
                        <FormattedMessage
                            key="new-button"
                            defaultMessage="New Policy"
                        />
                    </Link>
                ) : null}
            </div>
            <PageBody>{children}</PageBody>
        </PageContent>
    );
}

export default PolicyCommonPage;
