import React from 'react';
import "/opt/build/repo/src/root/page-terms.tsx?resplendence=true";
import { Switch, Link } from 'router';
import Eula from './page-eula';
import Privacy from './page-privacy';
import { PageContent, PageColumns } from 'common/layout';
import Icon from 'common/icon';
import LoggedOutHeader from 'common/logged-out-header';

/*
@import 'style.scss';
*/;

const LINK = "rx-root-page-terms-1"/*
    @include navigation-link;
    transition: all 150ms;
    border-radius: 4rem;
    height: 36rem;
    padding: 0 6rem;
    display: grid;
    grid: 1fr / auto auto;
    grid-gap: 8rem;
    align-items: center;
    justify-content: start;
    svg {
        color: $gray-60;
    }
    &.active svg {
        color: $white;
    }
*/;

const ICON = "rx-root-page-terms-2"/*
    font-size: 24rem;
*/;

const NAV = "rx-root-page-terms-3"/*
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-flow: row;
    grid-gap: 12rem;
    background-color: $white;
    height: max-content;
    padding: 24rem;
    border-radius: 4rem;

    @include mobile {
        grid-row: 1;
    }
*/;

const ARTICLE = "rx-root-page-terms-4"/*
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    height: 100%;
    width: 100%;
    background-color: $white;
    border-radius: 4rem;
*/;

/**
 * Homepage for all terms and conditions documents.
 */
function TermsPage() {
    return (
        <>
            <LoggedOutHeader />
            <PageContent>
                <PageColumns>
                    <article className={ARTICLE}>
                        <Switch
                            pages={[
                                {
                                    path: '/eula',
                                    component: Eula,
                                    exact: true
                                },
                                {
                                    path: '/privacy',
                                    component: Privacy,
                                    exact: true
                                },
                                {
                                    // send any incorrect or root urls to EULA
                                    path: '/',
                                    redirectTo: '/eula'
                                }
                            ]}
                        />
                    </article>
                    <nav className={NAV}>
                        <Link to="/eula" nav className={LINK}>
                            <Icon icon="Document-Agreement" className={ICON} />
                            <span>EULA</span>
                        </Link>
                        <Link to="/privacy" nav className={LINK}>
                            <Icon icon="Document-Privacy" className={ICON} />
                            <span>Privacy</span>
                        </Link>
                    </nav>
                </PageColumns>
            </PageContent>
        </>
    );
}

export default TermsPage;
