import React, { useEffect } from 'react';
import "/opt/build/repo/src/page-report/report-picker-monthly.tsx?resplendence=true";

import { ParamLink } from 'router';
import { LocalDate, LocalMonth } from 'utils/date-tools';
import { MapView } from 'types';
import { FormattedDate } from 'react-intl';
import { CalendarNavBar, PreliminaryDataKey } from 'common/date-picker';
import {
    useFinalizedAggregatedReportsQuery,
    useFinalizedAggregatedReports
} from './report-picker-common';
import Loading from 'common/loading';
import Icon from 'common/icon';
import cx from 'classnames';
import { AggregatedReportPeriod } from 'graphql.g';

/*
@import "~common/date-picker-styles";
@import "style";
*/;

const CALENDAR_GRID = "rx-page-report-report-picker-monthly-1"/*
    display: grid;
    grid-template-columns: auto auto auto;
    width: 294rem;
    height: 252rem;
    grid-row-gap: 15rem;
    grid-column-gap: 23.33rem;
    padding: 7.5rem 11.67rem;
    position: relative;
    @include calendar;
*/;

const MONTH_LINK = "rx-page-report-report-picker-monthly-2"/*
    display: flex;
    align-items: center;
    justify-content: center;
    color: $gray-90;
    border-radius: 6rem;
    transition-property: color, background-color;
    transition-duration: 100ms;
    @include text-label-bold;
    &.preliminary {
        position: relative;
        &:after {
            right: 15rem;
            top: 10rem;
            z-index: 1;
            @include preliminary-dot;
        }
        &:hover, &.active {
            &:after {
                right: -4rem;
                top: -4rem;
            }
        }
    }
*/;

type Props = {
    mapView: MapView;
    selectedYear: number;
    goToNextYear: () => void;
    goToPrevYear: () => void;
};

function ReportPickerMonthly({
    mapView,
    selectedYear,
    goToNextYear,
    goToPrevYear
}: Props) {
    const query = useFinalizedAggregatedReportsQuery();

    useEffect(() => {
        query({
            slug: mapView.slug,
            period: AggregatedReportPeriod.monthly,
            startDate: new LocalMonth(selectedYear - 1, 1).first.toString(),
            endDate: new LocalMonth(selectedYear - 1, 12).last.toString()
        });
        query({
            slug: mapView.slug,
            period: AggregatedReportPeriod.monthly,
            startDate: new LocalMonth(selectedYear + 1, 1).first.toString(),
            endDate: new LocalMonth(selectedYear + 1, 12).last.toString()
        });
    }, [query, mapView.slug, selectedYear]);

    const [reportsByMonth, loading] = useFinalizedAggregatedReports(
        mapView,
        AggregatedReportPeriod.monthly,
        new LocalMonth(selectedYear, 1).first,
        new LocalMonth(selectedYear, 12).last
    );

    const thisMonth = LocalDate.todayInTz(mapView.ianaTimezone).toLocalMonth();

    return (
        <>
            <CalendarNavBar
                label={'' + selectedYear}
                onNextClick={goToNextYear}
                onPreviousClick={goToPrevYear}
            />
            <div className={CALENDAR_GRID}>
                <Loading loading={loading} kind="over-table" size={4} />
                {LocalMonth.listForYear(selectedYear).map(month => {
                    const dayStr = month.first.toString();
                    const reportExists = loading || dayStr in reportsByMonth;
                    const beforeThisMonth = month.isBefore(thisMonth);
                    return !reportExists && beforeThisMonth ? (
                        <div
                            key={month.month}
                            className={cx(MONTH_LINK, 'missing')}
                        >
                            <Icon icon="Missing-File" />
                        </div>
                    ) : (
                        <ParamLink
                            params={{
                                mode: 'monthly',
                                date: dayStr
                            }}
                            nav
                            key={month.month}
                            disabled={!reportExists}
                            className={cx(MONTH_LINK, {
                                preliminary: reportsByMonth[dayStr] === false
                            })}
                        >
                            <FormattedDate
                                value={month.first.toDate()}
                                month="short"
                            />
                        </ParamLink>
                    );
                })}
            </div>
            {Object.values(reportsByMonth).some(finalized => !finalized) && (
                <PreliminaryDataKey />
            )}
        </>
    );
}

export default ReportPickerMonthly;
