import React from 'react';
import "/opt/build/repo/src/common/compliance-score.tsx?resplendence=true";
import cx from 'classnames';
import { FormattedMessage } from 'react-intl';
import { PolicyMetricDataLineFragment } from 'graphql.g';

/*
@import '../style.scss';
*/;

const COMPLIANCE_ROW = "rx-common-compliance-score-1"/*
    display: flex;
    flex-direction: row;
    align-items: center;
*/;

const COMPLIANCE_SCORE = "rx-common-compliance-score-2"/*
    @include text-label-bold;
    padding-top: 6rem;
    padding-bottom: 6rem;
    width: 40rem;
    margin-right: 4rem;
    text-align: center;
    border-radius: 4rem;

    &.green {
        color: $green-70;
        background-color: $green-00;
    }
    &.red {
        color: $red-70;
        background-color: $red-00;
    }
*/;

const COMPLIANCE_INFO = "rx-common-compliance-score-3"/*
    @include text-caption;
    flex-grow: 1;
    color: $gray-70;
    margin-top: auto;
    margin-bottom: auto;

    &.hovered {
        color: $gray-90;
    }
*/;

type COMPLIANCE_PROPS = {
    summedData: PolicyMetricDataLineFragment | undefined;
    hover?: boolean;
};

export const ComplianceScore = ({
    summedData,
    hover = false
}: COMPLIANCE_PROPS) => {
    return (
        <div className={COMPLIANCE_ROW}>
            <div
                className={cx(
                    COMPLIANCE_SCORE,
                    summedData?.complianceIsAcceptable ? 'green' : 'red'
                )}
            >
                {summedData?.percentCompliance}%
            </div>
            <div className={cx(COMPLIANCE_INFO, hover && 'hovered')}>
                <FormattedMessage
                    key="days-in-compliance"
                    defaultMessage="{inCompliance} of {total} days"
                    values={{
                        inCompliance: summedData?.numDaysInCompliance,
                        total: summedData?.numDaysInPeriod
                    }}
                />
            </div>
        </div>
    );
};
