import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import "/opt/build/repo/src/page-policy/new-policy-form.tsx?resplendence=true";
import { useRouteMatch } from 'react-router';
import { defineMessages, useIntl, FormattedMessage } from 'react-intl';

import Icon from 'common/icon';
import { BUTTON, Container, ContainerHeader } from 'common/layout';
import { NewPolicy } from './page-new';
import FormAreaMap from './form-area-map';
import {
    AVAILABLE_POLICIES,
    AVAILABLE_POLICIES_MESSAGES
} from './policy-selector';
import BaseForm from './forms/form-base';
import VehicleCapForm from './forms/form-vehicle-cap';
import MaxIdleTimeForm from './forms/form-max-idle-time';
import TripStartForm from './forms/form-trip-start';
import NoParkingZoneForm from './forms/form-no-parking-zone';

import { PolicyType } from 'graphql.g';
import MinimumDeploymentForm from './forms/form-minimum-deployment';
import SpeedLimitForm from './forms/form-speed-limit';
import { timeLessThan } from './forms/common';
import useCreatePolicy from './use-create-policy';
import useEditPolicy, { PolicyFormMode } from './policy-edit';
import useTippy from 'utils/use-tippy';
import { Link } from 'router';
import Callout from 'common/callout';

/*
    @import 'style';
*/;

const ICON = "rx-page-policy-new-policy-form-1"/*
    margin-right: 1rem;
*/;

const GRID = "rx-page-policy-new-policy-form-2"/*
    display: grid;
    grid-template-columns: minmax(400rem, 1fr) minmax(400rem, 1fr);
    grid-gap: 16rem;
    @include mobile {
        grid-template-columns: 1fr 1fr;
    }

    > section {
        height: fit-content;
    }
*/;

const BUTTON_CONTAINER = "rx-page-policy-new-policy-form-3"/*
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
*/;

const INFO_CONTAINER = "rx-page-policy-new-policy-form-4"/*
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    align-content: center;
    justify-content: space-between;
*/;

const EDIT_DISCLAIMER = "rx-page-policy-new-policy-form-5"/*
    @include text-link-underlined;
    color: $blue-50;
    display: flex;
    justify-content: flex-end;
    margin: 24rem 0 0 0;
    > div {
        width: 208rem;
    }
*/;

const ERROR_CONTAINER = "rx-page-policy-new-policy-form-6"/*
    margin-bottom: 18px;
*/;

const m = defineMessages({
    'name-placeholder': 'Policy Name',
    'description-placeholder':
        'Provide context for this policy, or describe the intended outcome.',
    'create-policy': 'Create Policy',
    'update-policy': 'Update Policy',
    'publish-to-odp': 'Publish to Open Data Portal',
    'edit-disclaimer-tooltip':
        'You can only update selected fields of an existing policy to maintain accuracy of historically collected data.'
});

export interface FormErrors {
    area: boolean;
    startDate: boolean;
    maximum: boolean;
    minimum: boolean;
    rateAmount: boolean;
    providers: boolean;
    endTime: boolean;
}

export interface PolicyFormProps {
    policy: NewPolicy;
    setPolicy: (policy: NewPolicy) => void;
}

function NewPolicyForm({ policy, setPolicy }: PolicyFormProps) {
    const intl = useIntl();
    const [editDisclaimerRef] = useTippy<HTMLDivElement>(
        intl.formatMessage(m['edit-disclaimer-tooltip']) ?? null,
        {
            placement: 'right'
        }
    );
    const match = useRouteMatch<{
        policyType: string;
        policyId: string;
        mode: PolicyFormMode;
    }>();

    const [formErrors, setFormErrors] = useState<FormErrors>({
        area: false,
        startDate: false,
        maximum: false,
        minimum: false,
        rateAmount: false,
        providers: false,
        endTime: false
    });

    const formHasErrors: boolean = Object.values(formErrors).some(
        hasError => hasError
    );

    const [onSubmit, errorMessage] = useCreatePolicy(policy, formHasErrors);

    const onEditSubmit = useEditPolicy(
        policy,
        match.params.policyId,
        formHasErrors
    );

    const [selectedPolicy, setSelectedPolicy] = useState({
        name: 'New Policy',
        description: ''
    });

    //Verifies that the end time is after the start time.
    useEffect(() => {
        const endTimeBeforeStartTime =
            policy.startTime != null &&
            policy.endTime != null &&
            timeLessThan(policy.endTime, policy.startTime);

        if (formErrors.endTime !== endTimeBeforeStartTime) {
            setFormErrors({
                ...formErrors,
                endTime: endTimeBeforeStartTime
            });
        }
    }, [policy.startTime, policy.endTime, formErrors, setFormErrors]);

    // set the type of policy form based on the URL
    useEffect(() => {
        const policyType = AVAILABLE_POLICIES.find(
            p => p === match.params.policyType
        );

        if (policyType) {
            setSelectedPolicy({
                name: intl.formatMessage(
                    AVAILABLE_POLICIES_MESSAGES[`${policyType}-name`]
                ),
                description: intl.formatMessage(
                    AVAILABLE_POLICIES_MESSAGES[`${policyType}-description`]
                )
            });
            if (policy.policyType !== policyType) {
                setPolicy({ ...policy, policyType: policyType });
            }
        }
    }, [match, setSelectedPolicy, setPolicy, policy, intl]);

    const editMode = match.params.mode === 'edit';

    const formProps = {
        policy,
        setPolicy,
        formErrors,
        setFormErrors,
        editMode
    };

    return (
        <div>
            <div className={ERROR_CONTAINER}>
                {errorMessage != null && (
                    <Callout color="red">{errorMessage}</Callout>
                )}
            </div>

            <div className={GRID}>
                <form onSubmit={!editMode ? onSubmit : onEditSubmit}>
                    <Container>
                        <BaseForm {...formProps} />

                        {!editMode &&
                            policy.policyType === PolicyType.vehicle_cap && (
                                <VehicleCapForm
                                    {...formProps}
                                    isPercentBased={false}
                                />
                            )}

                        {!editMode &&
                            policy.policyType ===
                                PolicyType.vehicle_cap_percentage && (
                                <VehicleCapForm
                                    {...formProps}
                                    isPercentBased={true}
                                />
                            )}

                        {!editMode &&
                            policy.policyType === PolicyType.trip_start && (
                                <TripStartForm {...formProps} />
                            )}

                        {/* No parking and no ride zone policies are identical forms */}
                        {!editMode &&
                            (policy.policyType === PolicyType.no_parking_zone ||
                                policy.policyType ===
                                    PolicyType.no_ride_zone) && (
                                <NoParkingZoneForm {...formProps} />
                            )}

                        {!editMode &&
                            policy.policyType === PolicyType.max_idle_time && (
                                <MaxIdleTimeForm {...formProps} />
                            )}

                        {!editMode &&
                            policy.policyType ===
                                PolicyType.minimum_deployment && (
                                <MinimumDeploymentForm
                                    {...formProps}
                                    isPercentBased={false}
                                />
                            )}

                        {!editMode &&
                            policy.policyType ===
                                PolicyType.minimum_deployment_percentage && (
                                <MinimumDeploymentForm
                                    {...formProps}
                                    isPercentBased={true}
                                />
                            )}

                        {!editMode &&
                            policy.policyType === PolicyType.speed_limit && (
                                <SpeedLimitForm {...formProps} />
                            )}

                        {!editMode ? (
                            <div className={BUTTON_CONTAINER}>
                                <input
                                    type="submit"
                                    disabled={formHasErrors}
                                    className={cx(BUTTON, 'blue')}
                                    value={intl.formatMessage(
                                        m['create-policy']
                                    )}
                                />
                            </div>
                        ) : (
                            <>
                                <div className={BUTTON_CONTAINER}>
                                    <Link
                                        to={`../../../../${match.params.policyId}`}
                                        preserveQuery
                                        className={BUTTON}
                                    >
                                        <FormattedMessage
                                            key="cancel"
                                            defaultMessage="Cancel"
                                        />
                                    </Link>
                                    <input
                                        type="submit"
                                        disabled={formHasErrors}
                                        className={cx(BUTTON, 'blue')}
                                        value={intl.formatMessage(
                                            m['update-policy']
                                        )}
                                    />
                                </div>

                                <span className={EDIT_DISCLAIMER}>
                                    <div ref={editDisclaimerRef}>
                                        <FormattedMessage
                                            key={'edit-disclaimer'}
                                            defaultMessage={
                                                "Why can't I edit all fields?"
                                            }
                                        />
                                    </div>
                                </span>
                            </>
                        )}
                    </Container>
                </form>
                <div className={INFO_CONTAINER}>
                    <Container>
                        <ContainerHeader>
                            <Icon icon="Information" className={ICON} />
                            {selectedPolicy.name}
                        </ContainerHeader>
                        {selectedPolicy.description}
                    </Container>

                    <FormAreaMap area={policy.area} />
                </div>
            </div>
        </div>
    );
}

export default NewPolicyForm;
