import React from 'react';
import "/opt/build/repo/src/page-report/tables.tsx?resplendence=true";
import { Link } from '../router';
import useTippy from '../utils/use-tippy';
import cx from 'classnames';
import { useIntl } from 'utils/use-intl';
import {
    defineMessages,
    FormattedNumber,
    FormattedMessage,
    MessageDescriptor
} from 'react-intl';
import ErrorBoundary from 'common/error-boundary';
import Icon, { IconName } from 'common/icon';
import { range } from 'utils/helpers';
import Loading from 'common/loading';
import { ContainerMessage, LINK } from 'common/layout';
import { useAreas } from 'common/use-areas';
import { useMapView } from 'common/use-map-view';
import { useUser } from 'common/user';
import { TableSorting } from 'common/table-sorting';
import { HeaderInfo } from 'constants/stat-descriptions';

/*
@import 'style';
$breakpoint-1: 800rem;

@mixin table-section {
    background-color: $white;
    border: 1rem solid $gray-30;
    box-sizing: border-box;
    border-radius: 6rem;
    position: relative;
    break-inside: avoid;
}
*/;

export const TITLE = "rx-page-report-tables-1"/*
    @include headline-3;
    margin: 4rem 0 42rem 0;
    grid-column: 1 / -1;
*/;

export const TABLE_SECTION = "rx-page-report-tables-2"/*
    @include table-section;
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    flex: 1;
    @media print {
        display: block;
    }
    padding: 24rem;
*/;

export const CONSTRAINED_TABLE = "rx-page-report-tables-3"/*
    max-height: 440rem;
    overflow: auto;
*/;

export const TABLE_TITLE = "rx-page-report-tables-4"/*
    @include subhead;
    color: $gray-70;
    padding: 13rem 24rem 12rem 24rem;
    border-bottom: 1rem solid $gray-30;
    margin: -24rem -24rem 0 -24rem;
*/;

type TableSectionProps = {
    title: any;
    children: React.ReactNode;
};

export function TableSection({ title, children }: TableSectionProps) {
    return (
        <section className={TABLE_SECTION}>
            <h3 className={TABLE_TITLE}>{title}</h3>
            <ErrorBoundary>{children}</ErrorBoundary>
        </section>
    );
}

export const CALLOUT_GRID = "rx-page-report-tables-5"/*
    margin-bottom: -24rem;
    margin-left: -24rem;
    columns: 2;
    &.small {
        columns: 3;
    }
*/;

const CALLOUT_SECTION = "rx-page-report-tables-6"/*
    @include table-section;
    margin-bottom: 24rem;
    flex: 1 0 0;
    margin-left: 24rem;
    display: flex;
    position: relative;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: center;
    height: 150rem;
    padding: 24rem;
    &.small {
        height: 106rem;
        overflow: visible;
    }
    overflow: auto;
*/;

const CALLOUT_SECTION_VALUE = "rx-page-report-tables-7"/*
    text-align: center;
    box-sizing: border-box;
    transition: opacity 150ms;
    opacity: 1;
    @include headline-3;
    &.hide {
        opacity: 0;
    }
    &.small {
        @include headline-5;
    }
*/;
const CALLOUT_SECTION_TITLE = "rx-page-report-tables-8"/*
    @include text-body-link;
    color: $gray-70;
    &.small {
        @include text-label-link;
    }

    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
*/;
const CALLOUT_SECTION_TITLE_CONTENT = "rx-page-report-tables-9"/*
    &.tooltip {
        @include tooltip;
    }
*/;

const CALLOUT_SECTION_LABEL = "rx-page-report-tables-10"/*
    @include text-label;
    color: $gray-60;
    margin-top: -6rem;
    text-align: center;
*/;

const CALLOUT_SECTION_ERROR = "rx-page-report-tables-11"/*
    @include text-label;
    color: $red-50;
    margin-top: -6rem;
    text-align: center;
*/;
interface CalloutSectionProps {
    title: HeaderCellProps;
    value: string | number | null;
    loading?: boolean;
    label?: string;
    error?: any;
    small?: boolean;
    maxFracDigits?: number;
}

export function CalloutSection({
    title,
    value,
    label,
    loading = false,
    error,
    small = false,
    maxFracDigits = 1
}: CalloutSectionProps) {
    const intl = useIntl();
    const valueString =
        value !== null && value !== undefined
            ? typeof value === 'number'
                ? intl.formatNumber(value, {
                      maximumFractionDigits: maxFracDigits
                  })
                : value
            : '--';
    const [titleRef] = useTippy<HTMLDivElement>(
        title.description ? intl.formatMessage(title.description) : null,
        { placement: 'bottom' }
    );
    return (
        <div className={cx(CALLOUT_SECTION, { small })}>
            <Loading loading={loading} kind="over-table" size={8} />
            <div
                className={cx(CALLOUT_SECTION_TITLE, { small })}
                ref={titleRef}
            >
                <span
                    className={cx(CALLOUT_SECTION_TITLE_CONTENT, {
                        tooltip: title.description
                    })}
                >
                    {intl.formatMessage(title.name)}
                </span>
            </div>
            <div
                className={cx(CALLOUT_SECTION_VALUE, { hide: loading, small })}
            >
                {valueString}
            </div>
            {error ? (
                <div className={CALLOUT_SECTION_ERROR}>{error}</div>
            ) : true ? (
                <div className={CALLOUT_SECTION_LABEL}>{label}</div>
            ) : null}
        </div>
    );
}

type AggregatedCalloutSectionProps = {
    title: HeaderCellProps;
    value: string | number | null;
    loading?: boolean;
    average: number | null;
    error?: any;
};
export function AggregatedCalloutSection({
    average,
    ...rest
}: AggregatedCalloutSectionProps) {
    const intl = useIntl();
    return (
        <CalloutSection
            {...rest}
            label={
                average !== null
                    ? intl.formatMessage(messages['average-value'], {
                          count: average
                      })
                    : intl.formatMessage(messages['average-blank'])
            }
        />
    );
}

const REPORT_SUMMARY_INFO = "rx-page-report-tables-12"/*
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-end;
*/;

const REPORT_SUMMARY_INFO_COUNT = "rx-page-report-tables-13"/*
    @include headline-3;

*/;
const REPORT_SUMMARY_INFO_LABEL = "rx-page-report-tables-14"/*
    @include text-body;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
*/;
const REPORT_SUMMARY_INFO_AVERAGE = "rx-page-report-tables-15"/*
    @include text-body;
    margin-top: -12rem;
    margin-bottom: 4rem;
*/;

export type ReportSummaryInfoProps = {
    value: number | null;
    average?: number | null;
    label: HeaderCellProps;
};
export function ReportSummaryInfo({
    value,
    average,
    label
}: ReportSummaryInfoProps) {
    const intl = useIntl();
    const [infoRef] = useTippy<HTMLDivElement>(
        label.description ? intl.formatMessage(label.description) : null,
        { placement: 'bottom-end' }
    );
    return (
        <div className={REPORT_SUMMARY_INFO}>
            <div className={REPORT_SUMMARY_INFO_COUNT}>
                {value !== null ? <FormattedNumber value={value} /> : '----'}
            </div>
            {average !== undefined ? (
                <div className={REPORT_SUMMARY_INFO_AVERAGE}>
                    {average} Avg.
                </div>
            ) : null}
            <div className={REPORT_SUMMARY_INFO_LABEL} ref={infoRef}>
                {intl.formatMessage(label.name)}
                <Icon icon="Information" className={INFO_ICON} />
            </div>
        </div>
    );
}

export const NAV_LINKS_HEADER = "rx-page-report-tables-16"/*
    @include text-body;
    color: $gray-70;
    padding: 11rem 24rem 9rem 24rem;
    margin-bottom: 16rem;
    border-bottom: 1rem solid $gray-30;
*/;

const NAV_LINKS_LINK = "rx-page-report-tables-17"/*
    @include text-body-link;
    @include navigation-link;
    color: $gray-90;
    padding: 0 12rem;
    height: 40rem;
    display: flex;
    align-items: center;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin: 0 28rem;
    border-radius: 6rem;
    &:last-child {
        margin-bottom: 24rem;
    }
*/;

type NavLinkProps = {
    name: string;
    slug: string;
    disabled?: boolean;
    tooltip?: string | null;
    exact?: boolean;
};

type NavLinksProps = {
    links: NavLinkProps[];
};

function NavLink({
    name,
    slug,
    disabled = false,
    tooltip = null,
    exact
}: NavLinkProps) {
    const [linkRef] = useTippy<HTMLAnchorElement>(tooltip, {
        placement: 'left'
    });
    return (
        <Link
            ref={linkRef}
            disabled={disabled}
            key={slug}
            nav
            className={NAV_LINKS_LINK}
            to={slug}
            exact={exact}
        >
            {name}
        </Link>
    );
}

export function NavLinks({ links }: NavLinksProps) {
    return (
        <>
            {links.map(link => (
                <NavLink key={link.slug} {...link} />
            ))}
        </>
    );
}

const TABLE = "rx-page-report-tables-18"/*
    @include table;
    width: 100%;
    table-layout: fixed;
    svg {
        flex: 0 0 auto;
        font-size: 24rem;
        margin-right: 8rem;
        color: $gray-90;
    }
*/;

const HEADER_ICON = "rx-page-report-tables-19"/*
    display: flex;
    margin-top: 16rem;
*/;

type RowProps = {
    name: string | null;
    values: (number | string | JSX.Element | null)[];
    timestamps?: boolean;
    icon?: IconName | undefined;
};

export function Row({ name, values, icon }: RowProps) {
    return (
        <tr>
            {name ? (
                <th className={cx(icon && HEADER_ICON)}>
                    {icon && <Icon icon={icon} />}
                    {name}
                </th>
            ) : null}
            {values.map((value, i) => (
                <td key={i}>
                    {value === null || value === undefined ? (
                        '-'
                    ) : typeof value === 'number' ? (
                        <FormattedNumber
                            value={value}
                            maximumFractionDigits={1}
                        />
                    ) : (
                        value
                    )}
                </td>
            ))}
        </tr>
    );
}

export const INFO_ICON = "rx-page-report-tables-20"/*
    margin-left: 4rem;
    font-size: 20rem;
*/;

const HEADER_CELL_TOOLTIP = "rx-page-report-tables-21"/*
    @include tooltip;
*/;

const HEADER_CELL = "rx-page-report-tables-22"/*
    width: auto;
*/;
const FIRST_HEADER = "rx-page-report-tables-23"/*
    width: 300rem;
*/;

const SORT_BUTTON = "rx-page-report-tables-24"/*
    cursor: initial;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
*/;

export type HeaderProps = [HeaderInfo, string];

type HeaderCellProps = {
    name: MessageDescriptor;
    description?: MessageDescriptor;
    sortKey?: string;
    sorting?: TableSorting;
    onSortHeaderClick?: (string) => void;
};

function HeaderCell({
    name,
    description,
    sortKey,
    sorting,
    onSortHeaderClick
}: HeaderCellProps) {
    const intl = useIntl();
    const [headerRef] = useTippy<HTMLDivElement>(
        description ? intl.formatMessage(description) : null,
        { placement: 'bottom' }
    );
    return (
        <th className={HEADER_CELL}>
            <button
                onClick={
                    onSortHeaderClick
                        ? () => onSortHeaderClick(sortKey)
                        : undefined
                }
                className={SORT_BUTTON}
            >
                <span
                    ref={headerRef}
                    className={description ? HEADER_CELL_TOOLTIP : undefined}
                >
                    {intl.formatMessage(name)}
                </span>
                {sorting &&
                    sortKey &&
                    sorting.get(sortKey) &&
                    sortKey === [...sorting.keys()][0] && (
                        <Icon
                            icon={
                                sorting.get(sortKey) === 'desc' ? 'Down' : 'Up'
                            }
                        />
                    )}
            </button>
        </th>
    );
}

type HeaderRowProps = {
    headers: HeaderProps[];
    noLeftLabels?: boolean;
    wideLeftLabels?: boolean;
    sorting?: TableSorting;
    onSortHeaderClick?: (string) => void;
};

export function HeaderRow({
    headers,
    noLeftLabels = false,
    wideLeftLabels = false,
    sorting,
    onSortHeaderClick
}: HeaderRowProps) {
    return (
        <thead>
            <tr>
                {noLeftLabels ? null : (
                    <th className={cx({ [FIRST_HEADER]: wideLeftLabels })} />
                )}
                {headers.map(cellProps => (
                    <HeaderCell
                        key={cellProps[0].name.id}
                        {...cellProps[0]}
                        sortKey={cellProps[1]}
                        sorting={sorting}
                        onSortHeaderClick={onSortHeaderClick}
                    />
                ))}
            </tr>
        </thead>
    );
}

interface TableProps {
    children?: any;
}

export function Table({ children }: TableProps) {
    return <table className={TABLE}>{children}</table>;
}

const SUBVALUE = "rx-page-report-tables-25"/*
    @include text-caption;
    color: $gray-60;
    margin-top: -6rem;
*/;

type TimestampValueProps = {
    value: number;
    time: string;
};

export function TimestampValue({ value, time }: TimestampValueProps) {
    return (
        <div>
            <FormattedMessage
                defaultMessage="{count, number} <Timestamp>at {time, time, short}</Timestamp>"
                key="timestamp-value"
                values={{
                    time: new Date(time),
                    count: value,
                    Timestamp: msg => <div className={SUBVALUE}>{msg}</div>
                }}
            />
        </div>
    );
}

type AverageValueProps = {
    value: number;
    average: number;
};

export function AverageValue({ value, average }: AverageValueProps) {
    const intl = useIntl();
    return (
        <div>
            <FormattedNumber value={value} />
            <div className={SUBVALUE}>
                {intl.formatMessage(messages['average-value'], {
                    count: average
                })}
            </div>
        </div>
    );
}

/**
 * An informational message for use inside of a Container letting the user know
 * that a given metric isn't available because the report doesn't include any
 * AOIs.
 */
export function NoAreasInReport() {
    const { name } = useMapView();
    const { orgAuth } = useUser();
    const { organization } = orgAuth;

    const areas = useAreas();
    const subject = encodeURIComponent(
        `Setting up areas of interest for ${name}`
    );
    const body = encodeURIComponent(
        `Hi Ride Report,

I'm with ${organization.name}, and we'd like to set up some areas of interest for the ${name} dashboard. Please get in touch with us so we can work out the specifics.

Regards,`.replace(/ +/g, ' ')
    );
    return (
        <ContainerMessage>
            {areas && areas.length === 0 ? (
                <FormattedMessage
                    key="no-areas-at-all"
                    defaultMessage="You've not set up any areas of interest. To view your metrics by specific geographic areas, <link>contact us</link>. Please include links to definitions of these areas or attach .shp, .kml, or .geojson files if you have them."
                    values={{
                        link: msg => (
                            <a
                                className={LINK}
                                href={`mailto:support@ridereport.com?subject=${subject}&body=${body}`}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {msg}
                            </a>
                        )
                    }}
                />
            ) : (
                <FormattedMessage
                    key="no-areas-in-report"
                    defaultMessage="This report doesn't have any areas of interest."
                />
            )}
        </ContainerMessage>
    );
}

type LoadingTableProps = {
    headers: HeaderProps[];
    rows?: string[];
};

export function LoadingTable({ headers, rows }: LoadingTableProps) {
    return (
        <Table>
            <HeaderRow headers={headers} />
            <tbody>
                {(rows ?? range(5).map(() => '- - - -'))?.map((row, i) => (
                    <Row key={i} name={row} values={headers.map(() => null)} />
                ))}
            </tbody>
        </Table>
    );
}

export const messages = defineMessages({
    'total-row': 'Total',
    timestamp: 'at {time, time, short}',
    'average-value': '{count, number} Avg.',
    'average-blank': '---- Avg.',
    'search-areas': 'Search areas'
});

export const AREA_DETAILS = "rx-page-report-tables-26"/*
    display: grid;
    grid-gap: 24rem;
    grid-template-columns: 2fr 1fr;

    a {
        @include text-body-link-underlined;
        color: $blue-50;
    }
*/;
