import React, { Dispatch, useContext, useReducer } from 'react';
import textColorForBackgroundColor from 'common/text-color-for-bg-color';
import { CustomDataset, DatasetIdentifiers } from './types';

export const CustomDatasetsContext = React.createContext<
    | {
          state: CustomDatasetsState;
          dispatch: Dispatch<CustomDatasetsActions>;
      }
    | undefined
>(undefined);

CustomDatasetsContext.displayName = 'CustomDataContext';

export interface CustomDatasetsState {
    displayType: 'default' | 'heatmap';
    datasets: { [id: string]: CustomDataset };
}

type CustomDatasetsActions =
    | {
          type: 'ADD_DATASET';
          payload: {
              color: string;
              headers: string[];
              rows: string[][];
              id: string;
              identifiers: DatasetIdentifiers;
              title: string;
          };
      }
    | { type: 'TOGGLE_DATASET_ACTIVE_ON_MAP'; payload: { id: string } }
    | { type: 'REMOVE_DATASET'; payload: { id: string } }
    | {
          type: 'CHANGE_LAYER_DISPLAY';
          payload: { displayType: 'default' | 'heatmap' };
      };

function customDatasetsReducer(
    state: Readonly<CustomDatasetsState>,
    action: CustomDatasetsActions
) {
    switch (action.type) {
        case 'ADD_DATASET':
            return {
                ...state,
                datasets: {
                    ...state.datasets,
                    [action.payload.id]: {
                        activeOnMap: false,
                        color: action.payload.color,
                        headers: action.payload.headers,
                        rows: action.payload.rows,
                        id: action.payload.id,
                        identifiers: action.payload.identifiers,
                        textColor: textColorForBackgroundColor(
                            action.payload.color
                        ),
                        title: action.payload.title
                    }
                }
            };
        case 'TOGGLE_DATASET_ACTIVE_ON_MAP': {
            const { id } = action.payload;
            const newState = { ...state };
            newState.datasets[id].activeOnMap = !state.datasets[id].activeOnMap;
            return newState;
        }
        case 'REMOVE_DATASET': {
            const newState = { ...state };
            if (newState.datasets[action.payload.id]) {
                delete newState.datasets[action.payload.id];
            }
            return newState;
        }
        case 'CHANGE_LAYER_DISPLAY':
            return { ...state, displayType: action.payload.displayType };
        default:
            return state;
    }
}

interface CustomDatasetsProviderProps {
    children: React.ReactNode;
}

export function CustomDatasetsProvider({
    children
}: CustomDatasetsProviderProps) {
    const [state, dispatch] = useReducer(customDatasetsReducer, {
        displayType: 'default',
        datasets: {}
    });
    const value = { state, dispatch };
    return (
        <CustomDatasetsContext.Provider value={value}>
            {children}
        </CustomDatasetsContext.Provider>
    );
}

export function useCustomDatasets() {
    const context = useContext(CustomDatasetsContext);
    if (context == null) {
        throw new Error(
            'useCustomDatasets must be used within a CustomDatasetsProvider'
        );
    }
    return context;
}
