import React from 'react';
import {
    Route,
    RouteChildrenProps,
    RouteComponentProps,
    useRouteMatch
} from 'react-router-dom';
import { useRelativePath } from './use-relative-path';

type Props = {
    path?: string;
    component?:
        | React.ComponentType<RouteComponentProps<any>>
        | React.ComponentType<any>;
    render?: () => JSX.Element;
    children?:
        | ((props: RouteChildrenProps<any>) => React.ReactNode)
        | React.ReactNode;
    exact?: boolean;
};

/**
 * A react-router route with a path relative to the current component tree.
 */
function RelativeRoute({ path = '', ...rest }: Props) {
    const match = useRouteMatch();
    const result = useRelativePath(match ? match.path : '/', path);

    return <Route path={result} {...rest} />;
}

export default RelativeRoute;
