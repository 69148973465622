import { ContainerMessage, PageBody, PageContent } from 'common/layout';
import { Title } from 'common/title';
import {
    defineMessages,
    FormattedMessage,
    IntlShape,
    useIntl
} from 'react-intl';
import React from 'react';
import { useAreas } from 'common/use-areas';
import useDateRangeQuery from 'utils/use-date-range-query';
import useLastReportDate from 'common/use-last-report-date';
import { useFinalizedReportDays } from 'page-report/report-picker-daily';
import { useMapView } from 'common/use-map-view';
import {
    getPreliminaryDates,
    useEarliestReportDate,
    useLatestFinalizedReportDate
} from 'page-analyze/index';
import {
    DATE_PICKER_CONTAINER,
    DateRangeSelect,
    DateButtons,
    DateRange
} from 'common/date-picker';
import "/opt/build/repo/src/page-mode-shift/index.tsx?resplendence=true";
import useDocumentTitle from 'common/use-document-title';
import { ModeShiftBlock } from './chart';
import { gql } from '@apollo/client';
import { ModeShiftDataArgs, ModeShiftDataData, VehicleClass } from 'graphql.g';
import { orderBy } from 'lodash';
import { useReloadingData } from 'utils/use-data';
import { aggregatedDescriptions } from 'constants/stat-descriptions';
import { CalloutSection } from 'page-report/tables';
import * as strings from 'strings';
import Callout from 'common/callout';

/*
@import 'style';
*/;

export const LINK = "rx-page-mode-shift-index-1"/*
    @include text-link-underlined;
    color: $blue-50;
*/;

const FOOTER = "rx-page-mode-shift-index-2"/*
    color: $gray-70;
    @include subhead;
*/;

const CONTAINER = "rx-page-mode-shift-index-3"/*
    @include container;
    padding: 24rem;
    display: flex;
    flex-flow: row wrap;
    gap: 37rem 8rem;
*/;
const MODE_SHIFT_CALLOUT_GRID = "rx-page-mode-shift-index-4"/*
    margin-left: -24rem;
    display: grid;
    grid-template-columns: auto auto auto;
*/;
const m = defineMessages({
    'document-title': 'Mode Shift · Ride Report'
});
const MODESHIFT_QUERY = gql`
    query ModeShiftQuery($slug: String!, $start_date: Date!, $end_date: Date!) {
        mapViewBySlug(slug: $slug) {
            id
            modeShift(startDate: $start_date, endDate: $end_date) {
                id
                modeShiftPercentages {
                    vehicleType
                    percentage
                }
            }
        }
    }
`;

export function useModeShiftPercentages(dateRange: DateRange) {
    const { slug } = useMapView();
    const [modeShiftData, loading] = useReloadingData<
        ModeShiftDataData,
        ModeShiftDataArgs
    >(MODESHIFT_QUERY, {
        slug,
        start_date: dateRange[0].toString(),
        end_date: dateRange[1].toString()
    });
    return [
        modeShiftData?.mapViewBySlug?.modeShift?.modeShiftPercentages ?? null,
        loading
    ] as const;
}

function ModeShiftPercentages(
    modeShiftPercentages: {
        vehicleType: VehicleClass;
        percentage: number;
    }[],
    loading: boolean,
    intl: IntlShape
) {
    if (loading) {
        return null;
    }
    if (modeShiftPercentages.length === 0) {
        return (
            <>
                <Callout color="blue">
                    <FormattedMessage
                        key="preliminary"
                        defaultMessage="There are are no mode shift percentages because there are no trips."
                    />
                </Callout>
            </>
        );
    }
    const orderedModeShiftPercentages = orderBy(
        modeShiftPercentages,
        vc => intl.formatMessage(strings.vehicleClasses[vc.vehicleType]),
        'asc'
    );
    const result = orderedModeShiftPercentages.map(item => {
        const vehicleCallOut = (
            <CalloutSection
                key={item.vehicleType}
                loading={loading}
                value={`${Math.floor(item.percentage)}%`}
                title={aggregatedDescriptions[item.vehicleType]}
            />
        );
        return vehicleCallOut;
    });
    return <div className={MODE_SHIFT_CALLOUT_GRID}>{result}</div>;
}

function ModeShiftPage() {
    const areas = useAreas();
    const mapView = useMapView();
    const intl = useIntl();
    useDocumentTitle(intl.formatMessage(m[`document-title`]));
    const earliestReportDate = useEarliestReportDate();
    const lastReportDate = useLastReportDate();
    const [dateRange, setDateRange] = useDateRangeQuery([
        lastReportDate.minusDays(14),
        lastReportDate
    ]);
    const [modeShiftPercentagesData, loading] = useModeShiftPercentages(
        dateRange
    );

    const latestFinalizedReportDate =
        useLatestFinalizedReportDate() ?? lastReportDate;
    const [finalizedReportsByDate] = useFinalizedReportDays(
        mapView,
        latestFinalizedReportDate.minusDays(30),
        lastReportDate
    );

    const preliminaryDates = getPreliminaryDates(finalizedReportsByDate);
    const hasPreliminaryData =
        dateRange[1].isAfter(latestFinalizedReportDate) ||
        preliminaryDates.size !== 0;

    return (
        <PageContent>
            <Title
                title={
                    <FormattedMessage
                        key="page-title"
                        defaultMessage="Mode Shift (Alpha Feature)"
                    />
                }
                subtitle={
                    <FormattedMessage
                        key="mode-shift-percent"
                        defaultMessage="Percentage of shared mobility trips that replace car trips"
                    />
                }
            ></Title>

            {modeShiftPercentagesData
                ? ModeShiftPercentages(modeShiftPercentagesData, loading, intl)
                : null}

            <PageBody>
                <div className={CONTAINER}>
                    <div className={DATE_PICKER_CONTAINER}>
                        <DateRangeSelect
                            selectedDateRange={dateRange}
                            onChange={setDateRange}
                            latestAllowed={lastReportDate}
                            earliestAllowed={earliestReportDate ?? undefined}
                            preliminaryDates={preliminaryDates}
                        />
                        <DateButtons
                            earliestDate={earliestReportDate}
                            selectedDateRange={dateRange}
                            setSelectedDateRange={setDateRange}
                        />
                    </div>
                </div>
                <ModeShiftBlock
                    areas={areas}
                    metric="co2_tons_saved_by_day"
                    dateRange={dateRange}
                    preliminaryDates={preliminaryDates}
                    hasPreliminaryData={hasPreliminaryData}
                />
                <ModeShiftBlock
                    areas={areas}
                    metric="trip_duration_mode_shift"
                    dateRange={dateRange}
                    preliminaryDates={preliminaryDates}
                    hasPreliminaryData={hasPreliminaryData}
                />
                <ModeShiftBlock
                    areas={areas}
                    metric="car_trips_shifted"
                    dateRange={dateRange}
                    preliminaryDates={preliminaryDates}
                    hasPreliminaryData={hasPreliminaryData}
                />
                <ContainerMessage className={FOOTER}>
                    <FormattedMessage
                        key="mode-shift-policies"
                        defaultMessage="Publish your mode shift metrics to your <link>Open Data Portal</link>!"
                        values={{
                            link: (text: string) => (
                                <a
                                    className={LINK}
                                    href="https://public.ridereport.com/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {text}
                                </a>
                            )
                        }}
                    />
                </ContainerMessage>
            </PageBody>
        </PageContent>
    );
}

export default ModeShiftPage;
