import "/opt/build/repo/src/page-explore/page-realtime/geocoder.ts?resplendence=true";

/*
    @import 'style.scss';
*/;

// these override styles set in
// node_modules/@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css
export const GEOCODER = "rx-page-explore-page-realtime-geocoder-1"/*
    pointer-events: auto;

    .mapboxgl-ctrl-geocoder {
        height: 48rem;
        font-family: "Work Sans";
        z-index: 14;
        border-radius: 36rem;
        width: 100%;
        @include shadow-8;
    }
    .mapboxgl-ctrl-geocoder.mapboxgl-ctrl-geocoder--collapsed {
        width: 48rem;
        min-width: 48rem;
        height: 48rem;
        border-radius: 48rem;
        transition: width .25s, min-width .25s;
        @include shadow-8;
    }
    .mapboxgl-ctrl-geocoder--icon-search {
        position: absolute;
        top: 13rem;
        left: 12rem;
        width: 23rem;
        height: 23rem;
        fill: $gray-90;
    }
    .mapboxgl-ctrl-geocoder--input {
        height: 48rem;
        color: $gray-90;
        padding: 6px 45px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        border-radius: 36rem;
    }

    .mapboxgl-ctrl-geocoder--input:focus {
        color: $gray-90;
        outline: 0;
        box-shadow: none;
        outline: 1px solid $gray-90;
    }

    .mapboxgl-ctrl-geocoder .mapboxgl-ctrl-geocoder--pin-right > * {
        z-index: 15;
        position: absolute;
        right: 10rem;
        top: 14rem;
        display: none;
    }

    @media screen and (min-width: 640px) {
        .mapboxgl-ctrl-geocoder.mapboxgl-ctrl-geocoder--collapsed {
            width: 48rem;
            min-width: 48rem;
            height: 48rem;
        }
        .mapboxgl-ctrl-geocoder--icon-search {
            left: 11rem;
            width: 24rem;
            height: 24rem;
        }
        .mapboxgl-ctrl-geocoder--input {
            height: 48rem;
            padding: 6rem 32rem 6rem 48rem;
        }
        .mapboxgl-ctrl-geocoder--icon-close {
            margin: 0;
        }
    }
*/;
