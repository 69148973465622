import { Checkbox } from 'common/layout';
import { Select } from 'common/select';
import { useMapView } from 'common/use-map-view';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { FormInput, FormNotice } from './common';
import { PolicyFormProps } from './types';
import { getAvailableOperatorIds } from './utils';

function AllOrSingleOperatorSelect({
    policy,
    setPolicy,
    formErrors,
    setFormErrors
}: PolicyFormProps) {
    const [allOperators, setAllOperators] = useState(true);

    const { operators } = useMapView();
    const availableOperatorIds = getAvailableOperatorIds(
        operators,
        policy.area
    );

    useEffect(() => {
        // is there an operator selected that is available for this area?
        const noAvailableOperatorSelected =
            policy.providers.filter(p =>
                availableOperatorIds.includes(p.operatorId)
            ).length === 0;

        // we have an error if they didn't select all operators,
        // or one that is available for this area
        const hasProviderError = !allOperators && noAvailableOperatorSelected;

        if (formErrors.providers !== hasProviderError)
            setFormErrors({
                ...formErrors,
                providers: hasProviderError
            });
    }, [
        policy.providers,
        formErrors,
        setFormErrors,
        availableOperatorIds,
        allOperators
    ]);

    return (
        <>
            <FormInput
                labelId="operator-select-input"
                label={
                    <FormattedMessage
                        key="operator-label"
                        defaultMessage="Applicable Operators:"
                    />
                }
            >
                <div style={{ paddingTop: 12, paddingBottom: 24 }}>
                    <Checkbox
                        label={
                            <FormattedMessage
                                key="all-operators-checkbox"
                                defaultMessage="Apply policy to all operators"
                            />
                        }
                        checked={allOperators}
                        onChange={setAllOperators}
                    />
                </div>
                <Select
                    isDisabled={allOperators}
                    options={operators}
                    getOptionLabel={op => op.name}
                    getOptionValue={op => op.name}
                    isOptionDisabled={op =>
                        op
                            ? !availableOperatorIds.includes(op.operatorId)
                            : false
                    }
                    onChange={op => {
                        setPolicy({
                            ...policy,
                            providers: op ? [op] : []
                        });
                    }}
                    value={
                        policy.providers.length > 0 ? policy.providers[0] : null
                    }
                    placeholder="Select an Operator"
                />

                <FormNotice
                    noticeType="error"
                    showNotice={formErrors.providers}
                >
                    <FormattedMessage
                        key="no-operators-selected"
                        defaultMessage="A policy must apply to an operator."
                    />
                </FormNotice>
                <FormNotice
                    showNotice={
                        !allOperators &&
                        availableOperatorIds.length !== operators.length
                    }
                >
                    <FormattedMessage
                        key="filtered-operators"
                        defaultMessage="Only operators with access to this area can be added to the policy."
                    />
                </FormNotice>
            </FormInput>
        </>
    );
}

function SingleOperatorSelect({
    policy,
    setPolicy,
    formErrors,
    setFormErrors
}: PolicyFormProps) {
    const { operators } = useMapView();
    const availableOperatorIds = getAvailableOperatorIds(
        operators,
        policy.area
    );

    useEffect(() => {
        let hasProviderError = policy.providers.length === 0;
        if (formErrors.providers !== hasProviderError) {
            setFormErrors({
                ...formErrors,
                providers: hasProviderError
            });
        }
    }, [policy.providers, formErrors, setFormErrors]);

    return (
        <FormInput
            labelId="operator-select-input"
            label={
                <FormattedMessage
                    key="operator-label"
                    defaultMessage="Applicable Operators:"
                />
            }
        >
            <Select
                options={operators}
                getOptionLabel={op => op.name}
                getOptionValue={op => op.name}
                isOptionDisabled={op =>
                    op ? !availableOperatorIds.includes(op.operatorId) : false
                }
                onChange={op => {
                    setPolicy({
                        ...policy,
                        providers: op ? [op] : []
                    });
                }}
                value={policy.providers.length > 0 ? policy.providers[0] : null}
                placeholder="Select an Operator"
            />

            <FormNotice
                noticeType="error"
                showNotice={
                    // if none of the available operators are selected
                    policy.providers.filter(p =>
                        availableOperatorIds.includes(p.operatorId)
                    ).length === 0
                }
            >
                <FormattedMessage
                    key="no-operators-selected"
                    defaultMessage="A policy must apply to an operator."
                />
            </FormNotice>
            <FormNotice
                showNotice={availableOperatorIds.length !== operators.length}
            >
                <FormattedMessage
                    key="filtered-operators"
                    defaultMessage="Only operators with access to this area can be added to the policy."
                />
            </FormNotice>
        </FormInput>
    );
}

export { AllOrSingleOperatorSelect, SingleOperatorSelect };
