import React, { useEffect, useMemo } from 'react';
import { RouteComponentProps, useRouteMatch, useHistory } from 'react-router';
import gql from 'graphql-tag';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';

import { Switch } from '../router';
import { PageContent, PageColumns, Banner, Button } from 'common/layout';
import { LocalDate, LocalMonth, LocalWeek } from 'utils/date-tools';
import { useMapView } from 'common/use-map-view';
import { useAreas } from 'common/use-areas';
import {
    convertToSlug,
    getUrlWithChangedParams,
    sortAreas
} from 'utils/helpers';
import ReportNavs, { AoiLink } from './nav';
import { ReportDate } from './report-picker';
import useData from 'utils/use-data';

import PageDailySummary from './page-daily-summary';
import PageDailyOperator from './page-daily-operator';
import PageDailyArea from './page-daily-area';
import PageAggregatedSummary from './page-aggregated-summary';
import PageAggregatedOperator from './page-aggregated-operator';
import PageAggregatedArea from './page-aggregated-area';
import DownloadButtons from './download-buttons';
import Report from './report';
import { PAGE_NOT_FOUND } from 'strings';
import { Title } from 'common/title';
import {
    OperatorHasDailyReportData,
    OperatorHasDailyReportArgs,
    AggregatedReportPeriod,
    OperatorHasAggregatedReportData,
    OperatorHasAggregatedReportArgs
} from 'graphql.g';

import { useViewport, MOBILE_BREAKPOINT } from 'utils/use-viewport';
import { AreaBanner } from './report-area-banner';
import { AoiInfoFragment } from './fragments';
import { sendEventAnalytics } from 'utils/use-analytics';

interface Props extends RouteComponentProps<{ mode: string; date: string }> {}

const summaryPages = {
    daily: PageDailySummary,
    weekly: PageAggregatedSummary,
    monthly: PageAggregatedSummary
};

const operatorPages = {
    daily: PageDailyOperator,
    weekly: PageAggregatedOperator,
    monthly: PageAggregatedOperator
};

const areaPages = {
    daily: PageDailyArea,
    weekly: PageAggregatedArea,
    monthly: PageAggregatedArea
};

function getBeginningOfReportPeriod(date: ReportDate | null) {
    if (!date) return null;
    if (date instanceof LocalDate) return date;
    if (date instanceof LocalWeek) return date.monday;
    return date.first;
}

function getReportDate(mode: string, dateString: string): ReportDate | null {
    let date: LocalDate;
    try {
        date = LocalDate.fromDateString(dateString);
    } catch {
        // We changed monthly report routing slugs from YYYY-MM to YYYY-MM-01
        // In case the user has a bookmark in the old format, check to see if
        // the date parses as a month
        try {
            date = LocalMonth.fromString(dateString).first;
        } catch {
            return null;
        }
    }
    switch (mode) {
        case 'daily':
            return date;
        case 'weekly':
            return date.toLocalWeek();
        case 'monthly':
            return date.toLocalMonth();
        default:
            return null;
    }
}

/**
 * If the report date used in the url isn't the "canonical" version, redirect to
 * the correct formatting.
 *
 * For example,
 * - monthly/2020-01 --> monthly/2020-01-01
 * - monthly/2020-05-20 --> monthly/2020-05-01
 * - weekly/2020-07-01 (wednesday) --> weekly/2020-06-29 (the monday before)
 */
function useRedirectToMatchReportDate(reportDate: ReportDate | null) {
    const match = useRouteMatch<{ date: string }>();
    const history = useHistory();
    const periodStart = getBeginningOfReportPeriod(reportDate);
    useEffect(() => {
        if (periodStart && match.params.date !== periodStart.toString()) {
            history.replace(
                getUrlWithChangedParams(match.path, {
                    date: periodStart.toString()
                })
            );
        }
    });
}

const HAS_REPORT_QUERY = gql`
    query OperatorHasDailyReport($slug: String!, $date: Date!) {
        mapView(slug: $slug) {
            id
            operators {
                id
                name
                report(date: $date) {
                    id
                    reportDate
                    aoiMetrics: aoiMdsMetrics {
                        area {
                            ...AoiInfo
                        }
                    }
                }
            }
        }
    }
    ${AoiInfoFragment}
`;
const HAS_AGGREGATED_REPORT_QUERY = gql`
    query OperatorHasAggregatedReport(
        $slug: String!
        $date: Date!
        $period: AggregatedReportPeriod!
    ) {
        mapView(slug: $slug) {
            id
            operators {
                id
                name
                report: aggregatedReport(date: $date, period: $period) {
                    id
                    reportDate
                    aoiMetrics {
                        area {
                            ...AoiInfo
                        }
                    }
                }
            }
        }
    }
    ${AoiInfoFragment}
`;

const QUERY_FOR_MODE = {
    daily: HAS_REPORT_QUERY,
    weekly: HAS_AGGREGATED_REPORT_QUERY,
    monthly: HAS_AGGREGATED_REPORT_QUERY
};

type NotFoundProps = {
    reportDate: ReportDate;
    areaFromUrl: string;
};

export function ReportNotFound({ reportDate, areaFromUrl }: NotFoundProps) {
    const areas = useAreas();
    const selectedArea = areas?.find(area => area.slug === areaFromUrl);
    return (
        <Report
            reportDate={reportDate}
            header={
                selectedArea ? (
                    <AreaBanner area={selectedArea} />
                ) : (
                    <Banner>{PAGE_NOT_FOUND}</Banner>
                )
            }
            unfinalizedOperators={[]}
            reportFound={false}
        />
    );
}

const m = defineMessages({
    'daily-header': '{day, date, reportday}',
    'weekly-header': '{monday, date, reportweek}—{sunday, date, reportweek}',
    'monthly-header': '{month, date, reportmonth}'
});

type OperatorHasReportsArgs =
    | OperatorHasDailyReportArgs
    | OperatorHasAggregatedReportArgs;

type OperatorHasReportsData =
    | OperatorHasDailyReportData
    | OperatorHasAggregatedReportData;

function ReportsPage({ match }: Props) {
    const intl = useIntl();
    const { mode, date } = match.params;
    const mapView = useMapView();
    const singleOperator = mapView.operators.length === 1;
    const reportDate = getReportDate(mode, date);
    useRedirectToMatchReportDate(reportDate);

    const { width } = useViewport();
    const isMobile = width < MOBILE_BREAKPOINT;

    const data = useData<OperatorHasReportsData, OperatorHasReportsArgs>(
        QUERY_FOR_MODE[mode] ?? HAS_REPORT_QUERY,
        !reportDate
            ? 'skip'
            : reportDate instanceof LocalDate
            ? { slug: mapView.slug, date: reportDate.toString() }
            : reportDate instanceof LocalWeek
            ? {
                  slug: mapView.slug,
                  date: reportDate.monday.toString(),
                  period: AggregatedReportPeriod.weekly
              }
            : {
                  slug: mapView.slug,
                  date: reportDate.first.toString(),
                  period: AggregatedReportPeriod.monthly
              }
    );

    const operators = !data
        ? mapView.operators.map(op => ({
              name: op.name,
              disabled: true
          }))
        : // declare this as any[] since TS can't pick up that all the objects will be of one type
          (data.mapView.operators as any[])
              .filter(op => op.report)
              .map(op => ({
                  name: op.name,
                  disabled: !op.report
              }));

    const activeAreas = useAreas();
    const areas = useMemo(() => {
        // When showing the list of areas available on the report, we also we to
        // include the currently-active AOIs that are missing. This way, the
        // positions of the links in the navigator remaining consistent as you
        // navigate through time, and we can clarify to user why that area isn't
        // available.
        if (!data) return [];
        const areasById: Record<string, AoiLink> = {};
        // Add all the areas present on any reports
        for (const operator of data.mapView.operators) {
            for (const metric of operator?.report?.aoiMetrics ?? []) {
                if (!areasById[metric.area.id]) {
                    areasById[metric.area.id] = {
                        ...metric.area,
                        disabled: false
                    };
                }
            }
        }
        // Any areas not on reports but active on the mapview should then be
        // mixed in and marked as disabled.
        for (const area of activeAreas ?? []) {
            if (!areasById[area.id]) {
                areasById[area.id] = { ...area, disabled: true };
            }
        }
        return sortAreas(Object.values(areasById));
    }, [activeAreas, data]);

    if (!reportDate)
        return (
            <PageContent>
                <Title title={PAGE_NOT_FOUND} />
            </PageContent>
        );

    const pageTitle =
        reportDate instanceof LocalDate
            ? intl.formatMessage(m['daily-header'], {
                  day: reportDate.toDate()
              })
            : reportDate instanceof LocalWeek
            ? intl.formatMessage(m['weekly-header'], {
                  monday: reportDate.monday.toDate(),
                  sunday: reportDate.sunday.toDate()
              })
            : intl.formatMessage(m['monthly-header'], {
                  month: reportDate.first.toDate()
              });

    return (
        <PageContent wide>
            <Title
                subtitle={
                    reportDate instanceof LocalDate ? (
                        <FormattedMessage
                            key="daily-subtitle"
                            defaultMessage="Daily Report"
                        />
                    ) : reportDate instanceof LocalWeek ? (
                        <FormattedMessage
                            key="weekly-subtitle"
                            defaultMessage="Weekly Report"
                        />
                    ) : (
                        <FormattedMessage
                            key="monthly-subtitle"
                            defaultMessage="Monthly Report"
                        />
                    )
                }
                title={pageTitle}
            >
                {!isMobile && (
                    // add tracking metrics here
                    <Button
                        color="blue"
                        icon="Print"
                        onClick={() => {
                            window.print();

                            sendEventAnalytics({
                                event_name: 'data export event',
                                name: match.url,
                                action: 'printed'
                            });
                        }}
                    >
                        <FormattedMessage
                            key="print-button"
                            defaultMessage="Print"
                        />
                    </Button>
                )}
                <DownloadButtons reportDate={reportDate} />
            </Title>
            <PageColumns>
                <Switch
                    properties={{ reportDate: reportDate }}
                    pages={[
                        // a path to operator details for each operator
                        // if operators.length > 1
                        ...(singleOperator
                            ? [
                                  {
                                      path: `/for/${convertToSlug(
                                          mapView.operators[0].name
                                      )}`,
                                      exact: true,
                                      redirectTo: '/'
                                  }
                              ]
                            : mapView.operators.map(op => ({
                                  path: `/for/${convertToSlug(op.name)}`,
                                  exact: true,
                                  component: operatorPages[mode],
                                  properties: {
                                      operator: op
                                  }
                              }))),
                        // area details
                        {
                            path: '/in/:area',
                            exact: true,
                            component: areaPages[mode] /*PageArea*/
                        },
                        // overview page, unless there's only one operator, then details
                        singleOperator
                            ? {
                                  path: `/`,
                                  exact: true,
                                  component: operatorPages[mode],
                                  properties: { operator: mapView.operators[0] }
                              }
                            : {
                                  path: '/',
                                  exact: true,
                                  component: summaryPages[mode]
                              },
                        {
                            component: ReportNotFound
                        }
                    ]}
                />
                <ReportNavs
                    areas={areas}
                    operators={mapView.operators.length > 1 ? operators : null}
                />
            </PageColumns>
        </PageContent>
    );
}

export default ReportsPage;
