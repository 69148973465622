import React from 'react';
import { FormattedMessage } from 'react-intl';
import Sidebar from '../../common/sidebar/sidebar';
import { Button } from 'common/layout';
import { useViewport, MOBILE_BREAKPOINT } from 'utils/use-viewport';
import { useModal } from 'common/modal';
import RoutesDownloadModal from './download-modal';
import { MapOptions, MapOptionsAction } from 'page-explore';
import { DateRange } from 'common/date-picker';
import SidebarSection from '../../common/sidebar/sidebar-section';
import SidebarCustomDatasets from '../../common/sidebar/custom-datasets';
import CommonPathLayersToggles from '../../layers/paths/layers';
import { AggregatedRouteFeature } from '../../layers/routes/sources';

interface RoutesSidebarProps {
    routesGeoJSONData: AggregatedRouteFeature[];
    mapOptions: MapOptions;
    dispatchMapOptions: React.Dispatch<MapOptionsAction>;
    dateRange: DateRange;
}

const RoutesSidebar = ({
    dateRange,
    routesGeoJSONData,
    mapOptions,
    dispatchMapOptions
}: RoutesSidebarProps) => {
    const { width } = useViewport();
    const isMobile = width < MOBILE_BREAKPOINT;
    const { isModalShowing, toggleModal } = useModal();

    if (isMobile) {
        return null;
    }

    return (
        <Sidebar
            mapOptions={mapOptions}
            dispatchMapOptions={dispatchMapOptions}
        >
            <SidebarCustomDatasets />
            <SidebarSection
                title={
                    <FormattedMessage
                        key="map-options"
                        defaultMessage="Map Options"
                    />
                }
            >
                <CommonPathLayersToggles
                    dispatchMapOptions={dispatchMapOptions}
                />
            </SidebarSection>

            <SidebarSection
                title={
                    <FormattedMessage key="export" defaultMessage="Export" />
                }
            >
                <Button
                    color="blue"
                    icon="Download"
                    center={true}
                    onClick={toggleModal}
                >
                    <FormattedMessage
                        key="export-routes"
                        defaultMessage="Export Routes"
                    />
                </Button>
            </SidebarSection>

            <RoutesDownloadModal
                dateRange={dateRange}
                routesGeoJSONData={routesGeoJSONData}
                isModalShowing={isModalShowing}
                toggleModal={toggleModal}
            />
        </Sidebar>
    );
};

export default RoutesSidebar;
