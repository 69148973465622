import React, { useState } from 'react';
import { IconName } from './icon';
import { Button } from './layout';
import { useAuthInfo } from './authentication';
import config from 'config';
import { sendEventAnalytics } from 'utils/use-analytics';
import Modal, { MODAL_FORM, MODAL_TITLE, useModal } from './modal';
import { FormattedMessage } from 'react-intl';

const DOWNLOAD_FAILURE_MESSAGES = {
    'download-failure-time-range': (
        <FormattedMessage
            key="download-failure-time-range"
            defaultMessage="Most likely this is because the files are too large. Try selecting a smaller time range and retrying."
        />
    ),
    'download-failure-temporary': (
        <FormattedMessage
            key="download-failure-temporary"
            defaultMessage="This is likely a temporary error. Please retry the download in a few minutes."
        />
    )
};

export type DownloadFailureReason = keyof typeof DOWNLOAD_FAILURE_MESSAGES;

type Props = {
    icon: IconName;
    children: React.ReactNode;
    path: string;
    params: { [key: string]: string };
    filename: string;
    failureReason: DownloadFailureReason;
    color?: 'blue' | 'gray';
};

async function downloadExportedData(
    path: string,
    params: { [key: string]: string },
    token: string,
    filename: string
) {
    // 1. Download as blob
    const headers = { Authorization: `Bearer ${token}` };
    const searchParams = new URLSearchParams(params);
    const fullPath = `${config.apiHost}/v1/data-export/${path}`;
    const response = await fetch(`${fullPath}?${searchParams.toString()}`, {
        headers: headers
    });
    const fileExt = filename.split('.').pop();

    if (!response.ok) {
        sendEventAnalytics({
            event_name: 'data export event',
            name: filename,
            action: 'failed',
            type: fileExt
        });
        throw new Error(
            `Response Failed: ${response.status} ${JSON.stringify(
                response.json(),
                null,
                2
            )}`
        );
    }
    const blob = await response.blob();
    // 2. Create blob link to download
    const url = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename);
    // 3. Append to html page
    document.body.appendChild(link);
    // 4. Force download
    link.click();
    // 5. Clean up and remove the link
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    link.parentNode!.removeChild(link);

    sendEventAnalytics({
        event_name: 'data export event',
        name: filename,
        action: 'exported',
        type: fileExt
    });
}

function DownloadButton({
    icon,
    children,
    path,
    params,
    filename,
    failureReason,
    color = 'blue'
}: Props) {
    const [loading, setLoading] = useState(false);
    const token = useAuthInfo().token;
    const { isModalShowing, toggleModal } = useModal();
    const failureMessage = DOWNLOAD_FAILURE_MESSAGES[failureReason];

    return (
        <>
            <Modal isModalShowing={isModalShowing} hide={() => toggleModal()}>
                <div className={MODAL_FORM}>
                    <h3 className={MODAL_TITLE}>
                        <FormattedMessage
                            key="download-failed"
                            defaultMessage="The file(s) failed to download"
                        />
                    </h3>
                    <p>{failureMessage}</p>
                    <p>
                        <FormattedMessage
                            key="download-failure-contact"
                            defaultMessage="Please contact support@ridereport.com for assistance if you continue to encounter issues."
                        />
                    </p>
                    <div>
                        <Button color="blue" onClick={toggleModal}>
                            <FormattedMessage key="ok" defaultMessage="OK" />
                        </Button>
                    </div>
                </div>
            </Modal>

            <Button
                icon={icon}
                color={color}
                loading={loading}
                onClick={async () => {
                    setLoading(true);
                    try {
                        await downloadExportedData(
                            path,
                            params,
                            token,
                            filename
                        );
                    } catch (err) {
                        toggleModal();
                    } finally {
                        setLoading(false);
                    }
                }}
            >
                {children}
            </Button>
        </>
    );
}

export default DownloadButton;
