import React, { Dispatch, SetStateAction } from 'react';
import { ContainerHeader, RadialButton } from 'common/layout';
import { FormattedMessage, useIntl } from 'react-intl';
import {
    RegionalAvailableVehicleClassesData,
    RegionalAvailableVehicleClassesArgs,
    VehicleClass
} from 'graphql.g';
import gql from 'graphql-tag';
import useData from 'utils/use-data';
import * as strings from 'strings';
import "/opt/build/repo/src/page-regional-dashboard/region-sidebar.tsx?resplendence=true";

/*
    @import 'style';
*/;

const REGION_SIDERBAR = "rx-page-regional-dashboard-region-sidebar-1"/*
    @include container;
    padding: 24rem;
*/;

const REGION_SIDEBAR_SUBHEADER = "rx-page-regional-dashboard-region-sidebar-2"/*
    @include text-label;
    color: $gray-60;
    margin: 16rem 0;
*/;

interface regionSidebarProps {
    vehicleClass: VehicleClass | string | null;
    setVehicleClass: Dispatch<SetStateAction<VehicleClass | string | null>>;
}

export function useAllAvailableVehicleClasses() {
    const intl = useIntl();

    const REGIONAL_AVAILABLE_VEHICLE_TYPES = gql`
        query RegionalAvailableVehicleClasses {
            mapViews {
                id
                availableVehicleClasses
            }
        }
    `;

    const availableVehicleClasses = useData<
        RegionalAvailableVehicleClassesData,
        RegionalAvailableVehicleClassesArgs
    >(REGIONAL_AVAILABLE_VEHICLE_TYPES, {});

    const vehicleClasses = new Set(
        availableVehicleClasses?.mapViews.flatMap(
            mapView => mapView.availableVehicleClasses
        )
    );

    const vehicleStrings = [...vehicleClasses].map(vehicle =>
        intl.formatMessage(strings.vehicleClasses[vehicle])
    );

    return [vehicleClasses, vehicleStrings];
}

function RegionSidebar({ vehicleClass, setVehicleClass }: regionSidebarProps) {
    const initialVehicleClass = 'All Vehicles';
    const [vehicleClasses, vehicleStrings] = useAllAvailableVehicleClasses();

    return (
        <div className={REGION_SIDERBAR}>
            <ContainerHeader>
                <FormattedMessage key="filter" defaultMessage="Filter" />
            </ContainerHeader>
            <h2 className={REGION_SIDEBAR_SUBHEADER}>
                <FormattedMessage
                    key="vehicle-types"
                    defaultMessage="Vehicle Types"
                />
            </h2>
            <RadialButton
                selected={vehicleClass === null ? 'all_vehicles' : vehicleClass}
                options={[initialVehicleClass, ...vehicleStrings]}
                values={['all_vehicles', ...vehicleClasses]}
                onChange={setVehicleClass}
            />
        </div>
    );
}

export default RegionSidebar;
