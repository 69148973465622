import { GeoJSONSourceRaw } from 'mapbox-gl';

export function getCurbEventsSource(
    curbEvents: any,
    isClustered: boolean
): GeoJSONSourceRaw {
    const source = {
        type: 'geojson' as const,
        data: {
            type: 'FeatureCollection' as const,
            features: curbEvents
        }
    };

    if (isClustered) {
        source['cluster'] = true;
        source['clusterRadius'] = 100;
        source['clusterMaxZoom'] = 24;
    }

    return source;
}
