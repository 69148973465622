import * as React from 'react';
import * as ReactDOM from 'react-dom';
import Root from './root';
import Rollbar from 'rollbar';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faSatellite } from '@fortawesome/free-solid-svg-icons/faSatellite';
import { faMap } from '@fortawesome/free-solid-svg-icons/faMap';
import { faChartLine } from '@fortawesome/free-solid-svg-icons/faChartLine';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { faExclamation } from '@fortawesome/free-solid-svg-icons/faExclamation';
import { faThermometerHalf } from '@fortawesome/free-solid-svg-icons/faThermometerHalf';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons/faMapMarkerAlt';
import { faStar } from '@fortawesome/free-solid-svg-icons/faStar';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons/faInfoCircle';

import './base-style.scss';

library.add(
    faSatellite,
    faMap,
    faChartLine,
    faTimes,
    faExclamation,
    faThermometerHalf,
    faMapMarkerAlt,
    faStar,
    faCheck,
    faInfoCircle
);

if (
    process.env.NODE_ENV !== 'development' &&
    process.env.NODE_ENV !== 'local_development'
) {
    const config: Rollbar.Configuration = {
        accessToken: 'ab1d48e1d55a4ab7a0c130daaa08990f',
        captureUncaught: false,
        captureUnhandledRejections: false,
        payload: {
            environment: process.env.NODE_ENV,
            client: {
                javascript: {
                    source_map_enabled: true,
                    code_version: process.env.COMMIT_HASH,
                    guess_uncaught_frames: true
                }
            }
        },
        autoInstrument: {
            network: true,
            log: true,
            dom: false,
            navigation: false,
            connectivity: true,
            networkRequestBody: true
        },
        filterTelemetry: event => {
            // remove analytics requests from Rollbar telemetry
            // adapted from https://docs.rollbar.com/docs/rollbarjs-telemetry#configuration
            const ANALYTICS_URLS = [
                'https://rs.fullstory.com',
                'https://api-js.mixpanel.com',
                'https://api.segment.io'
            ];
            const isNetworkRequest =
                event.type === 'network' &&
                ((event.body as any).subtype === 'xhr' ||
                    (event.body as any).subtype === 'fetch');

            return (
                isNetworkRequest &&
                ANALYTICS_URLS.some(
                    url => (event.body as any).url.indexOf(url) === 0
                )
            );
        }
    };
    window.rollbar = new Rollbar(config);
}

const app = document.getElementById('app');

if (app) {
    ReactDOM.render(<Root />, app);

    if ((module as any).hot) {
        (module as any).hot.accept('./root', () => {
            const NewRoot = require('./root').default;
            ReactDOM.render(<NewRoot />, app);
        });
    }
}
