import { useHistory } from 'react-router';
import { useCallback, useEffect } from 'react';

/**
 * Modifies a queryString, such as '?hello=world', replacing the listed params
 * with new values.
 *
 * Unlisted and undefined values are not modified.
 * Null values are removed from the query.
 * Previously unpresent values will be appended.
 */
export function modifyQuery(
    search: string,
    params: Record<string, string | null | undefined>
) {
    const query = new URLSearchParams(search);
    for (const key in params) {
        const param = params[key];
        if (param === null) {
            if (query.get(key)) {
                query.delete(key);
            }
        } else if (param !== undefined) {
            if (query.get(key) !== param) {
                query.set(key, param);
            }
        }
    }
    const queryString = query.toString();
    return queryString && '?' + queryString;
}

/**
 * A react-state-like interface to url query params. For example, if you were
 * currently navigated to '/pdx/explore', then used this hook:
 *
 * const [selectedVehicle, setSelectedVehicle] = useQueryState('selectedVehicle');
 *
 * selectedVehicle would be null. If you called...
 *
 * setSelectedVehicle('1');
 *
 * the current url would be replaced with '/pdx/explore?selectedVehicle=1' and
 * selectedVehicle would be "1". If you refreshed the page, the value of
 * selectedVehicle would persist.
 *
 * Navigating to any link will remove all current query params (unless the link
 * includes its own params). Additionally, passing null to the setter will
 * remove that specific param from the url.
 *
 * If multiple components share the same key, then they'll share state with
 * each other.
 *
 * By passing a value to constrain, you can limit the valid options. If the user
 * navigates to a url with a param value not included in the provided set, then
 * they'll be redirected to whatever you set as the fallback value. Example:
 *
 * useQueryState('value', {
 *   options: new Set([null, 'one', 'two', 'three']),
 *   fallbackValue: null
 * });
 *
 * If the user manually entered the url '/pdx?value=four', they'd be redirected
 * to '/pdx' on the first render of this hook.
 */
export function useQueryState<T extends string | null = string | null>(
    key: string,
    options: {
        /**
         * if true, delete the queryParam when the component unmounts.
         */
        deleteOnUnmount?: boolean;
        constrain?: {
            options: Set<T>;
            fallbackValue: T;
        };
        /**
         * If true, the param will always be shown as part of the URL, even if
         * it's currently set to the default value.
         */
        alwaysShowInUrl?: boolean;
        /**
         * If false, setting the state won't do anything, and you cannot rely on
         * the return value to be any value in particular. Prevents a backgrounded
         * component from polluting the url.
         * Defaults to true.
         */
        active?: boolean;
        /** If true, setting the state should be recorded in browser history. */
        addToHistory?: boolean;
    } = {}
) {
    const {
        location: { pathname, search, state: locationState },
        push,
        replace
    } = useHistory();
    const {
        deleteOnUnmount = false,
        alwaysShowInUrl = false,
        constrain,
        addToHistory,
        active = true
    } = options;

    const fallbackValue = constrain?.fallbackValue;

    const setState = useCallback(
        (value: T) => {
            const modified = modifyQuery(search, {
                [key]: fallbackValue && fallbackValue === value ? null : value
            });
            if (addToHistory) {
                push({ pathname, search: modified, state: locationState });
            } else {
                replace({ pathname, search: modified, state: locationState });
            }
        },
        [
            search,
            key,
            fallbackValue,
            addToHistory,
            push,
            pathname,
            locationState,
            replace
        ]
    );

    // Get the current value from the url.
    const query = new URLSearchParams(search);
    const queryParam = query.get(key);
    let valueFromQuery: string | null = null;
    if (search) {
        valueFromQuery = queryParam ?? constrain?.fallbackValue ?? null;
    }

    // It's possible the value in the url isn't a valid option. If so, we can
    // coerce it to the default value...
    let state: T;
    if (constrain && !constrain.options.has(valueFromQuery as T)) {
        state = constrain.fallbackValue;
    } else {
        state = valueFromQuery as T;
    }
    // ... and update the url to match
    useEffect(() => {
        if (
            active &&
            (alwaysShowInUrl ? state !== queryParam : state !== valueFromQuery)
        ) {
            setState(state);
        }
    }, [active, alwaysShowInUrl, queryParam, setState, state, valueFromQuery]);

    // We want to remove this key from the url query only on unmount.
    useEffect(() => {
        if (deleteOnUnmount) {
            return () => {
                const modified = modifyQuery(window.location.search, {
                    [key]: null
                });
                replace({
                    pathname: window.location.pathname,
                    search: modified,
                    state: locationState
                });
            };
        }
    }, [deleteOnUnmount, key, replace, locationState]);

    return [state, setState] as const;
}
