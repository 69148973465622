import React from 'react';
import "/opt/build/repo/src/page-explore/common/mode-picker.tsx?resplendence=true";
import { defineMessages, FormattedMessage } from 'react-intl';

import { MapOptions, MapOptionsAction } from 'page-explore';
import { useMapMode, useMapModes } from '../page-heatmaps';
import { Select } from 'common/select';
import { useIntl } from 'utils/use-intl';
import MAP_CONTAINER from './map-container';

/*
@import 'style.scss';
*/;

const COLUMN = "rx-page-explore-common-mode-picker-1"/*
    display: grid;
    grid-gap: 16rem;
    align-content: start;
    justify-items: start;
*/;

const SELECT_WRAPPER = "rx-page-explore-common-mode-picker-2"/*
    display: grid;
*/;

const m = defineMessages({
    'select-trip-starts': 'Trip Starts',
    'select-trip-ends': 'Trip Ends',
    'select-routes': 'Routes',
    'select-curb-events': 'Curb Events'
});

interface ModePickerProps {
    options: MapOptions;
    dispatchMapOptions: React.Dispatch<MapOptionsAction>;
    children: React.ReactNode;
}

function ModePicker({ children }: ModePickerProps) {
    const intl = useIntl();

    const [mode, setMode] = useMapMode();

    return (
        <div className={COLUMN}>
            <div className={MAP_CONTAINER}>
                <div className={SELECT_WRAPPER}>
                    <label>
                        <FormattedMessage
                            key="select-mode-label"
                            defaultMessage="Data Type"
                        />
                    </label>
                    <Select
                        options={useMapModes().map(mode => ({ value: mode }))}
                        getOptionLabel={option =>
                            intl.formatMessage(
                                m[`select-${option.value.replace(/_/g, '-')}`]
                            )
                        }
                        value={{ value: mode }}
                        onChange={option => option && setMode(option.value)}
                    />
                </div>
                {children}
            </div>
        </div>
    );
}

export default ModePicker;
