import { gql } from '@apollo/client';

export const POLICY_DETAILS_FRAGMENT = gql`
    fragment PolicyDetails on Policy {
        id
        name
        description
        hasEnded
        policyType
        operators {
            id
            name
        }
        startDate
        endDate
        rules {
            ...PolicyRuleDetails
        }
        policyApiData
        isPublic
    }
    fragment PolicyRuleDetails on PolicyRule {
        __typename
        id
        name
        ruleType
        vehicleClasses
        days
        startTime
        endTime
        areas {
            id
            name
            slug
        }
        ... on PolicyRateRule {
            name
            rateAmount
            __typename
        }
        ... on PolicyMaximumRule {
            name
            maximum
            __typename
        }
        ... on PolicyMinDeploymentRule {
            name
            minimum
            summaryStatistic
            __typename
        }
        ... on PolicyVehicleCapRule {
            name
            maximum
            summaryStatistic
            __typename
        }
    }
`;

const POLICY_METRIC_DATA_LINE_FRAGMENT = gql`
    fragment PolicyMetricDataLine on PolicyMetricDataLine {
        operator {
            name
        }
        points {
            xValue
            yValue
        }
        numDaysInCompliance
        numDaysInPeriod
        percentCompliance
        percentChangeInCompliance
        complianceIsAcceptable
    }
`;

export const POLICY_METRIC_FRAGMENT = gql`
    fragment PolicyMetric on PolicyMetricData {
        startDate
        endDate
        ruleTarget
        summedOperatorData {
            ...PolicyMetricDataLine
        }
        data {
            ...PolicyMetricDataLine
        }
    }
    ${POLICY_METRIC_DATA_LINE_FRAGMENT}
`;
