import Icon from 'common/icon';
import { ICON_FOR_VEHICLE_CLASS } from 'common/vehicle-classes';
import { VehicleClass } from 'graphql.g';
import React, { useState } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import * as strings from 'strings';
import IncludedPercent from './page-trip-filter-included-pct';
import cx from 'classnames';
import "/opt/build/repo/src/page-health/page-trip-filter-row.tsx?resplendence=true";
import { messages as unitMessages, Duration, Distance } from 'common/units';
import {
    mdsDistanceInUnits,
    mdsDurationInUnits
} from './page-trip-filter-common';

/*
@import 'style';
*/;

const DETAILS_BUTTON = "rx-page-health-page-trip-filter-row-1"/*
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    position: relative;
    height: 74rem;
    margin: -2rem 0 -2rem -12rem;
    white-space: nowrap;
    > .arrow {
        align-self: stretch;
        width: 40rem;
        height: 100%;
        border-radius: 8rem 0 0 0;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 4rem;
        svg {
            width: 32rem;
            height: 32rem;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 0!important;
        }
    }
    &:disabled > .arrow svg {
        color: $gray-30;
    }
    &:hover:not(:disabled) > .arrow {
        svg {
            border-radius: 50%;
            background-color: $gray-10;
        }
    }
    &:hover:not(:disabled).expanded > .arrow {
        background-color: $gray-10;
    }
*/;

const VALUE = "rx-page-health-page-trip-filter-row-2"/*
    white-space: nowrap;
*/;

const SUB_ROW_OPERATOR = "rx-page-health-page-trip-filter-row-3"/*
    text-align: right!important;
    padding-right: 64rem!important;
*/;

export type TripFilterRowProps = {
    minDistance: null | number;
    maxDistance: null | number;
    minDuration: null | number;
    maxDuration: null | number;
    vehicleClass: VehicleClass;
    excludedPercent: number | 'loading' | Error | null;
    excludedPercentByOperator:
        | null
        | {
              operator: { id: string; name: string };
              excludedPercent: number;
          }[];
};

const messages = defineMessages({
    'no-min': 'No min',
    'no-caps': 'No caps',
    'expand-button': '{vehicleType}. Click to show breakdown by operator.',
    'operator-label': '{vehicleType} operator'
});

type ValueProps = Duration | Distance;

export function Value({ value, unit }: ValueProps) {
    const intl = useIntl();
    return (
        <span className={VALUE}>
            <FormattedMessage
                key="value"
                defaultMessage="{value, number}{unitAbbreviation}"
                values={{
                    value,
                    unitAbbreviation: intl.formatMessage(
                        unitMessages[`${unit}-short`]
                    )
                }}
            />
        </span>
    );
}

function NoMin() {
    const intl = useIntl();
    return (
        <span className={VALUE}>{intl.formatMessage(messages['no-min'])}</span>
    );
}

function NoCaps() {
    const intl = useIntl();
    return (
        <span className={VALUE}>{intl.formatMessage(messages['no-caps'])}</span>
    );
}

function TripFilterRow({
    vehicleClass,
    minDistance,
    minDuration,
    maxDuration,
    maxDistance,
    excludedPercent,
    excludedPercentByOperator
}: TripFilterRowProps) {
    const intl = useIntl();
    const [expanded, setExpanded] = useState(false);
    const labelId = `page-trip-filter-row-${vehicleClass}`;
    const formattedVehicleClass = intl.formatMessage(
        strings.vehicleClasses[vehicleClass]
    );
    return (
        <tbody className={cx({ expanded })}>
            <tr>
                <th>
                    <button
                        disabled={
                            excludedPercentByOperator === null ||
                            excludedPercentByOperator.length === 0
                        }
                        className={cx(DETAILS_BUTTON, { expanded })}
                        onClick={() => setExpanded(value => !value)}
                        aria-label={intl.formatMessage(
                            messages['expand-button'],
                            { vehicleType: formattedVehicleClass }
                        )}
                    >
                        <div className="arrow">
                            <Icon icon={expanded ? 'Up' : 'Down'} />
                        </div>
                        <Icon icon={ICON_FOR_VEHICLE_CLASS[vehicleClass]} />
                        <span id={labelId}>{formattedVehicleClass}</span>
                    </button>
                </th>
                <td>
                    {minDistance !== null ? (
                        <Value {...mdsDistanceInUnits(minDistance)} />
                    ) : (
                        <NoMin />
                    )}{' '}
                    – 
                    {maxDistance !== null ? (
                        <Value {...mdsDistanceInUnits(maxDistance)} />
                    ) : (
                        <NoCaps />
                    )}
                </td>
                <td>
                    {minDuration !== null ? (
                        <Value {...mdsDurationInUnits(minDuration)} />
                    ) : (
                        <NoMin />
                    )}{' '}
                    – 
                    {maxDuration !== null ? (
                        <Value {...mdsDurationInUnits(maxDuration)} />
                    ) : (
                        <NoCaps />
                    )}
                </td>
                <td>
                    <IncludedPercent value={excludedPercent} />
                </td>
            </tr>
            {expanded &&
                excludedPercentByOperator?.map(row => (
                    <tr key={row.operator.id}>
                        <th
                            colSpan={3}
                            className={SUB_ROW_OPERATOR}
                            aria-label={intl.formatMessage(
                                messages['operator-label'],
                                { vehicleType: formattedVehicleClass }
                            )}
                        >
                            {row.operator.name}
                        </th>
                        <td>
                            <IncludedPercent value={row.excludedPercent} />
                        </td>
                    </tr>
                ))}
        </tbody>
    );
}

export default TripFilterRow;
