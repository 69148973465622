import { OperatorName, OPERATOR_NAMES } from 'constants/operators';
import DATA_COLORS from 'constants/data-colors.json';
import React from 'react';
import {
    CartesianGrid,
    XAxis,
    YAxis,
    Tooltip,
    BarChart as BarChartContainer,
    Bar,
    Cell
} from 'recharts';
import { getOperatorColorStyle } from 'utils/helpers';
import { useIntl } from 'utils/use-intl';
import {
    AnalyzeTooltip,
    AnalyzeChartWrapper,
    formatTickOnYAxis,
    getOpacity
} from './chart-util';
import { AnalyzeData } from './types';
import { IntlShape } from 'react-intl';
import {
    ANIMATION_DURATION,
    AXIS_LABELS,
    CHART_MESSAGES
} from 'common/common-chart';

type Props = {
    analyzeData: AnalyzeData;
};

const formatTickOnXAxis = (
    tick: string,
    xUnit: string,
    intl: IntlShape
): string => {
    if (xUnit === 'hour') {
        let hour = Number(tick);
        if (hour % 12 === 0 || tick === '23') {
            return `${intl.formatTime(new Date().setHours(hour), {
                hour: 'numeric'
            })}`;
        } else if (hour > 12) {
            return (hour - 12).toString();
        } else {
            return hour.toString();
        }
    } else if (xUnit === 'date') {
        return tick;
    } else if (xUnit === 'weekday') {
        // choose an arbitrary date which we know is a sunday and then modify from there
        let date = new Date(2022, 3, 10 + parseInt(tick, 10));
        return intl.formatDate(date, { weekday: 'long' });
    } else {
        return `${tick} ${intl.formatMessage(
            CHART_MESSAGES[`${xUnit}-abbreviation`]
        )}`;
    }
};

const BarChart = ({ analyzeData }: Props) => {
    const intl = useIntl();

    if (!analyzeData) return null;
    const chartData = analyzeData.dataLine.dataPoints;

    const name = analyzeData.dataLine.group as OperatorName;

    const { xUnit, yUnit } = analyzeData;

    const fill = OPERATOR_NAMES.includes(name)
        ? getOperatorColorStyle(name).backgroundColor
        : DATA_COLORS[0].background;

    const yMaxValue = analyzeData.dataLine.dataPoints.reduce(
        (max, d) => Math.max(max, d.yValue ?? 0),
        0
    );

    return (
        <AnalyzeChartWrapper>
            <BarChartContainer data={chartData}>
                <CartesianGrid stroke="#ebeef0" vertical={false} />
                <XAxis
                    dataKey="xValue"
                    axisLine={false}
                    tickLine={false}
                    padding={{ right: 50 }}
                    interval={0}
                    style={AXIS_LABELS}
                    tickFormatter={tick => formatTickOnXAxis(tick, xUnit, intl)}
                />
                <YAxis
                    orientation="right"
                    axisLine={false}
                    tickLine={false}
                    domain={[0, 'auto']}
                    tickFormatter={(tick, index) =>
                        formatTickOnYAxis(tick, index, yUnit, intl)
                    }
                    dy={-15}
                    padding={{ top: 50 }}
                    mirror
                    style={AXIS_LABELS}
                />
                <Tooltip<number, string>
                    label={yUnit}
                    isAnimationActive={false}
                    content={({ active, payload }) =>
                        active &&
                        payload && (
                            <AnalyzeTooltip
                                active={active}
                                payload={payload}
                                unitsShown={[yUnit]}
                            />
                        )
                    }
                />
                <Bar
                    animationDuration={ANIMATION_DURATION}
                    dataKey="yValue"
                    radius={[3, 3, 0, 0]}
                    fill={fill}
                    name={name}
                    unit={yUnit}
                >
                    {analyzeData?.dataLine.dataPoints.map(entry => (
                        <Cell
                            key={`cell-${entry.xValue}`}
                            fillOpacity={getOpacity(yMaxValue, entry.yValue)}
                        />
                    ))}
                </Bar>
            </BarChartContainer>
        </AnalyzeChartWrapper>
    );
};

export default BarChart;
