import React from 'react';
import "/opt/build/repo/src/common/title.tsx?resplendence=true";

/*
@import 'style';
*/;

const HEADER = "rx-common-title-1"/*
    color: $gray-90;
    @include subhead;
    display: flex;
    flex-flow: row wrap;
    align-items: flex-end;
    justify-content: space-between;
    justify-items: end;
    margin-right: -24rem;
*/;
const SUBTITLE = "rx-common-title-2"/*
    color: $gray-70;
    @include subhead;
*/;

const TITLE = "rx-common-title-3"/*
    @include headline-3;
    margin-top: 4rem;
*/;

const TEXT = "rx-common-title-4"/*
    align-self: start;
    padding-bottom: 18rem;
    padding-right: 24rem;
*/;

const CHILDREN = "rx-common-title-5"/*
    position: relative;
    flex: 1 0 auto;
    display: flex;
    flex-flow: row;
    padding-right: 24rem;
    margin-right: -8rem;
    > * {
        margin-right: 8rem;
    }
    justify-content: flex-end;
*/;

type TitleProps = {
    /** Subtitle to be rendered above the title. */
    subtitle?: React.ReactNode;
    /** Big title for what we're currently looking at. */
    title: React.ReactNode;
    /** A space for buttons at the top of the content. */
    children?: React.ReactNode;
};

/** A greeting at the top of each page. */
export function Title({ subtitle, title, children }: TitleProps) {
    return (
        <header className={HEADER}>
            <div className={TEXT}>
                <h1 className={TITLE}>{title}</h1>
                <h2 className={SUBTITLE}>{subtitle}</h2>
            </div>
            <div className={CHILDREN}>{children}</div>
        </header>
    );
}
