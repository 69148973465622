import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ModePicker from '../../common/mode-picker';
import { OVERLAY } from '../../common/overlay';
import { MapOptionsAction, MapOptions } from 'page-explore';
import { Map } from 'mapbox-gl';

import { useReloadingData } from 'utils/use-data';
import {
    CurbEventsArgs,
    CurbEventsData,
    EventLocationsDataType,
    VehicleClass
} from 'graphql.g';
import { useMapView } from 'common/use-map-view';
import useLastReportDate from 'common/use-last-report-date';
import Sidebar from '../../common/sidebar/sidebar';
import SidebarCustomDatasets from '../../common/sidebar/custom-datasets';
import {
    useVehicleClass,
    useVehicleClasses,
    VehicleClassQueryStatePicker
} from 'common/vehicle-classes';
import { SELECT_WRAPPER } from '../routes';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { Select } from 'common/select';
import { useQueryState } from 'router';
import { useEarliestReportDate } from 'page-analyze';
import { DateRange, DateRangeSelect } from 'common/date-picker';
import useDateRangeQuery from 'utils/use-date-range-query';
import "/opt/build/repo/src/page-explore/page-heatmaps/curb-events/index.tsx?resplendence=true";
import { getCurbEventsSource } from './sources';
import { getCurbEventsLayers } from './layers';
import sampleSize from 'lodash/sampleSize';
import SidebarSection from 'page-explore/common/sidebar/sidebar-section';
import { Button } from 'common/layout';
import { downloadCurbEvents, CURB_EVENTS_QUERY } from './api';
import { useApolloClient } from '@apollo/client';

enum CurbEventDisplayStyle {
    Heatmap = 'heatmap',
    Cluster = 'cluster'
}

/*
    @import 'style';
*/;

const PRIVACY_WARNING = "rx-page-explore-page-heatmaps-curb-events-index-1"/*
    @include text-body-tiny;
    height: 19.2rem;
    color: $red-70;
*/;

const m = defineMessages({
    trip_starts: 'Trip Starts',
    trip_ends: 'Trip Ends',
    deployments: 'Deployments',
    operator_placeholder: 'All operators'
});

type Props = {
    map: Map | null;
    mapOptions: MapOptions;
    dispatchMapOptions: React.Dispatch<MapOptionsAction>;
};

const CurbEvents = ({ map, mapOptions, dispatchMapOptions }: Props) => {
    const apolloClient = useApolloClient();
    const [isDownloading, setIsDownloading] = useState(false);
    const { slug, operators } = useMapView();

    const earliestReportDate = useEarliestReportDate();
    const lastReportDate = useLastReportDate();

    const [dataPeriod, setDataPeriod] = useDateRangeQuery([
        lastReportDate.minusDays(30),
        lastReportDate
    ]);

    const dateRangeTooSmall = dataPeriod[0].plusDays(6).isAfter(dataPeriod[1]);

    const availableVehicleClasses = useVehicleClasses();

    const vehicleClass = useVehicleClass();

    const [dataType, setDataType] = useQueryState<EventLocationsDataType>(
        'eventType',
        {
            constrain: {
                options: new Set(Object.values(EventLocationsDataType)),
                fallbackValue: EventLocationsDataType.trip_ends
            }
        }
    );

    const [operatorSlug, setOperatorSlug] = useQueryState<string | null>(
        'operator',
        {
            constrain: {
                options: new Set(operators.map(operator => operator.info.slug)),
                fallbackValue: null
            }
        }
    );

    const [displayStyle, setDisplayStyle] = useQueryState<
        CurbEventDisplayStyle
    >('display', {
        constrain: {
            options: new Set(Object.values(CurbEventDisplayStyle)),
            fallbackValue: CurbEventDisplayStyle.Cluster
        }
    });

    const selectedOperator =
        operators.find(operator => operator.info.slug === operatorSlug) || null;

    const intl = useIntl();

    const handleDownloadCurbEvents = useCallback(() => {
        setIsDownloading(true);
        downloadCurbEvents({
            apolloClient,
            slug,
            dataPeriod,
            vehicleClass,
            dataType,
            operator: selectedOperator?.operatorId ?? null
        }).then(() => setIsDownloading(false));
    }, [
        apolloClient,
        slug,
        dataPeriod,
        vehicleClass,
        dataType,
        selectedOperator
    ]);

    return (
        <div className={OVERLAY}>
            <ModePicker
                options={mapOptions}
                dispatchMapOptions={dispatchMapOptions}
            >
                {!!availableVehicleClasses?.length && (
                    <div className={SELECT_WRAPPER}>
                        <label>
                            <FormattedMessage
                                key="select-vehicle-type-label"
                                defaultMessage="Vehicle Type"
                            />
                        </label>
                        <VehicleClassQueryStatePicker
                            vehicleClasses={availableVehicleClasses}
                        />
                    </div>
                )}
                <div className={SELECT_WRAPPER}>
                    <label>
                        <FormattedMessage
                            key="select-date-label"
                            defaultMessage="Data Period"
                        />
                    </label>
                    <DateRangeSelect
                        selectedDateRange={dataPeriod}
                        onChange={value => setDataPeriod(value)}
                        latestAllowed={lastReportDate}
                        earliestAllowed={
                            earliestReportDate ? earliestReportDate : undefined
                        }
                    />

                    <span className={PRIVACY_WARNING}>
                        {dateRangeTooSmall && (
                            <FormattedMessage
                                key="date-range-too-small"
                                defaultMessage="Please select at least a week of data."
                            />
                        )}
                    </span>
                </div>
                <div className={SELECT_WRAPPER}>
                    <label>
                        <FormattedMessage
                            key="select-event-type-label"
                            defaultMessage="Curb Event Type"
                        />
                    </label>
                    <Select
                        options={Object.values(
                            EventLocationsDataType
                        ).map(dataType => ({ value: dataType }))}
                        getOptionLabel={option =>
                            intl.formatMessage(m[option.value])
                        }
                        value={{ value: dataType }}
                        onChange={option =>
                            option && setDataType(option?.value)
                        }
                    />
                </div>
                <div className={SELECT_WRAPPER}>
                    <label>
                        <FormattedMessage
                            key="select-operator-type-label"
                            defaultMessage="Operators"
                        />
                    </label>
                    <Select
                        value={
                            selectedOperator
                                ? {
                                      value: selectedOperator.info.slug,
                                      label: selectedOperator.name
                                  }
                                : {
                                      value: null,
                                      label: intl.formatMessage(
                                          m['operator_placeholder']
                                      )
                                  }
                        }
                        options={[
                            {
                                value: null,
                                label: intl.formatMessage(
                                    m['operator_placeholder']
                                )
                            },
                            ...operators.map(op => ({
                                value: op.info.slug,
                                label: op.name
                            }))
                        ]}
                        onChange={option =>
                            setOperatorSlug(option?.value ?? null)
                        }
                    />
                </div>
            </ModePicker>
            <Sidebar
                mapOptions={mapOptions}
                dispatchMapOptions={dispatchMapOptions}
                extraToggles={[
                    {
                        header: (
                            <FormattedMessage
                                key="data-view"
                                defaultMessage="Data View"
                            />
                        ),
                        options: [
                            {
                                id: CurbEventDisplayStyle.Cluster,
                                label: (
                                    <FormattedMessage
                                        key="show-as-clusters"
                                        defaultMessage="Cluster"
                                    />
                                ),
                                icon: 'Points'
                            },
                            {
                                id: CurbEventDisplayStyle.Heatmap,
                                label: (
                                    <FormattedMessage
                                        key="show-as-heatmap"
                                        defaultMessage="Heatmap"
                                    />
                                ),
                                icon: 'Heatmap'
                            }
                        ],
                        onChange: (value: CurbEventDisplayStyle) =>
                            setDisplayStyle(value),
                        checkedId: displayStyle
                    }
                ]}
            >
                <SidebarCustomDatasets />

                <SidebarSection
                    title={
                        <FormattedMessage
                            key="export"
                            defaultMessage="Export"
                        />
                    }
                >
                    <Button
                        color="blue"
                        icon="Download"
                        center={true}
                        onClick={handleDownloadCurbEvents}
                        loading={isDownloading}
                    >
                        <FormattedMessage
                            key="export-curb-events"
                            defaultMessage="Export Data"
                        />
                    </Button>
                </SidebarSection>
            </Sidebar>
            {!dateRangeTooSmall && (
                <MapHelper
                    slug={slug}
                    dataPeriod={dataPeriod}
                    vehicleClass={vehicleClass}
                    dataType={dataType}
                    operator={selectedOperator?.operatorId ?? null}
                    dispatchMapOptions={dispatchMapOptions}
                    map={map}
                    displayStyle={displayStyle}
                />
            )}
        </div>
    );
};

type MapHelperProps = {
    slug: string;
    dataPeriod: DateRange;
    vehicleClass: VehicleClass | null;
    dataType: EventLocationsDataType;
    operator: string | null;
    dispatchMapOptions: React.Dispatch<MapOptionsAction>;
    map: Map | null;
    displayStyle: CurbEventDisplayStyle;
};

const MapHelper = ({
    slug,
    dataPeriod,
    vehicleClass,
    dataType,
    operator,
    dispatchMapOptions,
    map,
    displayStyle
}: MapHelperProps) => {
    const [data, loading] = useReloadingData<CurbEventsData, CurbEventsArgs>(
        CURB_EVENTS_QUERY,
        {
            slug,
            dates: [dataPeriod[0].toString(), dataPeriod[1].toString()],
            vehicleClass: vehicleClass,
            dataType,
            operatorId: operator
        },
        // The apollo cache crashes the app when we request over 1,000,000 records.
        { fetchPolicy: 'no-cache' }
    );

    // For performance reasons, we serialize event locations as a JSON string
    const curbEvents = useMemo(() => {
        return data?.mapView.eventLocationsJson
            ? JSON.parse(data.mapView.eventLocationsJson)
            : [];
    }, [data]);

    // Mapbox crashes if we attempt to display more than 500,000 points as a heatmap. It
    // also crashes if we display fewer points and the user toggles frequently between
    // heatmap and cluster display. As a temporary fix, we sample the points displayed on the
    // heatmap at a level that does not (1) outright crash the page and (2) crash the
    // page at a reasonable threshold of user interactions for < 1,000,000 original points.
    // See this ticket for a discussion of the issues: https://github.com/RideReport/tickets/issues/4674
    const heatmapSampleMax = 200000;
    const heatmapCurbEvents = useMemo(() => {
        if (curbEvents.length > heatmapSampleMax) {
            return sampleSize(curbEvents, heatmapSampleMax);
        }
        return curbEvents;
    }, [curbEvents]);

    useEffect(() => {
        if (loading) {
            dispatchMapOptions({ type: 'set loading data', value: true });
        }

        if (map && !loading && curbEvents && heatmapCurbEvents) {
            const sourceId = 'curb-events';
            const isClustered = displayStyle === CurbEventDisplayStyle.Cluster;
            const sources = {
                [sourceId]: getCurbEventsSource(
                    isClustered ? curbEvents : heatmapCurbEvents,
                    isClustered
                )
            };
            const layers = getCurbEventsLayers(
                sourceId,
                isClustered,
                isClustered ? curbEvents.length : heatmapCurbEvents.length
            );

            dispatchMapOptions({
                type: 'set sources',
                payload: { dataType: 'curbEvents', sources }
            });
            dispatchMapOptions({
                type: 'set layers',
                payload: { dataType: 'curbEvents', layers }
            });
            dispatchMapOptions({ type: 'set loading data', value: false });
        }

        return () => {
            dispatchMapOptions({
                type: 'set layers',
                payload: { dataType: 'curbEvents', layers: [] }
            });
            dispatchMapOptions({
                type: 'set sources',
                payload: { dataType: 'curbEvents', sources: {} }
            });
            dispatchMapOptions({ type: 'set loading data', value: false });
        };
    }, [
        dispatchMapOptions,
        map,
        loading,
        displayStyle,
        curbEvents,
        heatmapCurbEvents
    ]);

    return null;
};

export default CurbEvents;
