import { AnalyzeData, ChartMetric } from './types';
import downloadData from 'utils/download-data';
import { range } from 'utils/helpers';
import { defineMessages, MessageDescriptor, IntlShape } from 'react-intl';
import { AnalyzeDataXUnit } from 'graphql.g';
import { DateRange } from 'common/date-picker';
import orderBy from 'lodash/orderBy';

/**
 * Given some different lines of values-by-date, flattens them into data rows
 * appropriate for a csv.
 *
 *
 * Say we have data like this:
 *
 *   EXISTING               ADDED
 *   ------------------     ------------------
 *   | 2021-01-01 | 1 |
 *   | 2021-01-02 | 2 |     | 2021-01-02 | 1 |
 *                          | 2021-01-03 | 2 |
 *   | 2021-01-04 | 3 |
 *
 * We want to merge them together:
 *
 *   RESULT
 *   ----------------------
 *   | 2021-01-01 | 1 |   |
 *   | 2021-01-02 | 2 | 1 |
 *   | 2021-01-03 |   | 2 |
 *   | 2021-01-04 | 3 |   |
 */
export function buildRows(
    lines: { xValue: string; yValue: number | null }[][]
) {
    if (lines.length === 0) {
        throw new Error('Must pass at least one line to build rows');
    }
    if (lines.length === 1) {
        // If we only have one line of data, no fancy logic is necessary.
        return lines[0].map(({ xValue, yValue }) => [xValue, yValue]);
    }
    // Otherwise, there's more then one line of data, so we add another column
    // for the values from that line. We want to protect against uncommon
    // situations where the two lines have different sets of points. So, we
    // check to make sure that the dates match.
    //
    // This is kind of hard to explain, so we're gonna do some literate
    // programming (https://en.wikipedia.org/wiki/Literate_programming).
    //
    // First we need the final output. We'll start by setting it to only include data
    // from the first line
    const rows: (string | number | null)[][] = orderBy(
        lines[0],
        point => point.xValue
    ).map(({ xValue, yValue }) => [xValue, yValue]);

    // Here's some example values for our "rows" variable and the new line
    // that is to be added. We will iterate over each of them in parallel.
    //
    //      ROWS                       LINE
    //      ----------------------     ------------------
    //      | 2021-01-01 | 1 |         | 2021-01-02 | 1 |
    //      | 2021-01-02 | 2 |         | 2021-01-03 | 2 |
    //      | 2021-01-04 | 3 |
    //

    for (const lineNumber of range(1, lines.length)) {
        const line = orderBy(lines[lineNumber], point => point.xValue);
        // We'll iterate over the rows using this index value:
        let index = 0;

        // and iterate over the added line with a loop:
        for (const { xValue: date, yValue: value } of line) {
            // Starting with our iterators at the beginning, notice how the
            // current row for LINE is for a date later than the current row
            // for ROWS.
            //
            //      ROWS                       LINE
            //      ----------------------     ------------------
            //   -> | 2021-01-01 | 1 |      -> | 2021-01-02 | 1 |
            //      | 2021-01-02 | 2 |         | 2021-01-03 | 2 |
            //      | 2021-01-04 | 3 |
            //
            // As long as this is the case, we add blank values
            // (represented with 'x' in the chart) to these rows to reflect
            // that we don't have new data for this date.
            while (index < rows.length && (rows[index][0] as string) < date) {
                rows[index].push('');
                index += 1;
            }

            //      ROWS                       LINE
            //      ----------------------     ------------------
            //      | 2021-01-01 | 1 | x |  -> | 2021-01-02 | 1 |
            //   -> | 2021-01-02 | 2 |         | 2021-01-03 | 2 |
            //      | 2021-01-04 | 3 |
            //
            // Now our two indexes are pointing at rows with the same date.
            // In this (ideal) case, we can set the appropriate value
            if (index < rows.length && rows[index][0] === date) {
                rows[index].push(value);
            }

            //      ROWS                       LINE
            //      ----------------------     ------------------
            //      | 2021-01-01 | 1 | x |     | 2021-01-02 | 1 |
            //      | 2021-01-02 | 2 | 1 |  -> | 2021-01-03 | 2 |
            //   -> | 2021-01-04 | 3 |
            //
            // The only other possibility is our added line's date is earlier
            // than the current row. In this case, we need to insert a new
            // entry.
            else {
                const newRow: (string | number | null)[] = [date];
                let alreadyAddedLine = 0;

                // That entry should have one blank value for each "column"
                // in our table. In our example, that's one column.
                while (alreadyAddedLine < lineNumber) {
                    newRow.push('');
                    alreadyAddedLine += 1;
                }

                // Our new value goes on the end
                newRow.push(value);

                // And we insert this new row into the current index
                rows.splice(index, 0, newRow);
            }

            index += 1;
        }

        //      ROWS                       LINE
        //      ----------------------     ------------------
        //      | 2021-01-01 | 1 | x |     | 2021-01-02 | 1 |
        //      | 2021-01-02 | 2 | 1 |     | 2021-01-03 | 2 |
        //      | 2021-01-03 | x | 2 |  ->
        //   -> | 2021-01-04 | 3 |
        //
        // Now we've finished iterating over our new line, but there's still
        // rows left. We can insert blank values for all of these.
        for (const row of rows.slice(index)) {
            row.push('');
        }

        // This leaves us with our final result!
        //
        //      ROWS
        //      ----------------------
        //      | 2021-01-01 | 1 | x |
        //      | 2021-01-02 | 2 | 1 |
        //      | 2021-01-03 | x | 2 |
        //      | 2021-01-04 | 3 | x |
    }
    return rows;
}

const messages = defineMessages({
    'column-header-date': 'Date',
    'column-header-miles': 'Miles',
    'column-header-kilometers': 'Kilometers',
    'column-header-hour': 'Hour',
    'column-header-minutes': 'Minutes',
    'column-header-day-hour': 'Day and Hour',
    'column-header-weekday': 'Weekday'
});
const X_UNIT_STRINGS: Record<AnalyzeDataXUnit, MessageDescriptor> = {
    [AnalyzeDataXUnit.date]: messages['column-header-date'],
    [AnalyzeDataXUnit.miles]: messages['column-header-miles'],
    [AnalyzeDataXUnit.kilometers]: messages['column-header-kilometers'],
    [AnalyzeDataXUnit.hour]: messages['column-header-hour'],
    [AnalyzeDataXUnit.minutes]: messages['column-header-minutes'],
    [AnalyzeDataXUnit.day_and_hour]: messages['column-header-day-hour'],
    [AnalyzeDataXUnit.weekday]: messages['column-header-weekday']
};

function handleCsvDownload(
    analyzeData: AnalyzeData[],
    chartLabel: string,
    additionalChartLabel: string | null,
    mainMetricType: ChartMetric,
    showAdditionalLine: boolean,
    dateRange: DateRange,
    intl: IntlShape,
    multiLineLabel: string
) {
    if (analyzeData.length === 0) {
        throw new Error('Must pass at least one line to build rows');
    }
    let metricNames;
    if (!showAdditionalLine) {
        metricNames = mainMetricType;
    } else {
        metricNames = multiLineLabel
            .toLowerCase()
            .split(' ')
            .join('-');
    }

    const filename = `${dateRange[0].toString()}--${dateRange[1].toString()}-analyze-${metricNames}.csv`;
    let headerRow = [
        intl.formatMessage(X_UNIT_STRINGS[analyzeData[0].xUnit]),
        chartLabel
    ];
    if (additionalChartLabel && analyzeData.length > 1) {
        headerRow.push(additionalChartLabel);
    }
    const rows = buildRows(analyzeData.map(data => data.dataLine.dataPoints));
    const data =
        // add the header
        headerRow.join(',') +
        '\n' +
        // add our data rows
        rows.map(row => row?.join(',')).join('\n');
    downloadData(filename, data);
}

export default handleCsvDownload;
