import React, { useMemo } from 'react';
import { Table, Row, HeaderRow, messages, HeaderProps } from './tables';
import statDescriptions from '../constants/stat-descriptions';
import { MapView } from 'types';
import gql from 'graphql-tag';
import Loading from 'common/loading';
import { useReloadingData } from 'utils/use-data';
import { useIntl } from 'utils/use-intl';
import { LocalDate } from 'utils/date-tools';
import {
    ReportMaxVehiclesFragment,
    ReportTripCountsFragment,
    ReportTripsPerVehicleFragment
} from './fragments';
import { AllOperatorsMdsDataData, AllOperatorsMdsDataArgs } from 'graphql.g';
import { useVehicleClass } from 'common/vehicle-classes';
import { doesReportHaveOperatorName } from './helpers';
import { ContainerMessage } from 'common/layout';
import { FormattedMessage } from 'react-intl';
import orderBy from 'lodash/orderBy';
import zipWith from 'lodash/zipWith';
import { useSorting } from 'common/table-sorting';

const headerInfo = [
    statDescriptions.startTrips,
    statDescriptions.tripsPerVehicle
];
const tripSortKeys = [
    'tripCounts.startCount',
    'tripsPerVehicleMetric.tripsPerVehicle'
];
const headers: HeaderProps[] = zipWith(headerInfo, tripSortKeys, (a, b) => {
    return [a, b];
});

type Props = {
    mapView: MapView;
    reportDate: LocalDate;
};

const QUERY = gql`
    query AllOperatorsMdsData(
        $slug: String
        $date: Date!
        $vehicleClass: VehicleClass
    ) {
        mapView(slug: $slug) {
            id
            overviewReport(date: $date, vehicleClass: $vehicleClass) {
                id
                reportDate
                reports {
                    id
                    operator {
                        name
                    }
                    ...ReportTripCounts
                    ...ReportTripsPerVehicle
                    ...ReportMaxVehicles
                }
            }
        }
    }
    ${ReportTripCountsFragment}
    ${ReportTripsPerVehicleFragment}
    ${ReportMaxVehiclesFragment}
`;

function PageDailySummaryTrips({ mapView, reportDate }: Props) {
    const vehicleClass = useVehicleClass();
    const [data, loading] = useReloadingData<
        AllOperatorsMdsDataData,
        AllOperatorsMdsDataArgs
    >(QUERY, {
        slug: mapView.slug,
        date: reportDate.toString(),
        vehicleClass
    });
    const intl = useIntl();
    const [sorting, onSortHeaderClick] = useSorting<
        | 'operator.name'
        | 'tripCounts.startCount'
        | 'tripsPerVehicleMetric.tripsPerVehicle'
    >('operator.name');

    const mapViewData = data && data.mapView;
    const allReports = useMemo(() => {
        const list =
            mapViewData && mapViewData.overviewReport
                ? mapViewData.overviewReport.reports
                : [];
        return orderBy(list, [...sorting.keys()], [...sorting.values()]);
    }, [sorting, mapViewData]);
    const totalTripCountForAllOperators =
        allReports?.reduce(
            (value, op) => value + (op?.tripCounts?.startCount ?? 0),
            0
        ) ?? 0;

    const totalMaxAvailableForAllOperators =
        allReports?.reduce(
            (value, op) => value + (op?.maxVehicleMetric?.maxAvailable ?? 0),
            0
        ) ?? 0;

    const totalTripsPerVehicle =
        totalTripCountForAllOperators / totalMaxAvailableForAllOperators ?? 0;

    return (
        <>
            <Loading loading={loading} kind="over-table" />
            {allReports && allReports.length !== 0 ? (
                <Table>
                    <HeaderRow
                        headers={headers}
                        sorting={sorting}
                        onSortHeaderClick={onSortHeaderClick}
                    />
                    <tbody>
                        {allReports
                            .filter(doesReportHaveOperatorName)
                            .map(report => {
                                if (report == null) {
                                    return null;
                                }
                                const name =
                                    report.operator && report.operator.name;
                                const id = report.id;
                                const values = [
                                    report?.tripCounts?.startCount ?? null,
                                    (report?.tripsPerVehicleMetric
                                        ?.tripsPerVehicle &&
                                        intl.formatNumber(
                                            report?.tripsPerVehicleMetric
                                                .tripsPerVehicle,
                                            {
                                                maximumFractionDigits: 1
                                            }
                                        )) ??
                                        null
                                ];
                                return (
                                    <Row key={id} name={name} values={values} />
                                );
                            })}
                        {/* Copy this line to create Totals for each table */}
                        <Row
                            name={intl.formatMessage(messages['total-row'])}
                            values={[
                                intl.formatNumber(
                                    allReports.reduce(
                                        (value, op) =>
                                            value +
                                            (op?.tripCounts?.startCount ?? 0),
                                        0
                                    )
                                ),
                                intl.formatNumber(totalTripsPerVehicle, {
                                    maximumFractionDigits: 1
                                })
                            ]}
                        />
                    </tbody>
                </Table>
            ) : (
                <ContainerMessage>
                    <FormattedMessage
                        key="no-trip-metrics"
                        defaultMessage="This report doesn't have any trip metrics."
                    />
                </ContainerMessage>
            )}
        </>
    );
}

export default PageDailySummaryTrips;
