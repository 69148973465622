import React from 'react';
import { Container, ContainerHeader, Button } from 'common/layout';
import {
    defineMessages,
    FormattedMessage,
    FormattedNumber,
    useIntl
} from 'react-intl';
import { LocalDate } from 'utils/date-tools';
import { Link } from 'router';
import "/opt/build/repo/src/page-fees/page-details.tsx?resplendence=true";
import Currency from 'common/currency';
import { useMapView } from 'common/use-map-view';
import Loading from 'common/loading';
import gql from 'graphql-tag';
import useData from 'utils/use-data';
import { RouteComponentProps } from 'react-router';
import { InvoiceTotalData, InvoiceTotalArgs } from 'graphql.g';
import { PageNotFoundString, PAGE_NOT_FOUND } from 'strings';
import DownloadButton from 'common/download-button';
import { Title } from 'common/title';
import useDocumentTitle from 'common/use-document-title';
import { sendEventAnalytics } from 'utils/use-analytics';

/*
@import 'style.scss';
*/;

const TABLE = "rx-page-fees-page-details-1"/*
    @include table;
    tfoot {
        td {
            @include text-body-bold;
            padding: 40rem 8rem 16rem 8rem;
        }
        th {
            @include text-body;
            padding: 40rem 8rem 16rem 8rem;
            text-align: left;
        }
    }
*/;

const LINK = "rx-page-fees-page-details-2"/*
    @include text-body-link-underlined;
    color: $blue-50;
*/;

const TOTAL = "rx-page-fees-page-details-3"/*
    @include text-body-bold;
    padding-top: 40rem;
*/;

const INVOICE_QUERY = gql`
    query InvoiceTotal($slug: String!, $date: Date!, $operatorSlug: String!) {
        mapView(slug: $slug) {
            id
            invoice(date: $date, operatorSlug: $operatorSlug) {
                id
                __typename
                operator {
                    name
                    id
                }
                ... on IssuedInvoice {
                    total
                    fees {
                        unitCount
                        amount
                        rule {
                            name
                            rateAmount
                        }
                    }
                }
            }
        }
    }
`;

function useInvoice(date: LocalDate, operatorSlug: string) {
    const { slug } = useMapView();
    const data = useData<InvoiceTotalData, InvoiceTotalArgs>(INVOICE_QUERY, {
        slug,
        date: date.toString(),
        operatorSlug
    })?.mapView;
    if (!data) return null;
    if (!data.invoice) {
        return 'not_found';
    }
    if (data.invoice.__typename !== 'IssuedInvoice') {
        return 'not_found';
    }

    return data.invoice;
}

type DetailsPageProps = RouteComponentProps<{
    date: string;
    operator: string;
}>;

const m = defineMessages({
    'document-title': '{operator} Fees - {month, date, reportmonth}',
    'invoice-not-found': 'Invoice not found'
});

function DetailsPage({ match }: DetailsPageProps) {
    const mapView = useMapView();
    const startDate = LocalDate.fromDateString(match.params.date);
    const intl = useIntl();
    const invoice = useInvoice(
        LocalDate.fromDateString(match.params.date),
        match.params.operator
    );

    const title =
        invoice !== 'not_found'
            ? intl.formatMessage(m['document-title'], {
                  operator: invoice?.operator.name,
                  month: startDate.toDate()
              })
            : PageNotFoundString();

    useDocumentTitle(title);

    if (!invoice) return <Loading />;
    if (invoice === 'not_found') return <Title title={PAGE_NOT_FOUND}></Title>;
    return (
        <>
            <Title
                title={
                    <FormattedMessage
                        key="title"
                        defaultMessage="{operator}, {month, date, reportmonth}"
                        values={{
                            operator: invoice.operator.name,
                            month: startDate.toDate()
                        }}
                    />
                }
                subtitle={
                    <FormattedMessage
                        key="subtitle"
                        defaultMessage="Fees breakdown"
                    />
                }
            >
                <Button
                    color="blue"
                    icon="Print"
                    onClick={() => {
                        window.print();
                        sendEventAnalytics({
                            event_name: 'data export event',
                            name: `Invoice ${
                                invoice?.operator.name
                            } -  ${startDate.toLocalMonth()}`,
                            action: 'printed'
                        });
                    }}
                >
                    <FormattedMessage
                        key="print-button"
                        defaultMessage="Print"
                    />
                </Button>
                <DownloadButton
                    icon="Spreadsheet"
                    path="invoice-audit"
                    params={{ invoice_id: invoice.id }}
                    filename={`${mapView.slug}_invoice_${match.params.operator}_${match.params.date}_audit.zip`}
                    failureReason="download-failure-temporary"
                >
                    <FormattedMessage
                        key="export-data-button"
                        defaultMessage="Export Data"
                    />
                </DownloadButton>
            </Title>
            <Container>
                <ContainerHeader el="nav">
                    <Link to="../" className={LINK}>
                        <FormattedMessage
                            key="back-link"
                            defaultMessage="← Back to Program Fees"
                        />
                    </Link>
                </ContainerHeader>
                <>
                    <table className={TABLE}>
                        <thead>
                            <tr>
                                <th>
                                    <FormattedMessage
                                        key="table-head-type"
                                        defaultMessage="Fee Type"
                                    />
                                </th>
                                <th>
                                    <FormattedMessage
                                        key="table-head-count"
                                        defaultMessage="Unit Count"
                                    />
                                </th>
                                <th>
                                    <FormattedMessage
                                        key="table-head-price"
                                        defaultMessage="Unit Price"
                                    />
                                </th>
                                <th>
                                    <FormattedMessage
                                        key="table-head-amount"
                                        defaultMessage="Amount"
                                    />
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {invoice.fees.map(fee => (
                                <tr key={fee.rule.name}>
                                    <td>{fee.rule.name}</td>
                                    <td>
                                        <FormattedNumber
                                            value={fee.unitCount}
                                        />
                                    </td>
                                    <td>
                                        <Currency value={fee.rule.rateAmount} />
                                    </td>
                                    <td>
                                        <Currency value={fee.amount} />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                        <tfoot>
                            <tr>
                                <td />
                                <td />
                                <td>
                                    <FormattedMessage
                                        key="total-label"
                                        defaultMessage="Total Fees"
                                    />
                                </td>
                                <td className={TOTAL}>
                                    <Currency value={invoice.total} />
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </>
            </Container>
        </>
    );
}

export default DetailsPage;
