import { useMemo } from 'react';
import { gql } from '@apollo/client';
import { useMapView } from 'common/use-map-view';
import {
    StreetSegmentsData,
    StreetSegmentsArgs,
    AggregatedTripResultsData,
    AggregatedTripResultsArgs,
    VehicleClass,
    AvailableRoutesDatesData,
    AvailableRoutesDatesArgs
} from 'graphql.g';
import useData, { useReloadingData } from 'utils/use-data';

const STREET_SEGMENT_QUERY = gql`
    query StreetSegments($slug: String) {
        mapView(slug: $slug) {
            id
            streetSegmentsJson
        }
    }
`;

interface StreetSegment {
    name: string;
    geometry: GeoJSON.LineString;
    road_class: string | null;
}

export interface StreetSegments {
    [geometryId: string]: StreetSegment;
}

/**
 * Fetches all of the street segments for the current mapview.
 * Segments are referenced by their geometryId, and contain GeoJSON LineStrings
 */
export function useStreetSegments() {
    const { slug } = useMapView();
    const streetSegments = useData<StreetSegmentsData, StreetSegmentsArgs>(
        STREET_SEGMENT_QUERY,
        { slug }
    );

    const parsedStreetSegments = useMemo(
        () =>
            streetSegments
                ? (JSON.parse(
                      streetSegments.mapView.streetSegmentsJson
                  ) as StreetSegments)
                : null,
        [streetSegments]
    );

    return parsedStreetSegments;
}

const AGGREGATED_TRIP_ROUTES_QUERY = gql`
    query AggregatedTripResults(
        $slug: String!
        $startDate: Date!
        $endDate: Date!
        $hours: [Int!]
        $vehicleClass: VehicleClass
    ) {
        mapView(slug: $slug) {
            id
            aggregatedTripRoutes(
                startDate: $startDate
                endDate: $endDate
                hours: $hours
                vehicleClass: $vehicleClass
            ) {
                referenceCountData
                totalTripCount
                matchedTripCount
            }
        }
    }
`;

export interface ReferenceCounts {
    [geometryId: string]: {
        /** Numeric count of trips that matched for this segment */
        count: number;
        /**
         * Percentage of trips that matched for this segment,
         * out of the total matched count. Ranges from 0.1 to 100
         * For example: 11.4%, 2.9%, or 0.1% */
        percentageMatched: number;
    };
}

export interface RoutesData {
    referenceCounts: ReferenceCounts;
    totalTripCount: number;
    matchedTripCount: number;
}

interface RoutesParams {
    startDate: string;
    endDate: string;
    hours?: number[] | null;
    vehicleClass?: VehicleClass | null;
}

/**
 * Fetches all of the aggregated trip results for the current mapview.
 * Filters based on the supplied arguments, and parses the data before
 * returning it.
 */
export function useRoutes(args: RoutesParams): [RoutesData | null, boolean] {
    const { slug } = useMapView();

    const [aggregatedTripRoutes, isLoading] = useReloadingData<
        AggregatedTripResultsData,
        AggregatedTripResultsArgs
    >(AGGREGATED_TRIP_ROUTES_QUERY, {
        slug,
        startDate: args.startDate,
        endDate: args.endDate,
        hours: args.hours ?? null,
        vehicleClass: args.vehicleClass ?? null
    });

    const parsedAggregatedTripRoutes = useMemo(() => {
        if (aggregatedTripRoutes == null) {
            return null;
        }

        const referenceCounts: ReferenceCounts = JSON.parse(
            aggregatedTripRoutes.mapView.aggregatedTripRoutes.referenceCountData
        );

        const {
            totalTripCount,
            matchedTripCount
        } = aggregatedTripRoutes.mapView.aggregatedTripRoutes;

        return {
            referenceCounts,
            totalTripCount,
            matchedTripCount
        };
    }, [aggregatedTripRoutes]);

    return [parsedAggregatedTripRoutes, isLoading];
}

export const AVAILABLE_ROUTES_DATES_QUERY = gql`
    query AvailableRoutesDates($id: ID!) {
        mapViewById(id: $id) {
            id
            earliestAggregatedTripRouteDate
            latestAggregatedTripRouteDate
        }
    }
`;

/** Returns the earliest and latest dates that routes are available for
 * the current mapview.
 */
export function useRoutesDates() {
    const { id } = useMapView();

    const routesDates = useData<
        AvailableRoutesDatesData,
        AvailableRoutesDatesArgs
    >(AVAILABLE_ROUTES_DATES_QUERY, { id });

    const earliestAllowed =
        routesDates?.mapViewById.earliestAggregatedTripRouteDate ?? null;

    const latestAllowed =
        routesDates?.mapViewById.latestAggregatedTripRouteDate ?? null;

    const isLoading = routesDates == null;

    return [earliestAllowed, latestAllowed, isLoading] as const;
}
