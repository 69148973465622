import React, { forwardRef } from 'react';
import { useRelativePath } from './use-relative-path';
import AbsoluteLink from './absolute-link';
import { useRouteMatch, LinkProps, NavLinkProps } from 'react-router-dom';

type Props = Omit<LinkProps, 'to'> & {
    to: string;
    search?: Record<string, string>;
    hash?: string;
    state?: any;
    nav?: boolean;
    exact?: boolean;
    disabled?: boolean;
    disabledClassName?: string;
    preserveQuery?: boolean;
    isActive?: NavLinkProps['isActive'];
};

/**
 * A react-router link with a destination relative to the current
 * component tree. To go up a level you can use '..'
 *
```
// suppose the component tree currently is at /current/location

// goes to /current/location/somewhere
<Link to="somewhere" />

// goes to /current/reports
<Link to="../reports" />
```
 */
const RelativeLink = forwardRef<any, Props>(
    ({ to, preserveQuery, search, hash, state, ...rest }, ref) => {
        const match = useRouteMatch();
        const path = useRelativePath(
            match ? match.url + (window.location.search ?? '') : '',
            to,
            preserveQuery
        );
        return (
            <AbsoluteLink
                to={
                    search || hash || state
                        ? {
                              pathname: path,
                              search:
                                  search &&
                                  new URLSearchParams(search).toString(),
                              hash,
                              state
                          }
                        : path
                }
                {...rest}
                ref={ref}
            />
        );
    }
);
export default RelativeLink;
