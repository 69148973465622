import { PageContent } from 'common/layout';
import { Tabs, TAB_LINK } from 'common/tabs';
import { Title } from 'common/title';
import { useMapView } from 'common/use-map-view';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { AbsoluteLink } from 'router';

type CommonTitleProps = { children?: React.ReactNode };

export function CommonTitle({ children }: CommonTitleProps) {
    return (
        <Title
            title={
                <FormattedMessage
                    key="feed-health-reports"
                    defaultMessage="Feed Health Reports"
                />
            }
        >
            {children}
        </Title>
    );
}

type Props = {
    wide?: boolean;
    children?: React.ReactNode;
    actions?: React.ReactNode;
};

function CommonPage({ wide, children, actions }: Props) {
    const { slug } = useMapView();
    return (
        <PageContent wide={wide}>
            <CommonTitle>{actions}</CommonTitle>
            <Tabs>
                <AbsoluteLink
                    className={TAB_LINK}
                    to={`/${slug}/health/status`}
                    nav
                >
                    <FormattedMessage
                        key="feed-status-link"
                        defaultMessage="Feed Status"
                    />
                </AbsoluteLink>
                <AbsoluteLink
                    className={TAB_LINK}
                    to={`/${slug}/health/trip-filter`}
                    nav
                >
                    <FormattedMessage
                        key="trip-filter-link"
                        defaultMessage="Trip Filter"
                    />
                </AbsoluteLink>
            </Tabs>
            {children}
        </PageContent>
    );
}

export default CommonPage;
