import React, { useState } from 'react';
import "/opt/build/repo/src/root/page-review-terms.tsx?resplendence=true";
import AppHeader from './app-header';
import { Checkbox, Button } from 'common/layout';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/client';
import ErrorMessage from 'common/error-message';
import { FormattedMessage, useIntl, defineMessages } from 'react-intl';
import { Title } from 'common/title';

/*
@import 'style';
*/;

const CONTAINER = "rx-root-page-review-terms-1"/*
    width: 480rem;
    margin: auto;
    margin-top: 120rem;
*/;

const DESCRIPTION = "rx-root-page-review-terms-2"/*
    @include text-body;
    color: $gray-70;
    margin-top: 24rem;
*/;

const CHECKBOX_CONTAINER = "rx-root-page-review-terms-3"/*
    margin-top: 32rem;
    margin-bottom: 32rem;
*/;

const CHECKBOX_ITEM = "rx-root-page-review-terms-4"/*
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 8rem;
*/;

const LINK = "rx-root-page-review-terms-5"/*
    @extend %link;
    padding-left: 6rem;
    color: $blue-50;
*/;

const ERROR_CONTAINER = "rx-root-page-review-terms-6"/*
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    height: 100%;
*/;

const m = defineMessages({
    'i-agree': 'I agree to the',
    'i-agree-eula': 'I agree to the EULA',
    'i-agree-privacy': 'I agree to the Privacy Policy'
});

export const ACCEPT_TERMS = gql`
    mutation AcceptTerms($hasAcceptedTerms: Boolean!) {
        acceptTerms(hasAcceptedTerms: $hasAcceptedTerms) {
            user {
                id
                hasAcceptedTerms
            }
        }
    }
`;

function ReviewTermsPage() {
    const intl = useIntl();
    const [hasError, setHasError] = useState(false);
    const [agreedToEula, setAgreedToEula] = useState(false);
    const [agreedToPrivacy, setAgreedToPrivacy] = useState(false);

    const [acceptTerms] = useMutation<{ hasAcceptedTerms: boolean }>(
        ACCEPT_TERMS
    );

    const canSubmit = agreedToEula && agreedToPrivacy;

    const handleSubmit = async () => {
        if (!canSubmit) return;

        try {
            // once the user accepts the terms, they'll be shown the page they
            // were viewing. No need to take any action here.
            await acceptTerms({
                variables: { hasAcceptedTerms: true }
            });
        } catch {
            setHasError(true);
        }
    };

    return (
        <>
            <AppHeader />
            <section className={CONTAINER}>
                {hasError ? (
                    <div className={ERROR_CONTAINER}>
                        <ErrorMessage />
                    </div>
                ) : (
                    <>
                        <Title
                            title={
                                <FormattedMessage
                                    key="header"
                                    defaultMessage="Terms and Conditions"
                                />
                            }
                        />
                        <p className={DESCRIPTION}>
                            <FormattedMessage
                                key="body-text"
                                defaultMessage="Please review our terms and conditions before continuing."
                            />
                        </p>
                        <div className={CHECKBOX_CONTAINER}>
                            <div className={CHECKBOX_ITEM}>
                                <Checkbox
                                    checked={agreedToEula}
                                    onChange={() =>
                                        setAgreedToEula(!agreedToEula)
                                    }
                                    label={intl.formatMessage(m['i-agree'])}
                                    ariaLabel={intl.formatMessage(
                                        m['i-agree-eula']
                                    )}
                                />
                                <a
                                    href="/terms/eula"
                                    target="_blank"
                                    rel="noreferrer noopener"
                                    className={LINK}
                                >
                                    <FormattedMessage
                                        key="eula"
                                        defaultMessage="End User License Agreement"
                                    />
                                </a>
                            </div>
                            <div className={CHECKBOX_ITEM}>
                                <Checkbox
                                    checked={agreedToPrivacy}
                                    onChange={() =>
                                        setAgreedToPrivacy(!agreedToPrivacy)
                                    }
                                    label={intl.formatMessage(m['i-agree'])}
                                    ariaLabel={intl.formatMessage(
                                        m['i-agree-privacy']
                                    )}
                                />
                                <a
                                    href="/terms/privacy"
                                    target="_blank"
                                    rel="noreferrer noopener"
                                    className={LINK}
                                >
                                    <FormattedMessage
                                        key="privacy-policy"
                                        defaultMessage="Privacy Policy"
                                    />
                                </a>
                            </div>
                        </div>
                        <Button
                            disabled={!canSubmit}
                            onClick={handleSubmit}
                            color="blue"
                        >
                            <FormattedMessage
                                key="accept-terms"
                                defaultMessage="Accept"
                            />
                        </Button>
                    </>
                )}
            </section>
        </>
    );
}

export default ReviewTermsPage;
