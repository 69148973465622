import { FormEvent } from 'react';
import { gql, useMutation } from '@apollo/client';
import { POLICY_DETAILS_FRAGMENT } from './fragments';
import { CreatePolicyMutationArgs, CreatePolicyResult } from 'graphql.g';
import { useMapView } from 'common/use-map-view';
import { sendEventAnalytics } from 'utils/use-analytics';
import { generatePolicy } from './forms/utils';
import { useHistory } from 'react-router';
import { NewPolicy } from './page-new';
import { defineMessages, useIntl } from 'react-intl';

const messages = defineMessages({
    'municipality-boundary-required':
        "Can't create a policy for this mapview because there is no municipality boundary set."
});

const CREATE_POLICY_MUTATION = gql`
    mutation CreatePolicy($policy: CreatePolicyInput!) {
        createPolicy(policy: $policy) {
            policy {
                ...PolicyDetails
            }
        }
    }
    ${POLICY_DETAILS_FRAGMENT}
`;

function useCreatePolicy(
    policy: NewPolicy,
    formHasErrors: boolean
): [(e: FormEvent<HTMLFormElement>) => Promise<void>, string | null] {
    const mapView = useMapView();
    const history = useHistory();
    const intl = useIntl();
    const [createPolicy, { error }] = useMutation<
        CreatePolicyResult,
        CreatePolicyMutationArgs
    >(CREATE_POLICY_MUTATION, {
        // this pattern of updating the cache was adapted from
        // https://www.apollographql.com/docs/react/caching/cache-interaction/#example-updating-the-cache-after-a-mutation
        //
        // We're first updating the PolicyDetails cache, then adding that
        // new policy to the mapview.policies cache.
        update(cache, { data }) {
            // get the new policy returned from the mutation
            const newPolicy = data?.createPolicy?.policy;
            if (newPolicy == null) return;
            cache.modify({
                // get the id of the mapview in the cache that we want to update
                id: cache.identify(mapView),
                fields: {
                    // take in the existing policy references
                    policies(existingPolicyRefs = [], { toReference }) {
                        // get the reference to the newPolicy in our cache
                        const newPolicyRef = toReference(newPolicy);
                        // build a new array of policy references to store
                        // on the mapview policies
                        return [...existingPolicyRefs, newPolicyRef];
                    }
                }
            });
        }
    });

    const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        // this shouldn't be possible due to a disabled submit button, but
        // we want to be sure before trying to make the API request.
        if (formHasErrors) {
            return;
        }

        // Create our policy and update the Apollo cache with the result that
        // was returned. This is necessary to show the new policy in the list
        // without reloading the page.
        const res = await createPolicy({
            variables: {
                policy: generatePolicy(policy, mapView)
            }
        });

        const newPolicyId = res.data?.createPolicy?.policy.id;
        if (newPolicyId) {
            history.push(`/${mapView.slug}/policy#${newPolicyId}`, {
                action: 'created',
                name: policy.name
            });
            sendEventAnalytics({
                event_name: 'policy event',
                action: 'created',
                name: policy.name,
                policy_type: policy.policyType,
                rate_amount: policy.rateAmount
            });
        } else {
            throw new Error(
                'CreatePolicy query returned no error and no data.'
            );
        }
    };
    if (error) {
        // Check if this is the missing municipality error and show to user since it will require action from them
        if (error.message.includes('has a null municipality boundary')) {
            return [
                onSubmit,
                intl.formatMessage(messages['municipality-boundary-required'])
            ];
        }
        // Else re-throw the error
        throw error;
    }
    return [onSubmit, null];
}

export default useCreatePolicy;
