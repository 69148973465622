import React from 'react';
import cx from 'classnames';
import "/opt/build/repo/src/page-policy/forms/day-of-week-selector.tsx?resplendence=true";
import { defineMessages, useIntl, FormattedMessage } from 'react-intl';

import { Day } from 'graphql.g';
import { FormInput } from './common';

/*
    @import 'style';
*/;

const dayStrings = defineMessages({
    sunday: 'Sun',
    saturday: 'Sat',
    monday: 'Mon',
    tuesday: 'Tue',
    wednesday: 'Wed',
    thursday: 'Thu',
    friday: 'Fri'
});

const ORDERED_DAYS_OF_WEEK = [
    Day.sunday,
    Day.monday,
    Day.tuesday,
    Day.wednesday,
    Day.thursday,
    Day.friday,
    Day.saturday
];

const DAY_OF_WEEK_CONTAINER = "rx-page-policy-forms-day-of-week-selector-1"/*
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-start;

    button:first-child {
        border-top-left-radius: 6rem;
        border-bottom-left-radius: 6rem;
    }

    button:last-child {
        border-top-right-radius: 6rem;
        border-bottom-right-radius: 6rem;
        border-right: 1rem solid $gray-30;
    }
*/;

const DAY_OF_WEEK_BUTTON = "rx-page-policy-forms-day-of-week-selector-2"/*
    font-size: 12rem;
    line-height: 150%;
    background-color: $white;
    color: $gray-90;
    text-decoration: none;
    cursor: pointer;
    border-radius: 0rem;
    padding: 6rem 12rem;
    border: 1rem solid $gray-30;
    border-right: none;

    &:hover {
        background-color: $gray-30;
    }

    &.selected {
        background-color: $blue-50;
        color: $white;

        &:hover {
            background-color: $blue-70;
        }
    }
*/;

interface DayOfWeekInputProps {
    days: Day[];
    onChange: (days: Day[]) => void;
}

function DayOfWeekInput({ days, onChange }: DayOfWeekInputProps) {
    const intl = useIntl();

    return (
        <FormInput
            label={
                <FormattedMessage
                    key="day-of-week-label"
                    defaultMessage="Day of Week:"
                />
            }
            labelId={'day-of-week-input'}
        >
            <div className={DAY_OF_WEEK_CONTAINER}>
                {ORDERED_DAYS_OF_WEEK.map(dayOfWeek => (
                    <button
                        className={cx(
                            DAY_OF_WEEK_BUTTON,
                            days.includes(dayOfWeek) && 'selected'
                        )}
                        type="button"
                        key={dayOfWeek}
                        onClick={() =>
                            days.includes(dayOfWeek)
                                ? onChange(days.filter(d => d !== dayOfWeek))
                                : onChange([...days, dayOfWeek])
                        }
                    >
                        {intl.formatMessage(dayStrings[dayOfWeek])}
                    </button>
                ))}
            </div>
        </FormInput>
    );
}

export default DayOfWeekInput;
