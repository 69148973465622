import { LocalDate } from './date-tools';
import { DateRange } from 'common/date-picker';
import { useQueryState } from 'router';
import { useCallback, useEffect } from 'react';

const parseDateQuery = (query: string) => {
    try {
        return LocalDate.fromDateString(query);
    } catch (err) {
        return null;
    }
};

/**
 * A hook to store and fetch a date range in the URL query params.
 * Use similar to `React.useState`. Pass in an initial DateRange
 * and this hook will return a [dateRange, setDateRange].
 *
 * For example:
 *
 *    const [dateRange, setDateRange] = useDateRangeQuery([
 *        yesterday.minusDays(14),
 *        yesterday
 *    ]);
 */
const useDateRangeQuery = (initial: DateRange) => {
    const [query, setQuery] = useQueryState<string | null>('dates');
    let range: DateRange | null = null;
    if (query) {
        const parts = query.split('--');
        if (parts.length === 2) {
            const start = parseDateQuery(parts[0]);
            const end = parseDateQuery(parts[1]);
            if (start && end) {
                // If the start and end of the range are reversed,
                // switch the order of the start and end in the new date range.
                range = end < start ? [end, start] : [start, end];
            }
        }
    }
    const setRange = useCallback(
        (range: DateRange) => {
            setQuery(`${range[0].toString()}--${range[1].toString()}`);
        },
        [setQuery]
    );
    useEffect(() => {
        if (!range) {
            setRange(initial);
        }
    }, [initial, range, setRange]);
    return [range ?? initial, setRange] as const;
};

export default useDateRangeQuery;
