import React, { useMemo, useState } from 'react';
import { MapView } from 'types';
import { OperatorReport } from './report';
import {
    CalloutSection,
    AggregatedCalloutSection,
    TableSection,
    Table,
    HeaderRow,
    Row,
    AverageValue,
    CALLOUT_GRID,
    ReportSummaryInfoProps,
    LoadingTable,
    NoAreasInReport,
    messages as tableMessages,
    CONSTRAINED_TABLE,
    HeaderProps
} from './tables';
import { defineMessages } from 'react-intl';
import { useIntl } from 'utils/use-intl';
import { sortByAreas } from 'utils/helpers';
import gql from 'graphql-tag';
import {
    AggregatedReportMaxVehiclesFragment,
    AggregatedReportTripCountsFragment,
    AggregatedReportTripsPerVehicleFragment,
    AggregatedReportAoiMetricsFragment
} from './fragments';
import { useReloadingData } from 'utils/use-data';
import { aggregatedDescriptions } from '../constants/stat-descriptions';
import { LocalMonth, LocalWeek } from 'utils/date-tools';
import Loading from 'common/loading';
import { OperatorName } from 'constants/operators';
import { useMapView } from 'common/use-map-view';
import {
    SingleOperatorAggregatedAoiDataData,
    SingleOperatorAggregatedAoiDataArgs,
    AggregatedReportPeriod,
    OperatorAggregatedReportData,
    OperatorAggregatedReportArgs
} from 'graphql.g';
import { useVehicleClass } from 'common/vehicle-classes';
import useDocumentTitle from 'common/use-document-title';
import useSearchFilter from 'utils/search';
import TableSearchInput from 'common/table-search-input';
import { useSorting } from 'common/table-sorting';
import orderBy from 'lodash/orderBy';
import zipWith from 'lodash/zipWith';

const messages = defineMessages({
    'monthly-areas-table-title':
        'Metrics by Area of Interest for {month, date, localmonth}',
    'weekly-areas-table-title':
        'Metrics by Area of Interest for {monday, date, reportweek}—{sunday, date, reportweek}',
    'weekly-document-title':
        '{operator} · Week of {monday, date, reportweek} · Ride Report',
    'monthly-document-title':
        '{operator} · {month, date, reportmonth} · Ride Report'
});

// --------- Areas ---------

interface AreasProps {
    mapView: MapView;
    operatorId: string;
    reportDate: LocalMonth | LocalWeek;
}

const AREAS_QUERY = gql`
    query SingleOperatorAggregatedAoiData(
        $slug: String
        $operatorId: ID
        $date: Date!
        $period: AggregatedReportPeriod!
        $vehicleClass: VehicleClass
    ) {
        mapView(slug: $slug) {
            id
            operator(id: $operatorId) {
                id
                aggregatedReport(
                    date: $date
                    period: $period
                    vehicleClass: $vehicleClass
                ) {
                    ...AggregatedReportAoiMetrics
                }
            }
        }
    }
    ${AggregatedReportAoiMetricsFragment}
`;

const headerInfo = [
    aggregatedDescriptions.startTrips,
    aggregatedDescriptions.endTrips,
    aggregatedDescriptions.morningDeployed
];

const sortKeys = [
    'tripCounts.startCount',
    'tripCounts.endCount',
    'morningDeploymentCounts.averageAvailableCount'
];

const headers: HeaderProps[] = zipWith(headerInfo, sortKeys, (a, b) => {
    return [a, b];
});

function AggregatedReportOperatorAreas({
    mapView,
    operatorId,
    reportDate
}: AreasProps) {
    const intl = useIntl();
    const vehicleClass = useVehicleClass();
    const [data, loading] = useReloadingData<
        SingleOperatorAggregatedAoiDataData,
        SingleOperatorAggregatedAoiDataArgs
    >(AREAS_QUERY, {
        slug: mapView.slug,
        operatorId,
        date:
            reportDate instanceof LocalMonth
                ? reportDate.first.toString()
                : reportDate.monday.toString(),
        period:
            reportDate instanceof LocalMonth
                ? AggregatedReportPeriod.monthly
                : AggregatedReportPeriod.weekly,
        vehicleClass
    });
    const [sorting, onSortHeaderClick] = useSorting<
        | 'tripCounts.startCount'
        | 'tripCounts.endCount'
        | 'morningDeploymentCounts.averageAvailableCount'
    >();
    const report =
        data &&
        data.mapView &&
        data.mapView.operator &&
        data.mapView.operator.aggregatedReport;
    const areaMetrics = useMemo(() => {
        return orderBy(
            report ? sortByAreas(report.aoiMetrics || [], x => x.area) : [],
            [...sorting.keys()],
            [...sorting.values()]
        );
    }, [sorting, report]);
    const [filter, setFilter] = useState('');
    const filteredAreas = useSearchFilter(
        areaMetrics,
        metric => metric.area.name,
        filter
    );
    return (
        <>
            <Loading loading={loading} kind="over-table" />
            <TableSearchInput
                placeholder={intl.formatMessage(tableMessages['search-areas'])}
                value={filter}
                onChange={setFilter}
            />
            <div className={CONSTRAINED_TABLE}>
                {report ? (
                    areaMetrics.length > 0 ? (
                        <Table>
                            <HeaderRow
                                wideLeftLabels
                                headers={headers}
                                sorting={sorting}
                                onSortHeaderClick={onSortHeaderClick}
                            />
                            <tbody>
                                {filteredAreas.map(metric => (
                                    <Row
                                        key={metric.area.name}
                                        name={metric.area.name}
                                        timestamps
                                        values={[
                                            metric.tripCounts && (
                                                <AverageValue
                                                    value={
                                                        metric.tripCounts
                                                            .startCount
                                                    }
                                                    average={
                                                        metric.tripCounts
                                                            .averageStartCount
                                                    }
                                                />
                                            ),
                                            metric.tripCounts && (
                                                <AverageValue
                                                    value={
                                                        metric.tripCounts
                                                            .endCount
                                                    }
                                                    average={
                                                        metric.tripCounts
                                                            .averageEndCount
                                                    }
                                                />
                                            ),
                                            metric.morningDeploymentCounts &&
                                                metric.morningDeploymentCounts
                                                    .averageAvailableCount
                                        ]}
                                    />
                                ))}
                            </tbody>
                        </Table>
                    ) : (
                        <NoAreasInReport />
                    )
                ) : (
                    <LoadingTable headers={headers} />
                )}
            </div>
        </>
    );
}

// --------- Operator ---------

const QUERY = gql`
    query OperatorAggregatedReport(
        $slug: String
        $operatorId: ID
        $date: Date!
        $period: AggregatedReportPeriod!
        $vehicleClass: VehicleClass
    ) {
        mapView(slug: $slug) {
            id
            operator(id: $operatorId) {
                id
                aggregatedReport(
                    date: $date
                    period: $period
                    vehicleClass: $vehicleClass
                ) {
                    finalized
                    ...AggregatedReportMaxVehicles
                    ...AggregatedReportTripCounts
                    ...AggregatedReportTripsPerVehicle
                }
            }
        }
    }
    ${AggregatedReportMaxVehiclesFragment}
    ${AggregatedReportTripCountsFragment}
    ${AggregatedReportTripsPerVehicleFragment}
`;

type AggregatedReportOperatorProps = {
    reportDate: LocalMonth | LocalWeek;
    operator: { name: OperatorName; operatorId: string };
};

function PageAggregatedOperator({
    operator,
    reportDate
}: AggregatedReportOperatorProps) {
    const intl = useIntl();
    const mapView = useMapView();
    const vehicleClass = useVehicleClass();

    const title =
        reportDate instanceof LocalWeek
            ? intl.formatMessage(messages[`weekly-document-title`], {
                  monday: reportDate.monday.toDate(),
                  operator: operator.name
              })
            : intl.formatMessage(messages[`monthly-document-title`], {
                  month: reportDate?.first.toDate(),
                  operator: operator.name
              });

    useDocumentTitle(title);

    const [data, loading] = useReloadingData<
        OperatorAggregatedReportData,
        OperatorAggregatedReportArgs
    >(QUERY, {
        slug: mapView.slug,
        operatorId: operator.operatorId,
        date:
            reportDate instanceof LocalMonth
                ? reportDate.first.toString()
                : reportDate.monday.toString(),
        period:
            reportDate instanceof LocalMonth
                ? AggregatedReportPeriod.monthly
                : AggregatedReportPeriod.weekly,
        vehicleClass
    });
    const operatorData = data && data.mapView && data.mapView.operator;
    const report = operatorData && operatorData.aggregatedReport;
    const maxVehicles = report && report.maxVehicles;
    const tripCounts = report && report.tripCounts;
    const tripsPerVehicle = report && report.tripsPerVehicle;
    const headerInfo: ReportSummaryInfoProps = {
        value: maxVehicles && maxVehicles.averageMaxParked,
        label: aggregatedDescriptions.maxParked
    };
    return (
        <OperatorReport
            reportDate={reportDate}
            operator={operator}
            headerInfo={headerInfo}
            finalized={report?.finalized ?? true}
        >
            <div className={CALLOUT_GRID}>
                <CalloutSection
                    value={maxVehicles && maxVehicles.averageMaxAvailable}
                    title={aggregatedDescriptions.maxAvailable}
                    loading={loading}
                />
                <CalloutSection
                    value={maxVehicles && maxVehicles.averageMaxUnavailable}
                    title={aggregatedDescriptions.maxUnavailable}
                    loading={loading}
                />
                <AggregatedCalloutSection
                    value={tripCounts && tripCounts.startCount}
                    average={tripCounts && tripCounts.averageStartCount}
                    title={aggregatedDescriptions.startTrips}
                    loading={loading}
                />
                <CalloutSection
                    value={tripsPerVehicle && tripsPerVehicle.tripsPerVehicle}
                    title={aggregatedDescriptions.tripsPerVehicle}
                    loading={loading}
                />
            </div>
            <TableSection
                title={
                    reportDate instanceof LocalMonth
                        ? intl.formatMessage(
                              messages['monthly-areas-table-title'],
                              {
                                  month: reportDate.first.toDate()
                              }
                          )
                        : intl.formatMessage(
                              messages['weekly-areas-table-title'],
                              {
                                  monday: reportDate.monday.toDate(),
                                  sunday: reportDate.sunday.toDate()
                              }
                          )
                }
            >
                <AggregatedReportOperatorAreas
                    mapView={mapView}
                    operatorId={operator.operatorId}
                    reportDate={reportDate}
                />
            </TableSection>
        </OperatorReport>
    );
}

export default PageAggregatedOperator;
