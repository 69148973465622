import { DateRange } from 'common/date-picker';
import ErrorBoundary from 'common/error-boundary';
import {
    Button,
    Container,
    ContainerHeader,
    ContainerMessage
} from 'common/layout';
import Loading from 'common/loading';
import { useMapView } from 'common/use-map-view';
import { useVehicleClasses } from 'common/vehicle-classes';
import { AoiInfoFragment } from 'graphql.g';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import "/opt/build/repo/src/page-analyze/analyze.tsx?resplendence=true";
import { useQueryState } from 'router';
import AnalyzeFilter, {
    formatChartLabel,
    useAnalyzeData,
    useFilter
} from './analyze-filter';
import handleCsvDownload from './csv';
import MetricChart from './metric-chart';
import MetricSelector from './metric-selector';
import ThreeDimensionalChart from './three-dimensional-chart';
import { ALLOWABLE_EXTRA_METRICS, ChartMetric, CHART_LABELS } from './types';

/*
@import 'style';
*/;

const CONTAINER = "rx-page-analyze-analyze-1"/*
    @include container;
    padding: 24rem;
    display: flex;
    flex-flow: row wrap;
    align-items: flex-end;
    gap: 35.2rem 8rem;
    > * {
        flex-shrink: 0;
    }
*/;

const ANALYZE_HEADER = "rx-page-analyze-analyze-2"/*
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    width: 100%;
*/;

type Props = {
    metric: ChartMetric;
    dateRange: DateRange;
    preliminaryDates: Set<string>;
    hasPreliminaryData: boolean;
    areas: AoiInfoFragment[] | null;
};

function roundDatesToWeeks([start, end]: DateRange): DateRange {
    if (end.isBefore(start.plusDays(6))) return [start, start.plusDays(7)];
    let weeks: number = 1;
    while (!end.isBefore(start.plusDays(7 * weeks - 1))) {
        weeks += 1;
    }
    return [start, start.plusDays((weeks - 1) * 7 - 1)];
}

function Analyze({
    metric,
    dateRange,
    preliminaryDates,
    hasPreliminaryData,
    areas
}: Props) {
    const filter = useFilter(metric, areas);

    const [
        additionalMetricType,
        setAdditionalMetricType
    ] = useQueryState<ChartMetric | null>('metric1');
    const additionalFilter = useFilter(
        additionalMetricType ?? metric,
        areas,
        '1'
    );

    const roundedDates = roundDatesToWeeks(dateRange);

    const [analyzeData, mainLoading] = useAnalyzeData(
        dateRange,
        metric,
        filter
    );
    const [additionalData, additionalLoading] = useAnalyzeData(
        dateRange,
        additionalMetricType,
        additionalFilter
    );
    const [threeDimensionalData, threeDimensionalLoading] = useAnalyzeData(
        roundedDates,
        'trips_per_day_by_hour',
        metric === 'trips_by_hour' ? filter : null
    );

    const loading =
        mainLoading ||
        (!!additionalMetricType && additionalLoading) ||
        (metric === 'trips_by_hour' && threeDimensionalLoading);
    const mapView = useMapView();
    const availableVehicleClasses = useVehicleClasses();
    const intl = useIntl();

    const operators = mapView.operators.filter(op => op.hasMdsData);

    const chartLabel = formatChartLabel(intl, metric, filter);

    const metricCanBeCompared =
        ALLOWABLE_EXTRA_METRICS.includes(metric) && operators.length !== 0;

    const comparisonMetricIsShowing =
        metricCanBeCompared &&
        !!additionalMetricType &&
        ALLOWABLE_EXTRA_METRICS.includes(metric);

    const multiLineLabel = intl.formatMessage(
        CHART_LABELS['metric-comparison-by-date']
    );
    return (
        <>
            {metricCanBeCompared &&
                (additionalMetricType && comparisonMetricIsShowing ? (
                    <div className={CONTAINER}>
                        <MetricSelector
                            metric={additionalMetricType}
                            setMetric={setAdditionalMetricType}
                            metrics={ALLOWABLE_EXTRA_METRICS}
                        />
                        <AnalyzeFilter
                            areas={areas}
                            chartType={additionalMetricType}
                            index={1}
                            availableVehicleClasses={availableVehicleClasses}
                            removeComparisonMetricButton={
                                <Button
                                    color="red"
                                    icon="Minus"
                                    onClick={() => {
                                        setAdditionalMetricType(null);
                                    }}
                                />
                            }
                        />
                    </div>
                ) : (
                    <div>
                        <Button
                            color="blue"
                            icon="Plus"
                            onClick={() =>
                                setAdditionalMetricType('trips_by_date')
                            }
                        >
                            <FormattedMessage
                                key="compare-metrics"
                                defaultMessage="Compare Metrics"
                            />
                        </Button>
                    </div>
                ))}
            <Container>
                <ContainerHeader>
                    <div className={ANALYZE_HEADER}>
                        {!comparisonMetricIsShowing
                            ? chartLabel
                            : multiLineLabel}
                        <Button
                            color="blue"
                            icon="Spreadsheet"
                            disabled={!chartLabel}
                            onClick={() =>
                                chartLabel &&
                                analyzeData &&
                                handleCsvDownload(
                                    !!additionalData
                                        ? [analyzeData, additionalData]
                                        : [analyzeData],
                                    chartLabel,
                                    additionalMetricType &&
                                        formatChartLabel(
                                            intl,
                                            additionalMetricType,
                                            additionalFilter
                                        ),
                                    metric,
                                    !!additionalMetricType,
                                    dateRange,
                                    intl,
                                    multiLineLabel
                                )
                            }
                        >
                            <FormattedMessage
                                key="csv-export-button-label"
                                defaultMessage="Export CSV"
                            />
                        </Button>
                    </div>
                </ContainerHeader>
                <ErrorBoundary>
                    <Loading loading={loading} kind="over-table" />
                    {operators.length === 0 ? (
                        <ContainerMessage>
                            <FormattedMessage
                                key="no-available-metrics"
                                defaultMessage="There are no available metrics"
                            />
                        </ContainerMessage>
                    ) : metric === 'trips_by_hour' ? (
                        <ThreeDimensionalChart
                            baseChart={{
                                dateRange,
                                metric,
                                filter,
                                data: analyzeData
                            }}
                            threeDimensionalChart={{
                                dateRange: roundedDates,
                                metric: 'trips_per_day_by_hour',
                                filter,
                                data: threeDimensionalData
                            }}
                        />
                    ) : (
                        <MetricChart
                            chart={{
                                dateRange,
                                metric,
                                filter,
                                data: analyzeData
                            }}
                            extraChart={
                                additionalMetricType && {
                                    dateRange,
                                    metric: additionalMetricType,
                                    filter: additionalFilter,
                                    data: additionalData
                                }
                            }
                            hasPreliminaryData={hasPreliminaryData}
                            preliminaryDates={preliminaryDates}
                        />
                    )}
                </ErrorBoundary>
            </Container>
        </>
    );
}

export default Analyze;
