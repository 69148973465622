import React, { useEffect } from 'react';
import {
    NumberInput,
    TimeSelectInput,
    TIME_SELECTOR_ROW,
    FormNotice
} from './common';
import { FormattedMessage } from 'react-intl';
import { PolicyFormProps } from './types';
import { SingleOperatorSelect } from './operator-selectors';
import VehicleClassInput from './vehicle-class-selector';
import DayOfWeekInput from './day-of-week-selector';
import "/opt/build/repo/src/page-policy/forms/form-minimum-deployment.tsx?resplendence=true";

/*
@import 'style';
*/;

const DEPLOYMENT_UNIT_ROW = "rx-page-policy-forms-form-minimum-deployment-1"/*
    display: flex;
    align-items: center;
    grid-gap: 16rem;
*/;

// We'll use midnight as the default time for time selectors as midnight to midnight will be for the full day
const DEFAULT_MINIMUM_DEPLOYMENT_TIME: [number, number] = [0, 0];

type MinimumDeploymentFormProps = PolicyFormProps & { isPercentBased: boolean };

function MinimumDeploymentForm({
    policy,
    setPolicy,
    formErrors,
    setFormErrors,
    isPercentBased
}: MinimumDeploymentFormProps) {
    useEffect(() => {
        const hasMinimumError = policy.minimum == null;
        // set the minimum error, if it's not set already
        if (formErrors.minimum !== hasMinimumError) {
            setFormErrors({
                ...formErrors,
                minimum: hasMinimumError
            });
        }
    }, [policy.minimum, formErrors, setFormErrors]);

    useEffect(() => {
        if (policy.startTime == null) {
            setPolicy({
                ...policy,
                startTime: DEFAULT_MINIMUM_DEPLOYMENT_TIME
            });
        }

        if (policy.endTime == null) {
            setPolicy({
                ...policy,
                endTime: DEFAULT_MINIMUM_DEPLOYMENT_TIME
            });
        }
    });

    return (
        <>
            <VehicleClassInput policy={policy} setPolicy={setPolicy} />

            <div className={DEPLOYMENT_UNIT_ROW}>
                <NumberInput
                    width={130}
                    numberLabel={isPercentBased ? '%' : undefined}
                    numberLabelPosition={isPercentBased ? 'right' : undefined}
                    label={
                        <FormattedMessage
                            key="minimum-label"
                            defaultMessage="Vehicle Minimum:"
                        />
                    }
                    id="minimum-input"
                    value={policy.minimum ?? ''}
                    min={0}
                    max={isPercentBased ? 100 : undefined}
                    onChange={newValue => {
                        const amount = parseInt(newValue);
                        setPolicy({
                            ...policy,
                            minimum: Number.isNaN(amount) ? undefined : amount
                        });
                    }}
                />
            </div>

            <TIME_SELECTOR_ROW>
                <TimeSelectInput
                    label={
                        <FormattedMessage
                            key="time-label-start"
                            defaultMessage="Start time:"
                        />
                    }
                    id="start-time-input"
                    selectedTime={
                        policy.startTime ?? DEFAULT_MINIMUM_DEPLOYMENT_TIME
                    }
                    onChange={startTime => {
                        setPolicy({
                            ...policy,
                            startTime
                        });
                    }}
                />

                <TimeSelectInput
                    label={
                        <FormattedMessage
                            key="time-label-end"
                            defaultMessage="End time:"
                        />
                    }
                    id="end-time-input"
                    selectedTime={
                        policy.endTime ?? DEFAULT_MINIMUM_DEPLOYMENT_TIME
                    }
                    onChange={endTime =>
                        setPolicy({
                            ...policy,
                            endTime
                        })
                    }
                />
            </TIME_SELECTOR_ROW>

            <FormNotice
                showNotice={policy.startTime == null}
                noticeType="error"
            >
                <FormattedMessage
                    key="required-policy-start-time"
                    defaultMessage="Minimum deployments required a selected start time."
                />
            </FormNotice>

            <DayOfWeekInput
                days={policy.days}
                onChange={days =>
                    setPolicy({
                        ...policy,
                        days
                    })
                }
            />

            <SingleOperatorSelect
                policy={policy}
                setPolicy={setPolicy}
                formErrors={formErrors}
                setFormErrors={setFormErrors}
            />
        </>
    );
}

export default MinimumDeploymentForm;
