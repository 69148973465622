import React from 'react';
import "/opt/build/repo/src/page-explore/common/sidebar/sidebar.tsx?resplendence=true";
import SidebarOptions, { SidebarToggle } from '../../../common/map-options';
import { Button } from 'common/layout';
import { FormattedMessage } from 'react-intl';
import cx from 'classnames';
import { MapOptions, DispatchMapOptions } from 'page-explore';
import { useRouteMatch } from 'react-router';
import { EXPLORE_URLS } from '../constants';

/*
@import 'style';
*/;

const SIDEBAR = "rx-page-explore-common-sidebar-sidebar-1"/*
    @include not-mobile-tiny {
        @include container;
        grid-template-columns: 220rem;
        max-height: calc(100% - 106rem);
        justify-self: end;
        align-self: start;
    }
    @include mobile-tiny {
        width: 100%;
        // override container styling, so we have a flat top on mobile
        border-radius: 0 0 6rem 6rem;
    }
    color: $gray-60;
    display: grid;
    grid-template-rows: auto 1fr;
    position: relative;
    min-height: 0;
	z-index: 1;
    pointer-events: auto;
    transition: 150ms all ease-in-out;
    left: 0;
    opacity: 1;
    &.exit, &.enter:not(.enter-active) {
        left: calc(268rem);
    }
    @media (prefers-reduced-motion: reduce) {
        &.exit, &.enter:not(.enter-active) {
            left: 0;
            opacity: 0;
        }
    }
    &.auto-height {
        height: auto
    }
*/;

const OPTIONS = "rx-page-explore-common-sidebar-sidebar-2"/*
    padding: 16rem;
    display: grid;
    grid-template-columns: auto auto;
    justify-content: start;
    grid-gap: 8rem;
*/;

const CHILD = "rx-page-explore-common-sidebar-sidebar-3"/*
    box-sizing: border-box;
    padding: 0rem 16rem 16rem;
    display: grid;
    grid-template-columns: 100%;
    grid-auto-flow: row;
    grid-gap: 16rem;

    @include scrollbar($color: $green-50);
*/;

interface SidebarProps {
    extraToggles?: SidebarToggle[];
    filtered?: boolean;
    onResetFilter?: () => void;
    mapOptions: MapOptions;
    dispatchMapOptions: DispatchMapOptions;
    children?: React.ReactNode;
    headerButton?: React.ReactNode;
}

const Sidebar = ({
    extraToggles,
    filtered,
    onResetFilter,
    mapOptions,
    dispatchMapOptions,
    children,
    headerButton = (
        <Button
            color="blue"
            disabled={!filtered || !onResetFilter}
            onClick={onResetFilter ? onResetFilter : () => {}}
        >
            <FormattedMessage
                key="reset-button"
                defaultMessage="Reset Filters"
            />
        </Button>
    )
}: SidebarProps) => {
    const page = useRouteMatch<{ page: string }>().params.page;
    return (
        <div
            className={cx(SIDEBAR, {
                'auto-height': !children || page === EXPLORE_URLS.HEATMAPS
            })}
        >
            <div className={OPTIONS}>
                <SidebarOptions
                    toggles={extraToggles}
                    showSatellite={mapOptions.showSatellite}
                    setSatellite={value =>
                        dispatchMapOptions({ type: 'set satellite', value })
                    }
                />
                {headerButton}
            </div>
            {children && <div className={CHILD}>{children}</div>}
        </div>
    );
};

export default Sidebar;
