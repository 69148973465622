import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { ReportDate } from './report-picker';
import { LocalDate, LocalWeek } from 'utils/date-tools';
import { useMapView } from 'common/use-map-view';
import "/opt/build/repo/src/page-report/download-buttons.tsx?resplendence=true";
import DownloadButton from 'common/download-button';
import { DateRange, DateRangePicker } from 'common/date-picker';
import { Dropdown } from 'common/dropdown';
import { Button } from 'common/layout';
import useLastReportDate from 'common/use-last-report-date';

/*
@import "style";
*/;

function getDateRangeForReportDate(date: ReportDate): DateRange {
    if (date instanceof LocalDate) {
        return [date.minusDays(7), date];
    } else if (date instanceof LocalWeek) {
        return [date.monday, date.sunday];
    } else {
        return [date.first, date.last];
    }
}

const DROPDOWN_BUTTONS = "rx-page-report-download-buttons-1"/*
    margin-top: 8rem;
    display: grid;
    grid-auto-flow: column;
    justify-content: flex-end;
    grid-gap: 8rem;
*/;

type DownloadReportProps<T extends ReportDate> = {
    reportDate: T;
    onClose: () => void;
};

function DownloadReport<T extends ReportDate>({
    reportDate,
    onClose
}: DownloadReportProps<T>) {
    const [dateRange, setDateRange] = useState<DateRange>(
        getDateRangeForReportDate(reportDate)
    );
    const { slug } = useMapView();
    const lastReportDate = useLastReportDate();
    return (
        <>
            <DateRangePicker
                selectedDateRange={dateRange}
                onChange={setDateRange}
                latestAllowed={lastReportDate}
            />
            <div className={DROPDOWN_BUTTONS}>
                <Button onClick={onClose}>
                    <FormattedMessage
                        key="cancel-button"
                        defaultMessage="Cancel"
                    />
                </Button>
                <DownloadButton
                    icon="Download"
                    path="reports_v2"
                    filename={`${dateRange[0].toString()}--${dateRange[1].toString()}_report_export(CONFIDENTIAL).zip`}
                    params={{
                        mapview_slug: slug,
                        start_date: dateRange[0].toString(),
                        end_date: dateRange[1].toString()
                    }}
                    failureReason="download-failure-time-range"
                >
                    <FormattedMessage
                        key="download-button"
                        defaultMessage="Download for selected dates"
                    />
                </DownloadButton>
            </div>
        </>
    );
}

type Props<T extends ReportDate> = {
    reportDate: T;
};

function DownloadButtons<T extends ReportDate>({ reportDate }: Props<T>) {
    const [showReportDropdown, setShowReportDropdown] = useState(false);

    return (
        <Dropdown
            active={showReportDropdown}
            setActive={setShowReportDropdown}
            buttonProps={{
                color: showReportDropdown ? 'gray' : 'blue',
                icon: 'Spreadsheet',
                children: (
                    <FormattedMessage
                        key="csv-export-button-label"
                        defaultMessage="Export CSV"
                    />
                )
            }}
            position="bottom-right"
        >
            <DownloadReport
                reportDate={reportDate}
                onClose={() => setShowReportDropdown(false)}
            />
        </Dropdown>
    );
}

export default DownloadButtons;
