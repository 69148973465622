import React from 'react';
import Loading from 'common/loading';

import "/opt/build/repo/src/root/loading-site.tsx?resplendence=true";
import { Logos } from './app-header';

/*
@import 'style.scss';
*/;

const BODY = "rx-root-loading-site-1"/*
    position: relative;
    flex: 1 0 auto;
*/;

/**
 * A loading screen to show in root-level components in place of any visible content at all.
 * Contains an empty header to render a gray bar at the top of the screen.
 */
function LoadingSite() {
    return (
        <>
            <header>
                <Logos />
            </header>
            <div className={BODY}>
                <Loading />
            </div>
        </>
    );
}

export default LoadingSite;
