import React from 'react';
import "/opt/build/repo/src/root/page-eula.tsx?resplendence=true";

/*
@import 'style';
*/;

const EULA = "rx-root-page-eula-1"/*
    max-width: 650rem;
    background-color: $white;
    color: $gray-90;
    padding: 24rem 0;
    margin: 24rem 0;
    p, h1, h2, ol {
        padding: 0 24rem;
    }
    h1 {
        @include headline-4;
    }
    h2{
        @include headline-5;
        margin-top: 24rem;
        &:before {
            content: counter(section) ". "
        }
    }
    .section {
        counter-increment: section;
    }
    p {
        margin: 16rem 0;
        &:first-child {
            margin-top: 0;
        }
        &:last-child {
            margin-bottom: 0;
        }
        @include text-body;
        .definition {
            font-weight: bold;
        }
    }
    .conspicious {
        font-size: 18rem;
        p, li {
            font-size: 18rem;
        }
        border-color: $red-50;
        border-style: solid;
        border-width: 0 4rem;
        background-color: fade-out($red-10, .8);
        padding: 24rem 28rem;
        margin: 16rem 0;
    }
    ol {
        margin: 16rem 0;
        @include text-body;
        counter-reset: section;

        ol {
            margin-left: 16rem;
        }
        &:first-child {
            margin-top: 0;
        }
        &:last-child {
            margin-bottom: 0;
        }
        li {
            margin: 16rem 0;
            &:first-child {
                margin-top: 0;
            }
            &:last-child {
                margin-bottom: 0;
            }
            list-style-type: none;
            counter-increment: section;
            &:before {
                font-weight: bold;
                content: "(" counters(section, ".") ") "
            }
        }
    }
    h1, h2 {
        & + p {
            margin-top: 0;
        }
    }

*/;

/**
 * An end-user license agreement.
 */
function Eula() {
    return (
        <div className={EULA}>
            <h1>End User License Agreement</h1>
            <p>
                Below are the legal requirements and obligations concerning the
                use of the data you are downloading. Please read this entire
                agreement to know all of the requirements and obligations.
                Basically, you get to use the data to do your analyses as long
                as you don't share it with other people who aren't covered under
                the agreement we have with your employer (the Software Services
                Agreement). We own our app data and the scooter/bike share
                operators own theirs. By clicking "I Accept", you are getting a
                license to use the data to do your analyses. Read below for
                specific conditions of this license and the actual binding
                language that you are agreeing to by clicking "I Accept".
            </p>
            <p>
                This End User License Agreement (this "
                <strong>Agreement</strong>
                "), is a binding agreement between Knock Software, Inc., doing
                business as Ride Report ("
                <strong>Ride Report</strong>
                ") and the person or entity downloading the Ride Report Data as
                the licensee of the Ride Report Data ("
                <strong>Licensee</strong>
                ").
            </p>
            <p className="conspicious">
                Ride Report provides the Ride Report Data solely on the terms
                and conditions set forth in this Agreement and the Software
                Services Agreement and on the condition that Licensee accepts
                and complies with them. In the case of a conflict between the
                terms of this Agreement and the Software Services Agreement, the
                terms of the Software Services Agreement will control. Please
                check with someone familiar with the Software Services Agreement
                if you have any questions.
            </p>
            <p>
                By selecting the "I Accept" button or other button or mechanism
                designed to acknowledge agreement to the terms of an electronic
                copy of this Agreement, or by downloading, accessing, or
                otherwise copying or using all or any portion of the Ride Report
                Data (i) you accept this Agreement on behalf of Licensee for
                which you are authorized to act (e.g., an employer) and
                acknowledge that such entity is legally bound by this Agreement
                (and you agree to act in a manner consistent with this
                Agreement) or, if there is no such entity for which you are
                authorized to act, you accept this Agreement on behalf of
                yourself as an individual and acknowledge that you are legally
                bound by this Agreement as Licensee, and (ii) you represent and
                warrant that you have the right, power and authority to act on
                behalf of and bind such entity (if any) or yourself. You may not
                accept this Agreement on behalf of another entity unless you are
                an employee of such other entity with the right, power and
                authority to act on behalf of such other entity.
            </p>
            <p />
            <p className="conspicious">
                If you are unwilling to accept this Agreement, or you do not
                have the right, power and authority to act on behalf of and bind
                such entity or yourself as an individual (if there is no such
                entity), do not select the "I accept" button or otherwise click
                on any button or other mechanism designed to acknowledge
                agreement, and do not download, access, or otherwise copy or use
                all or any portion of the Ride Report Data.
            </p>
            <section className="section">
                <h2>Definitions</h2>
                <p>
                    For the purposes of this agreement, the following terms have
                    the following meanings.
                </p>
                <p>
                    <span className="definition">"Authorized Users"</span> means
                    solely those individuals authorized to use the Ride Report
                    Services as set forth in the applicable Software Services
                    Agreement.
                </p>
                <p>
                    <span className="definition">"Documentation"</span> means
                    user manuals, technical manuals, and any other materials
                    provided by Ride Report, in printed, electronic, or other
                    form, that describe the operation, use, or technical
                    specifications of the Ride Report Data.
                </p>
                <p>
                    <span className="definition">
                        "Intellectual Property Rights"
                    </span>
                    means any and all registered and unregistered rights
                    granted, applied for, or otherwise now or hereafter in
                    existence under or related to any patent, copyright,
                    trademark, trade secret, database protection, or other
                    intellectual property rights laws, and all similar or
                    equivalent rights or forms of protection, in any part of the
                    world.
                </p>
                <p>
                    <span className="definition">"Person"</span> means an
                    individual, corporation, partnership, joint venture, limited
                    liability company, governmental authority, unincorporated
                    organization, trust, association, or other entity.
                </p>
                <p>
                    <span className="definition">"Ride Report Data"</span> means
                    the data, maps, figures, charts, and other information
                    provided by the Ride Report Services that is downloaded by
                    clicking on the "I Accept" button or other button or
                    mechanism associated with this Agreement or by otherwise
                    indicating assent to this Agreement. These data include 3rd
                    Party service provider data that Ride Report shares with the
                    Licensee’s employer as part of its Software Services
                    Agreement.
                </p>
                <p>
                    <span className="definition">"Ride Report Services"</span>{' '}
                    means the software-as-a-service offerings that are the
                    subject of the Software Services Agreement.
                </p>
                <p>
                    <span className="definition">
                        "Software Services Agreement"
                    </span>
                    means the agreement entered into between Ride Report and
                    Licensee, or Licensee's employer, for Licensee's use of the
                    Ride Report Services that serve and provide the Ride Report
                    Data.
                </p>
                <p>
                    <span className="definition">"Third Party"</span> means any
                    Person other than Licensee or Ride Report.
                </p>
            </section>
            <section className="section">
                <h2>License Grant and Scope</h2>
                <p>
                    Subject to and conditioned upon Licensee's strict compliance
                    with all terms and conditions set forth in this Agreement,
                    Ride Report hereby grants to Licensee a non-exclusive,
                    nontransferable, non-sublicensable, limited license during
                    the Term to use the Ride Report Data, solely as set forth in
                    this Section 2 and subject to all conditions and limitations
                    set forth in Section 3 or elsewhere in this Agreement. This
                    license grants Licensee the right to:
                </p>
                <ol>
                    <li>
                        <span>
                            Download and display the Ride Report Data. All
                            copies of the Ride Report Data made by the Licensee:
                        </span>
                        <ol>
                            <li>
                                will be the exclusive property of Ride Report or
                                the Third Party service provider that originally
                                provided the data to Ride Report for the
                                purposes of sharing with Licensee or Licensee’s
                                employer pursuant to Licensee or Licensee's
                                employer's Software Services Agreement;
                            </li>
                            <li>
                                will be subject to the terms and conditions of
                                this Agreement; and
                            </li>
                            <li>
                                must include all trademark, copyright, patent,
                                and other Intellectual Property Rights notices
                                contained in the original.
                            </li>
                        </ol>
                    </li>
                    <li>
                        Share the Ride Report Data with other Authorized Users.
                    </li>
                </ol>
            </section>
            <section className="section">
                <h2>Use Restrictions</h2>
                <p>Licensee will not directly or indirectly:</p>
                <ol>
                    <li>
                        use (including make any copies of) the Ride Report Data
                        beyond the scope of the license granted under Section 2
                        provide any other Person, including any subcontractor,
                        independent contractor, affiliate, or service provider
                        of Licensee, with access to or use of the Ride Report
                        Data unless that Person is authorized to receive the
                        data under Licensee’s or Licensee's employer's Software
                        Services Agreement or through a separate agreement with
                        Ride Report;
                    </li>
                    <li>
                        remove, delete, alter, or obscure any trademarks or any
                        copyright, trademark, patent, or other intellectual
                        property or proprietary rights notices provided on or
                        with the Ride Report Data, including any copy thereof;
                    </li>
                    <li>
                        rent, lease, lend, sell, sublicense, assign, distribute,
                        publish, transfer, or otherwise make available the Ride
                        Report Data to any Third Party for any reason;
                    </li>
                    <li>
                        <span>
                            use the Ride Report Data in, or in association with,
                            the design, construction, maintenance, or operation
                            of any hazardous environments or systems, including:
                        </span>
                        <ol>
                            <li>power generation systems;</li>
                            <li>
                                aircraft navigation or communication systems,
                                air traffic control systems, or any other
                                transport management systems;
                            </li>
                            <li>
                                safety-critical applications, including medical
                                or life-support systems, vehicle operation
                                applications, or any police, fire, or other
                                safety response systems; and
                            </li>
                            <li>
                                military or aerospace applications, weapons
                                systems, or environments;
                            </li>
                        </ol>
                    </li>
                    <li>
                        use the Ride Report Data in violation of any law,
                        regulation, or rule; or
                    </li>
                    <li>
                        use the Ride Report Data for purposes of competitive
                        analysis of the Ride Report Data, the development of a
                        competing data service, or any other purpose that is to
                        Ride Report's commercial disadvantage.
                    </li>
                </ol>
            </section>
            <section className="section">
                <h2>Responsibility for Use of Ride Report Data</h2>
                <p>
                    Licensee is responsible and liable for all uses of the Ride
                    Report Data through access thereto provided by Licensee,
                    directly or indirectly. Specifically, and without limiting
                    the generality of the foregoing, Licensee is responsible and
                    liable for all actions and failures to take required actions
                    with respect to the Ride Report Data by any other Person to
                    whom Licensee may provide access to or use of the Ride
                    Report Data, whether such access or use is permitted by or
                    in violation of this Agreement.
                </p>
            </section>
            <section className="section">
                <h2>Intellectual Property Rights</h2>
                <p>
                    Licensee acknowledges and agrees that the Ride Report Data
                    is provided under license, and not sold, to Licensee.
                    Licensee does not acquire any ownership interest in the Ride
                    Report Data under this Agreement, or any other rights
                    thereto, other than to use the same in accordance with the
                    license granted and subject to all terms, conditions, and
                    restrictions under this Agreement. Ride Report reserves and
                    will retain its entire right, title, and interest in and to
                    the Ride Report Data and all Intellectual Property Rights
                    arising out of or relating to the Ride Report Data, except
                    as expressly granted to Licensee in this Agreement. Licensee
                    will safeguard all Ride Report Data (including all copies
                    thereof) from infringement, misappropriation, theft, misuse,
                    or unauthorized access. Licensee will promptly notify Ride
                    Report if Licensee becomes aware of any infringement of Ride
                    Report's Intellectual Property Rights in the Ride Report
                    Data and fully cooperate with Ride Report in any legal
                    action taken by Ride Report to enforce its Intellectual
                    Property Rights.
                </p>
            </section>
            <section className="section">
                <h2>Term and Termination</h2>
                <ol>
                    <li>
                        This Agreement and the license granted hereunder will
                        remain in effect until terminated as set forth herein
                        (the "<strong>Term</strong>
                        ").
                    </li>
                    <li>
                        Licensee may terminate this Agreement by ceasing use of
                        and destroying all copies of the Ride Report Data.
                    </li>
                    <li>
                        Ride Report may terminate this Agreement, effective upon
                        written notice to Licensee, if Licensee breaches this
                        Agreement or the applicable Software Services Agreement
                        and such breach: (i) is incapable of cure; or (ii) being
                        capable of cure, remains uncured 30 days after Ride
                        Report provides Licensee with written notice of breach.
                    </li>
                    <li>
                        Upon termination of this Agreement, the license granted
                        hereunder will also terminate, and Licensee will cease
                        using and destroy all copies of the Ride Report Data.
                    </li>
                </ol>
            </section>
            <section className="section">
                <h2>Warranty Disclaimer</h2>
                <p className="conspicious">
                    The Ride Report Data is provided to Licensee "as is" and
                    with all faults and defects without warranty of any kind. To
                    the maximum extent permitted under applicable law, Ride
                    Report, on its own behalf and on behalf of its affiliates
                    and its and their respective Licensors and Service
                    Providers, expressly disclaims all warranties, whether
                    express, implied, statutory, or otherwise, with respect to
                    the Ride Report Data, including all implied warranties of
                    merchantability, fitness for a particular purpose, title,
                    and non-infringement, and warranties that may arise out of
                    course of dealing, course of performance, usage, or trade
                    practice. without limitation to the foregoing, ride report
                    provides no warranty or undertaking, and makes no
                    representation of any kind that the Ride Report Data will
                    meet the Licensee's requirements, achieve any intended
                    results, be error free, or that any errors or defects can or
                    will be corrected. Ride Report does not seek to limit
                    Licensee’s warranty rights to any extent not permitted by
                    law.
                </p>
            </section>
            <section className="section">
                <h2>Limitation of Liability</h2>
                <div className="conspicious">
                    <p>To the fullest extent permitted under applicable law:</p>
                    <ol>
                        <li>
                            In no event will ride report or its affiliates, or
                            any of its or their respective Licensors or Service
                            Providers, be liable to Licensee or any Third Party
                            for any use, interruption, delay, or inability to
                            use the Ride Report Data; lost revenues or profits;
                            delays, interruption, or loss of services, business,
                            or goodwill; or for any consequential, incidental,
                            indirect, exemplary, special, or punitive damages,
                            whether arising out of or in connection with this
                            agreement, breach of contract, tort (including
                            negligence), or otherwise, regardless of whether
                            such damages were foreseeable and whether or not
                            Ride Report was advised of the possibility of such
                            damages.
                        </li>
                        <li>
                            In no event will Ride Report and its Affiliates',
                            including any of its or their respective Licensors'
                            and Service Providers', collective aggregate
                            liability under or in connection with this Agreement
                            or its subject matter, under any legal or equitable
                            theory, including breach of contract, tort
                            (including negligence), strict liability, and
                            otherwise, exceed $100.
                        </li>
                        <li>
                            The limitations set forth in Section 8.1 and Section
                            8.2 will apply even if the licensee's remedies under
                            this agreement fail of their essential purpose.
                        </li>
                        <li>
                            Nothing in the foregoing restricts the effect of
                            warranties or conditions which may be implied by law
                            which cannot be excluded, restricted, or modified
                            notwithstanding a contractual restriction to the
                            contrary.
                        </li>
                    </ol>
                </div>
            </section>
            <section className="section">
                <h2>Miscellaneous</h2>
                <ol>
                    <li>
                        Ride Report will not be responsible or liable to
                        Licensee, or deemed in default or breach hereunder by
                        reason of any failure or delay in the performance of its
                        obligations hereunder where such failure or delay is due
                        to strikes, labor disputes, civil disturbances, riot,
                        rebellion, invasion, epidemic, hostilities, war,
                        terrorist attack, embargo, natural disaster, acts of
                        God, flood, fire, sabotage, fluctuations or
                        non-availability of electrical power, heat, light, air
                        conditioning, or Licensee equipment, loss and
                        destruction of property, or any other circumstances or
                        causes beyond Ride Report's reasonable control
                    </li>
                    <li>
                        This Agreement, together with the applicable Software
                        Services Agreement, and all other documents that are
                        incorporated by reference herein constitutes the sole
                        and entire agreement between Licensee and Ride Report
                        with respect to the subject matter contained herein, and
                        supersedes all prior and contemporaneous understandings,
                        agreements, representations, and warranties, both
                        written and oral, with respect to such subject matter.
                    </li>
                    <li>
                        Licensee will not assign or otherwise transfer any of
                        its rights, or delegate or otherwise transfer any of its
                        obligations or performance, under this Agreement, in
                        each case whether voluntarily, involuntarily, by
                        operation of law or otherwise, without Ride Report's
                        prior written consent, which consent Ride Report may
                        give or withhold in its sole discretion. No delegation
                        or other transfer will relieve Licensee of any of its
                        obligations or performance under this Agreement. Any
                        purported assignment, delegation, or transfer in
                        violation of this Section 9.3 is void. Ride Report may
                        freely assign or otherwise transfer all or any of its
                        rights, or delegate or otherwise transfer all or any of
                        its obligations or performance, under this Agreement
                        without Licensee's consent. This Agreement is binding
                        upon and inures to the benefit of the parties hereto and
                        their respective permitted successors and assigns.
                    </li>
                    <li>
                        This Agreement is for the sole benefit of the parties
                        hereto and their respective successors and permitted
                        assigns and nothing herein, express or implied, is
                        intended to or will confer on any other Person any legal
                        or equitable right, benefit, or remedy of any nature
                        whatsoever under or by reason of this Agreement.
                    </li>
                    <li>
                        This Agreement may only be amended, modified, or
                        supplemented by an agreement in writing signed by each
                        party hereto. No waiver by any party of any of the
                        provisions hereof will be effective unless explicitly
                        set forth in writing and signed by the party so waiving.
                        Except as otherwise set forth in this Agreement, no
                        failure to exercise, or delay in exercising, any right,
                        remedy, power, or privilege arising from this Agreement
                        will operate or be construed as a waiver thereof; nor
                        will any single or partial exercise of any right,
                        remedy, power, or privilege hereunder preclude any other
                        or further exercise thereof or the exercise of any other
                        right, remedy, power, or privilege.
                    </li>
                    <li>
                        If any term or provision of this Agreement is invalid,
                        illegal, or unenforceable in any jurisdiction, such
                        invalidity, illegality, or unenforceability will not
                        affect any other term or provision of this Agreement or
                        invalidate or render unenforceable such term or
                        provision in any other jurisdiction.
                    </li>
                </ol>
            </section>
            <p>
                This Agreement will be construed without regard to any
                presumption or rule requiring construction or interpretation
                against the party drafting an instrument or causing any
                instrument to be drafted.
            </p>
        </div>
    );
}

export default Eula;
