import { gql } from '@apollo/client';
import {
    Distance,
    Duration,
    METERS_PER_KILOMETER,
    SECONDS_PER_DAY,
    SECONDS_PER_HOUR,
    SECONDS_PER_MINUTE,
    UNIT_IN_COMMON_VALUE
} from 'common/units';
import { CreateTripFilterInput, VehicleClass } from 'graphql.g';

const TRIP_FILTER_INFO_FRAGMENT = gql`
    fragment TripFilterInfo on TripFilter {
        id
        vehicleClass
        minDuration
        maxDuration
        minDistance
        maxDistance
    }
`;

export const GET_TRIP_FILTERS = gql`
    query GetTripFilters($slug: String!) {
        mapView(slug: $slug) {
            id
            tripFilters {
                ...TripFilterInfo
            }
        }
    }
    ${TRIP_FILTER_INFO_FRAGMENT}
`;
export const TRIP_FILTER_WITH_EXCLUDED_FRAGMENT = gql`
    fragment TripFilterWithExcludedPercent on TripFilter {
        ...TripFilterInfo
        excludedPercent(mapViewSlug: $slug)
        excludedPercentByOperator(mapViewSlug: $slug) {
            operator {
                id
                name
            }
            excludedPercent
        }
    }
    ${TRIP_FILTER_INFO_FRAGMENT}
`;

export type FilterValue = Duration | Distance;
export type Filter = {
    minDistance: FilterValue | null;
    maxDistance: FilterValue | null;
    minDuration: FilterValue | null;
    maxDuration: FilterValue | null;
};

export function mdsDistanceInUnits(meters: number): FilterValue {
    if (!meters) {
        return { unit: 'meters', value: meters };
    } else if (meters && meters % METERS_PER_KILOMETER === 0) {
        return { unit: 'kilometers', value: meters / METERS_PER_KILOMETER };
    } else {
        return { unit: 'meters', value: meters };
    }
}

export function mdsDurationInUnits(seconds: number): FilterValue {
    if (!seconds) {
        return { unit: 'seconds', value: seconds };
    } else if (seconds % SECONDS_PER_DAY === 0) {
        return { unit: 'days', value: seconds / SECONDS_PER_DAY };
    } else if (seconds % SECONDS_PER_HOUR === 0) {
        return { unit: 'hours', value: seconds / SECONDS_PER_HOUR };
    } else if (seconds % SECONDS_PER_MINUTE === 0) {
        return { unit: 'minutes', value: seconds / SECONDS_PER_MINUTE };
    } else {
        return { unit: 'seconds', value: seconds };
    }
}

/** Convert a value with a unit into the smallest unit size of that type
 *
 * (ie, hours into minutes, kilometers into meters) */
export function filterValueToCommonValue(
    filterValue: FilterValue | null
): number | null {
    if (!filterValue) return null;
    return UNIT_IN_COMMON_VALUE[filterValue.unit] * filterValue.value;
}

/** Convert our form's idea of a trip filter into the API's idea of a trip filter */
export function filterToInput(
    vehicleClass: VehicleClass,
    filter: Filter
): CreateTripFilterInput {
    return {
        vehicleClass,
        minDistance: filterValueToCommonValue(filter.minDistance),
        maxDistance: filterValueToCommonValue(filter.maxDistance),
        minDuration: filterValueToCommonValue(filter.minDuration),
        maxDuration: filterValueToCommonValue(filter.maxDuration)
    };
}
