import React, { useState, useEffect, useContext } from 'react';

// NOTE: this method was adapted from this blog post!
// https://blog.logrocket.com/developing-responsive-layouts-with-react-hooks/

/** Breakpoint constants based off of our SCSS mixin `mobile` */
export const MOBILE_BREAKPOINT = 1188;
/** Breakpoint constants based off of our SCSS mixin `mobile-tiny` */
export const MOBILE_TINY_BREAKPOINT = 840;

interface ViewportContext {
    width: number;
    height: number;
}

const viewportContext = React.createContext<ViewportContext>({
    width: -1,
    height: -1
});

/** Get the current state of the window viewport size */
export const getViewport = () => ({
    width: window.innerWidth,
    height: window.innerHeight
});

/** Provides the window viewport size to components */
export function ViewportProvider({ children }) {
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const handleWindowResize = () => {
        const viewport = getViewport();
        setWidth(viewport.width);
        setHeight(viewport.height);
    };

    useEffect(() => {
        window.addEventListener('resize', handleWindowResize);
        return () => window.removeEventListener('resize', handleWindowResize);
    }, []);

    return (
        <viewportContext.Provider value={{ width, height }}>
            {children}
        </viewportContext.Provider>
    );
}

/** Hook that gives the current width and height of the viewport */
export function useViewport() {
    const { width, height } = useContext(viewportContext);
    return { width, height };
}
