import React from 'react';
import "/opt/build/repo/src/common/logged-out-header.tsx?resplendence=true";

import { Link } from 'react-router-dom';
import { Logos } from 'root/app-header';

/*
@import 'style.scss';
*/;

const HEADER = "rx-common-logged-out-header-1"/*
    height: 54rem;
    flex: 0 0 auto;
    background-color: $gray-90;
    padding: 14rem 16rem;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
*/;

const LINK = "rx-common-logged-out-header-2"/*
    height: 100%;
    width: auto;
*/;

interface Props {
    /** A url to link to from the logo */
    logoLinkOverride?: string;
    /** if the link is external or internal, defaults to false */
    externalLogoLink?: boolean;
}

/**
 * A simplified version of AppHeader that doesn't rely on user data,
 * or anything that would require an authenticated user to view.
 * Can be used on routes where the user may not be logged in.
 */
function LoggedOutHeader({
    logoLinkOverride,
    externalLogoLink = false
}: Props) {
    const isExternal = externalLogoLink && !!logoLinkOverride;
    const linkTo = logoLinkOverride ?? '/';

    return (
        <header className={HEADER}>
            {isExternal ? (
                <a
                    href={linkTo}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={LINK}
                >
                    <Logos />
                </a>
            ) : (
                <Link to={linkTo} className={LINK}>
                    <Logos />
                </Link>
            )}
        </header>
    );
}

export default LoggedOutHeader;
