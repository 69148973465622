import React, { useMemo, useCallback, useState, Suspense, lazy } from 'react';
import gql from 'graphql-tag';
import "/opt/build/repo/src/root/page-region.tsx?resplendence=true";

import { Route, Switch } from 'router';
import { MapView as MapViewType, MapViewOperator } from 'types';
import Loading from 'common/loading';
import { useGraphql, FetchError } from 'utils/use-data';
import { sortOperators } from 'utils/helpers';
import { MapViewProvider } from 'common/use-map-view';
import { useUser } from 'common/user';
import AppHeader from './app-header';
import ErrorBoundary from 'common/error-boundary';
import HealthPage from 'page-health';
import AnalyzePage from 'page-analyze';
import ReportsPage from 'page-report';
import CustomDataPage from 'page-custom-data';
import FeesPage from 'page-fees';
import ConfidentialWarning from 'root/confidential';
import NavigationMenu from 'root/navigation-menu';
import { PageContent } from 'common/layout';
import { PAGE_NOT_FOUND } from 'strings';
import { IntlProvider } from 'utils/use-intl';
import {
    MapViewOverviewData,
    MapViewOverviewArgs,
    FeatureFlaggedFeature
} from 'graphql.g';
import PolicyPage from 'page-policy';
import LOCAL_STORAGE_KEYS from 'constants/local-storage';
import lazyErrorHandler from 'common/lazy-error-handler';
import LoadingSite from './loading-site';
import { Title } from 'common/title';
import { getLastReportDate } from 'common/use-last-report-date';
import { CustomDatasetsProvider } from 'page-custom-data/custom-data-context';
import ModeShiftPage from 'page-mode-shift';
import SegmentEditorPage from 'page-segment-editor';

const MapPage = lazy(() => lazyErrorHandler(() => import('page-explore')));
const AreasPage = lazy(() => lazyErrorHandler(() => import('page-areas')));

/*
@import 'style';
*/;

const REGION = "rx-root-page-region-1"/*
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: stretch;
    grid-template-rows: 100%;
    flex: 1 1 auto;
    overflow: hidden;
    @media print {
        display: block;
    }
*/;

const PAGE = "rx-root-page-region-2"/*
    display: grid;
    position: relative;
    align-items: stretch;
    overflow: hidden;
    @media print {
        display: block;
    }
*/;

const QUERY = gql`
    query MapViewOverview($slug: String) {
        mapView(slug: $slug) {
            id
            name
            slug
            ianaTimezone
            initialViewBounds
            maxBounds
            hasFees
            measurementSystem
            enabledFeatures
            forOperator {
                id
                slug
                name
            }
            operators {
                ...MapViewOperator
            }
            activeSources {
                id
                info {
                    ...MapViewOperatorInfo
                }
            }
        }
    }
    fragment MapViewOperator on Operator {
        id
        operatorId
        hasMdsData
        name
        info {
            ...MapViewOperatorInfo
        }
    }
    fragment MapViewOperatorInfo on OperatorInfo {
        id
        name
        slug
    }
`;

export function useMapView(slug: string, isStaff: boolean): MapViewType | null {
    // We're don't need the middle value, "loading", so we skip over it.
    const [data, , error] = useGraphql<
        MapViewOverviewData,
        MapViewOverviewArgs
    >(QUERY, { slug });
    const mapViews = useUser().orgAuth.organization.mapViews;

    // if it's a staff user, there's a chance their cached org masquerade is
    // invalid now, so we need to clear it before throwing the error below. This
    // prevents a staff user from having to clear their local storage manually.
    if (error && isStaff) {
        localStorage.removeItem(LOCAL_STORAGE_KEYS.orgMasquerade);
    }

    const errorIsMissingMapView =
        error &&
        error instanceof FetchError &&
        (error.apolloMessage ===
            'GraphQL error: MapView matching query does not exist.' ||
            error.apolloMessage ===
                'GraphQL error: OrgMapViewAuthorization matching query does not exist.') &&
        mapViews.length > 1;

    if (error && !errorIsMissingMapView) throw error;

    return useMemo(() => {
        if (!data || !data.mapView) return null;
        const sortedOperators = sortOperators(data.mapView.operators);
        return {
            ...data.mapView,
            operators: sortedOperators as MapViewOperator[],
            activeOperators: data.mapView.activeSources.map(
                source => source.info
            ),
            currency: 'USD'
        };
    }, [data]);
}

export type MapView = NonNullable<ReturnType<typeof useMapView>>;

function NotFound() {
    return (
        <PageContent>
            <Title title={PAGE_NOT_FOUND} />
        </PageContent>
    );
}

interface RegionProps {
    slug: string;
}

function Region({ slug }: RegionProps) {
    const user = useUser();

    const mapView = useMapView(slug, user.isStaff);
    const [expandOnMobile, setExpandOnMobile] = useState(false);
    const toggleMobileNavMenu = useCallback(
        () => setExpandOnMobile(show => !show),
        []
    );

    if (!mapView) return <LoadingSite />;
    const lastReportDate = getLastReportDate(mapView.ianaTimezone);

    const canAccessCustomData =
        user.enabledFeatures.includes(FeatureFlaggedFeature.custom_datasets) ||
        mapView.enabledFeatures.includes(
            FeatureFlaggedFeature.custom_datasets
        ) ||
        user.useDemoFeatures;

    const canAccessSegmentEditing = user.isStaff && user.useDemoFeatures;
    const canAccessModeShift = user.isStaff && user.useDemoFeatures;

    return (
        <MapViewProvider value={mapView}>
            <IntlProvider locale={user.orgAuth.organization.locale}>
                <CustomDatasetsProvider>
                    <AppHeader toggleMobileNavMenu={toggleMobileNavMenu} />
                    <div className={REGION}>
                        <NavigationMenu
                            setExpandOnMobile={setExpandOnMobile}
                            expandOnMobile={expandOnMobile}
                        />
                        <div className={PAGE}>
                            <Suspense fallback={<Loading />}>
                                <ErrorBoundary>
                                    <Switch
                                        pages={[
                                            {
                                                path: '/health',
                                                component: HealthPage
                                            },
                                            {
                                                path: '/analyze',
                                                component: AnalyzePage
                                            },
                                            {
                                                path: '/report/:mode/:date',
                                                component: ReportsPage
                                            },
                                            {
                                                path: '/report',
                                                exact: true,
                                                redirectTo: `/report/daily/${lastReportDate.toString()}`
                                            },
                                            {
                                                path: '/areas',
                                                component: AreasPage
                                            },
                                            ...(canAccessModeShift
                                                ? [
                                                      {
                                                          path: '/mode-shift',
                                                          component: ModeShiftPage
                                                      }
                                                  ]
                                                : []),
                                            ...(canAccessCustomData
                                                ? [
                                                      {
                                                          path: '/custom-data',
                                                          component: CustomDataPage
                                                      }
                                                  ]
                                                : []),
                                            ...(canAccessSegmentEditing
                                                ? [
                                                      {
                                                          path:
                                                              '/segment-editor',
                                                          component: SegmentEditorPage
                                                      }
                                                  ]
                                                : []),
                                            ...(mapView.hasFees
                                                ? [
                                                      {
                                                          path: '/fees',
                                                          component: FeesPage
                                                      }
                                                  ]
                                                : []),
                                            {
                                                path: '/policy',
                                                component: PolicyPage
                                            },
                                            {
                                                path: '/explore',
                                                redirectTo: '/realtime'
                                            },
                                            // the following two routes are handled below
                                            {
                                                path: '/realtime'
                                            },
                                            {
                                                path: '/heatmaps'
                                            },
                                            {
                                                path: '/',
                                                exact: true,
                                                redirectTo: `/report/daily/${lastReportDate.toString()}`
                                            },
                                            {
                                                component: NotFound
                                            }
                                        ]}
                                    />
                                    <Route path="/:page">
                                        {() => {
                                            // We want to always render the map
                                            // page regardless of whether the user
                                            // is looking at it, so we never have
                                            // to reload the map.
                                            return <MapPage />;
                                        }}
                                    </Route>
                                </ErrorBoundary>
                            </Suspense>
                        </div>
                    </div>
                    <ConfidentialWarning />
                </CustomDatasetsProvider>
            </IntlProvider>
        </MapViewProvider>
    );
}

export default Region;
