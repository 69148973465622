import React from 'react';
import { AnalyzeData, ChartMetric, CHART_MESSAGES } from './types';
import "/opt/build/repo/src/page-analyze/metric-chart-legend.tsx?resplendence=true";
import KeyValue from 'common/key-value';
import { defineMessages, useIntl, FormattedMessage } from 'react-intl';
import { getOperatorColorStyle, getDataColor } from 'utils/helpers';
import { OperatorName } from 'constants/operators';
import useTippy from 'utils/use-tippy';
import {
    AVERAGE_AGGREGATE_COUNTS,
    LineStyle,
    NEEDS_OPERATOR_SELECTED
} from './metric-chart';
import { AnalyzeDataAggregateUnit } from 'graphql.g';

/*
@import 'style.scss';
*/;

const LEGEND_LABELS = defineMessages({
    'no-data-points': 'No data returned. Try selecting different filters.',
    'no-operator-selected': 'No operator selected',
    'no-area-selected': 'No area selected'
});

const m = defineMessages({
    'max_available-median-label': 'Median Max Available',
    'mean_available-median-label': 'Median Available',
    'trips_per_mean_available_vehicle-median-label':
        'Median Daily Trips Per Available Vehicle',
    'trips_per_mean_available_vehicle-average-label':
        'Average Daily Trips Per Available Vehicle',
    'mean_active-median-label': 'Median Active Vehicles per Day',
    'trips_per_mean_active_vehicle-median-label':
        'Median Daily Trips Per Active Vehicle',
    'trips_per_mean_active_vehicle-average-label':
        'Average Daily Trips Per Active Vehicle',
    'trip_duration-median-label': 'Median Duration',
    'trip_distance-median-label': 'Median Distance',
    'trip_distance-sum-total-label': 'Total Distance Traveled',
    'trips_by_hour-median-label': 'Median Per Hour',
    'trips_by_date-median-label': 'Median Per Day',
    'average_vehicle_idle_time-median-label': 'Median Vehicle Idle Time',
    'aggregate-metric-header': 'Aggregate Metrics',
    'trips-count-unit-message':
        '{count, number} {count, plural, one {trip} other {trips}}',
    'minutes-unit-message': '{count, number} min',
    'kilometers-unit-message': '{count, number} km',
    'miles-unit-message': '{count, number} mi',
    'trips-by-hour-unit-message':
        '{count, number} {count, plural, one {trip} other {trips}}',
    'trips-by-day-unit-message':
        '{count, number} {count, plural, one {trip} other {trips}}',
    'trips-per-vehicle-unit-message':
        '{count, number} {count, plural, one {trip} other {trips}} per vehicle',
    'vehicles-unit-message':
        '{count, number} {count, plural, one {vehicle} other {vehicles}}',
    'hours-unit-message': '{count, number} hours',
    'preliminary-data-tooltip':
        "This chart includes preliminary data that may be missing events that haven't been reported yet. Data is finalized after three days.",
    'max_available-median-tooltip': 'The median of daily maximums.',
    'median-of-averages-tooltip': 'The median of daily averages.',
    'average-of-averages-tooltip': 'The average of daily averages.',
    'average-of-totals-tooltip': 'The average of daily totals.',
    'trips_by_date-median-tooltip': 'The median of daily trip counts.',
    'trips_by_hour-median-tooltip': 'The median count of trips by hour.',
    'trip_distance-median-tooltip': 'The median trip distance.',
    'trip_duration-median-tooltip': 'The median trip duration.',
    'trips_per_day_by_hour-median-tooltip':
        'The median count of trips by hour and day of week',
    'trips_per_day_by_hour-median-label': 'Median Per Hour & Weekday',
    'trips_by_weekday-median-tooltip':
        'The median count of trips by hour and day of week',
    'trips_by_weekday-median-label': 'Median Per Weekday',
    'total_trip_distance_by_day-average-label': 'Average Total Trip Distance',
    'total_trip_distance_by_day-sum-total-label': 'Total Distance Traveled',
    'co2_tons_saved_by_day-sum-total-label': 'Total',
    'co2-unit-message': '{count} Metric Tons',
    'trip_duration_mode_shift-sum-total-label': 'Total',
    'trip_duration_mode_shift-sum-total-tooltip':
        'Additional minutes of physical activity resulting from mode shift',
    'car_trips_shifted-median-tooltip':
        'Median number of car trips replaced by shared mobility trips',
    'car_trips_shifted-sum-total-label': 'Total',
    'car_trips_shifted-median-label': 'Median Car Trips Replaced'
});

const METRIC_DEFINITION_TOOLTIPS = {
    'mean_available-median': m['median-of-averages-tooltip'],
    'trips_per_mean_available_vehicle-median': m['median-of-averages-tooltip'],
    'mean_active-median': m['median-of-averages-tooltip'],
    'trips_per_mean_active_vehicle-median': m['median-of-averages-tooltip'],
    'average_vehicle_idle_time-median': m['median-of-averages-tooltip'],
    'max_available-median': m['max_available-median-tooltip'],
    'trips_by_date-median': m['trips_by_date-median-tooltip'],
    'trips_by_hour-median': m['trips_by_hour-median-tooltip'],
    'trip_distance-median': m['trip_distance-median-tooltip'],
    'trip_duration-median': m['trip_duration-median-tooltip'],
    'trips_per_mean_available_vehicle-average':
        m['average-of-averages-tooltip'],
    'trips_per_mean_active_vehicle-average': m['average-of-averages-tooltip'],
    'trips_per_day_by_hour-median': m['trips_per_day_by_hour-median-tooltip'],
    'trips_by_weekday-median': m['trips_by_weekday-median-tooltip'],
    'total_trip_distance_by_day-average': m['average-of-totals-tooltip'],
    'car_trips_shifted-median': m['car_trips_shifted-median-tooltip']
};

const COUNT_WITH_UNIT_LABELS = {
    trips: m['trips-count-unit-message'],
    minutes: m['minutes-unit-message'],
    kilometers: m['kilometers-unit-message'],
    miles: m['miles-unit-message'],
    trips_per_hour: m['trips-by-hour-unit-message'],
    trips_per_day: m['trips-by-day-unit-message'],
    trips_per_vehicle: m['trips-per-vehicle-unit-message'],
    vehicles: m['vehicles-unit-message'],
    hours: m['hours-unit-message'],
    trips_per_day_by_hour: m['trips-by-hour-unit-message'],
    co2_tons: m['co2-unit-message']
};

const LEGEND_METRICS = "rx-page-analyze-metric-chart-legend-1"/*
    display: flex;
    align-items: flex-start;
    margin-right: 16rem;
    margin-top: 8rem;
    & > *:not(:last-child) {
        margin-top: 11rem;
        margin-right: 16rem;
    }
    & > p {
        margin-right: 16rem;
    }
*/;

const LEGEND_LINE = "rx-page-analyze-metric-chart-legend-2"/*
    display: block;
    height: 1rem;
    border: 0;
    margin: 1em 0;
    padding: 0;
    width: 100rem;
    margin-right: 16rem;
    border-top-width: 3rem;
*/;

const LEGEND_LABEL = "rx-page-analyze-metric-chart-legend-3"/*
    @include tooltip;
*/;

type LEGEND_PROPS = {
    analyzeData: AnalyzeData | null;
    metric: ChartMetric;
    operator?: string;
    index: number;
    style: LineStyle;
    label: string | null;
    error: string | null;
    showLine: boolean;
};

type LegendLabelProps = {
    metric: ChartMetric;
    label: string | null;
    description: string | null;
};

function LegendLabel({ metric, label, description }: LegendLabelProps) {
    const [metricDescriptionTippy] = useTippy<HTMLDivElement>(
        description ?? null,
        {
            placement: 'top'
        }
    );

    return (
        <FormattedMessage
            key="legend-label"
            defaultMessage="<0>{metric}</0> {rest}"
            tagName="span"
            values={{
                metric: CHART_MESSAGES[metric].defaultMessage,
                0: metric => (
                    <span className={LEGEND_LABEL} ref={metricDescriptionTippy}>
                        {metric}
                    </span>
                ),
                rest: label?.slice(
                    CHART_MESSAGES[metric].defaultMessage?.length
                )
            }}
        />
    );
}

function Legend({
    analyzeData,
    metric,
    operator,
    index,
    style,
    label,
    error,
    showLine
}: LEGEND_PROPS) {
    const intl = useIntl();
    const aggregateUnit = analyzeData?.aggregateUnit;
    const medianCount = analyzeData?.dataLine.median ?? 0;
    const averageCount = analyzeData?.dataLine.average ?? 0;
    const sumTotal = analyzeData?.dataLine.sumTotal || null;
    const totalCount = analyzeData?.dataLine.totalCount || null;
    const totalCountUnit = analyzeData?.totalCountUnit ?? 0;

    const legendLine = showLine ? (
        <hr
            className={LEGEND_LINE}
            style={{
                borderTopColor: operator
                    ? getOperatorColorStyle(operator as OperatorName)
                          .backgroundColor
                    : getDataColor(index).backgroundColor,
                borderTopStyle: style
            }}
        />
    ) : null;

    if (error)
        return (
            <div className={LEGEND_METRICS}>
                {legendLine}
                {intl.formatMessage(LEGEND_LABELS[error], {
                    operator: operator
                })}
            </div>
        );

    if (!aggregateUnit) {
        return null;
    }

    return (
        <div>
            {!(
                NEEDS_OPERATOR_SELECTED.includes(metric) && operator === null
            ) ? (
                aggregateUnit !== 'vehicles' ? (
                    <div className={LEGEND_METRICS}>
                        {legendLine}
                        <div>
                            <LegendLabel
                                metric={metric}
                                label={label}
                                description={
                                    analyzeData?.metricDefinition.description ??
                                    null
                                }
                            />
                            {totalCount && totalCount > 0 && (
                                <KeyValue
                                    keyName={
                                        <FormattedMessage
                                            key="total"
                                            defaultMessage="Total"
                                        />
                                    }
                                >
                                    {intl.formatMessage(
                                        COUNT_WITH_UNIT_LABELS[totalCountUnit],
                                        {
                                            count: totalCount
                                        }
                                    )}
                                </KeyValue>
                            )}
                            {AVERAGE_AGGREGATE_COUNTS.includes(metric)
                                ? averageCount > 0 && (
                                      <ExplainedMetric
                                          metric={metric}
                                          aggregate={'average'}
                                          aggregateUnit={aggregateUnit}
                                          count={averageCount}
                                      />
                                  )
                                : medianCount > 0 && (
                                      <ExplainedMetric
                                          metric={metric}
                                          aggregate={'median'}
                                          aggregateUnit={aggregateUnit}
                                          count={medianCount}
                                      />
                                  )}
                            {sumTotal &&
                                (metric === 'trip_distance' ||
                                    metric ===
                                        'total_trip_distance_by_day') && (
                                    <KeyValue
                                        keyName={intl.formatMessage(
                                            m[`${metric}-sum-total-label`]
                                        )}
                                    >
                                        {intl.formatMessage(
                                            COUNT_WITH_UNIT_LABELS[
                                                aggregateUnit
                                            ],
                                            {
                                                count: sumTotal
                                            }
                                        )}
                                    </KeyValue>
                                )}
                        </div>
                    </div>
                ) : (
                    <div className={LEGEND_METRICS}>
                        {legendLine}
                        <div>
                            <LegendLabel
                                metric={metric}
                                label={label}
                                description={
                                    analyzeData?.metricDefinition.description ??
                                    null
                                }
                            />
                            {medianCount > 0 && (
                                <ExplainedMetric
                                    metric={metric}
                                    aggregate={'median'}
                                    aggregateUnit={aggregateUnit}
                                    count={medianCount}
                                />
                            )}
                        </div>
                    </div>
                )
            ) : null}
        </div>
    );
}

type EXPLAINED_METRIC_PROPS = {
    metric: ChartMetric;
    aggregate: 'average' | 'median';
    aggregateUnit: AnalyzeDataAggregateUnit;
    count: number;
};

const EXPLAIN_METRIC_VALUE = "rx-page-analyze-metric-chart-legend-4"/*
    @include tooltip;
    @include text-body-bold;
    &:after {
        content: ": ";
    }
*/;

function ExplainedMetric({
    metric,
    aggregate,
    aggregateUnit,
    count
}: EXPLAINED_METRIC_PROPS) {
    const intl = useIntl();

    const [tooltip] = useTippy<HTMLDivElement>(
        intl.formatMessage(
            METRIC_DEFINITION_TOOLTIPS[`${metric}-${aggregate}`]
        ),
        {
            placement: 'top'
        }
    );

    return (
        <span ref={tooltip}>
            <p>
                <span className={EXPLAIN_METRIC_VALUE}>
                    {intl.formatMessage(m[`${metric}-${aggregate}-label`])}
                </span>
                {intl.formatMessage(COUNT_WITH_UNIT_LABELS[aggregateUnit], {
                    count
                })}
            </p>
        </span>
    );
}

export default Legend;
