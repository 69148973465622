import React from 'react';
import { Switch } from 'router';
import PageHealthReport from './page-health-report';
import PageTripFilter, {
    useTripFilterEditPermission
} from './page-trip-filter';
import PageTripFilterNew from './page-trip-filter-new';

function HealthPage() {
    const canEditTripFilter = useTripFilterEditPermission();
    return (
        <Switch
            pages={[
                ...(canEditTripFilter
                    ? [
                          {
                              path: 'trip-filter/new',
                              component: PageTripFilterNew
                          }
                      ]
                    : []),
                { path: 'trip-filter', component: PageTripFilter },
                { path: 'status', component: PageHealthReport },
                { redirectTo: 'status' }
            ]}
        />
    );
}

export default HealthPage;
