import React, { useEffect, useMemo, useState } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';

import {
    DateSelectInput,
    FormInput,
    FormNotice,
    TextareaInput,
    TextInput
} from './common';
import { useAreas } from 'common/use-areas';
import { PolicyFormProps } from './types';
import { Select } from 'common/select';
import { Checkbox, Toggle } from 'common/layout';
import "/opt/build/repo/src/page-policy/forms/form-base.tsx?resplendence=true";

/*
@import 'style.scss';
*/;

const BASE_FORM = "rx-page-policy-forms-form-base-1"/*
    margin-bottom: 24rem;
*/;

const DATE_ROW = "rx-page-policy-forms-form-base-2"/*
    display: grid;
    grid-auto-columns: 2fr;
    grid-auto-flow: column;
    grid-gap: 16rem;
    & > div {
        display: flex;
        flex-direction: column;
    }
*/;

const m = defineMessages({
    'name-placeholder': 'Policy Name',
    'description-placeholder':
        'Provide context for this policy, or describe the intended outcome.',
    'vehicle-class-all': 'All',
    'municipality-boundary': '{name} (Municipality Boundary)',
    'publish-to-odp': 'Publish to Open Data Portal'
});

/**
 * The base form component that contains fields that every Policy form share.
 *
 * These are:
 *  - name
 *  - description
 *  - start date
 *  - optional end date
 *  - area
 */
function BaseForm({
    policy,
    setPolicy,
    formErrors,
    setFormErrors,
    editMode
}: PolicyFormProps) {
    const intl = useIntl();
    // NOTE: All fields here will be shared on every Policy form. If a field
    // only belongs on some forms, please add it to those specific forms instead
    // of adding conditionals here.
    const areas = useAreas();

    // only allow policies to be created that apply to areas that are shared
    // with operators
    const sharedAreas = useMemo(
        () => areas?.filter(a => a.sharedWithOperators),
        [areas]
    );

    //The end date is optional, if it isn't set the policy has no end date.
    const [noEndDate, setNoEndDate] = useState(true);

    function handleClick(hasNoEndDate) {
        setNoEndDate(hasNoEndDate);
        setPolicy({
            ...policy,
            endDate: hasNoEndDate ? null : policy.startDate
        });
    }

    //For edit mode, we want to start with the endDate of the original policy
    useEffect(() => {
        if (editMode) {
            setNoEndDate(policy.endDate == null);
        }
    }, [editMode, policy.endDate]);

    //If the start date is set after the end date, update the end date to the selected start date.
    useEffect(() => {
        if (policy.endDate != null) {
            if (policy.startDate.isAfter(policy.endDate)) {
                setPolicy({
                    ...policy,
                    endDate: policy.startDate
                });
            }
        }
    }, [policy, policy.endDate, policy.startDate, setPolicy]);

    useEffect(() => {
        const newErrors = {
            area: policy.area == null,
            startDate: policy.startDate == null
        };

        if (
            newErrors.area !== formErrors.area ||
            newErrors.startDate !== formErrors.startDate
        ) {
            setFormErrors({
                ...formErrors,
                ...newErrors
            });
        }
    }, [policy.startDate, policy.area, formErrors, setFormErrors]);

    return (
        <div className={BASE_FORM}>
            <TextInput
                id="name-input"
                label={
                    <FormattedMessage key="name-label" defaultMessage="Name:" />
                }
                onChange={(name: string) => {
                    setPolicy({
                        ...policy,
                        name
                    });
                }}
                value={policy.name}
                placeholder={intl.formatMessage(m['name-placeholder'])}
            />

            <TextareaInput
                id="description-input"
                label={
                    <FormattedMessage
                        key="description-label"
                        defaultMessage="Description:"
                    />
                }
                onChange={(description: string) => {
                    setPolicy({
                        ...policy,
                        description
                    });
                }}
                value={policy.description}
                placeholder={intl.formatMessage(m['description-placeholder'])}
            />

            <div className={DATE_ROW}>
                <DateSelectInput
                    label={
                        <FormattedMessage
                            key="start-date-label"
                            defaultMessage="Start Date:"
                        />
                    }
                    id="date-select-input"
                    selectedDate={policy.startDate}
                    onChange={selectedDate =>
                        setPolicy({
                            ...policy,
                            startDate: selectedDate
                        })
                    }
                />
                <div>
                    <DateSelectInput
                        label={
                            <FormattedMessage
                                key="end-date-label"
                                defaultMessage="End Date:"
                            />
                        }
                        id="date-select-input"
                        selectedDate={noEndDate ? null : policy.endDate}
                        onChange={selectedDate =>
                            setPolicy({
                                ...policy,
                                endDate: selectedDate
                            })
                        }
                        disabled={noEndDate}
                        earliestAllowed={policy.startDate}
                    />

                    <FormInput labelId="optional-end-date-input" label={''}>
                        <Checkbox
                            checked={noEndDate}
                            onChange={() => handleClick(!noEndDate)}
                            label={
                                <FormattedMessage
                                    key="no-end-date"
                                    defaultMessage="No end date"
                                />
                            }
                        />
                    </FormInput>
                </div>
            </div>

            <FormInput
                labelId="area-select-input"
                label={
                    <FormattedMessage key="area-label" defaultMessage="Area:" />
                }
            >
                <Select
                    isDisabled={editMode}
                    options={sharedAreas ?? []}
                    getOptionLabel={area =>
                        area.isMunicipalityBoundary
                            ? intl.formatMessage(m['municipality-boundary'], {
                                  name: area.name
                              })
                            : area.name
                    }
                    getOptionValue={area => area.name}
                    onChange={area => {
                        setPolicy({
                            ...policy,
                            area
                        });
                    }}
                    value={policy.area ?? []}
                />
            </FormInput>
            <FormNotice
                showNotice={
                    !!sharedAreas &&
                    !!areas &&
                    sharedAreas.length !== areas.length
                }
            >
                {!editMode && (
                    <FormattedMessage
                        key="filtered-areas-notice"
                        defaultMessage="Only areas visible on operator dashboards can be selected."
                    />
                )}
            </FormNotice>

            {editMode ? (
                <FormInput labelId="public-select-input" label={''}>
                    <Toggle
                        checked={policy.isPublic}
                        onChange={value =>
                            setPolicy({ ...policy, isPublic: value })
                        }
                        label={intl.formatMessage(m['publish-to-odp'])}
                    />
                </FormInput>
            ) : null}
        </div>
    );
}

export default BaseForm;
