import React from 'react';
import { LocalDate, LocalWeek } from 'utils/date-tools';
import HeaderNav from './report-nav';
import { useIntl } from 'utils/use-intl';
import { defineMessages } from 'react-intl';
import { useMapView } from 'common/use-map-view';
import { useFinalizedAggregatedReports } from './report-picker-common';
import { AggregatedReportPeriod } from 'graphql.g';

const m = defineMessages({
    'last-week-link': 'Last Week'
});

type Props = {
    week: LocalWeek;
};

function WeeklyNav({ week }: Props) {
    const mapView = useMapView();
    const intl = useIntl();
    const lastWeek = LocalDate.todayInTz(mapView.ianaTimezone)
        .toLocalWeek()
        .minusWeeks(1);

    const [finalizedReports] = useFinalizedAggregatedReports(
        mapView,
        AggregatedReportPeriod.weekly,
        week.minusWeeks(3).monday,
        week.plusWeeks(3).sunday
    );
    const reportDays = Object.keys(finalizedReports).map(
        LocalDate.fromDateString
    );
    let nextReport: LocalDate | null = null;

    for (const report of reportDays) {
        if (report.isAfter(week.monday)) {
            nextReport = report;
            break;
        }
    }
    let prevReport: LocalDate | null = null;
    for (const report of reportDays) {
        if (!report.isBefore(week.monday)) {
            break;
        } else {
            prevReport = report;
        }
    }
    return (
        <HeaderNav
            mapView={mapView}
            mostRecent={lastWeek.monday}
            mostRecentLabel={intl.formatMessage(m['last-week-link'])}
            nextReport={nextReport}
            previousReport={prevReport}
            selectedReport={week}
        />
    );
}

export default WeeklyNav;
