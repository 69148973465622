import React from 'react';
import RootRouter from './page-app-root-router';
import Apollo from './apollo';
import AuthInfoProvider from 'common/authentication';
import UserProvider from './page-app-user-provider';
import ErrorBoundary from 'common/error-boundary';

/**
 * All of the dashboard functionality.
 */
function App() {
    return (
        <AuthInfoProvider>
            <Apollo>
                <UserProvider>
                    <ErrorBoundary size="large">
                        <RootRouter />
                    </ErrorBoundary>
                </UserProvider>
            </Apollo>
        </AuthInfoProvider>
    );
}

export default App;
