import React from 'react';
import "/opt/build/repo/src/page-report/report-nav.tsx?resplendence=true";

import { ParamLink } from 'router';
import { MapView } from 'types';
import Icon from 'common/icon';
import ReportPicker, { ReportDate } from './report-picker';
import { LocalDate } from 'utils/date-tools';

/*
@import "style";
@import "~react-day-picker/lib/style.css";

@mixin daily-nav-border {
    @include link-border;
    height: 36rem;
    border-radius: 6rem;
    box-sizing: border-box;
    padding: 6rem;
}
*/;

const SELECT_DAY = "rx-page-report-report-nav-1"/*
    display: flex;
    gap: 8rem;
    flex-flow: row nowrap;
    height: 36rem;
    align-items: stretch;
    width: 100%;

    > :first-child {
        flex: 1;
    }
*/;

const NAV_CONTAINER = "rx-page-report-report-nav-2"/*
    display: grid;
    grid: 1fr / auto-flow auto;
    gap: 8rem;
*/;

const NAV_LINK = "rx-page-report-report-nav-3"/*
    @include daily-nav-border;
    width: 36rem;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    font-size: 24rem;
    color: $gray-70;
    transition-property: color, background-color, border-color;
    transition-duration: 200ms;
    user-select: none;

    &.disabled {
        cursor: default;
        background-color: $gray-30;
        color: $white;
    }
    &:not(.disabled) {
        @include shadow-2;
        &:hover {
            @include shadow-hover;
        }
    }
*/;

const RECENT_LINK = "rx-page-report-report-nav-4"/*
    @include button;
    @include text-body;
    color: $white;
    padding: 6rem 12rem;
    background-color: $blue-50;
    user-select: none;
    &.active {
        cursor: default;
        background-color: $gray-30;
        color: $white;
        box-shadow: none;
        &:hover {
            box-shadow: none;
            transform: none;
        }
    }
    &:not(.active) {
        @include shadow-2;
        &:hover {
            background-color: $blue-70;
            border-color: $blue-30;
            color: $white;
            @include shadow-hover
        }
    }
*/;

type HeaderNavProps = {
    mapView: MapView;
    selectedReport: ReportDate;
    previousReport: LocalDate | null;
    nextReport: LocalDate | null;
    mostRecent: LocalDate;
    mostRecentLabel: string;
};

function HeaderNav({
    mapView,
    selectedReport,
    previousReport,
    nextReport,
    mostRecent,
    mostRecentLabel
}: HeaderNavProps) {
    return (
        <div className={SELECT_DAY}>
            <ReportPicker mapView={mapView} selectedReport={selectedReport} />
            <div className={NAV_CONTAINER}>
                <ParamLink
                    className={NAV_LINK}
                    disabled={!previousReport}
                    params={{ date: previousReport?.toString() }}
                >
                    <Icon icon="Left" />
                </ParamLink>
                <ParamLink
                    className={NAV_LINK}
                    disabled={!nextReport}
                    params={{ date: nextReport?.toString() }}
                >
                    <Icon icon="Right" />
                </ParamLink>
                <ParamLink
                    className={RECENT_LINK}
                    nav
                    params={{ date: mostRecent.toString() }}
                >
                    {mostRecentLabel}
                </ParamLink>
            </div>
        </div>
    );
}

export default HeaderNav;
