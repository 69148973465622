import React from 'react';
import "/opt/build/repo/src/root/confidential.tsx?resplendence=true";
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import LOCAL_STORAGE_KEYS from 'constants/local-storage';
import useCachedState from 'utils/use-cached-state';
import Icon from 'common/icon';
import useTippy from 'utils/use-tippy';

/*
    @import 'style';
*/;

const CONFIDENTIAL_WARNING = "rx-root-confidential-1"/*
    background-color: $white;
    width: 100%;
    color: $gray-70;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    column-gap: 8rem;
    padding: 8rem;
    @include text-label;
    border-top: 1px solid $gray-30;
*/;

const TERMS_LINK = "rx-root-confidential-2"/*
    @include text-label-link;
    text-decoration: underline;
    @media print {
        display: none;
    }
*/;

const DISMISS_BUTTON = "rx-root-confidential-3"/*
    border-radius: 50%;
    background-color: $gray-30;
    height: 16rem;
    width: 16rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 24rem;

    :hover {
        background-color: $gray-50;
    }
*/;

const CLOSE_ICON = "rx-root-confidential-4"/*
    height: 12rem;
    width: 12rem;
*/;

const m = defineMessages({
    'dismiss-tooltip': 'Dismiss notice'
});

export default function ConfidentialWarning() {
    const intl = useIntl();
    const [hideNotice, setHideNotice] = useCachedState(
        LOCAL_STORAGE_KEYS.dismissedConfidentialityNotice,
        false
    );

    const [dismissTooltipRef] = useTippy<HTMLButtonElement>(
        intl.formatMessage(m['dismiss-tooltip']),
        { placement: 'top' }
    );

    // if the user has dismissed the notice, don't render anything
    if (hideNotice) {
        return null;
    }

    return (
        <footer className={CONFIDENTIAL_WARNING}>
            <FormattedMessage
                key="warning"
                defaultMessage="This data is highly confidential and not to be shared outside of official city departments."
            />{' '}
            <a href="/terms" target="_blank" className={TERMS_LINK}>
                <FormattedMessage
                    key="terms-link"
                    defaultMessage="View our Terms."
                />
            </a>
            <button
                className={DISMISS_BUTTON}
                onClick={() => setHideNotice(true)}
                ref={dismissTooltipRef}
            >
                <Icon icon="Close" className={CLOSE_ICON} />
            </button>
        </footer>
    );
}
