import { gql } from '@apollo/client';
import { useMapView } from 'common/use-map-view';
import useData from 'utils/use-data';
import { POLICY_DETAILS_FRAGMENT, POLICY_METRIC_FRAGMENT } from './fragments';
import { PolicyWithMetricsArgs, PolicyWithMetricsData } from 'graphql.g';
import { LocalDate } from 'utils/date-tools';

const POLICY_WITH_METRICS_QUERY = gql`
    query PolicyWithMetrics(
        $mapviewId: ID!
        $policyId: ID!
        $startDate: Date!
        $endDate: Date!
    ) {
        mapViewById(id: $mapviewId) {
            id
            policy(id: $policyId) {
                ...PolicyDetails
                metric(startDate: $startDate, endDate: $endDate) {
                    ...PolicyMetric
                }
            }
        }
    }
    ${POLICY_DETAILS_FRAGMENT}
    ${POLICY_METRIC_FRAGMENT}
`;

export function usePolicyWithMetrics(
    policyId: string,
    startDate: LocalDate,
    endDate: LocalDate
) {
    const { id } = useMapView();
    const data = useData<PolicyWithMetricsData, PolicyWithMetricsArgs>(
        POLICY_WITH_METRICS_QUERY,
        {
            mapviewId: id,
            policyId,
            startDate: startDate.toString(),
            endDate: endDate.toString()
        }
    );

    if (data == null) return 'loading';
    if (data.mapViewById.policy == null) return 'not found';
    return data.mapViewById.policy;
}
