import React, { Dispatch, ReactNode, useEffect, useRef } from 'react';
import {
    EventData,
    Layer,
    Map,
    MapLayerMouseEvent,
    Sources,
    Popup as MapboxPopup
} from 'mapbox-gl';
import { useRoutes, useStreetSegments } from '../../layers/routes/api';
import getFeatureForPoint from '../../common/get-feature-for-point';
import {
    AggregatedRouteFeature,
    useRoutesSources
} from '../../layers/routes/sources';
import { DataStep, useRoutesDataSteps } from '../../layers/routes/data-steps';
import { useRoutesLayers } from '../../layers/routes/layers';
import { RoutesPopup } from '../../layers/routes/popup';
import { useVehicleClass } from 'common/vehicle-classes';
import { RoutesActions, RoutesState } from './reducer';
import { DateRange } from 'common/date-picker';
import { DispatchMapOptions } from 'page-explore';

/** an id for mapbox to use to identify the aggregated routes */
export const AGGREGATED_ROUTES_SOURCE_KEY = 'aggregated-routes';
export const AGGREGATED_ROUTES_LAYER_KEY = 'routes-aggregated-trip';

interface RouteEffects {
    layers: Layer[];
    sources: Sources;
    popup: ReactNode;
    dataSteps: DataStep[] | null;
    loading: boolean;
}

/**
 * Builds and returns the mapbox layers and sources for the current set of
 * street segments and aggregated trip routes.
 *
 * `sources` contains our route data to display on the map, including relevant
 * properties such as the name and trip count for each segment.
 * `layers` tells mapbox how to display and style the data provided by the sources.
 */
export const useRouteEffects = (
    map: Map | null,
    dispatchMapOptions: DispatchMapOptions,
    state: RoutesState,
    dispatch: Dispatch<RoutesActions>,
    timePeriod: {
        dataPeriod: DateRange;
        hours: number[] | null;
    }
): RouteEffects => {
    const vehicleClass = useVehicleClass();

    const streetSegments = useStreetSegments();

    useEffect(() => {
        dispatch({
            type: 'SET_STREET_SEGMENTS',
            payload: {
                streetSegments
            }
        });
    }, [dispatch, streetSegments]);

    const [routes, isRoutesLoading] = useRoutes({
        startDate: timePeriod.dataPeriod[0].toString(),
        endDate: timePeriod.dataPeriod[1].toString(),
        hours: timePeriod.hours,
        vehicleClass
    });

    useEffect(() => {
        dispatch({
            type: 'SET_ROUTES_DATA',
            payload: {
                routes
            }
        });
    }, [dispatch, routes]);

    const sources = useRoutesSources(streetSegments, routes);
    const dataSteps = useRoutesDataSteps(routes);
    const layers = useRoutesLayers(dataSteps, state.selectedStreetSegment);
    const popupRef = useRef<HTMLDivElement>(null);

    const loading = isRoutesLoading || streetSegments == null;

    // Set selected street segment on click
    useEffect(() => {
        if (map) {
            const listener = (event: MapLayerMouseEvent & EventData) => {
                //Attach a listener that detects features under the mouse
                const feature = getFeatureForPoint(
                    map,
                    [AGGREGATED_ROUTES_LAYER_KEY],
                    event.point
                );
                if (!feature || !feature.properties || !feature.geometry) {
                    return;
                }

                dispatch({
                    type: 'SET_SELECTED_STREET_SEGMENT',
                    payload: {
                        selectedStreetSegment: {
                            id: feature.properties.id,
                            lngLatClicked: event.lngLat,
                            properties: feature.properties as AggregatedRouteFeature['properties'],
                            geometry: feature.geometry as AggregatedRouteFeature['geometry']
                        }
                    }
                });
            };
            map.on('click', listener);
            return () => {
                map.off('click', listener);
            };
        }
    }, [map, dispatch]);

    // Create the PopUp
    useEffect(() => {
        if (popupRef.current && map && state.selectedStreetSegment) {
            const copy = popupRef.current.cloneNode(true);
            const popup = new MapboxPopup()
                .setLngLat(state.selectedStreetSegment.lngLatClicked)
                .setDOMContent(copy)
                .addTo(map)
                .once('close', () => {
                    dispatch({
                        type: 'SET_SELECTED_STREET_SEGMENT',
                        payload: {
                            selectedStreetSegment: null
                        }
                    });
                });

            dispatch({
                type: 'SET_SELECTED_STREET_SEGMENT',
                payload: {
                    selectedStreetSegment: state.selectedStreetSegment
                }
            });

            return () => {
                popup.remove();
            };
        }
    }, [map, state.selectedStreetSegment, dispatch]);

    // change the cursor on hover
    useEffect(() => {
        if (map) {
            const listener = ({ point }) => {
                let feature = getFeatureForPoint(
                    map,
                    [AGGREGATED_ROUTES_LAYER_KEY],
                    point
                );
                if (feature) {
                    map.getCanvas().style.cursor = 'pointer';
                } else {
                    map.getCanvas().style.cursor = '';
                }
            };
            map.on('mousemove', listener);
            return () => {
                map.off('mousemove', listener);
            };
        }
    });

    // handle setting the loading spinner
    useEffect(() => {
        let loadingTimeout: NodeJS.Timeout | undefined;
        if (loading === true) {
            // if we're going to show the spinner, set a short timeout. This
            // gives quick updates a chance to go through without showing a loading
            // spinner, helping prevent a quick flash on the screen.
            loadingTimeout = setTimeout(() => {
                dispatchMapOptions({
                    type: 'set loading data',
                    value: true
                });
            }, 100);
        } else {
            dispatchMapOptions({
                type: 'set loading data',
                value: false
            });
        }

        return () => {
            // make sure to clear our timeout and remove the loading spinner
            // when we clean up
            if (loadingTimeout) clearTimeout(loadingTimeout);
            dispatchMapOptions({
                type: 'set loading data',
                value: false
            });
        };
    }, [dispatchMapOptions, loading]);

    useEffect(() => {
        dispatchMapOptions({
            type: 'set sources',
            payload: {
                dataType: 'routes',
                sources
            }
        });
        return () =>
            dispatchMapOptions({
                type: 'set sources',
                payload: { dataType: 'routes', sources: {} }
            });
    }, [dispatchMapOptions, sources]);

    useEffect(() => {
        dispatchMapOptions({
            type: 'set layers',
            payload: {
                dataType: 'routes',
                layers
            }
        });
        return () =>
            dispatchMapOptions({
                type: 'set layers',
                payload: { dataType: 'routes', layers: [] }
            });
    }, [dispatchMapOptions, layers]);

    return {
        layers,
        sources,
        popup: (
            <RoutesPopup
                ref={popupRef}
                dataPeriod={timePeriod.dataPeriod}
                streetSegment={state.selectedStreetSegment}
            />
        ),
        dataSteps,
        loading
    };
};
