import React, { useMemo, useState } from 'react';
import "/opt/build/repo/src/common/side-nav.tsx?resplendence=true";
import { Link } from 'router';
import useTippy from 'utils/use-tippy';
import Icon, { IconName } from './icon';
import { NavLinkProps as ReactRouterNavLinkProps } from 'react-router-dom';
import { ContainerHeader } from './layout';
import SearchInput from './search-input';
import { flatten } from 'utils/helpers';
import useSearchFilter from 'utils/search';

/*
@import "style";
*/;

const NAV_LINKS_LINK = "rx-common-side-nav-1"/*
    @include text-body-link;
    @include navigation-link;
    color: $gray-90;
    padding: 4rem 12rem;
    min-height: 40rem;
    display: flex;
    align-items: center;
    flex-flow: row nowrap;
    margin-left: 4rem;
    border-radius: 6rem;
    svg {
        flex: 0 0 auto;
        font-size: 24rem;
        margin-right: 8rem;
    }
    &:not(.active) svg {
        color: $gray-60;
    }
*/;

type NavLinkProps = {
    name: string;
    pathname?: string;
    hash?: string;
    disabled?: boolean;
    tooltip?: string | null;
    exact?: boolean;
    preserveQuery?: boolean;
    icon?: IconName;
    isActive?: ReactRouterNavLinkProps['isActive'];
};

type NavLinksProps = {
    links: NavLinkProps[];
};

function NavLink({
    name,
    pathname = '',
    hash,
    disabled = false,
    tooltip = null,
    exact,
    preserveQuery,
    icon,
    isActive
}: NavLinkProps) {
    const [linkRef] = useTippy<HTMLAnchorElement>(tooltip, {
        placement: 'left'
    });
    return (
        <Link
            ref={linkRef}
            disabled={disabled}
            key={`${pathname}#${hash ?? ''}`}
            nav
            className={NAV_LINKS_LINK}
            to={pathname}
            hash={hash}
            exact={exact}
            preserveQuery={preserveQuery}
            isActive={isActive}
        >
            {icon && <Icon icon={icon} />}
            {name}
        </Link>
    );
}

function NavLinks({ links }: NavLinksProps) {
    return (
        <>
            {links.map(link => (
                <NavLink key={link.pathname ?? link.name} {...link} />
            ))}
        </>
    );
}

const NAVS = "rx-common-side-nav-2"/*
    @include container;
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    flex: 1;
    position: sticky;
    top: -32rem;
    @media print {
        display: block;
    }
    padding: 24rem;
    @include not-mobile {
        max-height: calc(100vh - 124rem);
    }
*/;
const LINKS = "rx-common-side-nav-3"/*
    overflow: auto;
    margin: -24rem;
    padding: 24rem;
*/;
const NAV_LINKS_SUBHEADER = "rx-common-side-nav-4"/*
    margin: 24rem 0 20rem 0;
    color: $gray-60;
    @include text-label;
*/;
const NAV_LINKS_SECTION = "rx-common-side-nav-5"/*
    &:not(:last-child) {
    }
*/;

export type Section = {
    name: string;
    links: NavLinkProps[] | NavLinkProps[][];
    searchable?: boolean;
};

function NavSection({ name, links, searchable }: Section) {
    const [filter, setFilter] = useState('');
    const flattenedLinks = useMemo(
        () =>
            links[0] && Array.isArray(links[0])
                ? flatten(links as NavLinkProps[][])
                : (links as NavLinkProps[]),
        [links]
    );
    const filteredLinks = useSearchFilter(
        flattenedLinks,
        link => link.name,
        filter
    );

    return (
        <section>
            <h2 className={NAV_LINKS_SUBHEADER}>{name}</h2>
            {searchable && <SearchInput value={filter} onChange={setFilter} />}
            {filter ? (
                <NavLinks links={filteredLinks} />
            ) : links[0] && Array.isArray(links[0]) ? (
                (links as NavLinkProps[][]).map((l, i) => (
                    <div key={i} className={NAV_LINKS_SECTION}>
                        <NavLinks links={l} />
                    </div>
                ))
            ) : (
                <NavLinks links={links as NavLinkProps[]} />
            )}
        </section>
    );
}

type NavsProps = {
    title: any;

    /** A list of links that don't belong to any particular section */
    links: NavLinkProps[];

    /** Groups of links, each with their own section title */
    sections?: Section[];
};

/**
 * Sub-page navigation links, rendered as the second column of a <PageColumns>
 */
function Navs({ title, links, sections = [] }: NavsProps) {
    return (
        <nav className={NAVS}>
            <ContainerHeader small>{title}</ContainerHeader>
            <div className={LINKS}>
                <NavLinks links={links} />
                {sections.map((section, i) => (
                    <NavSection key={i} {...section} />
                ))}
            </div>
        </nav>
    );
}

export default Navs;
