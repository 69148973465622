import { gql } from '@apollo/client';
import useData from 'utils/use-data';
import { POLICY_DETAILS_FRAGMENT } from './fragments';
import { useMapView } from 'common/use-map-view';
import { PoliciesData, PoliciesArgs } from 'graphql.g';

const POLICIES_QUERY = gql`
    query Policies($id: ID!) {
        mapViewById(id: $id) {
            id
            policies {
                ...PolicyDetails
            }
            endedPolicies {
                ...PolicyDetails
            }
        }
    }
    ${POLICY_DETAILS_FRAGMENT}
`;

export function usePolicies() {
    const { id } = useMapView();
    const data = useData<PoliciesData, PoliciesArgs>(POLICIES_QUERY, { id });
    return data?.mapViewById ?? null;
}
