import React, { useEffect } from 'react';
import { DateRange, DateRangeSelect } from 'common/date-picker';
import { useEnabledFeatures } from 'common/use-feature-flags';
import DownloadButton from 'common/download-button';
import { Container, ContainerHeader, PageContent } from 'common/layout';
import Loading from 'common/loading';
import { MODAL_BUTTONS } from 'common/modal';
import { Title } from 'common/title';
import useDocumentTitle from 'common/use-document-title';
import useLastReportDate from 'common/use-last-report-date';
import { OPERATOR_NAMES } from 'constants/operators';
import {
    Day,
    FeatureFlaggedFeature,
    PolicyMetricDataLineFragment,
    PolicyMetricFragment,
    PolicyType
} from 'graphql.g';
import { useUser } from 'common/user';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { useHistory, useLocation, useParams } from 'react-router';
import "/opt/build/repo/src/page-policy/page-details.tsx?resplendence=true";
import { Link } from 'router';
import { PageNotFoundString, PAGE_NOT_FOUND } from 'strings';
import { LocalDate } from 'utils/date-tools';
import {
    convertToSlug,
    getDataColor,
    getOperatorColorStyle
} from 'utils/helpers';
import useDateRangeQuery from 'utils/use-date-range-query';
import Policy from './policy';
import { usePolicyWithMetrics } from './use-policy-with-metrics';
import {
    CartesianGrid,
    XAxis,
    YAxis,
    Tooltip,
    ResponsiveContainer,
    AreaChart,
    Area,
    ReferenceLine
} from 'recharts';
import { AXIS_LABELS, CommonTooltip } from 'common/common-chart';
import ErrorBoundary from 'common/error-boundary';
import { NEW_LINK } from './common-page';
import cx from 'classnames';
import Callout from 'common/callout';

const PERCENT_POLICY_TYPES = [
    PolicyType.minimum_deployment_percentage,
    PolicyType.vehicle_cap_percentage
];

/*
@import 'style.scss';
*/;

const LINK = "rx-page-policy-page-details-1"/*
    @include text-body-link-underlined;
    color: $blue-50;
*/;

const TOP_CONTAINER = "rx-page-policy-page-details-2"/*
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
*/;

const m = defineMessages({
    'policy-tooltip':
        '{count, number} {operator} vehicles on {day, date, dayOfWeekReportDay}',
    'policy-tooltip-percentage':
        '{count, number}% of {operator} vehicles on {day, date, dayOfWeekReportDay}',
    'document-title': 'Policies · Ride Report',
    'policy-detail-document-title': '{policy} · Ride Report'
});

interface MetricChartProps {
    metric: PolicyMetricFragment;
    policyType: PolicyType;
    dateRange: DateRange;
    chartType: 'count' | 'percent';
    /** optional days of week this chart should show.
     * Days not present will be filtered out from the x-axis.
     * An empty array (or not supplying this prop) signifies all days */
    days?: Day[];
}

export function localDateToDay(date: LocalDate) {
    switch (date.weekday) {
        case 0:
            return Day.monday;
        case 1:
            return Day.tuesday;
        case 2:
            return Day.wednesday;
        case 3:
            return Day.thursday;
        case 4:
            return Day.friday;
        case 5:
            return Day.saturday;
        case 6:
            return Day.sunday;
        default:
            throw new Error(
                'Invalid day of week number, must be in the range 0..6'
            );
    }
}

function transformData(
    operators: PolicyMetricDataLineFragment[]
): PolicyMetricDataLineFragment[] {
    const policyDataByOperator = {};

    if (!operators) return [];

    const pointsByOperator = operators.map(op => op.points);
    pointsByOperator.forEach((pointsPerPolicy, index) => {
        pointsPerPolicy.forEach(({ xValue, yValue }) => {
            if (policyDataByOperator[xValue] == null) {
                policyDataByOperator[xValue] = { [index]: yValue };
            } else policyDataByOperator[xValue][index] = yValue;
        });
    });

    return Object.entries(policyDataByOperator).map(
        ([xValue, pointsPerPolicy]) => ({
            ...(pointsPerPolicy as any),
            xValue
        })
    );
}

export function MetricChart({
    metric,
    policyType,
    dateRange,
    days = [],
    chartType
}: MetricChartProps) {
    const [startDate, endDate] = dateRange;
    const intl = useIntl();
    // if a line doesn't have at least 1 data point, we don't want to show it
    const operatorData = metric.data.filter(line => line.points.length > 0);

    const unitForTooltip =
        chartType === 'percent' ? 'vehicles-percent' : 'vehicles';

    const shouldFilterDates =
        days.length > 0 && startDate.plusDays(7).isBefore(endDate);

    //max_idle_time target will differ and the default will always be 0
    const target =
        policyType === PolicyType.max_idle_time ? 0 : metric?.ruleTarget;

    const filteredDataByDate: PolicyMetricDataLineFragment[] = operatorData.map(
        op => {
            const filteredPoints = shouldFilterDates
                ? op.points.filter(point =>
                      days.includes(
                          localDateToDay(LocalDate.fromDateString(point.xValue))
                      )
                  )
                : op.points;
            return { ...op, points: filteredPoints };
        }
    );

    const chartData = transformData(filteredDataByDate);

    return chartData.length === 0 && operatorData.length === 0 ? (
        <div>No data available</div>
    ) : (
        <ResponsiveContainer height={250}>
            <AreaChart
                data={chartData}
                margin={{
                    top: 10,
                    right: -20,
                    left: 20,
                    bottom: 0
                }}
            >
                <CartesianGrid stroke="#ebeef0" vertical={false} />
                <ReferenceLine
                    ifOverflow={'extendDomain'}
                    y={target}
                    stroke="#777777"
                    strokeWidth={2}
                />
                {operatorData.map((op, index) => {
                    const name = operatorData[index]?.operator?.name;

                    const operatorColor =
                        name && OPERATOR_NAMES.includes(name)
                            ? getOperatorColorStyle(name).backgroundColor
                            : getDataColor(index).backgroundColor;

                    const gradient =
                        name && OPERATOR_NAMES.includes(name)
                            ? `url(#gradient-${convertToSlug(name)})`
                            : `url(#gradient-${index})`;

                    return (
                        <Area
                            connectNulls
                            type="monotone"
                            key={index}
                            dataKey={index}
                            name={name}
                            stroke={operatorColor}
                            strokeWidth={2}
                            fillOpacity={1}
                            unit={unitForTooltip}
                            dot={false}
                            activeDot={{
                                stroke: operatorColor,
                                strokeWidth: 4
                            }}
                            fill={gradient}
                        />
                    );
                })}
                <XAxis
                    dataKey="xValue"
                    axisLine={false}
                    tickLine={false}
                    padding={{ right: 10 }}
                    interval={'preserveStartEnd'}
                    tickFormatter={(tick: string) => {
                        const date = LocalDate.fromDateString(tick);

                        return intl.formatDate(date.toDate(), {
                            format: 'chartday'
                        });
                    }}
                    style={AXIS_LABELS}
                    minTickGap={15}
                />
                <YAxis
                    orientation="right"
                    axisLine={false}
                    tickLine={false}
                    padding={{ bottom: 20 }}
                    domain={[0, 'auto']}
                    allowDecimals={false}
                    style={AXIS_LABELS}
                />
                <Tooltip<number, string>
                    isAnimationActive={false}
                    content={({ active, payload }) =>
                        active &&
                        payload && (
                            <CommonTooltip
                                active={active}
                                payload={payload}
                                showLabel
                            />
                        )
                    }
                />
            </AreaChart>
        </ResponsiveContainer>
    );
}

function PageDetails() {
    const { useDemoFeatures, orgAuth } = useUser();
    const history = useHistory();
    const intl = useIntl();
    const { policyId } = useParams<{ policyId: string }>();
    const lastReportDate = useLastReportDate();
    const enabledFeatures = useEnabledFeatures();

    const location = useLocation<
        | undefined
        | {
              action: 'edited';
              policyName: string;
              endDate: string;
          }
    >();

    const endDate = location.state?.endDate;

    const lastAvailableDate =
        endDate != null
            ? LocalDate.fromDateString(endDate.toString())
            : lastReportDate;

    const [dateRange, setDateRange] = useDateRangeQuery([
        lastAvailableDate.minusDays(30),
        lastAvailableDate
    ]);

    const policy = usePolicyWithMetrics(policyId, dateRange[0], dateRange[1]);

    const title =
        policy === 'loading'
            ? intl.formatMessage(m['document-title'])
            : policy === 'not found'
            ? PageNotFoundString()
            : intl.formatMessage(m['policy-detail-document-title'], {
                  policy: policy.name
              });

    useDocumentTitle(title);

    // if we have a start date that is later than the date range, and/or an
    // end date that is before the date range, we need to adjust
    // the date range accordingly
    useEffect(() => {
        if (policy === 'loading' || policy === 'not found') {
            return;
        }

        const policyStartDate = LocalDate.fromDateString(policy.startDate);
        const policyEndDate = policy.endDate
            ? LocalDate.fromDateString(policy.endDate)
            : null;
        // default to the existing range
        const newDateRange = [...dateRange] as [LocalDate, LocalDate];

        if (policyStartDate.isAfter(dateRange[0])) {
            newDateRange[0] = policyStartDate;
        }
        if (policyEndDate?.isBefore(dateRange[1])) {
            newDateRange[1] = policyEndDate;
        }

        if (
            !newDateRange[0].equals(dateRange[0]) ||
            !newDateRange[1].equals(dateRange[1])
        ) {
            setDateRange(newDateRange);
        }
    }, [policy, setDateRange, dateRange]);

    if (policy === 'loading') {
        return <Loading />;
    }
    if (policy === 'not found') {
        return (
            <PageContent>
                <Title title={PAGE_NOT_FOUND}></Title>
            </PageContent>
        );
    }

    const isRateRelated =
        policy.policyType === PolicyType.trip_start_or_stop ||
        policy.policyType === PolicyType.trip_start_xor_stop ||
        policy.policyType === PolicyType.trip_start_and_stop ||
        policy.policyType === PolicyType.trip_start ||
        policy.policyType === PolicyType.trip_end ||
        policy.policyType === PolicyType.right_of_way ||
        policy.policyType === PolicyType.mean_active;

    const canManagePolicies =
        orgAuth.organization.__typename === 'AgencyOrganization' &&
        (enabledFeatures.includes(FeatureFlaggedFeature.self_serve_policies) ||
            useDemoFeatures);

    return (
        <PageContent>
            {location.state?.action === 'edited' ? (
                <Callout
                    color="green"
                    dismiss={() =>
                        history.replace({ ...location, state: undefined })
                    }
                >
                    {
                        <FormattedMessage
                            key="edit-user-success"
                            defaultMessage="{policyName} was successfully updated."
                            values={{ policyName: location.state.policyName }}
                        />
                    }
                </Callout>
            ) : null}
            <Title
                title={policy.name}
                subtitle={
                    <FormattedMessage
                        key="policy-subtitle"
                        defaultMessage="Policy"
                    />
                }
            />
            <div className={TOP_CONTAINER}>
                <Link to="../dashboard" preserveQuery className={LINK}>
                    <FormattedMessage
                        key="back-link"
                        defaultMessage="← Back to Policies"
                    />
                </Link>

                <>
                    <div className={MODAL_BUTTONS}>
                        {canManagePolicies && !isRateRelated ? (
                            <Link
                                state={{
                                    policyToEdit: policy,
                                    policyId: policyId
                                }}
                                to={`../form/edit/${policy.policyType}/${policyId}`}
                                className={cx(NEW_LINK, 'blue')}
                            >
                                <FormattedMessage
                                    key="edit-policy"
                                    defaultMessage="Edit Policy"
                                />
                            </Link>
                        ) : null}
                        {policy.metric != null ? (
                            <DownloadButton
                                icon="Download"
                                path="policy-metrics"
                                params={{ policy_id: policy.id }}
                                filename={`${policy.name.replace(
                                    ' ',
                                    '-'
                                )}-metrics.zip`}
                                failureReason="download-failure-time-range"
                            >
                                <FormattedMessage
                                    key="export-metrics"
                                    defaultMessage="Export Metrics"
                                />
                            </DownloadButton>
                        ) : null}
                    </div>
                </>
            </div>
            {/* some policies don't have metrics available */}
            {policy.metric != null ? (
                <>
                    <Container>
                        <DateRangeSelect
                            selectedDateRange={dateRange}
                            onChange={setDateRange}
                            earliestAllowed={LocalDate.fromDateString(
                                policy.startDate
                            )}
                            latestAllowed={lastAvailableDate}
                        />
                    </Container>

                    <Container>
                        <ContainerHeader>
                            <FormattedMessage
                                key="chart-title"
                                defaultMessage="Compliance for {name}"
                                values={{ name: policy.name }}
                            />
                        </ContainerHeader>
                        <ErrorBoundary>
                            <MetricChart
                                metric={policy.metric}
                                policyType={policy.policyType}
                                dateRange={dateRange}
                                chartType={
                                    PERCENT_POLICY_TYPES.includes(
                                        policy.policyType
                                    )
                                        ? 'percent'
                                        : 'count'
                                }
                                days={policy.rules[0].days}
                            />
                        </ErrorBoundary>
                    </Container>
                </>
            ) : null}
            <Policy policy={policy} />
        </PageContent>
    );
}

export default PageDetails;
