import React from 'react';
import cx from 'classnames';
import "/opt/build/repo/src/common/policy-rule-time.tsx?resplendence=true";
import { getDateTime } from 'page-policy/util';
import Icon from './icon';

import { FormattedTime } from 'react-intl';
import { PolicyRuleDetailsFragment } from 'graphql.g';

/*
@import 'style.scss';
*/;

const TIME_ROW = "rx-common-policy-rule-time-1"/*
    @include text-caption;
    letter-spacing: 0.5rem;
    display: flex;
    align-items: center;
    column-gap: 4rem;

    color: $gray-70;
    &.hovered {
        color: $gray-90;
    }

    svg {
        display: block;
        margin-top: auto;
        margin-bottom: auto;
    }
    &.large {
        @include text-body-tiny;
        color: $gray-90;
        column-gap: 12rem
    }
*/;

const TIME_ICON = "rx-common-policy-rule-time-2"/*
    width: 12rem;
    height: 12rem;
    &.large {
        width: 20rem;
        height: 20rem;
    }
*/;

type Props = {
    rule: PolicyRuleDetailsFragment;
    hover?: boolean;
    large?: boolean;
};
const PolicyRuleTime = ({ rule, hover = false, large = false }: Props) => {
    return (
        <div className={cx(TIME_ROW, hover && 'hovered', large && 'large')}>
            <Icon icon="Time" className={cx(TIME_ICON, large && 'large')} />
            <FormattedTime value={getDateTime(rule.startTime)} />
            {/* only show the range if the times are different */}
            {rule.startTime !== rule.endTime && rule.endTime != null && (
                <>
                    {' - '}
                    <FormattedTime value={getDateTime(rule.endTime)} />
                </>
            )}
        </div>
    );
};

export default PolicyRuleTime;
