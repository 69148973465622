import React from 'react';
import Icon from 'common/icon';
import { AnalyzeDataYUnit } from 'graphql.g';
import { IntlShape, useIntl } from 'react-intl';
import { Payload as ToolTipPayload } from 'recharts/types/component/DefaultTooltipContent';
import "/opt/build/repo/src/page-analyze/chart-util.tsx?resplendence=true";
import { orderBy } from 'lodash';
import cx from 'classnames';
import { ResponsiveContainer } from 'recharts';
import { truncate } from 'lodash';
import { CHART_MESSAGES, DOT, TooltipDateHeader } from 'common/common-chart';

/*
@import '../style.scss';
*/;

export const formatTickOnYAxis = (
    tick: number,
    index: number,
    unitOfMeasurement: AnalyzeDataYUnit,
    intl: IntlShape
): string => {
    return `${intl.formatNumber(tick, {
        notation: 'compact'
    })} ${
        index === 0 ? intl.formatMessage(CHART_MESSAGES[unitOfMeasurement]) : ''
    }`;
};

export const getOpacity = (
    maxValue: number,
    currentValue: number | null
): number => {
    const min = 0.35;
    let opacity: number;
    if (!currentValue) return 1;
    maxValue === 0 ? (opacity = min) : (opacity = currentValue / maxValue);
    if (opacity <= min) {
        opacity = min;
    }
    return opacity;
};

const TOOLTIP_WRAPPER = "rx-page-analyze-chart-util-1"/*
    background-color: rgba(35, 42, 53, 0.9); //grey-90
    color: $gray-30;
    border-radius:4rem;
    z-index: 99;
    font-size: 16rem;
    width: fit-content;
    &.label > div {
        border-top: 1rem solid $gray-70;
    }
*/;

const TOOLTIP_DETAIL = "rx-page-analyze-chart-util-2"/*
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 4rem 0rem;
    white-space: nowrap;
*/;

const TOOLTIP_VALUE = "rx-page-analyze-chart-util-3"/*
    padding: 0rem 8rem 0rem 16rem;
*/;

type TooltipProps = {
    active: boolean;
    payload: ToolTipPayload<number, string>[];
    label?: string; //Hides styles for the tooltip label
    unitsShown: AnalyzeDataYUnit[];
};

export const AnalyzeTooltip = ({
    active,
    payload,
    label,
    unitsShown
}: TooltipProps) => {
    const intl = useIntl();

    const isChartUnitsUniform = unitsShown.every((val, arr) => val === arr[0]);

    if (active && payload && payload.length) {
        return (
            <div className={cx(TOOLTIP_WRAPPER, { label })}>
                {label && <TooltipDateHeader label={label} />}
                <div>
                    {orderBy(payload, pl => pl.value, 'desc').map(
                        (payload, i) => {
                            const unit = payload.unit?.toLocaleString() ?? '';
                            const value =
                                payload.value &&
                                `${intl.formatNumber(payload.value)} ${
                                    !isChartUnitsUniform
                                        ? intl.formatMessage(
                                              CHART_MESSAGES[unit]
                                          )
                                        : ''
                                }`;

                            const percentageOfTotal = payload.payload
                                .percentageOfTotal
                                ? intl.formatMessage(
                                      CHART_MESSAGES[
                                          'tick-label-with-percentage'
                                      ],
                                      {
                                          percentageOfTotal:
                                              payload.payload.percentageOfTotal
                                      }
                                  )
                                : '';

                            const operatorColor = payload.color;

                            return (
                                <div key={i} className={TOOLTIP_DETAIL}>
                                    <div>
                                        <Icon
                                            icon="Circle"
                                            className={DOT}
                                            style={{ color: operatorColor }}
                                        />
                                        {truncate(payload.name, {
                                            length: 14
                                        })}
                                    </div>
                                    <div className={TOOLTIP_VALUE}>
                                        {value} {percentageOfTotal}
                                    </div>
                                </div>
                            );
                        }
                    )}
                </div>
            </div>
        );
    }
    return null;
};

// These styles taken from https://github.com/recharts/recharts/issues/172#issuecomment-992240256
const CHART_WRAPPER_OUTER = "rx-page-analyze-chart-util-4"/*
    width: 100%;
    height: 300px;
    overflow: hidden;
    position: relative;
*/;
const CHART_WRAPPER_INNER = "rx-page-analyze-chart-util-5"/*
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
*/;

export const AnalyzeChartWrapper = ({ children }) => {
    return (
        <div className={CHART_WRAPPER_OUTER}>
            <div className={CHART_WRAPPER_INNER}>
                <ResponsiveContainer width="100%" height={300}>
                    {children}
                </ResponsiveContainer>
            </div>
        </div>
    );
};
