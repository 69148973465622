import React from 'react';
import "/opt/build/repo/src/root/page-privacy.tsx?resplendence=true";

/*
@import 'style';
*/;

const PRIVACY = "rx-root-page-privacy-1"/*
    max-width: 650rem;
    background-color: $white;
    color: $gray-90;
    padding: 24rem 0;
    margin: 24rem 0;
    p, h1, h2, h3, h4, ul {
        padding: 0 24rem;
    }
    a {
        @extend %link;
        color: $blue-50;
    }
    h1 {
        @include headline-4;
    }
    h2{
        @include headline-5;
        margin-top: 24rem;
    }
    h3 {
        @include headline-5;
        font-size: 22rem;
        line-height: 150%;
    }
    h4 {
        @include headline-5;
        font-size: 18rem;
        line-height: 150%;
    }
    h5 {
        font-weight: bold;
    }
    p {
        margin: 16rem 0;
        &:first-child {
            margin-top: 0;
        }
        &:last-child {
            margin-bottom: 0;
        }
        @include text-body;
    }
    ul {
        margin: 16rem 0 16rem 16rem;
        @include text-body;
        &:first-child {
            margin-top: 0;
        }
        &:last-child {
            margin-bottom: 0;
        }
        li {
            margin: 16rem 0;
            &:first-child {
                margin-top: 0;
            }
            &:last-child {
                margin-bottom: 0;
            }
            list-style-type: disc;

            p {
                padding: 0;
            }
        }
    }
    h1, h2, h3, h4 {
        & + p {
            margin-top: 0;
        }
    }
    span.italic {
        font-style: italic;
    }
    .divider {
        margin: 24rem 16rem;
        height: 1rem;
        border: 1rem solid $gray-30;
    }
*/;

/**
 * An end-user license agreement.
 */
function Privacy() {
    return (
        <div className={PRIVACY}>
            <h1>Privacy Policy</h1>

            <p>
                <span className="italic">Effective 9/30/2021</span>
            </p>

            <p>
                This is a legally binding contract between you and Knock
                Software, Inc. By using Ride Report, you confirm that you agree
                to be bound by this contract. We’ve tried to make this document
                as clear as possible, but there's some unavoidable legal mumbo
                jumbo. We welcome your feedback to{' '}
                <a href="mailto:legal@ridereport.com">legal@ridereport.com</a>{' '}
                if you have suggestions or questions about this document or
                anything we do.
            </p>

            <section>
                <h2>Definitions</h2>

                <p>
                    We’re going to use some shorthand to make this easier to
                    read. When we say “Ride Report” or "services," we mean the
                    functionality provided by Ride Report, a web app hosted at
                    app.ridereport.com. When we say “we,” “us,” or “our,” we
                    mean Knock Software Inc., a Delaware corporation. When we
                    say "our site," we mean the APIs, services and data hosted
                    on our site, app.ridereport.com. And when we say “terms,” we
                    mean these terms of service.
                </p>
            </section>

            <section>
                <h2>Accepting these terms</h2>

                <p>
                    Please read these terms before using Ride Report. If you do
                    not agree to these terms, you may not use Ride Report. If we
                    make important changes to these terms, we’ll publish those
                    changes on our website. If you do not agree to those
                    changes, you must stop using Ride Report immediately.
                    Continuing to use Ride Report after this notification
                    constitutes your acceptance of the new terms as modified.
                </p>
            </section>

            <section>
                <h2>Privacy policy</h2>
                <p>
                    This Privacy Policy does not address mobility data (e.g.
                    information about riders or trips that a city or mobility
                    provider, like a shared electric vehicle fleet operator,
                    shares with us). You can learn more about how we use and
                    protect mobility data by contacting us.
                </p>
                <h3>
                    Personal Information that we collect, why we collect it, and
                    what it's used for
                </h3>
                <p>
                    Personal information you provide to us through our services
                    may include:
                </p>
                <ul>
                    <li>
                        <h5>Contact information</h5>
                        <p>
                            <span className="italic">Examples:</span> Your first
                            and last name, email address, phone number,
                            professional title and organization name.
                        </p>
                        <p>
                            <span className="italic">Why:</span> We collect this
                            information to maintain relevant communications with
                            you about the Service and your account.
                        </p>
                        <p>
                            <span className="italic">
                                What do we do with this information:
                            </span>{' '}
                            We use this information to correspond with you.
                        </p>
                    </li>
                    <li>
                        <h5>Account information</h5>
                        <p>
                            <span className="italic">Examples:</span> Your
                            username and password, any account preferences for
                            the Services.
                        </p>
                        <p>
                            <span className="italic">Why:</span> We collect this
                            information to provide you access and
                            configurability to the Services.
                        </p>
                        <p>
                            <span className="italic">
                                What we do with this information:
                            </span>{' '}
                            We use this information to log you into the Services
                            and keep you logged into user sessions that are
                            unique to you, and to provide you with appropriate
                            access to the Services based on your role.
                        </p>
                    </li>
                    <li>
                        <h5>Feedback or correspondence</h5>
                        <p>
                            <span className="italic">Examples:</span>{' '}
                            Information you provide when you contact us with
                            questions, feedback, or otherwise correspond with
                            us.
                        </p>
                        <p>
                            <span className="italic">Why:</span> We collect this
                            information as an avenue to hear your needs and
                            feedback about the Services to make them better and
                            more useful.
                        </p>
                        <p>
                            <span className="italic">
                                What we do with this information:
                            </span>{' '}
                            We use this information to inform how we build and
                            maintain the Services.
                        </p>
                    </li>
                    <li>
                        <h5>Usage information</h5>
                        <p>
                            <span className="italic">Examples:</span> Pages
                            visited, session duration, URL parameters, browser,
                            and clicks.
                        </p>
                        <p>
                            <span className="italic">Why:</span> We collect this
                            information to give us data-driven insights into how
                            the Services are being used.
                        </p>
                        <p>
                            <span className="italic">
                                What we do with this information:
                            </span>{' '}
                            We use this information to run reports and do
                            analyses on the ways users interact with the tool,
                            and share that information internally to make
                            decisions.
                        </p>
                    </li>
                    <li>
                        <h5>Marketing Information</h5>
                        <p>
                            <span className="italic">Examples:</span> Your
                            preferences for receiving communications about our
                            products, activities, publications, and details
                            about how you engage with our communications, as
                            collected by automated marketing tools.
                        </p>
                        <p>
                            <span className="italic">Why:</span> We collect this
                            information so we can send you content that is
                            relevant to and requested by you.
                        </p>
                        <p>
                            <span className="italic">
                                What we do with this information:
                            </span>{' '}
                            We use it to configure what correspondence we send
                            you.
                        </p>
                    </li>
                </ul>
                <h3>How we use cookies</h3>
                <p>
                    Some functionality of the Services rely on cookies. You may
                    decline the use of cookies in your web browser, however you
                    might lose access to some parts of the Services.
                </p>
                <h4>Cookies used by Ride Report</h4>
                <ul>
                    <li>
                        CSRF - securely transitions the user from the
                        authentication service to our web application
                    </li>
                    <li>
                        Session - unique id to keep you logged in to our site
                        between visits
                    </li>
                </ul>
                <h4>Cookies used by third parties</h4>
                <ul>
                    <li>
                        Session - our analytics tools (Mixpanel, Fullstory and
                        Segment) use cookies to track session data
                    </li>
                </ul>
                <h3>Information we share with third parties</h3>
                <p>
                    We rely on some third parties that provide services on our
                    behalf or help us operate the Services. These third parties
                    use your personal information only as authorized by their
                    contracts with us. Collection of this data is governed by
                    this privacy policy, and those defined by the following
                    websites:
                </p>
                <ul>
                    <li>Fullstory — account information, usage information</li>
                    <li>Mixpanel — account information, usage information</li>
                    <li>Segment — account information, usage information</li>
                    <li>
                        Hubspot — feedback or correspondence, contact
                        information, marketing information
                    </li>
                </ul>
                <p>
                    We do not share your personal information with third parties
                    without your consent, except as otherwise described in this
                    Privacy Policy (such as for legal purposes).
                </p>
                <h3>Your rights with regard to your data</h3>
                <p>Every user is entitled to the following:</p>
                <ul>
                    <li>The right to withdraw consent</li>
                    <li>The right of access</li>
                    <li>The right to erasure</li>
                    <li>The right to rectification</li>
                    <li>The right to data portability</li>
                    <li>The right to object</li>
                    <li>Notification of data breaches</li>
                    <li>
                        The right to lodge a complaint with a supervisory
                        authority
                    </li>
                </ul>
                <p>
                    If you would like to exercise any of these rights, please
                    reach out to us at{' '}
                    <a href="mailto:legal@ridereport.com">
                        legal@ridereport.com
                    </a>{' '}
                    and we will respond within one month of your request.
                </p>
                <h4>Do Not Track</h4>
                <p>
                    Some Internet browsers may be configured to send “Do Not
                    Track” signals to the online services that you visit. We
                    currently do not respond to “Do Not Track” or similar
                    signals. To find out more about “Do Not Track,” please visit{' '}
                    <a
                        href="http://www.allaboutdnt.com/"
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        www.allaboutdnt.com
                    </a>
                    .
                </p>
                <h4>Data security, retention, and transfer of data</h4>
                <p>
                    We only retain personal information about you to be able to
                    operate the Services as described above. We do not conduct
                    data transfers of your personal information. If you are no
                    longer using the Services, you can request your account be
                    deleted by emailing{' '}
                    <a href="mailto:legal@ridereport.com">
                        legal@ridereport.com
                    </a>
                    .
                </p>
            </section>

            <section>
                <h2>Security Practices</h2>
                <p>
                    The security of your personal information is important to
                    us. We employ a number of organizational, technical and
                    physical safeguards designed to protect the personal
                    information we collect. However, security risk is inherent
                    in all internet and information technologies and we cannot
                    guarantee the security of your personal information.
                </p>
            </section>

            <section>
                <h2>Updates to this Privacy Policy</h2>
                <p>
                    We may amend this Privacy Policy at any time by posting the
                    amended version on the Services and indicating the effective
                    date of the amended version. We will announce any material
                    changes to this Privacy Policy through Service or via email
                    if we have your email address.
                </p>
                <h4>Disclaimer of warranties</h4>
                <p>
                    We disclaim all warranties of any kind, whether express or
                    implied, including without limitation any warranty of
                    merchantability, fitness for a particular purpose, or
                    non-infringement. You understand that you use Ride Report at
                    your own discretion and risk.
                </p>
                <h4>Indemnification</h4>
                <p>
                    You agree to indemnify, defend, and hold harmless us, our
                    officers, directors, employees, members, shareholders,
                    partners, agents, and suppliers, and their respective
                    affiliates, officers, directors, employees, members,
                    shareholders, partners, and agents, from any and all claims
                    and expenses, including attorneys’ fees, arising out of your
                    use of Ride Report or our site, including but not limited to
                    your violation of this agreement. We may, at our sole
                    discretion, assume the exclusive defense and control of any
                    matter subject to indemnification by you. The assumption of
                    such defense or control by us, however, shall not excuse any
                    of your indemnity obligations.
                </p>
                <h4>Dispute resolution</h4>
                <p>
                    You agree to arbitrate all Disputes you may have with us.
                    Arbitration is more informal than a lawsuit in court and
                    seeks to resolve disputes more quickly. Instead of a judge
                    or a jury, the case will be decided by a neutral arbitrator
                    who has the power to award the same damages and relief that
                    a court can. Any arbitration under this agreement will be
                    only on an individual basis; class arbitrations, class
                    actions, private attorney general actions, and consolidation
                    with other arbitrations are not permitted.
                </p>
                <p>
                    In other words, you waive your right to bring a lawsuit
                    against us in court, and you waive your right to participate
                    in a class action against us.
                </p>
                <p>
                    If any provision of this arbitration agreement is found
                    unenforceable, the unenforceable provision shall be severed,
                    and the remaining arbitration terms shall be enforced (but
                    in no case shall there be a class arbitration).
                </p>
                <p>
                    For any Dispute, we will pay the arbitration fees. We think
                    this is only fair given that we are asking you to agree to
                    arbitration instead of trial in court. Should the arbitrator
                    determine that your claim is frivolous, we may seek to
                    recover from you the arbitration fees and our attorney's
                    fees. Similarly, if you prevail on any claim for which you
                    are legally entitled to attorney’s fees, you may recover
                    those fees from the arbitrator.
                </p>
                <p>
                    All Disputes shall be resolved finally and exclusively by
                    binding individual arbitration with a single arbitrator
                    administered by the American Arbitration Association{' '}
                    <a
                        href="http://www.adr.org/"
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        www.adr.org
                    </a>{' '}
                    or JAMS{' '}
                    <a
                        href="http://www.jamsadr.org/"
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        www.jamsadr.org
                    </a>{' '}
                    according to this provision and the applicable arbitration
                    rules for that forum. The Federal Arbitration Act, 9 U.S.C.
                    §§ 1-16, fully applies. If you are a consumer bringing a
                    claim relating to personal, household, or family use, any
                    arbitration hearing will occur within the county or parish
                    where you reside. Otherwise, any arbitration hearing will
                    occur in San Francisco, California, or another mutually
                    agreeable location. The arbitrator’s award shall be binding
                    on the parties and may be entered as a judgment in any court
                    of competent jurisdiction.
                </p>
                <p>
                    For purposes of this arbitration provision, references to
                    you and us also include respective subsidiaries, affiliates,
                    agents, employees, predecessors, successors and assigns as
                    well as authorized users or beneficiaries of Ride Report
                    services. Subject to and without waiver of the arbitration
                    provisions above, you agree that any judicial proceedings
                    (other than small claims actions in consumer cases) will be
                    brought in and you hereby consent to the exclusive
                    jurisdiction and venue in the state courts in the City and
                    County of San Francisco, California, or federal court for
                    the Northern District of California.
                </p>
            </section>

            <hr className="divider" />
            <p>
                <span className="italic">
                    Thanks to @GetEditorially for their plain language terms of
                    service inspiration. Used under the Creative Commons
                    Attribution-ShareAlike license.
                </span>
            </p>
        </div>
    );
}

export default Privacy;
