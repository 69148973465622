import React, { createContext, useState, useCallback, useContext } from 'react';
import { ContextError } from 'utils/errors';
import LoginPage from 'root/page-login';
import { DateTime } from 'luxon';
import LOCAL_STORAGE_KEYS from 'constants/local-storage';

/** An authentication specific Error class */
class AuthError extends Error {
    constructor(message: string) {
        super(message);
        this.name = 'AuthError';
    }
}

export interface AuthInfo {
    token: string;
    /** UNIX timestamp in milliseconds when this token expires */
    expiresAt: number;
}

export interface AuthContextType extends AuthInfo {
    isAuthenticated: () => boolean;
    login: (authInfo: AuthInfo) => void;
    logout: () => void;
}

const isAuthValid = (auth: AuthInfo) =>
    auth.token.length > 0 && auth.expiresAt > DateTime.utc().toMillis()
        ? true
        : false;

const AuthContext = createContext<AuthContextType | null>(null);

const tokenInLocalStorage: string | null = localStorage.getItem(
    LOCAL_STORAGE_KEYS.authToken
);
// initialize so that we always have a proper data type.
const initialAuthInfo = Object.freeze({ token: '', expiresAt: -1 });
let storedAuthInfo: AuthInfo = initialAuthInfo;
if (tokenInLocalStorage) {
    try {
        const result = JSON.parse(tokenInLocalStorage);
        if (isAuthValid({ token: result.token, expiresAt: result.expiresAt })) {
            storedAuthInfo = {
                token: result.token,
                expiresAt: result.expiresAt
            };
        }
    } catch {
        // continue
    }
}

/** Retrieve's the current auth token and provides functions to alter the
 * AuthContext.
 */
function useAuth(): AuthContextType {
    const [authInfo, setAuthInfo] = useState<AuthInfo>(storedAuthInfo);

    /** Helper to check if the user has credentials that appear valid.
     * The backend may still reject the credentials on requests. */
    const isAuthenticated = useCallback(() => isAuthValid(authInfo), [
        authInfo
    ]);

    /** Sets the user's AuthInfo, logging them in */
    const login = useCallback((newAuthInfo: AuthInfo) => {
        if (isAuthValid(newAuthInfo) === false) {
            throw new AuthError('Tried to login with invalid AuthInfo');
        }

        setAuthInfo(newAuthInfo);
        localStorage.setItem(
            LOCAL_STORAGE_KEYS.authToken,
            JSON.stringify(newAuthInfo)
        );
    }, []);

    /** Removes the user's AuthInfo, logging them out */
    const logout = useCallback(() => {
        setAuthInfo(initialAuthInfo);
        localStorage.removeItem(LOCAL_STORAGE_KEYS.authToken);
    }, []);

    return {
        token: authInfo.token,
        expiresAt: authInfo.expiresAt,
        isAuthenticated,
        login,
        logout
    };
}

/** Provides auth info to a component from the context.
 * To make use of this hook, you need to have an AuthInfoProvider above your
 * component in the tree.
 */
export function useAuthInfo() {
    const auth = useContext(AuthContext);
    if (!auth) throw new ContextError('AuthInfo');
    return auth;
}

interface AuthInfoProviderProps {
    children: React.ReactNode;
}

/** Ensures that the user is authenticated, and if they're not makes them
 * login before continuing.
 */
function AuthInfoProvider({ children }: AuthInfoProviderProps) {
    const auth = useAuth();

    return (
        <AuthContext.Provider value={auth}>
            {auth.isAuthenticated() ? (
                children
            ) : (
                <LoginPage login={auth.login} />
            )}
        </AuthContext.Provider>
    );
}

export default AuthInfoProvider;
