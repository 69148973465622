import React from 'react';
import { OperatorReport } from './report';
import { CalloutSection, messages, TableSection, CALLOUT_GRID } from './tables';
import { defineMessages, FormattedMessage } from 'react-intl';
import { useIntl } from 'utils/use-intl';
import gql from 'graphql-tag';
import {
    ReportMaxVehiclesFragment,
    ReportTripCountsFragment,
    ReportTripsPerVehicleFragment
} from './fragments';
import { useReloadingData } from 'utils/use-data';
import statDescriptions from '../constants/stat-descriptions';
import { ReportOperator } from './page-daily-summary';
import { LocalDate } from 'utils/date-tools';
import PageDailyOperatorsArea from './page-daily-operator-areas';
import ReportInfoBanner from './report-info-banner';
import { useMapView } from 'common/use-map-view';
import {
    SingleOperatorMdsDataData,
    SingleOperatorMdsDataArgs
} from 'graphql.g';
import { useVehicleClass } from 'common/vehicle-classes';
import useDocumentTitle from 'common/use-document-title';

interface Props {
    reportDate: LocalDate;
    operator: ReportOperator;
}

const QUERY = gql`
    query SingleOperatorMdsData(
        $slug: String
        $operatorId: ID
        $date: Date!
        $vehicleClass: VehicleClass
    ) {
        mapView(slug: $slug) {
            id
            operator(id: $operatorId) {
                id
                hasMdsData
                report(date: $date, vehicleClass: $vehicleClass) {
                    id
                    finalized
                    ...ReportMaxVehicles
                    ...ReportTripCounts
                    ...ReportTripsPerVehicle
                }
            }
        }
    }
    ${ReportMaxVehiclesFragment}
    ${ReportTripCountsFragment}
    ${ReportTripsPerVehicleFragment}
`;

const m = defineMessages({
    'metrics-by-area': 'Metrics By Area of Interest',
    title: "Here's a look at {operator}",
    'root-title': "Here's your confidential Ride Report",
    'delayed-report':
        'Your report is being generated. Reports may be delayed if data in the feed is delayed.',
    'document-title': '{operator} · {day, date, reportday} Report · Ride Report'
});

function PageDailyOperator({ operator, reportDate }: Props) {
    const mapView = useMapView();
    const intl = useIntl();

    const title = intl.formatMessage(m['document-title'], {
        operator: operator.name,
        day: reportDate?.toDate()
    });

    useDocumentTitle(title);

    const vehicleClass = useVehicleClass();
    const [data, loading] = useReloadingData<
        SingleOperatorMdsDataData,
        SingleOperatorMdsDataArgs
    >(QUERY, {
        slug: mapView.slug,
        operatorId: operator.operatorId,
        date: reportDate.toString(),
        vehicleClass
    });
    const operatorData = data && data.mapView && data.mapView.operator;
    const report = operatorData && operatorData.report;
    const hasMdsData = operatorData && operatorData.hasMdsData;
    const maxVehicleMetric = report && report.maxVehicleMetric;
    const tripCounts = report && report.tripCounts;
    const tripsPerVehicle =
        report &&
        report.tripsPerVehicleMetric &&
        report.tripsPerVehicleMetric.tripsPerVehicle;
    const errorMessage =
        data && !hasMdsData ? (
            <>
                <FormattedMessage
                    key="error"
                    defaultMessage="<0>No MDS data.</0> Contact {operator} for access."
                    values={{
                        operator: operator.name,
                        0: msg => <strong>{msg}</strong>
                    }}
                />
            </>
        ) : null;

    const delayedReportBanner =
        data && hasMdsData && !report ? (
            <ReportInfoBanner
                message={intl.formatMessage(m['delayed-report'])}
            />
        ) : null;
    return (
        <OperatorReport
            reportDate={reportDate}
            operator={operator}
            finalized={report?.finalized ?? true}
        >
            {delayedReportBanner}
            <div className={CALLOUT_GRID}>
                <CalloutSection
                    loading={loading}
                    title={statDescriptions.maxParked}
                    value={maxVehicleMetric ? maxVehicleMetric.maxParked : null}
                    label={
                        maxVehicleMetric
                            ? intl.formatMessage(messages.timestamp, {
                                  time: new Date(maxVehicleMetric.maxParkedTime)
                              })
                            : ''
                    }
                    error={errorMessage}
                />
                <CalloutSection
                    loading={loading}
                    title={statDescriptions.maxAvailable}
                    value={
                        maxVehicleMetric ? maxVehicleMetric.maxAvailable : null
                    }
                    label={
                        maxVehicleMetric
                            ? intl.formatMessage(messages.timestamp, {
                                  time: new Date(
                                      maxVehicleMetric.maxAvailableTime
                                  )
                              })
                            : ''
                    }
                    error={errorMessage}
                />
            </div>
            <div className={`${CALLOUT_GRID} small`}>
                <CalloutSection
                    small
                    loading={loading}
                    title={statDescriptions.maxUnavailable}
                    value={
                        maxVehicleMetric
                            ? maxVehicleMetric.maxUnavailable
                            : null
                    }
                    label={
                        maxVehicleMetric
                            ? intl.formatMessage(messages.timestamp, {
                                  time: new Date(
                                      maxVehicleMetric.maxUnavailableTime
                                  )
                              })
                            : ''
                    }
                    error={errorMessage}
                />
                <CalloutSection
                    small
                    loading={loading}
                    title={statDescriptions.startTrips}
                    value={tripCounts ? tripCounts.startCount : null}
                    error={errorMessage}
                />
                <CalloutSection
                    small
                    loading={loading}
                    title={statDescriptions.tripsPerVehicle}
                    value={
                        tripsPerVehicle
                            ? intl.formatNumber(tripsPerVehicle, {
                                  maximumFractionDigits: 1
                              })
                            : null
                    }
                    error={errorMessage}
                />
            </div>
            <TableSection title={intl.formatMessage(m['metrics-by-area'])}>
                <PageDailyOperatorsArea
                    mapView={mapView}
                    operatorId={operator.operatorId}
                    reportDate={reportDate}
                />
            </TableSection>
        </OperatorReport>
    );
}

export default PageDailyOperator;
