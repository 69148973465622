import React, { useState } from 'react';
import {
    Container,
    ContainerHeader,
    DetailsContainer,
    TabToggle
} from 'common/layout';
import {
    FormattedMessage,
    FormattedDate,
    useIntl,
    defineMessages,
    FormattedTime
} from 'react-intl';
import "/opt/build/repo/src/page-policy/policy.tsx?resplendence=true";
import { RuleTypeString } from 'strings';
import { separatedBy } from 'utils/helpers';
import CodeBlock from 'common/code-block';
import Currency from 'common/currency';
import {
    PolicyDetailsFragment,
    PolicyRuleDetailsFragment,
    Day,
    RuleType,
    MeasurementUnitSystemType,
    PolicyType
} from 'graphql.g';
import { OperatorTag, AreaTag, GenericTag } from 'common/tag';
import KeyValue, { KEY_VALUES } from 'common/key-value';
import { LocalDate } from 'utils/date-tools';
import { useMapView } from 'common/use-map-view';
import { getDateTime, getPolicyTags } from './util';
import { POLICY_TYPE_STRINGS } from './strings';

/*
@import 'style.scss';
*/;

interface PolicyRuleHighlightProps {
    rule: PolicyRuleDetailsFragment;
    policyType: PolicyType;
}

function PolicyRuleHighlight({ rule, policyType }: PolicyRuleHighlightProps) {
    const { measurementSystem } = useMapView();

    let amount: React.ReactNode = null;
    let message: React.ReactNode = null;

    // all rate rules will have a currency value
    if (rule.__typename === 'PolicyRateRule') {
        amount = <Currency value={rule.rateAmount} />;
        message = (
            <FormattedMessage
                key="policy-rule-amount-label"
                defaultMessage="Amount"
            />
        );
    }
    // maximums with a speed rule (speed limit)
    if (
        rule.__typename === 'PolicyMaximumRule' &&
        rule.ruleType === RuleType.speed
    ) {
        amount = rule.maximum;
        message = (
            <FormattedMessage
                key="policy-rule-max-speed-label"
                defaultMessage="Max Speed ({units})"
                values={{
                    units:
                        measurementSystem === MeasurementUnitSystemType.IMPERIAL
                            ? 'MPH'
                            : 'KPH'
                }}
            />
        );
    }
    // maximums with a count rule (vehicle cap, no parking, no ride zone)
    if ('maximum' in rule && rule.ruleType === RuleType.count) {
        amount =
            policyType === PolicyType.vehicle_cap_percentage
                ? `${rule.maximum}%`
                : rule.maximum;
        message =
            policyType === PolicyType.max_idle_time ? (
                <FormattedMessage
                    key="policy-rule-max-count-hours-label"
                    defaultMessage="Maximum Hours"
                />
            ) : (
                <FormattedMessage
                    key="policy-rule-max-count-label"
                    defaultMessage="Maximum Count"
                />
            );
    }

    // minimums with a count rule (minimum deployment)
    if (rule.__typename === 'PolicyMinDeploymentRule') {
        amount =
            policyType === PolicyType.minimum_deployment_percentage
                ? `${rule.minimum}%`
                : rule.minimum;
        message = (
            <FormattedMessage
                key="policy-rule-min-count-label"
                defaultMessage="Minimum Count"
            />
        );
    }

    return (
        <div className={AMOUNT}>
            <div className={AMOUNT_VALUE}>{amount}</div>
            <div className={AMOUNT_LABEL}>{message}</div>
        </div>
    );
}

const RULE_LABEL = "rx-page-policy-policy-1"/*
    color: $gray-70;
    &:after {
        content: ": ";
    }
*/;
const RULE_NAME = "rx-page-policy-policy-2"/*
    color: $gray-90;
*/;

const AMOUNT = "rx-page-policy-policy-3"/*
    float: right;
    text-align: right;
*/;

const AMOUNT_VALUE = "rx-page-policy-policy-4"/*
    @include headline-3;
*/;

const AMOUNT_LABEL = "rx-page-policy-policy-5"/*
    @include text-body-link;
    color: $gray-60;
    margin-top: -4rem;
*/;

const SUMMARY_HEADER = "rx-page-policy-policy-6"/*
    display: inline;
*/;

export const dayStrings = defineMessages({
    sunday: 'Sunday',
    saturday: 'Saturday',
    monday: 'Monday',
    tuesday: 'Tuesday',
    wednesday: 'Wednesday',
    thursday: 'Thursday',
    friday: 'Friday'
});

function getDayOfWeekType(days: Day[]) {
    if (days.length === 0) return 'all';

    if (
        days.length === 2 &&
        days.includes(Day.saturday) &&
        days.includes(Day.sunday)
    ) {
        return 'weekend';
    }

    if (
        days.length === 5 &&
        days.includes(Day.monday) &&
        days.includes(Day.tuesday) &&
        days.includes(Day.wednesday) &&
        days.includes(Day.thursday) &&
        days.includes(Day.friday)
    ) {
        return 'weekdays';
    }

    return 'mix';
}

type RuleProps = {
    rule: PolicyRuleDetailsFragment;
    policy: PolicyDetailsFragment;
};

function PolicyRule({ rule, policy }: RuleProps) {
    const intl = useIntl();
    const dayOfWeekType = getDayOfWeekType(rule.days);
    const { policyType } = policy;
    const tags = getPolicyTags({ policy });

    return (
        <DetailsContainer
            id={rule.id}
            summary={
                <h3 className={SUMMARY_HEADER}>
                    <span className={RULE_LABEL}>
                        <FormattedMessage
                            key="policy-rule-label"
                            defaultMessage="Rule"
                        />
                    </span>
                    <span className={RULE_NAME}>{rule.name}</span>
                </h3>
            }
        >
            <div className={KEY_VALUES}>
                <PolicyRuleHighlight rule={rule} policyType={policyType} />

                <KeyValue
                    keyName={
                        <FormattedMessage
                            key="policy-rule-type-label"
                            defaultMessage="Type"
                        />
                    }
                >
                    <RuleTypeString type={rule.ruleType} />
                    {tags.map((text, index) => (
                        <React.Fragment key={index + text}>
                            {' '}
                            <GenericTag text={text} size="smaller" />
                        </React.Fragment>
                    ))}
                </KeyValue>
                {rule.__typename !== 'PolicyRateRule' && (
                    <KeyValue
                        keyName={
                            <FormattedMessage
                                key="policy-rule-vehicle-classes-label"
                                defaultMessage="Vehicle Class"
                            />
                        }
                    >
                        {rule.vehicleClasses.length === 0 ? (
                            <FormattedMessage
                                key="vehicle-classes-all"
                                defaultMessage="All"
                            />
                        ) : (
                            separatedBy(
                                rule.vehicleClasses.map(
                                    vc =>
                                        vc.charAt(0).toUpperCase() + vc.slice(1)
                                ),
                                ', '
                            )
                        )}
                    </KeyValue>
                )}
                {rule.startTime && (
                    <KeyValue
                        keyName={
                            <FormattedMessage
                                key="policy-rule-time-label"
                                defaultMessage="Time"
                            />
                        }
                    >
                        <FormattedTime value={getDateTime(rule.startTime)} />
                        {/* if we have an end time, and it differs from our start time, show the range */}
                        {rule.endTime && rule.endTime !== rule.startTime && (
                            <>
                                {' '}
                                -{' '}
                                <FormattedTime
                                    value={getDateTime(rule.endTime)}
                                />
                            </>
                        )}
                    </KeyValue>
                )}
                {rule.__typename !== 'PolicyRateRule' && (
                    <KeyValue
                        keyName={
                            <FormattedMessage
                                key="policy-rule-day-of-week-label"
                                defaultMessage="Day of Week"
                            />
                        }
                    >
                        {dayOfWeekType === 'all' ? (
                            <FormattedMessage
                                key="day-of-week-all"
                                defaultMessage="All"
                            />
                        ) : dayOfWeekType === 'weekend' ? (
                            <FormattedMessage
                                key="day-of-week-weekend"
                                defaultMessage="Weekends"
                            />
                        ) : dayOfWeekType === 'weekdays' ? (
                            <FormattedMessage
                                key="day-of-week-weekdays"
                                defaultMessage="Weekdays"
                            />
                        ) : (
                            rule.days
                                .map(day => intl.formatMessage(dayStrings[day]))
                                .join(', ')
                        )}
                    </KeyValue>
                )}
                {rule.areas.length > 0 && (
                    <KeyValue
                        keyName={
                            <FormattedMessage
                                key="policy-rule-area-label"
                                defaultMessage="Area"
                            />
                        }
                    >
                        {separatedBy(
                            rule.areas.map(geo => (
                                <AreaTag key={geo.id} {...geo} />
                            )),
                            ' '
                        )}
                    </KeyValue>
                )}
            </div>
        </DetailsContainer>
    );
}

const POLICY = "rx-page-policy-policy-7"/*
    & > *:not(:last-child) {
        margin-bottom: 24rem;
    }
*/;

const POLICY_DESCRIPTION_CONTAINER = "rx-page-policy-policy-8"/*
    display: grid;
    grid-template-columns: 5fr 1fr;
    grid-gap: 8rem;
*/;

const HEADER_CONTAINER = "rx-page-policy-policy-9"/*
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;
    padding: 12rem 0;

    ~* create a gap between flex rows when the content gets wrapped *~
    margin: -4rem;
    > * {
        margin: 4rem;
    }
*/;

const TOGGLE = "rx-page-policy-policy-10"/*
    width: 200rem;
    // account for the margin being set above.
    margin-right: -4rem;
*/;

const m = defineMessages({
    mds: 'MDS',
    summary: 'Summary'
});

type PolicyProps = { policy: PolicyDetailsFragment };

function Policy({ policy }: PolicyProps) {
    const intl = useIntl();
    const [expandPolicy, setExpandPolicy] = useState(false);

    return (
        <Container key={policy.id}>
            <ContainerHeader el="div">
                <div className={HEADER_CONTAINER}>
                    <h2>{policy.name}</h2>
                    <div className={TOGGLE}>
                        <TabToggle
                            checked={!expandPolicy}
                            tabLabels={[
                                intl.formatMessage(m.mds),
                                intl.formatMessage(m.summary)
                            ]}
                            onChange={() => {
                                setExpandPolicy(!expandPolicy);
                            }}
                        />
                    </div>
                </div>
            </ContainerHeader>
            <div className={POLICY}>
                <div className={POLICY_DESCRIPTION_CONTAINER}>
                    <div className={KEY_VALUES}>
                        {policy.description !== '' && (
                            <KeyValue
                                keyName={
                                    <FormattedMessage
                                        key="policy-description-label"
                                        defaultMessage="Description"
                                    />
                                }
                            >
                                {policy.description}
                            </KeyValue>
                        )}
                        {policy.operators.length > 0 && (
                            <KeyValue
                                keyName={
                                    <FormattedMessage
                                        key="policy-providers-label"
                                        defaultMessage="Providers"
                                    />
                                }
                            >
                                {separatedBy(
                                    policy.operators.map(operator => (
                                        <OperatorTag
                                            key={operator.id}
                                            name={operator.name}
                                        />
                                    )),
                                    ' '
                                )}
                            </KeyValue>
                        )}
                        <KeyValue
                            keyName={
                                <FormattedMessage
                                    key="policy-date-label"
                                    defaultMessage="Policy date"
                                />
                            }
                        >
                            {policy.endDate === null ? (
                                <FormattedDate
                                    value={LocalDate.fromDateString(
                                        policy.startDate
                                    ).toDate()}
                                    format="reportday"
                                />
                            ) : (
                                <FormattedMessage
                                    key="policy-date"
                                    defaultMessage="{start, date, reportweek}—{end, date, reportweek}"
                                    values={{
                                        start: new Date(policy.startDate),
                                        end: new Date(policy.endDate)
                                    }}
                                />
                            )}
                        </KeyValue>
                        <KeyValue
                            keyName={
                                <FormattedMessage
                                    key="policy-type-label"
                                    defaultMessage="Policy type"
                                />
                            }
                        >
                            {intl.formatMessage(
                                POLICY_TYPE_STRINGS[policy.policyType] ??
                                    policy.policyType
                            )}
                        </KeyValue>
                    </div>
                </div>
                {expandPolicy ? (
                    <CodeBlock
                        initiallyExpanded
                        text={JSON.stringify(policy.policyApiData, null, 2)}
                    />
                ) : (
                    policy.rules.map(rule => (
                        <PolicyRule rule={rule} key={rule.id} policy={policy} />
                    ))
                )}
            </div>
        </Container>
    );
}

export default Policy;
