import React, { useState, useEffect, useRef } from 'react';
import useData from 'utils/use-data';
import gql from 'graphql-tag';
import { Organization } from 'types';
import Loading from './loading';
import { PageContent, Container, PageBody, TextField, Button } from './layout';
import "/opt/build/repo/src/common/user-masquerade-selector.tsx?resplendence=true";
import { ORGANIZATION_INFO_FRAGMENT } from './user-context';
import { CurrentUserData, UserRoleType } from 'graphql.g';
import { getOperatorColorStyle } from 'utils/helpers';
import { ErrorCallout } from './error-message';
import { useJoinOrgMutation } from './user';
import { useHistory } from 'react-router';
import { Title } from './title';

/*
@import 'style';
*/;

type OrganizationsData = {
    organizations: Organization[];
};

const ORGS_QUERY = gql`
    query Organizations {
        organizations {
            ...OrganizationInfo
        }
    }
    ${ORGANIZATION_INFO_FRAGMENT}
`;

const BUTTON_COLUMNS = "rx-common-user-masquerade-selector-1"/*
    display: flex;
    flex-flow: row wrap;
*/;

const BUTTON_ITEM = "rx-common-user-masquerade-selector-2"/*
    position: relative;
    break-inside: avoid;
    padding: 4rem 8rem;
*/;

const HEADER = "rx-common-user-masquerade-selector-3"/*
    @include text-label-bold;
    padding: 16rem 0 8rem 0;
*/;

type OrgColumnProps = {
    selectedOrgId: string | null;
    organizations: Organization[];
    orgType: 'Agency' | 'Operator';
    selectOrg: (id: string) => Promise<any>;
};

function OrgColumn({
    selectedOrgId,
    organizations,
    selectOrg,
    orgType
}: OrgColumnProps) {
    const [loadingId, setLoadingId] = useState<string | null>(null);
    return (
        <>
            <h3 className={HEADER}>{orgType} Organizations</h3>
            <div className={BUTTON_COLUMNS}>
                {organizations.map(org => {
                    const style =
                        org.__typename === 'OperatorOrganization' &&
                        org.id !== selectedOrgId
                            ? getOperatorColorStyle(org.operatorInfo.name)
                            : undefined;
                    return (
                        <div className={BUTTON_ITEM} key={org.id}>
                            <Button
                                color="blue"
                                loading={loadingId === org.id}
                                disabled={org.id === selectedOrgId}
                                onClick={async () => {
                                    setLoadingId(org.id);
                                    try {
                                        await selectOrg(org.id);
                                    } catch (err) {
                                        setLoadingId(null);
                                    }
                                }}
                                style={style}
                            >
                                {org.name}
                            </Button>
                        </div>
                    );
                })}
            </div>
        </>
    );
}

const LABEL = "rx-common-user-masquerade-selector-4"/*
    @include text-label;
    color: $gray-70;
*/;

const CURRENT_VERSION_CONTAINER = "rx-common-user-masquerade-selector-5"/*
    text-align: center;
*/;

const CURRENT_VERSION = "rx-common-user-masquerade-selector-6"/*
    @include text-body;
    color: $gray-70;
*/;

type UserMasqueradeSelectorProps = {
    currentUser: CurrentUserData['user'];
};

/**
 * A staff-only page for picking which organization you want to masquerade as.
 */
function UserMasqueradeSelector({ currentUser }: UserMasqueradeSelectorProps) {
    const history = useHistory();
    const data = useData<OrganizationsData>(ORGS_QUERY);

    const [filter, setFilter] = useState('');
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.select();
        }
    }, []);

    const orgs = data?.organizations.filter(
        org =>
            org.mapViews.length > 0 &&
            (!filter ||
                org.name.toLowerCase().includes(filter.toLowerCase().trim()))
    );

    const agencyOrgs = orgs?.filter(
        org => org.__typename === 'AgencyOrganization'
    );
    const operatorOrgs = orgs?.filter(
        org => org.__typename === 'OperatorOrganization'
    );

    const [setOrg, { error }] = useJoinOrgMutation();

    const onChangeOrg = (orgId: string) => {
        return setOrg({
            variables: {
                org: orgId,
                role: currentUser.orgAuth?.role ?? UserRoleType.Administrator
            }
        })
            .then(({ data }) => {
                const slug =
                    data?.joinOrganization?.user.orgAuth?.organization
                        .mapViews[0].slug ?? '';
                history.push('/' + slug);
            })
            .catch(err => {
                console.error(err);
                window.rollbar.error(err);
                throw err;
            });
    };

    const currentOrgId = currentUser.orgAuth?.organization.id ?? null;

    return (
        <>
            <PageContent>
                <Title title="Choose an Organization" />
                <PageBody>
                    {error && <ErrorCallout error={error} />}

                    <Container>
                        <Loading
                            kind="over-table"
                            loading={!agencyOrgs || !operatorOrgs}
                        />
                        <div>
                            <label htmlFor="filter" className={LABEL}>
                                Filter organizations by name
                            </label>
                            <TextField
                                ref={inputRef}
                                placeholder="Filter by name"
                                value={filter}
                                onChange={value => setFilter(value)}
                            />
                        </div>
                        <OrgColumn
                            orgType="Agency"
                            selectedOrgId={currentOrgId}
                            organizations={agencyOrgs ?? []}
                            selectOrg={onChangeOrg}
                        />
                        <OrgColumn
                            orgType="Operator"
                            selectedOrgId={currentOrgId}
                            organizations={operatorOrgs ?? []}
                            selectOrg={onChangeOrg}
                        />
                    </Container>
                    <div className={CURRENT_VERSION_CONTAINER}>
                        <p className={CURRENT_VERSION}>
                            Current Version: {process.env.COMMIT_HASH}
                        </p>
                    </div>
                </PageBody>
            </PageContent>
        </>
    );
}

export default UserMasqueradeSelector;
