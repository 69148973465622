import gql from 'graphql-tag';
import { AoiInfoFragment as AoiInfo } from 'graphql.g';

export const ReportMaxVehiclesFragment = gql`
    fragment ReportMaxVehicles on Report {
        maxVehicleMetric {
            maxAvailable
            maxAvailableTime
            maxUnavailable
            maxUnavailableTime
            maxParked
            maxParkedTime
        }
    }
`;

export const ReportTripCountsFragment = gql`
    fragment ReportTripCounts on Report {
        tripCounts {
            startCount
        }
    }
`;

export const ReportTripsPerVehicleFragment = gql`
    fragment ReportTripsPerVehicle on Report {
        tripsPerVehicleMetric {
            tripsPerVehicle
        }
    }
`;
export const AoiInfoFragment = gql`
    fragment AoiInfo on AreaOfInterest {
        id
        name
        slug
        priority
        tableViewOnly
        isMunicipalityBoundary
        operatorIds
        operators {
            id
            name
        }
        archivedAt
        slug
        sharedWithOperators
        localCreatedDate
        modified
    }
`;

export type MdsAoiInfo = {
    area: AoiInfo;
};

export const MdsAoiInfoFragment = gql`
    fragment MdsAoiInfo on AreaOfInterestMDSMetric {
        area {
            ...AoiInfo
        }
    }
    ${AoiInfoFragment}
`;

export type MdsAoiTripCounts = {
    tripStartCount: number | null;
    tripEndCount: number | null;
};

export const MdsAoiTripCountsFragment = gql`
    fragment MdsAoiTripCounts on AreaOfInterestMDSMetric {
        tripEndCount
        tripStartCount
    }
`;

export type MdsAoiMorningDeployments = {
    morningDeploymentCounts: {
        availableCount: number;
        unavailableCount: number;
        parkedCount: number;
    } | null;
};

export const MdsAoiMorningDeploymentsFragment = gql`
    fragment MdsAoiMorningDeployments on AreaOfInterestMDSMetric {
        morningDeploymentCounts {
            unavailableCount
            availableCount
            parkedCount
        }
    }
`;

export const AggregatedReportMaxVehiclesFragment = gql`
    fragment AggregatedReportMaxVehicles on AggregatedReport {
        maxVehicles {
            maxAvailable
            maxUnavailable
            maxParked
            averageMaxAvailable
            averageMaxUnavailable
            averageMaxParked
        }
    }
`;

export const AggregatedReportTripCountsFragment = gql`
    fragment AggregatedReportTripCounts on AggregatedReport {
        tripCounts {
            startCount
            averageStartCount
        }
    }
`;
export const AggregatedReportTripsPerVehicleFragment = gql`
    fragment AggregatedReportTripsPerVehicle on AggregatedReport {
        tripsPerVehicle {
            tripsPerVehicle
        }
    }
`;

export const AggregatedReportAoiMetricsFragment = gql`
    fragment AggregatedReportAoiMetrics on AggregatedReport {
        aoiMetrics {
            area {
                ...AoiInfo
            }
            tripCounts {
                startCount
                averageStartCount
                endCount
                averageEndCount
            }
            morningDeploymentCounts {
                averageAvailableCount
                averageUnavailableCount
                averageParkedCount
            }
            tripsPerVehicle
            totalMaxAvailable
        }
    }
    ${AoiInfoFragment}
`;

export const UNFINALIZED_OPERATOR_FRAGMENT = gql`
    fragment UnfinalizedOperator on OperatorInfo {
        id
        name
    }
`;
