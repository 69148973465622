import React from 'react';
import { FormattedMessage } from 'react-intl';
import { VehicleClass } from 'graphql.g';
import { FormInput } from './common';
import { PolicyFormProps } from './types';
import { VehicleClassPicker } from 'common/vehicle-classes';

const VEHICLE_CLASS_OPTIONS = [
    VehicleClass.bike,
    VehicleClass.car,
    VehicleClass.ebike,
    VehicleClass.moped,
    VehicleClass.scooter
];

type VehicleClassInputProps = Omit<
    PolicyFormProps,
    'formErrors' | 'setFormErrors'
>;

function VehicleClassInput({ policy, setPolicy }: VehicleClassInputProps) {
    return (
        <FormInput
            labelId="vehicle-class-select-input"
            label={
                <FormattedMessage
                    key="vehicle-class-label"
                    defaultMessage="Vehicle Class:"
                />
            }
        >
            <VehicleClassPicker
                vehicleClasses={VEHICLE_CLASS_OPTIONS}
                onChange={vc =>
                    setPolicy({
                        ...policy,
                        vehicleClass: vc == null ? [] : [vc]
                    })
                }
                value={
                    policy.vehicleClass.length === 0
                        ? null
                        : policy.vehicleClass[0]
                }
            />
        </FormInput>
    );
}

export default VehicleClassInput;
