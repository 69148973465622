import "/opt/build/repo/src/page-report/report-info-banner.tsx?resplendence=true";
import React from 'react';

/*
@import "style";
*/;

const REPORT_BANNER = "rx-page-report-report-info-banner-1"/*
    display: flex;
    flex: 0 0 auto;
    flex-flow: row nowrap;
    padding: 16rem 24rem;
    max-height: 64rem;
    margin-bottom: 24rem;
    background: rgba(59, 85, 230, 0.05);
    border: $blue-30 1rem solid;
    border-radius: 6rem;
    @include text-body-link;
    color: $blue-50;
*/;

type Props = {
    message: string;
};

function ReportInfoBanner({ message }: Props) {
    return <div className={REPORT_BANNER}>{message}</div>;
}

export default ReportInfoBanner;
