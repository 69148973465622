import React, { useReducer } from 'react';
import { DateRangeSelect } from 'common/date-picker';
import { useMapView } from 'common/use-map-view';
import { MapOptionsAction, MapOptions } from 'page-explore';
import { useEffect } from 'react';
import "/opt/build/repo/src/page-explore/page-heatmaps/hex-bins/index.tsx?resplendence=true";

import { Map } from 'mapbox-gl';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import gql from 'graphql-tag';
import { useGraphql } from 'utils/use-data';
import {
    EarliestTripsMetricsDateData,
    EarliestTripsMetricsDateArgs
} from 'graphql.g';
import { OVERLAY } from '../../common/overlay';
import ModePicker from '../../common/mode-picker';
import { LocalDate } from 'utils/date-tools';
import HourRangeSlider from 'common/hour-range-slider';
import HeatmapKey from '../../common/heatmap-key';
import TripsPrivacyCallout from './privacy-callout';
import { steppedArray } from 'utils/stepped-array';
import TripsPopup from './popup';
import useHexLayers from './layers';
import {
    usePrefetchedHexData,
    usePrivacyThreshold,
    useTripCountsByHex
} from './data';
import TripsSidebar from './sidebar';
import { getLastReportDate } from 'common/use-last-report-date';
import useDateRangeQuery from 'utils/use-date-range-query';

/*
@import 'style.scss';
*/;

const SELECT_WRAPPER = "rx-page-explore-page-heatmaps-hex-bins-index-1"/*
    display: grid;
*/;

interface MapHexBinsProps {
    map: Map | null;
    mapOptions: MapOptions;
    dispatchMapOptions: React.Dispatch<MapOptionsAction>;
    metric: 'starts' | 'ends';
}

export type Action =
    | { type: 'hourRange'; value: [number, number] }
    | { type: 'selectedHexId' | 'hoveredHexId'; value: string | null };

const getInitialState = () => {
    return {
        hourRange: [0, 24] as [number, number],
        selectedHexId: null as string | null,
        hoveredHexId: null as string | null
    };
};

export type State = ReturnType<typeof getInitialState>;

const reducer = (state: State, action: Action) => {
    return {
        ...state,
        [action.type]: action.value
    };
};

const MapHexBins = ({
    map,
    mapOptions,
    dispatchMapOptions,
    metric
}: MapHexBinsProps) => {
    const mapView = useMapView();

    const [state, dispatch] = useReducer(reducer, getInitialState());

    const lastReportDate = getLastReportDate(mapView.ianaTimezone);
    const [dataPeriod, setDataPeriod] = useDateRangeQuery([
        lastReportDate.minusDays(28),
        lastReportDate
    ]);

    usePrefetchedHexData(state, dataPeriod);

    const trips = useTripCountsByHex(state, dataPeriod);
    const privacyThreshold = usePrivacyThreshold();

    const max = trips.data.reduce((max, d) => Math.max(max, d.count), 0);
    const min = trips.data.reduce(
        (min, d) => (d.count === 0 ? min : Math.min(min, d.count)),
        max
    );

    const steps = steppedArray(min, max, HEX_COLORS.length);

    const colorSteps = steps.map((value, i) => ({
        value,
        color: HEX_COLORS[Math.round((i * HEX_COLORS.length) / steps.length)]
    }));

    const [earliestDateResponse] = useGraphql<
        EarliestTripsMetricsDateData,
        EarliestTripsMetricsDateArgs
    >(EARLIEST_DATE_QUERY, { slug: mapView.slug });
    const earliestAllowed =
        earliestDateResponse?.mapView?.earliestTripsMetricDate;

    useEffect(() => {
        dispatchMapOptions({
            type: 'set loading data',
            value: trips.loading
        });
    }, [dispatchMapOptions, trips.loading]);

    const hoveredHex =
        trips.data.find(
            d =>
                d.hex === state.hoveredHexId &&
                (d.count > 0 || state.selectedHexId === d.hex)
        ) ?? null;

    useHexLayers(
        map,
        state,
        dispatch,
        max,
        min,
        trips.data,
        colorSteps.map(s => s.color),
        privacyThreshold
    );

    return (
        <>
            <div className={OVERLAY}>
                <ModePicker
                    options={mapOptions}
                    dispatchMapOptions={dispatchMapOptions}
                >
                    <div className={SELECT_WRAPPER}>
                        <label>
                            <FormattedMessage
                                key="select-date-label"
                                defaultMessage="Data Period"
                            />
                        </label>
                        <DateRangeSelect
                            selectedDateRange={dataPeriod}
                            onChange={value => setDataPeriod(value)}
                            latestAllowed={LocalDate.todayInTz(
                                mapView.ianaTimezone
                            )}
                            earliestAllowed={
                                earliestAllowed
                                    ? LocalDate.fromDateString(earliestAllowed)
                                    : undefined
                            }
                        />
                    </div>
                    <HourRangeSlider
                        hourRange={state.hourRange}
                        setHourRange={value =>
                            dispatch({ type: 'hourRange', value })
                        }
                    />
                </ModePicker>
                <TripsPrivacyCallout
                    showNoDataMsg={
                        !trips.loading &&
                        state.selectedHexId != null &&
                        max <= 0
                    }
                />
                <TripsSidebar
                    mapOptions={mapOptions}
                    dispatchMapOptions={dispatchMapOptions}
                    hourRange={state.hourRange}
                    dataPeriod={dataPeriod}
                    metric={metric}
                />
            </div>
            {max > 0 ? (
                <HeatmapKey
                    scale={colorSteps.map(({ value, color }) => ({
                        value: <FormattedNumber value={value} />,
                        color: `rgba(${color[0]}, ${color[1]}, ${
                            color[2]
                        }, ${color[3] || 255})`
                    }))}
                />
            ) : null}
            {map && (
                <TripsPopup
                    map={map}
                    hoveredHex={hoveredHex}
                    selectedHexId={state.selectedHexId}
                    privacyThreshold={privacyThreshold}
                />
            )}
        </>
    );
};

export default MapHexBins;

export type HexDatum = {
    hex: string;
    count: number;
};

export const EARLIEST_DATE_QUERY = gql`
    query EarliestTripsMetricsDate($slug: String) {
        mapView(slug: $slug) {
            id
            earliestTripsMetricDate
        }
    }
`;

export type Color = [number, number, number] | [number, number, number, number];

export const HEX_COLORS: Color[] = [
    [255, 221, 192, 0.55 * 255],
    [249, 188, 136, 0.6 * 255],
    [231, 119, 159, 0.65 * 255],
    [235, 81, 145, 0.7 * 255],
    [179, 82, 243, 0.75 * 255],
    [125, 24, 207, 0.8 * 255],
    [73, 5, 140, 0.9 * 255]
];
