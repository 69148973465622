import React, { useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import "/opt/build/repo/src/page-custom-data/page-list.tsx?resplendence=true";
import { useHistory, useLocation } from 'react-router';

import { Button, Container, PageBody, PageContent } from 'common/layout';
import { Title } from 'common/title';
import {
    CUSTOM_DATASETS_LIST_QUERY,
    fetchCustomDatasetRaw,
    useCustomDatasetsList,
    useDeleteCustomDataset
} from './api';
import Callout from 'common/callout';
import { useEffect } from 'react';
import { useApolloClient } from '@apollo/client';
import { useMapView } from 'common/use-map-view';
import { useCustomDatasets } from './custom-data-context';
import { sendEventAnalytics } from 'utils/use-analytics';
import downloadData from 'utils/download-data';

/*
@import 'style';
*/;

/**
 * A link to be placed in the "call-to-action" area in the top-right of the page
 * */
export const ACTION_LINK = "rx-page-custom-data-page-list-1"/*
    @include navigation-link;
    @include container;
    padding: 6rem 12rem;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    svg:first-child {
        font-size: 24rem;
        margin-right: 8rem;
    }
*/;

const TABLE = "rx-page-custom-data-page-list-2"/*
    @include table;

    tbody tr > :nth-child(2) {
        padding: 7.5rem 8rem;
    }
*/;

const COLOR_CIRCLE = "rx-page-custom-data-page-list-3"/*
    width: 16rem;
    height: 16rem;
    border-radius: 50%;
*/;

const ACTION_ROW = "rx-page-custom-data-page-list-4"/*
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    button {
        margin-right: 16rem;
    }
*/;

function ListPage() {
    const apolloClient = useApolloClient();
    const { id: mapviewId } = useMapView();
    const location = useLocation<'uploaded' | undefined>();
    const history = useHistory();

    const customDatasetsList = useCustomDatasetsList();
    const customDatasetsDispatch = useCustomDatasets().dispatch;
    const [
        deleteCustomDataset,
        { loading: isDeleting, error: deleteError }
    ] = useDeleteCustomDataset();

    const fileInputRef = useRef<HTMLInputElement>(null);

    const onFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files ? event.target.files[0] : null;
        if (file == null) {
            return;
        }

        history.push('./custom-data/upload', { file });
    };

    const deleteDataset = (key: string) => {
        if (
            !window.confirm(
                'Are you sure you want to delete this dataset for all users?'
            )
        ) {
            return;
        }

        const datasetName = customDatasetsList.find(d => d.id === key)?.name;
        if (datasetName != null) {
            sendEventAnalytics({
                name: datasetName,
                event_name: 'custom dataset event',
                action: 'deleted'
            });
        }

        // delete the custom dataset from the backend
        deleteCustomDataset({ variables: { customDatasetId: key } });
        // remove it from the CustomDatasetContext so it doesn't continue to
        // appear for the current session.
        customDatasetsDispatch({
            type: 'REMOVE_DATASET',
            payload: {
                id: key
            }
        });
    };

    useEffect(() => {
        // If we uploaded a dataset, then refetch our list of custom datasets.
        // We need to do this manually since we upload via a non-gql endpoint,
        // so the new dataset won't exist in the apollo cache at this time
        if (location.state === 'uploaded') {
            apolloClient.query({
                query: CUSTOM_DATASETS_LIST_QUERY,
                variables: { mapviewId },
                fetchPolicy: 'network-only'
            });
        }
    }, [apolloClient, mapviewId, location.state]);

    return (
        <PageContent>
            <Title
                title={
                    <FormattedMessage
                        key="title"
                        defaultMessage="Custom Data"
                    />
                }
            >
                <input
                    ref={fileInputRef}
                    type="file"
                    onChange={onFileUpload}
                    accept="text/csv"
                    style={{ display: 'none' }}
                />

                <div>
                    <Button
                        color="gray"
                        onClick={() => fileInputRef.current?.click()}
                        icon="Plus"
                    >
                        <FormattedMessage
                            key="upload-dataset"
                            defaultMessage="Upload Dataset"
                        />
                    </Button>
                </div>
            </Title>

            <PageBody>
                {location.state === 'uploaded' && (
                    <Callout
                        color="green"
                        dismiss={() =>
                            history.replace({ ...location, state: undefined })
                        }
                    >
                        <FormattedMessage
                            key="uploaded-success"
                            defaultMessage="Successfully uploaded custom dataset."
                        />
                    </Callout>
                )}
                {deleteError && (
                    <Callout color="red">
                        <FormattedMessage
                            key="delete-error"
                            defaultMessage="Error deleting custom dataset. {error}."
                            values={{ error: deleteError.message }}
                        />
                    </Callout>
                )}

                <Container>
                    {customDatasetsList.length > 0 ? (
                        <table className={TABLE}>
                            <thead>
                                <tr>
                                    <th>
                                        <FormattedMessage
                                            key="list-header-name"
                                            defaultMessage="Name"
                                        />
                                    </th>
                                    <th>
                                        <FormattedMessage
                                            key="list-header-color"
                                            defaultMessage="Color"
                                        />
                                    </th>
                                    <th>
                                        <FormattedMessage
                                            key="list-header-action"
                                            defaultMessage="Action"
                                        />
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {customDatasetsList.map(dataset => (
                                    <tr key={dataset.id}>
                                        <td>{dataset.name}</td>
                                        <td>
                                            <div
                                                className={COLOR_CIRCLE}
                                                style={{
                                                    backgroundColor:
                                                        dataset.hexColor
                                                }}
                                            />
                                        </td>
                                        <td>
                                            <div className={ACTION_ROW}>
                                                <Button
                                                    color="gray"
                                                    onClick={async () => {
                                                        const filename = `${dataset.name.replace(
                                                            /\s/g,
                                                            '_'
                                                        )}.csv`;
                                                        const fullDataset = await fetchCustomDatasetRaw(
                                                            apolloClient,
                                                            mapviewId,
                                                            dataset.id
                                                        );
                                                        downloadData(
                                                            filename,
                                                            fullDataset.csvFile
                                                        );
                                                        sendEventAnalytics({
                                                            event_name:
                                                                'data export event',
                                                            action: 'exported',
                                                            name: `custom-data: ${filename}`
                                                        });
                                                    }}
                                                    icon="Download"
                                                />
                                                <Button
                                                    color="outline-red"
                                                    onClick={() =>
                                                        deleteDataset(
                                                            dataset.id
                                                        )
                                                    }
                                                    icon="Delete"
                                                    loading={isDeleting}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    ) : (
                        <FormattedMessage
                            key="no-datasets"
                            defaultMessage="No uploaded datasets."
                        />
                    )}
                </Container>
            </PageBody>
        </PageContent>
    );
}

export default ListPage;
