import { useRef, useCallback, useEffect } from 'react';

/**
 * Turns a function into a throttled version of itself. If you call it a second time while it's
 * in its throttle duration, it will wait until the duration completes before making it. If you
 * call it more than that, it will only fire the most recent call when the duration completes
 * will throw out all the other ones.
 */
const useThrottled = <T extends (...args: any) => any>(
    callback: T,
    duration: number = 500
) => {
    // We use refs for all of these so that we can always access the most recent value
    // of these variables in our callback functions, even after multiple renders.
    // None of these state items require re-rendering on change.

    // So for example, if the throttled function is called many times, when the timeout
    // expires we want to use the most recent version of the callback, and the most
    // recent version of the args passed to that callback. If we used normal variables,
    // that wouldn't happen and we'd be stuck with the original arguments.

    const isThrottling = useRef(false);
    const cancelled = useRef(false);

    const savedCallback = useRef(callback);
    savedCallback.current = callback;

    const savedDuration = useRef(duration);
    savedDuration.current = duration;

    const args = useRef<Parameters<T> | null>(null);

    const result = useCallback((...latestArgs: Parameters<T>) => {
        if (isThrottling.current) {
            args.current = latestArgs;
        } else {
            isThrottling.current = true;

            setTimeout(() => {
                if (args.current && !cancelled.current) {
                    savedCallback.current(...args.current);
                    args.current = null;
                }
                isThrottling.current = false;
            }, savedDuration.current);

            savedCallback.current(...latestArgs);
        }
    }, []);

    // If this component unmounts, we don't want to call the throttled function anymore
    useEffect(() => {
        return () => {
            cancelled.current = true;
        };
    }, []);

    return result;
};

export default useThrottled;
