import React from 'react';
import "/opt/build/repo/src/page-explore/page-heatmaps/routes/download-modal.tsx?resplendence=true";
import { FormattedMessage } from 'react-intl';
import { geojsonToArcGIS } from '@terraformer/arcgis';

import Modal from 'common/modal';
import Icon from 'common/icon';
import { Button } from 'common/layout';
import downloadData from 'utils/download-data';
import { useMapView } from 'common/use-map-view';
import { DateRange } from 'common/date-picker';
import { AggregatedRouteFeature } from '../../layers/routes/sources';

/*
@import 'style';
*/;

const CONTENT = "rx-page-explore-page-heatmaps-routes-download-modal-1"/*
    max-width: 480rem;
*/;

const HEADER = "rx-page-explore-page-heatmaps-routes-download-modal-2"/*
    @include headline-5;
    color: $gray-90;
    padding-bottom: 16rem;
*/;

const EXPORTS = "rx-page-explore-page-heatmaps-routes-download-modal-3"/*
    @include text-body-link-underlined;
    color: $blue-50;
    padding: 24rem 0;
    &.first-child{
        margin-bottom: 8rem;
    }
*/;

const EXPORT_LINK = "rx-page-explore-page-heatmaps-routes-download-modal-4"/*
    display: flex;
    align-items: center;
    border-radius: 6rem;
    padding: 6rem 12rem;
    margin-bottom: 4rem;
    &:hover {
        background-color: $gray-10;
        cursor: pointer;
    }
    &:active {
        background-color: $gray-30;
    }
    svg {
        margin-right: 4rem;
        font-size: 20rem;
    }
*/;

const EULA = "rx-page-explore-page-heatmaps-routes-download-modal-5"/*
    @include text-body-tiny;
*/;

const EULA_LINK = "rx-page-explore-page-heatmaps-routes-download-modal-6"/*
    @include text-body-link-underlined-tiny;
    color: $blue-50;
*/;

const CANCEL = "rx-page-explore-page-heatmaps-routes-download-modal-7"/*
    display: flex;
    justify-content: flex-end;
    padding-top: 24rem;
*/;

/** Returns a uniform filename, built from the information provided*/
const generateFilename = (
    mapViewName: string,
    dateRange: DateRange,
    type: 'geojson' | 'arcgis' | 'csv'
) => {
    const extension =
        type === 'arcgis' || type === 'geojson' ? 'geojson' : 'csv';
    return `${mapViewName}-aggregated-routes-from-${dateRange[0].toString()}-to-${dateRange[1].toString()}.${extension}`;
};

/**
 * Prepares a CSV data string for downloading. Handles adding headers, data,
 * and the proper data format type.
 */
const generateCsv = (routes: AggregatedRouteFeature[]) => {
    const headerRow = [
        'Street Segment Name',
        'Trip Count',
        'Percentage of Matched Trips',
        'Segment Geometry ID'
    ];

    const dataRows = routes
        .sort((a, b) => b.properties.count - a.properties.count) // highest to lowest
        .map(f => [
            f.properties.name,
            f.properties.count,
            // floats may be stored as 8.00000000000000001 instead of 8.0
            f.properties.percentage.toFixed(1),
            f.properties.geometryId
        ]);

    return (
        // add the header
        headerRow.join(',') +
        '\n' +
        // add our data rows
        dataRows.map(row => row.join(',')).join('\n')
    );
};

/**
 * Prepares a GeoJSON data string for downloading. Handles converting to JSON
 * and prepending the data format type.
 */
const generateGeoJson = (routes: AggregatedRouteFeature[]) =>
    JSON.stringify({
        type: 'FeatureCollection',
        features: routes
    });

/**
 * Prepares an ArcGIS JSON data string for downloading. Handles converting
 * data to ArcGIS JSON and prepending the data format type.
 */
const generateArcGisJson = (routes: AggregatedRouteFeature[]) => {
    const arcGisFeatures = geojsonToArcGIS({
        type: 'FeatureCollection',
        features: routes
    });

    // ESRI requires this metadata to be supplied in order to properly import
    // features and their properties into ArcGIS
    const arcGisData = {
        displayFieldName: '',
        fieldAliases: {
            id: 'id',
            name: 'name',
            count: 'count',
            percentage: 'percentage',
            geometryId: 'geometryId'
        },
        geometryType: 'esriGeometryPolyline',
        spatialReference: {
            wkid: 4326,
            latestWkid: 4326
        },
        fields: [
            {
                name: 'id',
                type: 'esriFieldTypeString',
                alias: 'id',
                length: 36
            },
            {
                name: 'name',
                type: 'esriFieldTypeString',
                alias: 'name'
            },
            {
                name: 'count',
                type: 'esriFieldTypeInteger',
                alias: 'count'
            },
            {
                name: 'percentage',
                type: 'esriFieldTypeDouble',
                alias: 'percentage'
            },
            {
                name: 'geometryId',
                type: 'esriFieldTypeString',
                alias: 'geometryId',
                length: 36
            }
        ],
        features: arcGisFeatures
    };

    return JSON.stringify(arcGisData);
};

interface RoutesDownloadModalProps {
    isModalShowing: boolean;
    toggleModal: () => void;
    routesGeoJSONData: AggregatedRouteFeature[];
    dateRange: DateRange;
}

export const RoutesDownloadModal = ({
    dateRange,
    routesGeoJSONData,
    isModalShowing,
    toggleModal
}: RoutesDownloadModalProps) => {
    const mapViewName = useMapView().name;

    /**
     * onClick handler that generates data for the specified format,
     * downloads the data, then closes the modal.
     */
    const handleDownload = (type: 'geojson' | 'arcgis' | 'csv') => () => {
        const filename = generateFilename(mapViewName, dateRange, type);
        const data =
            type === 'geojson'
                ? generateGeoJson(routesGeoJSONData)
                : type === 'arcgis'
                ? generateArcGisJson(routesGeoJSONData)
                : generateCsv(routesGeoJSONData);

        downloadData(filename, data);
        toggleModal();
    };

    return (
        <Modal isModalShowing={isModalShowing} hide={toggleModal}>
            <div className={CONTENT}>
                <div className={HEADER}>
                    <FormattedMessage
                        key="choose-data-format"
                        defaultMessage="Choose data format"
                    />
                </div>
                <div>
                    <FormattedMessage
                        key="select-a-format"
                        defaultMessage="Select a format to download aggregated traversals data."
                    />
                </div>
                <div className={EXPORTS}>
                    <div
                        className={EXPORT_LINK}
                        onClick={handleDownload('geojson')}
                    >
                        <Icon icon="List-File" />
                        <FormattedMessage
                            key="export-geo-json"
                            defaultMessage="Export GeoJSON"
                        />
                    </div>
                    <div
                        className={EXPORT_LINK}
                        onClick={handleDownload('arcgis')}
                    >
                        <Icon icon="List-File" />
                        <FormattedMessage
                            key="export-arc-gis-json"
                            defaultMessage="Export ArcGIS JSON"
                        />
                    </div>
                    <div
                        className={EXPORT_LINK}
                        onClick={handleDownload('csv')}
                    >
                        <Icon icon="Spreadsheet" />
                        <FormattedMessage
                            key="export-csv"
                            defaultMessage="Export CSV"
                        />
                    </div>
                </div>
                <div className={EULA}>
                    <FormattedMessage
                        key="routes-eula-link"
                        defaultMessage={
                            '©2022 Copyright Ride Report. By downloading the listed dataset(s), you agree to the terms established in the {eula}.'
                        }
                        tagName="span"
                        values={{
                            eula: (
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className={EULA_LINK}
                                    href="/terms/eula"
                                >
                                    end user license agreement
                                </a>
                            )
                        }}
                    />
                </div>
                <div className={CANCEL}>
                    <Button color="blue" onClick={toggleModal}>
                        <FormattedMessage
                            key="cancel"
                            defaultMessage="Cancel"
                        />
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

export default RoutesDownloadModal;
