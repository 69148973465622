import React from 'react';
import { FormattedMessage } from 'react-intl';
import { MODAL_FORM, MODAL_TITLE, MODAL_BUTTONS } from 'common/modal';
import { Button } from 'common/layout';
import { CancelButtonText, DeleteButtonText } from 'strings';
import {
    UserListInfoFragment,
    DeleteUserMutationArgs,
    DeleteUserResult
} from 'graphql.g';
import { gql, useMutation } from '@apollo/client';
import { useUser } from 'common/user';
import { ErrorCallout } from 'common/error-message';

type Props = {
    onCancel: () => void;
    onSuccess: (email: string) => void;
    user: UserListInfoFragment;
};

const DELETE_USER_MUTATION = gql`
    mutation DeleteUser($email: String!) {
        deleteUserAuthorization(deletedUserEmail: $email) {
            userEmail
        }
    }
`;

function DeleteVerification({ onCancel, onSuccess, user }: Props) {
    const currentUser = useUser();
    const [deleteUser, { loading, error }] = useMutation<
        DeleteUserResult,
        DeleteUserMutationArgs
    >(DELETE_USER_MUTATION, {
        update(cache, { data }) {
            if (data?.deleteUserAuthorization?.userEmail) {
                const email = data?.deleteUserAuthorization?.userEmail;
                cache.modify({
                    id: cache.identify(currentUser.orgAuth.organization),
                    fields: {
                        users(users, { readField }) {
                            // Remove this user from their organization list in the cache
                            return users.filter(
                                u => readField('email', u) !== email
                            );
                        }
                    }
                });
            }
        }
    });
    const onDelete = async () => {
        try {
            await deleteUser({ variables: { email: user.email } });
            onSuccess(user.email);
        } catch (err) {
            // We'll handle this with getting {error} out of the mutation hook
            console.error(err);
            window.rollbar.error(err);
        }
    };
    return (
        <div className={MODAL_FORM}>
            <h3 className={MODAL_TITLE}>
                <FormattedMessage
                    key="delete-verification-title"
                    defaultMessage="Are you sure?"
                />
            </h3>
            <p>
                <FormattedMessage
                    key="delete-verification-body"
                    defaultMessage="You are about to revoke organization access for this user. This action cannot be undone."
                />
            </p>
            {error && <ErrorCallout error={error} />}
            <div className={MODAL_BUTTONS}>
                <Button color="blue" onClick={onCancel}>
                    <CancelButtonText />
                </Button>
                <Button color="red" disabled={loading} onClick={onDelete}>
                    <DeleteButtonText />
                </Button>
            </div>
        </div>
    );
}

export default DeleteVerification;
