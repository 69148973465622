import { createContext, useContext } from 'react';
import { MapView } from 'types';
import { ContextError } from 'utils/errors';

const MapViewContext = createContext<MapView | null>(null);

/**
 * Supplies the map view to child components, that they can access with useMapView.
 */
export const MapViewProvider = MapViewContext.Provider as React.Provider<
    MapView
>;

/**
 * Get the current MapView if one is selected. Otherwise get null.
 */
export function useOptionalMapView() {
    return useContext(MapViewContext);
}

/**
 * Regularly-needed info about the current map view.
 * Supplied by a <MapViewProvider> somewhere higher up in the component tree.
 */
export function useMapView() {
    const mapView = useOptionalMapView();
    if (!mapView) throw new ContextError('MapView');
    return mapView;
}
