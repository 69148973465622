import Callout from 'common/callout';
import { Container, ContainerHeader } from 'common/layout';
import Loading from 'common/loading';
import useLastReportDate from 'common/use-last-report-date';
import { useMapView } from 'common/use-map-view';
import { OperatorName } from 'constants/operators';
import { PolicyType } from 'graphql.g';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory, useLocation } from 'react-router';
import "/opt/build/repo/src/page-policy/page-dashboard.tsx?resplendence=true";
import { LocalDate } from 'utils/date-tools';
import useDateRangeQuery from 'utils/use-date-range-query';
import PolicyCommonPage from './common-page';
import PolicyDashboardSidebar from './dashboard/sidebar';
import PolicyDashboardTable from './dashboard/table';
import { POLICY_TYPE_STRINGS } from './strings';
import { usePoliciesWithMetrics } from './use-policies-with-metrics';
import { sortPolicies } from './util';

/*
    @import 'style';
*/;

const PAGE_LAYOUT = "rx-page-policy-page-dashboard-1"/*
    display: grid;
    grid-template-columns: 2fr minmax(300rem, 1fr);
    @include mobile {
        grid-template-columns: 1fr;
    }
    grid-gap: 32rem;
    align-items: start;
*/;

const POLICY_TYPE_CONTAINER = "rx-page-policy-page-dashboard-2"/*
    display: flex;
    flex-direction: column;
    row-gap: 32rem;
    column-gap: 32rem;
*/;

const TEXT_LINK = "rx-page-policy-page-dashboard-3"/*
    @include text-body-link-underlined;
    color: $blue-50;
*/;

function PolicyDashboardPage() {
    const history = useHistory();
    const location = useLocation<
        | undefined
        | {
              action: 'delete';
              policyName: string;
          }
    >();
    const { activeOperators } = useMapView();
    const intl = useIntl();
    const lastReportDate = useLastReportDate();
    const [operatorFilters, setOperatorFilters] = useState<OperatorName[]>([]);
    const [dateRange, setDateRange] = useDateRangeQuery([
        lastReportDate.minusDays(6),
        lastReportDate
    ]);
    const [policiesFromApi, isLoading, policiesError] = usePoliciesWithMetrics(
        dateRange[0],
        dateRange[1]
    );

    const policies =
        isLoading === false && policiesError == null && policiesFromApi != null
            ? sortPolicies(policiesFromApi)
            : [];
    const filteredPolicies =
        operatorFilters.length > 0
            ? policies.filter(policy => {
                  // if the policy applies to all operators, include it
                  if (policy.operators.length === 0) return true;
                  // otherwise check if the operators are included in the filters
                  return policy.operators.some(
                      op => !operatorFilters.includes(op.name)
                  );
              })
            : policies;

    const policyTypes: PolicyType[] = [];
    for (const policy of filteredPolicies) {
        if (!policyTypes.includes(policy.policyType)) {
            policyTypes.push(policy.policyType);
        }
    }
    const sortedPolicyTypes = [...policyTypes].sort((a, b) =>
        a.localeCompare(b)
    );

    const policiesByType = sortedPolicyTypes.map(policyType => {
        const policiesForType = {
            type: policyType,
            // use the translated name whenever possible
            name: intl.formatMessage(
                POLICY_TYPE_STRINGS[policyType] ?? policyType
            ),
            // get all policies of this type
            policies: filteredPolicies.filter(p => p.policyType === policyType)
        };

        // if we don't have policies
        const hasMetrics =
            policiesForType.policies.map(p => p.metric).filter(Boolean).length >
            0;
        return hasMetrics ? policiesForType : null;
    });

    const earliestDate = filteredPolicies.reduce(
        // get the earliest start date of all policies
        (earliestDate, currentPolicy) => {
            // don't look at policies without metrics
            if (currentPolicy.metric == null) return earliestDate;

            const currDate = LocalDate.fromDateString(currentPolicy.startDate);
            return currDate.isBefore(earliestDate) ? currDate : earliestDate;
        },
        LocalDate.fromDate(new Date())
    );

    let operatorsInPolicies = new Set<OperatorName>();
    policies.forEach(policy => {
        // check if policy is for all operators, then add active operators tied to mapview
        if (policy.operators.length === 0) {
            activeOperators.forEach(operator =>
                operatorsInPolicies.add(operator.name)
            );
        }
        // add operators affiliated with policy
        else {
            policy.operators.forEach(operator => {
                operatorsInPolicies.add(operator.name);
            });
        }
    });
    return (
        <PolicyCommonPage>
            <Loading kind="clear" loading={isLoading} />
            {location.state?.action === 'delete' ? (
                <Callout
                    color="blue"
                    dismiss={() =>
                        history.replace({ ...location, state: undefined })
                    }
                >
                    {
                        <FormattedMessage
                            key="delete-user-sucess"
                            defaultMessage="{policyName} was sucessfully deleted."
                            values={{ policyName: location.state.policyName }}
                        />
                    }
                </Callout>
            ) : null}
            {policiesError != null ? (
                <Callout color="red">
                    <FormattedMessage
                        key="policies-error"
                        defaultMessage="There was an error loading policy metrics. If this persists, please contact <a>support@ridereport.com</a>."
                        values={{
                            a: msg => (
                                <a
                                    className={TEXT_LINK}
                                    href="mailto:support@ridereport.com"
                                >
                                    {msg}
                                </a>
                            )
                        }}
                    />
                </Callout>
            ) : filteredPolicies.length === 0 ? (
                !isLoading && (
                    <Container>
                        <FormattedMessage
                            key="no-policies"
                            defaultMessage="There are no active policies."
                        />
                    </Container>
                )
            ) : (
                <div className={PAGE_LAYOUT}>
                    <div className={POLICY_TYPE_CONTAINER}>
                        {policiesByType.map(policyType =>
                            policyType == null ? null : (
                                <Container key={policyType.type}>
                                    <ContainerHeader>
                                        {policyType.name}
                                    </ContainerHeader>
                                    <PolicyDashboardTable
                                        operators={operatorsInPolicies}
                                        policies={policyType.policies}
                                    />
                                </Container>
                            )
                        )}
                    </div>

                    <PolicyDashboardSidebar
                        operators={operatorsInPolicies}
                        operatorFilters={operatorFilters}
                        setOperatorFilters={setOperatorFilters}
                        dateRange={[earliestDate, lastReportDate]}
                        selectedDateRange={dateRange}
                        setSelectedDateRange={setDateRange}
                    />
                </div>
            )}
        </PolicyCommonPage>
    );
}

export default PolicyDashboardPage;
