import orderBy from 'lodash/orderBy';
import { useEffect, useState } from 'react';
import useThrottled from './use-throttled';

/** From https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions */
function escapeRegExp(string: string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

function search<T>(
    items: T[],
    getText: (item: T) => string,
    search: string
): T[] {
    const escapedSearch = escapeRegExp(search);
    const exactMatch = new RegExp('^' + escapedSearch + '$', 'i');
    const beginsWithString = new RegExp('^' + escapedSearch, 'i');
    const exactWordMatch = new RegExp('\\b' + escapedSearch + '\\b', 'i');
    const wordStartMatch = new RegExp('\\b' + escapedSearch, 'i');
    const anyMatch = new RegExp(escapedSearch, 'i');
    const rankedItems = items.map(item => {
        const text = getText(item);
        if (text.match(exactMatch)) return [item, 5] as const;
        if (text.match(beginsWithString)) return [item, 4] as const;
        if (text.match(exactWordMatch)) return [item, 3] as const;
        if (text.match(wordStartMatch)) return [item, 2] as const;
        if (text.match(anyMatch)) return [item, 1] as const;
        else return [item, 0] as const;
    });
    return orderBy(rankedItems, [1, 0], ['desc', 'asc'])
        .filter(([, rank]) => rank > 0)
        .map(([item]) => item);
}

/**
 * Filters the given items using a given search text, and ranks them by the quality
 * of the match.
 */
function useSearchFilter<T>(
    items: T[],
    getText: (item: T) => string,
    filter: string
): T[] {
    const [filteredItems, setFilteredItems] = useState<T[]>(items);
    const setFilteredItemsThrottled = useThrottled(setFilteredItems, 100);
    useEffect(() => {
        if (filter) {
            setFilteredItemsThrottled(search(items, getText, filter));
        } else {
            setFilteredItems(items);
        }
    }, [filter, setFilteredItemsThrottled, items, getText]);
    return filteredItems;
}

export default useSearchFilter;
