import { defineMessages } from 'react-intl';

export const messages = defineMessages({
    meters: 'meters',
    kilometers: 'kilometers',
    seconds: 'seconds',
    minutes: 'minutes',
    hours: 'hours',
    days: 'days',
    'meters-short': 'm',
    'kilometers-short': 'km',
    'seconds-short': 's',
    'minutes-short': 'min',
    'hours-short': 'h',
    'days-short': 'd',
    'no-min': 'No Min',
    'no-max': 'No Max'
});

export const SECONDS_PER_MINUTE = 60;
export const SECONDS_PER_HOUR = SECONDS_PER_MINUTE * 60;
export const SECONDS_PER_DAY = SECONDS_PER_HOUR * 24;
export const METERS_PER_KILOMETER = 1000;

type DistanceUnit = 'meters' | 'kilometers';
type DurationUnit = 'seconds' | 'minutes' | 'hours' | 'days';

const TIME_UNITS_IN_SECONDS: Record<DurationUnit, number> = {
    seconds: 1,
    minutes: SECONDS_PER_MINUTE,
    hours: SECONDS_PER_HOUR,
    days: SECONDS_PER_DAY
};
const DISTANCE_UNITS_IN_METERS: Record<DistanceUnit, number> = {
    meters: 1,
    kilometers: METERS_PER_KILOMETER
};
export const UNIT_IN_COMMON_VALUE = {
    ...DISTANCE_UNITS_IN_METERS,
    ...TIME_UNITS_IN_SECONDS
};

export type Distance = { value: number; unit: DistanceUnit };
export type Duration = { value: number; unit: DurationUnit };
