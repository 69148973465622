import { defineMessages } from 'react-intl';

export const POLICY_TYPE_STRINGS = defineMessages({
    trip_start: 'Trip Start Fee',
    trip_start_or_stop: 'Trip Start or Stop Fee',
    trip_start_xor_stop: 'Trip Start or Stop Fee (exclusive or)',
    trip_start_and_stop: 'Trip Start and Stop Fee',
    trip_end: 'Trip End Fee',
    right_of_way: 'Right of Way Fee',
    vehicle_cap: 'Vehicle Cap',
    vehicle_cap_percentage: 'Percentage-based Vehicle Cap',
    no_parking_zone: 'No Parking Zone',
    minimum_deployment: 'Minimum Deployment',
    minimum_deployment_percentage: 'Percentage-based Minimum Deployment',
    speed_limit: 'Speed Limit',
    no_ride_zone: 'No Ride Zone',
    max_idle_time: 'Max Idle Time',
    mean_active: 'Mean Active Vehicles Fee',
    provider_deployment_cap: 'Provider Deployment Cap',
    provider_deployment_minimum: 'Provider Deployment Minimum'
});
