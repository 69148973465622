import { Select } from 'common/select';
import React from 'react';
import { defineMessages } from 'react-intl';
import { useIntl } from 'utils/use-intl';
import {
    ChartMetric,
    CHART_MESSAGES,
    CHART_TYPES,
    TRIP_HISTOGRAMS
} from './types';
import "/opt/build/repo/src/page-analyze/metric-selector.tsx?resplendence=true";

/*
@import 'style';
*/;

const METRIC_SELECTOR = "rx-page-analyze-metric-selector-1"/*
    width: 200rem;
*/;

type Props = {
    queryKey?: string;
    metric: ChartMetric;
    setMetric: (metric: ChartMetric) => void;
    metrics?: readonly ChartMetric[];
};

const chartOptionGroups = defineMessages({
    'trip-histograms': 'Trip Histograms',
    'metrics-by-day': 'Metrics By Day'
});

function MetricSelector({ metric, setMetric, metrics }: Props) {
    const intl = useIntl();

    const options = metrics
        ? metrics.map(metric => ({
              value: metric,
              label: intl.formatMessage(CHART_MESSAGES[metric])
          }))
        : [
              {
                  label: intl.formatMessage(
                      chartOptionGroups[`trip-histograms`]
                  ),
                  options: CHART_TYPES.filter(type =>
                      TRIP_HISTOGRAMS.includes(type)
                  ).map(metric => ({
                      value: metric,
                      label: intl.formatMessage(CHART_MESSAGES[metric])
                  }))
              },
              {
                  label: intl.formatMessage(
                      chartOptionGroups[`metrics-by-day`]
                  ),
                  options: CHART_TYPES.filter(
                      type => !TRIP_HISTOGRAMS.includes(type)
                  ).map(metric => ({
                      value: metric,
                      label: intl.formatMessage(CHART_MESSAGES[metric])
                  }))
              }
          ];

    return (
        <div className={METRIC_SELECTOR}>
            <Select
                value={{
                    value: metric,
                    label: intl.formatMessage(CHART_MESSAGES[metric])
                }}
                onChange={option => option != null && setMetric(option?.value)}
                options={options}
            />
        </div>
    );
}

export default MetricSelector;
