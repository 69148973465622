/**
 * Tools for rendering maps that are used on multiple pages
 */

import { AoiInfoFragment, AreaDetailsFragment } from 'graphql.g';
import {
    MultiPolygon,
    Polygon,
    Feature,
    Geometry,
    GeoJsonProperties,
    FeatureCollection
} from 'geojson';
import { AnySourceData, Layer, Sources, Style } from 'mapbox-gl';
import { intoDict } from 'utils/helpers';

import { useMapView } from './use-map-view';
import { useRef, useState, useEffect } from 'react';
import MapboxGL from 'mapbox-gl';

import DEFAULT_STYLE from 'assets/default-style.json';
import DEFAULT_LAYERS from 'assets/default-layers.json';
import LABEL_LAYERS from 'assets/label-layers.json';
import { MeasurementUnitSystemType } from 'graphql.g';
import * as constants from 'constants/map';
import useThrottled from 'utils/use-throttled';
import CUSTOM_MARKER_SVG from 'assets/custom_map_marker.svg';

MapboxGL.accessToken = constants.MAPBOX_ACCESS_TOKEN;

/**
 * A hook to manage a mapbox map object with our standard settings.
 *
 * @returns
 * A tuple, the first element is the map itself (which will be null
 * before it has loaded), and second is the React ref which will need to be
 * attached to an element.
 */
type UseMapboxOptions = {
    showControls?: boolean;
    showLabels?: boolean;
    showSatellite?: boolean;
    layers?: Layer[];
    sources?: Sources;
};
export function useMapbox({
    showControls = true,
    showLabels = true,
    showSatellite = false,
    layers = [],
    sources = {}
}: UseMapboxOptions) {
    const [map, setMap] = useState<MapboxGL.Map | null>(null);
    const [loaded, setLoaded] = useState(false);
    const container = useRef<HTMLDivElement>(null);
    const mapView = useMapView();
    const [satelliteLoaded, setSatelliteLoaded] = useState(false);

    // create the map
    useEffect(() => {
        // track if the component has unmounted in the middle of creating the map
        let cancelled = false;

        // callback to clean up the map data (if any) on unmounting
        let destroy: () => void;

        // there's a race condition where the container component hasn't yet calculated its full
        // height before effects run. setTimeout() here tells the browser to finish everything else
        // it's doing before creating the map.
        setTimeout(() => {
            const mapRef = container.current;

            const center = {
                lng:
                    (mapView.initialViewBounds[0] +
                        mapView.initialViewBounds[2]) /
                    2,
                lat:
                    (mapView.initialViewBounds[1] +
                        mapView.initialViewBounds[3]) /
                    2
            };
            if (!cancelled && mapRef) {
                const newMap = new MapboxGL.Map({
                    container: mapRef,
                    trackResize: true,
                    maxBounds: mapView.maxBounds,
                    interactive: showControls,
                    attributionControl: false,
                    style: {
                        ...DEFAULT_STYLE,
                        layers: []
                    } as MapboxGL.Style,
                    center
                });

                newMap.on('load', () => {
                    setMap(newMap);
                });

                const img = new Image(32, 32);
                img.onload = () =>
                    newMap.addImage('custom-data-marker', img, { sdf: true });
                img.src = CUSTOM_MARKER_SVG;

                destroy = () => newMap.remove();
                if (showControls) {
                    newMap.addControl(
                        new MapboxGL.ScaleControl({
                            unit:
                                mapView.measurementSystem ===
                                MeasurementUnitSystemType.METRIC
                                    ? 'metric'
                                    : 'imperial'
                        }),
                        'bottom-right'
                    );
                    newMap.addControl(
                        new MapboxGL.NavigationControl(),
                        'bottom-right'
                    );
                }
            }
        }, 0);

        return () => {
            cancelled = true;
            // another race condition for which this is the only solution I could figure out
            // otherwise, some hooks end up running and crashing because mapbox gives no
            // guarantee that weird things won't happen after calling map.remove()
            if (destroy) setTimeout(destroy, 0);
            setMap(null);
        };
    }, [
        mapView.initialViewBounds,
        mapView.maxBounds,
        mapView.measurementSystem,
        showControls,
        showLabels
    ]);

    const setStyleThrottled = useThrottled(
        map ? (style: Style) => map.setStyle(style) : () => {},
        500
    );

    useEffect(() => {
        if (map && showSatellite) {
            if (!satelliteLoaded) {
                setSatelliteLoaded(true);
            }
        }
    }, [map, satelliteLoaded, showSatellite]);
    useEffect(() => {
        if (map) {
            setStyleThrottled({
                ...DEFAULT_STYLE,
                sources: {
                    ...getCommonSources(satelliteLoaded),
                    ...sources
                },
                layers: [
                    ...getCommonLayers({
                        showSatellite,
                        satelliteLoaded
                    }),
                    ...layers,
                    ...(showLabels ? (LABEL_LAYERS as Layer[]) : [])
                ]
            });
            setLoaded(true);
        }
    }, [
        layers,
        map,
        satelliteLoaded,
        setStyleThrottled,
        showLabels,
        showSatellite,
        sources
    ]);

    return [loaded ? map : null, container] as const;
}

/**
 * Standard type for an area of interest with its geojson geometry info attached.
 */
export type AreaWithGeometry = AoiInfoFragment & {
    geometry: MultiPolygon | Polygon;
};
export type AreaDetails = AreaDetailsFragment & {
    geometry: MultiPolygon | Polygon;
};

type Operator = {
    name: string;
    operatorId: string;
};

/**
 * Turn the given list of areas of interest into a mapbox source.
 * @param areasOfInterest - The areas to include
 * @param operators - All of the mapview's operators
 * @param getAreaColor - this can be obtained from `useGetAreaColor` in use-areas.tsx
 */
export function getAreaSource(
    areasOfInterest: AreaWithGeometry[],
    operators: Operator[],
    getAreaColor: (id: string) => { color: string; backgroundColor: string }
): AnySourceData {
    const source = getEmptyAreaSource();
    source.data = getAreaSourceData(areasOfInterest, operators, getAreaColor);
    return source;
}

/**
 * Turn the given list of areas of interest into feature collection data to be
 * used in a mapbox source.
 * @param areasOfInterest - The areas to include
 * @param operators - All of the mapview's operators
 * @param getAreaColor - this can be obtained from `useGetAreaColor` in use-areas.tsx
 */
export function getAreaSourceData(
    areasOfInterest: AreaWithGeometry[],
    operators: Operator[],
    getAreaColor: (id: string) => { color: string; backgroundColor: string }
): FeatureCollection<Geometry, GeoJsonProperties> {
    const features: Feature<Geometry, GeoJsonProperties>[] = [];
    const noAoiOperators = operators
        .filter(
            op =>
                !areasOfInterest.find(area =>
                    area.operatorIds.includes(op.operatorId)
                )
        )
        .map(op => op.name);

    const operatorsById = intoDict(
        operators.map(op => [op.operatorId, op.name])
    );

    for (const { geometry, operatorIds, name, id } of areasOfInterest) {
        features.push({
            type: 'Feature',
            geometry,
            properties: {
                color: getAreaColor(id).backgroundColor,
                name,
                shared: operatorIds.length === 0,
                operators: operatorIds.length
                    ? operatorIds.map(id => operatorsById[id])
                    : noAoiOperators
            }
        });
    }
    return { type: 'FeatureCollection', features };
}

/**
 * Creates an empty mapbox source that can be populated later with areas of
 * interest data
 */
export const getEmptyAreaSource = () => ({
    type: 'geojson' as const,
    data: {
        type: 'FeatureCollection' as const,
        features: [] as Feature[]
    },
    cluster: false
});

/**
 * The layers needed to render areas of interest on the mapview
 * @param filter - A custom mapbox filter function to apply to select which
 *  areas should be displayed.
 */
export function getAreaLayers(
    filter: MapboxGL.Expression = ['literal', true]
): Layer[] {
    return [
        {
            id: `areas-of-interest`,
            type: 'fill',
            source: 'aoi-geographies',
            filter,
            paint: {
                'fill-color': ['get', 'color'],
                'fill-opacity': [
                    'interpolate',
                    ['linear', 0.5],
                    ['zoom'],
                    8,
                    0.7,
                    17,
                    0
                ],

                'fill-outline-color': ['get', 'color']
            }
        },
        {
            id: `areas-of-interest-line`,
            type: 'line',
            source: 'aoi-geographies',
            filter,
            paint: {
                'line-color': ['get', 'color'],
                'line-width': 2
            }
        }
    ];
}

export const getShadingZoneSource = (
    shadingZone: Geometry
): Sources[string] => ({
    type: 'geojson',
    data: {
        type: 'Feature',
        geometry: shadingZone,
        properties: {}
    },
    cluster: false
});

export const getShadingZoneLayer = (showSatellite: boolean): Layer => ({
    id: 'shading-zone',
    type: 'fill',
    source: 'shading-zone',
    paint: {
        'fill-color': showSatellite ? '#91bbff' : '#000',
        'fill-opacity': showSatellite
            ? ['interpolate', ['linear', 0.9], ['zoom'], 11, 0.2, 17, 0]
            : 0.05
    }
});

function getCommonLayers({
    showSatellite = false,
    satelliteLoaded = false
}: {
    showLabels?: boolean;
    showSatellite?: boolean;
    satelliteLoaded?: boolean;
}): Layer[] {
    return [
        ...DEFAULT_LAYERS,
        ...(satelliteLoaded
            ? [
                  {
                      id: 'satellite',
                      source: 'satellite',
                      type: 'raster',
                      paint: {
                          'raster-opacity': showSatellite ? 1 : 0
                      }
                  }
              ]
            : [])
    ] as Layer[];
}

function getCommonSources(satellite: boolean): Sources {
    const result = { ...DEFAULT_STYLE.sources } as Sources;

    if (satellite) {
        result.satellite = {
            tileSize: 256,
            type: 'raster',
            url: 'mapbox://mapbox.satellite'
        };
    }

    return result;
}
