interface Config {
    apiHost: string;
    authHost: string;
    clientId: string;
}

const config = {
    apiHost: process.env.API_HOST,
    authHost: process.env.AUTH_HOST,
    clientId: process.env.CLIENT_ID
    // if you add a config var, make sure to add it to both
    // webpack.config.js and jest.setup.ts
};

if (
    config.apiHost == null ||
    config.authHost == null ||
    config.clientId == null
) {
    throw new Error(
        'ConfigError: config variables cannot be undefined. Ensure all environment variables are provided via the webpack and test setup configs.'
    );
}

export default config as Config;
