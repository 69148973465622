import React from 'react';
import {
    defineMessages,
    FormattedMessage,
    MessageDescriptor,
    useIntl
} from 'react-intl';
import { VehicleType, FeedType } from 'types';
import { RuleType, VehicleClass } from 'graphql.g';

const m = defineMessages({
    bike: 'bike',
    scooter: 'scooter',
    escooter: 'escooter',
    ebike: 'ebike',
    moped: 'moped',
    car: 'car',
    other: 'other',
    cargo_bike: 'cargo bike',
    cargo_ebike: 'cargo ebike',
    seated_scooter: 'seated scooter',
    delivery_robot: 'delivery robot',
    motorcycle: 'motorcycle',
    bus: 'bus',
    truck: 'truck',
    'feed-type-mds':
        'This operator provides an official MDS feed. The data should be highly accurate and additional features are available.',
    'feed-type-mds-disabled':
        "This operator provides an official MDS feed, but new features haven't been enabled for it yet.",
    'feed-type-other':
        'This operator does not provide an official MDS feed. The data may be less accurate and some features will be unavailable.',
    'status-available': 'available',
    'status-unavailable': 'unavailable',
    'status-reserved': 'reserved',
    'status-parked': 'parked',
    'rule-type-rate': 'Rate',
    'rule-type-time': 'Time',
    'rule-type-speed': 'Speed',
    'rule-type-user': 'User',
    'rule-type-count': 'Count',
    'vehicle-class-bikes': 'Bikes',
    'vehicle-class-cars': 'Cars',
    'vehicle-class-ebikes': 'E-Bikes',
    'vehicle-class-mopeds': 'Mopeds',
    'vehicle-class-scooters': 'Scooters',
    'vehicle-class-other': 'Other',
    'vehicle-class-buses': 'Buses',
    'vehicle-class-trucks': 'Trucks',
    'vehicle-class-cargo-bikes': 'Cargo Bikes',
    'vehicle-class-cargo-ebikes': 'Cargo E-Bikes',
    'vehicle-class-delivery-robots': 'Delivery Robots',
    'vehicle-class-motorcycles': 'Motorcycles',
    'vehicle-class-seated-scooters': 'Seated Scooters'
});

export const vehicleTypes = {
    [VehicleType.Unknown]: m.other,
    [VehicleType.Bike]: m.bike,
    [VehicleType.Scooter]: m.scooter,
    [VehicleType.eBike]: m.ebike,
    [VehicleType.eScooter]: m.escooter,
    [VehicleType.Moped]: m.moped,
    [VehicleType.Car]: m.car,
    [VehicleType.CargoBike]: m.cargo_bike,
    [VehicleType.CargoEbike]: m.cargo_ebike,
    [VehicleType.SeatedScooter]: m.seated_scooter,
    [VehicleType.DeliveryRobot]: m.delivery_robot,
    [VehicleType.Motorcycle]: m.motorcycle,
    [VehicleType.Bus]: m.bus,
    [VehicleType.Truck]: m.truck
};

export const feedDescription = (hasMds: boolean, feedType: FeedType) => {
    if (hasMds) return m['feed-type-mds'];
    else if (feedType === FeedType.Mds) return m['feed-type-mds-disabled'];
    else return m['feed-type-other'];
};

export const statuses = {
    available: m['status-available'],
    unavailable: m['status-unavailable'],
    parked: m['status-parked'],
    reserved: m['status-reserved']
};

export const PAGE_NOT_FOUND = (
    <FormattedMessage key="page-not-found" defaultMessage="Page not found" />
);

// used for document titles
export const PageNotFoundString = () => {
    const intl = useIntl();
    const m = defineMessages({
        'page-not-found': 'Page not found'
    });
    return intl.formatMessage(m['page-not-found']);
};

export function RuleTypeString({ type }: { type: RuleType }) {
    const intl = useIntl();
    const message = m['rule-type-' + type];
    return <>{message ? intl.formatMessage(message) : type}</>;
}

export const vehicleClasses: { [key in VehicleClass]: MessageDescriptor } = {
    [VehicleClass.bike]: m['vehicle-class-bikes'],
    [VehicleClass.car]: m['vehicle-class-cars'],
    [VehicleClass.ebike]: m['vehicle-class-ebikes'],
    [VehicleClass.moped]: m['vehicle-class-mopeds'],
    [VehicleClass.scooter]: m['vehicle-class-scooters'],
    [VehicleClass.other]: m['vehicle-class-other'],
    [VehicleClass.bus]: m['vehicle-class-buses'],
    [VehicleClass.truck]: m['vehicle-class-trucks'],
    [VehicleClass.cargo_bike]: m['vehicle-class-cargo-bikes'],
    [VehicleClass.cargo_ebike]: m['vehicle-class-cargo-ebikes'],
    [VehicleClass.delivery_robot]: m['vehicle-class-delivery-robots'],
    [VehicleClass.motorcycle]: m['vehicle-class-motorcycles'],
    [VehicleClass.seated_scooter]: m['vehicle-class-seated-scooters'],
    [VehicleClass.pedestrian]: m['vehicle-class-pedestrian']
};

export function VehicleClassString({ value }: { value: VehicleClass }) {
    const intl = useIntl();
    return <>{intl.formatMessage(vehicleClasses[value])}</>;
}

export function CancelButtonText() {
    return <FormattedMessage key="cancel-button" defaultMessage="Cancel" />;
}

export function DeleteButtonText() {
    return <FormattedMessage key="delete-button" defaultMessage="Delete" />;
}
