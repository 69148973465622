import { gql } from '@apollo/client';
import { Button, Container, ContainerHeader, LINK } from 'common/layout';
import { useMapView } from 'common/use-map-view';
import {
    EarliestFilteredTripDateArgs,
    EarliestFilteredTripDateData,
    GetTripFiltersArgs,
    GetTripFiltersData,
    GetTripFiltersWithExcludedPercentArgs,
    GetTripFiltersWithExcludedPercentData
} from 'graphql.g';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import useData, { useGraphql } from 'utils/use-data';
import CommonPage from './common-page';
import "/opt/build/repo/src/page-health/page-trip-filter.tsx?resplendence=true";
import TripFilterRow, { TripFilterRowProps } from './page-trip-filter-row';
import Loading from 'common/loading';
import { Link } from 'router';
import { useHistory, useLocation } from 'react-router';
import Callout from 'common/callout';
import {
    GET_TRIP_FILTERS,
    TRIP_FILTER_WITH_EXCLUDED_FRAGMENT
} from './page-trip-filter-common';
import { useUser } from 'common/user';
import { TripFilterForm } from './page-trip-filter-new';
import { useVehicleClasses } from 'common/vehicle-classes';
import { Dropdown } from 'common/dropdown';
import { DateRange, DateRangePicker } from 'common/date-picker';
import { LocalDate } from 'utils/date-tools';
import useLastReportDate from 'common/use-last-report-date';
import DownloadButton from 'common/download-button';
import { supportEmail } from 'constants/ridereport';

/*
@import '../style.scss';
*/;

const EARLIEST_FILTERED_TRIP_DATE_QUERY = gql`
    query EarliestFilteredTripDate($slug: String!) {
        mapView(slug: $slug) {
            earliestFilteredTripDate
        }
    }
`;

function useEarliestFilteredTripsDate(): LocalDate | undefined {
    const { slug } = useMapView();
    const date = useData<
        EarliestFilteredTripDateData,
        EarliestFilteredTripDateArgs
    >(EARLIEST_FILTERED_TRIP_DATE_QUERY, { slug })?.mapView
        .earliestFilteredTripDate;
    if (!date) return undefined;
    return LocalDate.fromDateString(date);
}

const DROPDOWN_BUTTONS = "rx-page-health-page-trip-filter-1"/*
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    > * {
        margin-left: 8rem;
    }
*/;

type ExportProps = {
    onClose: () => void;
};

function Export({ onClose }: ExportProps) {
    const lastReportDate = useLastReportDate();
    const [dateRange, setDateRange] = useState<DateRange>([
        lastReportDate.minusDays(14),
        lastReportDate
    ]);
    const earliestFilteredTripDate = useEarliestFilteredTripsDate();
    const { slug } = useMapView();
    return (
        <>
            <DateRangePicker
                selectedDateRange={dateRange}
                onChange={setDateRange}
                earliestAllowed={earliestFilteredTripDate}
                latestAllowed={lastReportDate}
            />
            <div className={DROPDOWN_BUTTONS}>
                <Button onClick={onClose}>
                    <FormattedMessage
                        key="download-cancel"
                        defaultMessage="Cancel"
                    />
                </Button>
                <DownloadButton
                    path="filtered-trips"
                    filename={`${slug}_filtered_trips_${dateRange[0]}-${dateRange[1]}.zip`}
                    icon="Download"
                    params={{
                        mapview_slug: slug,
                        start_date: dateRange[0].toString(),
                        end_date: dateRange[1].toString()
                    }}
                    failureReason="download-failure-time-range"
                >
                    <FormattedMessage
                        key="download-button"
                        defaultMessage="Export"
                    />
                </DownloadButton>
            </div>
        </>
    );
}

const TABLE = "rx-page-health-page-trip-filter-2"/*
    @include table;
    border-collapse: separate;
    tbody tr > * {
        height: 72rem;
        vertical-align: center;
        border-color: transparent;
        border-style: solid;
        border-width: 0;
    }
    tbody tr > *:first-child {
        @include text-body-bold;

        svg {
            font-size: 24rem;
            margin-right: 16rem;
        }
    }
    td:last-child {
        box-sizing: content-box;
        width: 164rem;
    }
    tr.sub-row td:first-child {
        text-align: right;
    }
    tbody {
        tr > *:first-child {
            border-left-width: 1rem;
        }
        tr > *:last-child {
            border-right-width: 1rem;
        }
        tr:first-child {
            > * {
                border-top-width: 1rem;
                border-bottom-width: 1rem;
            }
            > *:first-child {
                padding-left: 12rem;
                border-left-width: 1rem;
                border-top-left-radius: 8rem;
            }
            > *:last-child {
                border-right-width: 1rem;
                border-top-right-radius: 8rem;
            }
        }
        tr:last-child {
            > * {
                border-bottom-width: 1rem;
            }
            > *:first-child {
                border-left-width: 1rem;
                border-bottom-left-radius: 8rem;
            }
            > *:last-child {
                border-right-width: 1rem;
                border-bottom-right-radius: 8rem;
            }
        }
    }
    tbody.expanded tr > * {
        border-color: $gray-30;
    }
*/;
const HEADER = "rx-page-health-page-trip-filter-3"/*
    width: 100%;
    display: flex;
    flex-flow:  row nowrap;
    justify-content: space-between;
    align-items: center;
*/;
const ACTIONS = "rx-page-health-page-trip-filter-4"/*
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    > * {
        margin-left: 8rem;
    }
    margin-left: -8rem;
*/;

const GET_TRIP_FILTERS_WITH_EXCLUDED_PERCENT = gql`
    query GetTripFiltersWithExcludedPercent($slug: String!) {
        mapView(slug: $slug) {
            id
            tripFilters {
                ...TripFilterWithExcludedPercent
            }
        }
    }
    ${TRIP_FILTER_WITH_EXCLUDED_FRAGMENT}
`;

export function useTripFilterEditPermission() {
    const { orgAuth, useDemoFeatures } = useUser();
    const { organization, role } = orgAuth;
    return (
        role === 'Administrator' &&
        organization.__typename === 'AgencyOrganization' &&
        useDemoFeatures
    );
}

function useTripFilters(): TripFilterRowProps[] | null {
    const { slug } = useMapView();

    const data = useData<GetTripFiltersData, GetTripFiltersArgs>(
        GET_TRIP_FILTERS,
        { slug }
    );
    const [dataWithExcluded, , errors] = useGraphql<
        GetTripFiltersWithExcludedPercentData,
        GetTripFiltersWithExcludedPercentArgs
    >(GET_TRIP_FILTERS_WITH_EXCLUDED_PERCENT, { slug });
    return (
        dataWithExcluded?.mapView.tripFilters ??
        data?.mapView.tripFilters.map(filter => ({
            ...filter,
            excludedPercent: errors ?? ('loading' as const),
            excludedPercentByOperator: null
        })) ??
        null
    );
}

function PageTripFilter() {
    const tripFilters = useTripFilters();
    const location = useLocation<undefined | 'created'>();
    const history = useHistory();
    const user = useUser();
    const noFilters = tripFilters && tripFilters.length === 0;
    const vehicleClasses = useVehicleClasses();
    const [showDropdown, setShowDropdown] = useState(false);
    const canEdit = useTripFilterEditPermission();

    return (
        <CommonPage>
            {location.state === 'created' && (
                <Callout
                    color="green"
                    dismiss={() =>
                        history.replace({ ...location, state: undefined })
                    }
                >
                    <FormattedMessage
                        key="success-callout"
                        defaultMessage="<strong>New trip filter created.</strong> It will be used for newly-generated reports and invoices moving forward. To apply this filter to historical reports, contact {staffEmail}."
                        values={{
                            strong: msg => <strong>{msg}</strong>,
                            staffEmail: (
                                <a
                                    className={LINK}
                                    href="mailto:support@ridereport.com"
                                >
                                    support@ridereport.com
                                </a>
                            )
                        }}
                    />
                </Callout>
            )}
            {noFilters ? (
                user.orgAuth.organization.__typename ===
                'OperatorOrganization' ? (
                    <Callout color="blue">
                        <FormattedMessage
                            key="no-filters-operator"
                            defaultMessage="No trip filters have been set up for this dashboard."
                        />
                    </Callout>
                ) : canEdit ? (
                    <>
                        <Callout color="blue">
                            <FormattedMessage
                                key="no-filters-admin"
                                defaultMessage="You don't have any trip filters set up yet. You can use our recommended filters (prefilled below) or customize them to fit your your needs."
                            />
                        </Callout>
                        {vehicleClasses && (
                            <TripFilterForm
                                vehicleClasses={vehicleClasses}
                                existingFilters={[]}
                            />
                        )}
                    </>
                ) : (
                    <Callout color="blue">
                        <FormattedMessage
                            key="no-filters"
                            defaultMessage="You don't have any trip filters configured. <Mail>Contact Ride Report</Mail> to begin filtering your trip data."
                            values={{
                                Mail: msg => (
                                    <a
                                        className={LINK}
                                        href={`mailto:${supportEmail}`}
                                    >
                                        {msg}
                                    </a>
                                )
                            }}
                        />
                    </Callout>
                )
            ) : (
                <Container>
                    <ContainerHeader el="h2">
                        <div className={HEADER}>
                            <FormattedMessage
                                key="header"
                                defaultMessage="Trip Filter Configuration"
                            />
                            <div className={ACTIONS}>
                                {canEdit && (
                                    <Link to="/new" className={LINK}>
                                        <FormattedMessage
                                            key="edit-link"
                                            defaultMessage="Edit your filters"
                                        />
                                    </Link>
                                )}
                                <Dropdown
                                    active={showDropdown}
                                    setActive={setShowDropdown}
                                    buttonProps={{
                                        color: 'blue',
                                        icon: 'Spreadsheet',
                                        children: (
                                            <FormattedMessage
                                                key="export-button"
                                                defaultMessage="Export"
                                            />
                                        )
                                    }}
                                    position="bottom-right"
                                >
                                    <Export
                                        onClose={() => setShowDropdown(false)}
                                    />
                                </Dropdown>
                            </div>
                        </div>
                    </ContainerHeader>
                    <Loading kind="over-table" loading={!tripFilters} />
                    <table className={TABLE}>
                        <thead>
                            <tr>
                                <th>
                                    <FormattedMessage
                                        key="vehicle-type-table-header"
                                        defaultMessage="Vehicle Type"
                                    />
                                </th>
                                <th>
                                    <FormattedMessage
                                        key="distance-table-header"
                                        defaultMessage="Distance"
                                    />
                                </th>
                                <th>
                                    <FormattedMessage
                                        key="duration-table-header"
                                        defaultMessage="Duration"
                                    />
                                </th>
                                <th>
                                    <FormattedMessage
                                        key="included-percent-table-header"
                                        defaultMessage="Included Percent"
                                    />
                                </th>
                            </tr>
                        </thead>
                        {tripFilters?.map(filter => (
                            <TripFilterRow
                                key={filter.vehicleClass}
                                {...filter}
                            />
                        ))}
                    </table>
                </Container>
            )}
        </CommonPage>
    );
}

export default PageTripFilter;
