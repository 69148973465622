import * as React from 'react';
import cx from 'classnames';
import "/opt/build/repo/src/page-explore/common/heatmap-key.tsx?resplendence=true";

/*
@import 'style';
*/;

const HEATMAP_KEY_SCALE = "rx-page-explore-common-heatmap-key-1"/*
    display: grid;
    grid-template-rows: 8rem auto;
	align-items: stretch;
*/;

const HEATMAP_KEY_SCALE_COLORS = "rx-page-explore-common-heatmap-key-2"/*
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 40rem;
*/;

const HEATMAP_KEY_SCALE_NUMBERS = "rx-page-explore-common-heatmap-key-3"/*
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 40rem;
    height: 18rem;
    justify-items: center;
    align-items: center;
    @include text-caption-bold;
*/;

const HEATMAP_KEY_SCALE_COLOR = "rx-page-explore-common-heatmap-key-4"/*
    border: 1rem solid fade-out($gray-90, 0.9);
    border-width: 1rem 0;
    &:first-child {
        border-top-left-radius: 32rem;
        border-bottom-left-radius: 32rem;
        border-left-width: 1rem;
    }
    &:last-child {
        border-top-right-radius: 32rem;
        border-bottom-right-radius: 32rem;
        border-right-width: 1rem;
    }
*/;

const HEATMAP_KEY = "rx-page-explore-common-heatmap-key-5"/*
    position: absolute;
    left: 16rem;
    bottom: 40rem;
    color: $gray-90;
    @include container;
    padding: 12rem 16rem 8rem 16rem;
    @include shadow-8;
	display: flex;
	flex-flow: column nowrap;
	align-items: flex-end;
    font-size: 10rem;
    line-height: normal;
    pointer-events: none;
*/;
const HEATMAP_KEY_INFO = "rx-page-explore-common-heatmap-key-6"/*
    @include text-caption-bold;
*/;

type Props = {
    scale: { value: React.ReactNode; color: string }[];
    children?: React.ReactNode;
};

function HeatmapKey({ scale, children }: Props) {
    return (
        <div className={cx(HEATMAP_KEY)}>
            <div className={HEATMAP_KEY_SCALE}>
                <div className={HEATMAP_KEY_SCALE_COLORS}>
                    {scale.map(({ color }) => (
                        <div
                            key={color}
                            className={HEATMAP_KEY_SCALE_COLOR}
                            style={{ background: color }}
                        />
                    ))}
                </div>
                <div className={HEATMAP_KEY_SCALE_NUMBERS}>
                    {scale.map(({ value }, i) => (
                        <div key={i}>{value}</div>
                    ))}
                </div>
            </div>
            {children && <div className={HEATMAP_KEY_INFO}>{children}</div>}
        </div>
    );
}

export default HeatmapKey;
