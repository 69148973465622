import React, { useMemo, useState } from 'react';
import { OverviewReport } from './report';
import {
    TableSection,
    Table,
    HeaderRow,
    Row,
    NoAreasInReport,
    LoadingTable,
    CONSTRAINED_TABLE,
    messages,
    HeaderProps
} from './tables';
import { useIntl } from 'utils/use-intl';
import { defineMessages, FormattedMessage } from 'react-intl';
import PageDailySummaryVehicles from './page-daily-summary-vehicles';
import PageDailySummaryTrips from './page-daily-summary-trips';
import { LocalDate } from 'utils/date-tools';
import { useMapView } from 'common/use-map-view';
import gql from 'graphql-tag';
import { OperatorName } from 'constants/operators';
import Loading from 'common/loading';
import useData, { useReloadingData } from 'utils/use-data';
import statDescriptions from 'constants/stat-descriptions';
import { sortByAreas } from 'utils/helpers';
import {
    AoiInfoFragment,
    MdsAoiInfo,
    MdsAoiTripCounts,
    UNFINALIZED_OPERATOR_FRAGMENT
} from './fragments';
import {
    DailyOverviewAreasData,
    DailyOverviewAreasArgs,
    DailyFinalizedReportsArgs,
    DailyFinalizedReportsData,
    UnfinalizedOperatorFragment
} from 'graphql.g';
import { useVehicleClass } from 'common/vehicle-classes';
import useDocumentTitle from 'common/use-document-title';
import useSearchFilter from 'utils/search';
import TableSearchInput from 'common/table-search-input';
import orderBy from 'lodash/orderBy';
import zipWith from 'lodash/zipWith';
import { useSorting } from 'common/table-sorting';

type Props = {
    reportDate: LocalDate;
};

const m = defineMessages({
    'vehicle-header': 'Vehicle Metrics for {day, date, dayOfWeekReportDay}',
    'trips-header': 'Trips Metrics for {day, date, dayOfWeekReportDay}',
    'document-title': '{day, date, reportday} · Ride Report'
});

type Report = {
    startTime: string;
    endDate: string;
    endTime: string;
    aoiMdsMetrics: (MdsAoiInfo & MdsAoiTripCounts)[];
};

export type ReportOperator = {
    operatorId: string;
    name: OperatorName;
    report: Report | null;
};

// -------- AREAS ----------------

const AREAS_QUERY = gql`
    query DailyOverviewAreas(
        $slug: String!
        $date: Date!
        $vehicleClass: VehicleClass
    ) {
        mapView(slug: $slug) {
            id
            overviewReport(date: $date, vehicleClass: $vehicleClass) {
                id
                reportDate
                aoiMdsTotalsMetric {
                    area {
                        ...AoiInfo
                        operatorIds
                    }
                    tripStartCount
                    tripEndCount
                    morningDeploymentAvailableCount
                    tripsPerVehicle
                }
            }
        }
    }
    ${AoiInfoFragment}
`;

const headerInfo = [
    statDescriptions.startTripsArea,
    statDescriptions.endTripsAreas,
    statDescriptions.mdsMorningDeployed,
    statDescriptions.tripsPerVehicle
];
const tripSortKeys = [
    'tripStartCount',
    'tripEndCount',
    'morningDeploymentAvailableCount',
    'tripsPerVehicle'
];
const headers: HeaderProps[] = zipWith(headerInfo, tripSortKeys, (a, b) => {
    return [a, b];
});

type AreasProps = {
    reportDate: LocalDate;
};

function PageDailySummaryAreas({ reportDate }: AreasProps) {
    const vehicleClass = useVehicleClass();
    const mapView = useMapView();
    const [data, loading] = useReloadingData<
        DailyOverviewAreasData,
        DailyOverviewAreasArgs
    >(AREAS_QUERY, {
        slug: mapView.slug,
        date: reportDate.toString(),
        vehicleClass
    });
    const intl = useIntl();
    const [sorting, onSortHeaderClick] = useSorting<
        | 'name'
        | 'tripStartCount'
        | 'tripEndCount'
        | 'morningDeploymentAvailableCount'
        | 'tripsPerVehicle'
    >('name');
    const sortedAreaMetrics = useMemo(() => {
        const areaMetrics = data?.mapView.overviewReport?.aoiMdsTotalsMetric?.filter(
            area => area.area.operatorIds.length === 0
        );
        return orderBy(
            areaMetrics ? sortByAreas(areaMetrics, a => a.area) : [],
            [...sorting.keys()],
            [...sorting.values()]
        );
    }, [sorting, data?.mapView.overviewReport?.aoiMdsTotalsMetric]);
    const [filter, setFilter] = useState('');
    const filteredAreas = useSearchFilter(
        sortedAreaMetrics,
        item => item.area.name,
        filter
    );

    return (
        <>
            <Loading loading={loading} kind="over-table" />
            <TableSearchInput
                value={filter}
                onChange={setFilter}
                placeholder={intl.formatMessage(messages['search-areas'])}
            />
            <div className={CONSTRAINED_TABLE}>
                {data?.mapView.overviewReport?.aoiMdsTotalsMetric ? (
                    sortedAreaMetrics.length ? (
                        <Table>
                            <HeaderRow
                                wideLeftLabels
                                headers={headers}
                                sorting={sorting}
                                onSortHeaderClick={onSortHeaderClick}
                            />
                            <tbody>
                                {filteredAreas.map(aoi => (
                                    <Row
                                        key={aoi.area.id}
                                        name={aoi.area.name}
                                        icon={
                                            aoi.area.isMunicipalityBoundary
                                                ? 'MunBoundary'
                                                : undefined
                                        }
                                        values={[
                                            intl.formatNumber(
                                                aoi.tripStartCount
                                            ),
                                            intl.formatNumber(aoi.tripEndCount),
                                            aoi.morningDeploymentAvailableCount
                                                ? intl.formatNumber(
                                                      aoi.morningDeploymentAvailableCount
                                                  )
                                                : null,
                                            aoi.tripsPerVehicle
                                                ? intl.formatNumber(
                                                      aoi.tripsPerVehicle
                                                  )
                                                : null
                                        ]}
                                    />
                                ))}
                            </tbody>
                        </Table>
                    ) : (
                        <NoAreasInReport />
                    )
                ) : (
                    <LoadingTable headers={headers} />
                )}
            </div>
        </>
    );
}

// -------- OVERVIEW -------------

const DAILY_SUMMARY_IS_FINALIZED_QUERY = gql`
    query DailyFinalizedReports($slug: String!, $date: Date!) {
        mapView(slug: $slug) {
            id
            overviewReport(date: $date) {
                id
                reports {
                    id
                    finalized
                    operator {
                        ...UnfinalizedOperator
                    }
                }
            }
        }
    }
    ${UNFINALIZED_OPERATOR_FRAGMENT}
`;

export function useUnfinalizedOperators(reportDate: LocalDate) {
    const { slug } = useMapView();
    const data = useData<DailyFinalizedReportsData, DailyFinalizedReportsArgs>(
        DAILY_SUMMARY_IS_FINALIZED_QUERY,
        {
            slug,
            date: reportDate.toString()
        }
    );
    return (
        (data?.mapView.overviewReport?.reports ?? [])
            .filter(report => report?.finalized === false)
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            .map(report => report!.operator as UnfinalizedOperatorFragment)
    );
}

function PageDailySummary({ reportDate }: Props) {
    const mapView = useMapView();
    const intl = useIntl();

    const unfinalizedOperators = useUnfinalizedOperators(reportDate);

    const title = intl.formatMessage(m[`document-title`], {
        day: reportDate?.toDate()
    });
    useDocumentTitle(title);

    return (
        <OverviewReport
            reportDate={reportDate}
            unfinalizedOperators={unfinalizedOperators}
        >
            <TableSection
                title={intl.formatMessage(m['vehicle-header'], {
                    day: reportDate.toDate()
                })}
            >
                <PageDailySummaryVehicles
                    mapView={mapView}
                    reportDate={reportDate}
                />
            </TableSection>
            <TableSection
                title={intl.formatMessage(m['trips-header'], {
                    day: reportDate.toDate()
                })}
            >
                <PageDailySummaryTrips
                    mapView={mapView}
                    reportDate={reportDate}
                />
            </TableSection>
            <TableSection
                title={
                    <FormattedMessage
                        key="areas-header"
                        defaultMessage="Area of Interest Metrics for {day, date, reportday}"
                        values={{ day: reportDate.toDate() }}
                    />
                }
            >
                <PageDailySummaryAreas reportDate={reportDate} />
            </TableSection>
        </OverviewReport>
    );
}

export default PageDailySummary;
