import React, { FormEvent, useReducer, useState } from 'react';
import cx from 'classnames';
import "/opt/build/repo/src/page-custom-data/page-upload.tsx?resplendence=true";
import {
    Button,
    Container,
    DetailsContainer,
    PageBody,
    PageContent,
    TextField
} from 'common/layout';
import { Title } from 'common/title';
import { FormattedMessage } from 'react-intl';
import { useRef } from 'react';
import Papa from 'papaparse';
import { useHistory, useLocation } from 'react-router';
import { useMapView } from 'common/use-map-view';
import Loading from 'common/loading';
import { useEffect } from 'react';
import Icon from 'common/icon';
import PreviewCSV from './preview-csv';
import DATA_COLORS from 'constants/data-colors.json';
import { batchConvertGeocodeToFeatures, postCustomDataset } from './api';
import { useAuthInfo } from 'common/authentication';
import ColorPicker from './color-picker';
import { sendEventAnalytics } from 'utils/use-analytics';
import { Select } from 'common/select';
import { customColumnParsers } from './custom-column-parsers';
import { MultiValue } from 'react-select';

const BYTES_IN_MEGABYTE = 1000000;
const MAX_FILE_SIZE_IN_MEGABYTES = 20;
const MAX_NUMBER_OF_ROWS = 20000;
const MAX_NUMBER_OF_ROWS_ADDRESS_UPLOAD = 1000; // 1% of mapbox free tier limit
const MAX_NUMBER_OF_COLUMNS = 400; // Allow for ridiculous JIRA exports

/*
@import 'style';
*/;

const LINK = "rx-page-custom-data-page-upload-1"/*
    @include text-body-link-underlined;
    color: $blue-50;
*/;

const PREVIEW_CONTAINER = "rx-page-custom-data-page-upload-2"/*
    overflow-x: auto;
    max-width: 924rem;

    @include mobile {
        max-width: calc(100vw - 190rem);
    }
    @include mobile-tiny {
        max-width: 90vw;
    }
*/;

const FORM_ITEM = "rx-page-custom-data-page-upload-3"/*
    @include text-label;
    color: $gray-70;
    margin-bottom: 16rem;
*/;

const ERROR_CONTAINER = "rx-page-custom-data-page-upload-4"/*
    margin-bottom: 24rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 16rem;
*/;

const UPLOAD_AGAIN = "rx-page-custom-data-page-upload-5"/*
    margin-top: 16rem;
    margin-bottom: 16rem;
*/;

const SIZE_INFO = "rx-page-custom-data-page-upload-6"/*
    ul > li {
        list-style: disc;
        margin-left: 24rem;
    }
*/;

const HELP_TEXT = "rx-page-custom-data-page-upload-7"/*
    margin-top: 32rem;
    margin-bottom: 24rem;
*/;

const DATASET_TITLE_CONTAINER = "rx-page-custom-data-page-upload-8"/*
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
*/;

const CIRCLE_CHECK = "rx-page-custom-data-page-upload-9"/*
    width: 36rem;
    height: 36rem;
    border-radius: 50%;
    padding: 4rem;
    margin-right: 16rem;
    background-color: $green-50;
    color: $white;

    &.red {
        background-color: $red-50;
    }
*/;

const SAVE_ERROR = "rx-page-custom-data-page-upload-10"/*
    margin-top: 24rem;
    color: $red-70;
*/;

export interface ParsedDataset {
    rows: string[][];
    headers: string[];
}

/** Validates that a given coordinate pair is a valid WSG84 decimal degrees
 * coordinate. https://en.wikipedia.org/wiki/Decimal_degrees
 * A valid coordinate is within (-90,90) for latitude and (-180,180) for longitude
 */
function isValidWSG84Coord(lat: string, lng: string) {
    // Number('') or Number('  ') will return 0, but this is not actually a number!
    if (lat.trim() === '' || lng.trim() === '') {
        return false;
    }

    const latNum = Number(lat);
    const lngNum = Number(lng);
    if (isNaN(latNum) || isNaN(lngNum)) {
        return false;
    }

    return latNum > -90 && latNum < 90 && lngNum > -180 && lngNum < 180;
}

/** Takes only the first part of a file name and replaces all non alphanumeric
 * characters with spaces.
 */
function getHumanReadableFilename(filename: string) {
    return filename.split('.')[0].replace(/[\W_]+/g, ' ');
}

/** Formats a number to display, using the users default locale. In the US this
 * will produce a string like "1,345,912" for the number 1345912
 */
function getDisplayNumber(num: number) {
    return num.toLocaleString(undefined, {
        maximumFractionDigits: 0
    });
}

interface NullableDatasetIdentifiers {
    lat: number | null;
    lng: number | null;
    addressColumn: number | null;
}

function validateIdentifiers(ids: NullableDatasetIdentifiers) {
    return (
        (ids.lat != null && ids.lng != null && ids.lat !== ids.lng) ||
        ids.addressColumn != null
    );
}

/** Configures Papa Parse as a promise that returns a parsed file */
function parseFile(file: File): Promise<Papa.ParseResult<unknown>> {
    return new Promise((resolve, reject) => {
        Papa.parse(file, {
            skipEmptyLines: true,
            worker: true,
            complete: results => {
                resolve(results);
            },
            error: error => {
                reject(error);
            }
        });
    });
}

/** FILE VALIDATIONS */

type SuccessfulValidationResult = {
    result: true;
    action: null;
};

type FileValidationResult =
    | SuccessfulValidationResult
    | {
          result: false;
          action: Actions;
      };

const successfulValidationResult: SuccessfulValidationResult = {
    result: true,
    action: null
};

/** File Attributes (pre parse) */

/** Check if the file is too large to upload */
function validateFileSize(file: File): FileValidationResult {
    const fileSizeInMB = file.size / BYTES_IN_MEGABYTE;
    if (fileSizeInMB > MAX_FILE_SIZE_IN_MEGABYTES) {
        const humanSize = fileSizeInMB.toFixed(0);
        return {
            result: false,
            action: {
                type: 'PARSE_ERROR',
                payload: {
                    kind: 'size',
                    msg: `The uploaded file is too large (${humanSize}mb). Please edit your data so that it's less than ${MAX_FILE_SIZE_IN_MEGABYTES}mb and upload it again.`
                }
            }
        };
    }
    return successfulValidationResult;
}

/** Data Validations (post parse) */

/**
 * If it's not too large, we'll parse the data. We will check for other
 * types of errors after we've finished parsing.
 * check if there's too many rows
 */
function validateRowCount(
    results: Papa.ParseResult<unknown>
): FileValidationResult {
    const rowCount = results.data.length;
    if (rowCount > MAX_NUMBER_OF_ROWS) {
        const displayRowCount = getDisplayNumber(rowCount);
        const displayMaxRow = getDisplayNumber(MAX_NUMBER_OF_ROWS);
        return {
            result: false,
            action: {
                type: 'PARSE_ERROR',
                payload: {
                    kind: 'size',
                    msg: `The uploaded file has ${displayRowCount} rows which is over the ${displayMaxRow} row limit.`
                }
            }
        };
    }
    return successfulValidationResult;
}

/** Check if there are too many columns */
function validateColumnCount(
    results: Papa.ParseResult<unknown>
): FileValidationResult {
    const colCount = (results.data[0] as string[]).length;
    if (colCount > MAX_NUMBER_OF_COLUMNS) {
        const displayColCount = getDisplayNumber(colCount);
        const displayMaxCol = getDisplayNumber(MAX_NUMBER_OF_COLUMNS);
        return {
            result: false,
            action: {
                type: 'PARSE_ERROR',
                payload: {
                    kind: 'size',
                    msg: `The uploaded file has ${displayColCount} columns which is over the ${displayMaxCol} column limit.`
                }
            }
        };
    }
    return successfulValidationResult;
}

/** Check for errors in the data itself that got returned from papaparse */
function validateParseErrors(
    results: Papa.ParseResult<unknown>
): FileValidationResult {
    if (results.errors.length > 0) {
        const msg = results.errors[0].message;
        const row = results.errors[0].row;
        const displayRow = row ? ` on ${getDisplayNumber(row)}` : '';
        return {
            result: false,
            action: {
                type: 'PARSE_ERROR',
                payload: {
                    kind: 'parse',
                    msg: `There was an error parsing the dataset. ${msg}${displayRow}.`
                }
            }
        };
    }
    return successfulValidationResult;
}

function validateCoordinates(
    dataset: ParsedDataset,
    latCol: number,
    lngCol: number
): FileValidationResult {
    let errorMsg = '';
    const hasInvalidCoordinate = dataset.rows.some((row, i) => {
        // if it's an empty row, then consider it valid for now. We'll remove
        // these right before saving so that if there's an error, we still
        // have the correct row number to show the user
        if (row.every(cell => cell.trim() === '')) {
            return false;
        }

        const isValid = isValidWSG84Coord(row[latCol], row[lngCol]);
        if (!isValid) {
            // We add 2 to our row index to account for:
            //  - users expecting rows to be 1-based not 0-based
            //  - the header in a CSV takes up the first row
            const rowNum = i + 2;
            errorMsg = `Invalid coordinate (${row[latCol]}, ${row[lngCol]}) on row ${rowNum}. Coordinates must be in the WSG84 decimal degree format. If you provided an address column instead of lat/long, there is an issue converting the address on this row to coordinates. Please check the address on row ${rowNum} in your file and try again.`;
            return true;
        }
        return false;
    });

    return hasInvalidCoordinate
        ? {
              result: false,
              action: {
                  type: 'PARSE_ERROR',
                  payload: {
                      kind: 'validation',
                      msg: errorMsg
                  }
              }
          }
        : successfulValidationResult;
}

interface State {
    dataset: ParsedDataset | null;
    datasetTitle: string;
    error: { kind: 'parse' | 'size' | 'validation'; msg: string } | null;
    filename: string;
    identifiers: NullableDatasetIdentifiers;
    isLoading: boolean;
    isSaving: boolean;
    originalFile: File | null;
    locationInputType: 'coordinates' | 'address' | null;
    parsingFunction?: (address: string) => string;
    additionalColumns?: MultiValue<{ value: number; label: string }>;
}

const initialState: Readonly<State> = Object.freeze({
    dataset: null,
    datasetTitle: '',
    error: null,
    filename: '',
    identifiers: { lat: null, lng: null, addressColumn: null },
    isLoading: false,
    isSaving: false,
    originalFile: null,
    locationInputType: null,
    addressColumn: null,
    parsingFunction: undefined,
    additionalColumns: []
});

type Actions =
    | {
          type: 'START_PROCESSING_FILE';
          payload: {
              filename: string;
              originalFile: File;
              title: string;
          };
      }
    | {
          type: 'FINISH_PROCESSING_FILE';
          payload: ParsedDataset;
      }
    | {
          type: 'PARSE_ERROR';
          payload: { kind: 'parse' | 'size' | 'validation'; msg: string };
      }
    | { type: 'SET_DATASET_TITLE'; payload: { title: string } }
    | {
          type: 'SET_DATASET_IDENTIFIERS';
          payload: { lat?: number; lng?: number; addressColumn?: number };
      }
    | { type: 'RESET_DATASET' }
    | { type: 'START_SAVING' }
    | { type: 'FINISH_SAVING' }
    | {
          type: 'SET_LOCATION_INPUT_TYPE';
          payload: { kind: 'coordinates' | 'address' };
      }
    | {
          type: 'SET_CUSTOM_PARSING';
          payload: { parsingFunction?: (address: string) => string };
      }
    | {
          type: 'SET_ADDITIONAL_COLUMNS';
          payload: {
              additionalColumns: MultiValue<{ value: number; label: string }>;
          };
      };

function reducer(state: Readonly<State>, action: Actions): State {
    switch (action.type) {
        case 'START_PROCESSING_FILE':
            return {
                ...state,
                isLoading: true,
                datasetTitle: action.payload.title,
                filename: action.payload.filename,
                originalFile: action.payload.originalFile
            };
        case 'FINISH_PROCESSING_FILE':
            return {
                ...state,
                isLoading: false,
                dataset: {
                    ...state.dataset,
                    rows: action.payload.rows,
                    headers: action.payload.headers
                }
            };
        case 'PARSE_ERROR':
            return {
                ...state,
                isLoading: false,
                isSaving: false,
                error: {
                    ...state.error,
                    kind: action.payload.kind,
                    msg: action.payload.msg
                }
            };
        case 'SET_DATASET_TITLE': {
            return {
                ...state,
                datasetTitle: action.payload.title
            };
        }
        case 'SET_DATASET_IDENTIFIERS': {
            return {
                ...state,
                identifiers: {
                    ...state.identifiers,
                    lat: action.payload.lat ?? state.identifiers.lat,
                    lng: action.payload.lng ?? state.identifiers.lng,
                    addressColumn: action.payload.addressColumn ?? null
                }
            };
        }
        case 'RESET_DATASET': {
            return {
                ...state,
                dataset: null,
                datasetTitle: '',
                error: null,
                originalFile: null
            };
        }
        case 'START_SAVING':
            return {
                ...state,
                isSaving: true
            };
        case 'FINISH_SAVING':
            return { ...initialState };
        case 'SET_LOCATION_INPUT_TYPE':
            return {
                ...state,
                locationInputType: action.payload.kind
            };
        case 'SET_CUSTOM_PARSING':
            return {
                ...state,
                parsingFunction: action.payload.parsingFunction
            };
        case 'SET_ADDITIONAL_COLUMNS':
            return {
                ...state,
                additionalColumns: action.payload.additionalColumns
            };
        default:
            return state;
    }
}

function UploadPage() {
    // the dataset that gets stored in this reducer is temporary while we upload
    // it. After the data is uploaded, it's stored in the custom data context
    const [state, dispatch] = useReducer(reducer, initialState);
    const authInfo = useAuthInfo();

    const locationState = useLocation<{ file: File } | undefined>().state;
    const fileInputRef = useRef<HTMLInputElement>(null);
    const history = useHistory();
    const mapView = useMapView();

    const colorOptions = DATA_COLORS.map(color => color.background);
    const initialColor = colorOptions[0];
    const [color, setColor] = useState(initialColor);

    useEffect(() => {
        if (locationState?.file) {
            processDataset(locationState.file);
        }
    }, [locationState]);

    const processDataset = async (file: File) => {
        dispatch({
            type: 'START_PROCESSING_FILE',
            payload: {
                originalFile: file,
                filename: file.name,
                title: getHumanReadableFilename(file.name)
            }
        });

        // File Validations
        for (const fileValidationFunction of [validateFileSize]) {
            const { result, action } = fileValidationFunction(file);
            if (!result && action) {
                dispatch(action);
                return;
            }
        }

        const results = await parseFile(file);

        // Data Validations
        for (const dataValidationFunction of [
            validateRowCount,
            validateColumnCount,
            validateParseErrors
        ]) {
            const { result, action } = dataValidationFunction(results);
            if (!result && action) {
                dispatch(action);
                return;
            }
        }

        dispatch({
            type: 'FINISH_PROCESSING_FILE',
            payload: {
                // take the first row as headers
                headers: results.data[0] as string[],
                // and the rest of the rows as the data
                rows: results.data.slice(1) as string[][]
            }
        });
    };

    const onFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files ? event.target.files[0] : null;
        if (file == null) {
            dispatch({ type: 'RESET_DATASET' });
            return;
        }

        processDataset(file);
    };

    const createFileFromVagueAddressColumn = async (
        additionalColumns?: MultiValue<{ value: number; label: string }>,
        originalDataset?: ParsedDataset | null
    ): Promise<{
        newFile: any | Error;
    }> => {
        const { addressColumn } = state.identifiers;
        if (addressColumn == null) {
            dispatch({
                type: 'PARSE_ERROR',
                payload: {
                    kind: 'validation',
                    msg: 'A column containing addresses must be selected.'
                }
            });
            throw new Error('A column containing addresses must be selected.');
        }

        // Create address rows and parse them specially if needed
        let addresses = state.dataset?.rows.map(row =>
            row[addressColumn].trim()
        );
        const { parsingFunction } = state;
        if (parsingFunction)
            addresses = addresses?.map(address => parsingFunction(address));

        try {
            const features = await batchConvertGeocodeToFeatures(
                addresses,
                mapView.maxBounds // Get bounding box from map view to limit geocoding results
            );

            // Create new csv file and append selected data from original file
            const newRows = features.map((feature, i) => {
                const { latitude, longitude } = feature.properties.coordinates;
                const parsedAddress = feature.properties?.name;
                // append data from original file
                const row = originalDataset?.rows[i];
                const existingData = {};
                if (additionalColumns?.length) {
                    for (const { value, label } of additionalColumns) {
                        existingData[label.trim()] = row?.[value].trim();
                    }
                }
                return {
                    'Parsed Address': parsedAddress,
                    latitude,
                    longitude,
                    ...existingData
                };
            });

            const newCSVString = Papa.unparse(newRows);
            const newFile = new File([newCSVString], 'newFile.csv', {
                type: 'text/csv'
            });

            return { newFile };
        } catch (error) {
            const message =
                'There was an error geocoding your addresses. Please make sure you have the right column selected. If so, please contact us as this may be a temporary API issue.';
            dispatch({
                type: 'PARSE_ERROR',
                payload: {
                    kind: 'parse',
                    msg: message
                }
            });
            throw new Error(message);
        }
    };

    const handleSubmit = async (event: FormEvent<HTMLButtonElement>) => {
        event.preventDefault();
        dispatch({
            type: 'START_SAVING'
        });

        // vars set by default to upload long/lat
        let targetFile = state.originalFile;
        let latCol = state.identifiers.lat;
        let lngCol = state.identifiers.lng;
        let targetDataset = state.dataset;

        // set vars and make new file if we're uploading addresses
        if (state.locationInputType === 'address') {
            const { newFile } = await createFileFromVagueAddressColumn(
                state.additionalColumns,
                state.dataset
            );
            const parsedNewFileRecord = await parseFile(newFile);
            const { data: parsedNewFileData } = parsedNewFileRecord;

            targetDataset = {
                headers: parsedNewFileData[0] as string[],
                rows: parsedNewFileData.slice(1) as string[][]
            };
            latCol = 1;
            lngCol = 2;
            targetFile = newFile;
        }

        // double check we have all of the data we need, if not send them back
        // to the form.
        if (
            targetDataset == null ||
            targetFile == null ||
            latCol == null ||
            lngCol == null
        ) {
            dispatch({
                type: 'PARSE_ERROR',
                payload: {
                    kind: 'validation',
                    msg:
                        'Missing data, please complete the form or try uploading the file again.'
                }
            });
            return;
        }

        for (const rowValidationFunction of [validateCoordinates]) {
            const { result, action } = rowValidationFunction(
                targetDataset,
                latCol,
                lngCol
            );
            if (!result && action) {
                dispatch(action);
                return;
            }
        }

        // save to backend
        await postCustomDataset(authInfo.token, {
            title: state.datasetTitle,
            hexColor: color,
            mapviewId: mapView.id,
            latCol,
            lngCol,
            file: targetFile
        });

        sendEventAnalytics({
            name: state.datasetTitle,
            event_name: 'custom dataset event',
            action: 'created',
            file_size: targetFile.size,
            number_of_rows: targetDataset.rows.length,
            number_of_columns: targetDataset.headers.length
        });

        dispatch({
            type: 'FINISH_SAVING'
        });
        history.push(`/${mapView.slug}/custom-data`, 'uploaded');
    };

    const formDisabled = state.dataset == null || state.isLoading;

    return (
        <PageContent>
            <Title
                title={
                    <FormattedMessage
                        key="title"
                        defaultMessage="Upload Data"
                    />
                }
            />
            <PageBody>
                {state.dataset == null && state.isLoading === false ? (
                    <>
                        <Container>
                            <div className={DATASET_TITLE_CONTAINER}>
                                <Icon
                                    icon="Close"
                                    className={cx(CIRCLE_CHECK, 'red')}
                                />
                                <h1>{state.filename}</h1>
                            </div>
                        </Container>
                        <Container>
                            <>
                                {state.error &&
                                    (state.error.kind === 'size' ? (
                                        <div className={ERROR_CONTAINER}>
                                            <p>{state.error.msg}</p>
                                            <div className={SIZE_INFO}>
                                                <FormattedMessage
                                                    key="error-help-info-header"
                                                    defaultMessage="Custom Datasets must:"
                                                />
                                                <ul>
                                                    <li>
                                                        <FormattedMessage
                                                            key="error-help-info-size"
                                                            defaultMessage="be less than a 20mb file size"
                                                        />
                                                    </li>
                                                    <li>
                                                        <FormattedMessage
                                                            key="error-help-info-columns"
                                                            defaultMessage="have a maximum of 20 columns"
                                                        />
                                                    </li>
                                                    <li>
                                                        <FormattedMessage
                                                            key="error-help-info-rows"
                                                            defaultMessage="have a maximum of 20,000 rows"
                                                        />
                                                    </li>
                                                    <li>
                                                        <FormattedMessage
                                                            key="error-help-info-coordinates"
                                                            defaultMessage="have a latitude and longitude column containing valid WSG84 decimal degrees coordinates."
                                                        />
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    ) : (
                                        <>
                                            <p>{state.error.msg}</p>
                                            <p className={UPLOAD_AGAIN}>
                                                <FormattedMessage
                                                    key="error-help-info-check-file"
                                                    defaultMessage="Please check your file and upload again, or contact us at {email}."
                                                    values={{
                                                        email: (
                                                            <a
                                                                href="mailto:support@ridereport.com"
                                                                className={LINK}
                                                            >
                                                                support@ridereport.com
                                                            </a>
                                                        )
                                                    }}
                                                />
                                            </p>
                                        </>
                                    ))}

                                <input
                                    ref={fileInputRef}
                                    type="file"
                                    onChange={onFileUpload}
                                    accept="text/csv"
                                    style={{ display: 'none' }}
                                />

                                <div>
                                    <Button
                                        loading={state.isLoading}
                                        color="gray"
                                        onClick={() =>
                                            fileInputRef.current?.click()
                                        }
                                    >
                                        <FormattedMessage
                                            key="select-file"
                                            defaultMessage="Select File to Upload"
                                        />
                                    </Button>
                                </div>
                            </>
                        </Container>
                    </>
                ) : (
                    <Container>
                        <div className={DATASET_TITLE_CONTAINER}>
                            <Icon icon="Check" className={CIRCLE_CHECK} />
                            <h1>{state.filename}</h1>
                        </div>
                    </Container>
                )}

                {state.dataset != null && (
                    <DetailsContainer
                        open={false}
                        summary={
                            <>
                                <Loading loading={state.dataset == null} />
                                <FormattedMessage
                                    key="preview-title"
                                    defaultMessage="Preview Dataset"
                                />
                            </>
                        }
                    >
                        {state.dataset != null && (
                            <div className={PREVIEW_CONTAINER}>
                                <PreviewCSV
                                    headers={state.dataset.headers}
                                    rows={state.dataset.rows.slice(0, 3)}
                                />
                            </div>
                        )}
                    </DetailsContainer>
                )}

                {state.dataset != null && state.dataset.headers && (
                    <Container>
                        <TextField
                            disabled={formDisabled}
                            onChange={title =>
                                dispatch({
                                    type: 'SET_DATASET_TITLE',
                                    payload: { title }
                                })
                            }
                            value={state.datasetTitle}
                            id="dataset-name"
                            label={
                                <FormattedMessage
                                    key="dataset-name"
                                    defaultMessage="Dataset Name"
                                />
                            }
                        />

                        <p className={HELP_TEXT}>
                            <FormattedMessage
                                key="help-text"
                                defaultMessage="Please select which columns in the dataset to use for each field"
                            />
                        </p>

                        <label className={FORM_ITEM}>
                            <FormattedMessage
                                key="geoInput-type"
                                defaultMessage="Location Column Type"
                            />

                            <Select
                                isDisabled={formDisabled}
                                options={[
                                    {
                                        value: 0,
                                        key: 'coordinates',
                                        label:
                                            'My dataset has columns with longitude and latitude'
                                    },
                                    {
                                        value: 1,
                                        key: 'address',
                                        label: `My dataset has a column with an addresses ${
                                            state.dataset?.rows &&
                                            state.dataset.rows.length >
                                                MAX_NUMBER_OF_ROWS_ADDRESS_UPLOAD
                                                ? `(${MAX_NUMBER_OF_ROWS_ADDRESS_UPLOAD} row limit)`
                                                : ''
                                        }`,
                                        isDisabled:
                                            state.dataset?.rows &&
                                            state.dataset.rows.length >
                                                MAX_NUMBER_OF_ROWS_ADDRESS_UPLOAD
                                    }
                                ]}
                                onChange={option => {
                                    if (option?.key)
                                        dispatch({
                                            type: 'SET_LOCATION_INPUT_TYPE',
                                            payload: {
                                                kind: option.key as
                                                    | 'address'
                                                    | 'coordinates'
                                            }
                                        });
                                }}
                            />
                        </label>

                        {state.locationInputType === 'coordinates' && (
                            <>
                                <label className={FORM_ITEM}>
                                    <FormattedMessage
                                        key="lat-label"
                                        defaultMessage="Latitude"
                                    />

                                    <Select
                                        isDisabled={formDisabled}
                                        options={state.dataset.headers.map(
                                            (header, i) => ({
                                                value: i,
                                                label: header,
                                                key: `${i} ${header}`
                                            })
                                        )}
                                        value={{
                                            value: state.identifiers.lat,
                                            label:
                                                state.identifiers.lat != null
                                                    ? state.dataset.headers[
                                                          state.identifiers.lat
                                                      ]
                                                    : ''
                                        }}
                                        onChange={option =>
                                            dispatch({
                                                type: 'SET_DATASET_IDENTIFIERS',
                                                payload: {
                                                    lat:
                                                        option?.value != null
                                                            ? option.value
                                                            : undefined
                                                }
                                            })
                                        }
                                    />
                                </label>

                                <label className={FORM_ITEM}>
                                    <FormattedMessage
                                        key="lng-label"
                                        defaultMessage="Longitude"
                                    />
                                    <Select
                                        isDisabled={formDisabled}
                                        options={state.dataset.headers.map(
                                            (header, i) => ({
                                                value: i,
                                                label: header,
                                                key: `${i} ${header}`
                                            })
                                        )}
                                        value={{
                                            value: state.identifiers.lng,
                                            label:
                                                state.identifiers.lng != null
                                                    ? state.dataset.headers[
                                                          state.identifiers.lng
                                                      ]
                                                    : ''
                                        }}
                                        onChange={option =>
                                            dispatch({
                                                type: 'SET_DATASET_IDENTIFIERS',
                                                payload: {
                                                    lng:
                                                        option?.value != null
                                                            ? option.value
                                                            : undefined
                                                }
                                            })
                                        }
                                    />
                                </label>
                            </>
                        )}

                        {state.locationInputType === 'address' && (
                            <>
                                <label className={FORM_ITEM}>
                                    <FormattedMessage
                                        key="geocodeColumn-label"
                                        defaultMessage="Column with addresses"
                                    />
                                    <Select
                                        isDisabled={formDisabled}
                                        options={state.dataset.headers.map(
                                            (header, i) => ({
                                                value: i,
                                                label: header
                                            })
                                        )}
                                        value={{
                                            value:
                                                state.identifiers.addressColumn,
                                            label:
                                                state.identifiers
                                                    .addressColumn != null
                                                    ? state.dataset.headers[
                                                          state.identifiers
                                                              .addressColumn
                                                      ]
                                                    : ''
                                        }}
                                        onChange={option =>
                                            dispatch({
                                                type: 'SET_DATASET_IDENTIFIERS',
                                                payload: {
                                                    addressColumn:
                                                        option?.value != null
                                                            ? option.value
                                                            : undefined
                                                }
                                            })
                                        }
                                    />
                                </label>

                                <label className={FORM_ITEM}>
                                    <FormattedMessage
                                        key="geocodeCustomParsing-label"
                                        defaultMessage="Do you require custom parsing of the address column?"
                                    />
                                    <Select
                                        isDisabled={formDisabled}
                                        options={[
                                            {
                                                value: 0,
                                                label: 'None required'
                                            }
                                        ].concat(
                                            Object.keys(
                                                customColumnParsers
                                            ).map((key, i) => ({
                                                value: i + 1,
                                                label: key
                                            }))
                                        )}
                                        onChange={option => {
                                            dispatch({
                                                type: 'SET_CUSTOM_PARSING',
                                                payload: {
                                                    parsingFunction:
                                                        option?.value != null
                                                            ? customColumnParsers[
                                                                  option.label
                                                              ]
                                                            : undefined
                                                }
                                            });
                                        }}
                                    />
                                </label>

                                <label className={FORM_ITEM}>
                                    <FormattedMessage
                                        key="otherData-label"
                                        defaultMessage="What other data would you like to include in the dataset?"
                                    />
                                    <Select
                                        isMulti
                                        isDisabled={formDisabled}
                                        options={state.dataset.headers.map(
                                            (header, i) => ({
                                                value: i,
                                                label: header
                                            })
                                        )}
                                        onChange={selection => {
                                            dispatch({
                                                type: 'SET_ADDITIONAL_COLUMNS',
                                                payload: {
                                                    additionalColumns: selection.length
                                                        ? selection
                                                        : []
                                                }
                                            });
                                        }}
                                    />
                                </label>
                            </>
                        )}

                        <label className={FORM_ITEM}>
                            <FormattedMessage
                                key="color-label"
                                defaultMessage="Color"
                            />
                            <div>
                                <ColorPicker
                                    activeColor={color}
                                    setActiveColor={setColor}
                                    paletteOptions={colorOptions}
                                />
                            </div>
                        </label>
                        <div>
                            <Button
                                disabled={
                                    !validateIdentifiers(state.identifiers) ||
                                    state.datasetTitle.trim() === ''
                                }
                                loading={state.isSaving}
                                type="submit"
                                color="blue"
                                onClick={handleSubmit}
                            >
                                <FormattedMessage
                                    key="save-dataset"
                                    defaultMessage="Save Dataset"
                                />
                            </Button>
                        </div>

                        {state.isSaving === false &&
                            state.error?.kind === 'validation' && (
                                <>
                                    <p className={SAVE_ERROR}>
                                        {state.error.msg}
                                    </p>
                                    <FormattedMessage
                                        key="validate-error-help"
                                        defaultMessage="Please check that latitude and longitude columns are correct, and that your data contains valid coordinates."
                                    />
                                </>
                            )}
                    </Container>
                )}
            </PageBody>
        </PageContent>
    );
}

export default UploadPage;
