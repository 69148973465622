import React from 'react';
import { Container, ContainerHeader } from 'common/layout';
import {
    defineMessages,
    FormattedList,
    FormattedMessage,
    useIntl
} from 'react-intl';
import { useMapView } from 'common/use-map-view';
import Currency from 'common/currency';
import "/opt/build/repo/src/page-fees/page-list.tsx?resplendence=true";
import Loading from 'common/loading';
import gql from 'graphql-tag';
import useData from 'utils/use-data';
import orderBy from 'lodash/orderBy';
import InvoiceRow from './page-list-invoice-row';
import {
    InvoiceListData,
    InvoiceListArgs,
    FeeRulesData,
    FeeRulesArgs
} from 'graphql.g';
import { MapView } from 'types';
import { useUser } from 'common/user';
import { AbsoluteLink, Link } from 'router';
import { Title } from 'common/title';
import useDocumentTitle from 'common/use-document-title';
import Callout from 'common/callout';
import { useOpenIncidentsData } from 'page-health/health-incident-log';

/*
@import 'style.scss';
*/;

export const TABLE = "rx-page-fees-page-list-1"/*
    @include table;
    width: 100%;
*/;

const FEES = "rx-page-fees-page-list-2"/*
    @include headline-2;
    color: $gray-90;

    display: grid;
    grid-template-columns: repeat(auto-fill, 160rem);
    grid-gap: 20rem 65rem;
    padding: 0 8rem;
    max-width: 1150;
    li {
        &:before {
            display: inline;
        }
    }
    min-height: 89rem;
*/;

const FEE_LABEL = "rx-page-fees-page-list-3"/*
    margin-top: -4rem;
    @include text-body-link;
    color: $gray-60;
*/;
const FEE_LABEL_LINK = "rx-page-fees-page-list-4"/*;
    display: block;
    max-width: 160rem;
    margin-top: -4rem;
    @include text-body-link;
    color: $blue-50;
    &:hover {
        text-decoration: underline;
    }
*/;

const LOADING_FEE = "rx-page-fees-page-list-5"/*
    background-color: $gray-90;
    width: 120rem;
    height: 37rem;
    margin-top: 15rem;
    position: relative;
    border-radius: 3rem;
    &:after {
        content: '';
        position: absolute;
        background-color: $gray-60;
        width: 80rem;
        height: 17rem;
        top: 52rem;
        border-radius: 3rem;
    }
*/;

class NoPoliciesError extends Error {
    constructor(mapView: MapView) {
        super(
            `Attempted to fetch policies for mapview "${mapView.name}", but it has none configured.`
        );
    }
}

function useActiveFees() {
    const mapView = useMapView();
    const { slug } = mapView;
    const data = useData<FeeRulesData, FeeRulesArgs>(RULES_QUERY, { slug })
        ?.mapView;
    if (!data) return null;
    if (!data.activeFees) throw new NoPoliciesError(mapView);
    return data.activeFees;
}

const RULES_QUERY = gql`
    query FeeRules($slug: String!) {
        mapView(slug: $slug) {
            id
            activeFees {
                id
                name
                rateAmount
            }
        }
    }
`;

function useInvoices() {
    const { slug } = useMapView();
    const data = useData<InvoiceListData, InvoiceListArgs>(INVOICE_LIST_QUERY, {
        slug
    });
    if (!data) return null;
    const invoices = data.mapView.invoices;
    return orderBy(
        invoices,
        [
            invoice => invoice.startDate.toString(),
            invoice => invoice.operator.name
        ],
        ['desc']
    );
}

const INVOICE_LIST_QUERY = gql`
    query InvoiceList($slug: String!) {
        mapView(slug: $slug) {
            id
            invoices {
                __typename
                id
                startDate
                operator {
                    id
                    name
                    slug
                }
                ... on IssuedInvoice {
                    total
                }
                ... on FailedInvoice {
                    reason
                }
                ... on PendingInvoice {
                    expectedIssueDate
                }
            }
        }
    }
`;

/** all the fees currently set up for the mapview */
function ListPageFees() {
    const fees = useActiveFees();
    const { useDemoFeatures } = useUser();

    // if there are no active fees, don't render this component.
    if (fees != null && fees.length === 0) {
        return null;
    }

    return (
        <Container>
            <ContainerHeader>
                <FormattedMessage
                    key="fees-header"
                    defaultMessage="Fee Policies"
                />
            </ContainerHeader>
            <Loading loading={!fees} kind="over-table" />
            <ul className={FEES}>
                {fees?.map(fee => (
                    <li key={fee.id}>
                        <Currency value={fee.rateAmount} />
                        {useDemoFeatures ? (
                            <Link
                                className={FEE_LABEL_LINK}
                                to={`../policy#${fee.id}`}
                            >
                                {fee.name}
                            </Link>
                        ) : (
                            <div className={FEE_LABEL}>{fee.name}</div>
                        )}
                    </li>
                )) ?? <li className={LOADING_FEE}></li>}
            </ul>
        </Container>
    );
}

/** all of this mapview's invoices */
function ListPageInvoices() {
    const invoices = useInvoices();

    return (
        <Container>
            <Loading loading={!invoices} size={5} kind="over-table" />
            <table className={TABLE}>
                <thead>
                    <tr>
                        <th>
                            <FormattedMessage
                                key="table-header-fee-period"
                                defaultMessage="Fee Period"
                            />
                        </th>
                        <th>
                            <FormattedMessage
                                key="table-header-provider"
                                defaultMessage="Provider"
                            />
                        </th>
                        <th>
                            <FormattedMessage
                                key="table-header-total-fees"
                                defaultMessage="Total Fees"
                            />
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {invoices?.map(inv => (
                        <InvoiceRow invoice={inv} key={inv.id} />
                    ))}
                </tbody>
            </table>
        </Container>
    );
}

const m = defineMessages({
    'document-title': 'Program Fees · Ride Report'
});

function OpenIncidentWarning() {
    const mapView = useMapView();
    const incidents = useOpenIncidentsData(mapView);

    if (
        incidents &&
        incidents.incidentReports?.some(incident =>
            incident?.components.includes('Fees')
        )
    ) {
        // Not all operators in the mapview might be affected by open incidents.
        // Filter down to just affected operators
        let operatorSet: Set<string> = new Set();
        incidents.incidentReports
            ?.filter(incident => incident?.components.includes('Fees'))
            .forEach(relevantIncident => {
                if (relevantIncident?.operator?.name) {
                    operatorSet.add(relevantIncident.operator.name);
                }
            });
        return (
            <Callout color="red">
                <FormattedMessage
                    key="fees-open-incident-warning"
                    defaultMessage="There is an open incident for {operators} that may affect fees. We are investigating and will update the <Link>incident log</Link>."
                    values={{
                        Link: msg => (
                            <AbsoluteLink
                                to={`/${mapView.slug}/health#incidents`}
                            >
                                {msg}
                            </AbsoluteLink>
                        ),
                        operators: (
                            <FormattedList
                                value={Array.from(operatorSet)}
                                style="long"
                                type="conjunction"
                            />
                        ),
                        numOperators: 1
                    }}
                />
            </Callout>
        );
    }
    return null;
}

function ListPage() {
    const intl = useIntl();
    useDocumentTitle(intl.formatMessage(m[`document-title`]));

    return (
        <>
            <Title
                title={
                    <FormattedMessage
                        key="hello"
                        defaultMessage="Program Fees"
                    />
                }
            />
            <OpenIncidentWarning />
            <ListPageFees />
            <ListPageInvoices />
        </>
    );
}

export default ListPage;
