import React from 'react';
import "/opt/build/repo/src/common/map-options.tsx?resplendence=true";
import { FormattedMessage } from 'react-intl';
import Icon, { IconName } from 'common/icon';
import { Dropdown } from 'common/dropdown';
import cx from 'classnames';

/*
@import 'style.scss';
*/;

const OPTIONS = "rx-common-map-options-1"/*
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    opacity: 1;
    transition: 1000ms all;
*/;

const HEADER = "rx-common-map-options-2"/*
    @include text-label-bold;
    color: $gray-60;
    padding-bottom: 8rem;
    &:not(:first-child) {
        margin-top: 16rem;
    }
*/;

const RADIO = "rx-common-map-options-3"/*
    $switch: ' + label';

    @include toggle-defaults($switch);

    &#{$switch} {
        @include text-body-link;
        color: $gray-90;
        padding: 8rem;
        svg {
            font-size: 20rem;
            margin-right: 8rem;
        }
    }
    &:not(:checked)#{$switch} svg {
        color: $gray-60;
    }
    &:not(:checked):not(:focus):not(:hover)#{$switch} {
        border-color: transparent;
    }
    &:checked#{$switch} {
        color: $white;
    }
*/;

const MENU = "rx-common-map-options-4"/*
    @include container;
    position: absolute;
    padding: 12rem;
    right: calc(100% + 8rem);
    top: 0;
    @include shadow-8;
    display: flex;
    flex-flow: column nowrap;
    transition: 100ms all;
    &.enter {
        transition-timing-function: ease-out;
    }
    &.exit {
        transition-duration: 200ms;
        transition-timing-function: ease-in;
    }
    &.enter, &.exit.exit-active {
        opacity: 0;
        transform: translateX(24rem);
    }
    &.enter.enter-active, &.exit {
        opacity: 1;
        transform: translateX(0rem);
    }
*/;

export type SidebarToggle = {
    header: React.ReactNode;
    options: {
        id: string;
        label: React.ReactNode;
        icon: IconName;
    }[];
    onChange: (value: string) => void;
    checkedId: string;
};

type Props = {
    toggles?: SidebarToggle[];
    showSatellite?: boolean;
    setSatellite?: (value: boolean) => void;
    className?: string;
    children?: any;
};

function SidebarOptions({
    toggles = [],
    showSatellite,
    setSatellite,
    className
}: Props) {
    const defaultToggle: SidebarToggle = {
        header: (
            <FormattedMessage key="map-layers" defaultMessage="Map Layers" />
        ),
        options: [
            {
                id: 'hide-satellite',
                icon: 'Street',
                label: (
                    <FormattedMessage
                        key="streets-button"
                        defaultMessage="Streets"
                    />
                )
            },
            {
                id: 'show-satellite',
                icon: 'Satellite',
                label: (
                    <FormattedMessage
                        key="satellite-button"
                        defaultMessage="Satellite"
                    />
                )
            }
        ],
        onChange: (value: 'hide-satellite' | 'show-satellite') =>
            setSatellite && setSatellite(value === 'show-satellite'),
        checkedId: showSatellite ? 'show-satellite' : 'hide-satellite'
    };

    const allToggles = setSatellite
        ? [defaultToggle, ...toggles]
        : [...toggles];

    return (
        <div className={cx(OPTIONS, className)}>
            {allToggles.map((toggle, index) => (
                <React.Fragment key={index}>
                    <div className={HEADER}>{toggle.header}</div>
                    {toggle.options.map(option => (
                        <React.Fragment key={option.id}>
                            <input
                                className={RADIO}
                                type="radio"
                                name={option.id}
                                id={option.id}
                                checked={toggle.checkedId === option.id}
                                onChange={() => toggle.onChange(option.id)}
                            />
                            <label htmlFor={option.id}>
                                <Icon icon={option.icon} />
                                {option.label}
                            </label>
                        </React.Fragment>
                    ))}
                </React.Fragment>
            ))}
        </div>
    );
}

export function MapOptions({
    toggles,
    showSatellite,
    setSatellite,
    className
}: Props) {
    return (
        <Dropdown
            buttonProps={{ icon: 'More' }}
            dropdownClassName={className ? className : MENU}
        >
            <SidebarOptions
                toggles={toggles}
                showSatellite={showSatellite}
                setSatellite={setSatellite}
            />
        </Dropdown>
    );
}

export default MapOptions;
