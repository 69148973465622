/**
 * Common components for UI layout.
 *
 * Our design system features many repeating patterns. By putting most of them
 * in a single folder, we can reduce repetition and make it unnecessary to think
 * about styling in most files.
 */

import React, { CSSProperties, forwardRef, useMemo } from 'react';
import "/opt/build/repo/src/common/layout.tsx?resplendence=true";import rx from 'resplendence';
import cx from 'classnames';
import { OperatorName } from 'constants/operators';
import { getLogoSvgForOperator } from 'utils/reqSvgs';
import { getOperatorColorStyle } from 'utils/helpers';
import { FormattedMessage } from 'react-intl';
import Icon, { IconName } from './icon';
import { v4 as uuid4 } from 'uuid';
import Loading from './loading';
import useTippy from 'utils/use-tippy';
import { Placement } from 'tippy.js';

/*
@import 'style';
*/;

/**
 * The container for the content of a page.
 */
const PAGE_CONTENT = "rx-common-layout-1"/*
    padding: 48rem 190rem 0 24rem;
    flex: 1 0 0;
    display: grid;
    grid: auto-flow / 974rem;
    &:after {
        content: '';
        height: 48rem;
    }
    @include scrollbar($green-50);
    align-content: start;
    justify-content: center;

    row-gap: 24rem;
    @include not-mobile {
        &.wide {
            padding-right: 24rem;
            grid: auto-flow / 1140rem;
        }
    }
    @include mobile {
        grid: auto-flow / 100%;
        padding-right: 24rem;
    }
    @include mobile-tiny {
        padding-left: 4rem;
        padding-right: 4rem;
    }
    @media print {
        grid: auto-flow / fit-content(100%);
        display: block;
        padding: 0;
        justify-content: start;
    }
*/;

type PageContentProps = {
    children: React.ReactNode;
    wide?: boolean;
};
export function PageContent({ children, wide = false }: PageContentProps) {
    return <main className={cx(PAGE_CONTENT, { wide })}>{children}</main>;
}

const PAGE_COLUMNS = "rx-common-layout-2"/*
    display: grid;
    column-gap: 24rem;
    position: relative;
    row-gap: 24rem;
    max-width: 1140rem;
    grid: auto / minmax(752rem, 1fr);
    grid-auto-columns: 1fr;
    grid-auto-flow: column;
    @include not-mobile {
        min-width: 974rem;
    }
    @include mobile {
        grid-template-columns: 1fr;
        grid-auto-flow: row;
        grid: auto / max(1fr);
    }
    align-items: start;
    &.balanced {
        grid-template-columns: 1fr;
    }
    @media print {
        grid: auto / 1fr;
        display: block;
    }
*/;

type PageColumnsProps = {
    /** If true, the columns are evenly sized. Otherwise the left is wider. */
    balanced?: boolean;
    children: any;
};
/**
 * Split the PageContent into two columns.
 */
export function PageColumns({ balanced = false, children }: PageColumnsProps) {
    return <div className={cx(PAGE_COLUMNS, { balanced })}>{children}</div>;
}

export const PageBody = rx('article', "rx-common-layout-3")/*
    display: grid;
    grid: auto / 1fr;
    row-gap: 24rem;
    @media print {
        max-width: unset;
        display: block;
        > * {
            margin-bottom: 24rem;
        }
    }
*/;

export const BANNER = "rx-common-layout-4"/*
    @include banner;
    padding: 0 24rem;
    display: grid;
    grid-template: 1fr / auto;
    grid-auto-flow: column;
    grid-auto-columns: auto;
    justify-content: space-between;
    align-items: center;
    @media print {
        border: 1rem solid $gray-90;
    }
    img {
        max-height: 55rem;
    }
*/;
type BannerProps = {
    children: React.ReactNode;
    el?: keyof JSX.IntrinsicElements;
} & React.HTMLAttributes<any>;

export function Banner({ children, el: Element = 'h2', ...rest }: BannerProps) {
    return (
        <Element className={BANNER} {...rest}>
            {children}
        </Element>
    );
}

type OperatorBannerProps = {
    children?: any;
    name: OperatorName;
};
/**
 * A banner with the operator's logo, styled to the appropriate colors.
 */
export function OperatorBanner({ children, name }: OperatorBannerProps) {
    const logoUrl = getLogoSvgForOperator(name);

    return (
        <Banner style={getOperatorColorStyle(name)}>
            {logoUrl ? <img src={logoUrl} alt={name} /> : <div>{name}</div>}
            {children}
        </Banner>
    );
}

const REPORT_SUMMARY_SUBTITLE = "rx-common-layout-5"/*
    @include text-body;
*/;

type AgencyBannerProps = {
    name: string;
};
/**
 * A banner with the Agency's name.
 */
export function AgencyBanner({ name }: AgencyBannerProps) {
    return (
        <Banner>
            <div>
                <div className={REPORT_SUMMARY_SUBTITLE}>
                    <FormattedMessage
                        key="prepared-for"
                        defaultMessage="Prepared for"
                    />
                </div>
                <div>{name}</div>
            </div>
        </Banner>
    );
}

export const CONTAINER = "rx-common-layout-6"/*
    @include container;
    position: relative;
    padding: 24rem;
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    @media print {
        display: block;
    }
*/;
type ContainerProps = {
    children?: React.ReactNode;
    el?: keyof JSX.IntrinsicElements;
    style?: CSSProperties;
};

/**
 * A "card" containing a unit of content.
 */
export const Container = forwardRef<HTMLElement, ContainerProps>(
    ({ children, el: Element = 'section', style }, ref) => {
        return (
            // @ts-expect-error - there doesn't seem to be any way to get the
            // type for the ref and the arbitrary element type to work correctly
            <Element ref={ref} style={style} className={CONTAINER}>
                {children}
            </Element>
        );
    }
);

const CONTAINER_HEADER = "rx-common-layout-7"/*
    margin: -24rem -24rem 24rem -24rem;
    padding: 0 24rem;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    @include subhead;
    min-height: 55rem;
    box-sizing: content-box;
    &.small {
        @include text-body;
        min-height: 44rem;
    }
    border-bottom: 1rem solid $gray-30;
    color: $gray-70;
*/;
type ContainerHeaderProps = {
    small?: boolean;
    children?: any;
    el?: keyof JSX.IntrinsicElements;
};

/**
 * Adds a title to a container.
 */
export function ContainerHeader({
    children,
    small,
    el: Element = 'h3'
}: ContainerHeaderProps) {
    return (
        <Element className={cx(CONTAINER_HEADER, { small })}>
            {children}
        </Element>
    );
}

const DETAILS_CONTAINER = "rx-common-layout-8"/*
    @include container;
    position: relative;
    padding: 0 24rem;
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    @media print {
        display: block;
    }
    & > summary {
        @include text-body;
        color: $gray-70;
        border-bottom: 1rem solid $gray-30;
        margin: 0 -24rem;
        line-height: 150%;
        padding: 10rem 24rem;
        margin-bottom: 16rem;
        cursor: pointer;

        &::-webkit-details-marker {
            display: none; // hide the arrow in chrome
        }
        list-style: none;  // hide it elsewhere

        @media screen {
            &:before { // show our own custom arrow image
                content: "";
                display: inline-block;
                width: 12rem;
                height: 12rem;
                background-repeat: no-repeat;
                background-size: contain;
                margin-right: 6rem;
                transition: transform 100ms;
                background-image: url('../assets/svgs/details_arrow.svg');
                transform: rotate-z(0);
            }
        }
    }
    &[open] {
        padding-bottom: 24rem;
        > summary:before {
            transform: rotate(0.5turn);
        }
    }
    &:not([open]) {
        // when closed, collapse the margins and paddings to make the header the
        // only thing visible
        padding-bottom: 0;
        padding-top: 0;
        & > summary {
            margin-bottom: 0rem;
            margin-top: 0rem;
            border-bottom: none;
        }
    }
*/;

const DETAILS_SUMMARY = "rx-common-layout-9"/*
    display: flex;
    align-items: center;
*/;

export const DetailsContainer = React.forwardRef<
    HTMLDetailsElement,
    {
        summary: React.ReactNode;
        classname?: any;
    } & React.DetailsHTMLAttributes<HTMLElement>
>(({ summary, children, classname, open = true, ...rest }, ref) => {
    return (
        <details
            ref={ref}
            className={cx(DETAILS_CONTAINER, classname)}
            open={open}
            {...rest}
        >
            <summary className={DETAILS_SUMMARY}>{summary}</summary>
            {children}
        </details>
    );
});

export const ContainerMessage = rx('div', "rx-common-layout-10")/*
    @include text-body;
    color: $gray-70;
    padding: 32rem 88rem;
    text-align: center;
*/;

export const PageSubheader = rx('h2', "rx-common-layout-11")/*
    @include headline-5;
    margin-top: 16rem;
*/;

export const BUTTON = "rx-common-layout-12"/*
    @include button;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    white-space: nowrap;
    pointer-events: auto;
    svg {
        margin-right: 4rem;
        font-size: 20rem;
        &:last-child {
            margin: 2rem -6rem;
        }
    }
    &.center {
        justify-content: center;
    }
    &.right {
        flex-direction: row-reverse;
    }
    @media print {
        display: none;
    }
*/;

export type ButtonProps = {
    /** What text to render inside the button */
    children?: React.ReactNode;

    /** The appearance of the button */
    color?: 'red' | 'blue' | 'gray' | 'outline-red';

    /** The icon to show to the left of the text, if any. */
    icon?: IconName;
    className?: string;
    loading?: boolean;
    disabled?: boolean;
    center?: boolean;
    /** changes the position of the icon to the right, on left by default*/
    iconPosition?: 'left' | 'right';
    type?: 'button' | 'submit' | 'reset';
    onClick?: (
        event:
            | React.MouseEvent<HTMLButtonElement, MouseEvent>
            | React.FormEvent<HTMLButtonElement>
    ) => void;
    style?: React.CSSProperties;
};

/** A common button */
export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
    (
        {
            children,
            onClick,
            color = 'gray',
            className = BUTTON,
            disabled = false,
            loading = false,
            center = false,
            iconPosition = 'left',
            icon,
            style,
            type = 'button'
        }: ButtonProps,
        ref
    ) => {
        return (
            <button
                type={type}
                ref={ref}
                onClick={onClick}
                disabled={disabled || loading}
                className={cx(className, color, iconPosition, {
                    center: center
                })}
                style={style}
            >
                <Loading loading={loading} kind="blue" size={2} />
                {icon ? <Icon icon={icon} /> : null}
                {children ? <span>{children}</span> : null}
            </button>
        );
    }
);

const TEXT_FIELD = "rx-common-layout-13"/*
    @include forms-text-field;
*/;
const TEXT_FIELD_LABEL = "rx-common-layout-14"/*
    @include text-label;
    display: block;
    color: $gray-70;
    margin-bottom: 3rem;
*/;
const TEXT_FIELD_WRAPPER = "rx-common-layout-15"/*
    display: flex;
    flex-flow: column;
    align-items: stretch;
*/;
const TEXT_FIELD_NOTES = "rx-common-layout-16"/*
    @include text-label;
    margin-top: 2rem;
    margin-bottom: -21rem;
*/;
const TEXT_FIELD_ERROR = "rx-common-layout-17"/*
    color: $red-50;
*/;
const TEXT_FIELD_WARNING = "rx-common-layout-18"/*
    color: $yellow-50;
*/;

/** A text field that has a label must also have an id */
type TextFieldWithLabelProps = {
    label: React.ReactNode;
    id: string;
};
/** TextFields without labels are not required to have an ids */
type TextFieldWithoutLabelProps = { id?: string; label?: undefined };
type TextFieldProps = {
    disabled?: boolean;
    id?: string;
    onChange: (value: string) => void;
    onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
    onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
    onClick?: (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => void;
    placeholder?: string;
    type?: 'text' | 'password' | 'email';
    value?: string;
    /** A validation error message to display */
    error?: React.ReactNode;
    warning?: React.ReactNode;
} & (TextFieldWithLabelProps | TextFieldWithoutLabelProps);

/** A common text field */
export const TextField = React.forwardRef<HTMLInputElement, TextFieldProps>(
    (
        {
            disabled = false,
            error = false,
            id,
            onChange,
            onBlur,
            onFocus,
            onClick,
            placeholder,
            type = 'text',
            value,
            label,
            warning
        },
        ref
    ) => {
        const input = (
            <input
                ref={ref}
                className={cx(TEXT_FIELD, { error, warning })}
                disabled={disabled}
                id={id}
                onChange={e => onChange(e.target.value)}
                onFocus={onFocus}
                onBlur={onBlur}
                onClick={onClick}
                placeholder={placeholder}
                type={type}
                value={value}
            />
        );
        if (label || error || warning) {
            return (
                <div className={TEXT_FIELD_WRAPPER}>
                    {label && (
                        <label className={TEXT_FIELD_LABEL} htmlFor={id}>
                            {label}
                        </label>
                    )}
                    {input}
                    {(error || warning) && (
                        <div className={TEXT_FIELD_NOTES}>
                            {error && (
                                <div role="alert" className={TEXT_FIELD_ERROR}>
                                    {error}
                                </div>
                            )}
                            {warning && (
                                <div
                                    role="alert"
                                    className={TEXT_FIELD_WARNING}
                                >
                                    {warning}
                                </div>
                            )}
                        </div>
                    )}
                </div>
            );
        }
        return input;
    }
);

interface TextareaFieldProps extends Omit<TextFieldProps, 'onClick'> {
    onClick?: (e: React.MouseEvent<HTMLTextAreaElement, MouseEvent>) => void;
    resize?: CSSProperties['resize'];
}

/** A common textarea field (multiline) */
export function TextareaField({
    disabled = false,
    error = false,
    id,
    onChange,
    onClick,
    placeholder,
    value,
    resize
}: TextareaFieldProps) {
    return (
        <textarea
            style={{ resize }}
            className={cx(TEXT_FIELD, { error })}
            disabled={disabled}
            id={id}
            onChange={e => onChange(e.target.value)}
            onClick={onClick}
            placeholder={placeholder}
            value={value}
        />
    );
}

const TOGGLE = "rx-common-layout-19"/*
    @include toggle-defaults(' + label:before');
    & + label:before {
        content: '';
        width: 40rem;
        height: 24rem;
        border-radius: 64rem;
        margin-right: 0;
    }
    &.label {
        & + label:before {
            margin-right: 8rem;
            color: blue;
        }
        &:not(:checked) {
            & + label {
                color: $gray-70;
            }
        }
    }
    $knob-path: ' + label:after';
    &#{$knob-path} {
        content: '';
        height: 16rem;
        width: 16rem;
        position: absolute;
        border-radius: 32rem;
        left: 4rem;
        top: calc(50% - 8rem);
        background-color: $inactive-toggle;
        transition: 150ms all;
    }
    &:checked#{$knob-path} {
        left: 18rem;
        background-color: $white;
    }
    &:not(:disabled):not(:checked) {
        &:hover, &:focus {
            &#{$knob-path} {
                background-color: $hover-toggle;
            }
        }
    }
    &:disabled#{$knob-path} {
        background-color: $white;
    }
*/;

type ToggleProps = {
    checked: boolean;
    disabled?: boolean;
    onChange: (value: boolean) => void;
    /** What color to change the toggle to, away from its default */
    color?: string;
    label?: string;
    delayCount?: React.ReactNode;
    tooltipLabel?: string;
    tooltipPlacement?: Placement;
};

/**
 * A pretty toggle switch
 */
export function Toggle({
    checked,
    disabled,
    onChange,
    tooltipLabel,
    tooltipPlacement = 'right',
    color,
    label = ''
}: ToggleProps) {
    // Generate a unique id for matching the input and label
    const id = useMemo(uuid4, []);

    const [tippy] = useTippy<HTMLDivElement>(tooltipLabel ?? null, {
        placement: tooltipPlacement
    });

    return (
        <div
            ref={tippy}
            style={
                {
                    '--ride-report-active-toggle-color': color,
                    '--ride-report-hover-toggle-color': color
                } as React.CSSProperties // css variables aren't included in CSSProperties by default
            }
        >
            <input
                className={cx(TOGGLE, { label: label })}
                type="checkbox"
                id={id}
                checked={checked}
                onChange={e => onChange(e.target.checked)}
                disabled={disabled}
            />
            <label htmlFor={id}>{label}</label>
        </div>
    );
}

const CHECKBOX = "rx-common-layout-20"/*
    $checkbox-path: ' + label > :first-child';

    @include toggle-defaults($checkbox-path);

    &#{$checkbox-path} {
        width: 20rem;
        height: 20rem;
        svg {
            color: white;
        }
    }

    &:not(:checked):disabled#{$checkbox-path} svg {
        color: transparent;
    }
*/;

const CHECKBOX_WRAPPER = "rx-common-layout-21"/*
    position: relative;
*/;

type CheckboxProps = {
    checked: boolean;
    disabled?: boolean;
    onChange: (value: boolean) => void;
    label?: React.ReactNode;
    ariaLabel?: string;
    color?: string;
};

/**
 * A pretty checkbox
 */
export function Checkbox({
    checked,
    disabled,
    onChange,
    label,
    ariaLabel,
    color
}: CheckboxProps) {
    // Generate a unique id for matching the input and label
    const id = useMemo(uuid4, []);
    return (
        <div
            className={CHECKBOX_WRAPPER}
            style={
                color
                    ? ({
                          '--ride-report-inactive-toggle-color': color,
                          '--ride-report-active-toggle-color': color,
                          '--ride-report-hover-toggle-color': color
                      } as React.CSSProperties) // css variables aren't included in CSSProperties by default
                    : undefined
            }
        >
            <input
                className={CHECKBOX}
                type="checkbox"
                id={id}
                checked={checked}
                onChange={e => onChange(e.target.checked)}
                disabled={disabled}
            />
            <label aria-label={ariaLabel} htmlFor={id}>
                <div>
                    <Icon icon="Check" />
                </div>
                {label}
            </label>
        </div>
    );
}

const TAB_TOGGLE = "rx-common-layout-22"/*
    $toggle-path: ' + div';
    @include toggle-defaults($toggle-path);

    &#{$toggle-path} {
        height: 36rem;
        width: 100%;
        position: relative;
        border-radius: 6rem;
        padding: 3rem;
        @include text-body-link;
        background-color: $gray-30;
        border-color: transparent;
        color: $gray-60;
        cursor: pointer;
        & > span {
            width: 50%;
            text-align: center;
            z-index: 1;
            pointer-events: none;
            transition: color 200ms ease-in-out;
            padding: 3rem;
            &.active {
                color: $gray-90;
            }
        }
        &.tiny{
            @include text-body-tiny;
        }
    }
    &:not(:disabled) {
        &:hover,
        &:focus {
            &:not(:checked)#{$toggle-path} {
                border-color: transparent;
            }
        }
    }
    &:checked#{$toggle-path} {
        background-color: $gray-30;
    }
    &:disabled#{$toggle-path} {
        background-color: $gray-30;
    }

    &#{$toggle-path} > div.slider {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        margin: 3rem;
        width: 50%;
        background-color: $white;
        border-color: transparent;
        border-radius: 3rem;
        box-shadow: 0rem 1rem 4rem rgba(0, 0, 0, 0.1), 0rem 4rem 8rem rgba(0, 0, 0, 0.12);
        transition: transform 200ms ease-in-out;

        &.right {
            transform: translateX(calc(100% - 6rem))
        }
        &.tiny {
            transform: translateX(calc(100% - 4rem))
        }
        &.disabled {
            background-color: $gray-10;
            box-shadow: none;
        }
    }
*/;

const TAG_TOGGLE_LABEL = "rx-common-layout-23"/*
    position: relative;
*/;

interface TabToggleProps {
    checked: boolean;
    onChange: (value: boolean) => void;
    tabLabels: [string, string];
    label?: React.ReactNode;
    className?: string | null;
    tiny?: boolean;
    disabled?: boolean;
}

/** A tab like toggle */
export function TabToggle({
    checked,
    onChange,
    label,
    tabLabels,
    className,
    tiny,
    disabled
}: TabToggleProps) {
    // Generate a unique id for matching the input and label
    const id = useMemo(uuid4, []);

    return (
        <label htmlFor={id} className={TAG_TOGGLE_LABEL}>
            <div className={cx(className)}>{label}</div>
            <input
                disabled={disabled}
                className={TAB_TOGGLE}
                type="checkbox"
                id={id}
                checked={checked}
                onChange={e => onChange(e.target.checked)}
            />
            <div className={cx(tiny && 'tiny')}>
                <div
                    className={cx(
                        'slider',
                        checked && 'right',
                        tiny && checked && 'tiny',
                        disabled && 'disabled'
                    )}
                />
                <span className={!checked ? 'active' : undefined}>
                    {tabLabels[0]}
                </span>
                <span className={checked ? 'active' : undefined}>
                    {tabLabels[1]}
                </span>
            </div>
        </label>
    );
}

export const LINK = "rx-common-layout-24"/*
    @include text-body-link-underlined;
    color: $blue-50;
*/;

/**
 * A link to be placed in the "call-to-action" area in the top-right of the page
 * */
export const ACTION_LINK = "rx-common-layout-25"/*
    @include navigation-link;
    @include container;
    padding: 6rem 12rem;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    svg:first-child {
        font-size: 24rem;
        margin-right: 8rem;
    }
*/;

/** A link to return to the preview page */
export const BACK_LINK = "rx-common-layout-26"/*
    @include text-body-link-underlined;
    color: $blue-50;
    &:before {
        content: "← "
    }
*/;

interface NumberFieldProps {
    value: React.InputHTMLAttributes<HTMLInputElement>['value'];
    onChange: (value: string) => void;
    min?: React.InputHTMLAttributes<HTMLInputElement>['min'];
    max?: React.InputHTMLAttributes<HTMLInputElement>['max'];
    step?: React.InputHTMLAttributes<HTMLInputElement>['step'];
    id?: string;
    label?: string;
    labelPosition?: 'left' | 'right' | 'none';
    width?: CSSProperties['width'];
    disabled?: boolean;
}

const NUMBER_INPUT_CONTAINER = "rx-common-layout-27"/*
    position: relative;
    display: flex;
    align-items: center;
    color: $gray-90;

    &.left {
        flex-direction: row;

        > span {
            border-bottom-right-radius: 0;
            border-top-right-radius: 0;
            border-right: 0;
        }
        > input {
            border-bottom-left-radius: 0;
            border-top-left-radius: 0;
        }
    }
    &.right {
        flex-direction: row-reverse;

        > span {
            border-bottom-left-radius: 0;
            border-top-left-radius: 0;
            border-left: 0;
        }
        > input {
            border-bottom-right-radius: 0;
            border-top-right-radius: 0;
        }
    }
*/;

const NUMBER_INPUT_LABEL = "rx-common-layout-28"/*
    display: inline-flex;
    align-items: center;
    height: 36rem;
    background-color: $gray-10;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    border: 1rem solid $gray-30;
    border-radius: 6rem;
*/;

const NUMBER_INPUT = "rx-common-layout-29"/*
    @include forms-number-field;
    display: table-cell;
    width: 100%;
    height: 36rem;
*/;

/** A common number input, with support for adding a label, such as currency */
export function NumberField({
    value,
    onChange,
    min,
    max,
    step,
    id,
    label,
    labelPosition = 'left',
    width,
    disabled
}: NumberFieldProps) {
    return (
        <div
            className={cx(
                NUMBER_INPUT_CONTAINER,
                labelPosition !== 'none' && labelPosition
            )}
            style={{ maxWidth: width }}
        >
            {label && <span className={NUMBER_INPUT_LABEL}>{label}</span>}
            <input
                disabled={disabled}
                type="number"
                min={min}
                max={max}
                step={step}
                id={id}
                className={NUMBER_INPUT}
                value={value}
                // only send number characters, decimal, -
                onChange={e =>
                    onChange(e.target.value.replace(/[^0-9.-]/g, ''))
                }
            />
        </div>
    );
}

const RADIAL_BUTTON_WRAPPER = "rx-common-layout-30"/*
    display: flex;
    flex-direction: column;
    > label {
        padding: 4rem 0;
        display: flex;
        align-items: center;
    }
*/;

interface radialButtonProps {
    options: string[];
    values: string[];
    onChange: (value: string) => void;
    selected?: string;
}

export function RadialButton({
    options,
    values,
    onChange,
    selected
}: radialButtonProps) {
    const handleChange = e => {
        onChange(e.target.value);
    };

    return (
        <form className={RADIAL_BUTTON_WRAPPER}>
            {options.map((option, i) => {
                return (
                    <label key={i}>
                        <input
                            type="radio"
                            value={values[i]}
                            id={values[i]}
                            onChange={handleChange}
                            checked={selected === values[i]}
                            name="group"
                        />
                        <span>{option}</span>
                    </label>
                );
            })}
        </form>
    );
}
