import React from 'react';
import { Switch } from 'router';
import PolicyEditorPage from './page-new';
import PolicyDetailsPage from './page-details';
import { useEnabledFeatures } from 'common/use-feature-flags';
import { FeatureFlaggedFeature } from 'graphql.g';
import { useUser } from 'common/user';
import PolicyDashboardPage from './page-dashboard';
import PolicyListPage from './page-list';

function PolicyPage() {
    const enabledFeatures = useEnabledFeatures();
    const { useDemoFeatures, orgAuth } = useUser();

    const canManagePolicies =
        orgAuth.organization.__typename === 'AgencyOrganization' &&
        (enabledFeatures.includes(FeatureFlaggedFeature.self_serve_policies) ||
            useDemoFeatures);
    return (
        <>
            <Switch
                pages={[
                    ...(canManagePolicies
                        ? [
                              {
                                  path: '/form/:mode',
                                  component: PolicyEditorPage
                              }
                          ]
                        : []),
                    {
                        path: '/dashboard',
                        exact: true,
                        component: PolicyDashboardPage
                    },
                    {
                        path: '/active',
                        exact: true,
                        component: PolicyListPage
                    },
                    {
                        path: '/ended',
                        exact: true,
                        component: PolicyListPage
                    },
                    {
                        path: '/',
                        exact: true,
                        redirectTo: '/dashboard'
                    },
                    {
                        path: '/:policyId',
                        component: PolicyDetailsPage
                    }
                ]}
            />
        </>
    );
}

export default PolicyPage;
