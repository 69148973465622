import { PolicyDetailsFragment, PolicyType } from 'graphql.g';

/**
 * Converts from the policy rule time to a JS Date.
 *
 * The time is provided as a string formatted according to ISO 8601 Time.
 * For example, we may get '08:15:00' to signify 8:15 am
 */
export function getDateTime(time: string) {
    const timeParts = time.split(':');
    const dateTime = new Date();
    dateTime.setHours(Number(timeParts[0]), Number(timeParts[1]));
    return dateTime;
}

/**
 * Sorts an array of policies first by policyType, then alphabetically based
 * on the policy name
 * */
export function sortPolicies<
    T extends { name: string; policyType: PolicyType }
>(policies: T[]) {
    // we make a copy first so we can call .sort which mutates the array
    return (
        [...policies]
            // sort alphabetically by name first
            .sort((a, b) => a.name.localeCompare(b.name))
            // sort the alphabetical list by policyType, giving us the proper
            // groupings that match the side nav.
            .sort((a, b) => a.policyType.localeCompare(b.policyType))
    );
}

/**
 * Returns the policy tags for a given policy
 */
export function getPolicyTags({ policy }: { policy: PolicyDetailsFragment }) {
    let tags: string[] = [];
    const rule = policy.rules[0];
    if ('summaryStatistic' in rule) tags.push(rule.summaryStatistic);
    return tags;
}
