import { colorArrayToRGB } from 'common/color-array-to-rgb';
import { HEATMAP_COLORS } from 'constants/map';
import { Layer } from 'mapbox-gl';
import { interpolate } from 'page-explore/common/interpolate';
import { HEX_COLORS } from '../hex-bins';

export function getCurbEventsLayers(
    sourceId: string,
    isClustered: boolean,
    curbEventsCount: number
): Layer[] {
    if (!isClustered) {
        return [
            {
                id: 'curb-events-heatmap',
                type: 'heatmap',
                source: sourceId,
                paint: {
                    'heatmap-opacity': 0.5,
                    'heatmap-color': interpolate(
                        ['heatmap-density'],
                        HEATMAP_COLORS
                    ),
                    'heatmap-intensity': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        12,
                        0.001,
                        16,
                        0.01,
                        20,
                        0.05,
                        24,
                        0.1
                    ]
                }
            }
        ];
    }

    return [
        {
            // We want to cluster by trip event data, and we want these clusters
            // to be visually distinguishable by the number of points they contain.
            // We need this to work across mapviews with large differences in scale.
            // To normalize this, we divide the point count of a given cluster by the
            // total number of events in the map.
            // When a user is zoomed in, this strategy makes it hard to tell the difference
            // between clusters with ~1% of points and .001% of points. To help distinguish
            // between these clusters, we use a slightly different interpolation function
            // when zoomed further in.
            // We apply this strategy to both the circle-color and circle-radius properties.
            // I considered using a step function rather than nested interpolation, but
            // step functions don't allow for expressions in the outputs; this made it
            // difficult to choose step function outputs that work well across multiple
            // cities.
            id: 'curb-events-cluster',
            type: 'circle',
            source: sourceId,
            filter: ['has', 'point_count'],
            paint: {
                'circle-color': [
                    'interpolate',
                    ['linear'],
                    ['zoom'],
                    12,
                    [
                        'interpolate',
                        ['linear'],
                        ['/', ['get', 'point_count'], curbEventsCount],
                        0,
                        colorArrayToRGB(HEX_COLORS[0]),
                        0.01,
                        colorArrayToRGB(HEX_COLORS[2]),
                        1,
                        colorArrayToRGB(HEX_COLORS[3])
                    ],
                    24,
                    [
                        'interpolate',
                        ['linear'],
                        ['/', ['get', 'point_count'], curbEventsCount],
                        0,
                        colorArrayToRGB(HEX_COLORS[0]),
                        0.00001,
                        colorArrayToRGB(HEX_COLORS[2]),
                        1,
                        colorArrayToRGB(HEX_COLORS[3])
                    ]
                ],
                'circle-radius': [
                    'interpolate',
                    ['linear'],
                    ['zoom'],
                    12,
                    [
                        'interpolate',
                        ['linear'],
                        ['/', ['get', 'point_count'], curbEventsCount],
                        0,
                        10,
                        0.01,
                        40,
                        1,
                        50
                    ],
                    24,
                    [
                        'interpolate',
                        ['linear'],
                        ['/', ['get', 'point_count'], curbEventsCount],
                        0,
                        10,
                        0.00001,
                        40,
                        1,
                        50
                    ]
                ]
            }
        },
        {
            id: 'curb-events-cluster-text',
            type: 'symbol',
            source: sourceId,
            filter: ['has', 'point_count'],
            layout: {
                'text-field': '{point_count_abbreviated}',
                'text-font': ['Overpass Regular', 'Arial Unicode MS Regular'],
                'text-size': 12
            }
        },
        {
            id: 'curb-events-points',
            type: 'circle',
            source: sourceId,
            filter: ['!', ['has', 'point_count']],
            paint: {
                'circle-color': '#11b4da',
                'circle-radius': 4,
                'circle-stroke-width': 1,
                'circle-stroke-color': '#fff'
            }
        }
    ];
}
