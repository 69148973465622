import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import "/opt/build/repo/src/page-segment-editor/segment-editor-panel.tsx?resplendence=true";
import {
    ContextPanel,
    ContextPanelInfo
} from 'page-explore/page-realtime/context-panel';
import KeyValue from 'common/key-value';
import { Button, TextField } from 'common/layout';
import { useEffect } from 'react';
import { gql, useMutation } from '@apollo/client';
import { EditSegmentMutationArgs, EditSegmentResult } from 'graphql.g';
import { useMapView } from 'common/use-map-view';
import { ErrorCallout } from 'common/error-message';

/*
    @import 'style';
*/;

const BUTTON_CONTAINER = "rx-page-segment-editor-segment-editor-panel-1"/*
    margin-top: 8rem;
*/;

const EDIT_SEGMENT_MUTATION = gql`
    mutation EditSegment($streetSegment: EditStreetSegmentInput!) {
        editStreetSegment(streetSegment: $streetSegment) {
            streetSegment {
                geometryId
                name
            }
        }
    }
`;

interface SegmentEditorPanelProps {
    selectedSegment: {
        geometryId: string;
        name: string;
        roadClass: string | null;
    };
    onClose: () => void;
    onSave: () => void;
}

function SegmentEditorPanel({
    selectedSegment,
    onClose,
    onSave
}: SegmentEditorPanelProps) {
    const [name, setName] = useState(selectedSegment.name);
    const { id: mapViewId } = useMapView();
    const [editSegment, { loading: isSaving, error }] = useMutation<
        EditSegmentResult,
        EditSegmentMutationArgs
    >(EDIT_SEGMENT_MUTATION);

    useEffect(() => {
        setName(selectedSegment.name);
    }, [selectedSegment.name]);

    const hasNameChanged = name !== selectedSegment.name;

    const handleEditSegment = async (e: React.FormEvent) => {
        e.preventDefault();

        try {
            const result = await editSegment({
                variables: {
                    streetSegment: {
                        geometryId: selectedSegment.geometryId,
                        mapviewId: mapViewId,
                        name
                    }
                }
            });

            if (result.data?.editStreetSegment?.streetSegment) {
                onSave();
            }
        } catch (err) {
            console.error(err);
            window.rollbar.error(err);
        }
    };

    return (
        <ContextPanel
            title={
                <FormattedMessage
                    key="segment-editor-panel-title"
                    defaultMessage="Segment Editor"
                />
            }
            onClose={onClose}
            color="#3b55e6" // $blue-50
        >
            <ContextPanelInfo>
                <KeyValue
                    keyName={
                        <FormattedMessage
                            key="geometry-id"
                            defaultMessage="Geometry ID"
                        />
                    }
                >
                    {selectedSegment.geometryId}
                </KeyValue>
                <KeyValue
                    keyName={
                        <FormattedMessage
                            key="road-class"
                            defaultMessage="Road Class"
                        />
                    }
                >
                    {selectedSegment.roadClass}
                </KeyValue>
                <KeyValue
                    keyName={
                        <FormattedMessage key="name" defaultMessage="Name" />
                    }
                >
                    <TextField
                        id="segment-name"
                        onChange={setName}
                        value={name}
                    />
                </KeyValue>
                <div className={BUTTON_CONTAINER}>
                    <Button
                        type="submit"
                        color={hasNameChanged ? 'blue' : 'gray'}
                        disabled={!hasNameChanged}
                        onClick={handleEditSegment}
                        loading={isSaving}
                    >
                        {isSaving ? (
                            <FormattedMessage
                                key="saving"
                                defaultMessage="Saving"
                            />
                        ) : (
                            <FormattedMessage
                                key="save"
                                defaultMessage="Update Name"
                            />
                        )}
                    </Button>
                </div>
                {error && <ErrorCallout error={error} />}
            </ContextPanelInfo>
        </ContextPanel>
    );
}

export default SegmentEditorPanel;
