import React from 'react';
import Icon from './icon';
import "/opt/build/repo/src/common/search-input.tsx?resplendence=true";
import { defineMessages, useIntl } from 'react-intl';

/*
@import 'style';
*/;

const m = defineMessages({
    'search-placeholder': 'Type to filter...',
    'all-service-areas-link': 'All Service Areas'
});

const SEARCH_WRAPPER = "rx-common-search-input-1"/*
    position: relative;
    margin: 0 12px;
*/;

const SEARCH_ICON = "rx-common-search-input-2"/*
    position: absolute;
    font-size: 20px;
    color: $gray-60;
    top: calc(40% - 10px);
    left: 8px;
*/;

const SEARCH_INPUT = "rx-common-search-input-3"/*
    padding-left: 32px;
    margin-bottom: 10px;
    border: 1px solid $gray-30;
    box-sizing: border-box;
    border-radius: 6px;
    width: 100%;
    height: 36px;
*/;

type Props = {
    onChange: (value: string) => void;
    value: string;
};

const SearchInput = ({ value, onChange }: Props) => {
    const intl = useIntl();
    return (
        <div className={SEARCH_WRAPPER}>
            <Icon icon="Search" className={SEARCH_ICON} />
            <input
                type="text"
                onChange={e => onChange(e.target.value)}
                value={value}
                placeholder={intl.formatMessage(m['search-placeholder'])}
                className={SEARCH_INPUT}
            />
        </div>
    );
};

export default SearchInput;
