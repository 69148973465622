import React, { useState, useEffect, useMemo } from 'react';
import "/opt/build/repo/src/page-report/report-picker.tsx?resplendence=true";

import { LocalDate, LocalMonth, LocalWeek } from 'utils/date-tools';
import { MapView } from 'types';
import { FormattedDate, FormattedMessage } from 'react-intl';
import ReportPickerDaily from './report-picker-daily';
import ReportPickerMonthly from './report-picker-monthly';
import ReportPickerWeekly from './report-picker-weekly';
import { DropdownSelect } from 'common/dropdown-select';

/*
@import "style";
*/;

export type ReportDate = LocalDate | LocalWeek | LocalMonth;
export type PickerMode = 'daily' | 'weekly' | 'monthly';

const DROPDOWN = "rx-page-report-report-picker-1"/*
    display: grid;
    grid-template-columns: 140rem 294rem;
    grid-column-gap: 8rem;
*/;
const MODE_LIST = "rx-page-report-report-picker-2"/*
    margin-bottom: 5rem;
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    padding: 0 12rem 0 4rem;
    border-right: 1rem solid $gray-30;
*/;
const MODE = "rx-page-report-report-picker-3"/*
    @include text-body-link;
    color: $gray-90;
    transition-property: background-color;
    transition-duration: 100ms;
    border-radius: 6rem;
    padding: 8rem 12rem;
    &:disabled {
        background-color: $blue-50;
        color: white;
    }
    &:not(:disabled):hover {
        background-color: $gray-30;
    }
*/;
const CALENDAR = "rx-page-report-report-picker-4"/*
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    position: relative;
*/;

type Props = {
    mapView: MapView;
    selectedReport: ReportDate;
};

const dateToPickerMode = (date: ReportDate) =>
    date instanceof LocalDate
        ? 'daily'
        : date instanceof LocalWeek
        ? 'weekly'
        : 'monthly';

function ReportPicker({ mapView, selectedReport }: Props) {
    const [pickerMode, setPickerMode] = useState<PickerMode>(
        dateToPickerMode(selectedReport)
    );
    const [pickerActive, setPickerActive] = useState(false);
    const defaultMonth = useMemo(
        () =>
            selectedReport instanceof LocalMonth
                ? selectedReport
                : selectedReport instanceof LocalWeek
                ? selectedReport.monday.plusDays(3).toLocalMonth()
                : selectedReport.toLocalMonth(),
        [selectedReport]
    );
    const [selectedMonth, setSelectedMonth] = useState(defaultMonth);

    useEffect(() => {
        setPickerMode(dateToPickerMode(selectedReport));
        setPickerActive(false);
        // change the month after the picker is done fading away
        const timeout = setTimeout(() => setSelectedMonth(defaultMonth), 250);
        return () => clearTimeout(timeout);
    }, [defaultMonth, selectedReport]);

    return (
        <DropdownSelect
            value={
                selectedReport instanceof LocalDate ? (
                    <FormattedDate
                        value={selectedReport.toDate()}
                        year="numeric"
                        month="short"
                        day="numeric"
                    />
                ) : selectedReport instanceof LocalWeek ? (
                    <>
                        <FormattedDate
                            value={selectedReport.monday.toDate()}
                            year="numeric"
                            month="short"
                            day="numeric"
                        />
                        —
                        <FormattedDate
                            value={selectedReport.sunday.toDate()}
                            year="numeric"
                            month="short"
                            day="numeric"
                        />
                    </>
                ) : (
                    <FormattedDate
                        value={selectedReport.first.toDate()}
                        year="numeric"
                        month="short"
                    />
                )
            }
            icon="Calendar"
            setActive={setPickerActive}
            active={pickerActive}
        >
            <div className={DROPDOWN}>
                <div className={MODE_LIST}>
                    <button
                        type="button"
                        className={MODE}
                        onClick={() => setPickerMode('daily')}
                        disabled={pickerMode === 'daily'}
                    >
                        <FormattedMessage
                            key="daily-button"
                            defaultMessage="Daily"
                        />
                    </button>
                    <button
                        type="button"
                        className={MODE}
                        onClick={() => setPickerMode('weekly')}
                        disabled={pickerMode === 'weekly'}
                    >
                        <FormattedMessage
                            key="weekly-button"
                            defaultMessage="Weekly"
                        />
                    </button>
                    <button
                        type="button"
                        className={MODE}
                        onClick={() => setPickerMode('monthly')}
                        disabled={pickerMode === 'monthly'}
                    >
                        <FormattedMessage
                            key="monthly-button"
                            defaultMessage="Monthly"
                        />
                    </button>
                </div>
                <div className={CALENDAR}>
                    {pickerMode === 'daily' ? (
                        <ReportPickerDaily
                            mapView={mapView}
                            selectedMonth={selectedMonth}
                            selectMonth={setSelectedMonth}
                        />
                    ) : pickerMode === 'weekly' ? (
                        <ReportPickerWeekly
                            mapView={mapView}
                            selectedMonth={selectedMonth}
                            selectMonth={setSelectedMonth}
                        />
                    ) : (
                        <ReportPickerMonthly
                            mapView={mapView}
                            selectedYear={selectedMonth.year}
                            goToNextYear={() =>
                                setSelectedMonth(month => month.plusMonths(12))
                            }
                            goToPrevYear={() =>
                                setSelectedMonth(month => month.minusMonths(12))
                            }
                        />
                    )}
                </div>
            </div>
        </DropdownSelect>
    );
}

export default ReportPicker;
