import React from 'react';

import { LocalDate, LocalMonth } from 'utils/date-tools';
import { defineMessages } from 'react-intl';
import { useIntl } from 'utils/use-intl';
import HeaderNav from './report-nav';
import { useMapView } from 'common/use-map-view';
import { useFinalizedAggregatedReports } from './report-picker-common';
import { AggregatedReportPeriod } from 'graphql.g';

const m = defineMessages({
    'last-month-link': 'Last Month'
});

type Props = {
    month: LocalMonth;
};

function MonthlyNav({ month }: Props) {
    const mapView = useMapView();
    const intl = useIntl();
    const lastMonth = LocalDate.todayInTz(mapView.ianaTimezone)
        .toLocalMonth()
        .minusMonths(1);

    const startDate = month.minusMonths(3);
    const endDate = month.plusMonths(3);

    const [finalizedReports] = useFinalizedAggregatedReports(
        mapView,
        AggregatedReportPeriod.monthly,
        startDate.first,
        endDate.last
    );
    const reportMonths = Object.keys(finalizedReports).map(
        LocalDate.fromDateString
    );
    let nextReport: LocalDate | null = null;

    for (const report of reportMonths) {
        if (report.isAfter(month.first)) {
            nextReport = report;
            break;
        }
    }
    let prevReport: LocalDate | null = null;
    for (const report of reportMonths) {
        if (!report.isBefore(month.first)) {
            break;
        } else {
            prevReport = report;
        }
    }
    return (
        <HeaderNav
            mapView={mapView}
            mostRecent={lastMonth.first}
            mostRecentLabel={intl.formatMessage(m['last-month-link'])}
            nextReport={nextReport}
            previousReport={prevReport}
            selectedReport={month}
        />
    );
}

export default MonthlyNav;
