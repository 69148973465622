import React, { useEffect, useReducer } from 'react';
import HourRangeSlider from 'common/hour-range-slider';
import "/opt/build/repo/src/common/multi-hour-range-slider.tsx?resplendence=true";
import { Button } from './layout';
import { FormattedMessage } from 'react-intl';
import { flatMap, range } from 'utils/helpers';
import { isEqual } from 'lodash';

/*
@import 'style.scss';
*/;

interface State {
    hourRanges: [number, number][];
}

const initialState: State = {
    hourRanges: [[0, 24]]
};

function addHourRange(ranges: State['hourRanges']): State['hourRanges'] {
    if (ranges.length >= 3) {
        return ranges;
    } else {
        return [...ranges, [0, 24]];
    }
}

function removeHourRange(
    ranges: State['hourRanges'],
    position: number
): State['hourRanges'] {
    if (ranges.length <= 1) {
        return ranges;
    } else {
        const newRanges = [...ranges];
        newRanges.splice(position, 1);
        return newRanges;
    }
}

function setHourRange(
    ranges: State['hourRanges'],
    position: number,
    hours: [number, number]
): State['hourRanges'] {
    const newHourRanges = [...ranges];
    newHourRanges[position] = hours;
    return newHourRanges;
}

interface AddHourRangeAction {
    type: 'add';
}

export interface RemoveHourRangeAction {
    type: 'remove';
    position: number;
}

interface SetHourRangeAction {
    type: 'setHourRange';
    position: number;
    hours: [number, number];
}

export type MultiHourRangeActions =
    | AddHourRangeAction
    | RemoveHourRangeAction
    | SetHourRangeAction;

function reducer(state: State, action: MultiHourRangeActions) {
    switch (action.type) {
        case 'add':
            return {
                ...state,
                hourRanges: addHourRange(state.hourRanges)
            };
        case 'remove':
            return {
                ...state,
                hourRanges: removeHourRange(state.hourRanges, action.position)
            };
        case 'setHourRange':
            return {
                ...state,
                hourRanges: setHourRange(
                    state.hourRanges,
                    action.position,
                    action.hours
                )
            };
        default:
            throw new Error(
                'Invalid action passed to Multiple Hour Range slider'
            );
    }
}

function getValuesFromRange(dateRange: [number, number]): number[] {
    return range(dateRange[0], dateRange[1]);
}

const FULL_DAY = getValuesFromRange([0, 24]);

interface Props {
    setHours: (hours: number[] | null) => void;
}

function MultiHourRangeSelector({ setHours }: Props) {
    const [state, dispatchMultiHour] = useReducer(reducer, initialState);

    useEffect(() => {
        const hours = flatMap(state.hourRanges, getValuesFromRange);
        const hoursSet = new Set(hours);
        const uniqueHours = [...hoursSet].sort((a, b) => a - b);
        if (isEqual(uniqueHours, FULL_DAY)) {
            //return null if user selects full day so the backend will filter on day vs hour
            setHours(null);
        } else {
            setHours(uniqueHours);
        }
    }, [state, setHours]);

    return (
        <>
            {state.hourRanges.map((range, position) => {
                return (
                    <HourRangeSlider
                        key={position}
                        id={position}
                        hourRange={range}
                        setMultiHour={dispatchMultiHour}
                        removeHourRange={
                            position !== 0 ? dispatchMultiHour : undefined
                        }
                    />
                );
            })}
            <div>
                {state.hourRanges.length <= 2 ? (
                    <Button
                        color="blue"
                        icon="Plus"
                        onClick={() => dispatchMultiHour({ type: 'add' })}
                    >
                        <FormattedMessage
                            key="add-time-range"
                            defaultMessage="Add Time Range"
                        />
                    </Button>
                ) : null}
            </div>
        </>
    );
}

export default MultiHourRangeSelector;
