import React from 'react';
import "/opt/build/repo/src/common/callout.tsx?resplendence=true";
import cx from 'classnames';
import { Button } from '../common/layout';

/*
@import 'style.scss';
*/;

type Props = {
    dismiss?: () => void;
    children: React.ReactNode;
    color: 'gray' | 'blue' | 'red' | 'green' | 'yellow';
    shadow?: boolean;
    role?: string;
};

const CALLOUT = "rx-common-callout-1"/*
    &.gray {
        @include container;
    }
    &.blue {
        @include container("blue");
    }
    &.yellow {
        @include container("yellow");
    }
    &.red {
        @include container("red");
    }
    &.green {
        @include container("green");
    }
    &.yellow {
        @include container("yellow");
    }
    @include text-body-link;
    &.shadow {
        @include shadow-8;
    }
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: start;
    padding: 16rem 16rem 16rem 24rem;
    grid-gap: 20rem;

    a {
        @include text-body-link-underlined;
        color: $blue-50;
    }

*/;

const DISMISS_BUTTON = "rx-common-callout-2"/*
    margin: -8rem;
    pointer-events: auto;
    cursor: default;
    background-color: transparent;
    border: none;
    color: $blue-50;
    &:not(:hover):not(:focus) {
        box-shadow: none;
    }
    &:not(:disabled):hover, &:not(:disabled):focus {
        color: white;
        background-color: $blue-30;
    }
    &.red {
        color: $red-50;
        &:not(:disabled):hover, &:not(:disabled):focus {
            color: white;
            background-color: $red-30;
        }
    }
    &.yellow {
        color: $gray-90;
        &:not(:disabled):hover, &:not(:disabled):focus {
            color: white;
            background-color: $yellow-00;
        }
    }
    &.green {
        color: $green-50;
        &:not(:disabled):hover, &:not(:disabled):focus {
            color: white;
            background-color: $green-30;
        }
    }
*/;

function Callout({ dismiss, children, color, shadow, role }: Props) {
    return (
        <div className={cx(CALLOUT, color, { shadow })} role={role}>
            <div>{children}</div>
            {dismiss && (
                <Button
                    onClick={dismiss}
                    className={cx(DISMISS_BUTTON, color)}
                    icon="Close"
                />
            )}
        </div>
    );
}

export default Callout;
