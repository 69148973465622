import * as React from 'react';
import {
    Switch,
    Route as ReactRoute,
    Redirect as ReactRedirect,
    useRouteMatch,
    match as RouterMatch
} from 'react-router-dom';
import { makeRelativePath } from './use-relative-path';

type Properties = { [key: string]: any };

interface Page<T extends Properties, R extends Properties> {
    path?: string;
    component?: React.ComponentType<
        {
            match: RouterMatch;
        } & T &
            R
    >;
    exact?: boolean;
    properties?: R;
}

interface Redirect {
    path?: string;
    redirectTo: string;
    exact?: boolean;
}

interface Props<T extends Properties> {
    pages: (Page<T, any> | Redirect)[];
    properties?: T;
}

const isRedirect = <T extends Properties>(
    page: Page<T, any> | Redirect
): page is Redirect => !!(page as Redirect).redirectTo;

/**
 * Switch between multiple relative routes.
 * Doesn't work the same as react-router because they're doing some
 * weirdness with the children prop in there that I don't want to replicate.
 *
 ```
<Switch pages={[
	{ path: 'path1', component: Page },
	{ path: 'path2', redirectTo: 'path1' },
	{ path: ':param', component: ParamPage }
	{ component: RootPage }
]} />
 ```
 */
function RelativeSwitch<T extends Properties>({ pages, properties }: Props<T>) {
    const match = useRouteMatch();
    return (
        <Switch>
            {pages.map(page => (
                <ReactRoute
                    key={makeRelativePath(match.path, page.path || '')}
                    path={makeRelativePath(match.path, page.path || '')}
                    exact={page.exact}
                    render={
                        isRedirect(page)
                            ? () => (
                                  <ReactRedirect
                                      to={makeRelativePath(
                                          match.url,
                                          page.redirectTo
                                      )}
                                  />
                              )
                            : ({ match }) => {
                                  const Component = page.component;
                                  return (
                                      Component && (
                                          <Component
                                              match={match}
                                              {...((properties || {}) as T)}
                                              {...(page.properties || {})}
                                          />
                                      )
                                  );
                              }
                    }
                />
            ))}
        </Switch>
    );
}

export default RelativeSwitch;
