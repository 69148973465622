import React, { useEffect } from 'react';
import "/opt/build/repo/src/page-report/report-picker-weekly.tsx?resplendence=true";

import { ParamLink } from 'router';
import { LocalDate, LocalMonth } from 'utils/date-tools';
import { MapView } from 'types';
import {
    getMonthStartEndDate,
    getMonthDateRange,
    useFinalizedAggregatedReportsQuery,
    useFinalizedAggregatedReports
} from './report-picker-common';
import {
    Weekdays,
    CalendarMonthNav,
    PreliminaryDataKey
} from 'common/date-picker';
import Loading from 'common/loading';
import cx from 'classnames';
import Icon from 'common/icon';
import { AggregatedReportPeriod } from 'graphql.g';

/*
@import "style";
@import "~common/date-picker-styles";
*/;

const CALENDAR_GRID = "rx-page-report-report-picker-weekly-1"/*
    @include calendar-grid;
    @include calendar-wrapper;
    grid-template-columns: auto;
    @include calendar;
*/;

const WEEK = "rx-page-report-report-picker-weekly-2"/*
    border-radius: 16rem;
    @include calendar-grid;
    color: $gray-90;
*/;

const DAY = "rx-page-report-report-picker-weekly-3"/*
    @include calendar-cell;
    color: inherit;
*/;

type Props = {
    mapView: MapView;
    selectedMonth: LocalMonth;
    selectMonth: (month: LocalMonth) => void;
};

function ReportPickerWeekly({ mapView, selectedMonth, selectMonth }: Props) {
    const query = useFinalizedAggregatedReportsQuery();

    useEffect(() => {
        query({
            slug: mapView.slug,
            ...getMonthStartEndDate(selectedMonth.minusMonths(1)),
            period: AggregatedReportPeriod.weekly
        });
        query({
            slug: mapView.slug,
            ...getMonthStartEndDate(selectedMonth.plusMonths(1)),
            period: AggregatedReportPeriod.weekly
        });
    }, [mapView.slug, query, selectedMonth]);

    const [reportsByDate, loading] = useFinalizedAggregatedReports(
        mapView,
        AggregatedReportPeriod.weekly,
        ...getMonthDateRange(selectedMonth)
    );

    const thisWeekStart = LocalDate.todayInTz(mapView.ianaTimezone)
        .toLocalWeek()
        .monday.toString();

    return (
        <>
            <CalendarMonthNav month={selectedMonth} onChange={selectMonth} />
            <div className={CALENDAR_GRID}>
                <Loading loading={loading} kind="over-table" size={4} />
                <div className={cx(WEEK, 'weekday')}>
                    <Weekdays />
                </div>
                {selectedMonth.toWeeks().map((week, weekNumber) => {
                    const dayStr = week.monday.toString();
                    const reportExists = loading || dayStr in reportsByDate;
                    const beforeThisWeek = dayStr < thisWeekStart;
                    return !reportExists && beforeThisWeek ? (
                        <div key={weekNumber} className={cx(WEEK, 'missing')}>
                            {week.toDays().map(day => (
                                <div
                                    key={day.toString()}
                                    className={cx(DAY, {
                                        outside: !day
                                            .toLocalMonth()
                                            .equals(selectedMonth)
                                    })}
                                >
                                    <Icon icon="Missing-File" />
                                </div>
                            ))}
                        </div>
                    ) : (
                        <ParamLink
                            key={weekNumber}
                            params={{
                                mode: 'weekly',
                                date: dayStr
                            }}
                            nav
                            disabled={!reportExists}
                            className={WEEK}
                        >
                            {week.toDays().map((day, dayOfWeek) => (
                                <div
                                    key={dayOfWeek}
                                    className={cx(DAY, {
                                        outside: !day
                                            .toLocalMonth()
                                            .equals(selectedMonth),
                                        preliminary:
                                            reportsByDate[dayStr] === false
                                    })}
                                >
                                    {day.day}
                                </div>
                            ))}
                        </ParamLink>
                    );
                })}
            </div>

            {Object.values(reportsByDate).some(finalized => !finalized) && (
                <PreliminaryDataKey />
            )}
        </>
    );
}

export default ReportPickerWeekly;
