import React, { useState } from 'react';
import "/opt/build/repo/src/common/code-block.tsx?resplendence=true";
import { useIntl, defineMessages } from 'react-intl';
import useTippy from 'utils/use-tippy';
import cx from 'classnames';
import Icon from './icon';

/*
@import 'style.scss';
*/;

const CODE_CONTAINER = "rx-common-code-block-1"/*
    background-color: #212a35;
    border-radius: 6rem;
    padding: 16rem;
    position: relative;
    height: auto;
    color: $gray-60;
    > pre > code > ol {
        font-family: PT-Mono;
        box-sizing: border-box;
        font-size: 16rem;
        overflow-x: auto;
        list-style-position: inside;
        counter-reset: item;
        list-style-type: none;
    }
    @media screen {
        &:not(.expanded) {
            &:after {
                content: "";
                position: absolute;
                z-index: 2;
                bottom: 0rem;
                left: 0rem;
                width: 100%;
                height: 200rem;
                background: linear-gradient(180deg, rgba(34, 42, 53, 0) 0%, #222A35 100%);
                border-radius: 6rem;
                pointer-events: none;
            }
            > pre > code > ol {
                height: 200rem;
                overflow: hidden;
            }
        }
    }
    @media print {
        border: 1rem solid $gray-30;
        background-color: transparent;
        color: $gray-90;
    }
*/;

const BUTTON_WRAPPER = "rx-common-code-block-2"/*
    z-index: 3;
    position: absolute;
    right: 24rem;
    height: auto;
    max-width: 70rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $white;
*/;

const ICON = "rx-common-code-block-3"/*
    font-size: 24rem;
    margin-right: 12rem;
*/;

const COPY_PASTE_ICON = "rx-common-code-block-4"/*
    font-size: 24rem;
*/;

const LIST_ITEM = "rx-common-code-block-5"/*
    max-width: 670rem;

    &:before {
        display: inline-block;
        width: 40rem;
        content: counter(item);
        counter-increment: item;
        vertical-align: baseline;
        line-height: 24rem;
    }
*/;

const m = defineMessages({
    collapse: 'Collapse',
    expand: 'Expand',
    'copy-to-clipboard': 'Copy to Clipboard',
    'copied-tooltip': 'Copied!'
});

interface CodeBlockProps {
    text: string;
    initiallyExpanded?: boolean;
}

/** An expandable block of code, with a button to copy the whole block */
function CodeBlock({ text, initiallyExpanded = false }: CodeBlockProps) {
    const intl = useIntl();
    const lines = text.split('\n');
    const tooManyLines = lines.length > 8;
    const [expandCode, setExpandCode] = useState(initiallyExpanded);
    const [copied, setCopied] = useState(false);
    const [copyPasteButtonRef, copyPasteTooltip] = useTippy<HTMLButtonElement>(
        intl.formatMessage(
            copied ? m['copied-tooltip'] : m['copy-to-clipboard']
        ),
        {
            placement: 'top-end',
            hideOnClick: false
        }
    );
    const [collapseExpandButtonRef] = useTippy<HTMLButtonElement>(
        expandCode
            ? intl.formatMessage(m['collapse'])
            : intl.formatMessage(m['expand']),
        {
            placement: 'top-end'
        }
    );

    const toggleDropdown = () => {
        setExpandCode(!expandCode);
    };

    function copyToClipboard() {
        // by default tippy triggers when focus is removed, but we need to
        // select() our copy text, removing the focus
        // so we temporarily tell tippy to not watch focus events
        copyPasteTooltip?.setProps({
            trigger: 'mouseenter'
        });

        // to copy text you need to select a textarea
        const copyJSONOutput = document.createElement('textarea');
        copyJSONOutput.innerText = text;
        document.body.appendChild(copyJSONOutput);
        copyJSONOutput.select();
        document.execCommand('copy');
        copyJSONOutput.remove();

        // now we can refocus the button and re-enable focus events for the tip
        copyPasteButtonRef.current?.focus();
        copyPasteTooltip?.setProps({
            onHidden: () => setCopied(false),
            trigger: 'mouseenter focus'
        });

        setCopied(true);
    }

    return (
        <div
            className={cx(CODE_CONTAINER, {
                expanded: expandCode || !tooManyLines
            })}
        >
            <div className={BUTTON_WRAPPER}>
                {tooManyLines && (
                    <button
                        type="button"
                        ref={collapseExpandButtonRef}
                        onClick={toggleDropdown}
                    >
                        <Icon
                            className={ICON}
                            icon={expandCode ? 'Collapse' : 'Expand'}
                        />
                    </button>
                )}
                <button
                    type="button"
                    ref={copyPasteButtonRef}
                    onClick={copyToClipboard}
                >
                    <Icon className={COPY_PASTE_ICON} icon="Copy-Paste" />
                </button>
            </div>
            <pre>
                <code>
                    <ol>
                        {lines.map((line, i) => (
                            <li className={LIST_ITEM} key={i}>
                                {line}
                            </li>
                        ))}
                    </ol>
                </code>
            </pre>
        </div>
    );
}

export default CodeBlock;
