import React from 'react';
import { PageColumns, PageBody, Banner } from 'common/layout';
import { Switch } from 'router';
import { useMapView } from 'common/use-map-view';
import useData from 'utils/use-data';
import Loading from 'common/loading';
import gql from 'graphql-tag';
import HealthSummaryPage from './health-summary-page';
import { PAGE_NOT_FOUND } from 'strings';
import { FeedStatusesArgs, FeedStatusesData } from 'graphql.g';
import CommonPage from './common-page';

const FEED_STATUS_QUERY = gql`
    query FeedStatuses($mapviewSlug: String!) {
        feedStatuses(mapviewSlug: $mapviewSlug) {
            sourceId
            statusChangeStatus
            tripsStatus
            mdsVersion
            operator {
                id
                name
            }
        }
    }
`;

function NotFound() {
    return <Banner>{PAGE_NOT_FOUND}</Banner>;
}

function PageHealthReport() {
    const mapView = useMapView();
    const feedHealthData = useData<FeedStatusesData, FeedStatusesArgs>(
        FEED_STATUS_QUERY,
        {
            mapviewSlug: mapView.slug
        }
    );

    if (!feedHealthData)
        return (
            <CommonPage>
                <Loading kind="clear" />
            </CommonPage>
        );

    return (
        <CommonPage>
            <PageColumns>
                <PageBody>
                    <Switch
                        pages={[
                            {
                                path: '/',
                                exact: true,
                                component: HealthSummaryPage,
                                properties: {
                                    feedStatuses: feedHealthData.feedStatuses
                                }
                            },
                            {
                                component: NotFound
                            }
                        ]}
                    />
                </PageBody>
            </PageColumns>
        </CommonPage>
    );
}

export default PageHealthReport;
