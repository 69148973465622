import React, { useMemo } from 'react';
import { MapView } from 'types';
import { OverviewReport } from './report';
import {
    TableSection,
    Table,
    HeaderRow,
    AverageValue,
    Row,
    messages,
    HeaderProps
} from './tables';
import { useIntl } from 'utils/use-intl';
import { defineMessages, FormattedMessage } from 'react-intl';
import { LocalMonth, LocalWeek } from 'utils/date-tools';
import gql from 'graphql-tag';
import useData, { useReloadingData } from 'utils/use-data';
import Loading from 'common/loading';
import { aggregatedDescriptions } from '../constants/stat-descriptions';
import {
    AggregatedReportTripCountsFragment,
    AggregatedReportTripsPerVehicleFragment,
    AggregatedReportMaxVehiclesFragment,
    UNFINALIZED_OPERATOR_FRAGMENT
} from './fragments';
import { useMapView } from 'common/use-map-view';
import {
    AggregatedReportOverviewVehiclesData,
    AggregatedReportOverviewVehiclesArgs,
    AggregatedReportPeriod,
    AggregatedReportOverviewTripsData,
    AggregatedReportOverviewTripsArgs,
    AggregatedUnfinalizedOperatorsData,
    AggregatedUnfinalizedOperatorsArgs,
    UnfinalizedOperatorFragment
} from 'graphql.g';
import { useVehicleClass } from 'common/vehicle-classes';
import { doesReportHaveOperatorName } from './helpers';
import useDocumentTitle from 'common/use-document-title';
import { ContainerMessage } from 'common/layout';
import { useSorting } from 'common/table-sorting';
import orderBy from 'lodash/orderBy';
import zipWith from 'lodash/zipWith';

// --------- VEHICLES ---------

type VehiclesProps = {
    reportDate: LocalMonth | LocalWeek;
    mapView: MapView;
};

const vehiclesHeaderInfo = [
    aggregatedDescriptions.maxAvailable,
    aggregatedDescriptions.maxUnavailable,
    aggregatedDescriptions.maxParked
];
const vehiclesSortKeys = [
    'maxVehicles.averageMaxAvailable',
    'maxVehicles.averageMaxUnavailable',
    'maxVehicles.averageMaxParked'
];

const vehiclesHeaders: HeaderProps[] = zipWith(
    vehiclesHeaderInfo,
    vehiclesSortKeys,
    (a, b) => {
        return [a, b];
    }
);

const VEHICLES_QUERY = gql`
    query AggregatedReportOverviewVehicles(
        $slug: String
        $date: Date!
        $period: AggregatedReportPeriod!
        $vehicleClass: VehicleClass
    ) {
        mapView(slug: $slug) {
            id
            aggregatedOverviewReport(
                startDate: $date
                period: $period
                vehicleClass: $vehicleClass
            ) {
                id
                reportStartDate
                aggregatedReports {
                    id
                    operator {
                        name
                    }
                    ...AggregatedReportMaxVehicles
                }
            }
        }
    }
    ${AggregatedReportMaxVehiclesFragment}
`;

function AggregatedReportOverviewVehicles({
    mapView,
    reportDate
}: VehiclesProps) {
    const vehicleClass = useVehicleClass();
    const [data, loading] = useReloadingData<
        AggregatedReportOverviewVehiclesData,
        AggregatedReportOverviewVehiclesArgs
    >(VEHICLES_QUERY, {
        slug: mapView.slug,
        date:
            reportDate instanceof LocalMonth
                ? reportDate.first.toString()
                : reportDate.monday.toString(),
        period:
            reportDate instanceof LocalMonth
                ? AggregatedReportPeriod.monthly
                : AggregatedReportPeriod.weekly,
        vehicleClass
    });
    const [sorting, onSortHeaderClick] = useSorting<
        | 'operator.name'
        | 'maxVehicles.averageMaxAvailable'
        | 'maxVehicles.averageMaxUnavailable'
        | 'maxVehicles.averageMaxParked'
    >('operator.name');
    const mapViewData = data && data.mapView;
    const allReports = useMemo(() => {
        const list = mapViewData?.aggregatedOverviewReport?.aggregatedReports
            ? mapViewData.aggregatedOverviewReport.aggregatedReports
            : [];
        const existingIds = new Set<string>();
        const uniqueList = list.filter(aggregation => {
            if (!existingIds.has(aggregation.id)) {
                existingIds.add(aggregation.id);
                return true;
            }
            return false;
        });
        return orderBy(uniqueList, [...sorting.keys()], [...sorting.values()]);
    }, [sorting, mapViewData]);

    return (
        <>
            <Loading loading={loading} kind="over-table" />
            {allReports &&
            allReports.length !== 0 &&
            !allReports.every(element => element === null) ? (
                <Table>
                    <HeaderRow
                        headers={vehiclesHeaders}
                        sorting={sorting}
                        onSortHeaderClick={onSortHeaderClick}
                    />
                    <tbody>
                        {allReports
                            .filter(doesReportHaveOperatorName)
                            .map(aggReport => {
                                if (aggReport == null) {
                                    return null;
                                }
                                const maxVehicles = aggReport.maxVehicles;
                                const name =
                                    aggReport.operator &&
                                    aggReport.operator.name;
                                const id = aggReport.id;
                                const values = [
                                    maxVehicles &&
                                        maxVehicles.averageMaxAvailable,
                                    maxVehicles &&
                                        maxVehicles.averageMaxUnavailable,
                                    maxVehicles && maxVehicles.averageMaxParked
                                ];
                                return (
                                    <Row key={id} name={name} values={values} />
                                );
                            })}
                    </tbody>
                </Table>
            ) : (
                <ContainerMessage>
                    <FormattedMessage
                        key="no-vehicle-metrics"
                        defaultMessage="This report doesn't have any vehicle metrics."
                    />
                </ContainerMessage>
            )}
        </>
    );
}

// --------- TRIPS ---------

const tripsHeaderInfo = [
    aggregatedDescriptions.startTrips,
    aggregatedDescriptions.tripsPerVehicle
];

const tripsSortKeys = [
    'tripCounts.startCount',
    'tripsPerVehicle.tripsPerVehicle'
];

const tripsHeaders: HeaderProps[] = zipWith(
    tripsHeaderInfo,
    tripsSortKeys,
    (a, b) => {
        return [a, b];
    }
);

type TripsProps = {
    mapView: MapView;
    reportDate: LocalMonth | LocalWeek;
};

const TRIPS_QUERY = gql`
    query AggregatedReportOverviewTrips(
        $slug: String
        $date: Date!
        $period: AggregatedReportPeriod!
        $vehicleClass: VehicleClass
    ) {
        mapView(slug: $slug) {
            id
            aggregatedOverviewReport(
                startDate: $date
                period: $period
                vehicleClass: $vehicleClass
            ) {
                id
                reportStartDate
                aggregatedReports {
                    id
                    operator {
                        name
                    }
                    ...AggregatedReportTripCounts
                    ...AggregatedReportTripsPerVehicle
                    ...AggregatedReportMaxVehicles
                }
            }
        }
    }
    ${AggregatedReportTripCountsFragment}
    ${AggregatedReportTripsPerVehicleFragment}
    ${AggregatedReportMaxVehiclesFragment}
`;

function AggregatedReportOverviewTrips({ mapView, reportDate }: TripsProps) {
    const vehicleClass = useVehicleClass();
    const [data, loading] = useReloadingData<
        AggregatedReportOverviewTripsData,
        AggregatedReportOverviewTripsArgs
    >(TRIPS_QUERY, {
        slug: mapView.slug,
        date:
            reportDate instanceof LocalMonth
                ? reportDate.first.toString()
                : reportDate.monday.toString(),
        period:
            reportDate instanceof LocalMonth
                ? AggregatedReportPeriod.monthly
                : AggregatedReportPeriod.weekly,
        vehicleClass
    });
    const intl = useIntl();
    const [sorting, onSortHeaderClick] = useSorting<
        | 'operator.name'
        | 'tripCounts.startCount'
        | 'tripsPerVehicle.tripsPerVehicle'
    >('operator.name');
    const mapViewData = data && data.mapView;
    const allReports = useMemo(() => {
        const list = mapViewData?.aggregatedOverviewReport?.aggregatedReports
            ? mapViewData.aggregatedOverviewReport.aggregatedReports
            : [];
        const existingIds = new Set<string>();
        const uniqueList = list.filter(aggregation => {
            if (!existingIds.has(aggregation.id)) {
                existingIds.add(aggregation.id);
                return true;
            }
            return false;
        });
        return orderBy(uniqueList, [...sorting.keys()], [...sorting.values()]);
    }, [sorting, mapViewData]);

    const numberOfDays =
        reportDate instanceof LocalMonth ? reportDate.numberOfDays() : 7;

    const totalTripCountForAllOperators =
        allReports?.reduce(
            (value, report) => value + (report?.tripCounts?.startCount ?? 0),
            0
        ) ?? 0;

    const totalAverageMaxAvailableForAllOperators =
        allReports?.reduce(
            (value, report) =>
                value + (report?.maxVehicles?.averageMaxAvailable ?? 0),
            0
        ) ?? 0;

    const totalAverageTripsForAllOperators =
        totalTripCountForAllOperators / numberOfDays;

    const totalTripsPerVehicle =
        totalAverageTripsForAllOperators /
        totalAverageMaxAvailableForAllOperators;

    return (
        <>
            <Loading loading={loading} kind="over-table" />
            {allReports &&
            allReports.length !== 0 &&
            !allReports.every(element => element === null) ? (
                <Table>
                    <HeaderRow
                        headers={tripsHeaders}
                        sorting={sorting}
                        onSortHeaderClick={onSortHeaderClick}
                    />
                    <tbody>
                        {allReports
                            .filter(doesReportHaveOperatorName)
                            .map(aggReport => {
                                if (aggReport == null) {
                                    return null;
                                }
                                const name =
                                    aggReport.operator &&
                                    aggReport.operator.name;
                                const id = aggReport.id;
                                const values = [
                                    aggReport.tripCounts && (
                                        <AverageValue
                                            value={
                                                aggReport.tripCounts.startCount
                                            }
                                            average={
                                                aggReport.tripCounts
                                                    .averageStartCount
                                            }
                                        />
                                    ),
                                    aggReport?.tripsPerVehicle?.tripsPerVehicle
                                        ? intl.formatNumber(
                                              aggReport.tripsPerVehicle
                                                  .tripsPerVehicle,
                                              {
                                                  maximumFractionDigits: 1
                                              }
                                          )
                                        : null
                                ];
                                return (
                                    <Row
                                        timestamps
                                        key={id}
                                        name={name}
                                        values={values}
                                    />
                                );
                            })}
                        <Row
                            name={intl.formatMessage(messages['total-row'])}
                            values={[
                                intl.formatNumber(
                                    allReports.reduce(
                                        (value, aggReport) =>
                                            value +
                                            (aggReport?.tripCounts?.startCount
                                                ? aggReport.tripCounts
                                                      .startCount
                                                : 0),
                                        0
                                    )
                                ),
                                allReports
                                    ? intl.formatNumber(totalTripsPerVehicle, {
                                          maximumFractionDigits: 1
                                      })
                                    : 0
                            ]}
                        />
                    </tbody>
                </Table>
            ) : (
                <ContainerMessage>
                    <FormattedMessage
                        key="no-trip-metrics"
                        defaultMessage="This report doesn't have any trip metrics."
                    />
                </ContainerMessage>
            )}
        </>
    );
}

// --------- OVERVIEW ---------

type Props = {
    mapView: MapView;
    reportDate: LocalMonth | LocalWeek;
};

export const m = defineMessages({
    'monthly-vehicle-header': 'Vehicle Metrics for {month, date, reportmonth}',
    'monthly-trips-header': 'Trips Metrics for {month, date, reportmonth}',
    'weekly-vehicle-header':
        'Vehicle Metrics for {monday, date, dayOfWeekReportWeek}—{sunday, date, dayOfWeekReportWeek}',
    'weekly-trips-header':
        'Trips Metrics for {monday, date, dayOfWeekReportWeek}—{sunday, date, dayOfWeekReportWeek}',
    'weekly-document-title': 'Week of {monday, date, reportweek} · Ride Report',
    'monthly-document-title': '{month, date, reportmonth} · Ride Report'
});

const AGGREGATED_UNFINALIZED_OPERATORS_QUERY = gql`
    query AggregatedUnfinalizedOperators(
        $slug: String!
        $period: AggregatedReportPeriod!
        $date: Date!
    ) {
        mapView(slug: $slug) {
            aggregatedOverviewReport(period: $period, startDate: $date) {
                id
                aggregatedReports {
                    id
                    finalized
                    operator {
                        ...UnfinalizedOperator
                    }
                }
            }
        }
    }
    ${UNFINALIZED_OPERATOR_FRAGMENT}
`;

export function useAggregatedUnfinalizedOperators(
    reportDate: LocalWeek | LocalMonth
) {
    const { slug } = useMapView();
    const data = useData<
        AggregatedUnfinalizedOperatorsData,
        AggregatedUnfinalizedOperatorsArgs
    >(AGGREGATED_UNFINALIZED_OPERATORS_QUERY, {
        slug,
        date: reportDate.start.toString(),
        period:
            reportDate instanceof LocalMonth
                ? AggregatedReportPeriod.monthly
                : AggregatedReportPeriod.weekly
    });
    return (
        (data?.mapView.aggregatedOverviewReport?.aggregatedReports ?? [])
            .filter(report => report?.finalized === false)
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            .map(report => report!.operator as UnfinalizedOperatorFragment)
    );
}

function PageAggregatedSummary({ reportDate }: Props) {
    const mapView = useMapView();
    const intl = useIntl();

    const unfinalizedOperators = useAggregatedUnfinalizedOperators(reportDate);

    const title =
        reportDate instanceof LocalWeek
            ? intl.formatMessage(m['weekly-document-title'], {
                  monday: reportDate.monday.toDate()
              })
            : intl.formatMessage(m['monthly-document-title'], {
                  month: reportDate?.first.toDate()
              });

    useDocumentTitle(title);
    return (
        <OverviewReport
            reportDate={reportDate}
            unfinalizedOperators={unfinalizedOperators}
        >
            <TableSection
                title={
                    reportDate instanceof LocalMonth
                        ? intl.formatMessage(m['monthly-vehicle-header'], {
                              month: reportDate.first.toDate()
                          })
                        : intl.formatMessage(m['weekly-vehicle-header'], {
                              monday: reportDate.monday.toDate(),
                              sunday: reportDate.sunday.toDate()
                          })
                }
            >
                <AggregatedReportOverviewVehicles
                    mapView={mapView}
                    reportDate={reportDate}
                />
            </TableSection>
            <TableSection
                title={
                    reportDate instanceof LocalMonth
                        ? intl.formatMessage(m['monthly-trips-header'], {
                              month: reportDate.first.toDate()
                          })
                        : intl.formatMessage(m['weekly-trips-header'], {
                              monday: reportDate.monday.toDate(),
                              sunday: reportDate.sunday.toDate()
                          })
                }
            >
                <AggregatedReportOverviewTrips
                    mapView={mapView}
                    reportDate={reportDate}
                />
            </TableSection>
        </OverviewReport>
    );
}

export default PageAggregatedSummary;
