import {
    Container,
    ContainerHeader,
    DetailsContainer,
    ContainerMessage
} from 'common/layout';
import { OperatorTag, AllOperatorsTag } from 'common/tag';
import React from 'react';
import {
    defineMessages,
    FormattedDate,
    FormattedMessage,
    useIntl
} from 'react-intl';
import "/opt/build/repo/src/page-health/health-incident-log.tsx?resplendence=true";import rx from 'resplendence';
import cx from 'classnames';
import gql from 'graphql-tag';
import useData from 'utils/use-data';
import {
    IncidentState,
    IncidentReportsArgs,
    IncidentReportsData
} from 'graphql.g';
import { useMapView } from 'common/use-map-view';
import Loading from 'common/loading';
import { LocalDate, LocalMonth } from 'utils/date-tools';
import { useScrollIntoView } from 'utils/use-scroll-into-view';
import { MapView } from 'types';

/*
@import 'style.scss';
*/;

const INCIDENT_CONTAINER = "rx-page-health-health-incident-log-1"/*
    @include container;
    margin-bottom: 16rem;
*/;
const INCIDENT_HEADER = "rx-page-health-health-incident-log-2"/*
    margin: -1rem;
    padding: 10rem 24rem 10rem 16rem;
    border-radius: 6rem 6rem 0 0;
    color: $white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $gray-70;
    & > h3 > a {
        margin-right: 8rem;
    }
    &.timeline::before {
        content:'';
        display:block;
        width:0;
        height:0;
        position:absolute;
        border-top: 12rem solid transparent;
        border-bottom: 12rem solid transparent;
        border-right:12rem solid $gray-70;
        left:0rem;
        top:24rem;
    }
    &.investigating {
        background-color: $red-50;
        border: 1rem solid $red-50;
        border-right:12rem solid $red-50;
        &.timeline::before {
            border-right:12rem solid $red-50;
        }
    }
    &.resolved {
        background-color: $gray-50;
        border: 1rem solid $gray-50;
        border-right:12rem solid $gray-50;
        color: $gray-70;
        &.timeline::before {
            border-right:12rem solid $gray-50;
        }
    }
*/;

const INCIDENT_BODY = "rx-page-health-health-incident-log-3"/*
    padding: 16rem 24rem;
*/;

const INCIDENT_COLUMNS = "rx-page-health-health-incident-log-4"/*
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 24rem;
*/;

const INCIDENT_TITLE = "rx-page-health-health-incident-log-5"/*
    display: flex;
    align-items: center;
    > span {
        padding-right: 8rem;
    }
*/;

const INCIDENT_SUBHEADER = "rx-page-health-health-incident-log-6"/*
    @include text-body-bold;
*/;

const INCIDENT_NOTES = "rx-page-health-health-incident-log-7"/*
    padding-top: 16rem;
*/;

const DETAILS_HEADER = "rx-page-health-health-incident-log-8"/*
    display: inline-block;
    ~* Account for the dropdown icon *~
    width: calc(100% - 18rem);
*/;

const DETAILS_TITLE = "rx-page-health-health-incident-log-9"/*
    display: grid;
    grid-template-columns: 1fr 1fr;
*/;

const EXTRA_DETAILS = "rx-page-health-health-incident-log-10"/*
    border: none;
    border-top-left-radius: 0rem;
    border-top-right-radius: 0rem;
    border-top: 1rem solid $gray-30;
    & > summary {
        border-bottom: none;
        margin: 0rem;
        padding: 10rem 0rem;
    }
*/;
const m = defineMessages({
    Resolved: 'Resolved',
    Monitoring: 'Monitoring',
    Investigating: 'Investigating'
});

//Rather than defining Incident and risk drift from the graphql type, exract the Incident
//type from the graphql IncidentReportsData type. Make the incidentReports array nonnullable,
//grab the type of the first element in the incidentReports array (not the value of the first
//element), and make that element's type nonnullable.
type Incident = NonNullable<
    NonNullable<IncidentReportsData['incidentReports']>[0]
>;

type SectionProps = {
    status: 'Resolved' | 'Monitoring' | 'Investigating' | string;
    incident: Incident;
    inTimeline?: boolean;
};

function IncidentSection({
    status,
    incident,
    inTimeline = false
}: SectionProps) {
    const intl = useIntl();

    const mostRecentStatus = incident.statuses[0];
    const oldStatuses =
        incident.statuses.length > 1 ? incident.statuses.slice(1) : [];

    return (
        <div className={INCIDENT_CONTAINER}>
            <div
                className={cx(INCIDENT_HEADER, status, {
                    timeline: inTimeline
                })}
            >
                <span className={INCIDENT_TITLE}>
                    <span>
                        {incident.operator ? (
                            <OperatorTag
                                disableColor={true}
                                key={incident.operator.id}
                                name={incident.operator.name}
                            />
                        ) : (
                            <AllOperatorsTag />
                        )}
                    </span>
                    <h3>{incident.title}</h3>
                </span>
                <span>{intl.formatMessage(m[status])}</span>
            </div>
            <div className={INCIDENT_BODY}>
                <div className={INCIDENT_COLUMNS}>
                    <div>
                        <h4 className={INCIDENT_SUBHEADER}>
                            <FormattedMessage
                                key="updated"
                                defaultMessage={'Updated'}
                            />
                        </h4>
                        <FormattedDate
                            value={new Date(mostRecentStatus.date).getTime()}
                            year="numeric"
                            month="short"
                            day="2-digit"
                            hour="numeric"
                            minute="numeric"
                            timeZoneName="short"
                        />
                        <div className={INCIDENT_NOTES}>
                            {mostRecentStatus.notes}
                        </div>
                    </div>
                    <div>
                        <h4 className={INCIDENT_SUBHEADER}>
                            <FormattedMessage
                                key="affects"
                                defaultMessage={'Affects'}
                            />
                            :
                        </h4>
                        <div>{incident.components.join(', ')}</div>
                    </div>
                </div>
            </div>
            {oldStatuses.map(oldStatus => (
                <DetailsContainer
                    id={oldStatus.id}
                    key={oldStatus.id}
                    open={false}
                    classname={EXTRA_DETAILS}
                    summary={
                        <h3 className={DETAILS_HEADER}>
                            <span className={DETAILS_TITLE}>
                                {intl.formatMessage(m[oldStatus.state])}
                                <span>
                                    <FormattedDate
                                        value={new Date(
                                            oldStatus.date
                                        ).getTime()}
                                        year="numeric"
                                        month="short"
                                        day="2-digit"
                                        hour="numeric"
                                        minute="numeric"
                                        timeZoneName="short"
                                    />
                                </span>
                            </span>
                        </h3>
                    }
                >
                    <div className={INCIDENT_COLUMNS}>
                        {oldStatus.notes ? oldStatus.notes : ''}
                        <div>
                            <h4 className={INCIDENT_TITLE}>
                                <FormattedMessage
                                    key="affects"
                                    defaultMessage={'Affects'}
                                />
                                :
                            </h4>
                            <div>{incident.components.join(', ')}</div>
                        </div>
                    </div>
                </DetailsContainer>
            ))}
        </div>
    );
}

const TIMELINE_BODY = rx('ul', "rx-page-health-health-incident-log-11")/*
    list-style-type: none;
    &:last-child {
        margin-bottom: 0;
    }
    > li {
        position: relative;
        padding: 10rem;
    }
    > li:before {
        content: '';
        width: 8rem;
        height: 8rem;
        border-radius: 50%;
        background: $blue-50;
        position: absolute;
        left: -15rem;
        top: 32rem;
        z-index: 2;

    }
    > li.single-event:before {
        transform: translateY(-14rem);
    }
    > li.month {
        @include text-label;
        color: $gray-60;
        font-size: 1em;
    }
    > li.month:before {
        background: none;
    }
    > li:after {
        content: '';
        width: 1rem;
        height: calc(100% + 48rem);
        background: $gray-30;
        position: absolute;
        left: -12rem;
        top: 0;
        bottom: -100rem;
        margin-top: -24rem;
        margin-bottom: 24rem;
    }
*/;

const INCIDENT_QUERY = gql`
    query IncidentReports($slug: String!, $state: IncidentState!) {
        incidentReports(mapviewSlug: $slug, state: $state) {
            id
            title
            operator {
                id
                name
            }
            components
            statuses {
                id
                state
                date
                notes
            }
        }
    }
`;

function convertDateToMonthString(incident: Incident): string {
    return LocalDate.fromDateString(incident.statuses[0].date)
        .toLocalMonth()
        .toString();
}

function monthIsSame(incident: Incident, month: string): boolean {
    return convertDateToMonthString(incident) === month;
}

export function useOpenIncidentsData(
    mapView: MapView
): IncidentReportsData | null {
    return useData<IncidentReportsData, IncidentReportsArgs>(INCIDENT_QUERY, {
        slug: mapView.slug,
        state: IncidentState.unresolved
    });
}

function IncidentLog() {
    const mapView = useMapView();
    const openIncidentsData = useOpenIncidentsData(mapView);

    const closedIncidentData = useData<
        IncidentReportsData,
        IncidentReportsArgs
    >(INCIDENT_QUERY, {
        slug: mapView.slug,
        state: IncidentState.resolved
    });
    const [incidentsRef] = useScrollIntoView(
        openIncidentsData && closedIncidentData ? 'incidents' : null
    );

    //Get the set of months associated with the closed incidents
    // and map the incidents onto those months.
    const closedIncidentsByMonth = [
        ...new Set(
            closedIncidentData?.incidentReports?.map(incident =>
                incident?.statuses[0].date
                    ? convertDateToMonthString(incident)
                    : null
            )
        )
    ].map(monthYear =>
        monthYear
            ? {
                  month: monthYear,
                  incidents: closedIncidentData?.incidentReports?.filter(
                      incident =>
                          incident ? monthIsSame(incident, monthYear) : false
                  )
              }
            : null
    );

    return (
        <>
            {/* Open Incidents */}
            <Container ref={incidentsRef}>
                <ContainerHeader el="h2">
                    <FormattedMessage
                        key="open-incidents"
                        defaultMessage="Open Incidents"
                    />
                </ContainerHeader>
                <Loading kind="over-table" loading={!openIncidentsData} />
                {(openIncidentsData?.incidentReports?.length ?? 0) > 0 ? (
                    openIncidentsData?.incidentReports?.map(incident =>
                        incident ? (
                            <IncidentSection
                                status={incident.statuses[0].state}
                                key={incident.id}
                                incident={incident}
                            />
                        ) : null
                    )
                ) : (
                    <ContainerMessage>
                        <FormattedMessage
                            key="no-current-incidents"
                            defaultMessage="There are no open incidents."
                        />
                    </ContainerMessage>
                )}
            </Container>

            {/* Past Incidents */}
            <Container>
                <ContainerHeader el="h2">
                    <FormattedMessage
                        key="past-incidents"
                        defaultMessage="Past Incidents"
                    />
                </ContainerHeader>
                <Loading kind="over-table" loading={!closedIncidentData} />
                {closedIncidentsByMonth.length > 0 ? (
                    closedIncidentsByMonth.map(monthIncidents =>
                        monthIncidents ? (
                            <TIMELINE_BODY key={monthIncidents.month}>
                                <li
                                    key={monthIncidents.month}
                                    className="month"
                                >
                                    {
                                        <FormattedDate
                                            value={LocalMonth.fromString(
                                                monthIncidents.month
                                            ).first.toDate()}
                                            year="numeric"
                                            month="long"
                                        />
                                    }
                                </li>
                                {monthIncidents.incidents?.map(incident =>
                                    incident ? (
                                        <li key={incident.id}>
                                            <IncidentSection
                                                status={'Resolved'}
                                                key={incident.id}
                                                incident={incident}
                                                inTimeline={true}
                                            />
                                        </li>
                                    ) : null
                                )}
                            </TIMELINE_BODY>
                        ) : null
                    )
                ) : (
                    <ContainerMessage>
                        <FormattedMessage
                            key="no-past-incidents"
                            defaultMessage="There are no past incidents."
                        />
                    </ContainerMessage>
                )}
            </Container>
        </>
    );
}

export default IncidentLog;
