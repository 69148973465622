import React from 'react';
import { OperatorName } from 'constants/operators';
import { ReportSummaryInfoProps, ReportSummaryInfo } from './tables';
import { PageBody, OperatorBanner, AgencyBanner } from 'common/layout';
import { ReportDate } from './report-picker';
import { useMapView } from 'common/use-map-view';
import DailyNav from './report-nav-daily';
import WeeklyNav from './report-nav-weekly';
import MonthlyNav from './report-nav-monthly';
import { LocalDate, LocalWeek } from 'utils/date-tools';
import {
    useVehicleClass,
    useVehicleClasses,
    VehicleClassQueryStatePicker
} from 'common/vehicle-classes';
import Loading from 'common/loading';
import "/opt/build/repo/src/page-report/report.tsx?resplendence=true";
import { AreaBanner } from './report-area-banner';
import { AoiInfoFragment } from 'graphql.g';
import { FormattedList, FormattedMessage } from 'react-intl';
import Callout from 'common/callout';
import { AbsoluteLink } from 'router';
import { UnfinalizedOperatorFragment } from 'graphql.g';

/*
@import 'style.scss';
*/;

const NAV_CONTAINER = "rx-page-report-report-1"/*
    @include container;
    padding: 24rem;
    display: flex;
    col-gap: 8rem;
*/;

const VEHICLE_CLASS_PICKER = "rx-page-report-report-2"/*
    width: 220rem;
    margin-right: 8rem;
*/;

type OperatorInfo = {
    name: OperatorName;
    operatorId: string;
};

type Props = {
    header: any;
    children?: any;
    operator?: OperatorInfo;
    reportDate: ReportDate;
    unfinalizedOperators: UnfinalizedOperatorFragment[];
    reportFound?: boolean;
};

function Report({
    header,
    children,
    operator,
    reportDate,
    unfinalizedOperators,
    reportFound = true
}: Props) {
    const mapView = useMapView();
    const vehicleClasses = useVehicleClasses(operator?.operatorId);
    const expectedFinalizationDate = reportDate.end.plusDays(3);
    const selectedVehicleClass = useVehicleClass();

    return (
        <PageBody>
            {header}
            <nav className={NAV_CONTAINER}>
                <Loading loading={!vehicleClasses} />
                {vehicleClasses != null && vehicleClasses.length > 1 && (
                    <div className={VEHICLE_CLASS_PICKER}>
                        <VehicleClassQueryStatePicker
                            vehicleClasses={vehicleClasses}
                        />
                    </div>
                )}
                {reportDate instanceof LocalDate ? (
                    <DailyNav date={reportDate} />
                ) : reportDate instanceof LocalWeek ? (
                    <WeeklyNav week={reportDate} />
                ) : (
                    <MonthlyNav month={reportDate} />
                )}
            </nav>
            {reportFound === false ? (
                <Callout color="gray">
                    {reportDate instanceof LocalDate ? (
                        <FormattedMessage
                            key="no-report-local-date"
                            defaultMessage="No report was found for {vehicle}s on {date, date}."
                            values={{
                                date: reportDate.toDate(),
                                vehicle: selectedVehicleClass
                            }}
                        />
                    ) : reportDate instanceof LocalWeek ? (
                        <FormattedMessage
                            key="no-report-local-week"
                            defaultMessage="No report was found for {vehicle}s in the week of {startDate, date}-{endDate, date}."
                            values={{
                                startDate: reportDate.start.toDate(),
                                endDate: reportDate.end.toDate(),
                                vehicle: selectedVehicleClass
                            }}
                        />
                    ) : (
                        <FormattedMessage
                            key="no-report-local-month"
                            defaultMessage="No report was found for {vehicle}s in {month}."
                            values={{
                                month: reportDate.end
                                    .toDate()
                                    .toLocaleString('default', {
                                        month: 'long'
                                    }),
                                vehicle: selectedVehicleClass
                            }}
                        />
                    )}
                </Callout>
            ) : (
                unfinalizedOperators.length > 0 &&
                (LocalDate.todayInTz(mapView.ianaTimezone).isAfter(
                    expectedFinalizationDate
                ) ? (
                    <Callout color="red">
                        <FormattedMessage
                            key="late-to-finalize"
                            defaultMessage="We were unable to produce finalized data for {operators}. We are investigating and will update the <Link>incident log</Link> when we have located the issue."
                            values={{
                                Link: msg => (
                                    <AbsoluteLink
                                        to={`/${mapView.slug}/health#incidents`}
                                    >
                                        {msg}
                                    </AbsoluteLink>
                                ),
                                operators: (
                                    <FormattedList
                                        value={unfinalizedOperators.map(
                                            op => op.name
                                        )}
                                        style="long"
                                        type="conjunction"
                                    />
                                ),
                                numOperators: unfinalizedOperators.length
                            }}
                        />
                    </Callout>
                ) : (
                    <Callout color="blue">
                        <FormattedMessage
                            key="preliminary"
                            defaultMessage="This data is preliminary and may be missing events that haven't been reported yet. The finalized report will be available on {date, date, localdayofmonth}."
                            values={{
                                date: expectedFinalizationDate.toDate()
                            }}
                        />
                    </Callout>
                ))
            )}
            {children}
        </PageBody>
    );
}

type OperatorReportProps = {
    children: React.ReactNode;
    headerInfo?: ReportSummaryInfoProps | null;
    operator: OperatorInfo;
    reportDate: ReportDate;
    finalized: boolean;
};
export function OperatorReport({
    headerInfo,
    operator,
    finalized,
    ...props
}: OperatorReportProps) {
    return (
        <Report
            operator={operator}
            unfinalizedOperators={
                finalized
                    ? []
                    : [{ name: operator.name, id: operator.operatorId }]
            }
            {...props}
            header={
                <OperatorBanner name={operator.name}>
                    {headerInfo && <ReportSummaryInfo {...headerInfo} />}
                </OperatorBanner>
            }
        />
    );
}

type OverviewReportProps = {
    reportDate: ReportDate;
    children: React.ReactNode;
    unfinalizedOperators: UnfinalizedOperatorFragment[];
};

export function OverviewReport({ ...props }: OverviewReportProps) {
    const mapView = useMapView();
    return <Report {...props} header={<AgencyBanner name={mapView.name} />} />;
}

type AreaReportProps = {
    reportDate: ReportDate;
    area: AoiInfoFragment;
    children: React.ReactNode;
    unfinalizedOperators: UnfinalizedOperatorFragment[];
};

export function AreaReport({ area, ...props }: AreaReportProps) {
    return <Report {...props} header={<AreaBanner area={area} />} />;
}

export default Report;
