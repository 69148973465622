import React from 'react';
import "/opt/build/repo/src/page-policy/form-area-map.tsx?resplendence=true";
import { AoiInfoFragment } from 'graphql.g';
import AreaMap, { useAreaGeometry } from 'common/area-map';

/*
@import 'style.scss';
*/;

const CONTAINER = "rx-page-policy-form-area-map-1"/*
    position: relative;
    height: 100%;
    margin-top: 1rem;
*/;

const MAP_CONTAINER = "rx-page-policy-form-area-map-2"/*
    @include container;
    display: grid;
    height: 100%;
    overflow: hidden;
    @include mobile {
        min-height: 500rem;
    }
*/;

interface FormAreaMapProps {
    area: AoiInfoFragment | null;
}

function FormAreaMap({ area }: FormAreaMapProps) {
    const areaGeometry = useAreaGeometry(area?.slug);

    return (
        <div className={CONTAINER}>
            <AreaMap
                area={
                    areaGeometry === 'not found' || areaGeometry === 'loading'
                        ? null
                        : areaGeometry
                }
                loading={areaGeometry === 'loading'}
                className={MAP_CONTAINER}
            />
        </div>
    );
}

export default FormAreaMap;
