import React, { useEffect, useState } from 'react';
import { useRef } from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import "/opt/build/repo/src/page-health/page-trip-filter-included-pct.tsx?resplendence=true";
import cx from 'classnames';
/*
@import 'style';

@keyframes page_trip_filter_included_pct_load {
    from {
        left: -82rem;
    }
    to   {
        left: 100%;
    }
}
*/;

const TOP = "rx-page-health-page-trip-filter-included-pct-1"/*
    @include text-label-bold;
    white-space: nowrap;
*/;
const BOTTOM = "rx-page-health-page-trip-filter-included-pct-2"/*
    @include text-label-bold;
    text-align: right;
    color: $gray-70;
    height: 19.2rem;
    white-space: nowrap;
*/;
const BOTTOM_VALUE = "rx-page-health-page-trip-filter-included-pct-3"/*
    color: $gray-90;
*/;
const FAILED = "rx-page-health-page-trip-filter-included-pct-4"/*
    @include text-label-bold;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 144rem;
    color: $gray-70;
*/;
const VALUE = "rx-page-health-page-trip-filter-included-pct-5"/*
    height: 4rem;
    background-color: $gray-50;
    border-radius: 2rem;
    position: relative;
    overflow: hidden;
    --value: 0%;
    &:before, &:after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        height: 100%;
    }
    &:before { // the bar for the current value
        transition: transform 600ms cubic-bezier(0.4, 0.0, 0.2, 1), background  350ms;
        left: -100%;
        background: $blue-50;
        width: 100%;
        transform: translate(var(--value));
    }
    &:after { // the animated loading bar
        content: '';
        left: -82rem;
        width: 82rem;
        background: transparent;
        transition: background 350ms;
    }
    &.loading {
        background-color: $gray-30;
        &:after {
            background: linear-gradient(to right, transparent 0%, $gray-50 50%, transparent 100%);
            @media (prefers-reduced-motion: no-preference) {
                animation: page_trip_filter_included_pct_load 1s cubic-bezier(0.4, 0.0, 0.2, 1) infinite;
            }
        }
        &:before {
            background: transparent;
        }
    }
*/;

type Props = {
    value: number | 'loading' | Error | null;
};

function IncludedPercent({ value }: Props) {
    // In order to animate the bar so it moves from its old location to the new
    // one, we need to remember where it last was before it started loading.
    const lastValue = useRef(typeof value === 'number' ? value : 1);
    if (typeof value === 'number') lastValue.current = value;
    else if (value === null) lastValue.current = 1;

    // If the bar is only loading for a fraction of a second, it's distracting
    // for it to keep flickering between the loading and loaded states. We can
    // set a timeout so that we only actually display it as loading if it is
    // loading for more than 350ms
    const [loading, setLoading] = useState(value === 'loading');
    useEffect(() => {
        if (value === 'loading') {
            if (!loading) {
                const timeout = setTimeout(() => setLoading(true), 350);
                return () => {
                    clearTimeout(timeout);
                };
            }
        } else {
            setLoading(false);
        }
    }, [loading, value]);

    if (value instanceof Error) {
        return (
            <div className={FAILED}>
                <FormattedMessage
                    key="failed"
                    defaultMessage="Loading data failed."
                />
            </div>
        );
    }
    return (
        <div>
            <div className={TOP}>
                {loading ? (
                    <FormattedMessage
                        key="calculating"
                        defaultMessage="Calculating…"
                    />
                ) : value === null ? (
                    <FormattedMessage
                        key="no-data"
                        defaultMessage="No data available"
                    />
                ) : (
                    <FormattedNumber
                        value={1 - lastValue.current}
                        style="percent"
                        maximumSignificantDigits={2}
                    />
                )}
            </div>
            <div
                className={cx(VALUE, { loading })}
                style={
                    {
                        '--value': (1 - lastValue.current) * 100 + '%'
                    } as any
                }
            />
            <div className={BOTTOM}>
                {value === null
                    ? '--'
                    : !loading && (
                          <FormattedMessage
                              key="excluded"
                              defaultMessage="excluded: {percent}"
                              values={{
                                  percent: (
                                      <span className={BOTTOM_VALUE}>
                                          <FormattedNumber
                                              value={lastValue.current}
                                              style="percent"
                                              maximumSignificantDigits={2}
                                          />
                                      </span>
                                  )
                              }}
                          />
                      )}
            </div>
        </div>
    );
}

export default IncludedPercent;
