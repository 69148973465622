import { CreatePolicyInput, PolicyType, RuleType, Day } from 'graphql.g';
import { NewPolicy } from 'page-policy/page-new';
import { AreaOfInterest, MapView, MapViewOperator } from 'types';

/**
 * Returns a list of the available operators for a given area, by id.
 * If the area is not operator specific, it will return all the mapview operator
 * ids.
 */
function getAvailableOperatorIds(
    mapviewOperators: MapViewOperator[],
    area: AreaOfInterest | null
) {
    const operatorIds = mapviewOperators.map(op => {
        if (op == null || op.operatorId == null) {
            throw new Error(
                'Operator should not be null and should have a non-null ID.'
            );
        }
        return op.operatorId;
    });

    return area && area.operatorIds.length > 0
        ? operatorIds.filter(id => area.operatorIds.includes(id))
        : operatorIds;
}

/**
 * Creates a policy to save to the backend, with defaults set if they were not
 * filled in on the form.
 */
function generatePolicy(
    policy: NewPolicy,
    mapView: MapView
): CreatePolicyInput {
    // A lot of this is setting defaults. We want to show policy and rule names
    // that make sense based on the policyType, area, and city it's for.

    // set some defaults that we'll override below
    let policyName: string = 'Policy Name';
    let ruleName: string = 'Policy Rule';
    let ruleType: RuleType = RuleType.count;

    // this lets us create more descriptive Rule names like:
    // "Spin Vehicle Cap", or "Spin, Lime Trip Start Fee"
    let providerNames = '';
    if (policy.providers.length > 0) {
        providerNames = policy.providers.map(p => p.name).join(', ');
    }

    // this lets us create more descriptive Policy names like:
    // "Downtown Zone Trip Start Fee" or "City Parks No Parking Zone"
    // if they selected all areas, then we'll use the mapview name, so we get:
    // "Portland Trip Start Fee", etc..
    let policyNamePrefix = policy.area ? policy.area.name : mapView.name;

    if (policy.policyType === PolicyType.no_parking_zone) {
        policyName = `${policyNamePrefix} No Parking Zone`;
        ruleName = `${providerNames} No Parking Zone`;
        ruleType = RuleType.count;
        policy.maximum = 0;
    }

    if (policy.policyType === PolicyType.no_ride_zone) {
        policyName = `${policyNamePrefix} No Ride Zone`;
        ruleName = `${providerNames} No Ride Zone`;
        ruleType = RuleType.count;
        policy.maximum = 0;
    }

    if (policy.policyType === PolicyType.trip_start) {
        policyName = `${policyNamePrefix} Trip Start Fee`;
        ruleName = `${providerNames} Trip Start Fee`;
        ruleType = RuleType.rate;
    }

    if (policy.policyType === PolicyType.vehicle_cap) {
        policyName = `${policyNamePrefix} Vehicle Cap`;
        ruleName = `${providerNames} Vehicle Cap`;
        ruleType = RuleType.count;
    }

    if (policy.policyType === PolicyType.vehicle_cap_percentage) {
        policyName = `${policyNamePrefix} Vehicle Cap Percentage`;
        ruleName = `${providerNames} Vehicle Cap Percentage`;
        ruleType = RuleType.count;
    }

    if (policy.policyType === PolicyType.minimum_deployment) {
        policyName = `${policyNamePrefix} Minimum Deployment`;
        ruleName = `${providerNames} Minimum Deployment`;
        ruleType = RuleType.count;
    }

    if (policy.policyType === PolicyType.minimum_deployment_percentage) {
        policyName = `${policyNamePrefix} Minimum Deployment Percentage`;
        ruleName = `${providerNames} Minimum Deployment Percentage`;
        ruleType = RuleType.count;
    }

    if (policy.policyType === PolicyType.speed_limit) {
        policyName = `${policyNamePrefix} Speed Zone`;
        ruleName = `${providerNames} Speed Zone`;
        ruleType = RuleType.speed;
    }

    let days = [...policy.days];
    if (
        days.length === 7 &&
        days.includes(Day.monday) &&
        days.includes(Day.tuesday) &&
        days.includes(Day.wednesday) &&
        days.includes(Day.thursday) &&
        days.includes(Day.friday) &&
        days.includes(Day.saturday) &&
        days.includes(Day.sunday)
    ) {
        // if all days are included, we want to save an empty array
        // to signify all days
        days = [];
    }

    return {
        mapviewId: mapView.id,
        // if they didn't set a policy name, we'll use our default
        name: policy.name === '' ? policyName : policy.name,
        description: policy.description,
        operatorIds: policy.providers.map(p => p.operatorId),
        startDate: policy.startDate.toString(),
        endDate: policy.endDate != null ? policy.endDate.toString() : undefined,
        policyType: policy.policyType,
        rules: [
            {
                areaIds: policy.area ? [policy.area.id] : [],
                name: ruleName,
                ruleType,
                days,
                vehicleClasses: policy.vehicleClass,
                maximum: policy.maximum,
                minimum: policy.minimum,
                // send the startTime and endTime in ISO 8601 Time (hh:mm:ss)
                startTime: policy.startTime
                    ? `${policy.startTime[0]}:${policy.startTime[1]}:00`
                    : undefined,
                endTime: policy.endTime
                    ? `${policy.endTime[0]}:${policy.endTime[1]}:00`
                    : undefined,
                // we need to store the rate amount in cents
                rateAmount: policy.rateAmount
                    ? policy.rateAmount * 100
                    : undefined
            }
        ]
    };
}

export { getAvailableOperatorIds, generatePolicy };
