/**
 * Generic date-picker components.
 *
 * I chose to roll my own instead of using a library because I found that after
 * having implemented our date-tools library that the custom code was simpler
 * than the many workarounds I needed to do to make the libraries have the
 * desired appearance and functionality.
 */

import React, { useState, useEffect } from 'react';
import { defineMessages, FormattedDate, FormattedMessage } from 'react-intl';
import { useIntl } from 'utils/use-intl';
import "/opt/build/repo/src/common/date-picker.tsx?resplendence=true";
import { LocalDate, LocalMonth } from 'utils/date-tools';
import Icon from './icon';
import cx from 'classnames';
import { DropdownSelect } from './dropdown-select';
import { Button } from 'common/layout';

/*
@import "style";
@import "./date-picker-styles";
*/;

const NAVBAR = "rx-common-date-picker-1"/*
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    padding: 0 8rem;
*/;

const NAVBAR_LINK = "rx-common-date-picker-2"/*
    @include transition;
    @include text-label;
    border-radius: 6rem;
    width: 32rem;
    height: 32rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1rem solid $gray-30;
    box-sizing: border-box;
    color: $gray-70;
    font-size: 20rem;
    opacity: 0;
    cursor: default;
    &:not(:disabled) {
        opacity: 1;
        @include shadow-2;
        &:hover {
            @include shadow-hover;
        }
    }
*/;

const MONTHLY_NAV_BUTTONS = "rx-common-date-picker-3"/*
    display: grid;
    grid-auto-flow: column;
    grid-gap: 8rem;
*/;

const DATE_BUTTONS = "rx-common-date-picker-4"/*
    margin-top: 16rem;
    margin-bottom: 16rem;
    display: flex;
    gap: 16rem;
    flex-wrap: wrap;

    button {
        color: $gray-70;
    }
*/;
type CalendarNavBarProps = {
    /** The currently selected month/year/whatever */
    label: any;
    /** When you click the left arrow */
    onPreviousClick?: () => void;
    /** When you click the right arrow */
    onNextClick?: () => void;
    setEarliestMonthOnClick?: () => void;
    setLatestMonthOnClick?: () => void;
    showEarliestBtn?: boolean;
    showLatestBtn?: boolean;
};

/** The month or year left/right navigator for a calendar */
export function CalendarNavBar({
    label,
    onPreviousClick,
    onNextClick,
    setEarliestMonthOnClick,
    setLatestMonthOnClick,
    showEarliestBtn,
    showLatestBtn
}: CalendarNavBarProps) {
    return (
        <div className={NAVBAR}>
            <div className={MONTHLY_NAV_BUTTONS}>
                {showEarliestBtn && (
                    <button
                        type="button"
                        disabled={!setEarliestMonthOnClick}
                        onClick={setEarliestMonthOnClick}
                        className={NAVBAR_LINK}
                    >
                        <Icon icon="Double-Left" />
                    </button>
                )}
                <button
                    type="button"
                    disabled={!onPreviousClick}
                    onClick={onPreviousClick}
                    className={NAVBAR_LINK}
                >
                    <Icon icon="Left" />
                </button>
            </div>
            {label}
            <div className={MONTHLY_NAV_BUTTONS}>
                <button
                    type="button"
                    disabled={!onNextClick}
                    onClick={onNextClick}
                    className={NAVBAR_LINK}
                >
                    <Icon icon="Right" />
                </button>
                {showLatestBtn && (
                    <button
                        disabled={!setLatestMonthOnClick}
                        type="button"
                        onClick={setLatestMonthOnClick}
                        className={NAVBAR_LINK}
                    >
                        <Icon icon="Double-Right" />
                    </button>
                )}
            </div>
        </div>
    );
}

type CalendarMonthNavProps = {
    /** the currently selected month */
    month: LocalMonth;
    /** a state setter for the currently selected month */
    onChange: (month: LocalMonth) => void;
    start?: LocalMonth;
    end?: LocalMonth;
    onClick?: (clicked: LocalDate) => void;
};
/** The month selector for a standard datepicker */
export function CalendarMonthNav({
    month,
    onChange: onChangeMonth,
    start,
    end,
    onClick
}: CalendarMonthNavProps) {
    const setToEarliestMonth = () => {
        if (onClick && start) {
            onChangeMonth(start);
        }
    };
    const setToLatestMonth = () => {
        if (onClick && end) {
            onChangeMonth(end);
        }
    };

    return (
        <CalendarNavBar
            label={
                <FormattedDate
                    value={month.first.toDate()}
                    year="numeric"
                    month="long"
                />
            }
            onNextClick={
                end && month.toString() >= end.toString()
                    ? undefined
                    : () => onChangeMonth(month.plusMonths(1))
            }
            onPreviousClick={
                start && month.toString() <= start.toString()
                    ? undefined
                    : () => onChangeMonth(month.minusMonths(1))
            }
            setEarliestMonthOnClick={
                start && month.isAfter(start) ? setToEarliestMonth : undefined
            }
            setLatestMonthOnClick={
                end && month.isBefore(end) ? setToLatestMonth : undefined
            }
            showEarliestBtn={!!start}
            showLatestBtn={!!end}
        />
    );
}

const messages = defineMessages({
    monday: 'Mo',
    tuesday: 'Tu',
    wendesday: 'We',
    thursday: 'Th',
    friday: 'Fr',
    saturday: 'Sa',
    sunday: 'Su'
});

const WEEKDAY_CELL = "rx-common-date-picker-5"/*
    @include calendar-cell;
    color: $gray-60;
*/;

const weekdays = [
    messages.monday,
    messages.tuesday,
    messages.wendesday,
    messages.thursday,
    messages.friday,
    messages.saturday,
    messages.sunday
];

/** The row of weekdays at the top of the calendar */
export function Weekdays() {
    const intl = useIntl();
    return (
        <>
            {weekdays.map(message => (
                <div key={message.id} className={WEEKDAY_CELL}>
                    {intl.formatMessage(message)}
                </div>
            ))}
        </>
    );
}

const DATE_RANGE_CONTAINER = "rx-common-date-picker-6"/*
    display: flex;
    flex-flow: row nowrap;
*/;
const PRELIMINARY_DATES = "rx-common-date-picker-7"/*
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 0 7rem;
    svg {
        margin-right: 8rem;
        color: $yellow-50;
    }
    @include text-label;
*/;

const DATE_RANGE_CALENDAR = "rx-common-date-picker-8"/*
    &:not(:first-child) {
        padding-left: 5rem;
    }
    &:not(:last-child) {
        padding-right: 5rem;
        border-right: 1rem solid $gray-30;
    }
*/;

const DATE_RANGE_CALENDAR_GRID = "rx-common-date-picker-9"/*
    @include calendar;
    @include calendar-grid;
    padding: 13rem;
*/;
const DATE_RANGE_DAYS_OF_WEEK = "rx-common-date-picker-10"/*
    @include calendar-grid;
*/;
const DATE_RANGE_DAY = "rx-common-date-picker-11"/*
    @include calendar-cell;
*/;
const DATE_RANGE_SELECTED = "rx-common-date-picker-12"/*
    margin: 0 -5rem;
    background-color: $gray-30;
    &.left {
        margin-left: 0;
        border-top-left-radius: 16rem;
        border-bottom-left-radius: 16rem;
    }
    &.right {
        margin-right: 0;
        border-top-right-radius: 16rem;
        border-bottom-right-radius: 16rem;
    }
    &.continue-right, &.continue-left {
        border-radius: 0;
    }
    @mixin continue-gradient($direction) {
        background: linear-gradient(to $direction, $gray-30, fade-out($gray-30, 1));
    }
    &.continue-left {
        @include continue-gradient(left);
    }
    &.continue-right {
        @include continue-gradient(right);
    }
*/;

/** A start date and an end date, inclusive */
export type DateRange = [LocalDate, LocalDate];
type PartialDateRange = DateRange | [LocalDate, null];

type DateRangePickerCalendarProps = {
    selectedDateRange: PartialDateRange;
    onClick: (clicked: LocalDate) => void;
    hovered: LocalDate | null;
    onHover: (hovered: LocalDate | null) => void;
    month: LocalMonth;
    onChangeMonth: (month: LocalMonth) => void;
    first?: boolean;
    latestAllowed?: LocalDate;
    earliestAllowed?: LocalDate;
    singleDate?: boolean;

    /** A set of dates (as strings) that should be marked as preliminary */
    preliminaryDates?: Set<string>;
};

function DateRangePickerCalendar({
    selectedDateRange,
    onClick,
    hovered,
    onHover,
    month,
    onChangeMonth,
    first,
    earliestAllowed,
    latestAllowed,
    singleDate = false,
    preliminaryDates
}: DateRangePickerCalendarProps) {
    let firstValue = selectedDateRange[0];
    let secondValue = selectedDateRange[1] || hovered || selectedDateRange[0];
    if (firstValue > secondValue) {
        firstValue = secondValue;
        secondValue = selectedDateRange[0];
    }
    const selectedDays = firstValue.range(secondValue);
    return (
        <div className={DATE_RANGE_CALENDAR}>
            <CalendarMonthNav
                month={month}
                onChange={onChangeMonth}
                start={
                    singleDate
                        ? undefined
                        : first
                        ? earliestAllowed?.toLocalMonth()
                        : month
                }
                end={
                    singleDate
                        ? undefined
                        : first
                        ? month
                        : latestAllowed?.toLocalMonth()
                }
                onClick={onClick}
            />
            <div className={DATE_RANGE_DAYS_OF_WEEK}>
                <Weekdays />
            </div>
            <div className={DATE_RANGE_CALENDAR_GRID}>
                {/* The gray selection background. Manually positioned on the grid.
                    Only show if selecting a date range. */}
                {!singleDate &&
                    month.toWeeks().map((week, r) =>
                        week.toDays().map((day, c) => {
                            const selected = !!selectedDays.find(x =>
                                x.equals(day)
                            );
                            let continueLeft = false;
                            let continueRight = false;
                            if (!day.toLocalMonth().equals(month)) {
                                if (selected) {
                                    if (
                                        day.day === 1 &&
                                        day.isAfter(firstValue)
                                    ) {
                                        continueRight = true;
                                    } else if (
                                        day.plusDays(1).day === 1 &&
                                        day.isBefore(secondValue)
                                    ) {
                                        continueLeft = true;
                                    } else {
                                        return null;
                                    }
                                }
                            }
                            if (selected) {
                                const left = day.equals(firstValue);
                                const right = day.equals(secondValue);
                                return (
                                    <div
                                        key={day.day}
                                        className={cx(DATE_RANGE_SELECTED, {
                                            left,
                                            right,
                                            'continue-left': continueLeft,
                                            'continue-right': continueRight
                                        })}
                                        style={{
                                            gridRow: r + 1,
                                            gridColumn: c + 1
                                        }}
                                    />
                                );
                            }
                            return null;
                        })
                    )}
                {/* The day numbers. Manually positioned on the grid. */}
                {month.toWeeks().map((week, r) =>
                    week.toDays().map((day, c) =>
                        day.toLocalMonth().equals(month) ? (
                            <button
                                type="button"
                                key={day.day}
                                disabled={
                                    (latestAllowed &&
                                        day.isAfter(latestAllowed)) ||
                                    (earliestAllowed &&
                                        day.isBefore(earliestAllowed))
                                }
                                className={cx(DATE_RANGE_DAY, {
                                    active: !!selectedDateRange.find(
                                        d => d && day.equals(d)
                                    ),
                                    hover: hovered
                                        ? hovered.equals(day)
                                        : false,
                                    preliminary:
                                        preliminaryDates &&
                                        preliminaryDates.has(day.toString())
                                })}
                                onMouseOver={() => {
                                    onHover(day);
                                }}
                                onClick={() => onClick(day)}
                                style={{
                                    gridRow: r + 1,
                                    gridColumn: (c % 7) + 1
                                }}
                            >
                                {day.day}
                            </button>
                        ) : null
                    )
                )}
            </div>
        </div>
    );
}

type DatePickerCalendarProps = {
    selectedDate: LocalDate;
    onClick: (clicked: LocalDate) => void;
    latestAllowed?: LocalDate;
    earliestAllowed?: LocalDate;
};

function DatePickerCalendar({
    selectedDate,
    onClick,
    earliestAllowed,
    latestAllowed
}: DatePickerCalendarProps) {
    const [hovered, setHovered] = useState<LocalDate | null>(null);
    const [month, setMonth] = useState(selectedDate.toLocalMonth());

    return (
        <div className={DATE_RANGE_CALENDAR}>
            <CalendarMonthNav
                month={month}
                onChange={setMonth}
                start={earliestAllowed?.toLocalMonth()}
                end={latestAllowed?.toLocalMonth()}
                onClick={onClick}
            />
            <div className={DATE_RANGE_CALENDAR_GRID}>
                {/* The day numbers. Manually positioned on the grid. */}
                {month.toWeeks().map((week, r) =>
                    week.toDays().map((day, c) =>
                        day.toLocalMonth().equals(month) ? (
                            <button
                                type="button"
                                key={day.day}
                                disabled={
                                    (latestAllowed &&
                                        day.isAfter(latestAllowed)) ||
                                    (earliestAllowed &&
                                        day.isBefore(earliestAllowed))
                                }
                                className={cx(DATE_RANGE_DAY, {
                                    active: day.equals(selectedDate),
                                    hover: hovered ? hovered.equals(day) : false
                                })}
                                onMouseOver={() => {
                                    setHovered(day);
                                }}
                                onClick={() => onClick(day)}
                                style={{
                                    gridRow: r + 1,
                                    gridColumn: (c % 7) + 1
                                }}
                            >
                                {day.day}
                            </button>
                        ) : null
                    )
                )}
            </div>
        </div>
    );
}

type DateRangePickerProps = {
    selectedDateRange: DateRange;
    onChange: (selectedDateRange: DateRange) => void;
    latestAllowed?: LocalDate;
    earliestAllowed?: LocalDate;

    /** A set of dates (as strings) that should be marked as preliminary */
    preliminaryDates?: Set<string>;
};
export function DateRangePicker({
    selectedDateRange,
    onChange,
    latestAllowed,
    earliestAllowed,
    preliminaryDates
}: DateRangePickerProps) {
    const [clicked, setClicked] = useState<LocalDate | null>(null);
    const [hovered, setHovered] = useState<LocalDate | null>(null);
    const [month, setMonth] = useState(selectedDateRange[1].toLocalMonth());
    const onClick = (selectedDateRange: LocalDate) => {
        if (clicked) {
            setClicked(null);
            onChange(
                clicked > selectedDateRange
                    ? [selectedDateRange, clicked]
                    : [clicked, selectedDateRange]
            );
        } else {
            setClicked(selectedDateRange);
        }
    };
    useEffect(() => {
        if (earliestAllowed && selectedDateRange[0].isBefore(earliestAllowed)) {
            const newDefaultLatest =
                latestAllowed &&
                earliestAllowed.plusDays(90).isAfter(latestAllowed)
                    ? latestAllowed
                    : earliestAllowed.plusDays(90);
            const endDateForLatestChange = selectedDateRange[1].isBefore(
                earliestAllowed
            )
                ? newDefaultLatest
                : selectedDateRange[1];
            onChange([earliestAllowed, endDateForLatestChange]);
        } else if (
            latestAllowed &&
            selectedDateRange[1].isAfter(latestAllowed)
        ) {
            const newDefaultEarliest =
                earliestAllowed &&
                latestAllowed.minusDays(90).isBefore(earliestAllowed)
                    ? earliestAllowed
                    : latestAllowed.minusDays(90);
            const startDateForLatestChange = selectedDateRange[0].isAfter(
                latestAllowed
            )
                ? newDefaultEarliest
                : selectedDateRange[0];
            onChange([startDateForLatestChange, latestAllowed]);
        }
    }, [earliestAllowed, latestAllowed, onChange, selectedDateRange]);
    const commonProps = {
        onClick,
        hovered,
        onHover: setHovered,
        latestAllowed,
        earliestAllowed,
        preliminaryDates,
        selectedDateRange: (clicked
            ? [clicked, null]
            : selectedDateRange) as PartialDateRange
    };
    return (
        <div>
            <div className={DATE_RANGE_CONTAINER}>
                {latestAllowed &&
                earliestAllowed &&
                latestAllowed
                    .toLocalMonth()
                    .equals(earliestAllowed.toLocalMonth()) ? null : (
                    <DateRangePickerCalendar
                        {...commonProps}
                        first
                        month={month.minusMonths(1)}
                        onChangeMonth={month => setMonth(month.plusMonths(1))}
                    />
                )}
                <DateRangePickerCalendar
                    {...commonProps}
                    month={month}
                    onChangeMonth={setMonth}
                />
            </div>
            {preliminaryDates && preliminaryDates.size > 0 && (
                <PreliminaryDataKey />
            )}
        </div>
    );
}

export function PreliminaryDataKey() {
    return (
        <div className={PRELIMINARY_DATES}>
            <Icon icon="Circle" />
            <FormattedMessage
                key="preliminary-data-key"
                defaultMessage="Preliminary data"
            />
        </div>
    );
}

type DateRangeSelectProps = {
    /** The currently selected date range */
    selectedDateRange: DateRange;

    /** A setter for the currently selected date range */
    onChange: (selectedDateRange: DateRange) => void;

    /** The latest date the user is allowed to select */
    latestAllowed?: LocalDate;

    /** The earliest date the user is allowed to select */
    earliestAllowed?: LocalDate;

    /** A set of dates (as strings) that should be marked as preliminary */
    preliminaryDates?: Set<string>;

    disabled?: boolean;

    position?: 'bottom-left' | 'bottom-right';
};

/** A select dropdown for a choosing a range of dates */
export function DateRangeSelect({
    selectedDateRange,
    onChange,
    disabled,
    position,
    ...props
}: DateRangeSelectProps) {
    const [selectActive, setSelectActive] = useState(false);
    const setSelectedDateRange = (value: DateRange) => {
        setSelectActive(false);
        onChange(value);
    };
    return (
        <DropdownSelect
            position={position}
            disabled={disabled}
            icon="Calendar"
            active={selectActive}
            setActive={setSelectActive}
            value={
                <FormattedMessage
                    key="range-select-label"
                    defaultMessage="{start, date, reportweek}—{end, date, reportweek}"
                    values={{
                        start: selectedDateRange[0].toDate(),
                        end: selectedDateRange[1].toDate()
                    }}
                />
            }
        >
            <DateRangePicker
                selectedDateRange={selectedDateRange}
                onChange={setSelectedDateRange}
                {...props}
            />
        </DropdownSelect>
    );
}

type DateSelectProps = {
    /** The currently selected date */
    selectedDate: LocalDate | null;
    /** A setter for the currently selected date */
    onChange: (selectedDate: LocalDate) => void;
    /** The latest date the user is allowed to select */
    latestAllowed?: LocalDate;
    /** The earliest date the user is allowed to select */
    earliestAllowed?: LocalDate;
    /** If this control is disabled */
    disabled?: boolean;
};

/** A select dropdown for a choosing a single date */
export function DateSelect({
    selectedDate,
    onChange,
    latestAllowed,
    earliestAllowed,
    disabled
}: DateSelectProps) {
    const [selectActive, setSelectActive] = useState(false);

    return (
        <DropdownSelect
            disabled={disabled}
            icon="Calendar"
            active={selectActive}
            setActive={setSelectActive}
            value={
                selectedDate ? (
                    <FormattedMessage
                        key="date-select-label"
                        defaultMessage="{value, date, reportweek}"
                        values={{
                            value: selectedDate.toDate()
                        }}
                    />
                ) : (
                    <FormattedMessage
                        key="null-date"
                        defaultMessage="-- -- --"
                    />
                )
            }
        >
            {selectedDate && (
                <DatePickerCalendar
                    selectedDate={selectedDate}
                    onClick={clickedDate => {
                        setSelectActive(false);
                        onChange(clickedDate);
                    }}
                    latestAllowed={latestAllowed}
                    earliestAllowed={earliestAllowed}
                />
            )}
        </DropdownSelect>
    );
}

function getDateButtons(
    earliestDate: LocalDate | null
): {
    name: string;
    value: DateRange;
}[] {
    const dateButtons = [
        {
            name: '1W',
            value: [
                LocalDate.fromDate(new Date()).minusDays(7),
                LocalDate.fromDate(new Date()).minusDays(1)
            ] as DateRange
        },
        {
            name: '1M',
            value: [
                LocalDate.fromDate(new Date()).minusDays(28),
                LocalDate.fromDate(new Date()).minusDays(1)
            ] as DateRange
        },
        {
            name: '3M',
            value: [
                LocalDate.fromDate(new Date()).minusDays(90),
                LocalDate.fromDate(new Date()).minusDays(1)
            ] as DateRange
        },
        {
            name: '6M',
            value: [
                LocalDate.fromDate(new Date()).minusDays(180),
                LocalDate.fromDate(new Date()).minusDays(1)
            ] as DateRange
        },
        {
            name: '1YR',
            value: [
                LocalDate.fromDate(new Date()).minusDays(365),
                LocalDate.fromDate(new Date()).minusDays(1)
            ] as DateRange
        }
    ];

    const allTimeButton = {
        name: 'All Time',
        value: [
            earliestDate,
            LocalDate.fromDate(new Date()).minusDays(1)
        ] as DateRange
    };
    return earliestDate ? [...dateButtons, allTimeButton] : dateButtons;
}
interface DateButtonsProps {
    earliestDate: LocalDate | null;
    selectedDateRange: DateRange;
    setSelectedDateRange: (range: DateRange) => void;
}
export function DateButtons({
    selectedDateRange,
    earliestDate,
    setSelectedDateRange
}: DateButtonsProps) {
    const dateButtons = getDateButtons(earliestDate);
    return (
        <div className={DATE_BUTTONS}>
            {dateButtons.map(dateButton => (
                <Button
                    key={dateButton.name}
                    color={
                        // use a primary button if it's the selected date
                        selectedDateRange[0].equals(dateButton.value[0]) &&
                        selectedDateRange[1].equals(dateButton.value[1])
                            ? 'blue'
                            : 'gray'
                    }
                    onClick={() => {
                        setSelectedDateRange(dateButton.value);
                    }}
                >
                    {dateButton.name}
                </Button>
            ))}
        </div>
    );
}

/** Shared container styles for the DatePicker and DateButton combo  */
export const DATE_PICKER_CONTAINER = "rx-common-date-picker-13"/*
    display: flex;
    flex-flow: row nowrap;
    > * {
        flex: 1 0 auto;
        margin: 8rem 8rem 0 0;
    }
    > button {
        flex: 0 0 auto;
    }
    margin: -8rem -8rem 0 0;
*/;

const DATE_TEXT_ONLY = "rx-common-date-picker-14"/*
    display: flex;
    align-items: center;
    padding: 6rem;
    padding-left: 36rem;
    position: relative;
    white-space: nowrap;
    svg {
        position: absolute;
        font-size: 24rem;
        top: calc(50% - 24rem / 2);
        left: 7rem;
        pointer-events: none;
        color: $blue-50;
    }
*/;

type DateRangeTextOnlyProps = {
    selectedDateRange: DateRange;
};

/** Displays a minimal text-only version of the date, used when not allowing users to pick a custom date range through the calendar date-picker */
export function DateRangeTextOnly({
    selectedDateRange
}: DateRangeTextOnlyProps) {
    return (
        <div className={DATE_TEXT_ONLY}>
            <Icon icon="Calendar" />
            <FormattedMessage
                key="date-range-label"
                defaultMessage="{start, date, reportweek}—{end, date, reportweek}"
                values={{
                    start: selectedDateRange[0].toDate(),
                    end: selectedDateRange[1].toDate()
                }}
            />
        </div>
    );
}
