import Icon from 'common/icon';
import { OperatorName, OPERATOR_NAMES } from 'constants/operators';
import { AnalyzeData } from './types';
import React, { useState } from 'react';
import {
    Area,
    AreaChart,
    CartesianGrid,
    Legend,
    ReferenceArea,
    Tooltip,
    XAxis,
    YAxis
} from 'recharts';
import { Payload as LegendPayload } from 'recharts/types/component/DefaultLegendContent';
import { BaseAxisProps } from 'recharts/types/util/types';
import "/opt/build/repo/src/page-analyze/multi-line-graph.tsx?resplendence=true";
import { LocalDate } from 'utils/date-tools';
import {
    convertToSlug,
    getDataColor,
    getOperatorColorStyle
} from 'utils/helpers';
import { useIntl } from 'utils/use-intl';
import {
    AnalyzeChartWrapper,
    AnalyzeTooltip,
    formatTickOnYAxis
} from './chart-util';
import { DOT, AXIS_LABELS, ANIMATION_DURATION } from 'common/common-chart';

/*
@import '../style.scss';
*/;

const LEGEND_WRAPPER = "rx-page-analyze-multi-line-graph-1"/*
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
*/;

const LEGEND_ITEMS = "rx-page-analyze-multi-line-graph-2"/*
    display:flex;
    align-items: center;
    padding: 0rem 12rem;
    p {
        padding: 4rem 8rem;
        font-size: 12rem;
    }
*/;

type LegendProps = {
    payload: LegendPayload[];
    seriesNames: string[];
    onMouseEnter: (
        event: React.MouseEvent<HTMLDivElement>,
        index: number
    ) => void;
    onMouseLeave: (event: React.MouseEvent<HTMLDivElement>) => void;
};

const AnalyzeLegend = ({
    payload,
    seriesNames,
    onMouseEnter,
    onMouseLeave
}: LegendProps) => {
    return (
        <div className={LEGEND_WRAPPER}>
            {payload.map((entry, index: number) => {
                return (
                    <div
                        className={LEGEND_ITEMS}
                        onMouseEnter={e => onMouseEnter(e, index)}
                        onMouseLeave={e => onMouseLeave(e)}
                        key={`item-${index}`}
                    >
                        <Icon
                            icon="Circle"
                            className={DOT}
                            style={{ color: entry.color }}
                        />
                        <p>{seriesNames[index]}</p>
                    </div>
                );
            })}
        </div>
    );
};

type Props = {
    data: AnalyzeData[];
    seriesNames: string[];
    showLegend?: boolean;
    preliminaryDates?: Set<string>;
};

const LineChart = ({
    data,
    seriesNames,
    showLegend = false,
    preliminaryDates
}: Props) => {
    const intl = useIntl();

    const unitOfMeasurement = data[0]?.yUnit;

    const lines = data && transformRawData(data);

    const allUnitTypesShown = data.map(d => d.yUnit);

    const formatTickOnXAxis: BaseAxisProps['tickFormatter'] = tick => {
        const date = LocalDate.fromDateString(tick);
        return intl.formatDate(date.toDate(), {
            format: 'chartday'
        });
    };

    const [activeLine, setActiveLine] = useState<number | null>(null);

    const handleMouseEnter = (event, index) => {
        setActiveLine(index);
    };
    const handleMouseLeave = () => {
        setActiveLine(null);
    };

    const dataLineAttributes = getDataLineAttributes(data, activeLine);

    const preliminaryDatesReferenceArea = preliminaryDates
        ? Array.from(preliminaryDates).map(date => {
              const preliminaryDate = LocalDate.fromDateString(date);

              return (
                  <ReferenceArea
                      key={preliminaryDate.toString()}
                      x1={preliminaryDate.toString()}
                      x2={preliminaryDate.plusDays(1).toString()}
                      fill={'rgba(255, 199, 0, 0.25)'}
                      fillOpacity={0.6}
                  />
              );
          })
        : null;
    return (
        <AnalyzeChartWrapper>
            <AreaChart
                data={lines}
                margin={{
                    // A top margin here keeps the cartesian grid equal, and yAxis values round/even numbers.
                    top: 10,
                    right: 10,
                    left: 20,
                    bottom: 0
                }}
            >
                <defs>
                    {dataLineAttributes.map(dataLine => (
                        <linearGradient
                            key={dataLine.id}
                            id={`gradient-${dataLine.id}`}
                            x1="0"
                            y1="0"
                            x2="0"
                            y2="1"
                        >
                            <stop
                                offset="5%"
                                stopColor={dataLine.operatorColor}
                                stopOpacity={0.8}
                            />
                            <stop
                                offset="75%"
                                stopColor={dataLine.operatorColor}
                                stopOpacity={0}
                            />
                        </linearGradient>
                    ))}
                </defs>
                <CartesianGrid stroke="#ebeef0" vertical={false} />
                {/* Preliminary data is represented with a highlighted region.*/}
                {preliminaryDatesReferenceArea}
                {dataLineAttributes.map(dataLine => (
                    <Area
                        type="monotone"
                        key={dataLine.id}
                        dataKey={dataLine.id}
                        name={dataLine.name}
                        unit={dataLine.unit}
                        stroke={
                            activeLine !== null
                                ? dataLine.activeStrokeColor
                                : dataLine.operatorColor
                        }
                        strokeWidth={2}
                        strokeOpacity={
                            activeLine !== null
                                ? dataLine.activeStrokeOpacity
                                : 1
                        }
                        strokeDasharray={dataLine.showDashStroke}
                        animationDuration={ANIMATION_DURATION}
                        activeDot={{
                            strokeWidth: 4,
                            stroke: dataLine.operatorColor
                        }}
                        fillOpacity={0.5}
                        fill={`url(#gradient-${dataLine.id})`}
                    />
                ))}
                <XAxis
                    dataKey="date"
                    axisLine={false}
                    tickLine={false}
                    padding={{ right: 10 }}
                    interval={'preserveStartEnd'}
                    tickFormatter={formatTickOnXAxis}
                    style={AXIS_LABELS}
                    minTickGap={15}
                />
                <YAxis
                    orientation="right"
                    axisLine={false}
                    tickLine={false}
                    padding={{ bottom: 20 }}
                    domain={[0, 'auto']}
                    tickFormatter={(tick, index) =>
                        formatTickOnYAxis(tick, index, unitOfMeasurement, intl)
                    }
                    style={AXIS_LABELS}
                />
                <Tooltip<number, string>
                    label={unitOfMeasurement}
                    isAnimationActive={false}
                    animationDuration={ANIMATION_DURATION}
                    content={({ active, payload, label }) =>
                        active &&
                        payload && (
                            <AnalyzeTooltip
                                active={active}
                                payload={payload}
                                label={label}
                                unitsShown={allUnitTypesShown}
                            />
                        )
                    }
                />
                {showLegend && (
                    <Legend
                        content={({ payload }) =>
                            payload && (
                                <AnalyzeLegend
                                    payload={payload}
                                    seriesNames={seriesNames}
                                    onMouseEnter={handleMouseEnter}
                                    onMouseLeave={handleMouseLeave}
                                />
                            )
                        }
                    />
                )}
            </AreaChart>
        </AnalyzeChartWrapper>
    );
};

export default LineChart;

type DataLineAttributes = {
    id: number;
    name: OperatorName;
    unit: string;
    previousName: string;
    showDashStroke: string | undefined;
    operatorColor: string;
    activeStrokeOpacity: number;
    activeStrokeColor: string;
    gradient: string;
}[];
function getDataLineAttributes(
    data: AnalyzeData[],
    activeLine: number | null
): DataLineAttributes {
    const dataLines: DataLineAttributes = [];
    for (const index in data) {
        const i = Number(index);
        const line = data[i];
        const name = line?.dataLine?.group as OperatorName;
        const unit = line?.yUnit;
        const previousName = data[i - 1]?.dataLine?.group;
        const showDashStroke =
            previousName !== 'All Operators' && name === previousName
                ? '4 1'
                : undefined;

        const operatorColor =
            name && OPERATOR_NAMES.includes(name)
                ? getOperatorColorStyle(name).backgroundColor
                : getDataColor(i).backgroundColor;

        const gradient =
            name && OPERATOR_NAMES.includes(name)
                ? `url(#gradient-${convertToSlug(name)})`
                : `url(#gradient-${i})`;

        const activeStrokeOpacity =
            activeLine !== i || activeLine === null ? 0.2 : 1;

        const activeStrokeColor =
            activeLine !== i || activeLine === null
                ? '#667380' //$gray-70
                : operatorColor;

        const lineAttributes = {
            id: i,
            name,
            unit,
            previousName,
            showDashStroke,
            operatorColor,
            activeStrokeOpacity,
            activeStrokeColor,
            gradient
        };
        dataLines.push(lineAttributes);
    }
    return dataLines;
}

const transformRawData = (data: AnalyzeData[] | undefined) => {
    /*
        We query the back end for each requested metric seperately.
        To display multiple lines using ReCharts we have to take the data for each line in AnalyzeData.dataLine.dataPoints
        and combine them into a single object by Date to display each line on the chart.
    */
    if (!data) {
        return [];
    }

    const valuesByDate: Record<string, Record<number, number | null>> = {};

    for (const index in data) {
        const line = data[index];
        for (const point of line.dataLine.dataPoints) {
            if (!(point.xValue in valuesByDate)) {
                valuesByDate[point.xValue] = {};
            }
            valuesByDate[point.xValue][index] = point.yValue;
        }
    }
    return Object.keys(valuesByDate)
        .sort()
        .map(date => {
            const result: {
                date: string;
                [lineIndex: number]: number | null;
            } = { date };
            for (const index in data) {
                result[index] = valuesByDate[date][index] ?? null;
            }
            return result;
        });
};
