import React from 'react';
import { PageContent, PageBody } from 'common/layout';
import { Switch } from 'router';
import ListPage from './page-list';
import DetailsPage from './page-details';

interface CommonInvoice {
    __typename: string;
    id: string;
    startDate: string;
    operatorId: string;
}
interface IssuedInvoice extends CommonInvoice {
    __typename: 'IssuedInvoice';
    total: number;
}

interface FailedInvoice extends CommonInvoice {
    __typename: 'FailedInvoice';
    reason: null | string;
}

interface PendingInvoice extends CommonInvoice {
    __typename: 'PendingInvoice';
    expectedIssueDate: null | string;
}

export type Invoice = IssuedInvoice | FailedInvoice | PendingInvoice;

function FeesPage() {
    return (
        <PageContent>
            <PageBody>
                <Switch
                    pages={[
                        {
                            path: '/:operator/:date',
                            component: DetailsPage
                        },
                        {
                            path: '/',
                            exact: true,
                            component: ListPage
                        },
                        {
                            redirectTo: '/'
                        }
                    ]}
                />
            </PageBody>
        </PageContent>
    );
}

export default FeesPage;
