import React from 'react';
import "/opt/build/repo/src/common/table-search-input.tsx?resplendence=true";
import Icon from './icon';

/*
@import 'style';
*/;

const WRAPPER = "rx-common-table-search-input-1"/*
    position: relative;
    margin-top: 24rem;
    @media print {
        display: none;
    }
    align-self: stretch;
*/;
const MAGNIFYING_GLASS = "rx-common-table-search-input-2"/*
    position: absolute;
    left: 0;
    top: calc(50% - 12rem);
    font-size: 24rem;
*/;
const AREA_SEARCH = "rx-common-table-search-input-3"/*
    @include forms-text-field;
    width: 100%;
    padding-left: 48rem;
    margin: 0 -12rem;
    &:not(:focus):not(:hover) {
        border-color: transparent;
    }
*/;

type AreaSearchProps = {
    value: string;
    onChange: (value: string) => void;
    placeholder: string;
};

function TableSearchInput({ value, onChange, placeholder }: AreaSearchProps) {
    return (
        <div className={WRAPPER}>
            <Icon className={MAGNIFYING_GLASS} icon="Search" />
            <input
                type="text"
                className={AREA_SEARCH}
                value={value}
                placeholder={placeholder}
                aria-label={placeholder}
                onChange={e => onChange(e.target.value)}
            />
        </div>
    );
}

export default TableSearchInput;
