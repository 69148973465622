import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router';

export const useScrollIntoView = <T extends HTMLElement>(id: string | null) => {
    const target = useLocation().hash.slice(1);
    const ref = useRef<T>(null);
    const matched = id === target;
    useEffect(() => {
        if (matched) {
            ref.current?.scrollIntoView({ block: 'start' });
        }
    }, [matched]);
    return [ref, matched] as const;
};
