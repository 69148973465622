import React, { useEffect, useState } from 'react';
import "/opt/build/repo/src/common/select-number-field.tsx?resplendence=true";
import Icon from 'common/icon';
import cx from 'classnames';
import { GRAPHQL_MAX_INT, GRAPHQL_MIN_INT } from 'graphql';

/*
@import 'style';
*/;

const WRAPPER = "rx-common-select-number-field-1"/*
    height: 60rem;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-end;
    flex: 0 1 104rem;
*/;

const LABEL = "rx-common-select-number-field-2"/*
    @include text-label;
    color: $gray-70;
    margin-bottom: 3rem;
    &.disabled {
        display: none;
    }
*/;

const SELECT_NUMBER_FIELD = "rx-common-select-number-field-3"/*
    border: 1rem solid $gray-30;
    border-radius: 6rem;
    height: 36rem;
    &:hover {
        border-color: $gray-60;
    }
    &:focus-within {
        border-color: $blue-50;
    }
    > input {
        font-size: inherit;

        padding: 12rem 0 12rem 12rem;
        border: none;
        &::-webkit-inner-spin-button, &::-webkit-outer-spin-button {
            -webkit-appearance: none;
        }
        appearance: none;
        -moz-appearance: textfield;
        width: 100%;
        position: relative;
        z-index: 1;
        &:disabled {
            display: none;
        }
        background: none;
        min-width: 32rem;
    }
    .value {
        white-space: nowrap;
    }
    display: flex;
    position: relative;
    flex-flow: row nowrap;
    > select {
        opacity: 0;
        position: absolute;
        left: 0;
        top: 0;
        height:100%;
        width: 100%;
        text-align: right;
        right: 0;
        background-color: transparent;
    }
    > .value {
        display: flex;
        height: 100%;
        justify-content: space-between;
        align-items: center;
        svg {
            color: $gray-60;
            font-size: 24rem;
        }
    }
    > select:hover + .value svg {
        color: $gray-90;
    }
    > select:focus + .value svg {
        color: $blue-50;
    }
    > input:disabled ~ .value {
        width: 100%;
        margin-left: 12rem;
        color: $gray-60;
    }
*/;

type Value<T> = { number: number; selection: T };

type Props<T extends string> = {
    options: { key: T; formattedName: string; shortFormattedName: string }[];
    /**
     * The name of the `null` option in the select dropdown. When this option is
     * selected, the number field is disabled.
     */
    nullName: string;
    placeholder: string;
    value: Value<T> | null;
    id: string;
    onChange: (value: Value<T> | null) => void;
    min?: number;
    max?: number;
    label?: React.ReactNode;
    /** If true, position the element as though the label is there, even if it isn't */
    leaveSpaceForLabel?: boolean;
};

/**
 * A number field paired with a select field
 *
 * Used for pairing a number with the units that number is in.
 */
function SelectNumberField<T extends string>({
    options,
    value,
    id,
    placeholder,
    onChange,
    nullName,
    label,
    leaveSpaceForLabel,
    min,
    max
}: Props<T>) {
    const [numberEmpty, setNumberEmpty] = useState(false);

    let number = value?.number ?? 0;
    if (min !== undefined) {
        number = Math.max(min, number);
    } else {
        number = Math.max(GRAPHQL_MIN_INT, number);
    }
    if (max !== undefined) {
        number = Math.min(max, number);
    } else {
        number = Math.min(GRAPHQL_MAX_INT, number);
    }
    useEffect(() => {
        if (value && value.number !== number) {
            onChange({ ...value, number });
        }
    }, [number, onChange, value]);

    const field = (
        <div className={SELECT_NUMBER_FIELD}>
            <input
                id={id}
                type="number"
                value={numberEmpty ? '' : number}
                disabled={value === null}
                placeholder={placeholder}
                min={min}
                max={max}
                onChange={e => {
                    if (e.target.value) {
                        if (value) {
                            onChange({
                                ...value,
                                number: e.target.value
                                    ? parseInt(e.target.value)
                                    : 0
                            });
                        }
                        setNumberEmpty(false);
                    } else {
                        setNumberEmpty(true);
                    }
                }}
                onBlur={e => {
                    if (!e.target.value && value) {
                        onChange(null);
                    }
                }}
            />
            <select
                value={value?.selection ?? ''}
                onChange={e => {
                    onChange(
                        e.target.value
                            ? {
                                  number,
                                  selection: e.target.value as T
                              }
                            : null
                    );
                    if (e.target.value) {
                        setNumberEmpty(false);
                    }
                }}
            >
                <option value="">{nullName}</option>
                {options.map(opt => (
                    <option key={opt.key} value={opt.key}>
                        {opt.formattedName}
                    </option>
                ))}
            </select>
            <span className="value" aria-hidden="true">
                <span>
                    {value
                        ? options.find(opt => opt.key === value.selection)
                              ?.shortFormattedName
                        : nullName}
                </span>
                <Icon icon="Down" />
            </span>
        </div>
    );
    if (label || leaveSpaceForLabel) {
        return (
            <div className={WRAPPER}>
                {label && (
                    <label
                        className={cx(LABEL, { disabled: value === null })}
                        htmlFor={id}
                    >
                        {label}
                    </label>
                )}
                {field}
            </div>
        );
    }
    return field;
}

export default SelectNumberField;
