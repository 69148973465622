import React, { useState } from 'react';
import "/opt/build/repo/src/page-custom-data/color-picker.tsx?resplendence=true";
import { useRef } from 'react';
import { useEffect } from 'react';
import Icon from 'common/icon';
import cx from 'classnames';
import { Button } from 'common/layout';
import textColorForBackgroundColor from 'common/text-color-for-bg-color';

/*
@import 'style';
*/;

const COLOR_PALLETTE = "rx-page-custom-data-color-picker-1"/*
    position: absolute;
    background-color: $white;
    z-index: 1;
    border: 1rem solid $gray-30;
    border-radius: 6rem;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(40px, 1fr));
    max-width: 280rem;
    padding: 8rem;
*/;

const SWATCH = "rx-page-custom-data-color-picker-2"/*
    cursor: pointer;
    width: 25rem;
    height: 25rem;
    border-radius: 50%;
    margin-right: 8rem;
    pointer-events: auto;
    &.isPalette {
        margin: 8rem;
        transition: transform .25s ease;
        &:hover {
            transform: scale(1.3);
        }
    }
*/;

const ICON = "rx-page-custom-data-color-picker-3"/*
    color: $white;
    font-size: 25rem;
*/;

interface ColorPickerProps {
    activeColor: string;
    setActiveColor: (color: string) => void;
    paletteOptions: string[];
}

const ColorPicker = ({
    activeColor,
    setActiveColor,
    paletteOptions
}: ColorPickerProps) => {
    const colorRef = useRef<HTMLDivElement>(null);
    const [showColorPalette, setShowColorPalette] = useState(false);

    const handleChange = (color: string) => {
        setActiveColor(color);
        setShowColorPalette(false);
    };

    const handleClick = ({ target }) => {
        if (colorRef && colorRef.current && colorRef.current.contains(target)) {
            // inside the color picker - do nothing
            return;
        }
        // outside of the color picker - close it
        setShowColorPalette(false);
    };

    useEffect(() => {
        // add an event listener on click so we can close the color palette
        // when the user click outside of it.
        document.addEventListener('click', handleClick);
        return () => {
            document.removeEventListener('click', handleClick);
        };
    }, []);

    return (
        <div ref={colorRef}>
            <Button
                icon={showColorPalette ? 'Up' : 'Down'}
                iconPosition={'right'}
                onClick={() =>
                    setShowColorPalette(currentValue => !currentValue)
                }
            >
                <div
                    className={cx(SWATCH, { isPalette: false })}
                    style={{ background: activeColor }}
                />
            </Button>
            {showColorPalette && (
                <div className={COLOR_PALLETTE}>
                    {paletteOptions.map((color: string) => {
                        return (
                            <div
                                className={cx(SWATCH, { isPalette: true })}
                                style={{ background: color }}
                                key={color}
                                onClick={() => handleChange(color)}
                            >
                                {color === activeColor ? (
                                    <Icon
                                        className={ICON}
                                        icon={'Check'}
                                        style={{
                                            color: textColorForBackgroundColor(
                                                color
                                            )
                                        }}
                                    />
                                ) : null}
                            </div>
                        );
                    })}
                </div>
            )}
        </div>
    );
};

export default ColorPicker;
